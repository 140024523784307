import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-plus-button',
    templateUrl: "./formly-plus-button.component.html"
})

export class FormlyPlusButtonComponent extends FieldType implements OnInit, OnDestroy {
    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    label: string;
    buttonClass: string;
    iconClass: string;
    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }

        if (this.field.templateOptions && this.field.templateOptions.label) {
            this.label = this.field.templateOptions.label;
        }
        if (this.field.templateOptions && this.field.templateOptions.buttonClass) {
            this.buttonClass = this.field.templateOptions.buttonClass;
        }
        if (this.field.templateOptions && this.field.templateOptions.iconClass) {
            this.iconClass = this.field.templateOptions.iconClass;
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onClick() {
        this.to.click(this.field);
    }

}
