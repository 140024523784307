import { OnInit, Component, EventEmitter, Input, Output, AfterViewInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { ViewReceiptComponent } from "src/app/shared/panels/view-receipt/view-receipt.component";
import { IApiresponse } from "src/app/_models";
import { ExpenseMergeInputModel, ExpenseMergePanelOutputModel, GetReceiptExpensesWithAttachmentRequest, IGetReceiptExpensesWithAttachmentRequest, IGetReceiptExpensesWithAttachmentResponse, ReceiptExpenseDetails, ViewVoiceMemoInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ExpenseCategoryRequest, ExpenseCategoryRequestInfo } from "src/app/_models/claimant/expense-category-request";
import { Claims } from "src/app/_models/common/claims";
import { ApiRequest } from "src/app/_models/common/request-response/api-request";
import { IReceiptInfo } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { ExpenseCategoryService } from "src/app/_services";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { environment } from "src/environments/environment";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { Subscription } from "rxjs";
import { AlertService } from "src/app/alert-panel/alert.service";

@Component({
    selector: "expense-merge-panel",
    templateUrl: "./expense-merge-panel.component.html"
})
export class ExpenseMergePanelComponent implements OnInit, AfterViewInit {
    @Input()
    inputData: ExpenseMergeInputModel;
    @Output()
    closePanelEvent = new EventEmitter<ExpenseMergeInputModel>();
    @Output()
    savePanelEvent = new EventEmitter<ExpenseMergePanelOutputModel>();
    param: any;
    localOrganisationId: any;
    localUserId: any;
    claims: Claims;
    receiptExpenseList: ReceiptExpenseDetails[];
    filteredReceiptExpenseList: ReceiptExpenseDetails[];
    gridFilterItems = [
        { id: 0, name: this.translate.instant("All") },
        { id: 1, name: this.translate.instant("Emailed Receipts") },
        { id: 2, name: this.translate.instant("Receipts created via App") }
    ];
    statusValue: number = 0;
    localOrgCurrency: string = "";
    orgExpenseCategoryList = <any[]>[];
    selectedExpenseIds = <number[]>[];
    showViewVoiceMemoPanel: boolean = false;
    viewVoiceMemoInputModel: ViewVoiceMemoInputModel;
    viewTagList = <any[]>[];
    showViewTagPanel: boolean = false;
    @ViewChild('viewReceiptComponent') viewReceiptComponent: ViewReceiptComponent;
    receiptCount: number = 1;
    viewReceiptsList = <any[]>[];

    showMoreThanOneRecordAlert: boolean = false;
    moreThanOneRecordAlertModel: AlertPanelModel;
    isMoreThanOneRecordAlertDone: boolean = false

    receiptLimitAlertPopupModel: AlertPanelModel;
    showReceiptLimitAlertPopup: boolean = false;
    isSaveEnabled:boolean;
    hasFormChanges:boolean;
    userActionEventSubscription: Subscription;

    constructor(
        private translate: TranslateService,
        private claimsService: ClaimsService,
        private claimantOnWebService: ClaimantOnWebService,
        private expenseCategoryService: ExpenseCategoryService,
        private alertService: AlertService,
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
            this.localUserId = this.claims.UserId;
            this.localOrganisationId = this.claims.OrgId;
            this.localOrgCurrency = this.claims.CurrentOrganisationCurrencyCode;
        });
        this.fnObserveUnsavedChangesAlerts();
    }

    ngOnInit() {
        this.selectedExpenseIds = this.inputData.mergeExpenseIds;
        this.fnGetOrgExpenseCategoryList();
        this.fnGetReceiptExpensesWithAttachment()
    }

    fnObserveUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(this.inputData);
            }
        });
    }

    canDeactivate(): boolean {
       return !this.hasFormChanges;
    }

    ngAfterViewInit() { }

    fnSavePanel() {

        let outputModel = this.generateOutputModel();

        let selectedReceiptCount = 0;
        let availableReceiptCount = 0;

        if (outputModel && outputModel.receiptList) {
            selectedReceiptCount = outputModel.receiptList.length;
        }
        if (this.inputData && this.inputData.receiptInfo) {
            availableReceiptCount = this.inputData.receiptInfo.length;
        }
        if ((selectedReceiptCount) > 5) //Not allowed more than 5 for an item.
        {
            this.fnShowReciptLimitAlert();
            return;
        }

        this.savePanelEvent.emit(outputModel);
    }

    fnClosePanel() {
       
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(this.inputData);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetReceiptExpensesWithAttachment() {
        let request = new ApiRequest<IGetReceiptExpensesWithAttachmentRequest>("GetReceiptExpensesWithAttachment", environment.applicationVersion, environment.requestId);
        let requestInfo = new GetReceiptExpensesWithAttachmentRequest(this.inputData.orgId, this.inputData.userId, this.inputData.expenseId);
        request.requestInfo = requestInfo;

        this.claimantOnWebService.GetReceiptExpensesWithAttachment(request)
            .pipe(first())
            .subscribe((response: IApiresponse<IGetReceiptExpensesWithAttachmentResponse>) => {
                if (response.responseInfo != null) {
                    this.receiptExpenseList = response.responseInfo.expenseList;
                    this.filteredReceiptExpenseList = this.receiptExpenseList;
                }
            });
    }

    fnFilterChanged() {
        switch (this.statusValue) {
            case 0:
                this.filteredReceiptExpenseList = this.receiptExpenseList;
                break;
            case 1:
                this.filteredReceiptExpenseList = this.receiptExpenseList.filter(a => a.receiptInfo && a.receiptInfo.some(b => b.isEmailedReceipt == true));
                break;
            case 2:
                this.filteredReceiptExpenseList = this.receiptExpenseList.filter(a => a.receiptInfo && a.receiptInfo.some(b => b.isEmailedReceipt == false));
                break;
            default:
                break;
        }
        this.selectedExpenseIds = <number[]>[];
        // console.log("Filtered Receipt Expense List:", this.filteredReceiptExpenseList);
    }

    fnGetOrgExpenseCategoryList() {
        let request = new ExpenseCategoryRequest();
        request.WebApiName = "GetExpenseCategoryByID";
        request.WebApiVersion = environment.applicationVersion;
        request.RequestId = Number(environment.requestId);

        request.RequestInfo = new ExpenseCategoryRequestInfo();
        request.RequestInfo.OrgId = this.claims.OrgId;
        request.RequestInfo.LastUpdatedTimeStamp = 0;

        this.expenseCategoryService
            .getOrgExpenseCategory(request)
            .pipe(first())
            .subscribe((response) => {
                if (response.responseInfo != null) {
                    let resData = response.responseInfo;
                    this.orgExpenseCategoryList = resData.categories;
                }
            });
    }

    getOrgCategoryName(catId: number) {
        return this.orgExpenseCategoryList.filter(a => a.orgExpenseCategoryId == catId).map(b => b.expenseCategoryName);
    }

    fnSelectExpenseId(expenseId: number) {
        this.hasFormChanges = true;
        this.isSaveEnabled = true;
        let isAvailable = this.selectedExpenseIds.includes(expenseId);
        if (isAvailable) {
            this.selectedExpenseIds = this.selectedExpenseIds.filter(a => a != expenseId);
        } else {
            this.selectedExpenseIds.push(expenseId);

            if (this.selectedExpenseIds.length > 1 && this.selectedExpenseIds.length < 3) {
                this.fnShowMoreThanOneRecordAlert();
            }
        }
    }

    isExpenseChecked(expenseId) {
        
        return this.selectedExpenseIds.includes(expenseId);
    }

    generateOutputModel() {
        let tagsList = <any[]>[];
        let voiceMemoList = <any[]>[];
        let receiptList = <any[]>[];
        let notesList = <any[]>[];
        this.selectedExpenseIds.forEach(element => {
            let selectedItem = this.receiptExpenseList.filter(a => a.expenseId == element);
            if (selectedItem && selectedItem.length > 0 &&  selectedItem[0].receiptInfo && selectedItem[0].receiptInfo.length > 0) {
                receiptList.push(...selectedItem[0].receiptInfo);
            }
            if (selectedItem && selectedItem.length > 0 && selectedItem[0].tagList && selectedItem[0].tagList.length > 0) {
                tagsList.push(...selectedItem[0].tagList);
            }
            if (selectedItem && selectedItem.length > 0 && selectedItem[0].memoInfo) {
                voiceMemoList.push(selectedItem[0].memoInfo);
            }
            if (selectedItem && selectedItem.length > 0 && selectedItem[0].notes && selectedItem[0].notes.trim().length > 0) {
                notesList.push(selectedItem[0].notes.trim());
            }
        });

        let outputModel = new ExpenseMergePanelOutputModel();
        outputModel.selectedExpenseIds = this.selectedExpenseIds;
        outputModel.tagsList = tagsList.filter((n, i) => tagsList.indexOf(n) === i);
        outputModel.receiptList = receiptList;
        outputModel.voiceMemoList = voiceMemoList;
        outputModel.notesList = notesList;
        return outputModel;
    }

    fnShowViewVoiceMemoPanel(memoName, memoURL) {
        this.viewVoiceMemoInputModel = new ViewVoiceMemoInputModel(memoName, memoURL);
        this.showViewVoiceMemoPanel = true;
    }

    fnShowViewTagPanel(tagList) {
        this.viewTagList = tagList;
        this.showViewTagPanel = true;
    }

    fnShowReceiptsPanel(items: IReceiptInfo[]) {
        this.receiptCount = items.length;
        this.viewReceiptsList = items;
        this.viewReceiptComponent.fnShowReceiptsPanel(items);
    }


    fnShowMoreThanOneRecordAlert() {

        if (!this.isMoreThanOneRecordAlertDone) {

            this.moreThanOneRecordAlertModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [this.translate.instant("more_than_one_expense_selected_msg")],
                '',
                this.translate.instant("label_ok"),
                true,
                ''
            );
            this.showMoreThanOneRecordAlert = true;
        }
    }

    fnThanOneRecordAlertPanelReceiver(returnValue) {
        this.showMoreThanOneRecordAlert = false;
        this.isMoreThanOneRecordAlertDone = true;
    }


    fnShowReciptLimitAlert() {
        this.receiptLimitAlertPopupModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [this.translate.instant("receipt_limit_alert")],
            "",
            this.translate.instant("label_ok"),
            true,
            ""
        );
        this.showReceiptLimitAlertPopup = true;
    }

    fnReceiptLimitPopupReceiver() {
        this.showReceiptLimitAlertPopup = false;
    }


}
