import { OnInit, Component, EventEmitter, Input, Output, AfterViewInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "../../../../_models/common/claims";
import { ClaimsService } from "../../../../_services/common/claims.service";

@Component({
    selector: "attach-receipt-options-panel",
    templateUrl: "./attach-receipt-options-panel.component.html"
})
export class AttachReceiptOptionsPanelComponent implements OnInit, AfterViewInit {
    // @Input()
    // inputData: AttachReceiptOptionsInputModel;
    @Output() closePanelEvent = new EventEmitter();
    @Output() showFilePickerEvent = new EventEmitter();
    @Output() showExpenseMergePanelEvent = new EventEmitter();
    @Input() hideSavedEmailReciepts: boolean;
    userType:number;
    param: any;
    claims: Claims;
    constructor(
        private translate: TranslateService,
        private claimsService: ClaimsService
    ) { 
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
            this.userType = this.claims.userType??0;
          });
    }

    ngOnInit() { }

    ngAfterViewInit() { }

    fnCloseAttachReceiptOptionsPanel() {
        this.closePanelEvent.emit(null);
    }

    fnShowFilePicker() {
        this.showFilePickerEvent.emit(null);
    }

    fnShowExpenseMergePanel() {
        this.showExpenseMergePanelEvent.emit(null);
    }
}
