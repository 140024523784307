<h1 class="onboarding-title pt-3">
    {{ 'login' | translate}}
</h1>
<form class="form onboarding onboarding-width pt-5"
      [formGroup]="loginForm"
      (ngSubmit)="onLoginSubmit()">
    <div class="d-flex flex-column align-content-between">
        <div>
            <div class="form-group">
                <label class="input-label"
                       for="email">{{ "label_email" | translate }}<span>*</span></label>
                <input class="form-control form-input"
                       type="text"
                       id="email"
                       formControlName="username"
                       autocomplete="off"
                       [ngClass]="{
                    'has-value': username.value != ''
                  }"
                       (blur)="onEmailIdEntered()"
                       (focus)="showPasswordBox = false"
                       #emailField />

                <div *ngIf="
                       username.invalid &&
                       (username.dirty ||
                         username.touched ||
                         username.value == '' ||
                         submitted)
                     "
                     class="alert-danger">
                    <div *ngIf="username.errors.required && submitted">
                        <b>{{ "required_validation" | translate }}</b>
                    </div>
                    <div *ngIf="username.errors.invalidEmail && submitted">
                        <b>{{ "label_Emailisnotvalid" | translate }}</b>
                    </div>
                </div>
            </div>
            <div *ngIf="showPasswordBox"
                 class="form-group position-relative">
                <label class="input-label"
                       for="password">{{'password' | translate}}<span>*</span></label>
                <input class="form-control form-input"
                       [type]="showPassword ? 'text' : 'password'"
                       id="password"
                       formControlName="password"
                       autocomplete="off"
                       #passwordField />
                <div *ngIf="
                            password.invalid &&
                            (password.dirty ||
                              password.touched ||
                              password.value == '' ||
                              submitted)
                          "
                     class="alert-danger">
                    <div *ngIf="password.errors.required && submitted">
                        <b>{{ "required_validation" | translate }}</b>
                    </div>
                </div>
                <button type="button"
                        style="
                z-index: 99999999999;
                color: #333;
                right: 5px !important;
              "
                        class="view-password"><i class="fas fa-eye-slash"
                       (mousedown)="showPassword = true"
                       (mouseup)="showPassword = false"
                       [ngClass]="{
        'fa-eye-slash': !showPassword,
        'fa-eye': showPassword
        }"></i></button>
            </div>
            <div *ngIf="showPasswordBox"
                 class="form-group">
                <label class="custom-checkbox">{{'remember_me' | translate}}
                    <input type="checkbox"
                           formControlName="rememberpassword">
                    <span class="checkmark"></span>
                </label>
            </div>
            <button type="submit"
                    class="btn eod-btn-secondary btn-lg btn-block">{{'login' | translate}}</button>
            <div class="text-center py-4 fw-600">
                <a href="/forgotpassword"
                   class="forgot-password eod-text-danger2">{{'forgot_password' | translate }}?</a>
            </div>
        </div>

        <div *ngIf="showCaptchaMessage"
             class="row text-left eod-text-danger2 f-12 font-weight-bold font-weight-normal margin-top-5">
            {{ "invalid_captcha_text" | translate: param }}
        </div>

        <div *ngIf="showCaptcha"
             class="text-center pt-2 d-flex justify-content-start">
            <p>
                <re-captcha #captchaRef="reCaptcha"
                            (resolved)="resolved($event)"
                            (error)="onError($event)"
                            errorMode="handled">
                </re-captcha>
            </p>
            <p class="text-left d-block mt-2 text-mustard f-13 font-weight-bold padding-left-10">
                {{ "ten_filed_login_attempts" | translate: param }}
            </p>
        </div>
        <div class="mt-auto">
            <p class="text-center py-4 onboarding-width">
                {{'dont_have_expenseondemand_account' | translate}} <a href="javascript:void(0)"
                   (click)="gotoSignUp()"
                   class="forgot-password eod-text-danger2 fw-600">{{'register_now' | translate }}</a>
            </p>
            <div  *ngIf="!isChrome" class="chrome-notification d-flex onboarding-width">
                <small class="onboarding-width">{{'please_use_chrome' | translate}}</small>
                <img src="../../../../assets/images/chrome.png" class="padding-left-35"
                     alt="">
            </div>
        </div>
    </div>



</form>