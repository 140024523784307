import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'grid-dropdown-renderer',
    templateUrl: './grid-dropdown-renderer.component.html',
    styleUrls: ["./grid-dropdown-renderer.component.scss"],
})

export class GridDropdownRendererComponent implements ICellRendererAngularComp {

    placeholder = "";
    items = [];
    value: any;
    params: any;

    initilize(params: ICellRendererParams) {

        if (params.colDef.headerComponentParams) {
            this.placeholder = params.colDef.headerComponentParams.placeholder;
        }

        if (params.data) {
            this.items = params.data.dropdownList ? params.data.dropdownList : [];
        }

        this.value = params.getValue();
    }

    agInit(params: any): void {
        this.params = params;
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onChange($event) {
        if (this.params.onChange instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data
            }
            this.params.setValue(params.event.id);
            this.params.onChange(params);
        }
    }

}
