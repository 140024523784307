import { DatePipe } from "@angular/common";
import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'status-supporting-text-renderer',
    templateUrl: './status-supporting-text-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class StatusSupportingTextRendererComponent implements ICellRendererAngularComp {

    text: string = '';
    class: string = '';
    aboutToExpirytext: string = '';
    suspensionText: string = '';
    secondDocumentPart1 : string = '';
    secondDocumentPart2 : string = '';
    secondDocumentPart3 : string = '';
    colorStatusBeforeExpiry : string = '';
    status:number;
    isStatusChange: boolean;
    constructor(public datePipe: DatePipe,
        private translate: TranslateService) {
    }

    initilize(params: ICellRendererParams) {
        let value = params.getValue();
        if (value && params.colDef.headerComponentParams) {
            let item = params.colDef.headerComponentParams.find(item => item.value == value);
            if (item) {
                this.class = item.class;
                this.text = item.text;
            }
        }
        else{
            this.class = "";
            this.text = "";  
        }    
        
        if(params.data)
        { 
          this.isStatusChange=false;
          this.status = params.data.status;
          if(params.data.status == 9)
          {   this.suspensionText = params.data.suspensionStartDate > 0 && params.data.suspensionEndDate > 0 ?
             " (" + this.datePipe.transform(new Date(parseInt(params.data.suspensionStartDate.toString())),"dd-MMM-yyyy") +" to " +
                    this.datePipe.transform(new Date(parseInt(params.data.suspensionEndDate.toString())),"dd-MMM-yyyy") + ")" : "";
          }
          if(params.data.status != params.data.statusBeforeExpiry && params.data.status != 9)
          { 
             this.isStatusChange = true;
             this.secondDocumentPart3 = "(" + this.translate.instant('new_document_submitted') + ")";

             this.secondDocumentPart1="<p class='text-charcoal-black font-weight-bold text-logo-blue'>({{secondDocumentPart1}}"    
                +"</p><p class='text-charcoal-black font-weight-bold padding-bottom-5'>Status :"
                +"<span [style.color]="+ this.colorStatusBeforeExpiry +" class='font-weight-bold'>{{secondDocumentPart2}}</span>)</p>";

             this.secondDocumentPart1 = this.translate.instant('new_document_submitted_on') + " " + this.datePipe.transform(new Date(parseInt(params.data.submissionDateBeforeExpiry.toString())),"dd-MMM-yyyy");
             this.secondDocumentPart2 = this.fnGetStatus(params.data.statusBeforeExpiry);
             this.colorStatusBeforeExpiry = params.data.colorStatusBeforeExpiry;
          }
          else if(params.data.status == 6)
             this.aboutToExpirytext = params.data.remainingDaysExpiry > 0 ? " (" + this.translate.instant('document_will_expire') +" "+ params.data.remainingDaysExpiry + this.translate.instant('days') + ")" : "";
        }
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    fnGetStatus(status: number)
    {
    var string = "";
    switch(status)
    {
        case 1 : string = this.translate.instant('please_complete');
                break;
        case 2 : string = this.translate.instant('incomplete');
                break;
        case 3 : string = this.translate.instant('pending_submission');
                break;
        case 4 : string = this.translate.instant('pending_for_approval');
                break;
        case 5 : string = this.translate.instant('label_rejected');
                break;
        case 6 : string = this.translate.instant('approved');
                break;
        case 7 : string = this.translate.instant('expired');
                break;
        case 10 : string = this.translate.instant('about_to_expire');
                break;
        case 9 : string = this.translate.instant('suspended');
                break;
        case 11 : string = this.translate.instant('not_required');
        break;
    }
    return string;
    }
}