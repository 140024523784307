import { Component, ViewEncapsulation } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'click-to-setup-cell',
  templateUrl: './click-to-setup-renderer.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ClickToSetupRendererComponent implements ICellRendererAngularComp {
  text: string = '';
  class: string = '';
  actionButtons: any[] = [];
  params: ICellRendererParams;
  constructor() {
  }

  onUserAction(action) {
    this.params.data.userAction = action;
    this.params.node.setData(this.params.data);
    this.params.eGridCell.click();
  }

  initilize(params: ICellRendererParams) {    
    if (params && params.data) {  
      this.params = params;
      this.actionButtons = JSON.parse(JSON.stringify(params.colDef.headerComponentParams));
      for (let i = 0; i < this.actionButtons.length; i++) {
        this.actionButtons[i].isHidden = this.actionButtons[i].hideProperty && this.params.data[this.actionButtons[i].hideProperty];        
        if(this.params.data[this.actionButtons[i].showProperty] && this.actionButtons[i].isHidden==false)
          this.actionButtons[i].isHidden=true;
        else if(this.actionButtons[i].isHidden==undefined)
          this.actionButtons[i].isHidden=!this.params.data[this.actionButtons[i].showProperty];
      }
    }
    else {
      this.actionButtons = [];
    }  
    console.log(this.actionButtons);  
  }

  showEditGradePanelFunction() {

  }
  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
