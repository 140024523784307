import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ClaimsService } from "../common/claims.service";
import { Observable, Subject } from "rxjs";
import { ClaimsHeaderService } from "../../shared/claimsHeader/claims-header.service";
import { LogObjects } from "../../_models/common/LogObjects";
import { Claims } from "../../_models/common/claims";
import { environment } from "../../../environments/environment";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { LoggingPanelModel } from "../../_models/common/LoggingPanelModel";


@Injectable({
  providedIn: "root"
})
export class LoggingPanelService {
  orgId: number;
  userId: number;
  token: string;
  organisationCurrency: string;
  claims: Claims;

  private logPanelModelSubject: Subject<LoggingPanelModel> = new Subject<LoggingPanelModel>();

  constructor(private http: HttpClient, private claimsService: ClaimsService, private claimsHeaderService: ClaimsHeaderService) {
    this.claimsService.currentClaims.subscribe(
      claims => (this.claims = claims)
    );
  }


  getFMActionLog(OrgId, FunctionId, FilterType, MileageFilterType="0", EntityFilterType="0"): Observable<IApiresponse<LogObjects[]>> {
    let url = `${environment.catalogApiUrl}GetLogs/` + OrgId + `/` + FunctionId + `/` + FilterType + `/` + MileageFilterType + ``+ `/` + EntityFilterType + ``;
    return this.http.get<IApiresponse<LogObjects[]>>(url, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }


  public onShowLogPanel(model: LoggingPanelModel) {
    
    this.logPanelModelSubject.next(model);
  }

  public getLogPanelEvent(): Observable<LoggingPanelModel> {
    return this.logPanelModelSubject.asObservable();
  }


}
