import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-checkbox-input',
  templateUrl: "./formly-checkbox-input.component.html"
})
export class FormlyCheckboxInputComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  valueSubscription: Subscription;
  checkedItems: number[] = [];
  allItems: any;
  fieldKey: string;

  constructor(private dynamicFormService: DynamicFormService, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.initialize();
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    this.valueSubscription = this.formControl.valueChanges.subscribe(value => {
      this.setCheckedItems(value);
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  initialize() {
    this.allItems = [];
    if (this.to.options) {
      this.allItems = this.to.options;
      if (this.allItems.filter(i => i.key == 0).length == 0) {
        this.allItems.splice(0, 0, {
          value: this.translate.instant('label_select_all'),
          key: 0,
          checked: this.allItems.filter(i => i.key > 0).length == this.allItems.filter(i => i.checked == true).length,
          class:"margin-bottom-10"
        });
      }
      else {
        this.allItems.find(i => i.key == 0).checked = this.allItems.filter(i => i.key > 0).length == this.allItems.filter(i => i.checked == true).length;
      }

      this.allItems.forEach(element => {
        if (element.checked)
          this.checkedItems.push(element.key);
      });
      if (this.model && this.field.key) {
        const items = this.model[this.field.key.toString()];
        this.setCheckedItems(items);
      }
    }

  }

  setCheckedItems(items) {
    if (items && items.length > 0) {
      this.checkedItems = items;
      this.allItems.forEach(i => {
        let isItemChecked = this.checkedItems.indexOf(i.key) > -1;
        if (this.checkedItems && isItemChecked) {
          i.checked = true;
        }
        else {
          i.checked = false;
        }
      });
      this.checkUncheckAll();
    }

  }

  onClick($event, item) {
    item.checked = $event.target.checked;
    if (item.checked) {
      if (item.key == 0) {
        this.checkedItems = [];
        this.allItems.forEach(i => {
          i.checked = true;
          if (i.key > 0) {
            this.checkedItems.push(i.key);
          }
        });
      }
      else if (item.key > 0 && this.checkedItems.indexOf(item.key) < 0) {
        this.checkedItems.push(item.key);
        this.checkUncheckAll();
      }
    }
    else {
      if (item.key == 0) {
        this.checkedItems = [];
        this.allItems.forEach(i => {
          i.checked = false;
        });
      }
      else {
        this.checkedItems = this.checkedItems.filter(i => i != item.key);
        this.checkUncheckAll();
      }
    }
    this.checkedItems = this.checkedItems.filter(i => i > 0);

    this.model[this.field.key.toString()] = this.checkedItems;

    if (this.to.change) {
      this.to.change(this.field, this.checkedItems);
    }
  }

  checkUncheckAll() {
    var selectAllItem = this.allItems.find(i => i.key == 0);
    if (selectAllItem) {
      let checkedItemsLength = this.checkedItems.filter(i => i > 0).length;
      let allItemsLength = this.allItems.filter(i => i.key > 0).length;
      if (this.checkedItems && checkedItemsLength == allItemsLength) {
        selectAllItem.checked = true;
      }
      else {
        selectAllItem.checked = false;
      }
    }

  }

}
