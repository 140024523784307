<div *ngIf="showPdfViewerPanel && !isOverLayHide" class="overLayDiv active"></div>
<div id="rightSideBar" *ngIf="showPdfViewerPanel">
    <div class="rightSidePanel overflow-hidden {{panelWidth}}" id="pdfViewer" [ngClass]="{ active: showPdfViewerPanel == true,'w-44pc': isClaimPage}">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{pdfName}}</h2>
            <div class="d-flex">
                <button type="button" class="btn eod-btn-outline-warning height-38px mr-2" *ngIf="showDeleteButton" (click)="delete()">
                    {{ 'label_delete' | translate: param }}
                </button>
                <div (click)="downloadReceipt()">
                    <button type="button" class="btn eod-btn-outline-warning height-38px mr-2" *ngIf="claimType == 1">
                        {{ 'download_receipt' | translate: param }}
                    </button>
                    <button type="button" class="btn eod-btn-outline-warning height-38px mr-2" *ngIf="claimType == 2">
                        {{ 'download_invoice' | translate: param }}
                    </button>
                </div>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="dismiss()">
                    {{ 'close' | translate: param }}
                </button>
            </div>
        </header>

        <div class="clear"></div>
        <div class="block">
            <div class="col-md-12 col-sm-12 no-padding-right-left">
                <div class=row>
                    <ngx-extended-pdf-viewer [src]="pdfUrl"
                                             height="80vh"
                                             class="w-100pc"
                                             style="display: block;"
                                             [showSidebarButton]="false"
                                             [showFindButton]="true"
                                             [showPagingButtons]="true"
                                             [showZoomButtons]="true"
                                             [showPresentationModeButton]="true"
                                             [showOpenFileButton]="false"
                                             [showPrintButton]="false"
                                             [showDownloadButton]="false"
                                             [showBookmarkButton]="false"
                                             [showSecondaryToolbarButton]="true"
                                             [showRotateButton]="true"
                                             [showHandToolButton]="false"
                                             [showScrollingButton]="false"
                                             [showSpreadButton]="false"
                                             [showPropertiesButton]="false"
                                             [useBrowserLocale]="true"
                                             [enablePrint]="false"
                                             [showDrawEditor] = "false"
                                             [showTextEditor] = "false"
                                             >
                    </ngx-extended-pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</div>