import { OnInit, Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Userresponsedto } from "src/app/_models/user/userresponsedto";
import { AuthenticationService } from "src/app/_services";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { IRecentTagsRequest, RecentTagsRequest, IRecentTagsResponse, SelectTagsPopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "create-tag-panel",
    templateUrl: "./create-tag-panel.component.html"
})
export class CreateTagPanelComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input()
    inputData: SelectTagsPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectTagsPopupModel>();

    currentUserSubscription: Subscription;
    userActionEventSubscription: Subscription;
    currentUser: Userresponsedto;
    claims: Claims;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;

    recentTagsList = <string[]>[];
    createTagsForm: UntypedFormGroup;
    isCreateTagsFormSubmitted: boolean = false;
    disableSubmitButton: boolean = false;
    tagList = <string[]>[];

    @ViewChild("tagName") tagNameInput: ElementRef;

    constructor(
        private authenticationService: AuthenticationService,
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private alertService: AlertService,
        public fb: FormBuilder,
        private claimantOnWebService: ClaimantOnWebService
    ) {
        super();
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
            (user) => {
                this.currentUser = user;
            }
        );
        this.observeUnsavedChangesAlerts();
    }
    ngOnInit(): void {
        this.createTagsForm = this.fnCreateTagsForm();
        this.fnGetTagListControl();
        this.fnGetRecentTags();
    }

    ngAfterViewInit() {
        this.tagNameInput.nativeElement.focus();
    }
    canDeactivate(): boolean {
        return !this.createTagsForm.dirty;
    }

    observeUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }
    fnCreateTagsForm() {
        let form = this.fb.group({
            tagName: ['', [Validators.required, this.noWhitespaceValidator]],
        });
        return form;
    }

    fnGetRecentTags() {
        let request = new ApiRequest<IRecentTagsRequest>("RecentTags", environment.applicationVersion, environment.requestId);
        let requestInfo = new RecentTagsRequest(this.claims.OrgId, this.inputData.claimantId);
        request.requestInfo = requestInfo;

        this.claimantOnWebService
            .GetRecentTags(request)
            .pipe(first())
            .subscribe((response: IApiresponse<IRecentTagsResponse>) => {
                if (response.responseInfo != null &&  response.responseInfo.recentTagList) {
                    this.recentTagsList =  [...new Set(response.responseInfo.recentTagList)];
                }
            });
    }

    fnSelectTag() {

        if (this.createTagsForm.value.tagName && this.createTagsForm.value.tagName.trim()) {
            this.fnAddTag();
        }
        let selectTagsPopupModel = new SelectTagsPopupModel();
        if (this.tagList && this.tagList.length > 0) {
            selectTagsPopupModel.tagList = this.tagList;
        } else {
            selectTagsPopupModel.tagList = [];
        }
        this.selectItemEvent.emit(selectTagsPopupModel);
    }

    fnAddTag() {
        let isAvailable = this.tagList.find(item => item.toLowerCase() == this.createTagsForm.value.tagName.toLowerCase())
        if (!isAvailable) {
            this.tagList.push(this.createTagsForm.value.tagName.trim());
        }
        this.createTagsForm.reset();
        this.createTagsForm.markAsDirty();
        this.tagNameInput.nativeElement.focus();
    }

    fnRemoveTag(i: number) {
        this.tagList.splice(i, 1);
        this.tagNameInput.nativeElement.focus();
        this.createTagsForm.markAsDirty();
    }

    fnGetTagListControl() {
        if (this.inputData.tagList && this.inputData.tagList.length > 0) {
            this.tagList = [];
            this.inputData.tagList.forEach(item => {
                this.tagList.push(item);
            })
        }
    }

    fnAddRecentTag(tagName: string) {
        if (tagName) {
            this.tagList.push(tagName);
        }
        this.tagNameInput.nativeElement.focus();
        this.createTagsForm.markAsDirty();
    }

    fnGetFilteredRecentTagList() {
        let recentTagList = [];
        if (this.recentTagsList && this.recentTagsList.length > 0) {
            recentTagList = this.recentTagsList.filter(r => !this.tagList.map(v => v.toLowerCase()).includes(r.toLowerCase()));
        }
        return recentTagList;
    }
    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || "").trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }
}
