<div class="block">
    <div class="row">
            <div class="col-lg-8 pl-0 pt-2">  
                <h6 class="text-left">
                    {{field.templateOptions.label}}
                </h6>
            </div>

            <div class="col-lg-4 pr-0 pt-2">  
                <!-- <div class="custom-control custom-switch text-right position-relative top--10px"  >
                    <input type="checkbox" checked class="custom-control-input" id="{{ 'cbx' + fieldKey }}" [checked]="formControl.value"
                    [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">
                    <label class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5" for="{{ 'cbx' + fieldKey }}"></label>
                </div> -->
                <div class="d-flex justify-content-end">
                    <input id="{{ 'cbx' + fieldKey }}" type="checkbox" switch="bool" [checked]="formControl.value"
                    [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">
                    <label for="{{ 'cbx' + fieldKey }}" class="mb-0 cursor-pointer">
                        <span class="ml-5 eod-text-secondary fw-600 f-16 text-nowrap"></span>
                    </label>
                </div>
            
            </div>
    </div>
</div>
