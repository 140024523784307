
import { INRClaimantList, NRClaimantList } from "./nrclaimant-list";

export interface IClaimantListForNRFCApproval {
    hasMorePage: boolean;
    userId: number;
    countOfRec: number;
    countOfEmp: number;
    fcClaimantList: INRClaimantList[]
    nrClaimantList: any;

}

export class ClaimantListForNRFCApproval implements IClaimantListForNRFCApproval {
    hasMorePage: boolean;
    userId: number;
    countOfRec: number;
    countOfEmp: number;
    fcClaimantList: NRClaimantList[]
    nrClaimantList: any;
}