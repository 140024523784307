import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'receipts-text',
    templateUrl: './receipts-text-renderer.component.html'
})
export class ReceiptsTextRendererComponent implements ICellRendererAngularComp {
    public hasReceipt: boolean = false;
    text: string = this.translate.instant("text_view_receipts");
    class: string = '';
    actionButtons: any[] = [];
    params: ICellRendererParams;
    
    constructor(private translate: TranslateService)
    {

    }

    initilize(params: ICellRendererParams) {
        if (params.data) {
            if(params.data.invoice?.invoiceNumber)
            {
                this.text = this.translate.instant("text_view_invoice_receipt");
            }
            this.hasReceipt = params.data.hasReceipt;
            this.params= params;
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
    onUserAction(action) {
        this.params.data.userAction = action;
        this.params.node.setData(this.params.data);
        this.params.eGridCell.click();
      }
}
