import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-default-message-renderer',
  templateUrl: './default-message-renderer.component.html'
})
export class DefaultMessageRendererComponent implements ICellRendererAngularComp {

  text: string = '';
  isComplete: boolean = false;
  params: ICellRendererParams;
  constructor() {
  }

  initilize(params: ICellRendererParams) {
        
    if (params.data) {
      this.isComplete = params.data.isComplete;
      this.text = params.data.defaultMessage;
    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
