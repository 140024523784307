import { INRDashboardReceiptModal, NRDashboardReceiptModal } from "../NrFc/nrdashboard-receipt-modal";


export interface INRDashboardReceiptResponse {
    userId: number;
    costOfFuelTotal: number;
    expenseUsedDistanceTotal: number;
    originalDistanceTotal: number;
    receiptAmountTotal: number;
    vatClaimableTotal: number;
    vatTotal: number;
    nrFuelRate: string;
    nRDashboardReceiptList: INRDashboardReceiptModal[];

}

export class NRDashboardReceiptResponse implements INRDashboardReceiptResponse {
    userId: number;
    costOfFuelTotal: number;
    expenseUsedDistanceTotal: number;
    originalDistanceTotal: number;
    receiptAmountTotal: number;
    vatClaimableTotal: number;
    vatTotal: number;
    nrFuelRate: string;
    nRDashboardReceiptList: NRDashboardReceiptModal[];
}
