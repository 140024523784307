import { FormlyFieldConfig } from "@ngx-formly/core";

export function GetField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, len = fields.length; i < len; i++) {
        const f = fields[i];
        if (f.key === key) {
            return f;
        }

        if (f.fieldGroup && !f.key) {
            const cf = GetField(key, f.fieldGroup);
            if (cf) {
                return cf;
            }
        }
    }
}