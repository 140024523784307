import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pre-approval-count-renderer',
  templateUrl: './pre-approval-count-renderer.component.html'
})
export class PreApprovalCountRendererComponent implements ICellRendererAngularComp {
  count: number = 0;
  preApprovalId : number = 0;

  constructor() {
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  initilize(params: ICellRendererParams) { 
    this.count =  params.getValue();

    if (params.data && params.data.preApprovalId) {
      this.preApprovalId = params.data.preApprovalId;
    }
    
  }
}
