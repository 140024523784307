<div class="input-group">
  
  <input id="calendarDateMonthInput" type="text" class="form-control formly-calender-input has-value"
    [ngClass]="{ 'margin-top-20': addTopMargin,
      'color-light-grey-disabledType pointer-events-none': to.disabled, 'text-logo-blue': !to.disabled }"
    #fydate readonly [formControl]="formControl"
    [formlyAttributes]="field" (input)="onValueChange()">
  <label for="calendarDateMonthInput" *ngIf="isLabelVisible">
    {{calendarLabel}}<span *ngIf="field?.templateOptions?.required">*</span>
  </label>
  <app-calendar-icon></app-calendar-icon>


  <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted"
    class="alert-danger padding-left-140">
    <span *ngIf="!formControl.value || !formControl.value.trim()">
      <b>{{ "label_Required" | translate: param }}</b>
    </span>
  </div>
  <p class="text-left margin-top--20" *ngIf="field.templateOptions.note && field.templateOptions.note">
    <small>
        {{field.templateOptions.note}}
    </small>
</p>
</div>