import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiRequest, IApiresponse } from "src/app/_models";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { ExcelExportService } from "../common/excel-export.service";
import {
  IRequestGradeListAll,
  IRequestGetGrade,
  IResponseGrade,
  IResponseGradeList,
  IRequestGrade,
  IRequestGradeListForAssignEmployee,
  IGradeMappedEmployeeList,
  IRequestGradeBulkDeactivate,
  IGradeCsvRecordExport,
  GradeCsvRecordExport,
} from "../../_models/grade/grade";
@Injectable({
  providedIn: "root",
})
export class GradeService {
  gradeUrl: any;
  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private excelExportService: ExcelExportService
  ) {}

  GetGrade(
    requestInfo: IApiRequest<IRequestGetGrade>
  ): Observable<IApiresponse<IResponseGrade>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGrade>>(
      `${environment.billingApiUrl}GetGradeById`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CreateGrade(
    requestInfo: IApiRequest<IRequestGrade>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}CreateGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  UpdateGrade(
    requestInfo: IApiRequest<IRequestGrade>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UpdateGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetGradeListForAssignEmployee(
    requestInfo: IApiRequest<IRequestGradeListForAssignEmployee>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetGradeListForAssignEmployee`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignDeassignGradeToEmployee(
    requestInfo: IApiRequest<IGradeMappedEmployeeList>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AssignDeassignGradeToEmployee`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignDeassignGradeToEmployeeBackground(
    requestInfo: IApiRequest<IGradeMappedEmployeeList>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AssignDeassignGradeToEmployeeBackground`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  BulkDeactiveGrade(
    requestInfo: IApiRequest<IRequestGradeBulkDeactivate>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkDeactiveGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllGradeByOrgId(
    requestInfo: IApiRequest<IRequestGradeListAll>
  ): Observable<IApiresponse<IResponseGradeList>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGradeList>>(
      `${environment.billingApiUrl}GetAllGradesByOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  exportData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: IGradeCsvRecordExport[] = [];
    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new GradeCsvRecordExport();
      recordExport.GradeName = exportedData[i].gradeName;
      recordExport.Description = exportedData[i].gradeDescription;
      if (addErrorColumn) {
        recordExport.Error = exportedData[i].error;
      }

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  BulkReactiveGrade(
    requestInfo: IApiRequest<IRequestGradeBulkDeactivate>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkReactiveGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getCategpriesToGrade(): Observable<any> {
    let url = `${environment.billingApiUrl}GetOrgAssignCategoryToGrades`;
    return this.http.get<any>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  saveCategpriesToGradeByGrade(
    requestInfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveAssignCategoryToGradesByGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetGradeDetailsById(gradeId: number): Observable<IApiresponse<IResponseGrade>> {
    let params = new HttpParams();
    params = params.append("gradeId", gradeId.toString());
    return this.http.get<IApiresponse<IResponseGrade>>(
        `${environment.billingApiUrl}GetGradeDetailsById`,
        {
            params: params,
            headers: this.claimsHeaderService.createLoginAuthorizationHeader()
        }
    );
}

}
