import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "../common/claims.service";
import { UniversalImportTemplateEnum } from "src/app/_models/enum/solo-enum";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { ApiRequest, IApiRequest } from "../../_models/common/request-response/api-request";
import {
  IUniversalImportFieldMasterResponse,
  IEmployeeImportModel,
  IValidateUniversalImportEmployeesRequest,
  IValidateUniversalImportEmployeesResponse,
  IBulkSaveUniversalImportEmployeesRequest,
  StorageData,
  IBulkSaveUniversalCostCentreApproversRequest
} from "src/app/_models/universal-importer/universal-importer";
import { BackgroundTaskKeys, BackgroundTaskNotificationBody, BackgroundTaskNotificationHeaders, BackgroundTaskTopics } from 'src/app/common/commonvariables';
import { BackgroundTask } from 'src/app/_models/common/background-task';
import { NotificationService } from 'src/app/_services/common/notification.service';
import { FunctionPackageEnum, FunctionPrivilege, BillingMethod } from '../../_models';
import { AdvanceCappingPolicyImport } from "src/app/_models/advances/advances";
import { IBulkSaveCustomerRequest } from "src/app/_models/customer/customer";
import {IBulkImportExpenseCategoryRequest } from "src/app/_models/manage-category/expense-category";
import { IBulkCounterAppRequest } from "../../_models/counter-approver/counter-approver.model";
import { IBulkSaveCurrenciesRequest } from "../../_models/fxRate/fx-rate.model";
import { UkMileageRateSettingService } from "../advance-mileage/uk-mileage-rate-setting.service";
import {IBulkImportAssignCreditCardRequest} from "src/app/_models/credit-card/credit-card";
import { BulkImportGradeRequest } from "src/app/_models/grade/grade";

@Injectable({
  providedIn: "root",
})
export class UniversalImporterService {

  //add observable import storage
  importStorageData: StorageData = new StorageData();
  private _importStorageData = new BehaviorSubject(this.importStorageData);
  currentImportStorageData = this._importStorageData.asObservable();

  orgId: number;
  userId: number;
  organisationCurrency: string;
  claims: Claims;


  private currentTemplateName = new BehaviorSubject('');
  templateName = this.currentTemplateName.asObservable();

  private currentImportTemplateData = new BehaviorSubject('');
  importTemplateData = this.currentImportTemplateData.asObservable();

  private currentImportTemplateExtension = new BehaviorSubject('');
  importTemplateExtension = this.currentImportTemplateExtension.asObservable();

  private currentImportTemplateFileName = new BehaviorSubject('');
  importTemplatFileName = this.currentImportTemplateFileName.asObservable();

  private currentImportTemplateGridData = new BehaviorSubject('');
  importTemplateGridData = this.currentImportTemplateGridData.asObservable();

  private isCurrentTemplateContainsHeader = new BehaviorSubject(true);
  isTemplateContainsHeader = this.isCurrentTemplateContainsHeader.asObservable();

  private currentReviewEmployeesList = new BehaviorSubject([]);
  importReviewEmployeesList = this.currentReviewEmployeesList.asObservable();

  constructor(
    private http: HttpClient,
    private header: setHeader,
    private claimsService: ClaimsService,
    private claimsHeaderService: ClaimsHeaderService,
    private notificationService: NotificationService,
    private ukMileageRateSettingService: UkMileageRateSettingService
  ) {
    this.claimsService.currentClaims.subscribe(
      (claims) => (this.claims = claims)
    );
    this.orgId = this.claims.OrgId;
    this.userId = this.claims.UserId;
    this.organisationCurrency = this.claims.CurrentOrganisationCurrency;
  }

  updateTemplateName(name: string) {
    this.currentTemplateName.next(name)
  }

  updateimporttemplatedata(name: string) {
    this.currentImportTemplateData.next(name)
  }

  updateimporttemplateextension(name: string) {
    this.currentImportTemplateExtension.next(name)
  }

  updateImportTemplateFileName(name: string) {
    this.currentImportTemplateFileName.next(name)
  }

  updateImportTemplateGridData(name: string) {
    this.currentImportTemplateGridData.next(name);
  }

  updateIsCurrentTemplateContainsHeader(value: boolean) {
    this.isCurrentTemplateContainsHeader.next(value);
  }

  updateCurrentReviewEmployeesList(reviewEmployeesList: IEmployeeImportModel[] = []) {
    this.currentReviewEmployeesList.next(reviewEmployeesList);
  }


  GetUniversalImportFieldMasterByTemplateType(templateType: number):
    Observable<IApiresponse<IUniversalImportFieldMasterResponse[]>> {
    let params = new HttpParams();
    params = params.append("templateType", templateType.toString());
    return this.http.get<IApiresponse<IUniversalImportFieldMasterResponse[]>>(
      `${environment.catalogApiUrl}GetUniversalImportFieldMasterByTemplateType`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  ValidateUniversalImportEmployees(
    requestInfo: IApiRequest<IValidateUniversalImportEmployeesRequest>): Observable<IApiresponse<IValidateUniversalImportEmployeesResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IValidateUniversalImportEmployeesResponse>>(
      `${environment.billingApiUrl}ValidateUniversalImportEmployees`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }


  // BulkSaveUniversalImportEmployees(
  //   requestInfo: IApiRequest<IBulkSaveUniversalImportEmployeesRequest>): Observable<IApiresponse<any>> {
  //   let body = JSON.stringify(requestInfo);
  //   return this.http.post<IApiresponse<any>>(
  //     `${environment.billingApiUrl}BulkSaveUniversalImportEmployees`,
  //     body,
  //     { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  //   );
  // }

  BulkSaveUniversalImportEmployees(
    saveRequest: IApiRequest<IBulkSaveUniversalImportEmployeesRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalImportEmployees;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalImportEmployees,
      saveRequest, saveRequest.requestInfo.employees.map(e => e.email), BackgroundTaskNotificationHeaders.BulkUniversalImportEmployees,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.ManageEmployees, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }

  // BulkSaveUniversalCostCentreApprovers(
  //   requestInfo: IApiRequest<IBulkSaveUniversalCostCentreApproversRequest>): Observable<IApiresponse<any>> {
  //   let body = JSON.stringify(requestInfo);
  //   return this.http.post<IApiresponse<any>>(
  //     `${environment.billingApiUrl}BulkSaveUniversalCostCentreApprovers`,
  //     body,
  //     { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  //   );
  // }

  BulkSaveUniversalCostCentreApprovers(
    saveRequest: IApiRequest<IBulkSaveUniversalCostCentreApproversRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalCostCentreApprovers;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalCostCentreApprovers,
      saveRequest, saveRequest.requestInfo.costCentreApprovers.map(e => e.costCentreName), BackgroundTaskNotificationHeaders.BulkUniversalCostCentreApprovers,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.SetupCostCentre, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);
  }

  BulkSaveUniversalAdvanceClaimantPolicy(saveRequest: IApiRequest<AdvanceCappingPolicyImport>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalAdvanceClaimantPolicy;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalAdvanceClaimantPolicy,
      saveRequest, saveRequest.requestInfo.claimantPolicy.map(e => e.userId.toString()), BackgroundTaskNotificationHeaders.BulkUniversalAdvanceClaimantPolicy,
      notificationBody, route,
      FunctionPackageEnum.Advances, FunctionPrivilege.Advances, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);
  }

  BulkSaveUniversalImportCustomers(
    saveRequest: IApiRequest<IBulkSaveCustomerRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalImportCustomers;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalImportCustomers,
      saveRequest, saveRequest.requestInfo.customerList.map(e => e.name), BackgroundTaskNotificationHeaders.BulkUniversalImportCustomers,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.Customers, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }

  BulkSaveUniversalImportCounterApprover(
    saveRequest: IApiRequest<IBulkCounterAppRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalImportCounterApprover;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalImportCounterApprover,
      saveRequest, saveRequest.requestInfo.counterAppList.map(e => e.approverId.toString()), BackgroundTaskNotificationHeaders.BulkUniversalImportCounterApprover,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.CounterApprover, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }

  BulkSaveUniversalImportCurrencies(
    saveRequest: IApiRequest<IBulkSaveCurrenciesRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalImportFxRate;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.CataLog, BackgroundTaskKeys.BulkUniversalImportFxRate,
      saveRequest, saveRequest.requestInfo.currencyList.map(e => e.currencyId.toString()), BackgroundTaskNotificationHeaders.BulkUniversalImportFxRate,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.FxRate, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }


  fnSaveDataToLocalStorage(newStorageData) {
    this._importStorageData.next(newStorageData);
  }

  BulkUniversalImportEmployees(
    saveRequest: IApiRequest<IBulkSaveUniversalImportEmployeesRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkImportEmployees;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkImportEmployees,
      saveRequest, saveRequest.requestInfo.employees.map(e => e.email), BackgroundTaskNotificationHeaders.BulkImportEmployees,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.ManageEmployees, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }

  BulkUniversalImportCategories(
    saveRequest: IApiRequest<IBulkImportExpenseCategoryRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkUniversalImportCategories;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Billing, BackgroundTaskKeys.BulkUniversalImportCategories,
      saveRequest, saveRequest.requestInfo.categoryList.map(e => e.expenseCategoryName), BackgroundTaskNotificationHeaders.BulkUniversalImportCategories,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.ExpenseCategoryManagement, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }
  CreateGradeInBulk(
    saveRequest: IApiRequest<BulkImportGradeRequest>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkImportGradeBody;
    const route = "orgsetting/grade";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Expense, BackgroundTaskKeys.BulkImportGrades,
      saveRequest, saveRequest.requestInfo.gradeList.map(e => e.gradeName),
       BackgroundTaskNotificationHeaders.BulkImportGrades,
      notificationBody, route,
      FunctionPackageEnum.UniversalImport, FunctionPrivilege.SetupGrade, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }
  bulkSaveUniversalImportVehicleRequest: IApiRequest<any>;
  BulkSaveUniversalImportVehicleAssignment(
    request: any): Observable<IApiresponse<any>> {
      this.bulkSaveUniversalImportVehicleRequest = new ApiRequest<any>("BulkImportUserVehicleAssignment",
       environment.applicationVersion,
      environment.requestId);
      let functionIds = this.ukMileageRateSettingService.fnGetPurchaseFunctionList();
      let apiRequest = {
        purchaseFunctionList: functionIds,
        bulkImportVehicleAssignments: request
      }
      this.bulkSaveUniversalImportVehicleRequest.requestInfo = apiRequest
      let body = JSON.stringify(apiRequest);
      return this.http.post<IApiresponse<IValidateUniversalImportEmployeesResponse>>(
        `${environment.billingApiUrl}BulkImportUserVehicleAssignment`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
    }

    BulkImportAssignCreditCard(
      saveRequest: IApiRequest<IBulkImportAssignCreditCardRequest>): Observable<any> {
      const notificationBody = BackgroundTaskNotificationBody.BulkImportAssignCreditCard;
      const route = "universalimport/importsummary";
      let jobInfo = new BackgroundTask(BackgroundTaskTopics.Expense, BackgroundTaskKeys.BulkImportAssignCreditCard,
        saveRequest, saveRequest.requestInfo.assignCreditCardList.map(e => e.cardNumber), BackgroundTaskNotificationHeaders.BulkImportAssignCreditCard,
        notificationBody, route,
        FunctionPackageEnum.SetupCreditCard, FunctionPrivilege.SetupCreditCard, saveRequest.requestInfo.fmName);
  
      return this.notificationService.submitBackgroundJob(jobInfo);
  
    }
  }



  

