<ng-scrollbar class="scroll-event" id="pricing-ext-res" thumbClass="white-scrollbars">
  <div class="container-fluid ">
    <div id="pricing-external">
      <div class="container-fluid" >
        <div class="navbar-parent" [ngClass]="{'navbar-hidden': (size$ | async) === 'navbar-hidden' }">
          <div class="topheader_wrappe">
            <marquee class="marquee-text"><a href="">{{"Pricing_key16" | translate: param}}</a>
            </marquee>
          </div>
          <nav class="navbar navbar-expand-xl navbar_container">
            <div class="nav_wrapper">
              <a href="https://www.expenseondemand.com/" class="navbar-brand">
                <img src="../../assets/images/logo.png" class="height-auto" alt="ExpenseOnDemand">
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas"
                aria-controls="navbarOffcanvas" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="offcanvas offcanvas-end bg-secondary" id="navbarOffcanvas" tabindex="-1"
                aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title text-light" id="offcanvasNavbarLabel">{{"Pricing_key15" | translate: param}}</h5>
                  <button type="button" class="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  <div class="navbar-nav justify-content-end align-items-center flex-grow-1 mt-0 ml-0">
                    <a class="nav-item nav-link active" aria-current="page" href="https://www.expenseondemand.com/pricing">{{"pricing" | translate: param}}</a>
                    <a class="nav-item nav-link" href="#">Features</a>
                    <a class="nav-item nav-link" href="https://www.expenseondemand.com/customer-benefits">{{"customers_import_success_note4" | translate: param}}</a>
                    <a class="nav-item nav-link" href="https://www.expenseondemand.com/partners">{{"partners" | translate: param}}</a>
                    <a class="nav-item nav-link" href="https://www.expenseondemand.com/about-us">{{"company" | translate: param}}</a>
                    <a class="nav-item nav-link" href="https://www.expenseondemand.com/expensify-alternative-contact-us">{{"pricing_footer_resources" | translate: param}}</a>
                    <a class="btn eod-btn-wb custom-eod-btn-wb mx-2 fw-600 hover_button" href="https://apps.expenseondemand.com/login">{{"login" | translate: param}}</a>
                    <a class="btn eod-btn-warning custom-eod-btn-warning fw-600 hover_button" href="https://www.expenseondemand.com/book-a-demo">{{"pricing_label_booknow" | translate: param}}</a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div class="d-flex justify-content-center py-4">
          <div class="container-full text-center">
            <h1 class="fw-700 mb-3">{{"pricing_flexi_plan" | translate: param}}</h1>
            <a class="btn eod-btn-primary custom-eod-btn-primary fw-700 mb-3 hover_button" href="https://apps.expenseondemand.com/register">{{"Pricing_key17" | translate: param}}</a>
            <p class="f-20 fw-600">{{"pricing_signup_message" | translate: param}}</p>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <div class="container pricing-calulator">
            <div class="row py-3 eod-bg-secondary rounded">
              <div class="col-md-9 d-grid">
                <div class="d-flex justify-content-between">
                  <h5><i class="fas fa-crown mr-2 eod-text-warning"></i>{{"ess_pack_text1" | translate: param}}<button type="button"
                      class="eod-text-warning bg-transparent " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i
                        class="fas fa-info-circle"></i></button></h5>
                  <h5>{{currencySymbol}}{{ accessAmount + essentialPackAmount |
                    number: "1.2-2" |
                    noComma}}</h5>
                </div>
                <div class="d-flex justify-content-between" style="border-bottom:1px solid #dfaa00">
                  <h5>Function(s) Selected<span
                      class="mustard-box px-2 ml-2">{{selectedFunctionPackageList.length}}</span></h5>
                  <h5>{{currencySymbol}}{{this.fnGetTotalCost() | number: "1.2-2" |
                    noComma}}</h5>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="mb-0">{{"invoice_total" | translate: param}}</h5>
                  <h5 class="mb-0">{{currencySymbol}}{{ essentialPackAmount + accessAmount +fnGetTotalCost() |
                    number: "1.2-2" | noComma }}</h5>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mustard-box p-2 mb-2 text-center">
                  <h3 class="mb-0 fw-600">{{ essentialPackAmount + accessAmount + this.fnGetTotalCost() |
                    number: "1.2-2" | noComma }}<sup class="pl-1">{{currencyCode}}</sup></h3>
                  <h6 class="mb-0 fw-600">{{"basic_price_note2" | translate: param}}</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn eod-btn-white mr-2 w-30pc"
                    (click)="resetSelectedFunction()" [disabled]="selectedFunctionPackageList.length === 0">{{"pricing_summary_reset" | translate: param}}</button>
                  <button class="btn eod-btn-primary w-65pc" data-bs-toggle="modal" data-bs-target="#function-modal2"
                    (click)="fnViewSummary()" [disabled]="selectedFunctionPackageList.length === 0">{{"pricing_discuss_quote" | translate: param}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="container ">
            <div class="sticky-position">
              <!-- Choose your Advanced Add-Ons  -->
              <div class="d-flex justify-content-between align-items-center mt-4 pt-3">
                <h4 class="mb-0 fw-700 f-20">{{"Pricing_key14" | translate: param}}</h4>
                <div>
                  <div class="search-container">
                    <i class="fal fa-search f-20 mt-1"></i>
                    <input type="search" maxlength="50" placeholder="Search Functions" class="border-right-0"
                      [(ngModel)]="searchText" (input)="searchTextKeypressEvent($event)"/>
  
                  </div>
                </div>
              </div>
              <!-- accordion -->
              <div class="accordion-parent">
                <div class="row">
                  <div class="col-md-11 d-flex justify-content-between">
                    <h5 class="mb-0">{{"pricing_function_name" | translate: param}}</h5>
                    <h5 class="mb-0">{{"price" | translate: param}}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" id="accordionExample" style="z-index: -15 !important;">
              <div class="accordion-item" *ngFor="let funCat of functionCategoryGroup; let i = index">
                <h2 class="accordion-header f-20 fw-20">
                  <button class="accordion-button collapsed" type="button" cla data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                    {{funCat.functionCategoryName}}
                  </button>
                </h2>
                <div [id]="'collapse'+i" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-0 pb-0"
                    *ngFor="let paidlist of funCat.functionPackage  | grdFilter : { websiteFunctionPackageName: searchText }">
                    <div class="row p-0 pb-3">
                      <div class="col-md-11 d-flex justify-content-between">
                        <h5 class="mb-0" data-toggle="modal" data-target=".function-modal"
                          (click)="fnShowFuncDetailPopup($event, paidlist)">{{paidlist.websiteFunctionPackageName}}</h5>
                          <h5 class="mb-0" (click)="fnShowFuncDetailPopup($event, paidlist)">
                            {{currencySymbol}}{{paidlist.functionAmount
                            | number: "1.2-2" | noComma}}
                          </h5>
                      </div>
                      <div class="col-md-1  d-flex justify-content-end">
                        <div class="form-check">
                          <input type="checkbox" id="formCheck1" class="form-check-input" [(ngModel)]="paidlist.selected"
                            (click)="fnUpdateSummary($event, paidlist)">
                          <label for="formCheck1" class="form-check-label">
  
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
      </div>
      <footer>
        <div class="container-full">
          <div class="row">
            <div class="col-md-3 footer-card">
              <p class="hero-subtext">{{"Pricing_key13" | translate: param}}</p>
              <div class="footer-social-wrapper"><a href="#" class="footer-social-link-block w-inline-block"><img
                    src="https://assets-global.website-files.com/653a83594795190d35b45ab8/653aa65ee814188de2b2977d_Facebook%20-%20Negative.svg"
                    loading="lazy" alt="" class="footer-social-icon"></a><a href="#"
                  class="footer-social-link-block w-inline-block"><img
                    src="https://assets-global.website-files.com/653a83594795190d35b45ab8/653aa65e0ab9b9e1413af76c_Instagram%20-%20Negative.svg"
                    loading="lazy" alt="" class="footer-social-icon"></a><a href="#"
                  class="footer-social-link-block w-inline-block"><img
                    src="https://assets-global.website-files.com/653a83594795190d35b45ab8/653aa65e2eb339d0790e6c9d_Twitter%20-%20Negative.svg"
                    loading="lazy" alt="" class="footer-social-icon"></a><a href="#"
                  class="footer-social-link-block w-inline-block"><img
                    src="https://assets-global.website-files.com/653a83594795190d35b45ab8/653aa65e27e2ffe3925d1939_LinkedIn%20-%20Negative.svg"
                    loading="lazy" alt="" class="footer-social-icon"></a><a href="#"
                  class="footer-social-link-block w-inline-block"><img
                    src="https://assets-global.website-files.com/653a83594795190d35b45ab8/653aa65e95bfbd2dcbe514e1_YouTube%20-%20Negative.svg"
                    loading="lazy" alt="" class="footer-social-icon"></a>
              </div>
              <div>{{"pricing_footer_register" | translate: param}}
                <br>{{"pricing_footer_registerGDPR" | translate: param}}
                <br>{{"pricing_footer_copyright" | translate: param}}
              </div>
            </div>
            <div class="col-md-3 footer-card">
              <p class="hero-subtext">{{"pricing_footer_company" | translate: param}}</p>
              <a href="/blog" class="body footer-text">{{"pricing_footer_blog" | translate: param}}</a>
              <a href="/about-us" class="body footer-text">{{"pricing_footer_aboutus" | translate: param}}</a>
              <a href="/giving-back" class="body footer-text">{{"Pricing_key12" | translate: param}}</a>
              <a href="/privacy-policy" class="body footer-text">{{"pricing_footer_Privacy" | translate: param}}</a>
              <a href="https://assets.website-files.com/653a83594795190d35b45ab8/65ae07531d4812f6cad90ffd_ExpenseOnDemand-SMB-TOS-January_2024_V7%5B1%5D.pdf"
                target="_blank" class="body footer-text">{{"pricing_footer_termsOfServcie" | translate: param}}</a>
            </div>
            <div class="col-md-3 footer-card">
              <p class="hero-subtext">{{"pricing_footer_important" | translate: param}}</p>
              <a href="/privacy-policy" class="body footer-text">Security</a>
              <a href="https://assets-global.website-files.com/654a0641c3234a8260a9d030/654a0641c3234a8260a9d1cc_Anti%20Bribery%20and%20Anti%20Corruption%20Policy.pdf"
                class="body footer-text">{{"Pricing_key11" | translate: param}}</a>
              <a href="https://assets-global.website-files.com/654a0641c3234a8260a9d030/654a0641c3234a8260a9d1ca_Anti-Slavery%20And%20Human%20Trafficking%20Policy.pdf"
                class="body footer-text">{{"Pricing_key10" | translate: param}}</a>
              <a href="https://apps.expenseondemand.com/register" class="body footer-text">{{"Pricing_key9" | translate: param}}</a>
              <a href="/press" class="body footer-text">{{"Pricing_key8" | translate: param}}</a>
            </div>
            <div class="col-md-3 footer-card">
              <p class="hero-subtext">{{"Pricing_key7" | translate: param}}</p>
              <a href="http://www.expenseondemand.com/blog-posts/pleo-vs-expensify" class="body footer-text">{{"Pricing_key6" | translate: param}}</a>
              <a href="http://www.expenseondemand.com/blog-posts/concur-vs-quickbooks" class="body footer-text">{{"Pricing_key5" | translate: param}}</a>
              <a href="http://www.expenseondemand.com/blog-posts/expensify-vs-concur" class="body footer-text">{{"Pricing_key4" | translate: param}}</a>
              <a href="http://www.expenseondemand.com/blog-posts/payhawk-vs-pleo" class="body footer-text">{{"Pricing_key3" | translate: param}}o</a>
              <a href="http://www.expenseondemand.com/blog-posts/zoho-expense-vs-expensify"
                class="body footer-text">{{"Pricing_key2" | translate: param}}</a>
            </div>
          </div>
        </div>
      </footer>
      
        <info-model-popup *ngIf="infoPopupVisible" [(infoModel)]="infoModel" (closePanelEvent)="fnCloseInfoPopup()"
          (btnClickEvent)="onInfoPanelBtnClick($event)">
        </info-model-popup>
        
        <div class="takeFreeTrial d-none" style="opacity: 1;" *ngIf="isFreeTrialBoxDisplay">
          <a href="https://apps.expenseondemand.com/login" target="_blank" class="text-decoration-none">
            <h2>{{"take_a" | translate: param}} <span class="color-white fw-700">{{"free" | translate:
                            param}}</span>
              <span class="d-block">{{"30_day_trial" | translate:
                            param}}</span>
            </h2>
          </a>
        </div>
      </div>
    </div> 
  </div>
  </ng-scrollbar>
  
  <!-- Modal -->
  <div class="modal fade" style="z-index: 10;" id="staticBackdrop" data-bs-backdrop="static"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content p-0 h-100pc">
      <div class="modal-header align-items-center eod-bg-secondary py-4">
        <h5 class="mb-0 f-22 fw-700" id="staticBackdropLabel"><i
            class="fas fa-crown mr-2 eod-text-warning"></i>{{"Pricing_key1" | translate:
            param}}</h5>
        <button type="button" class="eod-text-white bg-transparent f-20" data-bs-dismiss="modal"
          aria-label="Close"><i class="fal fa-times"></i></button>
      </div>
      <div class="modal-body p-4 overflow-y-auto overflow-x-hidden heightCalc-7">
        <div  class="m-body-content">
          <div class="mb-2" *ngFor="let freeFunction of functionPackageName"> 
            <h5 class="fw-600 eod-text-primary">{{freeFunction.functionPackageName}}</h5>
            <ul class="big-blue-disc w-100pc" style="display: table;">
              <li class="pb-1 mb-0 d-flex align-items-starts">
                <span class="d-inline-block w-2pc"><i aria-hidden="true"
                    class="fas fa-circle f-10 text-logo-blue position-relative top--1px"></i></span>
                <span class="d-inline-block w-98pc padding-left-5">
                  {{freeFunction.essentialPackDescripition}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  <pricing-summary [hidden]="!pricingSummaryVisible" [(selectedFunctionCategory)]="functionCategoryGroup"
          [(currencyCode)]="currencyCode" [(currencySymbol)]="currencySymbol"
          (removeFunPackageEvent)="fnRemoveFunPackage($event)" (raisePricingQuoteEvent)="emailPricingQuote($event)"
          [(accessChargeFunction)]="accessChargeFunction" [(essentialPack)]="essentialPackAmount" [(accessCharge)]="accessAmount">
        </pricing-summary>