import { Injectable, forwardRef, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiresponse } from '../../_models/common/request-response/apiresponse';
import { IStripeSessionResponse, IStripeSessionRequest, IStripeGenerateInvoiceRequest, IStripeRegisterUserResponse, IStripeChangeUserCardRequest, IStripeInstantPaymentSessionRequest, IStripeInstantPaymentRegisterUserResponse } from '../../_models/stripe/stripe';
import { IApiRequest } from 'src/app/_models';
import { setHeader } from 'src/app/_helpers/setRequestHeader';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  orgSettingUrl: any;
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }

  CreateStripeSession(
    requestInfo: IApiRequest<IStripeSessionRequest>
  ): Observable<IApiresponse<IStripeSessionResponse>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<IStripeSessionResponse>>(
      `${environment.billingApiUrl}CreateStripeSession`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  RegisterUserCardOnStripe(sessionId: string): Observable<IApiresponse<IStripeRegisterUserResponse>> {
    let params = new HttpParams();
    params = params.append("sessionId", sessionId);

    return this.http.get<IApiresponse<IStripeRegisterUserResponse>>(`${environment.billingApiUrl}RegisterUserOnStripe`, { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GenerateInvoice(
    requestInfo: IApiRequest<IStripeGenerateInvoiceRequest>
  ): Observable<IApiresponse<string>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<string>>(
      `${environment.billingApiUrl}GenerateStripeInvoice`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ChangeStripeUserCard(
    requestInfo: IApiRequest<IStripeChangeUserCardRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}ChangeStripeUserCard`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CreateStripeSessionForInstantPayment(
    requestInfo: IApiRequest<IStripeInstantPaymentSessionRequest>
  ): Observable<IApiresponse<IStripeSessionResponse>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<IStripeSessionResponse>>(
      `${environment.billingApiUrl}CreateStripeSessionForInstantPayment`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetStripeCustomerDetailsForInstantPaymentCaptured(sessionId: string): Observable<IApiresponse<IStripeInstantPaymentRegisterUserResponse>> {
    let params = new HttpParams();
    params = params.append("sessionId", sessionId);

    return this.http.get<IApiresponse<IStripeInstantPaymentRegisterUserResponse>>(`${environment.billingApiUrl}GetStripeCustomerDetailsForInstantPaymentCaptured`, { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  CreateStripeSessionForExistingCustomer(
    requestInfo: IApiRequest<IStripeSessionRequest>
  ): Observable<IApiresponse<IStripeSessionResponse>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<IStripeSessionResponse>>(
      `${environment.billingApiUrl}CreateStripeSessionForExistingCustomer`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
