import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseCategoryTypeEnum } from 'src/app/_models';
import { IManageCategoryListResponse } from 'src/app/_models/manage-category/manage-category-list-response';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-category-view',
    templateUrl: "./formly-category-view.component.html"
})

export class FormlyCategoryViewComponent extends FieldType implements OnInit, OnDestroy {
    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    valueChangeSubscription: Subscription;
    baseCategoryNameModel: string;
    baseCategoryId: number;
    baseCategoryTypeEnum = BaseCategoryTypeEnum;
    orgCategoryListByBaseCatId = <any[]>[];
    isActiveCategoryForTemplate: boolean;

    constructor(private dynamicFormService: DynamicFormService, private translate: TranslateService
        ,private cdr:ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this.to.options && this.to.options[0] && this.to.options[0].categoryDetail) {
            this.baseCategoryId = Number(this.to.options[0].categoryDetail.baseCategoryId);
            this.fnGetTemplateName();
            this.fnOpenTemplateDiv();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
        this.valueChangeSubscription = this.dynamicFormService.getValueChangeEvent().subscribe(data => {
            this.baseCategoryId = data;
            this.fnGetTemplateName();
            this.fnOpenTemplateDiv();
            this.cdr.detectChanges();
        });
    }
    fnGetTemplateName() {
        switch (this.baseCategoryId) {
            case this.baseCategoryTypeEnum.Entertainment:
                this.baseCategoryNameModel = this.translate.instant("Entertainment");
                break;
            case this.baseCategoryTypeEnum.Generic:
                this.baseCategoryNameModel = this.translate.instant("standard_category");
                break;
            case this.baseCategoryTypeEnum.Hotel:
                this.baseCategoryNameModel = this.translate.instant("HotelandLodgings");
                break;
            case this.baseCategoryTypeEnum.Mileage:
                this.baseCategoryNameModel = this.translate.instant("Mileage");
                break;
            case this.baseCategoryTypeEnum.MileageGPS:
                this.baseCategoryNameModel = this.translate.instant("mileage_gps");
                break;
            case this.baseCategoryTypeEnum.MileageGoogle:
                this.baseCategoryNameModel = this.translate.instant("mileage_google");
                break;
            case this.baseCategoryTypeEnum.NoReceiptCategory:
                this.baseCategoryNameModel = this.translate.instant("receipt_type_text9");
                break;
        }
    }
    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange(selectedValue) {
        this.formControl.setValue(selectedValue);
        this.to.change(this.field, this.formControl.value);

    }

    fnOpenTemplateDiv() {
        this.orgCategoryListByBaseCatId = [];
        let manageCategoryList = [];
        if (this.to.options && this.to.options[0] && this.to.options[0].categoryDetail && this.to.options[0].manageCategoryView) {
            manageCategoryList = this.to.options[0].manageCategoryList
        }
        this.orgCategoryListByBaseCatId = manageCategoryList.filter(a => a.baseCategoryId == this.baseCategoryId && a.isActive == true);
    }
    fnCloseTemplateDiv() {
        this.orgCategoryListByBaseCatId = <any[]>[];
        this.isActiveCategoryForTemplate = false;
    }


}