<svg #NewCategory *ngIf="expenseCategoryId==0" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <path d="M16.6,30.2v1.5c0,1.4,1.5,2.6,3.3,2.6h10.3c1.9,0,3.3-1.1,3.3-2.4v-1.4c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1V32
	c-0.1,0.1-0.5,0.4-1.3,0.4H20c-0.9,0-1.3-0.4-1.3-0.5v-1.5c0-0.6-0.5-1-1-1C17.1,29.2,16.6,29.6,16.6,30.2 M27.8,19.8
	c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2H33c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H27.8z M18.1,18.8v1h-1.4
	c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h1.4v0.9c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2v-0.9h1c0.6,0,1.2-0.5,1.2-1.2
	c0-0.6-0.5-1.2-1.2-1.2h-1v-1c0-0.6-0.5-1.2-1.2-1.2C18.6,17.5,18.1,18,18.1,18.8 M11.8,39.1V8.9c0-1,0.8-1.9,1.9-1.9h16.7l0.1,0.1
	v7.4h7.8V39c0,1-0.8,1.9-1.9,1.9h-8h-0.1c-0.7-0.1-1.4,0.1-1.9,0.6l-1.6,1.6l-1.9-1.7c-0.5-0.4-1-0.6-1.6-0.6c-0.1,0-0.2,0-0.3,0
	h-7.3C12.6,41,11.8,40.2,11.8,39.1 M13.6,4.2C10,4.2,8.8,5.5,8.8,9v30.1c0,3.5,1.3,4.8,4.8,4.8h6.9l2.9,2.5c0.4,0.4,1,0.6,1.5,0.6
	c0.6,0,1.2-0.2,1.6-0.7l2.4-2.4h7.4c3.5,0,4.8-1.3,4.8-4.8V13.4l0,0c0-0.1,0-0.2,0-0.3s0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2
	c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2L40.6,12c-0.1-0.1-0.1-0.1-0.2-0.2l0,0l-7.6-7.1l-0.1-0.1
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
	c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0L13.6,4.2L13.6,4.2z" />

</svg>

<svg #AirTravel *ngIf="expenseCategoryId==1" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <path d="M47.3,6.7l-0.1-0.8c-0.2-1.7-1.6-3-3.3-3.1h-0.7c-2.1-0.1-4.1,0.7-5.6,2.2l-6.9,6.8H8.1c-1.9,0-3.7,0.8-5.1,2.2l-0.9,0.9
	c-0.3,0.3-0.4,0.8-0.3,1.3c0.1,0.4,0.4,0.8,0.9,0.9l18,4.9l-4.2,4l-7.6,0.3c-0.8,0-1.5,0.3-2.1,0.9l-0.7,0.7
	c-0.3,0.3-0.4,0.8-0.3,1.2c0.1,0.4,0.4,0.7,0.8,0.9l9.6,3.7l3.9,10c0.2,0.4,0.6,0.7,1,0.8c0.4,0,0.8-0.1,1.1-0.4l0.8-0.8
	c0.5-0.5,0.8-1.2,0.9-2.1l0.3-7.6l4.2-4.1l4.9,18c0.1,0.4,0.5,0.8,0.9,0.9c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,0.9-0.4l0.9-0.9
	c1.4-1.4,2.2-3.2,2.2-5.2V19.3l6.7-6.7C46.8,11,47.6,8.8,47.3,6.7L47.3,6.7z M44,11.4l-7,7c-0.2,0.2-0.3,0.4-0.3,0.6v22.9
	c0,1.5-0.6,2.9-1.6,4l-0.4,0.4l-5.1-18.7c-0.1-0.3-0.3-0.5-0.6-0.6c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.3l-5.5,5.4
	c-0.2,0.2-0.3,0.3-0.3,0.6l-0.3,7.9c0,0.3-0.1,0.6-0.4,0.9l-0.3,0.3l-3.7-9.7c-0.1-0.2-0.3-0.4-0.5-0.5l-9.4-3.6l0.3-0.3
	C8.2,28.2,8.6,28,9,28l8-0.3c0.2,0,0.4-0.1,0.6-0.3L23,22c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.3-0.5-0.6-0.6L3.9,15.4L4.3,15
	c1-1,2.5-1.6,3.9-1.6h22.9c0.2,0,0.4-0.1,0.6-0.3L38.9,6c1.1-1.1,2.7-1.7,4.3-1.6h0.7c0.9,0,1.6,0.7,1.6,1.6l0.1,0.8
	C45.8,8.5,45.2,10.1,44,11.4L44,11.4z" />

</svg>

<svg #BooksPublications *ngIf="expenseCategoryId==2" [ngClass]="this.className" version="1.1"
    id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="50px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"
    class="svg-30">
    <g>
        <path
            d="M15,1.4c-2.9,0-5.3,2.4-5.3,5.3V44c0,2.5,2.1,4.6,4.6,4.6h27.4V1.4H15z M29.4,2.9H34v6.8L31.7,8l-2.3,1.7
		C29.4,9.7,29.4,2.9,29.4,2.9z M11.1,6.7c0-2.1,1.7-3.8,3.8-3.8h2.3v36.5h-3c-1.2,0-2.2,0.4-3.1,1.2V6.7z M40.1,43.2H14.2v1.5h25.9
		V47H14.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3,3.1-3h25.9V43.2z M40.1,39.4H18.8V2.9h9.1v9.9l3.8-2.8l3.8,2.8V2.9h4.6V39.4z" />

    </g>
</svg>

<svg #FoodAndDrink *ngIf="expenseCategoryId==3" [ngClass]="this.className" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50"
    style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30" [ngClass]="this.className">
    <g>
        <path
            d="M41.8,37.8L8,4C7.8,3.8,7.5,3.7,7.2,3.7c-0.3,0-0.6,0.2-0.8,0.4C6.3,4.2,4.8,6.1,4.7,9.2c-0.1,4,1.9,8.2,6.2,12.5l8.5,8.4   c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.7-1.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.2,0.1l1.4,1.4c0.1,0,0,0.2,0,0.3   c-0.7,1.5-0.4,3.2,0.8,4.3l8.1,8.1c0.9,0.9,2,1.3,3.2,1.3c1.2,0,2.3-0.4,3.2-1.3C43.5,42.4,43.5,39.5,41.8,37.8L41.8,37.8z    M40.2,42.6c-0.9,0.9-2.3,0.9-3.2,0l-8.1-8.1c-0.5-0.5-0.6-1.2-0.3-1.8c0.5-1,0.3-2.1-0.4-2.8l-1.4-1.4c-0.5-0.5-1.1-0.7-1.8-0.7   c-0.7,0-1.3,0.3-1.8,0.7C22.9,28.8,22.5,29,22,29c-0.4,0-0.8-0.2-1.1-0.5l-8.4-8.4c-6.5-6.5-5.8-11.3-5-13.4l32.7,32.7   C41.1,40.2,41.1,41.7,40.2,42.6L40.2,42.6z M40.2,42.6" />
        <path
            d="M42.8,6.4c-0.4-0.4-1.1-0.4-1.6,0L34,13.5c-0.4,0.4-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l7.2-7.2   C43.2,7.5,43.2,6.8,42.8,6.4L42.8,6.4z M42.8,6.4" />
        <path
            d="M39.7,2.4c-0.4-0.4-1.1-0.4-1.6,0l-7.3,7.3c-1.1,1.1-1.8,2.5-1.8,3.9l0,2.1l-2,2c-0.4,0.4-0.4,1.2,0,1.6   c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l2.4-2.4c0.2-0.2,0.3-0.5,0.3-0.8l0.1-2.5c0-0.8,0.5-1.7,1.2-2.4L39.7,4   C40.2,3.5,40.2,2.8,39.7,2.4L39.7,2.4z M39.7,2.4" />
        <path
            d="M20.5,33.5c-0.4-0.4-1.2-0.4-1.6,0l-9,9c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6   c0-0.6,0.2-1.2,0.7-1.6l8.7-8.7c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.2-0.4-1.6,0l-8.7,8.7c-0.9,0.9-1.3,2-1.3,3.2   c0,1.2,0.5,2.4,1.3,3.2c0.9,0.9,2,1.3,3.2,1.3c1.2,0,2.4-0.5,3.2-1.3l9-9C20.9,34.6,20.9,33.9,20.5,33.5L20.5,33.5z M20.5,33.5" />
        <path
            d="M46.9,9.6c-0.4-0.4-1.2-0.4-1.6,0L38,16.9c-0.7,0.7-1.6,1.1-2.4,1.1L33,18c-0.3,0-0.6,0.1-0.8,0.3l-2.4,2.4   c-0.4,0.4-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l2-2l2.2,0c1.4,0,2.9-0.6,4-1.7l7.3-7.3   C47.4,10.7,47.4,10,46.9,9.6L46.9,9.6z M46.9,9.6" />
    </g>
</svg>

<svg #Hotel *ngIf="expenseCategoryId==4" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <g transform="translate(1 1)">
        <g>
            <path
                d="M44.2,40.6h-6.1v-7.5H9.5v7.5H3.4V9.3h6.1V27h34.7V40.6z M39.4,39.3h3.4V28.4H8.1V10.7H4.7v28.6h3.4v-7.5h31.3V39.3z" />

        </g>
        <g>
            <path
                d="M44.2,28.4H21.7V16.8H34c5.4,0,10.2,5.1,10.2,10.9V28.4z M23.1,27h19.7c-0.3-4.8-4.4-8.8-8.8-8.8H23.1V27z" />

        </g>
        <g>
            <path d="M15.6,25.7c-2.7,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8S18.3,25.7,15.6,25.7z M15.6,17.5
			c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4S17.5,17.5,15.6,17.5z" />

        </g>
    </g>
</svg>

<svg #Mileage *ngIf="expenseCategoryId==5" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <g>
        <path d="M12.9,39.5c-6.6-6.6-6.6-17.2,0-23.7c3.3-3.3,7.6-4.9,11.8-4.9c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h0v0
		c-4.5,0-8.9,1.7-12.3,5.1c-6.8,6.8-6.8,17.9,0,24.7c0.1,0.1,0.4,0.1,0.5,0S13,39.7,12.9,39.5L12.9,39.5z" />

        <path d="M35.7,16.3c-0.2,0-0.3,0.1-0.5,0.2l-8.6,8.6c-0.5-0.4-1.2-0.6-1.9-0.6c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4
		s3.4-1.5,3.4-3.4c0-0.7-0.2-1.3-0.6-1.9l8.6-8.6C36.6,17.1,36.3,16.3,35.7,16.3z" />

        <path d="M38.8,13.9c-3.9-3.9-8.9-5.8-14-5.8s-10.2,1.9-14,5.8C3,21.7,3,34.3,10.7,42c0.3,0.3,0.7,0.3,1,0s0.3-0.7,0-1
		c-7.2-7.2-7.2-18.9,0-26.1c7.2-7.2,18.9-7.2,26.1,0c7.2,7.2,7.2,18.9,0,26.1c-0.3,0.3-0.3,0.7,0,1s0.7,0.3,1,0
		C46.5,34.3,46.5,21.7,38.8,13.9z" />

    </g>
</svg>

<svg #MileageGoogle *ngIf="expenseCategoryId==6" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M25.2,19.8c2,0,3.7-1.7,3.7-3.7s-1.7-3.7-3.7-3.7s-3.7,1.7-3.7,3.7S23.2,19.8,25.2,19.8z" />

        <path class="st0" d="M25.1,40.1l10.2-14.8c3.8-5.1,3.3-13.6-1.2-18.1c-2.4-2.4-5.6-3.7-9-3.7c-3.4,0-6.6,1.3-9,3.7
		c-4.5,4.5-5.1,12.9-1.2,18.1l2.9,4.2L25.1,40.1z M25.2,10.9c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2
		C20,13.2,22.3,10.9,25.2,10.9z" />

        <polygon class="st0" points="38.6,31 33.2,31 25.1,42.7 17,31 11.3,31 4.6,45.1 45.3,45.1 	" />

        <path d="M25.2,21.3c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2c-2.9,0-5.2,2.3-5.2,5.2C20,19,22.3,21.3,25.2,21.3z M25.2,12.4
		c2,0,3.7,1.7,3.7,3.7s-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7S23.2,12.4,25.2,12.4z" />

        <path d="M39.5,29.5h-5.3l2.3-3.3c4.3-5.7,3.7-15.1-1.3-20C32.5,3.5,28.9,2,25.1,2C21.3,2,17.7,3.5,15,6.2c-5,5-5.6,14.3-1.3,20
		l2.3,3.3h-5.6L2.2,46.6h45.4L39.5,29.5z M14.9,25.3c-3.9-5.2-3.3-13.6,1.2-18.1c2.4-2.4,5.6-3.7,9-3.7c3.4,0,6.6,1.3,9,3.7
		c4.5,4.5,5,13,1.2,18.1L25.1,40.1l-7.3-10.6L14.9,25.3z M11.3,31H17l8.1,11.7L33.2,31h5.4l6.7,14.1H4.6L11.3,31z" />

    </g>
</svg>

<svg #Mileage_auto_tracker_gps *ngIf="expenseCategoryId==7" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0"
            d="M23.9,15.4c-2,0-3.7,1.7-3.7,3.7s1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7C27.6,17.1,26,15.4,23.9,15.4z" />

        <path class="st0" d="M32.8,10.2c-2.4-2.4-5.6-3.7-9-3.7c-3.4,0-6.6,1.3-9,3.7c-4.5,4.5-5.1,13-1.2,18.1l10.2,14.8L34,28.3
		C37.9,23.2,37.3,14.7,32.8,10.2z M23.9,24.3c-2.8,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2
		C29.1,22,26.8,24.3,23.9,24.3z" />

        <path d="M23.9,13.9c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.4,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2C29.1,16.2,26.8,13.9,23.9,13.9z
		 M23.9,22.8c-2.1,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7c2.1,0,3.7,1.7,3.7,3.7C27.6,21.2,25.9,22.8,23.9,22.8z" />

        <path d="M33.9,9.1C31.2,6.5,27.6,5,23.8,5s-7.4,1.5-10.1,4.2c-5,5-5.6,14.4-1.3,20l11.4,16.5l11.4-16.5
		C39.5,23.5,38.9,14.1,33.9,9.1z M34,28.3L23.8,43.1L13.6,28.3c-3.9-5.1-3.3-13.6,1.2-18.1c2.4-2.4,5.6-3.7,9-3.7
		c3.4,0,6.6,1.3,9,3.7C37.3,14.7,37.9,23.2,34,28.3z" />

    </g>
</svg>

<svg #Miscelleanous *ngIf="expenseCategoryId==8" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M24.5,6.3c-10.1,0-18.4,8.2-18.4,18.4S14.4,43,24.5,43s18.4-8.2,18.4-18.4C42.9,14.4,34.6,6.3,24.5,6.3z
		 M19.5,28.9h-1.8v-2.3l0.2-4L15.6,29h-1.2L12,22.6l0.2,4v2.3h-1.8v-8.5h2.4l2.2,6.2l2.2-6.2h2.3V28.9z M22.8,28.9H21v-8.5h1.8V28.9
		z M26.3,23.4c0.3,0.2,0.7,0.4,1.2,0.5c1,0.3,1.7,0.7,2.2,1.1s0.7,1,0.7,1.7s-0.3,1.3-0.8,1.7c-0.5,0.4-1.3,0.6-2.3,0.6
		c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.3-1-0.6-1.2-1c-0.2-0.4-0.4-0.9-0.4-1.5h1.8c0,0.9,0.6,1.4,1.7,1.4c0.4,0,0.7-0.1,1-0.3
		s0.5-0.2,0.5-0.5s-0.1-0.6-0.4-0.8c-0.3-0.2-0.7-0.4-1.3-0.6c-0.6-0.2-1.1-0.4-1.4-0.6c-1-0.5-1.5-1.2-1.5-2.1
		c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.6,1.1-0.8c0.5-0.2,1-0.3,1.6-0.3c0.6,0,1.2,0.1,1.6,0.3c0.4,0.2,0.8,0.5,1.1,0.9
		c0.3,0.4,0.4,0.9,0.4,1.4h-1.8c0-0.4-0.1-0.7-0.4-0.9s-0.6-0.3-1-0.3c-0.4,0-0.8,0.1-1,0.3s-0.4,0.4-0.4,0.7S26,23.2,26.3,23.4z
		 M38.5,26.1c-0.1,0.9-0.4,1.6-1,2.2c-0.6,0.6-1.4,0.8-2.4,0.8c-1.1,0-2-0.4-2.6-1.1s-0.9-1.7-0.9-3v-0.5c0-0.8,0.1-1.5,0.4-2.2
		s0.7-1.1,1.2-1.4c0.5-0.3,1.2-0.5,1.9-0.5c1,0,1.8,0.3,2.4,0.8c0.6,0.5,0.9,1.3,1,2.2h-1.8c0-0.6-0.2-1-0.5-1.2s-0.7-0.4-1.2-0.4
		c-0.6,0-1,0.2-1.3,0.6c-0.3,0.4-0.4,1.1-0.4,2V25c0,0.9,0.1,1.6,0.4,2s0.7,0.6,1.3,0.6c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.5-1.2
		L38.5,26.1L38.5,26.1z" />

        <path d="M24.5,4.3c-11.3,0-20.4,9.2-20.4,20.4S13.3,45,24.5,45s20.4-9.1,20.4-20.3C44.9,13.4,35.7,4.3,24.5,4.3z M24.5,43
		C14.4,43,6.1,34.9,6.1,24.7S14.4,6.3,24.5,6.3s18.4,8.1,18.4,18.3C42.9,34.8,34.6,43,24.5,43z" />

        <polygon points="15,26.6 12.8,20.4 10.4,20.4 10.4,28.9 12.2,28.9 12.2,26.6 12,22.6 14.4,29 15.6,29 17.9,22.6 17.7,26.6
		17.7,28.9 19.5,28.9 19.5,20.4 17.2,20.4 	" />

        <rect x="21" y="20.4" width="1.8" height="8.5" />

        <path
            d="M27.3,21.7c0.4,0,0.7,0.1,1,0.3s0.4,0.5,0.4,0.9h1.8c0-0.5-0.1-1-0.4-1.4c-0.3-0.4-0.7-0.7-1.1-0.9c-0.4-0.2-1-0.3-1.6-0.3
		c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.4,0.7-0.4,1.2c0,0.9,0.5,1.6,1.5,2.1c0.3,0.2,0.8,0.4,1.4,0.6
		c0.6,0.2,1,0.4,1.3,0.6c0.3,0.2,0.4,0.5,0.4,0.8s-0.2,0.3-0.5,0.5s-0.6,0.3-1,0.3c-1.1,0-1.7-0.5-1.7-1.4h-1.8
		c0,0.6,0.2,1.1,0.4,1.5c0.2,0.4,0.7,0.7,1.2,1c0.5,0.3,1.1,0.4,1.8,0.4c1,0,1.8-0.2,2.3-0.6c0.5-0.4,0.8-1,0.8-1.7
		s-0.2-1.3-0.7-1.7s-1.2-0.8-2.2-1.1c-0.5-0.1-0.9-0.3-1.2-0.5c-0.3-0.2-0.4-0.4-0.4-0.7s0.2-0.5,0.4-0.7S26.9,21.7,27.3,21.7z" />

        <path d="M36.2,27.2c-0.3,0.3-0.7,0.4-1.2,0.4c-0.6,0-1-0.2-1.3-0.6s-0.4-1.1-0.4-2v-0.6c0-0.9,0.1-1.6,0.4-2
		c0.3-0.4,0.7-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.4s0.5,0.6,0.5,1.2h1.8c-0.1-0.9-0.4-1.7-1-2.2c-0.6-0.5-1.4-0.8-2.4-0.8
		c-0.7,0-1.4,0.2-1.9,0.5c-0.5,0.3-0.9,0.7-1.2,1.4s-0.4,1.4-0.4,2.2V25c0,1.3,0.3,2.3,0.9,3s1.5,1.1,2.6,1.1c1,0,1.8-0.2,2.4-0.8
		c0.6-0.6,0.9-1.3,1-2.2V26h-1.8C36.6,26.6,36.5,26.9,36.2,27.2z" />

    </g>
</svg>

<svg #OfficeRent *ngIf="expenseCategoryId==9" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <rect x="19.2" y="27.4" class="st0" width="2.3" height="2.3" />

        <rect x="19.2" y="34.4" class="st0" width="2.3" height="2.3" />

        <rect x="19.2" y="20.4" class="st0" width="2.3" height="2.3" />

        <polygon class="st0" points="8.6,41.4 41.4,41.4 41.5,41.4 41.5,40.2 8.6,40.2 	" />

        <rect x="19.2" y="13.3" class="st0" width="2.3" height="2.3" />

        <path class="st0" d="M23.2,15.6H25v-4.7H9.7V39h14.1V19.1h-0.6c-0.3,0-0.6-0.3-0.6-0.6v-2c-0.1,0.2-0.3,0.3-0.5,0.3h-3.5
		c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6v3.2C22.8,15.7,23,15.6,23.2,15.6z M16.8,37.3
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V37.3z M16.8,30.3
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.2,0.6,0.6V30.3z M16.8,23.2
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V23.2z M16.8,16.2
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V16.2z M22.7,37.3
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V37.3z M22.7,30.3
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.2,0.6,0.6V30.3z M18.6,19.1h3.5
		c0.3,0,0.6,0.3,0.6,0.6v3.5c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5C18,19.4,18.3,19.1,18.6,19.1z" />

        <rect x="13.3" y="13.3" class="st0" width="2.3" height="2.3" />

        <rect x="13.3" y="20.4" class="st0" width="2.3" height="2.3" />

        <rect x="8.6" y="8.6" class="st0" width="17.6" height="1.2" />

        <rect x="13.3" y="27.4" class="st0" width="2.3" height="2.3" />

        <rect x="13.3" y="34.4" class="st0" width="2.3" height="2.3" />

        <rect x="34.4" y="21.5" class="st0" width="2.3" height="2.3" />

        <rect x="23.8" y="16.8" class="st0" width="17.6" height="1.2" />

        <rect x="30.3" y="33.2" class="st0" width="4.7" height="5.9" />

        <path class="st0" d="M40.3,39.1v-20H25v20h4.1v-6.5c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v6.5H40.3z M33.2,20.9
		c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6v3.5c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6V20.9z M32,30.3
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V30.3z M32,24.4
		c0,0.3-0.3,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6V24.4z M33.8,30.9
		c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h3.5c0.3,0,0.6,0.3,0.6,0.6v3.5c0,0.3-0.3,0.6-0.6,0.6H33.8z" />

        <rect x="34.4" y="27.3" class="st0" width="2.3" height="2.3" />

        <rect x="28.5" y="21.5" class="st0" width="2.3" height="2.3" />

        <rect x="28.5" y="27.3" class="st0" width="2.3" height="2.3" />

        <path d="M42,19.1c0.3,0,0.6-0.3,0.6-0.6v-2.3c0-0.3-0.3-0.6-0.6-0.6H26.2v-4.7h0.6c0.3,0,0.6-0.3,0.6-0.6V8c0-0.3-0.3-0.6-0.6-0.6
		H8C7.7,7.4,7.4,7.7,7.4,8v2.3c0,0.3,0.3,0.6,0.6,0.6h0.6v28.2H8c-0.3,0-0.6,0.3-0.6,0.6V42c0,0.3,0.3,0.6,0.6,0.6h34
		c0.3,0,0.6-0.3,0.6-0.6v-2.3c0-0.3-0.3-0.6-0.6-0.6h-0.6v-20H42z M8.6,8.6h17.6v1.2H8.6V8.6z M22.1,12.1h-3.5
		c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.2,0,0.4-0.1,0.5-0.3v2c0,0.3,0.3,0.6,0.6,0.6h0.6V39H9.7V10.9H25v4.7
		h-1.8c-0.2,0-0.4,0.1-0.5,0.3v-3.2C22.7,12.4,22.4,12.1,22.1,12.1z M21.5,13.3v2.3h-2.3v-2.3H21.5z M41.5,40.2v1.2h-0.1H8.6v-1.2
		H41.5z M35.6,32h-5.9c-0.3,0-0.6,0.3-0.6,0.6v6.5H25v-20h15.3v20h-4.1v-6.5C36.2,32.3,35.9,32,35.6,32z M35,33.2v5.9h-4.7v-5.9H35z
		 M23.8,18v-1.2h17.6V18H23.8z" />

        <path d="M16.2,12.1h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C16.8,12.4,16.5,12.1,16.2,12.1z M15.6,15.6h-2.3v-2.3h2.3V15.6z" />

        <path d="M16.2,19.1h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C16.8,19.4,16.5,19.1,16.2,19.1z M15.6,22.7h-2.3v-2.3h2.3V22.7z" />

        <path d="M18,23.2c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5c0-0.3-0.3-0.6-0.6-0.6h-3.5c-0.3,0-0.6,0.3-0.6,0.6V23.2z
		 M19.2,20.4h2.3v2.3h-2.3V20.4z" />

        <path d="M16.2,26.2h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C16.8,26.4,16.5,26.2,16.2,26.2z M15.6,29.7h-2.3v-2.3h2.3V29.7z" />

        <path d="M22.1,26.2h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C22.7,26.4,22.4,26.2,22.1,26.2z M21.5,29.7h-2.3v-2.3h2.3V29.7z" />

        <path d="M16.2,33.2h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C16.8,33.5,16.5,33.2,16.2,33.2z M15.6,36.7h-2.3v-2.3h2.3V36.7z" />

        <path d="M22.1,33.2h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C22.7,33.5,22.4,33.2,22.1,33.2z M21.5,36.7h-2.3v-2.3h2.3V36.7z" />

        <path d="M31.4,20.3h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C32,20.6,31.7,20.3,31.4,20.3z M30.8,23.8h-2.3v-2.3h2.3V23.8z" />

        <path d="M33.8,25h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5c0-0.3-0.3-0.6-0.6-0.6h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5
		C33.2,24.7,33.5,25,33.8,25z M34.4,21.5h2.3v2.3h-2.3V21.5z" />

        <path d="M31.4,26.2h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5c0.3,0,0.6-0.3,0.6-0.6v-3.5
		C32,26.5,31.7,26.2,31.4,26.2z M30.8,29.6h-2.3v-2.3h2.3V29.6z" />

        <path d="M37.9,30.3v-3.5c0-0.3-0.3-0.6-0.6-0.6h-3.5c-0.3,0-0.6,0.3-0.6,0.6v3.5c0,0.3,0.3,0.6,0.6,0.6h3.5
		C37.6,30.9,37.9,30.6,37.9,30.3z M36.7,29.6h-2.3v-2.3h2.3V29.6z" />

    </g>
</svg>

<svg #Parking *ngIf="expenseCategoryId==10" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M24.5,6.3c-10.1,0-18.4,8.2-18.4,18.4S14.4,43,24.5,43s18.4-8.2,18.4-18.4C42.9,14.4,34.6,6.3,24.5,6.3z
		 M29.4,25.7c-1.1,1-2.7,1.4-4.8,1.4h-3.1v0.1v6H18V16.1h6.7c1.3,0,2.4,0.2,3.4,0.7s1.7,1.1,2.2,2c0.5,0.9,0.8,1.8,0.8,2.9
		C31.1,23.4,30.5,24.7,29.4,25.7z" />

        <path class="st0"
            d="M24.7,19h-3.2v5.4h3.1c0.9,0,1.6-0.2,2.1-0.7s0.7-1.1,0.7-1.9s-0.2-1.5-0.7-2S25.5,19,24.7,19z" />

        <path d="M24.5,4.3c-11.3,0-20.4,9.2-20.4,20.4S13.3,45,24.5,45s20.4-9.1,20.4-20.3C44.9,13.4,35.7,4.3,24.5,4.3z M24.5,43
		C14.4,43,6.1,34.9,6.1,24.7S14.4,6.3,24.5,6.3s18.4,8.1,18.4,18.3C42.9,34.8,34.6,43,24.5,43z" />

        <path d="M28.1,16.8c-1-0.5-2.1-0.7-3.4-0.7H18v17.1h3.5v-6v-0.1h3.1c2.1,0,3.7-0.4,4.8-1.4c1.1-1,1.7-2.3,1.7-4
		c0-1.1-0.3-2-0.8-2.9C29.8,17.9,29.1,17.3,28.1,16.8z M26.7,23.7c-0.5,0.5-1.2,0.7-2.1,0.7h-3.1V19h3.2c0.8,0,1.5,0.3,2,0.8
		s0.7,1.2,0.7,2S27.2,23.2,26.7,23.7z" />

    </g>
</svg>

<svg #PetrolFuel *ngIf="expenseCategoryId==11" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <rect x="41.6" y="19" class="st0" width="1.8" height="3.6" />

        <rect x="14.5" y="9.9" class="st0" width="14.5" height="10" />

        <path class="st0" d="M30.8,5.4H12.7c-1.5,0-2.7,1.2-2.7,2.7v34.3c0.6,0,1.5,0,2.7,0h18.1c1.2,0,2.1,0,2.7,0V8.1
		C33.5,6.6,32.3,5.4,30.8,5.4z M30.8,20.7c0,0.5-0.4,0.9-0.9,0.9H13.6c-0.5,0-0.9-0.4-0.9-0.9V9c0-0.5,0.4-0.9,0.9-0.9h16.3
		c0.5,0,0.9,0.4,0.9,0.9V20.7z" />

        <path d="M29.9,8.1H13.6c-0.5,0-0.9,0.4-0.9,0.9v11.7c0,0.5,0.4,0.9,0.9,0.9h16.3c0.5,0,0.9-0.4,0.9-0.9V9
		C30.8,8.5,30.4,8.1,29.9,8.1z M29,19.9H14.5v-10H29V19.9z" />

        <path
            d="M37.1,45.1H6.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h30.7c0.5,0,0.9-0.4,0.9-0.9S37.6,45.1,37.1,45.1z" />

        <path d="M43.8,14.2L41.1,11c-0.1-0.1-0.3-0.2-0.4-0.2c-1,0-1.3,1-0.8,1.5l2.6,3c0.6,0.7,1,1.1,1,1.8H41c-0.6,0-1.1,0.5-1.1,1.1v4.3
		c0,1,0.8,1.8,1.8,1.8h1.8v13.5c0,0.5-0.4,0.9-0.9,0.9h-2c-0.5,0-0.7-0.5-0.7-0.9V26.1c0-1.6-1.5-2.7-2.9-2.7h-1.6V8.1
		c0-2.5-2-4.5-4.5-4.5H12.7c-2.5,0-4.5,2-4.5,4.5v35.2c0,0.9,0.7,0.9,2,0.9c0.7,0,1.6,0,2.6,0h18.1c1,0,1.9,0,2.6,0c1.2,0,2,0,2-0.9
		v-18h1.6c0.6,0,1.1,0.4,1.1,0.9V38c0,1.5,1.1,2.7,2.5,2.7h2c1.5,0,2.7-1.2,2.7-2.7V17.1C45.2,15.8,44.5,14.9,43.8,14.2z M43.4,22.6
		h-1.8V19h1.8V22.6z M33.5,42.4c-0.6,0-1.5,0-2.7,0H12.7c-1.2,0-2.1,0-2.7,0V8.1c0-1.5,1.2-2.7,2.7-2.7h18.1c1.5,0,2.7,1.2,2.7,2.7
		V42.4z" />

    </g>
</svg>

<svg #TelephoneInternet *ngIf="expenseCategoryId==12" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0"
            d="M38.4,30.8c-0.2-0.3-0.7-0.7-1.3-0.7c-0.6,0-1.1,0.4-1.4,0.7L32.5,34c-0.1,0.1-0.6,0.6-1.4,0.6
		c-0.3,0-0.6,0-0.9-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.2-0.6-0.3-0.9-0.5s-0.7-0.4-1.1-0.6c-3-1.9-5.7-4.3-8.3-7.5
		c-1.4-1.8-2.3-3.3-3-4.9v-0.1c-0.2-0.5-0.3-1.3,0.5-2.1s1.5-1.5,2.3-2.3c0.3-0.3,0.5-0.5,0.8-0.8c1-1,1-1.9,0-2.9l-2.5-2.5
		c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.3-0.3-0.8-0.7-1.4-0.7c-0.5,0-0.9,0.3-1.4,0.7l-3.2,3.2
		c-0.8,0.8-1.2,1.7-1.3,2.8c-0.2,1.7,0.1,3.5,1,5.8c1.4,3.7,3.5,7.3,6.7,11.1c3.8,4.5,8.4,8.2,13.7,10.7c1.9,0.9,4.4,2,7.2,2.2
		c0.1,0,0.3,0,0.4,0c1.5,0,2.6-0.5,3.5-1.5c0.6-0.7,1.2-1.3,1.8-1.9c0.4-0.4,0.7-0.7,1.1-1.1c1-1,1-2,0.1-2.9L38.4,30.8z" />

        <path d="M45.3,34.1L40.1,29c-0.9-0.9-2-1.4-3.1-1.4s-2.2,0.5-3.2,1.4l-2.9,2.9c-0.2-0.1-0.5-0.3-0.7-0.4c-0.3-0.2-0.7-0.3-0.9-0.5
		c-2.8-1.7-5.3-4-7.6-7c-1.2-1.5-1.9-2.7-2.5-4c0.8-0.7,1.5-1.4,2.2-2.1c0.3-0.3,0.5-0.5,0.8-0.8c2-2,2-4.5,0-6.4l-2.5-2.5
		c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.9-0.9-2-1.4-3.1-1.4s-2.2,0.5-3.2,1.4L7.4,8.8c-1.2,1.2-1.9,2.6-2,4.3
		C5.2,15.8,6,18.3,6.6,20c1.5,4.1,3.8,7.8,7.1,11.9c4.1,4.9,9,8.7,14.6,11.4c2.1,1,5,2.2,8.2,2.4c0.2,0,0.4,0,0.6,0
		c2.1,0,4-0.8,5.4-2.3c0.5-0.6,1-1.1,1.6-1.7c0.4-0.4,0.8-0.8,1.2-1.2c0.9-1,1.4-2.1,1.4-3.2C46.7,36.1,46.2,35,45.3,34.1z
		 M43.4,38.8c-0.4,0.4-0.7,0.7-1.1,1.1c-0.6,0.6-1.2,1.2-1.8,1.9c-0.9,1-2,1.5-3.5,1.5c-0.1,0-0.3,0-0.4,0c-2.8-0.2-5.3-1.3-7.2-2.2
		c-5.3-2.5-9.9-6.2-13.7-10.7C12.5,26.6,10.4,23,9,19.3c-0.9-2.3-1.2-4.1-1-5.8c0.1-1.1,0.5-2,1.3-2.8l3.2-3.2
		c0.5-0.4,0.9-0.7,1.4-0.7c0.6,0,1.1,0.4,1.4,0.7C15.9,8,16.4,8.6,17,9.2c0.3,0.3,0.6,0.6,0.9,0.9l2.5,2.5c1,1,1,1.9,0,2.9
		c-0.3,0.3-0.5,0.5-0.8,0.8c-0.8,0.8-1.5,1.5-2.3,2.3s-0.7,1.6-0.5,2.1v0.1c0.7,1.6,1.6,3.1,3,4.9c2.6,3.2,5.3,5.6,8.3,7.5
		c0.4,0.2,0.8,0.4,1.1,0.6s0.7,0.3,0.9,0.5c0,0,0.1,0,0.1,0.1c0.3,0.2,0.6,0.2,0.9,0.2c0.8,0,1.3-0.5,1.4-0.6l3.2-3.2
		c0.3-0.3,0.8-0.7,1.4-0.7c0.6,0,1.1,0.4,1.3,0.7l5.1,5.1C44.4,36.8,44.4,37.8,43.4,38.8z" />

    </g>
</svg>

<svg #BusTrains *ngIf="expenseCategoryId==13" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <path d="M40.4,13.8H10c-2,0-3.6,1.6-3.6,3.6v15.8c0,0.3,0.3,0.5,0.6,0.5h2.5c0.2,2.1,2,3.7,4.1,3.7s3.9-1.6,4.1-3.7h15.7
	c0.2,2.1,2,3.7,4.1,3.7s3.9-1.6,4.1-3.7h2.1c0.3,0,0.5-0.2,0.6-0.5V17.5C44,15.5,42.4,13.8,40.4,13.8z M43,17.5v7.3h-7
	c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3v-8h6.3C41.8,14.9,42.9,16.1,43,17.5z M26.1,22.1V18H33v4.1H26.1z M10.4,22.1V18h6.1v4.1
	H10.4z M17.7,18.1H25v4.1h-7.4v-4.1H17.7z M13.5,36.4c-1.7,0-3-1.4-3-3c0-1.7,1.4-3,3-3s3,1.4,3,3C16.5,35.1,15.2,36.4,13.5,36.4z
	 M37.3,36.4c-1.7,0-3-1.4-3-3c0-1.7,1.4-3,3-3c1.7,0,3,1.4,3,3C40.3,35.1,39,36.4,37.3,36.4z M41.4,32.7c-0.3-2-2-3.4-4.1-3.4
	c-2,0-3.7,1.5-4.1,3.4H17.5c-0.3-2-2-3.4-4.1-3.4s-3.7,1.5-4.1,3.4h-2V17.5c0-1.4,1.1-2.5,2.6-2.5h23v2h-23c-0.3,0-0.6,0.2-0.6,0.5
	v5.2c0,0.3,0.3,0.5,0.6,0.5H33c0.2,1.5,1.4,2.6,2.9,2.6h7v6.9H41.4z" />

</svg>

<svg #CarHire *ngIf="expenseCategoryId==14 || expenseCategoryId==25" [ngClass]="this.className" version="1.1"
    id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="50px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"
    class="svg-30">
    <g>
        <path d="M33.6,32.3H19.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h14.4c0.3,0,0.6,0.3,0.6,0.6C34.3,32,34,32.3,33.6,32.3
		L33.6,32.3z" />

        <path d="M15.8,35.7c-2.2,0-4.1-1.8-4.1-4.1c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1C19.9,33.9,18,35.7,15.8,35.7L15.8,35.7z
		 M15.8,28.9c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8C18.6,30.1,17.3,28.9,15.8,28.9L15.8,28.9z" />

        <path d="M37.1,35.7c-2.2,0-4.1-1.8-4.1-4.1c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1C41.1,33.9,39.3,35.7,37.1,35.7L37.1,35.7z
		 M37.1,28.9c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8C39.9,30.1,38.6,28.9,37.1,28.9L37.1,28.9z" />

        <path d="M33.7,32.3H19.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h14.4c0.3,0,0.6,0.3,0.6,0.6C34.3,32,34,32.3,33.7,32.3
		L33.7,32.3z" />

        <path d="M43.5,32.3h-3c-0.4,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h3c0.8,0,1.4-0.6,1.6-1.3l0.7-4.3c0-0.3,0-0.6-0.2-0.8
		c-0.2-0.2-0.5-0.4-0.8-0.4l-8.4-1c-0.1,0-0.2-0.1-0.3-0.1l-7.6-6.4c-0.5-0.4-1.1-0.7-1.8-0.7H14.8c-0.6,0-1.1,0.3-1.4,0.8l-3.5,5.6
		c-0.1,0.1-0.2,0.2-0.3,0.3l-4.3,1.3c-0.6,0.2-1,0.8-0.9,1.5L5,29.4c0,0.9,0.9,1.7,2,1.7h5.3c0.4,0,0.6,0.3,0.6,0.6
		c0,0.3-0.3,0.6-0.6,0.6H7c-1.7,0-3.2-1.2-3.4-2.9L3,25.5c-0.2-1.3,0.6-2.5,1.8-2.9l4.1-1.2l3.4-5.4c0.5-0.9,1.5-1.4,2.5-1.4h11.9
		c1,0,1.9,0.3,2.6,0.9l7.4,6.3l8.2,1c0.6,0.1,1.2,0.4,1.6,0.9c0.4,0.5,0.5,1.2,0.5,1.8l-0.7,4.3C46.1,31.3,44.9,32.3,43.5,32.3
		L43.5,32.3z" />

        <path d="M29.6,23.6H15.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h14.5c0.4,0,0.6,0.3,0.6,0.6C30.3,23.3,30,23.6,29.6,23.6
		L29.6,23.6z" />

    </g>
</svg>

<svg #CarRepair *ngIf="expenseCategoryId==15" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30 ">
    <g>
        <path d="M46.5,42.1c0-0.2-0.1-0.4-0.3-0.5L43,39.4L30.5,26.9l1-1c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5l-1.7-1.7l1.9-2.2
		c0.5,0.3,1.1,0.5,1.7,0.6c0.4,0.1,0.7,0.1,1,0.1c0.6,0,0.7,0,1.3,0.5c-0.3,0.2-0.4,0.6-0.4,0.9s0.1,0.8,0.4,1.1l2.5,2.5
		c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.7l5.1-5.1c0.4-0.4,0.6-0.9,0.6-1.5s-0.2-1.1-0.6-1.5L43.8,16c-0.3-0.3-0.6-0.4-1-0.4
		s-0.7,0.2-1,0.4c-0.6-0.5-0.6-0.6-0.6-1.2c0-0.3,0-0.6-0.1-1.1c-0.1-1-0.6-2-1.4-2.8l-2.6-2.6c-1.5-1.5-2.2-2.2-4.2-3.1
		c-4.2-1.9-8.4-1.6-14.6,1.2c-0.4,0.1-0.5,0.4-0.4,0.8s0.4,0.6,0.7,0.6h0.9c3.2,0,4.8,0,7.7,2.9c1.3,1.3,1.3,3.2,0.2,4.6
		c-0.2,0-0.3,0.1-0.4,0.2L24.7,18L10.9,4.2C9.7,3,7.9,3,6.7,4.2L4.8,6.1C4.2,6.7,3.9,7.4,3.9,8.2s0.3,1.5,0.9,2.1L19,24.5L4.8,40.3
		c-0.6,0.6-0.9,1.4-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1l1,1c0.6,0.5,1.2,0.8,2,0.8s1.5-0.4,2.1-0.9l14.3-16l1.4,1.4
		c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.4-0.1l1.1-1.1l12.6,12.6l2.1,3.2c0.1,0.1,0.3,0.3,0.5,0.3l3.4,0.4h0.1c0.2,0,0.4-0.1,0.6-0.1
		c0.1-0.1,0.2-0.4,0.2-0.6L46.5,42.1z M28.2,9.6c-2.3-2.2-4-2.9-6-3.2c4.1-1.3,7.1-1.2,10.1,0.1c1.7,0.7,2.3,1.3,3.8,2.8l2.6,2.6
		c0.6,0.6,0.9,1.3,1,2c0.1,0.4,0.1,0.6,0.1,0.9c0,0.8,0.1,1.4,0.9,2.2c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1-0.4l2.5,2.5
		c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5l-5.1,5.1c-0.3,0.3-0.7,0.3-1,0l-2.5-2.5c0.3-0.2,0.4-0.6,0.4-1S37,21.3,36.7,21
		c-0.9-0.9-1.4-0.9-2.2-0.9c-0.2,0-0.5,0-0.9-0.1c-0.8-0.1-1.4-0.4-2-1l-3.1-3.1C30,14,29.9,11.3,28.2,9.6L28.2,9.6z M5.8,9.3
		C5.5,9,5.3,8.6,5.3,8.2c0-0.4,0.1-0.8,0.4-1.1l1.9-1.9C8,4.9,8.4,4.7,8.8,4.7c0.4,0,0.8,0.1,1.1,0.4L23.8,19l-1,1.2L20,23.4
		L5.8,9.3z M8.9,44.5c-0.3,0.3-0.6,0.4-1.1,0.4c-0.4,0-0.7-0.1-1-0.4l-1-0.9c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.8,0.5-1.1l21.7-24.3
		l3.1,3l-2.1,2.4c-0.1,0-0.1,0.1-0.2,0.1L23.6,28c-0.1,0.1-0.1,0.1-0.1,0.2L8.9,44.5z M26,29.4l-0.9-0.9l1.4-1.6l2.3-2.6l1.2,1.1
		L26,29.4z M29.6,27.9l12,12.1l-1,1L28.5,28.9L29.6,27.9z M43.2,44.6l-1.7-2.4l1.2-1.2l2.4,1.7l0.3,2.3L43.2,44.6z" />

        <path d="M20.9,19.3L8.7,7.1c-0.3-0.3-0.7-0.3-1,0s-0.3,0.7,0,1l12.2,12.2c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2
		C21.2,20,21.2,19.6,20.9,19.3L20.9,19.3z" />

    </g>
</svg>

<svg #CleaningCosts *ngIf="expenseCategoryId==16" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <g>
        <path d="M45.8,33.3L45.8,33.3c0-0.1,0-0.1,0-0.2c0-4.2-3.5-7.7-7.8-7.7c-4,0-7.3,3-7.7,6.9c-0.6-0.7-1.4-1.2-2.3-1.2h-2V4.3
		C26,2.9,25.1,2,23.8,2s-2.2,0.9-2.2,2.3v26.9h-1.9c-0.8,0-1.5,0.4-2,0.9V24c0-0.3-0.3-0.6-0.6-0.6H6.5c-0.3,0-0.6,0.3-0.6,0.6v0.3
		H4v3h2v0.1C6,27.7,6.3,28,6.6,28h3v0.9c0,1.3-1,2.3-2.3,2.3c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c1.9,0,3.4-1.5,3.4-3.4v-0.9
		h1.4v6H7c-1.9,0-3.4,1.5-3.4,3.4v10.2c0,0.3,0.3,0.6,0.6,0.6h12.9l0,0l0,0h13.3c0.3,0,0.6-0.3,0.6-0.6v-9.2l1.6,9.3
		c0,0.3,0.3,0.5,0.6,0.5h9.5c0.3,0,0.5-0.2,0.6-0.5l2.5-13.7c0-0.1,0-0.2-0.1-0.3C45.7,33.5,45.8,33.4,45.8,33.3L45.8,33.3z
		 M23.8,3.1c0.4,0,1.1,0.1,1.1,1.2v20.8h-2.2V4.3C22.7,3.3,23.4,3.1,23.8,3.1L23.8,3.1z M22.7,26.2h2.2v5h-2.2V26.2z M7.1,26.7v-2.2
		h9.4v6.8h-3.3v-4c0-0.3-0.3-0.6-0.6-0.6L7.1,26.7z M7,35h5.6c0.3,0,0.6-0.3,0.6-0.6v-2.1h3.3v6.8h-0.1c-0.8,1.3-2.1,3.4-5.6,1.3
		c-3.2-1.9-5.2-1.3-6.2-0.7v-2.5C4.7,36.1,5.8,35,7,35L7,35z M4.7,46.9v-5.7c0,0,0.1,0,0.1-0.1c0.1-0.1,1.6-1.8,5.5,0.4
		c1.2,0.7,2.3,1,3.2,1c1.4,0,2.4-0.7,3-1.4V47H4.7V46.9z M29.9,46.9h-2.1v-5.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v5.8
		h-2.2v-5.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v5.8H21v-5.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v5.8h-2.1V34.6
		c0-1.3,0.9-2.3,1.9-2.3H28c1.1,0,1.9,1,1.9,2.3V46.9z M43.8,38.3L43.8,38.3c-0.4,0.8-1.1,2.1-2.3,2.4c-0.8,0.2-1.9-0.1-3.1-0.9
		c-3.2-2.1-5.2-1.5-6.1-0.8l-0.7-4.1c1.7,0.7,4.5,1,6.4,1c2,0,4.8-0.3,6.5-1L43.8,38.3z M44.7,33.3c-0.1,0.4-2.3,1.3-6.7,1.3
		s-6.6-1-6.7-1.3C31.4,33,33.6,32,38,32S44.5,33,44.7,33.3L44.7,33.3z M38,26.6c3.3,0,6,2.3,6.5,5.3c-1.6-0.8-4.5-1-6.5-1
		s-4.9,0.3-6.5,1C32,28.9,34.7,26.6,38,26.6L38,26.6z M42.2,46.9h-8.6l-1.1-6.7c0.1-0.1,1.6-2,5.3,0.4c1.1,0.8,2.2,1.1,3.1,1.1
		c0.3,0,0.6,0,0.8-0.1c0.6-0.2,1.1-0.5,1.6-0.9L42.2,46.9z" />

    </g>
</svg>

<svg #CoursesReferences *ngIf="expenseCategoryId==17" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <path d="M24.9,11.2c-0.1,0-0.2,0-0.3,0l-22.4,8c-0.7,0.2-0.7,1.3,0,1.5l2.7,0.9v5.6c-0.9,0.3-1.6,1.2-1.6,2.3s0.7,1.9,1.6,2.3v2.5
	c0,1.1,1.6,1.1,1.6,0v-2.5c0.9-0.3,1.6-1.2,1.6-2.3s-0.7-1.9-1.6-2.3v-5.1l4.8,1.7v11.9c-0.1,0.4,0.2,0.8,0.6,0.9
	c8.5,2.4,18.2,2.5,26,0c0.4-0.1,0.6-0.5,0.6-0.9V24l9.1-3.2c0.7-0.3,0.7-1.3,0-1.5l0,0l-22.3-8C25,11.2,25,11.2,24.9,11.2L24.9,11.2
	z M24.8,12.9l20,7.2l-20,7.2L4.8,20L24.8,12.9z M24.8,19.2c-0.9,0-1.6,0.4-1.6,0.8s0.7,0.8,1.6,0.8s1.6-0.4,1.6-0.8
	S25.7,19.2,24.8,19.2L24.8,19.2z M12.9,24.6l11.7,4.2c0.2,0.1,0.4,0.1,0.5,0l11.7-4.2v10.8c-7.1,2.1-16.1,2.1-23.9,0V24.6z
	 M5.7,28.8c0.4,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.3-0.8-0.8C4.9,29.2,5.2,28.8,5.7,28.8L5.7,28.8z" />

</svg>

<svg #Entertainment_and_clients *ngIf="expenseCategoryId==18" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <g>
        <path d="M23.1,13.1c-2.5,0-4.4,1.3-4.4,3s1.9,3,4.4,3s4.4-1.3,4.4-3S25.6,13.1,23.1,13.1L23.1,13.1z M23.1,17.6c-1.7,0-3-0.8-3-1.5
		c0-0.7,1.3-1.5,3-1.5s3,0.8,3,1.5C26,16.8,24.8,17.6,23.1,17.6L23.1,17.6z" />

        <path
            d="M37.3,26.1c-2.3-1-4.6-0.6-5.2,1c-0.7,1.5,0.6,3.5,2.9,4.5c2.3,1,4.6,0.6,5.2-0.9C40.8,29.1,39.6,27.1,37.3,26.1L37.3,26.1
		z M38.8,30c-0.3,0.6-1.8,0.9-3.3,0.2c-1.6-0.7-2.4-1.9-2.1-2.5c0.3-0.6,1.8-0.9,3.3-0.2C38.3,28.1,39.1,29.4,38.8,30L38.8,30z" />

        <path
            d="M23.4,23.8c-1.4,2.1-3.7,3.4-6.2,3.4s-4.8-1.3-6.2-3.4l-1.2,0.8c1.6,2.5,4.4,4,7.4,4s5.8-1.5,7.4-4L23.4,23.8z" />

        <path d="M11.2,13.1c-2.5,0-4.4,1.3-4.4,3s2,3,4.4,3c2.5,0,4.4-1.3,4.4-3C15.7,14.4,13.7,13.1,11.2,13.1L11.2,13.1z M11.2,17.6
		c-1.7,0-3-0.8-3-1.5c0-0.7,1.3-1.5,3-1.5s3,0.8,3,1.5C14.2,16.8,12.9,17.6,11.2,17.6L11.2,17.6z" />

        <path d="M31.2,17.3V3.7c0,0-6.6,4-14.1,4C9.7,7.7,3,3.7,3,3.7v18.6c0,5.1,2.8,9.8,7.2,12.3c1.4,0.8,2.9,1.3,4.4,1.5
		c0.5,1.6,1.3,3.2,2.3,4.5c2.7,3.5,6.8,6,12.5,5.5c5.1-0.5,9.5-3.7,11.5-8.4l7.4-17C48.5,20.7,39.2,22.3,31.2,17.3L31.2,17.3z
		 M11.1,33.3c-4-2.2-6.5-6.4-6.5-11V6.2c3.9,1.9,8.2,3,12.6,3s8.7-1,12.6-3v16.1c0,4.6-2.5,8.8-6.5,11
		C19.5,35.4,14.8,35.4,11.1,33.3L11.1,33.3z M39.7,37.2c-1.8,4.2-5.8,7.1-10.3,7.5c-4.4,0.4-8.5-1.4-11.2-4.9
		c-0.8-1-1.4-2.2-1.9-3.4c0.3,0,4.4,0.3,8.6-2.3c3.8,0,6.1,3.7,6.5,6.1l1.5-0.2c-0.5-3-3.1-6.6-6.4-7.1c3-2.6,4.7-6.4,4.7-10.5V19
		c4.5,2.5,9.8,3.7,14.9,3.4L39.7,37.2z" />

    </g>
</svg>

<svg #Hardware_and_computer *ngIf="expenseCategoryId==19" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">
    <path
        d="M48.3,35.1V9.3c0-2.6-2.1-4.8-4.8-4.8H7.3c-2.6,0-4.8,2.1-4.8,4.8V35c0,2.6,2.1,4.8,4.8,4.8h13L19,44.6h-3.1v1.9h19v-1.9
	h-3.1l-1.4-4.8h13C46.2,39.8,48.3,37.7,48.3,35.1z M7.3,6.5h36.2c1.6,0,2.9,1.3,2.9,2.9v21.9H4.5v-22C4.5,7.8,5.8,6.5,7.3,6.5z
	 M29.9,44.6H21l1.4-4.8h6.2L29.9,44.6z M43.5,37.9H7.3c-1.6,0-2.9-1.3-2.9-2.9v-1.9h41.9V35C46.4,36.6,45.1,37.9,43.5,37.9z" />

</svg>

<svg #Media *ngIf="expenseCategoryId==20" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M41.4,11.1c-1.2-1.5-2.8-2.5-4.7-2.8c-1.7-0.3-3.3,0.1-4.9,1l5.7,7.8c0.3,0.3,0.2,0.7-0.1,0.9
		c-0.3,0.3-0.7,0.2-0.9-0.1l-5.7-7.8c-1.2,1.1-2,2.5-2.3,4.2c-0.2,1.8,0.2,3.7,1.2,5.3c1.2,1.5,2.8,2.5,4.7,2.8
		c1.7,0.3,3.4,0,4.8-0.9l-1.1-1.6C38,19.7,38,19.6,38,19.4c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.7-0.2,0.9,0.1l1.1,1.6
		c1.3-1.1,2.1-2.6,2.4-4.3C43,14.5,42.5,12.6,41.4,11.1z M36,10.7c-0.1,0.4-0.4,0.6-0.7,0.5c-0.4-0.1-0.6-0.4-0.5-0.7
		c0-0.3,0.3-0.5,0.7-0.5C35.9,10.1,36.1,10.4,36,10.7z M39.6,11.3c-0.1,0.4-0.4,0.6-0.7,0.5c-0.4-0.1-0.6-0.4-0.5-0.7
		c0-0.3,0.3-0.6,0.7-0.5C39.5,10.7,39.7,11,39.6,11.3z" />

        <path class="st0"
            d="M25.9,22.4c-0.7-1.1-1.3-2.3-1.7-3.5L7.7,32.6l1.5,2.1l1.5,2.1l18-11.7C27.6,24.4,26.7,23.5,25.9,22.4z" />

        <path class="st0"
            d="M23.5,9.6c-0.6,0.1-1,0.7-0.9,1.3c0.1,0.6,0.7,1,1.3,0.9c0.6-0.1,1-0.7,0.9-1.3C24.7,9.9,24.1,9.5,23.5,9.6z" />

        <path class="st0" d="M28.7,20.4c-0.8-1.2-1.3-2.5-1.5-3.9l-2,1.6c0.3,1.3,0.9,2.5,1.7,3.6c0.8,1.1,1.8,2,2.9,2.7l2.1-1.3
		C30.6,22.5,29.5,21.6,28.7,20.4z" />

        <path class="st0" d="M17.2,13.2c-0.6,0.1-1,0.7-0.9,1.3c0.1,0.6,0.7,1,1.3,0.9c0.6-0.1,1-0.7,0.9-1.3
		C18.4,13.5,17.8,13.1,17.2,13.2z" />

        <path
            d="M35.5,10c-0.4,0-0.7,0.2-0.7,0.5c-0.1,0.3,0.1,0.6,0.5,0.7c0.3,0.1,0.6-0.1,0.7-0.5C36.1,10.4,35.9,10.1,35.5,10z" />

        <path
            d="M39.1,10.6c-0.4-0.1-0.7,0.2-0.7,0.5c-0.1,0.3,0.1,0.6,0.5,0.7c0.3,0.1,0.6-0.1,0.7-0.5C39.7,11,39.5,10.7,39.1,10.6z" />

        <path d="M42.5,10.4c-1.3-1.8-3.3-3-5.5-3.4c-2-0.3-4,0.1-5.8,1.2L31,8c-0.2-0.3-0.6-0.4-0.9-0.1C30,8,29.9,8.1,29.8,8.3
		c0,0.2,0,0.3,0.1,0.5L30.1,9c-1.5,1.3-2.5,3.1-2.8,5.1c0,0.3-0.1,0.5-0.1,0.8l-3,2.4L6.3,31.9C6.1,32,6.1,32.2,6,32.3
		c0,0.2,0,0.3,0.1,0.5l1.5,2.1l-0.4,0.3c-1.1,0.8-1.7,1.9-1.9,3.1s0.1,2.5,0.9,3.6c1.6,2.1,4.6,2.6,6.7,1.1l13.9-10.1
		c1.6-1.1,3.8-0.8,4.9,0.8c1.1,1.6,0.8,3.8-0.8,4.9l-8.6,6.3l0.8,1l8.6-6.3c2.1-1.6,2.6-4.6,1.1-6.7c-1.6-2.1-4.6-2.6-6.7-1.1
		L12.2,41.9c-1.6,1.1-3.8,0.8-4.9-0.8c-1.1-1.6-0.8-3.8,0.8-4.9l0.4-0.3L10,38c0.2,0.3,0.6,0.4,0.9,0.2l22.7-14.7
		c0.3,0.1,0.5,0.1,0.8,0.2c2,0.3,4-0.1,5.7-1.1l0.1,0.2c0.2,0.3,0.6,0.4,0.9,0.1c0.3-0.2,0.4-0.6,0.1-0.9l-0.1-0.2
		c1.3-1.2,2.4-2.7,2.8-4.5l0.2-1.4C44.2,13.9,43.6,12,42.5,10.4z M10.7,36.8l-1.5-2.1l-1.5-2.1l16.5-13.7c0.4,1.2,1,2.4,1.7,3.5
		c0.8,1.1,1.7,2,2.8,2.7L10.7,36.8z M29.8,24.4c-1.1-0.7-2.1-1.6-2.9-2.7c-0.8-1.1-1.4-2.3-1.7-3.6l2-1.6c0.2,1.4,0.7,2.7,1.5,3.9
		c0.8,1.2,1.9,2.1,3.2,2.7L29.8,24.4z M40.3,20.7l-1.1-1.6c-0.2-0.3-0.6-0.4-0.9-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.2,0,0.3,0.1,0.5
		l1.1,1.6c-1.4,0.9-3.1,1.2-4.8,0.9c-1.9-0.3-3.5-1.3-4.7-2.8c-1-1.6-1.4-3.5-1.2-5.3c0.3-1.7,1.1-3.1,2.3-4.2l5.7,7.8
		c0.2,0.3,0.6,0.4,0.9,0.1c0.3-0.2,0.4-0.6,0.1-0.9l-5.7-7.8c1.6-0.9,3.2-1.3,4.9-1c1.9,0.3,3.5,1.3,4.7,2.8
		c1.1,1.5,1.6,3.4,1.3,5.3C42.4,18.1,41.6,19.6,40.3,20.7z" />

        <path d="M17.4,6.7c-0.2,0.1-0.3,0.4-0.3,0.6l1,4.8c-0.4-0.1-0.8-0.2-1.2-0.1c-1.2,0.3-2,1.5-1.8,2.7c0.3,1.2,1.5,2,2.7,1.8
		s2-1.5,1.8-2.7l-1.3-6.3l5.2-3l0.8,4c-0.4-0.1-0.8-0.2-1.2-0.1c-1.2,0.3-2,1.5-1.8,2.7c0.3,1.2,1.5,2,2.7,1.8
		c1.2-0.2,2-1.5,1.8-2.7l-1.4-6.7c0.1-0.1-0.1-0.3-0.2-0.4C24,3,23.8,3,23.7,3.1L17.4,6.7z M18.5,14.1c0.1,0.6-0.3,1.2-0.9,1.3
		c-0.6,0.1-1.2-0.3-1.3-0.9c-0.1-0.6,0.3-1.2,0.9-1.3C17.8,13.1,18.4,13.5,18.5,14.1z M23.9,11.8c-0.6,0.1-1.2-0.3-1.3-0.9
		c-0.1-0.6,0.3-1.2,0.9-1.3c0.6-0.1,1.2,0.3,1.3,0.9C24.9,11.1,24.5,11.7,23.9,11.8z" />

    </g>
</svg>

<svg #OfficeSupplies *ngIf="expenseCategoryId==21" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <polygon class="st0" points="5.3,45.1 9.2,43.9 6.5,41.2 	" />

        <polygon class="st0" points="8.8,34.3 16.1,41.5 43.2,14.6 35.9,7.4 	" />

        <polygon class="st0" points="6.9,39.6 10.7,43.4 14.7,42.1 8.1,35.6 	" />

        <path class="st0" d="M43.7,6.9c-2.3-2.4-5.8-1.2-6.7-0.5l7.2,7.1C44.9,12.6,46,9.1,43.7,6.9z" />

        <path d="M44.8,5.8c-3.1-3.1-7.6-1.4-8.8-0.5l-2.1-2.1l-11,10.9l1,1l9.9-9.8l1,1L7.1,33.9L3,47.3l13.4-4.1l28.3-28.1
		C46,13.9,48.2,9.2,44.8,5.8z M5.3,45.1l1.2-3.9l2.7,2.7L5.3,45.1z M10.7,43.4l-3.8-3.8l1.2-4l6.6,6.5L10.7,43.4z M16.1,41.5
		l-7.3-7.2L35.9,7.4l7.3,7.2L16.1,41.5z M44.2,13.5L37,6.4c0.9-0.7,4.4-1.9,6.7,0.5C46,9.1,44.9,12.6,44.2,13.5z" />

    </g>
</svg>

<svg #ZeroTax *ngIf="expenseCategoryId==22" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M27.7,22c0.2-0.3,0.3-0.6,0.3-1v-0.7c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.9-0.4c-0.4,0-0.6,0.1-0.9,0.4
		c-0.3,0.3-0.3,0.6-0.3,1v0.7c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4C27.2,22.4,27.5,22.3,27.7,22z" />

        <path class="st0" d="M17.1,19.6c-0.7,0-1.2,0.3-1.5,0.8c-0.3,0.5-0.5,1.3-0.5,2.4v3.5c0,1.2,0.2,2,0.5,2.6c0.3,0.6,0.8,0.8,1.5,0.8
		c0.7,0,1.2-0.3,1.5-0.8c0.3-0.5,0.5-1.4,0.5-2.5V23c0-1.2-0.2-2-0.5-2.6C18.3,19.9,17.8,19.6,17.1,19.6z" />

        <path class="st0" d="M33.6,27c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1v0.7c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.5,0.4,0.9,0.4
		c0.8,0,1.2-0.4,1.2-1.3v-0.7c0-0.4-0.1-0.7-0.3-0.9C34.3,27.1,34,27,33.6,27z" />

        <path class="st0"
            d="M24.5,6.3c-10.1,0-18.4,8.2-18.4,18.4S14.4,43,24.5,43s18.4-8.2,18.4-18.4C42.9,14.4,34.6,6.3,24.5,6.3z
		 M21.9,25.9c0,2-0.4,3.5-1.2,4.5s-2,1.6-3.6,1.6c-1.5,0-2.7-0.5-3.6-1.5s-1.2-2.5-1.3-4.4v-2.6c0-2,0.4-3.5,1.2-4.5
		c0.8-1,2-1.5,3.6-1.5s2.7,0.5,3.6,1.5c0.9,1,1.2,2.5,1.3,4.4V25.9z M23.8,21v-0.7c0-0.9,0.3-1.6,0.8-2.1s1.3-0.8,2.2-0.8
		c0.9,0,1.7,0.3,2.2,0.8s0.8,1.3,0.8,2.2v0.7c0,0.9-0.3,1.6-0.8,2.1S27.8,24,26.9,24c-0.9,0-1.7-0.3-2.2-0.8S23.8,21.9,23.8,21z
		 M26,30l7-11.1l1.4,0.7l-7,11.1L26,30z M36.8,29.1c0,0.9-0.3,1.6-0.8,2.1c-0.6,0.5-1.3,0.8-2.2,0.8c-0.9,0-1.7-0.3-2.3-0.8
		s-0.8-1.3-0.8-2.1v-0.8h-0.1c0-0.9,0.3-1.6,0.9-2.1s1.3-0.8,2.2-0.8c0.9,0,1.7,0.3,2.2,0.8c0.6,0.5,0.9,1.3,0.9,2.2V29.1z" />

        <path d="M24.5,4.3c-11.3,0-20.4,9.2-20.4,20.4S13.3,45,24.5,45s20.4-9.1,20.4-20.3C44.9,13.4,35.7,4.3,24.5,4.3z M24.5,43
		C14.4,43,6.1,34.9,6.1,24.7S14.4,6.3,24.5,6.3s18.4,8.1,18.4,18.3C42.9,34.8,34.6,43,24.5,43z" />

        <path d="M17,17.5c-1.6,0-2.8,0.5-3.6,1.5c-0.8,1-1.2,2.5-1.2,4.5v2.6c0.1,1.9,0.4,3.4,1.3,4.4s2.1,1.5,3.6,1.5
		c1.6,0,2.8-0.6,3.6-1.6s1.2-2.5,1.2-4.5v-2.5c-0.1-1.9-0.4-3.4-1.3-4.4C19.7,18,18.6,17.5,17,17.5z M19.1,26.4c0,1.1-0.2,2-0.5,2.5
		c-0.3,0.5-0.8,0.8-1.5,0.8c-0.7,0-1.2-0.2-1.5-0.8c-0.3-0.6-0.5-1.4-0.5-2.6v-3.5c0-1.1,0.2-1.9,0.5-2.4c0.3-0.5,0.8-0.8,1.5-0.8
		c0.7,0,1.2,0.3,1.5,0.8c0.3,0.6,0.5,1.4,0.5,2.6V26.4z" />

        <polygon points="33,18.9 26,30 27.4,30.7 34.4,19.6 	" />

        <path d="M33.7,25.4c-0.9,0-1.6,0.3-2.2,0.8s-0.9,1.2-0.9,2.1h0.1v0.8c0,0.8,0.2,1.6,0.8,2.1s1.4,0.8,2.3,0.8c0.9,0,1.6-0.3,2.2-0.8
		c0.5-0.5,0.8-1.2,0.8-2.1v-0.7c0-0.9-0.3-1.7-0.9-2.2C35.4,25.7,34.6,25.4,33.7,25.4z M34.9,29.1c0,0.9-0.4,1.3-1.2,1.3
		c-0.4,0-0.6-0.2-0.9-0.4c-0.3-0.2-0.4-0.5-0.4-0.9v-0.7c0-0.4,0.1-0.8,0.3-1c0.2-0.3,0.5-0.4,0.9-0.4c0.4,0,0.7,0.1,1,0.5
		c0.2,0.2,0.3,0.5,0.3,0.9V29.1z" />

        <path d="M29,23.2c0.5-0.5,0.8-1.2,0.8-2.1v-0.7c0-0.9-0.3-1.7-0.8-2.2s-1.3-0.8-2.2-0.8c-0.9,0-1.7,0.3-2.2,0.8s-0.8,1.2-0.8,2.1
		V21c0,0.9,0.4,1.7,0.9,2.2S26,24,26.9,24C27.8,24,28.5,23.7,29,23.2z M25.9,22c-0.2-0.2-0.3-0.5-0.3-0.9v-0.7c0-0.4,0-0.7,0.3-1
		c0.3-0.3,0.5-0.4,0.9-0.4c0.4,0,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.5,0.3,0.9V21c0,0.4-0.1,0.7-0.3,1s-0.5,0.4-0.9,0.4
		C26.4,22.4,26.1,22.2,25.9,22z" />

    </g>
</svg>

<svg #PostageCourier *ngIf="expenseCategoryId==23" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0"
            d="M22.7,27.9c0.9,0.8,2.5,0.8,3.4,0l18-15.7c-0.3-0.2-0.6-0.3-1-0.3H5.8c-0.4,0-0.7,0.1-1.1,0.3L22.7,27.9z" />

        <path class="st0" d="M30.5,26l8.3,7.8c0.3,0.3,0.3,0.7,0,1c-0.2,0.1-0.4,0.1-0.6,0.1s-0.4-0.1-0.5-0.2L29.5,27c0,0,0,0,0-0.1
		L27.1,29c-0.7,0.6-1.7,0.9-2.6,0.9c-1,0-1.9-0.3-2.6-1l-2.4-2c0,0,0,0,0,0l-8.3,7.8c-0.2,0.2-0.4,0.2-0.5,0.2
		c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-1l8.3-7.8L3.8,13.2c-0.1,0.3-0.2,0.6-0.2,0.9v24.5c0,1.2,1,2.2,2.2,2.2h37.3
		c1.2,0,2.2-1,2.2-2.2V14.1c0-0.3-0.1-0.6-0.2-0.9L30.5,26z" />

        <path d="M43.1,10.5H5.8c-2,0-3.6,1.6-3.6,3.6v24.4c0,2,1.6,3.6,3.6,3.6h37.3c2,0,3.6-1.6,3.6-3.5V14.1
		C46.7,12.1,45.1,10.5,43.1,10.5z M43.1,11.9c0.4,0,0.7,0.1,1,0.3l-18,15.7c-0.9,0.8-2.5,0.8-3.4,0l-18-15.7
		c0.3-0.2,0.7-0.3,1.1-0.3H43.1z M45.3,38.6c0,1.2-1,2.2-2.2,2.2H5.8c-1.2,0-2.2-1-2.2-2.2V14.1c0-0.3,0.1-0.6,0.2-0.9l14.7,12.7
		l-8.3,7.8c-0.3,0.3-0.3,0.7,0,1c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.5-0.2l8.3-7.8c0,0,0,0,0,0l2.4,2c0.7,0.7,1.6,1,2.6,1
		c0.9,0,1.9-0.3,2.6-0.9l2.4-2.1c0,0,0,0,0,0.1l8.2,7.7c0.1,0.1,0.3,0.2,0.5,0.2s0.4,0,0.6-0.1c0.3-0.3,0.3-0.7,0-1L30.5,26
		l14.6-12.8c0.1,0.3,0.2,0.6,0.2,0.9V38.6z" />

    </g>
</svg>

<svg #Software *ngIf="expenseCategoryId==24" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0"
            d="M24.6,27.6c1.2,0,2.2-1,2.2-2.2c0-1.2-0.9-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C22.4,26.6,23.4,27.6,24.6,27.6z" />

        <path class="st0"
            d="M24.6,34.1c4.8,0,8.7-3.9,8.7-8.7c0-4.8-3.9-8.7-8.7-8.7c-4.8,0-8.7,3.9-8.7,8.7
		C15.9,30.2,19.8,34.1,24.6,34.1z M24.6,21.8c2,0,3.6,1.6,3.6,3.6S26.6,29,24.6,29S21,27.4,21,25.4S22.6,21.8,24.6,21.8z" />

        <path d="M2.7,25.6c0-12.1,9.9-22,22-22c3.8,0,7.6,1,10.9,2.9c0.3,0.1,0.7,0,0.9-0.3c0.1-0.3,0-0.7-0.3-0.9c-3.5-2-7.5-3.1-11.6-3.1
		C18.4,2.2,12.5,4.6,8.1,9c-4.4,4.4-6.8,10.3-6.8,16.5c0,3.6,0.9,7.1,2.5,10.4c1.6,3.1,3.9,5.9,6.6,8c0.1,0.1,0.3,0.1,0.4,0.1
		c0.2,0,0.4-0.1,0.5,0c0.3-0.3,0.2-0.8-0.1-1C5.8,38.8,2.7,32.4,2.7,25.6z" />

        <path d="M45.5,14.9c-1.6-3.1-3.9-5.9-6.6-8c-0.3-0.3-0.8-0.2-1,0.1c-0.3,0.3-0.2,0.8,0.1,1c5.4,4.2,8.5,10.6,8.5,17.4
		c0,12.1-9.9,22-22,22c-3.8,0-7.6-1-10.9-2.9c-0.3-0.1-0.7,0-0.9,0.3c-0.1,0.3,0,0.7,0.3,0.9c3.5,2,7.5,3.1,11.6,3.1
		c6.3,0,12.2-2.6,16.6-7c4.4-4.4,6.8-10.3,6.8-16.5C48,21.7,47.1,18.2,45.5,14.9z" />

        <path d="M24.6,35.5c5.6,0,10.1-4.5,10.1-10.1c0-5.6-4.5-10.1-10.1-10.1c-5.6,0-10.1,4.5-10.1,10.1C14.5,31,19,35.5,24.6,35.5z
		 M24.6,16.7c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7c-4.8,0-8.7-3.9-8.7-8.7C15.9,20.6,19.8,16.7,24.6,16.7z" />

        <path d="M29.9,41.3c0.1,0,0.2,0,0.3-0.1c2.9-1,5.5-2.9,7.5-5.3c2-2.5,3.2-5.4,3.6-8.6c0-0.4-0.2-0.8-0.6-0.8
		c-0.4,0-0.8,0.2-0.8,0.6c-0.6,5.8-4.6,10.9-10.2,12.8c-0.3,0.2-0.5,0.5-0.4,0.9C29.4,41.1,29.6,41.3,29.9,41.3z" />

        <path d="M20,10c-0.2-0.3-0.5-0.5-0.9-0.4c-2.9,1-5.5,2.9-7.5,5.3c-2,2.5-3.2,5.4-3.6,8.6c0,0.4,0.2,0.8,0.7,0.8
		c0.3,0,0.6-0.3,0.7-0.6C10,17.9,14,12.8,19.6,10.9C19.9,10.7,20.1,10.4,20,10z" />

        <path d="M24.6,29c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6S21,23.4,21,25.4S22.6,29,24.6,29z M24.6,23.2c1.3,0,2.2,1,2.2,2.2
		c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2C22.4,24.2,23.4,23.2,24.6,23.2z" />

    </g>
</svg>

<svg #Tips *ngIf="expenseCategoryId==26" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <ellipse class="st0" cx="12.7" cy="24.1" rx="7.8" ry="3" />

        <ellipse class="st0" cx="37.1" cy="12.9" rx="7.8" ry="3" />

        <path d="M37.1,18c5.7,0,10-2.2,10-5.2s-4.3-5.2-10-5.2c-5.7,0-10,2.2-10,5.2C27,15.8,31.3,18,37.1,18z M37.1,9.9
		c4.8,0,7.8,1.8,7.8,3c0,1.2-3,3-7.8,3c-4.8,0-7.8-1.8-7.8-3C29.3,11.7,32.3,9.9,37.1,9.9z" />

        <path
            d="M46.2,18.1c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C47.1,19,46.8,18.3,46.2,18.1z" />

        <path
            d="M46.2,23.8c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C47.1,24.6,46.8,24,46.2,23.8z" />

        <path
            d="M46.2,29.4c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C47.1,30.3,46.8,29.6,46.2,29.4z" />

        <path
            d="M46.2,35.1c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C47.1,35.9,46.8,35.3,46.2,35.1z" />

        <path
            d="M46.2,40.7c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C47.1,41.6,46.8,40.9,46.2,40.7z" />

        <path d="M21.9,29.4c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		S2.7,30.6,3,31.2c1,2.2,5,3.8,9.7,3.8c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C22.8,30.3,22.5,29.6,21.9,29.4z" />

        <path
            d="M21.9,35.1c-0.6-0.2-1.2,0.1-1.4,0.7c-0.5,1.3-3.6,2.7-7.8,2.7c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,0.9-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C22.8,35.9,22.5,35.3,21.9,35.1z" />

        <path
            d="M21.9,40.7c-0.6-0.2-1.2,0.1-1.4,0.7C20,42.7,16.9,44,12.7,44c-4.1,0-7.1-1.3-7.7-2.6c-0.3-0.6-0.9-0.8-1.5-0.5
		c-0.6,0.3-0.8,1-0.5,1.5c1.1,2.3,5,3.9,9.7,3.9c2.3,0,4.5-0.4,6.2-1.1c1.9-0.7,3.2-1.8,3.6-3.1C22.8,41.6,22.5,40.9,21.9,40.7z" />

        <path d="M12.7,19c-5.7,0-10,2.2-10,5.2s4.3,5.2,10,5.2s10-2.2,10-5.2S18.4,19,12.7,19z M12.7,27.1c-4.8,0-7.8-1.8-7.8-3
		c0-1.2,3-3,7.8-3s7.8,1.8,7.8,3C20.5,25.3,17.5,27.1,12.7,27.1z" />

    </g>
</svg>

<svg #TollTax *ngIf="expenseCategoryId==27" [ngClass]="this.className" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px"
    height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="svg-30">

    <g>
        <path class="st0" d="M8.7,44.6l3.9-2.6c0.4-0.3,0.9-0.3,1.3,0l5,3.3l5-3.3c0.4-0.3,0.9-0.3,1.3,0l5,3.4l5-3.4
		c0.4-0.3,0.9-0.3,1.3,0l3.9,2.6V5.1H8.7V44.6z M32.1,9.6h3c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.1-0.6-1.2-1.2
		C30.9,10.1,31.5,9.6,32.1,9.6z M32.1,13.6h3c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.2-0.5-1.2-1.2
		S31.5,13.6,32.1,13.6z M32.1,18.8h3c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.1-0.5-1.2-1.2
		C30.9,19.3,31.5,18.8,32.1,18.8z M32.1,22.9h3c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.1-0.6-1.2-1.2
		C30.9,23.4,31.5,22.9,32.1,22.9z M32.1,28.2h3c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.2-0.5-1.2-1.2
		C30.9,28.7,31.5,28.2,32.1,28.2z M32.1,32.3h3c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-3c-0.6,0-1.1-0.6-1.2-1.2
		C30.9,32.8,31.5,32.3,32.1,32.3z M14,9.6h12.1c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2H14c-0.7,0-1.2-0.6-1.2-1.2
		C12.8,10.1,13.4,9.6,14,9.6z M14,13.6h9c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-9c-0.7,0-1.2-0.5-1.2-1.2S13.4,13.6,14,13.6
		z M14,18.8h12.1c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2H14c-0.7,0-1.2-0.5-1.2-1.2S13.4,18.8,14,18.8z M14,22.9h9
		c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-9c-0.7,0-1.2-0.6-1.2-1.2C12.8,23.4,13.4,22.9,14,22.9z M14,28.2h12.1
		c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2H14c-0.7,0-1.2-0.5-1.2-1.2C12.8,28.7,13.4,28.2,14,28.2z M14,32.3h9
		c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2h-9c-0.7,0-1.2-0.6-1.2-1.2C12.8,32.8,13.4,32.3,14,32.3z" />

        <path d="M45.3,2.8H3.8C3.2,2.8,2.6,3.3,2.6,4s0.5,1.2,1.2,1.2h2.5v41.7c0,0.4,0.2,0.8,0.6,1C7.3,48,7.8,48,8.1,47.8l5-3.3l5,3.4
		c0.4,0.3,0.9,0.3,1.3,0l5-3.3l5,3.4c0.4,0.3,0.9,0.3,1.3,0l5-3.3l5,3.4c0.2,0.1,0.4,0.2,0.6,0.2s0.4,0,0.5-0.1
		c0.4-0.2,0.6-0.6,0.6-1V5.5H45c0.6,0,1.2-0.5,1.2-1.2C46.2,3.6,45.9,2.8,45.3,2.8z M40.4,44.6L36.5,42c-0.4-0.3-0.9-0.3-1.3,0
		l-5,3.4l-5-3.4c-0.4-0.3-0.9-0.3-1.3,0l-5,3.3l-5-3.3c-0.4-0.3-0.9-0.3-1.3,0l-3.9,2.6V5.1h31.7V44.6z" />

        <path
            d="M14,21.2h12.1c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H14c-0.6,0-1.2,0.5-1.2,1.2S13.3,21.2,14,21.2z" />

        <path
            d="M14,25.3h9c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-9c-0.6,0-1.2,0.5-1.2,1.2C12.8,24.7,13.3,25.3,14,25.3z" />

        <path
            d="M32.1,21.2h3c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2C31,20.7,31.5,21.2,32.1,21.2z" />

        <path
            d="M32.1,25.3h3c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2C31,24.7,31.5,25.3,32.1,25.3z" />

        <path
            d="M14,12h12.1c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H14c-0.6,0-1.2,0.5-1.2,1.2C12.8,11.4,13.3,12,14,12z" />

        <path d="M14,16h9c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-9c-0.6,0-1.2,0.5-1.2,1.2S13.3,16,14,16z" />

        <path
            d="M32.1,12h3c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2C31,11.4,31.5,12,32.1,12z" />

        <path d="M32.1,16h3c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2S31.5,16,32.1,16z" />

        <path
            d="M14,30.6h12.1c0.6,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H14c-0.6,0-1.2,0.5-1.2,1.2C12.8,30.1,13.3,30.6,14,30.6z" />

        <path
            d="M14,34.7h9c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-9c-0.6,0-1.2,0.5-1.2,1.2C12.8,34.1,13.3,34.7,14,34.7z" />

        <path
            d="M32.1,30.6h3c0.6,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2C30.9,30.1,31.5,30.6,32.1,30.6z" />

        <path
            d="M32.1,34.7h3c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2C31,34.1,31.5,34.7,32.1,34.7z" />

    </g>
</svg>

<img *ngIf="expenseCategoryId == 28" [ngClass]="this.className" src="../../assets/svg-icons/ccadvance.svg" alt="cash"
  class="svg-30">
