import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-calendar-date-month-input',
  templateUrl: "./formly-calendar-date-month-input.component.html"
})
export class FormlyCalendarDateMonthInputComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {
  @Output() dateChange = new EventEmitter<Date>();
  datePickerOptions: FlatpickrOptions = {
    mode: "single",
    defaultDate: new Date(),
    altInput: true,
    altFormat: "d-M",
    dateFormat: "m/d/Y"
  };
  @ViewChild("fydate") fydate: any;
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  isLabelVisible: boolean = false;
  calendarLabel: string = "";
  addTopMargin: boolean = true;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    this.datePickerOptions.defaultDate = new Date();
    if (this.field && this.field.formControl && this.field.formControl.value) {
      this.datePickerOptions.defaultDate = new Date(this.field.formControl.value);
    }
    else{
      this.formControl.setValue(new Date());
    }


    if (this.field && this.field.templateOptions) {
      this.calendarLabel = this.field.templateOptions.calendarLabel;
      this.isLabelVisible = this.field.templateOptions.isLabelVisible;
    }

    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    //Initialize datePicker
    this.fydate.nativeElement.flatpickr(this.datePickerOptions);
  }

  onValueChange() {
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }
  }


}