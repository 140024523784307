import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-radio-button',
  templateUrl: "./formly-radio-button.component.html",
  styleUrls: ['./formly-radio-button.component.scss']
})
export class FormlyRadioButtonComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  valueSubscription: Subscription;

  currentSelection: string;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  initialize() {
    const fieldKey = this.field.key.toString();
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    this.valueSubscription = this.formControl.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        this.currentSelection = value[0];
      }
      else {
        this.currentSelection = '';
      }
    });
    if (this.field.key && this.model[fieldKey] && this.model[fieldKey].length > 0) {
      this.currentSelection = this.model[fieldKey][0];
    }
    else {
      this.currentSelection = '';
    }
  }

  onClick(item: any) {
    this.currentSelection = item.key;
    this.model[this.field.key.toString()] = [this.currentSelection];
    if (this.to.change) {
      this.to.change(this, this.currentSelection);
    }
  }

}
