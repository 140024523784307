<h4 class="eod-text-secondary fw-600">{{'step_4_5' | translate}}</h4>
<h1 class="onboarding-title pt-3">{{'invite_your_Employees' | translate}}</h1>
<div class="form onboarding mt-5 d-flex flex-column align-content-between">
       <form class="row">
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="first-name-claimant1">{{'label_first_name' | translate}}<span>*</span></label>
                     <input class="form-control form-input"
                            type="search"
                            id="fname1"
                            [(ngModel)]="newAttribute1.firstName"
                            maxlength="30"
                            name="newAttribute1FirstName"
                            required
                            #fname1="ngModel"
                            appBlockCopyPaste
                            autocomplete="off"
                            autofocus
                            (input)="
                  noWhiteSpaceAtTheStart($event); onFirstNameChange($event)
                "
                            (keypress)="omit_special_char($event)" />
                     <div *ngIf="
                      (!newAttribute1.firstName ||
                        newAttribute1.firstName.trim() == '') &&
                      submitted
                    "
                          class="alert-danger">
                            <div *ngIf="
                        (!newAttribute1.firstName ||
                          newAttribute1.firstName.trim() == '') &&
                        submitted
                      ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="last-name-claimant1">{{'label_last_name' | translate }}</label>
                     <input class="form-control form-input"
                            maxlength="30"
                            type="search"
                            id="lName1"
                            #lname1="ngModel"
                            [(ngModel)]="newAttribute1.lastName"
                            (input)="noWhiteSpaceAtTheStart($event)"
                            (keypress)="omit_special_char($event)"
                            name="newAttribute1LastName"
                            appBlockCopyPaste
                            autocomplete="off" />

              </div>
              <div class="form-group col-md-6 px-0">
                     <label class="input-label"
                            for="email-claimant1">{{'label_email' | translate }}<span>*</span></label>
                     <input class="form-control form-input"
                            maxlength="100"
                            type="search"
                            id="newAttribute1Email"
                            [(ngModel)]="newAttribute1.email"
                            #email1="ngModel"
                            (input)="onEmailIdChange($event)"
                            (focusout)="onEmailFocusOut($event)"
                            name="newAttribute1Email"
                            pattern="emailPattern"
                            autocomplete="off" />
                     <div *ngIf="
                            (!newAttribute1.email ||
                              newAttribute1.email == '' ||
                              newAttribute1.isInvalidEmail ||
                              newAttribute1.isUserBelongsToOtherOrganisation ||
                              newAttribute1.isSelfInvite ||
                              newAttribute1.IsDuplicate) &&
                            submitted
                          "
                          class="alert-danger">
                            <div *ngIf="
                              (!newAttribute1.email || newAttribute1.email == '') &&
                              submitted
                            ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>

                            <div
                                 *ngIf="newAttribute1.isInvalidEmail && !(!newAttribute1.email || newAttribute1.email == '')">
                                   <b>{{ "label_Emailisnotvalid" | translate }}</b>
                            </div>
                            <div *ngIf="
                              newAttribute1.isUserBelongsToOtherOrganisation && !newAttribute1.IsDuplicate  && !(!newAttribute1.email || newAttribute1.email == '') &&
                              submitted
                            ">
                                   <b>{{
                                          "claimant_exists" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute1.isSelfInvite && submitted">
                                   <b>{{
                                          "you_can_not_invite_yourself" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute1.IsDuplicate && !newAttribute1.isSelfInvite && submitted">
                                   <b>{{
                                          "email_is_already_in_invite_list" | translate
                                          }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="first-name-claiment2">{{'label_first_name' | translate}}<span>*</span></label>
                     <input class="form-control form-input"
                            type="search"
                            id="fname2"
                            [(ngModel)]="newAttribute2.firstName"
                            maxlength="30"
                            name="newAttribute2FirstName"
                            required
                            #fname2="ngModel"
                            appBlockCopyPaste
                            autocomplete="off"
                            (input)="
                  noWhiteSpaceAtTheStart($event); onFirstNameChange($event)
                "
                            (keypress)="omit_special_char($event)" />

                     <div *ngIf="
                      (!newAttribute2.firstName ||
                        newAttribute2.firstName.trim() == '') &&
                      submitted
                    "
                          class="alert-danger">
                            <div *ngIf="
                        (!newAttribute2.firstName ||
                          newAttribute2.firstName.trim() == '') &&
                        submitted
                      ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="last-name-claiment2">{{'label_last_name' | translate}}</label>
                     <input class="form-control form-input"
                            maxlength="30"
                            type="search"
                            id="lName2"
                            #lname2="ngModel"
                            [(ngModel)]="newAttribute2.lastName"
                            (input)="noWhiteSpaceAtTheStart($event)"
                            (keypress)="omit_special_char($event)"
                            name="newAttribute2LastName"
                            appBlockCopyPaste
                            autocomplete="off" />

              </div>
              <div class="form-group col-md-6 px-0">
                     <label class="input-label"
                            for="email-claiment2">{{'label_email' | translate}}<span>*</span></label>
                     <input class="form-control form-input"
                            maxlength="100"
                            type="search"
                            id="newAttribute2Email"
                            [(ngModel)]="newAttribute2.email"
                            #email2="ngModel"
                            (input)="onEmailIdChange($event.target.value)"
                            (focusout)="onEmailFocusOut($event)"
                            name="newAttribute2Email"
                            pattern="emailPattern"
                            autocomplete="off" />
                     <div *ngIf="
                      (!newAttribute2.email ||
                        newAttribute2.email == '' ||
                        newAttribute2.isInvalidEmail ||
                        newAttribute2.isUserBelongsToOtherOrganisation ||
                        newAttribute2.isSelfInvite ||
                        newAttribute2.IsDuplicate) &&
                      submitted
                    "
                          class="alert-danger">
                            <div *ngIf="
                        (!newAttribute2.email || newAttribute2.email == '') &&
                        submitted
                      ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>

                            <div *ngIf="newAttribute2.isInvalidEmail">
                                   <b>{{ "label_Emailisnotvalid" | translate }}</b>
                            </div>
                            <div *ngIf="
                        newAttribute2.isUserBelongsToOtherOrganisation && !newAttribute2.IsDuplicate &&
                        submitted
                      ">
                                   <b>{{
                                          "claimant_exists" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute2.isSelfInvite && submitted">
                                   <b>{{
                                          "you_can_not_invite_yourself" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute2.IsDuplicate && !newAttribute2.isSelfInvite && submitted">
                                   <b>{{
                                          "email_is_already_in_invite_list" | translate
                                          }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="first-name-claiment3">{{ "label_first_name" | translate }}<span>*</span></label>
                     <input class="form-control form-input"
                            type="search"
                            id="fname3"
                            [(ngModel)]="newAttribute.firstName"
                            maxlength="30"
                            name="newAttributeFirstName"
                            required
                            #fname="ngModel"
                            appBlockCopyPaste
                            autocomplete="off"
                            (input)="
                  noWhiteSpaceAtTheStart($event); onFirstNameChange($event)
                "
                            (keypress)="omit_special_char($event)" />
                     <div *ngIf="
                            (!newAttribute.firstName ||
                              newAttribute.firstName.trim() == '') &&
                            submitted
                          "
                          class="alert-danger">
                            <div *ngIf="
                              (!newAttribute.firstName ||
                                newAttribute.firstName.trim() == '') &&
                              submitted
                            ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-3 pl-0">
                     <label class="input-label"
                            for="last-name-claiment3">{{ "label_last_name" | translate }}</label>
                     <input class="form-control form-input"
                            type="search"
                            id="lName3"
                            [(ngModel)]="newAttribute.lastName"
                            maxlength="30"
                            name="newAttributeLastName"
                            required
                            #lname="ngModel"
                            appBlockCopyPaste
                            (keypress)="omit_special_char($event)"
                            (input)="noWhiteSpaceAtTheStart($event)"
                            autocomplete="off" />

              </div>
              <div class="form-group col-md-6 px-0">
                     <label class="input-label"
                            for="email-claiment3">{{ "label_email" | translate }}<span>*</span></label>
                     <input class="form-control form-input"
                            maxlength="100"
                            type="search"
                            id="newAttribute3Email"
                            [(ngModel)]="newAttribute.email"
                            #email="ngModel"
                            (input)="onEmailIdChange($event.target.value)"
                            (focusout)="onEmailFocusOut($event)"
                            name="newAttributeEmail"
                            pattern="emailPattern"
                            autocomplete="off" />
                     <div *ngIf="
                      (!newAttribute.email ||
                        newAttribute.email == '' ||
                        newAttribute.isInvalidEmail ||
                        newAttribute.isUserBelongsToOtherOrganisation ||
                        newAttribute.isSelfInvite ||
                        newAttribute.IsDuplicate) &&
                      submitted
                    "
                          class="alert-danger">
                            <div *ngIf="
                        (!newAttribute.email || newAttribute.email == '') &&
                        submitted
                      ">
                                   <b>{{ "label_Required" | translate }}</b>
                            </div>

                            <div *ngIf="newAttribute.isInvalidEmail">
                                   <b>{{ "label_Emailisnotvalid" | translate }}</b>
                            </div>
                            <div *ngIf="
                        newAttribute.isUserBelongsToOtherOrganisation && !newAttribute.IsDuplicate && 
                        submitted
                      ">
                                   <b>{{
                                          "claimant_exists" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute.isSelfInvite && submitted">
                                   <b>{{
                                          "you_can_not_invite_yourself" | translate
                                          }}</b>
                            </div>
                            <div *ngIf="newAttribute.IsDuplicate && !newAttribute.isSelfInvite && submitted">
                                   <b>{{
                                          "email_is_already_in_invite_list" | translate
                                          }}</b>
                            </div>
                     </div>

              </div>
              <div class="col-md-12 px-0 text-center pb-2">
                     <p *ngIf="IsDuplicateGlobal"
                        class="eod-text-danger w-100pc">
                            <b> {{ ErrorMessage }}</b>
                     </p>

                     <p *ngIf="!hasValidData"
                        class="eod-text-danger w-100pc">
                            <b> {{ "please_check_validations" | translate }}</b>
                     </p>

                     <p *ngIf="!ShowSpecialCharError && hasValidData && !IsDuplicateGlobal"
                        class="eod-text-danger w-100pc">
                            <b> {{ "numeric_keys_not_allowed" | translate }}</b>
                     </p>

              </div>

       </form>
       <button class="btn eod-btn-secondary btn-lg mt-auto onboarding-width"  [disabled]="IsInviteCanBeDisabled"
               (click)="OnInviteClick()">{{'next' | translate}}</button>
</div>
