<div class="row">
    <div class="pl-0"
         [ngClass]="isInviteEmployeeActive ? 'col-md-5' : 'col-md-6'">
        <app-onboarding-banner [currentStep]="currentStep"></app-onboarding-banner>
    </div>
    <div class="pl-5" [ngClass]="isInviteEmployeeActive ? 'col-md-7' : 'col-md-6'">
        <div class="h-100vh d-flex flex-column justify-content-between overflow-auto">
            <div class="logo-container py-4">
                <a href="https://www.expenseondemand.com/">
                    <img src="../../assets/images/logo.svg"
                         alt="Expense On Demand" />
                </a>
            </div>
            <div class="pr-2">
                <app-login-new *ngIf="isLoginActive"
                               (gotoNextStepEvent)="onLoginClick($event)"></app-login-new>
    
                <app-register *ngIf="isSignupActive"
                              (gotoNextStepEvent)="gotoOTPVerification()"
                              (gotoLoginEvent)="gotoLogin()"></app-register>
                <app-otp-verification *ngIf="isOTPVerificationActive"
                                      (gotoNextStepEvent)="gotoOrgDetails()"></app-otp-verification>
                <app-organisation-details *ngIf="isOrgDetailActive"
                                          (gotoNextStepEvent)="gotoInviteEmployee()"></app-organisation-details>
                <app-invite-employees *ngIf="isInviteEmployeeActive"
                                      (gotoNextStepEvent)="gotoAppDownload()"></app-invite-employees>
                <app-app-download *ngIf="isAppDownloadActive"></app-app-download>
            </div>
            <p class="py-3 mt-auto f-11">{{'footer_expenseOnDemand_AllRights' | translate}}</p>
        </div>
        
    </div>

</div>
