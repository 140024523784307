import { Component, ViewEncapsulation } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'button-cell',
  templateUrl: './buttons-renderer.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ButtonsRendererComponent implements ICellRendererAngularComp {
  actionButtons: any[] = [];
  params: ICellRendererParams;
  constructor() {

  }

  onUserAction(action) {
    this.params.data.userAction = action;
    this.params.node.setData(this.params.data);
    this.params.eGridCell.click();
  }

  initilize(params: ICellRendererParams) {
    if (params && params.data) {
      this.params = params;
      if(params.colDef && params.colDef.headerComponentParams)
      {
        this.actionButtons = JSON.parse(JSON.stringify(params.colDef.headerComponentParams));
        for (let i = 0; i < this.actionButtons.length; i++) {
          this.actionButtons[i].isHidden = this.actionButtons[i].hideProperty && this.params.data[this.actionButtons[i].hideProperty]
        }
      }
    }
    else {
      this.actionButtons = [];
    }
  }


  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
