import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { DatePipe, KeyValue } from "@angular/common";
import { CdkDragStart,CdkDragEnd, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PreviewOutputModel } from "../../../_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "app-img-preview-panel",
    templateUrl: "./img-preview.component.html"
})

export class ImagePreviewDetailComponent implements OnInit {
    @Input() scannedText : any;
    @Input() previewImageUrl : string;
    @Input() selectedItem : any;
    @ViewChild('canvas', { static: true }) canvasRef: ElementRef<HTMLCanvasElement>;
    param:string;
    date: string = "";
    amount: string = "";
    receiptNumber : string;
    supplier: string;
    hasFormChanges:boolean = false;
    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() savePanelEvent = new EventEmitter<PreviewOutputModel>();
    rect:any;
    constructor(private datepipe: DatePipe) {}
    
    ngOnInit()
    {
      this.hasFormChanges = false;
      this.amount = "Not clear enough for scanning";
      this.date = "Not clear enough for scanning";
      this.receiptNumber = "Not clear enough for scanning";
      this.supplier = "Not clear enough for scanning";
      if(this.scannedText)
      {
        this.hasFormChanges= true;
        this.supplier = this.scannedText.find(it=>it.key == "MerchantName").value;
        this.amount = this.scannedText.find(it=>it.key == "Total").value;  
        this.date = this.scannedText.find(it=>it.key == "TransactionDate").value;
        this.drawPolygonOnImage();        
      }
    }

    
    drawPolygonOnImage(): void {
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
  
      const image = new Image();
      image.src = this.previewImageUrl; // Replace with the path to your image
  
      image.onload = () => {
        // Set canvas size to match the image size
        canvas.width = image.width;
        canvas.height = image.height;
  
        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0);
  
        // Define the polygon coordinates
        //Draw polygon for supplier
        const polygon=[];
        let supplierPolygon = this.scannedText.find(it=>it.key == "MerchantName").coordinatesList;
        supplierPolygon.forEach(element => {
          polygon.push({x: element.xCoordinate, y: element.yCoordinate});
        });        
  
        // Draw the polygon on the canvas
        this.drawPolygon(ctx, polygon, '1');
        const polygon1=[];
        let expenseDatePolygon = this.scannedText.find(it=>it.key == "TransactionDate").coordinatesList;
        expenseDatePolygon.forEach(element => {
          polygon1.push({x: element.xCoordinate, y: element.yCoordinate});
        });    
          
        // Draw the polygon on the canvas
        this.drawPolygon(ctx, polygon1, '2');
        const polygon2=[];
        let amountPolygon = this.scannedText.find(it=>it.key == "Total").coordinatesList;
        amountPolygon.forEach(element => {
          polygon2.push({x: element.xCoordinate, y: element.yCoordinate});
        });    
         
        // Draw the polygon on the canvas
        this.drawPolygon(ctx, polygon2, '3');
      };

      this.rect = canvas.getBoundingClientRect();
    }
  
    drawPolygon(ctx: CanvasRenderingContext2D, points: { x: number, y: number }[], type:string): void {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }
      ctx.closePath();
      ctx.lineWidth = 7;
      ctx.strokeStyle = this.getStrokeColor(type);
      ctx.stroke();
    }

    getStrokeColor(elementType : string)
    {
      var string = '';
      switch(elementType)
      {
        case '3': string = 'red';
                  break;
        case '2': string = 'green'
                  break;
        case '1': string = 'blue'
        break;
      }
      return string;
    }

    onMouseMove(event: MouseEvent): void {
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;
      const rect = canvas.getBoundingClientRect();
  
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
    }
  

    fnSaveReceipt()
    {

    }        

    fnClosePanel(){
      this.closePanelEvent.emit(false);
    }

    fnSavePreview()
    {
      let previewOutModel = new PreviewOutputModel();
      previewOutModel.amount = this.amount;
      previewOutModel.date = this.date;
      previewOutModel.receiptNumber = this.receiptNumber == "Not clear enough for scanning" ? "" : this.receiptNumber;
      previewOutModel.supplier = this.supplier;
      previewOutModel.receipt = this.selectedItem;
      this.savePanelEvent.emit(previewOutModel);
    }

    droppedItems: string[] = [];

    drop(event: CdkDragDrop<string[]>) {
    
    }

    fnGetDate(_event)
    {
      this.date=_event;
    }

    fnGetAmount(_event)
    {
      this.amount=_event;
    }

    fnGetSupplier(_event)
    {
      this.supplier=_event;
    }
  
    fnGetReceiptNumber(_event)
    {
      this.receiptNumber=_event;
    }
}