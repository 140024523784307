<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel d-flex flex-column w-55pc" style="z-index: 99999;">
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
    <h2> {{ panelHeading}}</h2>
    <div>

        <button type="button" *ngIf="inputData?.isEditMode" class="btn eod-btn-outline-warning height-38px mr-2" (click)="fnShowContainerLoggingPanel()">
            {{ 'label_log' | translate }}
        </button>

        <button class="btn eod-btn-primary height-38px mr-2" 
        [ngClass]="{'disabled': disableSaveButton 
        || !(this.containerForm && this.containerForm.form && this.containerForm.form.dirty)}"
        (click)="fnSubmitContainer()" >
            {{'label_save' | translate}}
        </button>

        <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
            {{ 'label_close' | translate }}
        </button>
    </div>
    </header>

     <div>
        <div class="block mt-2">
            <p>{{ 'add_container_info_note' | translate }}</p>
        </div>
        <dynamic-form [model]="containerFormModel" [fields]="containerFormFields"></dynamic-form>
     </div>

    </div>
</div>


