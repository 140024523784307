<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel d-flex flex-column">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{this.fnGetPanelTitle()}}</h2>
            <div>
                <button class="btn eod-btn-primary height-38px mr-2"
                    [ngClass]="{ 'disabled': fnIsSubmitDisabled() || !this.hasFormChanges}"
                    (click)="fnSelectCustomerCostCentreProject()">
                    {{ "save" | translate }}
                </button>
                <button class="btn eod-btn-outline-warning height-38px mr-2" (click)="fnClosePanel()">
                    {{ "close" | translate }}
                </button>
            </div>
        </header>
        <div class="block mt-2">
            <div class="input-group">
                <input type="text" class="form-control searchField" #searchField
                    [ngClass]="{ 'has-value': searchField.value }" name="searchText"
                    [(ngModel)]="searchText" autocomplete="off">
                <label for="ExpenseCategory">{{ "label_search_criterion" | translate: param }}</label>
                <span class="icons"><a>
                        <i class="fas fa-search" aria-hidden="true"></i>
                    </a>
                </span>
            </div>
        </div>
        <div class="block padding-top-10 heightCalc-12 overflow-auto scrollbar-visible">
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <h6 class="border-bottom padding-bottom-10">{{this.fnGetGridTitle()}}</h6>

                    <div class="text-center noDataAvailable disabled opacity-60"
                        *ngIf="(customerCostCentreProjectList | grdFilter : {entityName: searchText, entityCode: searchText}).length === 0">
                        {{ "no_data_available" | translate: param }}
                    </div>

                    <ul class="exportReportWrite-exportFile"
                        *ngIf="(customerCostCentreProjectList | grdFilter : {entityName: searchText, entityCode: searchText}).length > 0">
                        <li class="border-bottom padding-bottom-0 lH-40">
                            <div id="accordion"
                                *ngFor="let item of customerCostCentreProjectList | grdFilter: { entityName: searchText, entityCode: searchText}">
                                <div class="card"
                                    [ngClass]="(customerCostCentreProjectList.length > 1)? 'margin-top-10':'margin-top-0'">
                                    <div class="card-header padding-top-0 padding-left-10 text-ellipsis cursor-pointer"
                                        (click)="fnOnChangeEntity(item.entityId);hasFormChanges = true;">
                                        <span class="card-link padding-0" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#' + item.entityId">
                                            <input type="checkbox" [checked]="item.entityId ==selectedEntityId"
                                                (change)="hasFormChanges = true;">
                                            {{item.entityName}} -  {{item.entityCode}}
                                            <small class="d-block padding-left-22 lH-0"
                                                *ngIf="item.entityType == projectBudgetEntityType.Customer">
                                                {{'customer' | translate:param}}
                                            </small>
                                            <small class="d-block padding-left-22 lH-0"
                                                *ngIf="item.entityType == projectBudgetEntityType.CostCentre && !item.isHomeCostCentre">
                                                {{'label_cost_centre' | translate:param}}
                                            </small>
                                            <small class="d-block padding-left-22 lH-0"
                                                *ngIf="item.entityType == projectBudgetEntityType.CostCentre && item.isHomeCostCentre">
                                                {{'home_cost_centre' | translate:param}}
                                            </small>

                                        </span>
                                    </div>
                                    <div [id]="item.entityId" class="in collapse show" data-bs-parent="#accordion"
                                        *ngIf="selectedEntityId == item.entityId">
                                        <div class="card-body padding-10 bg-aliceBlue">
                                            <div class="row ">
                                                <div class="col-lg-12 no-padding-right-left margin-top-0">
                                                    <div class="panel panel-default padding-10"
                                                        *ngIf="item.projectList && item.projectList.length > 0">
                                                        <div class="panel-heading panel-heading-claimant">
                                                            {{'select_project' | translate:param}}</div>
                                                        <div
                                                            class="panel-body bg-white padding-10 overflow-hidden">
                                                            <ul>
                                                                <li class="lH-normal padding-TB-10 text-ellipsis"
                                                                    *ngFor="let project of item.projectList">
                                                                    <input type="checkbox"
                                                                        (change)="hasFormChanges = true;"
                                                                        (click)="fnOnChangeProject($event,project.projectDetailsId)"
                                                                        [checked]="project.projectDetailsId ==selectedProjectDetailsId">
                                                                    {{project.projectName}}
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="text-center noDataAvailable"
                                                        *ngIf="item.projectList && item.projectList.length == 0">
                                                        {{'no_project_setup' | translate:param}}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>