
import { ErrorInfo } from "../common/error-info";

export interface ICardOrganisationSaveRequest {
  cardOrganisationId: number;
  organisationId: number;
  cardType: number;
  bankName: string;
  liveMode: number;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
}

export class CardOrganisationSaveRequest
  implements ICardOrganisationSaveRequest {
  cardOrganisationId: number;
  organisationId: number;
  cardType: number;
  bankName: string;
  liveMode: number;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  isActive: boolean;
}

export interface ICardOrganisationSaveResponse {
  responseString: string;
}

export class CardOrganisationSaveResponse
  implements ICardOrganisationSaveResponse {
  responseString: string;
}

export interface ICardImportTemplateSaveRequest {
  organisationId: number;
  expenseDateCol: number;
  cardNumberCol: number;
  amountCol: number;
  currencyCol: number;
  exchangeRateCol: number;
  localCurrencyAmountCol: number;
  supplierCol: number;
  dateFormat: number;
  drCrCol: number;
  notes1Col: number;
  notes2Col: number;
  notes3Col: number;
  notes4Col: number;
  referenceNumberCol: number;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
  importDateFormat: string;
}

export class  CardImportTemplateSaveRequest
  implements ICardImportTemplateSaveRequest {
  organisationId: number;
  expenseDateCol: number;
  cardNumberCol: number;
  amountCol: number;
  currencyCol: number;
  exchangeRateCol: number;
  localCurrencyAmountCol: number;
  supplierCol: number;
  dateFormat: number;
  drCrCol: number;
  notes1Col: number;
  notes2Col: number;
  notes3Col: number;
  notes4Col: number;
  referenceNumberCol: number;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
  importDateFormat: string;
}

export interface ICardImportTemplateSaveResponse {
  responseString: string;
}

export class CardImportTemplateSaveResponse
  implements ICardImportTemplateSaveResponse {
  responseString: string;
}

export interface IResponseCardImportTemplateHistory {
  cardImportTemplateHistoryId: number;
  cardImportTemplateId: number;
  // organisationId: number;
  expenseDateCol: number;
  cardNumberCol: number;
  amountCol: number;
  currencyCol: number;
  exchangeRateCol: number;
  localCurrencyAmountCol: number;
  supplierCol: number;
  drCrCol: number;
  notes1Col: number;
  notes2Col: number;
  notes3Col: number;
  // notes4Col: number;
  referenceNumberCol: number;
  notes: string;
  // actionPerformed: number;
  createdBy: number;
  createdOn: Date;
  // createdByName: string;
}

export interface ICardRulesImportSaveRequest {
  OrganisationId: number;
  CardNumberLength: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export class CardRulesImportSaveRequest implements ICardRulesImportSaveRequest {
  OrganisationId: number;
  CardNumberLength: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export interface ICardRulesImportResponse {
  CardRulesImportId: number;
  OrganisationId: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  isDuplicateCards: boolean;
}

export class CardRulesImportResponse implements ICardRulesImportResponse {
  CardRulesImportId: number;
  OrganisationId: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  isDuplicateCards: boolean;
}

export interface ICardRulesEmailReminderSaveRequest {
  OrganisationId: number;
  OutstandingReminder: boolean;
  OutstandingReminderDays: number;
  OutstandingReminderEmailToApprover: boolean;
  OutstandingReminderEmailToFM: boolean;
  OutstandingReminderEmailToOtherFM: boolean;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export class CardRulesEmailReminderSaveRequest
  implements ICardRulesEmailReminderSaveRequest {
  OrganisationId: number;
  OutstandingReminder: boolean;
  OutstandingReminderDays: number;
  OutstandingReminderEmailToApprover: boolean;
  OutstandingReminderEmailToFM: boolean;
  OutstandingReminderEmailToOtherFM: boolean;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export interface ICardRulesEmailReminderResponse {
  CardRulesEmailReminderId: number;
  OrganisationId: number;
  OutstandingReminder: boolean;
  OutstandingReminderDays: number;
  OutstandingReminderEmailToApprover: boolean;
  OutstandingReminderEmailToFM: boolean;
  OutstandingReminderEmailToOtherFM: boolean;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
}

export class CardRulesEmailReminderResponse
  implements ICardRulesEmailReminderResponse {
  CardRulesEmailReminderId: number;
  OrganisationId: number;
  OutstandingReminder: boolean;
  OutstandingReminderDays: number;
  OutstandingReminderEmailToApprover: boolean;
  OutstandingReminderEmailToFM: boolean;
  OutstandingReminderEmailToOtherFM: boolean;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
}

export interface ICardRulesFinancialYearSaveRequest {
  OrganisationId: number;
  FinancialYearStartDate: Date;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export class CardRulesFinancialYearSaveRequest
  implements ICardRulesFinancialYearSaveRequest {
  OrganisationId: number;
  FinancialYearStartDate: Date;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CreatedByName: string;
}

export interface ICardRulesFinancialYearResponse {
  CardRulesFinancialYearId: number;
  OrganisationId: number;
  FinancialYearStartDate: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
}

export class CardRulesFinancialYearResponse
  implements ICardRulesFinancialYearResponse {
  CardRulesFinancialYearId: number;
  OrganisationId: number;
  FinancialYearStartDate: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
}

export interface ICardRulesEmailReminderHistoryResponse {
  cardRulesEmailReminderHistoryId: number;
  cardRulesEmailReminderId: number;
  outstandingReminder: boolean;
  outstandingReminderDays: number;
  outstandingReminderEmailToApprover: boolean;
  outstandingReminderEmailToFM: boolean;
  outstandingReminderEmailToOtherFM: boolean;
  notes: string;
  createdBy: number;
  createdOn: number;
}

export interface ICardRulesFinancialYearHistoryResponse {
  cardRulesFinancialYearHistoryId: number;
  cardRulesFinancialYearId: number;
  financialYearStartDate: Date;
  notes: string;
  createdBy: number;
  createdOn: number;
}

export interface ICardUserAssignmentListRequest {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
}

export class CardUserAssignmentListRequest
  implements ICardUserAssignmentListRequest {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
  constructor(
    organisationId: number,
    status: number,
    pageNumber: number,
    sortOrder: number,
    sortColumn: string
  ) {
    this.organisationId = organisationId;
    this.status = status;
    this.pageNumber = pageNumber;
    this.sortOrder = sortOrder;
    this.sortColumn = sortColumn;
  }
}

export interface ICardUserAssignmentListResponse {
  cardUserAssignmentId: number;
  organisationId: number;
  employeeId: number;
  cardNumber: string;
  employeeCode: string;
  fullName: string;
  firstName: string;
  lastName: string;
  claimantEmail: string;
  isActive: boolean;
  selected: boolean;
  isRowHighlighted: boolean;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
  error: string;
}

export class CardUserAssignmentListResponse
  implements ICardUserAssignmentListResponse {
  cardUserAssignmentId: number;
  organisationId: number;
  employeeId: number;
  cardNumber: string;
  employeeCode: string;
  fullName: string;
  firstName: string;
  lastName: string;
  claimantEmail: string;
  isActive: boolean;
  selected: boolean;
  isRowHighlighted: boolean;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
  error: string;
  constructor(
    cardUserAssignmentId: number,
    organisationId: number,
    employeeId: number,
    cardNumber: string,
    employeeCode: string,
    fullName: string,
    firstName: string,
    lastName: string,
    claimantEmail: string,
    isActive: boolean,
    selected: boolean,
    isRowHighlighted: boolean,
    createdBy: number,
    createdOn: number,
    updatedBy: number,
    updatedOn: number,
    createdByName: string,
    error: string
  ) {
    (this.cardUserAssignmentId = cardUserAssignmentId),
      (this.organisationId = organisationId);
    (this.employeeId = employeeId),
      (this.cardNumber = cardNumber),
      (this.employeeCode = employeeCode),
      (this.fullName = fullName),
      (this.firstName = firstName),
      (this.lastName = lastName),
      (this.claimantEmail = claimantEmail),
      (this.isActive = isActive),
      (this.selected = selected),
      (this.isRowHighlighted = isRowHighlighted),
      (this.createdBy = createdBy),
      (this.createdOn = createdOn),
      (this.updatedBy = updatedBy),
      (this.updatedOn = updatedOn),
      (this.createdByName = createdByName);
    this.error = error;
  }
}

export interface ICardUserAssignmentError {
  cardNumberError: boolean;
}

export class CardUserAssignmentError implements ICardUserAssignmentError {
  cardNumberError: boolean;
  constructor() { }
}

export interface ICardUserAssignmentRequest {
  cardUserAssignmentId: number;
  organisationId: number;
  employeeId: number;
  cardNumber: string;
  isActive: boolean;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
}

export class CardUserAssignmentRequest implements ICardUserAssignmentRequest {
  cardUserAssignmentId: number;
  organisationId: number;
  employeeId: number;
  cardNumber: string;
  isActive: boolean;
  createdBy: number;
  createdOn: number;
  updatedBy: number;
  updatedOn: number;
  createdByName: string;
  constructor(
    cardUserAssignmentId: number,
    organisationId: number,
    employeeId: number,
    cardNumber: string,
    isActive: boolean,
    createdBy: number,
    createdOn: number,
    updatedBy: number,
    updatedOn: number,
    createdByName: string
  ) {
    this.cardUserAssignmentId = cardUserAssignmentId;
    this.organisationId = organisationId;
    this.employeeId = employeeId;
    this.cardNumber = cardNumber;
    this.isActive = isActive;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
    this.createdByName = createdByName;
  }
}

export interface ICardUserAssignmentHistoryResponse {
  cardUserAssignmentHistoryId: number;
  cardUserAssignmentId: number;
  cardNumber: string;
  isActive: boolean;
  notes: string;
  createdBy: number;
  createdOn: number;
}

export interface ICSVRecordExport {
  EmployeeID: string;
  ClaimantName: string;
  Email: string;
  CreditCardNumber: string;
  Error: string;
}

export class CSVRecordExport implements ICSVRecordExport {
  EmployeeID: string;
  ClaimantName: string;
  Email: string;
  CreditCardNumber: string;
  Error: string;
  constructor() { }
}

export interface ICUACsvRecord {
  claimantCode: string;
  employeeName: string;
  email: string;
  cardNumber: string;
  error: string;
}

export class CUACsvRecord implements ICUACsvRecord {
  claimantCode: string;
  employeeName: string;
  email: string;
  cardNumber: string;
  error: string;

  constructor() { }
}

export interface IImportFormatResponse {
  expenseDateCol: number;
  cardNumberCol: number;
  amountCol: number;
  currencyCol: number;
  exchangeRateCol: number;
  localCurrencyAmountCol: number;
  supplierCol: number;
  drCrCol: number;
  notes1Col: number;
  notes2Col: number;
  notes3Col: number;
  notes4Col: number;
  referenceNumberCol: number;
}

export class ImportFormatResponse implements IImportFormatResponse {
  expenseDateCol: number;
  cardNumberCol: number;
  amountCol: number;
  currencyCol: number;
  exchangeRateCol: number;
  localCurrencyAmountCol: number;
  supplierCol: number;
  drCrCol: number;
  notes1Col: number;
  notes2Col: number;
  notes3Col: number;
  notes4Col: number;
  notes5Col: number;
  referenceNumberCol: number;
  dateFormat: number;
  constructor(
    expenseDateCol: number,
    cardNumberCol: number,
    amountCol: number,
    currencyCol: number,
    exchangeRateCol: number,
    localCurrencyAmountCol: number,
    supplierCol: number,
    //drCrCol: number,
    notes1Col: number,
    notes2Col: number,
    notes3Col: number,
    notes4Col: number,
    notes5Col: number,
    referenceNumberCol: number,
    dateFormat: number
  ) {
    this.expenseDateCol = expenseDateCol;
    this.cardNumberCol = cardNumberCol;
    this.amountCol = amountCol;
    this.currencyCol = currencyCol;
    this.exchangeRateCol = exchangeRateCol;
    this.localCurrencyAmountCol = localCurrencyAmountCol;
    this.supplierCol = supplierCol;
    //this.drCrCol = drCrCol;
    this.notes1Col = notes1Col;
    this.notes2Col = notes2Col;
    this.notes3Col = notes3Col;
    this.notes4Col = notes4Col;
    this.notes5Col = notes5Col;
    this.referenceNumberCol = referenceNumberCol;
    this.dateFormat = dateFormat;
  }
}

export interface ICSVRecordSampleCCImportTemplateExport {
  ExpenseDate: string;
  CardNo: string;
  ReferenceNo: string;
  Amount: string;
  Supplier: string;
}

export class CSVRecordSampleCCImportTemplateExport
  implements ICSVRecordSampleCCImportTemplateExport {
  ExpenseDate: string;
  CardNo: string;
  ReferenceNo: string;
  Amount: string;
  Supplier: string;
  constructor() { }
}

export interface ICardReviewUploadedStatementRequest {
  organisationId: number;
  uploadedCsvFileData: string[];
}

export class CardReviewUploadedStatementRequest
  implements ICardReviewUploadedStatementRequest {
  organisationId: number;
  uploadedCsvFileData: string[];
  constructor(organisationId: number, uploadedCsvFileData: string[]) {
    this.organisationId = organisationId;
    this.uploadedCsvFileData = uploadedCsvFileData;
  }
}

export interface ICardStatementReviewData {
  expenseDate: string;
  cardNumber: string;
  amount: string;
  currency: string;
  exchangeRate: string;
  localCurrencyAmount: string;
  supplier: string;
  drCr: string;
  notes1: string;
  notes2: string;
  notes3: string;
  notes4: string;
  referenceNumber: string;
  error: boolean;
}

export class CardStatementReviewData implements ICardStatementReviewData {
  expenseDate: string;
  cardNumber: string;
  amount: string;
  currency: string;
  exchangeRate: string;
  localCurrencyAmount: string;
  supplier: string;
  drCr: string;
  notes1: string;
  notes2: string;
  notes3: string;
  notes4: string;
  referenceNumber: string;
  error: boolean;
}

export interface ICardDismissedExpenseListRequest {
  orgId: number;
  skipRecords: number;
  noOfRecords: number;
}

export class CardDismissedExpenseListRequest
  implements ICardDismissedExpenseListRequest {
  orgId: number;
  skipRecords: number;
  noOfRecords: number;
  constructor(
    orgId: number,
    skipRecords: number,
    noOfRecords: number
  ) {
    this.orgId = orgId;
    this.skipRecords = skipRecords;
    this.noOfRecords = noOfRecords;
  }
}

export interface ICardDismissedTransactionListResponse {
  amount: number;
  cardNumber: string;
  dismissJustificationNotes: string;
  dismissedBy: string;
  expenseDate: number;
  expenseId: number;
  notes: string;
  orgId: number;
  supplier: string;
  userId: number;
  selected: boolean;
  isRowHighlighted: boolean;
}

export class CardDismissedTransactionListResponse
  implements ICardDismissedTransactionListResponse {
  amount: number;
  cardNumber: string;
  dismissJustificationNotes: string;
  dismissedBy: string;
  expenseDate: number;
  expenseId: number;
  notes: string;
  orgId: number;
  supplier: string;
  userId: number;
  selected: boolean;
  isRowHighlighted: boolean;
  constructor(
    amount: number,
    cardNumber: string,
    dismissJustificationNotes: string,
    dismissedBy: string,
    expenseDate: number,
    expenseId: number,
    notes: string,
    orgId: number,
    supplier: string,
    userId: number,
    selected: boolean,
    isRowHighlighted: boolean
  ) {
    this.amount = amount;
    this.cardNumber = cardNumber;
    this.dismissJustificationNotes = dismissJustificationNotes;
    this.dismissedBy = dismissedBy;
    this.expenseDate = expenseDate;
    this.expenseId = expenseId;
    this.notes = notes;
    this.orgId = orgId;
    this.supplier = supplier;
    this.userId = userId;
    this.selected = selected;
    this.isRowHighlighted = isRowHighlighted;
  }
}

export interface ICardClaimantAssignDismissedRequest {
  expenseId: number;
  userId: number;
}

export class CardClaimantAssignDismissedRequest
  implements ICardClaimantAssignDismissedRequest {
  expenseId: number;
  userId: number;
  constructor(expenseId: number, userId: number) {
    this.expenseId = expenseId;
    this.userId = userId;
  }
}

export interface ICardDashboardImports {
  importDate: number;
  statementDate: string;
  transactionOriginal: number;
  transactionNotImported: number;
  amount: number;
  transactionImported: number;
  claimants: number;
  completedAmount: number;
  completedTransaction: number;
  completedClaimants: number;
  pendingAmount: number;
  pendingTransaction: number;
  pendingClaimants: number;
  cardImportOriginalFileId: number;
  isRowHighlighted: boolean;
}

export class CardDashboardImportsResponse implements ICardDashboardImports {
  importDate: number;
  statementDate: string;
  transactionOriginal: number;
  transactionNotImported: number;
  amount: number;
  transactionImported: number;
  claimants: number;
  completedAmount: number;
  completedTransaction: number;
  completedClaimants: number;
  pendingAmount: number;
  pendingTransaction: number;
  pendingClaimants: number;
  cardImportOriginalFileId: number;
  isRowHighlighted: boolean;
  constructor(
    importDate: number,
    statementDate: string,
    transactionOriginal: number,
    transactionNotImported: number,
    amount: number,
    transactionImported: number,
    claimants: number,
    completedAmount: number,
    completedTransaction: number,
    completedClaimants: number,
    pendingAmount: number,
    pendingTransaction: number,
    pendingClaimants: number,
    cardImportOriginalFileId: number,
    isRowHighlighted: boolean
  ) {
    this.importDate = importDate;
    this.statementDate = statementDate;
    this.transactionOriginal = transactionOriginal;
    this.transactionNotImported = transactionNotImported;
    this.amount = amount;
    this.transactionImported = transactionImported;
    this.claimants = claimants;
    this.completedAmount = completedAmount;
    this.completedTransaction = completedTransaction;
    this.completedClaimants = completedClaimants;
    this.pendingAmount = pendingAmount;
    this.pendingTransaction = pendingTransaction;
    this.pendingClaimants = pendingClaimants;
    this.cardImportOriginalFileId = cardImportOriginalFileId;
    this.isRowHighlighted = isRowHighlighted;
  }
}

export interface ICardDashboardTopSpendor {
  userId: number;
  userName: string;
  count: number;
  amount: number;
}

export interface ICardDashboardFrequentlyUsedCategories {
  expenseCategoryId: number;
  expenseCategoryName: string;
  count: number;
  amount: number;
}

export interface ICardDashboardQuickestActionTakers {
  statement: string;
  employee1: string;
  employee2: string;
  employee3: string;
  employee4: string;
  employee5: string;
}

export interface ICardDashboardSlowestActionTakers {
  statement: string;
  employee1: string;
  employee2: string;
  employee3: string;
  employee4: string;
  employee5: string;
}

export interface ICSVTransactionTemplate {
  statementDate: string;
  cardNumber: string;
  expenseDate: string;
  amount: number;
  referenceNumber: string;
  supplier: string;
  currency: string;
  exchangeRate: string;
  localCurrencyAmount: number;
  //drCr: string;
  notes1: string;
  notes2: string;
  notes3: string;
  notes4: string;
  notes5: string;
  uniqueId: number;
}

export class CSVTransactionTemplate implements ICSVTransactionTemplate {
  statementDate: string;
  cardNumber: string;
  expenseDate: string;
  amount: number;
  referenceNumber: string;
  supplier: string;
  currency: string;
  exchangeRate: string;
  localCurrencyAmount: number;
  //drCr: string;
  notes1: string;
  notes2: string;
  notes3: string;
  notes4: string;
  notes5: string;
  uniqueId: number;
}

export interface ICardAssignCardExpensesToClaimantRequest {
  claimantId: number;
  assignedBy: number;
  expenseIds: number[];
  expenseCount: number;
  cardNumber: string;
}

export class CardAssignCardExpensesToClaimantRequest
  implements ICardAssignCardExpensesToClaimantRequest {
    claimantId: number;
    assignedBy: number;
    expenseIds: number[];
    expenseCount: number;
    cardNumber: string;
  constructor(
    claimantId: number,
    assignedBy: number,
    expenseIds: number[],
    expenseCount: number,
    cardNumber: string
  ) {
    this.claimantId = claimantId;
    this.assignedBy = assignedBy;
    this.expenseIds = expenseIds;
    this.expenseCount = expenseCount;
    this.cardNumber = cardNumber;
  }
}

export interface IGetAllEmployeesRequest {
  OrgId: number;
  Status: number;
  Type:number;
}

export class GetAllEmployeesRequest implements IGetAllEmployeesRequest {
  OrgId: number;
  Status: number;
  Type:number;
  IsFromManageEmployee:boolean = false;
  constructor(OrgId: number, Status: number) {
    this.OrgId = OrgId;
    this.Status = Status;
  }
}


export class DismissCardExpenseFormModel {
  dismissJustificationNotes: string;
}


export interface IDismissCardExpenseRequest {
  expenseId: number;
  dismissJustificationNotes: string;
  userId: number;
  orgId: number;
}

export class DismissCardExpenseRequest
  implements IDismissCardExpenseRequest {
  expenseId: number;
  dismissJustificationNotes: string;
  userId: number;
  orgId: number;
  constructor(
    expenseId: number,
    dismissJustificationNotes: string,
    userId: number,
    orgId: number
  ) {
    this.expenseId = expenseId;
    this.dismissJustificationNotes = dismissJustificationNotes;
    this.userId = userId;
    this.orgId = orgId;
  }
}

export interface IClaimantAssociatedExpenseListRequest {
  orgId: number;
  userId: number;
  expenseCategoryId: number;
  supplierName: string;
}

export class ClaimantAssociatedExpenseListRequest
  implements IClaimantAssociatedExpenseListRequest {
  orgId: number;
  userId: number;
  expenseCategoryId: number;
  supplierName: string;
  constructor(
    orgId: number,
    userId: number,
    expenseCategoryId: number,
    supplierName: string
  ) {
    this.orgId = orgId;
    this.userId = userId;
    this.expenseCategoryId = expenseCategoryId;
    this.supplierName = supplierName;
  }
}

export interface IClaimantAssociatedExpenseListResponse {
  expenseList: IAssociatedExpenseDetail[]
}

export class ClaimantAssociatedExpenseListResponse
  implements IClaimantAssociatedExpenseListResponse {
  expenseList: IAssociatedExpenseDetail[]
  constructor(
    expenseList
  ) {
    this.expenseList = expenseList
  }
}

export interface IAssociatedExpenseDetail {
  expenseId: number;
  expenseDate: number;
  orgExpenseCategoryId: number;
  spentCurrencyId: number;
  spentAmount: string;
  supplier: string;
  orgId: number;
  notes: number;
  currencyId: number;
  amount: string;
}

export class FinancialYearRulesFormModel {
  financialYearStartDate: Date;
}

export class EmailReminderRulesFormModel {
  outstandingReminderDays: number;
  outstandingReminderEmailToApprover: boolean;
  outstandingReminderEmailToFM: boolean;
}

export class ImportRulesFormModel {
  cardNumberLength: number;
}

export interface IHistoricalPendingItems {
  pendingItemCount: number;
  pendingItemUserCount: number;
  pendingItemAmt: number;
}

export class HistoricalPendingItems implements IHistoricalPendingItems {
  pendingItemCount: number;
  pendingItemUserCount: number;
  pendingItemAmt: number;
}

export interface ICardPendingExpenseListResponse {
  expenseId: number;
  cardNumber: string;
  expenseDate: number;
  amount: number;
  supplier: string;
  userId: number;
  orgId: number;
  userName: string;
  updatedOn: number;
  isRowHighlighted: boolean;
  cardHolderId: number;  
  cardHolderName: string;
  selected: boolean;
}

export class CardPendingExpenseListResponse implements ICardPendingExpenseListResponse {
  expenseId: number;
  cardNumber: string;
  expenseDate: number;
  amount: number;
  supplier: string;
  userId: number;
  orgId: number;
  userName: string;
  updatedOn: number;
  isRowHighlighted: boolean;
  cardHolderId: number;  
  cardHolderName: string;
  selected: boolean;
}


export interface ICardReassignedAndDeletedExpenseListResponse {
  expenseId: number;
  cardNumber: string;
  expenseDate: number;
  amount: number;
  supplier: string;
  userId: number;
  orgId: number;
  userName: string;
  updatedOn: number;
  cardSupervisoryActionTaken: number;
  cardSupervisoryActionTakenOn: number;
  cardSupervisoryActionTakenBy: number;
  cardSupervisoryActionTakenByUserName: string;
  hideRestoreButton: boolean;
  isRowHighlighted: boolean;
  cardHolderName: string;
}

export class CardReassignedAndDeletedExpenseListResponse implements ICardReassignedAndDeletedExpenseListResponse {
  expenseId: number;
  cardNumber: string;
  expenseDate: number;
  amount: number;
  supplier: string;
  userId: number;
  orgId: number;
  userName: string;
  updatedOn: number;
  cardSupervisoryActionTaken: number;
  cardSupervisoryActionTakenOn: number;
  cardSupervisoryActionTakenBy: number;
  cardSupervisoryActionTakenByUserName: string;
  hideRestoreButton: boolean;
  isRowHighlighted: boolean;
  cardHolderName: string;
}


export interface ICardPendingExpenseListRequest {
  orgId: number;
}

export class CardPendingExpenseListRequest implements ICardPendingExpenseListRequest {
  orgId: number;
}


export interface ICardPendingExpenseListByImportIdRequest {
  orgId: number;
  importId: number;
}

export class CardPendingExpenseListByImportIdRequest implements ICardPendingExpenseListByImportIdRequest {
  orgId: number;
  importId: number;
}



export interface ICardSaveSupervisoryActionRequest {
  userId: number;
  expenseIds: number[];
  cardSupervisoryActionTaken: number;
  cardSupervisoryActionTakenOn: number;
  cardSupervisoryActionTakenBy: number;
}

export class CardSaveSupervisoryActionRequest implements ICardSaveSupervisoryActionRequest {
  userId: number;
  expenseIds: number[];
  cardSupervisoryActionTaken: number;
  cardSupervisoryActionTakenOn: number;
  cardSupervisoryActionTakenBy: number;
}

export interface ISelectUserModel {
  userId: number;
  userName: string;
  supplier: string;
  amount: number;
  expenseDate: number;
  cardNumber: string;
  expenseId: number;
}

export class SelectUserModel implements ISelectUserModel {
  userId: number;
  userName: string;
  supplier: string;
  amount: number;
  expenseDate: number;
  cardNumber: string;
  expenseId: number;

  constructor(
    userId: number,
    userName: string,
    supplier: string,
    amount: number,
    expenseDate: number,
    cardNumber: string,
    expenseId: number
  ) {
    this.userId = userId;
    this.userName = userName;
    this.supplier = supplier;
    this.amount = amount;
    this.expenseDate = expenseDate;
    this.cardNumber = cardNumber;
    this.expenseId = expenseId;
  }
}

export class CcImportTemplateDetails {
  importTemplateType: number;
  firstRowHasHeader: boolean;
  cardNoSelectionMode: number;
  cardNoCellRowId: number;
  cardNoCellColumnId: string;
  cardNoColumnStartRowId: number;
  cardNoColumnStartColumnId: string;
  importHeaderRowNo: number;
  expenseDateCol: string;
  cardNumberCol: string;
  amountCol: string;
  currencyCol: string;
  exchangeRateCol: string;
  localCurrencyAmountCol: string;
  supplierCol: string;
  supplierIdCol: string;
  notes1Col: string;
  notes2Col: string;
  notes3Col: string;
  notes4Col: string;
  notes5Col: string;
  referenceNumberCol: string;
  uniqueRowId: number;
  cardImportTemplateId: number;
  importDateFormat: string;
}

export class CcImportModel {
  templateDetails: CcImportTemplateDetails;
  selectedCardNumber: string;
  isTemplateModified: boolean;
  rowsImported: number;
  cardNoFromCell: string;
  statementDate: Date;
  cardOrganisationId: number;
  totalCards:number;
}

export class CardTransactionObject {
  cardNumber: string;
  expenseDate: string;
  amount: string;
  referenceNumber: string;
  supplier: string;
  currency: string;
  exchangeRates: string;
  localCurrencyAmount: string;
  //drCr: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  note5: string;
  uniqueRowId: number;
  supplierId: string;
}

export interface ICardReviewUploadedTransactionsRequest {
  organisationId: number;
  templateDetails: CcImportTemplateDetails;
  cardTransactions: CardTransactionObject[];
}

export class CardReviewUploadedTransactionsRequest
  implements ICardReviewUploadedTransactionsRequest {
  organisationId: number;
  templateDetails: CcImportTemplateDetails;
  cardTransactions: CardTransactionObject[];
  constructor(
    organisationId: number,
    templateDetails: CcImportTemplateDetails,
    cardTransactions: CardTransactionObject[]) {
    this.organisationId = organisationId;
    this.templateDetails = templateDetails;
    this.cardTransactions = cardTransactions;
  }
}

export interface ICcImportReviewDisplayModel {
  amount: string;
  amountOk: boolean;
  cardNumber: string;
  cardNumberOk: boolean;
  currency: string;
  currencyOk: boolean;
  drCr: string;
  drCrOk: boolean;
  exchangeRate: string;
  exchangeRateOk: boolean;
  expenseDate: string;
  expenseDateFormat: string;
  expenseDateOk: boolean;
  localCurrencyAmount: string;
  localCurrencyAmountOk: boolean;
  notes1: string;
  notes1Ok: boolean;
  notes2: string;
  notes2Ok: boolean;
  notes3: string;
  notes3Ok: boolean;
  notes4: string;
  notes4Ok: boolean;
  supplier: string;
  supplierOk: boolean;
  referenceNumber: string;
  referenceNumberOk: boolean;
  isDuplicateReferenceNumber: boolean;

  importId: number;
  toImport: boolean;
  isFormatOk: boolean;
  isCardNumberAvailableInSystem: boolean;

  selected: boolean;
  disableEdit: boolean;
  hideEdit: boolean;
  disableDelete: boolean;
  hideDelete: boolean;

  error: string;
  errors: ErrorInfo[];
  errorsCount: number;

  updatedOn: any;
  isRowHighlighted: boolean;
}

export class CcImportReviewDisplayModel implements ICcImportReviewDisplayModel {
  amount: string;
  amountOk: boolean;
  cardNumber: string;
  cardNumberOk: boolean;
  currency: string;
  currencyOk: boolean;
  drCr: string;
  drCrOk: boolean;
  exchangeRate: string;
  exchangeRateOk: boolean;
  expenseDate: string;
  expenseDateFormat: string;
  expenseDateOk: boolean;
  localCurrencyAmount: string;
  localCurrencyAmountOk: boolean;
  notes1: string;
  notes1Ok: boolean;
  notes2: string;
  notes2Ok: boolean;
  notes3: string;
  notes3Ok: boolean;
  notes4: string;
  notes4Ok: boolean;
  supplier: string;
  supplierOk: boolean;
  referenceNumber: string;
  referenceNumberOk: boolean;
  isDuplicateReferenceNumber: boolean;

  importId: number;
  toImport: boolean;
  isFormatOk: boolean;
  isCardNumberAvailableInSystem: boolean;

  selected: boolean;
  disableEdit: boolean;
  hideEdit: boolean;
  disableDelete: boolean;
  hideDelete: boolean;

  error: string;
  errors: ErrorInfo[] = [];
  errorsCount: number = 0;

  updatedOn: any;
  isRowHighlighted: boolean;
}

export interface ICardImportTemplateSaveRequestModel {
  organisationId: number;
  updatedBy: number;
  templateDetails: CcImportTemplateDetails;
}

export class CardImportTemplateSaveRequestModel
  implements ICardImportTemplateSaveRequestModel {
  organisationId: number;
  updatedBy: number;
  templateDetails: CcImportTemplateDetails;
}

export interface ICSVPendingTransactionTemplate {
  statementDate: string;
  cardNumber: string;
  expenseDate: string;
  amount: number;
  supplier: string;
  currency: string;
  assignedTo: string;

}

export class CSVPendingTransactionTemplate implements ICSVPendingTransactionTemplate {
  statementDate: string;
  cardNumber: string;
  expenseDate: string;
  amount: number;
  supplier: string;
  currency: string;
  assignedTo: string;
}

// Response
export interface IBulkCreditCardExpensesImportResponse {
  TotalCount: number;
  ImportedCount: number;
  UnassignedImports: number;
}

export class BulkCreditCardExpensesImportResponse implements IBulkCreditCardExpensesImportResponse {
  TotalCount: number;
  ImportedCount: number;
  UnassignedImports: number;
}

export interface IGoLiveWithCardFunctionRequest {
  organisationId: number;
  liveMode: number;
}

export class GoLiveWithCardFunctionRequest
  implements IGoLiveWithCardFunctionRequest {
  organisationId: number;
  liveMode: number;
}


export interface ICardImportTemplateDetailSaveDateFormatRequest {
  organisationId: number;
  importDateFormat: string;
  updatedBy: number;
  oldImportDateFormat: string;
  userName: string;
}

export class CardImportTemplateDetailSaveDateFormatRequest
  implements ICardImportTemplateDetailSaveDateFormatRequest {
    organisationId: number;
    importDateFormat: string;
    updatedBy: number;
    oldImportDateFormat: string;
    userName: string;
}

export class CardUserAssignmentFormModel {
  cardNumber: string;
  employeeCode: string;
  employeeEmail: string;
  fullName: string;
  firstName: string;
  lastName: string;
  cardOrganisationId: number;
}

export class CardUserAssignmentFormOutputModel {
  filterStatus: number;
  constructor(filterStatus: number) {
    this.filterStatus = filterStatus;

  }
}

export class CCPendingExpenseListPanelInputModel {
  cardOrganisationId: number;
  cardName: string;
  expenseStatus: number;
  constructor(cardOrganisationId: number, cardName: string, expenseStatus: number) {
      this.cardOrganisationId = cardOrganisationId;
      this.cardName = cardName;
      this.expenseStatus = expenseStatus;
  }
}

export interface ICardPendingExpenseRequest{
  cardOrganisationId: number;
  organisationId: number;
  expenseStatus: number;
  userId: number;
}

export class CardPendingExpenseRequest implements ICardPendingExpenseRequest {
  cardOrganisationId: number;
  organisationId: number;
  expenseStatus: number;
  userId: number;
  constructor(cardOrganisationId: number, organisationId: number, expenseStatus: number,userId: number) {
      this.cardOrganisationId = cardOrganisationId;
      this.organisationId = organisationId;
      this.expenseStatus = expenseStatus;
      this.userId = userId;
  }
}

export interface IPendingCreditCardExpenseListByCardRequest {
  orgId: number;
  cardOrganisationId: number;
}

export class PendingCreditCardExpenseListByCardRequest implements IPendingCreditCardExpenseListByCardRequest {
  orgId: number;
  cardOrganisationId: number;
}

export class UsersForAssignCCExpense {
  employeeId: number;
  employeeCode: string;
  employeeName: number;
  employeeEmail: number;
  cardNumber: number;
  selected: boolean;
}

export class CCAssignTransactionsPanelInputModel{
  cardNumber: string;
  expenseList: any[];
  constructor(cardNumber: string, expenseList: any[]) {
    this.cardNumber = cardNumber;
    this.expenseList = expenseList;
   }
}

export interface IUnassignExpenseByCardRequest {
  orgId: number;
  cardNumber: string;
}

export class UnassignExpenseByCardRequest implements IUnassignExpenseByCardRequest {
    orgId: number;
    cardNumber: string;
    constructor(orgId: number, cardNumber: string) {
      this.orgId = orgId;
      this.cardNumber = cardNumber;
  }
}


export interface IDuplicateCardDetailsRequest {
  orgId: number;
  cardDigit: number;
}

export class DuplicateCardDetailsRequest implements IDuplicateCardDetailsRequest {
  orgId: number;
  cardDigit: number;
  constructor(orgId: number, cardDigit:number) {
    this.orgId = orgId;
    this.cardDigit = cardDigit;
  }
}


export interface IDuplicateCardDetailsResponse {
  cardHolderId: number;
  cardNumber: string;
  cardHolderName: string;
  newCardNumber: string;
}


// New Dashboard
export interface ICCDashboardRequest {
  orgId: number;
}

export class CCDashboardRequest implements ICCDashboardRequest {
  orgId: number;
  constructor(orgId: number) {
    this.orgId = orgId;
  }
}

export interface ICCDashboardResponse {
  importDetails: ICCDashboardImportDetails[];
  importedTransactionDetails:ICCDashboardTransactionDetails;
  pendingTransactionDetails: ICCDashboardTransactionDetails;
  graphsDetails: ICCDashboardGraphsResponse;
  allPendingItems: number;

}

export interface ICCDashboardImportDetails{
  cardImportOriginalFileId:number;
  statementDate: Date;
  importDate: number;
  originalTransaction: number;
  importedTransaction: number;
  notImportedTransaction: number;
  importedAmount: any;
  completedTransaction: number;
  deletedTransaction: number;
  completedAmount:number;
  pendingTransaction: number;
  notCompletedTransaction: number;
  dismissedTransaction: number;
  reRoutedTransaction: number;
  orphanTransaction: number; 
  pendingAmount: number;
}

export interface ICCDashboardTransactionDetails{
  transactionCount: number;
  userCount: number;
  amount: any;
}

export interface ICCDashboardGraphsResponse{
  cardExpenditure: any[];
  cardUsers: number[];
  monthsList: any[];
}

export interface ICCExpenseResponse{
  expenseId: number;
  cardNumber: string;
  expenseDate:number;
  amount:any;
  supplier: string;
  userId:number;
  orgId: number;
  updatedOn: number;
  cardSupervisoryActionTaken:number;
  cardSupervisoryActionTakenBy:number;
  cardSupervisoryActionTakenOn: number;
  cardSupervisoryActionTakenByUserName: string;
  userName: string;
  cardHolderName: string;
  cardHolderId: number;
  currentStatus: number;
  dismissJustificationNotes: string;
  importDate: number;
  status: number;
  hasReceipt: boolean;
  hideDeleteAction: boolean;
  hideAssignAction: boolean;
  hideReceiptAction: boolean;
}
export class CCPendingTransactionInputModel{
  cardOrganisationId: number;
  importId: number;
  constructor(cardOrganisationId: number, importId: number) {
    this.cardOrganisationId = cardOrganisationId;
    this.importId = importId;
  }
}

export interface ICCPendingTransactionRequest {
  organisationId: number;
  cardImportOriginalFileId: number;
  cardOrganisationId: number;
}

export class CCPendingTransactionRequest implements ICCPendingTransactionRequest {
  organisationId: number;
  cardImportOriginalFileId: number;
  cardOrganisationId: number;

  constructor(organisationId: number,cardImportOriginalFileId: number,cardOrganisationId: number ) {
    this.organisationId = organisationId;
    this.cardImportOriginalFileId = cardImportOriginalFileId;
    this.cardOrganisationId = cardOrganisationId;
  }
}



export class BulkImportAssignCreditCard {
  uniqueRowId: number;
  cardUserAssignmentId: number;
  employeeId: number;
  employeeCode: string;
  employeeName: string;
  email: string;
  cardNumber: string;
  errors: ErrorInfo[] = [];
  errorsCount: number = 0;
  templateName: string;
  importUpdatedDate : any;
  cardNumberLength: number;
  isActive: boolean;
}

export interface IBulkImportAssignCreditCardRequest {
 organisationId: number;
 fmName: string;
 fmEmail: string;
 fmUserId: number;
 assignCreditCardList: BulkImportAssignCreditCard[];
}

export class BulkImportAssignCreditCardRequest implements IBulkImportAssignCreditCardRequest {
 organisationId: number;
 fmName: string;
 fmEmail: string;
 fmUserId: number;
 assignCreditCardList: BulkImportAssignCreditCard[];
 constructor(organisationId: number,fmName: string, fmEmail: string,fmUserId: number, assignCreditCardList: BulkImportAssignCreditCard[]) {
     this.organisationId = organisationId;
     this.fmName = fmName;
     this.fmEmail = fmEmail;
     this.fmUserId = fmUserId;
     this.assignCreditCardList = assignCreditCardList;
 }
}

export interface IBulkImportAssignCreditCardResponse{
  organisationId : number;
  totalRecords: number;
  failedRecords: number;
  passedRecords: number;
  assignCreditCardList: BulkImportAssignCreditCard[];
}


export class ImportAssignCreditCardEditInputModel {
  public title: string;
  public uniqueRowId: number;
  public cardNumberLength:  number;
}


export interface IAssignCreditCardToClaimantRes {
  claimantList: CCAssignClaimant[];
  totalClaimaint: number;
  claimantWithActiveCard: number;
  claimantWithoutActiveCard: number;
}

export class CCAssignClaimant{
  employeeId: number;
  employeeCode: string;
  firstName: string;
  lastName: string;
  fullName: string;
  employeeEmail: string;
  isActive: boolean;
  status: any;
  cardNumberWithTemplate: any;
  assignCardList: CardUserAssignmentDetails[];
  isRowHighlighted: boolean;
  updatedOn: number;
  isMultipleCard: boolean;
}

export class CardUserAssignmentDetails{
  sequence: number;
  cardUserAssignmentId: number;
  cardNumber: string;
  bankName: string;
  isActive: boolean;
  status: any;
  cardNumberWithTemplate: any;
  showText: boolean;
  showSwitchText: string;
  errors: any;
  cardOrganisationId: number;
  employeeId: number;
}

export class CCClaimantCreditCardListPanelInputModel{
  creditCardList: CardUserAssignmentDetails[];
}

export class SaveUserCreditCardDetailsRequest {
  employeeId: number;
  organisationId: number;
  userId: number;
  userName: string;
  creditCardList: CreditCardDetails[];
}

export class CreditCardDetails {
  id: number;
  cardUserAssignmentId: number;
  cardNumber: string;
  isActive: boolean;
  cardOrganisationId: number;
  bankName: string;
}

export interface ICardDetailsForImport {
  cardNumberLength: number;
  cardUserAssignment: any[];
}

export class CCImportedTransactionInputModel{
  importDate: number;
  filterStatus: number;
  constructor(importDate: number,filterStatus: number) {
    this.importDate = importDate;
    this.filterStatus = filterStatus;
  }
}

export interface ICCImportedTransactionRequest {
  organisationId: number;
  importDate: string;
}

export class CCImportedTransactionRequest implements ICCImportedTransactionRequest {
  organisationId: number;
  importDate: string;

  constructor(organisationId: number,importDate: string) {
    this.organisationId = organisationId;
    this.importDate = importDate;
  }
}

export class CCImportedFileDateDetails{
  importDate: any;
}
