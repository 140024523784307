import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../util/constants';

// @Pipe({ name: 'trimAllSpaces' })
// export class TrimAllSpacesPipe implements PipeTransform {
//   transform(value: any) {

//     return value.replace(/\s/g, "");
//   }
// }


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.DATE_FMT);
  }
}

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.TIME_FMT).toString().toLowerCase();
  }
}

@Pipe({
  name: 'timeFormatNew'
})
export class TimeFormatPipeNew extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.TIME_FMT_HHmm).toString().toLowerCase();
  }
}

@Pipe({
  name: 'monthFormat'
})
export class MonthFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.MONTH_FMT).toString().toLowerCase();
  }
}

@Pipe({
  name: 'eodDateFormat'
})
export class EODDateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.EODDATE_FMT).toString();
  }
}

// @Pipe({
//   name: 'filter'
// })
// export class FilterPipe implements PipeTransform {
//   transform(items: any[], searchText: string): any[] {
//     if (!items) return [];
//     if (!searchText) return items;
//     searchText = searchText.toString().toLowerCase();
//     return items.filter(it => {
//       return it.toString().toLowerCase().includes(searchText);
//     });
//   }
// }

@Pipe({
  name: 'grdFilter'
})
export class GrdFilterPipe implements PipeTransform {
  transform(items: any, filter: any, defaultFilter: boolean): any {
    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
          filterKeys.reduce((x, keyName) =>
            (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
          });
        });
      }
    }
  }
}

// @Pipe({
//   name: 'sanitizeHtml'
// })
// export class SanitizeHtmlPipe implements PipeTransform {
//   constructor(private sanitizer: DomSanitizer) { }
//   transform(value: any): any {
//     return this.sanitizer.bypassSecurityTrustHtml(value);
//   }
// }

@Pipe({
  name: 'orderBy',
  pure: true
})
export class OrderByPipe implements PipeTransform {
  transform(values: number[] | string[] | object[], key?: string, reverse?: boolean) {
    if (!Array.isArray(values) || values.length <= 0) {
      return null;
    }

    return this.sort(values, key, reverse);
  }

  private sort(value: any[], key?: any, reverse?: boolean): any[] {
    const isInside = key && key.indexOf('.') !== -1;

    if (isInside) {
      key = key.split('.');
    }

    const array: any[] = value.sort((a: any, b: any): number => {
      if (!key) {
        return a > b ? 1 : -1;
      }

      if (!isInside) {
        return a[key] > b[key] ? 1 : -1;
      }

      return this.getValue(a, key) > this.getValue(b, key) ? 1 : -1;
    });

    if (reverse) {
      return array.reverse();
    }

    return array;
  }

  private getValue(object: any, key: string[]) {
    for (let i = 0, n = key.length; i < n; ++i) {
      const k = key[i];
      if (!(k in object)) {
        return;
      }

      object = object[k];
    }

    return object;
  }

}

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipeline implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
// @Pipe({
//   name: 'functionpackagefilter'
// })
// export class FunctionPackageFilter implements PipeTransform {

//   transform(functionDetails: any[], conditionValue: boolean): any[] {
//     return functionDetails.filter(x => x.isFree == conditionValue);
//   }
// }
// @Pipe({
//   name: 'filterUnique'

// })
// export class FilterUniquePipe implements PipeTransform {

//   transform(value: any): any {

//     // Remove the duplicate elements
//     let uniqueArray = value.filter(function (el, index, array) {
//       return array.indexOf(el) == index;
//     });

//     return uniqueArray;
//   }
// }
