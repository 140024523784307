import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef } from "@angular/core";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { CountByStatus, GridConfig } from "src/app/_models/common/grid/grid-config";
import { TranslateService } from "@ngx-translate/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { AlertService } from "src/app/alert-panel/alert.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import {ContianerPolicyInputModel} from "src/app/_models/container/container";
import {ContainerService} from "src/app/_services/container/container.service";
import { PolicyViolationInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "container-policy-panel",
    templateUrl: "./container-policy-panel.component.html"
})

export class ContianerPolicyPanelComponent implements OnInit {
    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

    @Input()
    inputData: ContianerPolicyInputModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() submitPanelEvent = new EventEmitter<any>();

    claims: Claims;
    claimsSubscription: Subscription;
    userActionEventSubscription: Subscription;
    hasFormChanges: boolean = false;
    disableSaveButton: boolean = true;
    
    defaultSortColumn: string = 'categoryName';
    gridColumnDefs: ColDef[];
    gridConfigItems: GridConfig;
    gridData = <any[]>[];

    showPolicyViolationPanel: boolean = false;
    policyViolationInputModel: PolicyViolationInputModel;
    isReceiptNumberSettingsEnabled:boolean=false;

    selectedExpenseId: number = 0;

    constructor(
        private router: Router,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private containerService: ContainerService,
        private alertService: AlertService,
        private toastr: ToastrService,
    ) {
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });

        this.fnObserveUnsavedChangesAlerts();
    }

    ngOnInit(): void {
        this.fnInitialiseGrid();
        this.fnGetPolcyList();
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    canDeactivate() {
        return !this.hasFormChanges;
    }

    fnObserveUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnGetPolcyList(){
        if(this.inputData?.expenseStatusInfo){
            this.gridData = this.inputData.expenseStatusInfo;
            this.gridData = this.gridData.map(item=>{
                item.chevron = this.translate.instant('text_view_details');
                item.daysViolated = item?.policyViolation?.policyViolation?.expenseTimeLimit?.expenseTimeLimit;
                return item;
            })
            this.fnGridFilterChanged();
        }
    }

    fnInitialiseGrid() {

        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.initializeGrid();
        }, 100);

    }

    fnCreateColumnDefs() {
        this.gridColumnDefs = [
            {
                headerName: this.translate.instant('text_category'),
                field: 'categoryName',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100
            },
            {
                headerName: this.translate.instant('expense_date'),
                field: 'expenseDate',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100,
                cellRenderer: 'dateRenderer'
            },
            {
                headerName: this.translate.instant('amount'),
                field: 'amount',
                suppressMenu: true,
                sortable: true,
                resizable: true,
                minWidth: 100,
                headerClass: 'text-right',
                cellClass: 'text-right',
                cellRenderer: 'amountRenderer',
            },
            {
                headerName: this.translate.instant("receipt"),
                field: 'hasReceipt',
                suppressMenu: true,
                sortable: false,
                cellRenderer: 'receiptsIconRenderer',
            },
            {
                headerName: this.translate.instant('text_supplier'),
                field: 'supplier',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100,
                cellClass: 'grid-text-center'
            },
            {
                headerName: this.translate.instant('text_days_violated'),
                field: 'daysViolated',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 50,
                cellClass: 'grid-text-center',
                headerClass: 'color-red',
                cellRenderer: "dayPolicyRenderer"
            },
            {
                headerName: this.translate.instant("justification_notes"),
                field: "justificationNotes",
                resizable: true,
                sortable: false,
                suppressMenu: true,
                minWidth: 300,
                width: 300,
                cellRenderer: "gridTextBoxRenderer",
                headerComponentParams: {
                  maxLength: 30,
                  placeHolder: this.translate.instant("please_enter"),
                  isShowValidationOnInit: true,
                },
                cellRendererParams: {
                  onInput: this.fnOnJustificationNoteChange.bind(this),
                },
              },
              {
                headerName: "",
                field: 'chevron',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 150,
                width: 150,
                cellRenderer: 'formattedTextRenderer',
                headerComponentParams: {
                    class: 'd-inline-block text-logo-blue cursor-pointer text-underline lH-12'
                }
            },
        ]
    }

    fnGetGridConfigItems() {
        this.gridConfigItems = new GridConfig(
            true,
            true,
            this.defaultSortColumn,
            'expenseId',
            0,
            false,
            [],
            true,
            true,
            true,
            null,
            this.defaultSortColumn,
            40,
            this.translate.instant('no_data_availabe'),
            null,
            null,
            false, 
            false,
            '',
            '',
            false
        );
    }

    fnGridFilterChanged() {

        this.fnGetGridConfigItems();
        this.fnCreateColumnDefs();

        setTimeout(() => {
            this.gridComponent.refreshGridData();
        }, 100);
    }

    fnOnGridCellClick(params) {
        switch (params.column.colId) {
            case 'chevron':          
                this.fnShowPolicyViolationPanel(params.data);
                break;
          }
      }  

    fnOnJustificationNoteChange(selectedData) {
        if (selectedData) {
            this.hasFormChanges = true; 
            this.fnDisableSaveButton();
        }
    } 

    fnCheckValidation() {
        let isValid = true;

        this.gridData.forEach(item => {   
            if(!item.justificationNotes || !(item.justificationNotes.trim())){
                isValid = false;
            }
        });
        return isValid;
    }

    fnDisableSaveButton() {
        this.disableSaveButton = false;

        if (!this.fnCheckValidation()) {
            this.disableSaveButton = true;
            return;
        }
    }

    fnOnSave(){
        this.submitPanelEvent.emit(this.gridData);
    }

    fnShowPolicyViolationPanel(model){
        this.policyViolationInputModel = new PolicyViolationInputModel(
        model.expenseId,
        model.expenseCategoryName,
        model.baseCategoryId,
        model.justificationNotes?.trim(),
        false,
        ''
        );
        this.policyViolationInputModel.policyViolationDetails =  model.policyViolation;
        this.showPolicyViolationPanel = true;
        this.selectedExpenseId =  model.expenseId;
    }
    
    fnClosePolicyViolationPanel(justificationNotes) {
        this.policyViolationInputModel = null;
        this.showPolicyViolationPanel = false;

        if (justificationNotes?.trim()) {
         this.gridData.map(item=>{
            
            if(item.expenseId ==  this.selectedExpenseId){
                item.justificationNotes = justificationNotes ? justificationNotes.trim() : '';
            }
            return item;

         });
         setTimeout(() => {
            this.gridComponent.refreshGridData();
            this.fnDisableSaveButton();
        }, 100);
        }

        this.selectedExpenseId = 0;
      }

}