import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { EmployeeStatusEnum } from "src/app/_models/common/manage-emp-enum.enum";
import { AddUpdateBulkEmployeeRequestDto, AddUpdateEmployeeRequestDto, ApiRequest, GetAllEmployeesRequest, IGetAllEmployeesRequest, LogObjectActionTemplateEnum } from "src/app/_models";
import { environment } from "src/environments/environment";
import { CostCentreService, ManageempService } from "src/app/_services";
import { BaseComponent } from "src/app/shared/base/base-component";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "src/app/alert-panel/alert.service";
import { LoggingPanelModel } from "../../../_models/common/LoggingPanelModel";
import { LoggingPanelService } from "../../../_services/logging-panel/logging-panel.service";
import { AssignCostCentreApprover, SaveCostCentreApproverRequest } from "src/app/_models/costCentre/costCentre";
import { first } from "rxjs/operators";

enum ItemType {
    Claimants = 1,
    CostCentre = 2
}

@Component({
    selector: "assign-approver-panel",
    templateUrl: "./assign-approver-panel.component.html"
})
export class AssignApproverPanelComponent extends BaseComponent implements OnInit, OnChanges {

    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

    @Output() uncheckEvent = new EventEmitter<any>();
    @Output() closePanel = new EventEmitter<boolean>();

    @Input() selectedItems: any[] = [];
    @Input() columnDefinitions: ColDef[];
    @Input() itemType: number;

    rowKey: string;
    userActionEventSubscription: Subscription;
    claimsSubscription: Subscription;
    claims: Claims;
    currentUser: any;

    defaultSortColumn;
    gridConfigItems: GridConfig;
    gridColumnDefs: ColDef[];
    gridData: any;

    allApproversList: any = [];
    approversList: any = [];
    assignedApproverId: number = null;
    selectedApproverId: number = 0;
    selectedApproverName: string = '';
    selectedApproverEmail: string = '';
    selectedApproverCostCentre: string = '';
    selectedApproverCostCentreCode: string = '';
    selectedApproverStatus: string = '';
    selectedApproverStatusId: number = 0;
    newApproverIds: number[] = [];
    ccDisplaySetting: number = 0;
    hasFormChanges: boolean = false;
    disableAssign: boolean = true;
    disableSave: boolean = true;
    panelHeading: string = '';
    isSaved: boolean = false;
    panelItemType: number = 0;
    showApproverStatusPanel: boolean = false;
    employeesList: any[] = [];
    approverListCostCentre: string;
    approverListCostCentreCode: string;
    approverListCostCentreId: number;
    selectedItemsText: string;
    anyTextForDisplay: string = '';
    constructor(
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private alertService: AlertService,
        private manageEmpService: ManageempService,
        private costCentreService: CostCentreService,
        private loggingPanelService: LoggingPanelService
    ) {
        super();
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {

                this.resetFields();
                this.closePanel.emit(false);
            }
        });
        this.getCustomerCCInfo();

    }

    ngOnInit(): void {
        this.gridData = JSON.parse(JSON.stringify(this.selectedItems));
        this.fnInitialiseEmployeesGrid();
        this.fnGetAllEmployeesList();
    }

    ngOnDestroy() {
        this.claimsSubscription.unsubscribe();
        this.userActionEventSubscription.unsubscribe();
    }

    fnShowConfigureshowLoggingAlertPanel() {

        const logTypeEnum = this.itemType == ItemType.Claimants ?
            LogObjectActionTemplateEnum.Approver1stLevel :
            LogObjectActionTemplateEnum.AssignApproverToCostCentres;

        const model = new LoggingPanelModel(
            this.claims.OrgId.toString(),
            logTypeEnum.toString(),
            this.gridData[0][this.rowKey]
        );

        this.loggingPanelService.onShowLogPanel(model);
    }

    ngOnChanges(changes) {
        this.gridData = JSON.parse(JSON.stringify(this.selectedItems));
        this.newApproverIds = [];
        this.filterApproversList();
        this.resetSelectedApprover();

        if (this.gridComponent) {
            setTimeout(() => {
                this.gridComponent.refreshGridData();
            }, 100);
        }

        let key = '', selectedItemKey = '';
        if (this.itemType == ItemType.Claimants) {
            key = 'label_selected_claimants';
            selectedItemKey = 'label_selected_claimants';
        }
        else if (this.itemType == ItemType.CostCentre) {
            key = 'label_assign_approver_costcentre';
            selectedItemKey = 'label_selected_cost_centres';
        }

        this.selectedItemsText = this.translate.instant(selectedItemKey);

        if (this.selectedItems && this.selectedItems.length > 1) {
            // this.panelHeading = `${this.translate.instant('label_bulk')} - ${this.translate.instant(key)} ${this.selectedItems.length}`;
            this.panelHeading = `${this.translate.instant('labl_bulk_action')}: ${this.translate.instant('label_selected')} ${this.selectedItems.length}`;
            this.anyTextForDisplay = '';
        }
        else {
            if (this.itemType == ItemType.CostCentre && this.selectedItems[0].approversList.length == 1) {
                this.anyTextForDisplay = this.translate.instant('appoint_2nd_approver');
            }
            this.panelHeading = this.translate.instant(key);
        }
    }

    canDeactivate() {
        return !this.hasFormChanges;
    }

    fnGridInit() {
        this.rowKey = this.itemType == ItemType.Claimants ? "employeeID" : "costCentreId";

        if (this.columnDefinitions && this.columnDefinitions.length > 0) {
            this.gridColumnDefs = JSON.parse(JSON.stringify(this.columnDefinitions));

            this.gridColumnDefs = this.gridColumnDefs.filter(c => c.field != "selected");

            this.gridColumnDefs.splice(this.gridColumnDefs.length - 1, 1,
                {
                    headerName: "",
                    valueGetter: 'data.' + this.rowKey,
                    cellRenderer: 'actionRenderer',
                    field: 'userActionField',
                    suppressMovable: true,
                    suppressMenu: true,
                    sortable: false,
                    minWidth: 100,
                    headerComponentParams:
                        [
                            {
                                buttonClass: 'fas fa-minus-circle color-red',
                                buttonTooltip: this.translate.instant('remove_text'),
                                userAction: 1
                            }
                        ]
                });

            this.defaultSortColumn = this.gridColumnDefs[0].field;
        }


        this.gridConfigItems = new GridConfig(
            true,
            true,
            this.defaultSortColumn,
            this.rowKey,
            0,
            false,
            [],
            true,
            true,
            false,
            null,
            'asc',
            40,
            'no_data_availabe',
            null,
            null,
            false, false,
            '',
            '',
            false
        );
    }

    fnInitialiseEmployeesGrid() {
        this.fnGridInit();
        setTimeout(() => {
            this.gridComponent.initializeGrid();
        }, 100);
    }

    fnGetAllEmployeesList() {
        let request = new ApiRequest<IGetAllEmployeesRequest>("GetAllEmployees", environment.applicationVersion, environment.requestId);
        let requestInfo = new GetAllEmployeesRequest(this.claims.OrgId);
        request.requestInfo = requestInfo;
        this.manageEmpService.GetAllApprovers(request).subscribe(response => {
            this.allApproversList = response;
            this.filterApproversList();
        });
    }

    filterApproversList() {
        if (this.itemType == ItemType.Claimants) {
            this.approversList = this.allApproversList.filter(a => this.selectedItems.filter(i => i.employeeID == a.employeeID).length == 0 && Number(a.status) != 2);
        }
        else {
            this.approversList = this.allApproversList.filter(a => Number(a.status) != 2);
        }
    }

    onApproverChange() {
        if (this.assignedApproverId) {
            this.disableAssign = false;
            this.selectedApproverId = this.assignedApproverId;
            const selectedApprover = this.approversList.find(a => a.employeeID == this.selectedApproverId);
            if (selectedApprover) {
                this.selectedApproverName = selectedApprover.displayName;
                this.selectedApproverEmail = selectedApprover.email;
                this.selectedApproverCostCentre = selectedApprover.costCentreName;
                this.selectedApproverCostCentreCode = selectedApprover.costCentreCode1;
                this.selectedApproverStatusId = parseInt(selectedApprover.status);
                if (this.selectedApproverStatusId == EmployeeStatusEnum.NotInvited) {
                    this.selectedApproverStatus = this.translate.instant('label_approver_notinvited');
                }
                else if (this.selectedApproverStatusId == EmployeeStatusEnum.Pending) {
                    this.selectedApproverStatus = this.translate.instant('label_approver_notjoined');
                }
                else {
                    this.selectedApproverStatus = '';
                }
            }
            else {
                this.selectedApproverName = '';
                this.selectedApproverEmail = '';
                this.selectedApproverCostCentre = '';
                this.selectedApproverCostCentreCode = '';
            }
        }
        else {
            this.resetFields();
        }
    }

    fnOnGridCellClick(params) {
        if (params.column.colId === 'userActionField') {
            switch (params.data.userAction) {
                case 1:
                    this.uncheckEvent.emit(params.data);
                    break;
            }
        }
        else if (params.column.colId === 'approvers') {
            this.openEmployeeListPanel(params.data, 2);
        }
        else if (params.column.colId === 'claimantCount') {
            this.openEmployeeListPanel(params.data, 3);
        }
    }

    openEmployeeListPanel(costCentre, itemType) {
        this.panelItemType = itemType;
        this.showApproverStatusPanel = true;
        this.employeesList = [];
        if (itemType == 2) {
            this.employeesList = costCentre.approversList;
        }
        else if (itemType == 3) {
            this.employeesList = costCentre.claimantsList;
        }
        this.approverListCostCentre = costCentre.costCentreName;
        this.approverListCostCentreCode = costCentre.costCentreCode1;
        this.approverListCostCentreId = costCentre.costCentreId;
    }

    closeEmployeeStatusPanel(isSaved) {
        this.showApproverStatusPanel = false;
        this.employeesList = [];
        this.approverListCostCentre = '';
        this.approverListCostCentreCode = '';
        this.approverListCostCentreId = 0;
        this.isSaved = isSaved;
    }

    onRemoveApprover(removedApproverIds) {
        const costCentre = this.gridData.find(r => r.costCentreId == this.approverListCostCentreId);
        if (costCentre) {
            costCentre.approverIds = costCentre.approverIds.filter(a => removedApproverIds.indexOf(a) == -1);
            costCentre.approversList = costCentre.approversList.filter(a => removedApproverIds.indexOf(a.employeeID) == -1);
            costCentre.approverCount -= removedApproverIds.length;
            costCentre.assignedApproverCount -= removedApproverIds.length;
            if (costCentre.approverCount <= 0) {
                costCentre.approvers = this.translate.instant('no_approver');
            }
            else if (costCentre.approverCount == 1) {
                costCentre.approvers = costCentre.approversList[0].displayName;
            }
            else {
                costCentre.approvers = `${costCentre.approversList[0].displayName} (+${costCentre.approverCount - 1})`;
            }
        }
        if (this.gridComponent) {
            setTimeout(() => {
                this.gridComponent.refreshGridData();
            }, 100);
        }
        this.closeEmployeeStatusPanel(true);
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.resetFields();
            this.closePanel.emit(this.isSaved);
            this.isSaved = false;
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    assignApprovers() {
        if (this.selectedApproverId) {
            if (this.newApproverIds.indexOf(this.selectedApproverId) == -1 || this.itemType != ItemType.CostCentre) {
                this.newApproverIds.push(this.selectedApproverId);
                this.disableSave = false;
                this.disableAssign = true;
                this.hasFormChanges = true;

                if (this.itemType == ItemType.Claimants) {
                    this.assignApproverToClaimants();
                }
                else if (this.itemType == ItemType.CostCentre) {
                    this.assignApproverToCostCentres();
                }

                if (this.gridComponent) {
                    setTimeout(() => {
                        this.gridComponent.refreshGridData();
                    }, 100);
                }
            }
            else {
                this.toastr.info(this.translate.instant('approver_already_assigned'));
            }
        }
        else {
            this.disableAssign = false;
            this.disableSave = true;
        }
    }

    assignApproverToClaimants() {
        this.gridData.forEach((item) => {
            item.approverId = this.selectedApproverId;
            item.approverName = this.selectedApproverName;
            item.approverStatus = this.selectedApproverStatusId;
        });
    }

    assignApproverToCostCentres() {
        this.gridData.forEach((costCentre) => {
            if (!costCentre.approverIds.find(a => a == this.selectedApproverId)) {
                costCentre.approverIds.push(this.selectedApproverId);
                costCentre.approversList.push(this.approversList.find(a => a.employeeID == this.selectedApproverId));
                costCentre.approverCount++;
                costCentre.assignedApproverCount++;
                if (costCentre.approverCount <= 0) {
                    costCentre.approvers = this.translate.instant('no_approver');
                }
                else if (costCentre.approverCount == 1) {
                    costCentre.approvers = costCentre.approversList[0].displayName;
                }
                else {
                    costCentre.approvers = `${costCentre.approversList[0].displayName} (+${costCentre.approverCount - 1})`;
                }

                if (!costCentre.newApproverIds) {
                    costCentre.newApproverIds = [this.selectedApproverId];
                }
                else {
                    if (costCentre.newApproverIds.indexOf(this.selectedApproverId) < 0) {
                        costCentre.newApproverIds.push(this.selectedApproverId);
                    }
                }
            }
        });
        this.resetSelectedApprover();
    }

    saveAssignments() {
        this.disableAssign = true;
        this.disableSave = true;
        this.toastr.info(this.translate.instant("request_processing_info"));

        if (this.itemType == ItemType.Claimants) {
            this.saveEmployees();
        }
        else if (this.itemType == ItemType.CostCentre) {
            this.saveCostCentres();
        }
    }

    saveCostCentres() {

        const infoToast = this.toastr.info(this.translate.instant('request_processing_info'));

        let requestInfo: AssignCostCentreApprover[] = [];

        this.gridData.forEach((costCentre) => {
            if (costCentre.newApproverIds && costCentre.newApproverIds.length > 0) {
                costCentre.newApproverIds.forEach((approverId) => {
                    const approver = costCentre.approversList.find(a => a.employeeID == approverId);
                    requestInfo.push(new AssignCostCentreApprover(costCentre.costCentreId, costCentre.costCentreName, costCentre.costCentreCode1,
                        approverId, approver.displayName, 1));
                });
            }
        });

        const saveRequest = new SaveCostCentreApproverRequest(this.claims.FinanceManager, requestInfo);
        this.costCentreService.saveCostCentreApproverAssignment(saveRequest).subscribe(response => {
            if (response && response.responseInfo) {
                this.toastr.remove(infoToast.toastId);
                this.toastr.success(this.translate.instant("success"));
                this.hasFormChanges = false;
                this.isSaved = true;
                this.fnClosePanel();
            }
        });

    }

    getCustomerCCInfo() {
        this.costCentreService.GetCustomerCCInfo()
            .pipe(first())
            .subscribe(
                data => {
                    if (data.statusCode == 0) {
                        this.ccDisplaySetting = data.responseInfo.ccDisplaySetting;
                    }
                });

    }

    saveEmployees() {

        let requestInfo = new AddUpdateBulkEmployeeRequestDto(this.claims.EmailId, this.claims.FinanceManager,
            this.claims.UserId, [], false);
        this.gridData.forEach((item) => {
            requestInfo.employees.push(new AddUpdateEmployeeRequestDto(
                item.employeeID, item.employeeCode, item.employeeCode2, item.employeeCode3, item.employeeCode4,
                item.firstName, item.lastName, item.email, Number(status), false,
                Number(item.employeeRole), Number(item.vehicleTypeId), item.vehicleStatus, item.vehicleSubTypeId,
                item.rateType, item.orgVehicleTypeMapId, item.derivedName, this.selectedApproverId, '',
                item.costCentreId, item.costCentreName, item.costCentreCode1, item.costCentreRuleId, 0, null, null));
        });

        this.manageEmpService.BulkAddUpdateEmployee(requestInfo).subscribe(data => {
            if (data) {
                if (data.responseInfo) {
                    this.toastr.success(this.translate.instant("success"));
                } else {
                    this.toastr.success(this.translate.instant("JobSubmittedToaster"));
                }
                this.hasFormChanges = false;
                this.isSaved = true;
                this.fnClosePanel();
            }
        });
    }

    resetFields() {
        this.resetSelectedApprover();
        this.hasFormChanges = false;
        this.disableAssign = true;
        this.disableSave = true;
        this.newApproverIds = [];
    }

    resetSelectedApprover() {
        this.selectedApproverEmail = '';
        this.selectedApproverCostCentre = '';
        this.selectedApproverCostCentreCode = '';
        this.selectedApproverStatus = '';
        this.selectedApproverStatusId = 0;
        this.selectedApproverId = 0;
        this.assignedApproverId = null;
    }

    formateCostCentreName() {
        if (this.selectedApproverCostCentre && this.selectedApproverCostCentreCode) {
            if (this.ccDisplaySetting == 1) {
                return this.selectedApproverCostCentre + ' / ' + this.selectedApproverCostCentreCode;
            }
            else if (this.ccDisplaySetting == 2) {
                return this.selectedApproverCostCentreCode + ' / ' + this.selectedApproverCostCentre;
            }
            else if (this.ccDisplaySetting == 3) {
                return this.selectedApproverCostCentreCode + ' - ' + this.selectedApproverCostCentre;
            }
            else if (this.ccDisplaySetting == 4) {
                return this.selectedApproverCostCentre + ' - ' + this.selectedApproverCostCentreCode;
            }
            else if (this.ccDisplaySetting == 5) {
                return this.selectedApproverCostCentreCode;
            }
            else if (this.ccDisplaySetting == 6) {
                return this.selectedApproverCostCentre;
            }
            else
                return this.selectedApproverCostCentre + ' - ' + this.selectedApproverCostCentreCode;
        }
    }
}


