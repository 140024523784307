<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active w-55pc">
        <div class="d-flex flex-column bd-highlight h-100" >
          <header class="py-1 d-flex justify-content-between align-items-center height-60px">
              <h2>
                {{ "basic_function_trial_end_alert_title" | translate: param }}
              </h2>
          </header>
          <div class="block mt-2">
            <ul>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center eod-text-danger">
                  <i class="fas fa-exclamation-circle fa-2x pr-2 inline-block"></i><span class="f-16 fw-600"> {{ "trial_end_alert_note1" | translate: param }} {{this.explorationDays}} {{ "trial_end_alert_note2" | translate: param }}</span>
                </li>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                  <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "trial_end_alert_note3" | translate: param }}</span>
                </li>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                  <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "trial_end_alert_note4" | translate: param }} <span class="eod-text-danger fw-600">{{ this.datePipe.transform(basicFunctionTrialGraceEndDate) }}</span></span>
                </li>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                  <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "trial_end_alert_note5" | translate: param }} <span class="eod-text-danger fw-600">{{ datePipe.transform(basicFunctionTrialEndDate) }}</span></span>
                </li>
            </ul>
          </div>
          <div class="block mt-2">
            <div class="fw-600 eod-text-grey-02">
              {{ "trial_end_alert_subtitle" | translate: param }}
            </div>
            <div class="font-weight-bold mt-2">
              {{ "trial_end_alert_subnote1" | translate: param }} {{ this.datePipe.transform(basicFunctionTrialStartDate) }}
            </div>
            <div class="color-red font-weight-bold mt-2">
              {{ "trial_end_alert_subnote2" | translate: param }} {{ this.datePipe.transform(basicFunctionTrialEndDate) }} {{ "trial_end_alert_subnote3" | translate: param }}
            </div>
          </div>
          <div class="mt-auto d-flex justify-content-center bg-white p-2">
            <button type="button" class="btn eod-btn-primary height-38px mr-2" (click)="fnSubscribeNow()">
              <b> {{ "purchase_now" | translate: param }}</b>
            </button>
            <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="logout()">
              <b> {{ "logout" | translate: param }}</b>
            </button>
          </div>
        </div>
    </div>
</div>


<stripe-agreement-panel *ngIf="showStripPanel" (closePanelEvent)="fnCloseStripePanel($event)">
    
</stripe-agreement-panel>