export class BackgroundTask {
    constructor(public topic: string, public key: string, public input: any, public allItemIds: string[],
        public notificationHeader: string, public notificationBody: string, public route: string,
        public functionId: number, public privilegeId: number, public userName: string) {

    }
}
export class BackgroundTaskStatus {
    constructor(public id: string, public startTime: number, public endTime: number, public status: number,
        public failedCount: number, public passedCount: number, public totalCount: number,
        public output: any) {

    }
}

export enum BackgroundTaskStatusEnum {
    NotStarted = 0,
    InProgress = 1,
    Success = 2,
    PartialSuccess = 3,
    Failed = 4
}