<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-70pc active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2 class="text-truncate w-120pc">{{ panelHeading }}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="resendInviteAll()" [hidden]="!inviteButtonText">
                                    <button class="btn btn-primary height-38px margin-right-15" [ngClass]="{ 'disabled-state': !gridData || gridData.length == 0 }">
                                        {{ inviteButtonText }}
                                    </button>
                                </li>
                                <li (click)="saveApproverAssignment()" [hidden]="hideSave">
                                    <button  [ngClass]="{'disabled-state': disableSave }" class="btn btn-primary height-38px margin-right-15">
                                        {{ 'save_text' | translate }}
                                    </button>
                                </li>
                                <li class="padding-right-0" (click)="fnClosePanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>

        <div class="row">
            <div class="block padding-top-10 heightCalc-6">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-12">
                    <!----------------------Start Grid--------------------------->
                    <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems"
                        [gridFilterItems]="gridFilterItems" (filterChanged)="fnGridFilterChanged($event)"
                        (onCellClick)="fnOnGridCellClick($event)">
                    </app-grid>
                    <!----------------------End Grid--------------------------->
                </div>
            </div>
        </div>

    </div>
</div>

<cost-centre-list-panel [hidden]="!showCostCentreListPanel" [(selectedApprover)]="selectedApproverData"
    (closePanel)="closeCostCentreListPanel($event)">
</cost-centre-list-panel>