<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div *ngIf="inputData" class="rightSidePanel active" style="z-index: 99999;">
      <header>
          <div class="row">
            <div class="col-lg-12 no-padding-right-left">
              <div class="row">
                <div class="col-lg-8  padding-left-0">
                    <h2>{{'policy_violation_2' | translate}}</h2>
                </div>
                <div class="col-lg-4 padding-right-0 header-icons text-right">
                  <ul class="d-flex justify-content-end w-100pc">
                    <li *ngIf="inputData && !inputData.disableJustificationNotes" (click)="fnSubmitPanel()"
                      [ngClass]="{ disabled: !inputData.justificationNotes || !this.hasFormChanges}">
                      <Button class="btn btn-primary height-38px margin-right-15">
                        {{ 'label_save' | translate }}
                      </Button>
                    </li>
                    <li (click)="fnClosePanel()" class="padding-right-1">
                      <Button
                          class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                          {{ 'close' | translate }}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </header>
      <div class="clear"></div>
      <div class="col-lg-12 margin-top-10 no-padding-right-left">
        <div class="block scrollbar-visible heightCalc-16 overflow-auto">
          <div class="row" id="taxRateSetup-expiredSlab">
            <div class="col-md-12 col-sm-12 no-padding-right-left ">
              <div id="accordion">

                <div *ngIf="advanceCappingLimit" class="card margin-top-10">
                  <div class="card-header card-header-claimant-xx"
                      [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'advanceCappingLimit'}">
                    <a class="card-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                        aria-expanded="false">
                      {{ "advance_limit_policy" | translate }}
                    </a>
                  </div>
                  <div id="collapseOne" class="in collapse" data-bs-parent="#accordion"
                      [ngClass]="{'show': openSectionName == 'advanceCappingLimit'}">
                    <div class="card-body padding-10">
                      <div class="row margin-top-0">
                        <div class="col-md-12 no-padding-right-left margin-top-0">
                          <div class="row ">
                            <div class="w-100pc height-auto">
                              <div class="row margin-top-0">
                                <ul class="tempUL policyViolation-duplicateExp ">
                                  <li>
                                    {{ "advance_limit" | translate }}
                                    <span class="position-absolute right-10">
                                      {{ inputData.advanceLimit }}
                                    </span>
                                  </li>
                                  <li>
                                    {{ "advance_balance" | translate }}
                                    <span class="position-absolute right-10">
                                      {{ inputData.advanceBalance }}
                                    </span>
                                  </li>
                                  <li class="color-red">
                                    {{ "advance_amount" | translate }}
                                    <span class="position-absolute right-10">
                                      {{ inputData.amount }}
                                    </span>
                                  </li>
                                  <li class="color-red fw-600">
                                    {{ "advance_above_limit" | translate }}
                                    <span class="position-absolute right-10">
                                      {{ inputData.advanceOverLimit }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="block padding-bottom-0 box-shadow margin-top-11">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left">
              <h6 class="border-bottom padding-bottom-10">
                <span class="padding-right-10 color-orange">
                  <i class="far fa-file-signature" aria-hidden="true"></i>
                </span>
                {{'justification_notes' | translate}}
                <small class="color-blue"></small>
              </h6>
              <div class="input-group margin-top-40"
                [ngClass]="{ 'disabled': inputData.disableJustificationNotes == true }">
                <input type="text" class="form-control has-value rounded margin-top-5 padding-left-0"
                    (input)="this.hasFormChanges= true;" maxlength="200" required="" value=""
                    name="JustificationNotes"
                    [ngClass]="{ 'has-value': inputData.justificationNotes, 'color-light-grey-disabledType': inputData.disableJustificationNotes == true }"
                    [(ngModel)]="inputData.justificationNotes" />
                <label for="JustificationNotes">
                  {{'enter_justification_notes' | translate}}<span *ngIf="inputData && !inputData.disableJustificationNotes">*</span>
                </label>
                <div *ngIf="this.isNoteError" class="alert-danger">
                  <b>{{ "label_Required" | translate }}</b>
                </div>
              </div>
              <p class="text-right">
                <small>
                  <span [ngClass]="{'color-red': inputData.justificationNotes && inputData.justificationNotes.length > 190 }">
                    {{inputData.justificationNotes?inputData.justificationNotes.length:0}}/200
                  </span>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
