
<div class="text-right">
    <span class="d-block">
        {{ discountedAmount | number: "1.2-2" | noComma }} {{this.currency}}    
    </span> 
    <span class="d-block percentage">
        {{ this.percentage  | number: "1.2-2" | noComma }}%   
    </span>
</div>

