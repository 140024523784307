import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  CostCentreService,
  DashboardService,
  ManageempService,
} from "../_services";
import { first } from "rxjs/operators";
import { Chart } from "chart.js";
import {
  FunctionPackageEnum,
  DasboardExpenseStatusEnum,
  ExpenseStatusEnum,
  FMRoleType,
  FunctionPrivilege,
  Page,
  ApprovalRuleSetting,
  DashboardCountEnum,
  DOCStatusEnum,
  OrgFeedbackTypes,
  CcImportTemplateTypeEnum,
  UniversalImportTemplateEnum,
} from "../_models/enum/solo-enum";
import { Claims } from "../_models/common/claims";
import { ClaimsService } from "../_services/common/claims.service";
import { Router } from "@angular/router";
import { OrgSubscriptionPurchaseFunctionList } from "../_models/billing-pricing/IOrgSubscriptionPurchaseFunctionList";
import {
  ApiRequest,
  GetAllEmployeesRequest,
  IApiRequest,
  IGetAllEmployeesRequest,
  CCPendingTransactionsFilterEnum,
  IApiresponse,
} from "../_models";
import { OrgSubscription } from "../_models/common/OrgSubscription";
import { SoloCheckFuncPurchaseListRequest } from "../_models/common/solo-check-func-purchase-list-request";
import { OrgSubscriptionFunctionService } from "../_services/billing-pricing/Org-Subscription-Function.service";
import {
  ICartDetailsRequest,
  CartDetailsRequest,
  GetOrgDependentFunctionListRequest,
  IGetOrgDependentFunctionListRequest,
  GetCartDetailsRequest,
  IGetCartDetailsRequest,
} from "../_models/organisation-setting/organisation-setting";
import { OrganisationSettingService } from "../_services/organisation-setting/organisation-setting.service";
import { ToastrService } from "ngx-toastr";
import { RSAHelper } from "../_helpers/RSAHelper";
import { environment } from "src/environments/environment";
import { DashboardExpenseStatusSummary } from "../_models/Dashboard/dashboard-expense-status-summary";
import { ApprovalSummary } from "../_models/Dashboard/approval-summary";
import {
  IDashBoardAPI,
  DashBoardAPI,
  DashboardDataRequest,
} from "../_models/Dashboard/dashboardAPI";
import { OrgSubscriptionFunctionList } from "../_models/billing-pricing/OrgSubscriptionFunctionList";
import { TranslateService } from "@ngx-translate/core";
import { AlertPanelModel } from "../_models/common/alertPanelModel";
import { FunctionPrivilegeService } from "../_services/secondary-fm/function-privilege.service";
import { OrganisationFunctionService } from "../_services/organisation-setting/organisation-function.service";
import { NRFCConfigModel } from "../_models/NrFc/nrfcconfig-model";
import { MarketingPopup } from "../_models/common/marketing-popup";
import { NotificationService } from "../_services/common/notification.service";
import {
  Notification,
  NotificationActionModel,
} from "../_models/common/notification";
import { Subscription } from "rxjs";
import { EmployeeStatusEnum } from "../_models/common/manage-emp-enum.enum";
import { PurchaseFuncListDetails } from "../_models/billing-pricing/SoloCheckFuncPurchaseListResponse";
import { RecommendationService } from "src/app/_services/Recommendation/recommendation.service";
import {
  IRecommendation,
  IOrgRecommendation,
  IExpenseRecommendationDetail,
} from "src/app/_models/recommendation/recommendation";
import { WebPushSubscription } from "../_models/web-notifications/push-subscription";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";
import Swal from "sweetalert2";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { AccountingIntegrationService } from "../_services/Accounting/accounting-integration.service";
import { CreditCardService } from "../_services/credit-card/credit-card.service";
import {
  CardReviewUploadedTransactionsRequest,
  CcImportModel,
  ICardReviewUploadedTransactionsRequest,
} from "../_models/credit-card/credit-card";
import { StorageData } from "../_models/universal-importer/universal-importer";
import { UniversalImporterService } from "../_services/universal-importer/universal-importer.service";
import { UTCDateTime } from "../common/commonvariables";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  packagePath: string;
  OrganisationId: number;
  token: string;
  loginId: string;
  userId: string;
  PurchaseFunctionResponse_1: OrgSubscriptionPurchaseFunctionList;
  allPurchaseFunction: PurchaseFuncListDetails[];
  incompleteExpenses: DashboardExpenseStatusSummary;
  pendingExpensesWithApprover: DashboardExpenseStatusSummary;
  pendingExpensewithFA: DashboardExpenseStatusSummary;
  passedForPayment: DashboardExpenseStatusSummary;
  pendingPassedForPayment: DashboardExpenseStatusSummary;
  organisationCurrency: string;
  claims: Claims;
  twoStepApproval: boolean = false;
  mileageMarketingPopup: boolean;
  creditCardPopup: boolean;
  categoryMarketingPopup: boolean;
  showOrphanspopUp: boolean = false;
  openOrphansPopup: boolean = false;
  OrgPurchaseFunctionRequest: IApiRequest<OrgSubscription>;
  saveCartDetailsRequest: IApiRequest<ICartDetailsRequest>;
  getOrgDependentFunctionListRequest: IApiRequest<IGetOrgDependentFunctionListRequest>;
  FunctionPackageId: any;
  dependentFunction: any[];
  functionPackageName: any;
  twoStepApprovalPopup: boolean;
  liabilityReportPopup: boolean;
  disableCartMileage: boolean;
  disableCartCc: boolean;
  disableCartExpenseCategory: boolean;
  disableTwoStepApp: boolean;
  disableLiabilityReportApp: boolean;
  cartList: any;
  getCartDetailsRequest: IApiRequest<IGetCartDetailsRequest>;
  showGooglePackagePurchaseReminder: boolean;
  googlePackagePurchaseReminderThreshold: number;
  isMileagePurchased: boolean = false;
  isTwoStepPurchased: boolean = false;
  functionPackageEnum = FunctionPackageEnum;
  dashboardExpenseStatusSummary = <DashboardExpenseStatusSummary[]>[];
  approvalSummary: ApprovalSummary;
  dasboardExpenseStatusEnum = DasboardExpenseStatusEnum;
  dashBoardAPI: IDashBoardAPI;
  OrgTrialingFunctionRequest: IApiRequest<OrgSubscription>;
  trialingFunctionResponse = <OrgSubscriptionFunctionList[]>[];

  isAccountingPurchased: boolean = false;
  param: string;
  isLiabilityReportPurchased: boolean = false;
  auditTrailPopup: boolean;
  advancesPopup: boolean;
  apAutomationPopup: boolean;
  cfpPopup: boolean;
  disableAuditTrailApp: boolean;
  isAuditTrailPurchased: boolean = false;
  isAdvancesPurchased: boolean = false;
  isAPAutomationPurchased: boolean = false;
  isCarbonFootprintPurchased: boolean = false;
  leaveManagementPopup: boolean;
  isLeaveManagementPurchased: boolean = false;

  expenseStatusEnum = ExpenseStatusEnum;
  alertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("please_review_or_confirm")
  );
  isAlertDisplay: boolean = false;
  showLargeImagePopup: boolean;
  showLargImageForFunction: number;

  isPrimaryFM: boolean = false;
  isFinanceApprovalAccess: boolean = false;
  isPayAccess: boolean = false;
  isCardAccess: boolean = false;
  isBuildReportAccess: boolean = false;
  isAdvancedMileageAccess: boolean = false;
  isManageCategoryAccess: boolean = false;
  isLiabilityReportAccess: boolean = false;
  isAuditTrailAccess: boolean = false;
  isAdvancesAccess: boolean = false;
  isVendorAccess: boolean = false;
  showPendingDeputiesPopup: boolean = false;
  noOfApproversDoNotHaveDeputies: number;
  isApproversDeputyPurchased: boolean;

  showPendingEmployeeWithGradePopup: boolean = false;
  noOfEmployeeDoNotHaveGrade: number;
  isAssignMileageRateToGradePurchased: boolean;
  isAssignCategoryToGradePurchased: boolean;
  isDailyCapPurchased: boolean;
  isMonthlyCapPurchased: boolean;
  isYearlyCapPurchased: boolean;
  isAutoApprovalPurchased: boolean;
  isCounterApproverPurchased: boolean = false;
  isPerNightPurchased: boolean;
  isPerPersonPurchased: boolean;
  isDutyofCareAccess: boolean = false;
  isNRFCAccess: boolean = false;
  isApproverDeputyAccess: boolean = false;
  isLeaveManagementAccess: boolean = false;
  isManageEmployeesAccess: boolean = false;
  nrfcConfigModel: NRFCConfigModel = new NRFCConfigModel();
  creditCardMarketingPopupModel: any;
  manageCategoryMarketingPopupModel: any;
  mileageMarketingPopupModel: any;

  notificationActionModel: NotificationActionModel;
  notificationActionSubscription: Subscription;

  liabilityReportPopupModel: MarketingPopup;
  advancesMarketingPopupModel: MarketingPopup;
  cfpMarketingPopupModel: MarketingPopup;
  IsLeaveManagementAllowed: boolean;
  isUniversalImportAllowed: boolean;
  IsAPAutomationAllowed: boolean;

  approvalRuleSetting: number = 0;
  costCentresWithoutApproverCount: number = 0;
  costCentresWithoutApproversMessage: string = "";
  fmAsApproverSummary: any = [];
  isDataLoadComplete: boolean = false;
  fmAsApproverData: any;
  showComplianceStatement: boolean = false;
  financeApprovalPendingCount: number = 0;
  financePayPendingCount: number = 0;
  assignCategoryToGradePurchased: boolean = false;
  isMultipleCountryPackagePurchasedOrTrial: boolean = false;
  isDuplicateCheckerPurchased: boolean;
  allRecomm: IRecommendation[];
  orgRecomm: IOrgRecommendation[];
  showRecommendationPopup: boolean = true;
  expenseRecomm: IExpenseRecommendationDetail[];
  showStripPaymentFailedPanel: boolean = false;
  docStatus: number;
  showDutyofCarePopup: boolean;
  showDutyofCarePopupModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("please_review_or_confirm")
  );

  loggedInUserDisplayName: string;

  actionableRecordForDashboard: any;
  showSetupSkipPayStepPanel: boolean = false;
  setupSetupSkipPayStepPanelInputmodel: any;
  isPayStepEnabled: boolean = false;
  isTwoStepApprovalTurnOn: boolean = false;
  showBasicFunctionExploreAlertPanel: boolean = false;

  constructor(
    private dashboard: DashboardService,
    private claimsService: ClaimsService,
    private orgSubscriptionFunctionService: OrgSubscriptionFunctionService,
    private router: Router,
    private organisationSettingService: OrganisationSettingService,
    private costCentreService: CostCentreService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private functionPrivilegeService: FunctionPrivilegeService,
    private organisationFunctionService: OrganisationFunctionService,
    private notificationService: NotificationService,
    private manageEmpService: ManageempService,
    private recommService: RecommendationService,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService,
    private gtmService: GoogleTagManagerService,
    private accountingIntegrationService: AccountingIntegrationService,
    private creditCardService: CreditCardService,
    private universalImporterService: UniversalImporterService
  ) {
    document.body.className = "color-dashboard";
    this.claims = new Claims();
    this.nrfcConfigModel.claims = new Claims();
    this.claimsService.currentClaims.subscribe((claims) => {
      this.nrfcConfigModel.claims = claims;
      this.claims = claims;
      this.OrganisationId = this.claims.OrgId;
      this.loginId = this.claims.LoginId;
      this.userId = this.claims.UserId.toString();
      this.token = this.claims.Token;
      if (this.claims.CurrentOrganisationCurrency.indexOf("-") > -1) {
        this.organisationCurrency =
          this.claims.CurrentOrganisationCurrencyCode.trim();
      } else {
        this.organisationCurrency =
          this.claims.CurrentOrganisationCurrency.trim();
      }
      this.showGooglePackagePurchaseReminder =
        this.claims.showGooglePackagePurchaseReminder;
      this.googlePackagePurchaseReminderThreshold =
        this.claims.googlePackagePurchaseReminderThreshold;
      this.IsLeaveManagementAllowed = this.claims.IsLeaveManagementAllowed;
      this.IsAPAutomationAllowed = this.claims.IsAPAutomationAllow;
      this.approvalRuleSetting = this.claims.ApprovalRuleSetting
        ? this.claims.ApprovalRuleSetting
        : 1;
      this.isUniversalImportAllowed = this.claims.isUniversalImportAllowed;
      this.docStatus = this.claims.docStatus;
      this.isMultipleCountryPackagePurchasedOrTrial =
        this.claims.IsMultipleCountryPackagePurchasedOrTrial;
      this.showStripPaymentFailedPanel = this.claims.isStripPaymentFailed;
      this.isPayStepEnabled = this.claims.IsPayStepEnabled;
      this.isTwoStepApprovalTurnOn = this.claims.TwoStepApproval;
    });

    this.dashBoardAPI = new DashBoardAPI();
    this.approvalSummary = new ApprovalSummary();
    this.incompleteExpenses = new DashboardExpenseStatusSummary();
    this.pendingExpensesWithApprover = new DashboardExpenseStatusSummary();
    this.pendingExpensewithFA = new DashboardExpenseStatusSummary();
    this.passedForPayment = new DashboardExpenseStatusSummary();
    this.pendingPassedForPayment = new DashboardExpenseStatusSummary();
    this.getOrgPurchaseFunctionList();
    this.fnGetCostCentresList();
    this.fnGetFmDashboardApproveRejectData();
    this.fnGetSoloActionableFMCountData();

    this.onNotificationAction(this.notificationService.getNotificationAction());
    this.notificationActionSubscription = this.notificationService
      .onNotificationAction()
      .subscribe((notification) => {
        this.onNotificationAction(notification);
      });

    if (this.claims.ManageCCOptions > 1) {
      this.runImportCCAtSpecificTimeOfDay();
    }
  }

  readonly VAPID_PUBLIC_KEY =
    "BP8dsmafi6xfRWX3dZmpiMaXT0blFzlD7GOcqZV-ZJqeTbhlPEuI8Ge34DikR07BlJiFRJ59tVJWVkpyd8CbF1k";
  webPushSubscription: WebPushSubscription;

  IsOnboardingCompleted = true;
  isTrialFeedbackPanelDisplayed: boolean = false;
  isOnboardingFeedbackPanelDisplayed: boolean = false;
  isFeedbackPanelActive: boolean = false;
  feedbackPanelType: number = 0;

  ngOnInit() {
    if (localStorage.getItem("registereduser")) {
      let currentUser = JSON.parse(localStorage.getItem("registereduser"));

      this.loggedInUserDisplayName = (
        currentUser.FirstName +
        " " +
        currentUser.LastName
      ).trim();
    }
    this.IsOnboardingCompleted = this.claims.IsOnboardingCompleted;

    this.isPrimaryFM = this.claims.FMRoleType == FMRoleType.AllPrivilege;
    if (this.isPrimaryFM) {
      this.isFinanceApprovalAccess = true;
      this.isPayAccess = true;
      this.isCardAccess = true;
      this.isBuildReportAccess = true;
      this.isAdvancedMileageAccess = true;
      this.isManageCategoryAccess = true;
      this.isLiabilityReportAccess = true;
      this.isAuditTrailAccess = true;
      this.isAdvancesAccess = true;
      this.isNRFCAccess = true;
      this.isDutyofCareAccess = true;
      this.isApproverDeputyAccess = true;
      this.isLeaveManagementAccess = true;
      this.isManageEmployeesAccess = true;
      this.isVendorAccess = true;
      this.getAllRecommendation();
    } else if (this.claims.FMRoleType === FMRoleType.LimitedPrivilege) {
      var functions = [
        FunctionPackageEnum.SetupCreditCard,
        FunctionPackageEnum.SetupAdvancedMileage,
        FunctionPackageEnum.ExpenseCategoryManagement,
        FunctionPackageEnum.LiabilityReport,
        FunctionPackageEnum.AuditTrail,
        FunctionPackageEnum.ConfigureApprovalRule,
        FunctionPackageEnum.Advances,
        FunctionPackageEnum.APAutomation,
      ];

      var functionprivileges = [
        FunctionPrivilege.FinanceApprover,
        FunctionPrivilege.PassforPayment,
        FunctionPrivilege.SetupCreditCard,
        FunctionPrivilege.TransferData,
        FunctionPrivilege.SetupAdvancedMileage,
        FunctionPrivilege.ExpenseCategoryManagement,
        FunctionPrivilege.LiabilityReport,
        FunctionPrivilege.AuditTrail,
        FunctionPrivilege.NonReimbursableFuel,
        FunctionPrivilege.DutyofCareApproval,
        FunctionPrivilege.ManageEmployees,
        FunctionPrivilege.Advances,
        FunctionPrivilege.ApAutomation,
      ];

      this.organisationFunctionService
        .checkFunctionsSubscribed(functions, this.claims.OrgId)
        .subscribe((subscription) => {
          this.functionPrivilegeService
            .checkPrivilegesAccess(functionprivileges, this.claims.UserId)
            .subscribe((access) => {
              this.isFinanceApprovalAccess =
                access.indexOf(FunctionPrivilege.FinanceApprover) > -1;
              this.isPayAccess =
                access.indexOf(FunctionPrivilege.PassforPayment) > -1;
              this.isCardAccess =
                subscription.indexOf(FunctionPackageEnum.SetupCreditCard) >
                  -1 && access.indexOf(FunctionPrivilege.SetupCreditCard) > -1;
              this.isBuildReportAccess =
                access.indexOf(FunctionPrivilege.TransferData) > -1;
              this.isAdvancedMileageAccess =
                subscription.indexOf(FunctionPackageEnum.SetupAdvancedMileage) >
                  -1 &&
                access.indexOf(FunctionPrivilege.SetupAdvancedMileage) > -1;
              this.isManageCategoryAccess =
                subscription.indexOf(
                  FunctionPackageEnum.ExpenseCategoryManagement
                ) > -1 &&
                access.indexOf(FunctionPrivilege.ExpenseCategoryManagement) >
                  -1;
              this.isLiabilityReportAccess =
                subscription.indexOf(FunctionPackageEnum.LiabilityReport) >
                  -1 && access.indexOf(FunctionPrivilege.LiabilityReport) > -1;
              this.isAuditTrailAccess =
                subscription.indexOf(FunctionPackageEnum.AuditTrail) > -1 &&
                access.indexOf(FunctionPrivilege.AuditTrail) > -1;
              this.isNRFCAccess =
                subscription.indexOf(FunctionPackageEnum.SetupAdvancedMileage) >
                  -1 &&
                access.indexOf(FunctionPrivilege.SetupAdvancedMileage) > -1;
              this.isDutyofCareAccess =
                subscription.indexOf(FunctionPackageEnum.DutyofCareApproval) >
                  -1 &&
                access.indexOf(FunctionPrivilege.DutyofCareApproval) > -1;
              this.isApproverDeputyAccess =
                subscription.indexOf(
                  FunctionPackageEnum.AssignDeputyToApprovers
                ) > -1 &&
                access.indexOf(FunctionPrivilege.AssignDeputyToApprovers) > -1;
              this.isLeaveManagementAccess =
                subscription.indexOf(FunctionPackageEnum.LeaveManagement) >
                  -1 && access.indexOf(FunctionPrivilege.LeaveManagement) > -1;
              this.isManageEmployeesAccess =
                subscription.indexOf(
                  FunctionPackageEnum.ConfigureApprovalRule
                ) > -1 &&
                access.indexOf(FunctionPrivilege.ManageEmployees) > -1;
              this.isAdvancesAccess =
                subscription.indexOf(FunctionPackageEnum.Advances) > -1 &&
                access.indexOf(FunctionPrivilege.Advances) > -1;
              this.isVendorAccess =
                subscription.indexOf(FunctionPackageEnum.APAutomation) > -1 &&
                access.indexOf(FunctionPrivilege.ApAutomation) > -1;
            });
        });
    }
    this.isMileagePurchased = false;
    this.twoStepApproval = false;
    this.showOrphanspopUp = false;
    this.openOrphansPopup = false;
    this.categoryMarketingPopup = false;
    this.creditCardPopup = false;
    this.mileageMarketingPopup = false;
    this.twoStepApprovalPopup = false;
    this.disableCartMileage = false;
    this.disableCartCc = false;
    this.disableCartExpenseCategory = false;
    this.disableTwoStepApp = false;
    this.liabilityReportPopup = false;
    this.auditTrailPopup = false;
    this.advancesPopup = false;
    this.apAutomationPopup = false;
    this.showDutyofCarePopup = false;
    this.getCartDetails();
    //this.checkForDOCStatus();
    this.getCostCentresWithoutApprovers();
    this.checkForPendingEmployeeWithGrade();
    this.fnGetOrgBasicFunctionSubscriptionTrialDetails();
    if (localStorage.getItem("istokenImpersonated") == "true") {
      localStorage.setItem("istokenImpersonated", "false");
      window.location.reload();
    }

    if (
      localStorage.getItem("showComplianceStatement") == "1" &&
      this.claims.showCompliancePopup
    ) {
      this.claims.complianceStatement = this.claims.complianceStatement
        .split("<p>")
        .join('<p class="d-block padding-10 whitespace-pre text-center">');
      this.alertPanelModel = new AlertPanelModel(
        true,
        this.claims.complianceHeader,
        [this.claims.complianceStatement],
        "",
        this.translate.instant("change_pass_ok_click"),
        false,
        ""
      );
      this.alertPanelModel.bodyClass = "row bg-white margin-top-10";
      this.alertPanelModel.hideInfoIcon = true;
      this.alertPanelModel.singleMessagetextClass =
        "padding-LR-15 padding-top-15 padding-bottom-15 f-20";
      localStorage.removeItem("showComplianceStatement");
    }

    if (
      !this.claims.IsOnboardingCompleted &&
      !this.isFeedbackPanelActive &&
      !this.isOnboardingFeedbackPanelDisplayed &&
      this.claims.IsFinanceManager
    ) {
      this.isOnboardingFeedbackPanelDisplayed = true;
      this.feedbackPanelType = OrgFeedbackTypes.OnboardingFeedback;
      this.isFeedbackPanelActive = true;
    } else if (
      !this.isTrialFeedbackPanelDisplayed &&
      !this.isFeedbackPanelActive
    ) {
      this.getOrgBasicFunctionSubscriptionTrialDetails();
    }
  }

  basicFunctionRemainingTrialDays: number;
  getOrgBasicFunctionSubscriptionTrialDetails() {
    if (this.claims && this.claims.OrgId && this.claims.IsFinanceManager) {
      this.isTrialFeedbackPanelDisplayed = true;
      this.orgBasicFunctionSubscriptionService
        .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
        .subscribe((res: any) => {
          this.basicFunctionRemainingTrialDays =
            res.responseInfo.basicFunctionRemainingTrialDays;

          if (res.responseInfo) {
            if (
              !res.responseInfo.isBasicFunctionPurchased &&
              !this.isFeedbackPanelActive
            ) {
              const trialPastDays =
                res.responseInfo.basicFunctionTrialDays -
                res.responseInfo.basicFunctionRemainingTrialDays;
              if (trialPastDays >= environment.trialFeedbackDays) {
                this.feedbackPanelType = OrgFeedbackTypes.TrialFeedback;
                this.isFeedbackPanelActive = true;
              }
            }
          }
        });
    }
  }

  ngOnDestroy() {
    this.notificationActionSubscription.unsubscribe();
    document.body.className = "";
  }

  onNotificationAction(notification: Notification) {
    if (notification) {
      setTimeout(() => {
        this.notificationActionModel = notification.action;
        if (this.notificationActionModel.panel === "ItemsForApproval") {
          this.showOrphanspop(true);
          this.notificationService.notificationActionCompleted();
        }
      }, 500);
    }
  }

  fnShowDutyofCareRequirementPopup(status: number) {
    if (
      status == DOCStatusEnum.incomplete ||
      status == DOCStatusEnum.pleaseComplete ||
      status == DOCStatusEnum.pendingSubmission
    ) {
      this.showDutyofCarePopupModel = new AlertPanelModel(
        true,
        this.translate.instant("duty_of_care_compliance"),
        [],
        this.translate.instant("label_cancel"),
        this.translate.instant("label_confirm"),
        true,
        this.translate.instant("duty_of_care_compliance_subtext")
      );
    }
    this.showDutyofCarePopup = true;
  }

  fnDutyofCarePopupReceiver(returnValue) {
    this.showDutyofCarePopupModel = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      ""
    );
    this.showDutyofCarePopup = false;
    if (returnValue) {
      if (returnValue == 1) {
        let url = "claimant/mymileagerate";
        this.router.navigate([url], {
          queryParams: {
            step: "3",
          },
        });
      }
    }
  }

  fnGetFmDashboardApproveRejectData() {
    this.dashboard
      .getFmDashboardApproveRejectData(this.OrganisationId, this.token)
      .subscribe((res) => {
        this.dashBoardAPI = res.responseInfo;
        if (
          this.dashBoardAPI.approvedExpenses != "0,0,0,0" ||
          this.dashBoardAPI.rejectedExpenses != "0,0,0,0"
        ) {
          this.isCanvasbar2Display = true;
          var myChart = new Chart("canvas", {
            type: "line",
            data: {
              labels: this.dashBoardAPI.monthsList,
              datasets: [
                {
                  label: this.translate.instant("approved_expenses"),
                  data: this.dashBoardAPI.approvedExpenses,
                  backgroundColor: "rgb(41, 174, 73)",
                  fill: false,
                  borderColor: "rgb(41, 174, 73)",
                  borderWidth: "1",
                  pointStyle: "rectRot",
                  pointRadius: 5,
                },
                {
                  label: this.translate.instant("rejected_expenses"),
                  data: this.dashBoardAPI.rejectedExpenses,
                  backgroundColor: "rgb(255, 0, 0)",
                  fill: false,
                  borderColor: "rgb(255, 0, 0)",
                  borderWidth: "1",
                  pointStyle: "rectRot",
                  pointRadius: 5,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                mode: "nearest",
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    display: true,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      fontStyle: "bold",
                      fontFamily: "Open Sans",
                      labelString: this.translate.instant("expenses_count"),
                    },
                    ticks: {
                      beginAtZero: true,
                      userCallback: function (label) {
                        // when the floored value is the same as the value we have a whole number
                        if (Math.floor(label) === label) {
                          return label;
                        }
                      },
                      autoSkip: true,
                      maxTicksLimit: 5,
                    },
                  },
                ],
              },
            },
          });
        } else {
          this.isCanvasbar2Display = false;
          var myChart = new Chart("canvas", {
            options: {
              title: {
                display: true,
                text: this.translate.instant("no_data_available_text"),
                lineHeight: 20,
                //position: 'bottom'
              },
            },
          });
        }
        if (
          this.dashBoardAPI.pendingInvite != "0,0,0,0" ||
          this.dashBoardAPI.notInvited != "0,0,0,0" ||
          this.dashBoardAPI.activeEmp != "0,0,0,0"
        ) {
          var myChart1 = new Chart("canvasbar", {
            type: "bar",
            data: {
              labels: this.dashBoardAPI.monthsList,
              datasets: [
                {
                  label: this.translate.instant("label_pending"),
                  data: this.dashBoardAPI.pendingInvite,
                  backgroundColor: "rgb(253, 198, 21)",
                  fill: false,
                },
                {
                  label: this.translate.instant("label_notinvited"),
                  data: this.dashBoardAPI.notInvited,
                  backgroundColor: "rgb(226, 11, 32)",
                  fill: false,
                },
                {
                  label: this.translate.instant("label_active"),
                  data: this.dashBoardAPI.activeEmp,
                  backgroundColor: "rgb(41, 174, 73)",
                  fill: false,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                mode: "x",
              },
              scales: {
                xAxes: [
                  {
                    barPercentage: 0,
                    gridLines: {
                      display: false,
                    },
                    barThickness: 15,

                    display: true,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    interval: 10,
                    scaleLabel: {
                      display: true,
                      fontStyle: "bold",
                      fontFamily: "Open Sans",
                      labelString: this.translate.instant("emp_count"),
                    },

                    ticks: {
                      beginAtZero: true,

                      userCallback: function (label) {
                        // when the floored value is the same as the value we have a whole number
                        if (Math.floor(label) === label) {
                          return label;
                        }
                      },
                      autoSkip: true,
                      maxTicksLimit: 5,
                    },
                  },
                ],
              },
            },
          });
        } else {
          var myChart1 = new Chart("canvasbar", {
            type: "bar",
            data: {
              labels: this.dashBoardAPI.monthsList,
              datasets: [
                {
                  data: this.dashBoardAPI.pendingInvite,
                  backgroundColor: "rgb(255, 102, 0)",
                  fill: false,
                },
                {
                  data: this.dashBoardAPI.notInvited,
                  backgroundColor: "rgb(9, 96, 148)",
                  fill: false,
                },
                {
                  data: this.dashBoardAPI.activeEmp,
                  backgroundColor: "rgb(41, 174, 73)",
                  fill: false,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    barThickness: 15,
                    display: true,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      fontStyle: "bold",
                      fontFamily: "Open Sans",
                      labelString: this.translate.instant("emp_count"),
                    },
                    ticks: {
                      beginAtZero: true,
                      suggestedMin: 1,
                      stepSize: 100,
                      suggestedMax: 500,
                    },
                  },
                ],
              },
            },
          });
        }
        if (
          this.dashBoardAPI.mileage != "0,0,0,0" ||
          this.dashBoardAPI.mileageGoogle != "0,0,0,0" ||
          this.dashBoardAPI.mileageGPS != "0,0,0,0"
        ) {
          this.isCanvasbar1Display = true;
          var myChart2 = new Chart("canvasbar1", {
            type: "bar",
            data: {
              labels: this.dashBoardAPI.monthsList,
              datasets: [
                {
                  label: this.translate.instant("manual_mileage"),
                  data: this.dashBoardAPI.mileage,
                  backgroundColor: "rgb(9, 96, 148)",
                  fill: false,
                },
                {
                  label: this.translate.instant("mileage_google"),
                  data: this.dashBoardAPI.mileageGoogle,
                  backgroundColor: "rgb(41, 174, 73)",
                  fill: false,
                },
                {
                  label: this.translate.instant("mileage_gps"),
                  data: this.dashBoardAPI.mileageGPS,
                  backgroundColor: "rgb(161, 34, 94)",
                  fill: false,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                mode: "x",
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    display: true,
                    barThickness: 15,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    interval: 10,
                    scaleLabel: {
                      display: true,
                      fontStyle: "bold",
                      fontFamily: "Open Sans",
                      labelString: this.translate.instant("expenses_count"),
                    },
                    ticks: {
                      beginAtZero: true,
                      userCallback: function (label) {
                        // when the floored value is the same as the value we have a whole number
                        if (Math.floor(label) === label) {
                          return label;
                        }
                      },
                      autoSkip: true,
                      maxTicksLimit: 5,
                    },
                  },
                ],
              },
            },
          });
        } else {
          this.isCanvasbar1Display = false;
          var myChart2 = new Chart("canvasbar1", {
            options: {
              title: {
                display: true,
                text: this.translate.instant("no_data_available_text"),
                lineHeight: 20,
                //position: 'bottom'
              },
            },
          });
        }
      });
  }
  totalMilesOfOrg: number;
  isCanvasbar1Display: boolean;
  isCanvasbar2Display: boolean;
  fnGetSoloActionableFMCountData() {
    let request = new DashboardDataRequest();
    request.loginId = this.loginId;
    request.userId = Number(this.userId);
    request.orgId = this.OrganisationId;
    request.requestCountType = DashboardCountEnum.Dashboard_Count;
    this.dashboard
      .SoloGetActionableRecordForDashboard(request)
      .subscribe((fmcount) => {
        this.actionableRecordForDashboard = fmcount.responseInfo;
        this.totalMilesOfOrg = fmcount.responseInfo.totalMilesOfOrg;
        this.dashboardExpenseStatusSummary =
          fmcount.responseInfo.dashboardExpenseStatusSummary;
        this.fmAsApproverSummary = fmcount.responseInfo.fmAsApproverSummary;
        this.approvalSummary = fmcount.responseInfo.approvalSummary;

        this.claims.pendingAllForPayment =
          this.approvalSummary.pendingCountFinancePayment;
        this.claims.pendingForFinanceApproval =
          this.approvalSummary.pendingCountFinanceApproval;
        this.claims.pendingExpensesForPayment =
          this.approvalSummary.pendingCountFinancePayment;
        this.claims.pendingCountNRFinanceApproval =
          this.approvalSummary.pendingCountNRFinanceApproval;
        this.claims.pendingCountFCFinanceApproval =
          this.approvalSummary.pendingCountFCFinanceApproval;
        this.claims.pendingCountPreApproval =
          this.approvalSummary.pendingPreApprovalsWithApprover;
        this.claims.pendingCountNRApprover = this.approvalSummary.nrCountForFM;
        this.claims.pendingCountFCApprover =
          this.approvalSummary.fcCountForApprover;
        this.claims.pendingExpenseCountApprover =
          this.approvalSummary.pendingExpensesWithApprover;
        this.claims.pendingAdvanceForFinanceApproval =
          this.approvalSummary.pendingCountAdvanceFinanceApproval;
        this.claims.pendingAdvancesForPayment =
          this.approvalSummary.pendingCountAdvancePay;
        this.claims.pendingAdvancesForApproval =
          this.approvalSummary.pendingCountAdvanceApproval;
        this.claims.pendingCountInvoiceApproval =
          this.approvalSummary.pendingCountInvoiceApproval;
        this.claims.pendingCountInvoiceFinanceApproval =
          this.approvalSummary.pendingCountInvoiceFinanceApproval;
        this.claims.pendingCountInvoicePay =
          this.approvalSummary.pendingCountInvoicePay;
        this.claimsService.updateClaims(this.claims);
        //this.totalcount = this.pendingCountExpensesWithFM + this.approverNotAssigned + this.approverNotInvited;
        // getSoloActionableFMOrphansRecord End here

        this.financeApprovalPendingCount =
          this.approvalSummary.pendingCountFinanceApproval +
          this.approvalSummary.pendingCountNRFinanceApproval +
          this.approvalSummary.pendingCountFCFinanceApproval +
          this.approvalSummary.pendingCountAdvanceFinanceApproval +
          this.approvalSummary.pendingCountInvoiceFinanceApproval;

        this.financePayPendingCount =
          this.approvalSummary.pendingCountFinancePayment +
          this.approvalSummary.pendingCountAdvancePay +
          this.approvalSummary.pendingCountInvoicePay;

        if (
          this.dashboardExpenseStatusSummary != null &&
          this.dashboardExpenseStatusSummary.length != 0
        ) {
          this.incompleteExpenses = this.dashboardExpenseStatusSummary.find(
            (x) => x.status == this.dasboardExpenseStatusEnum.Incomplete
          );
          this.pendingExpensesWithApprover =
            this.dashboardExpenseStatusSummary.find(
              (x) =>
                x.status == this.dasboardExpenseStatusEnum.PendingWithApprover
            );
          this.pendingExpensewithFA = this.dashboardExpenseStatusSummary.find(
            (x) =>
              x.status == this.dasboardExpenseStatusEnum.PendingFinanceApproval
          );
          this.passedForPayment = this.dashboardExpenseStatusSummary.find(
            (x) => x.status == this.dasboardExpenseStatusEnum.PassedForPayment
          );
          this.pendingPassedForPayment =
            this.dashboardExpenseStatusSummary.find(
              (x) =>
                x.status == this.dasboardExpenseStatusEnum.PendingPassforPayment
            );
        }
        setTimeout(() => {
          let isBasicMileagePurchased = this.checkOrgFunctionPurchase(
            this.functionPackageEnum.BasicMileageRates
          );
          if (
            !isBasicMileagePurchased &&
            (this.claims.RateType == 1 || this.claims.RateType == 0)
          ) {
            this.fnOpenFreeMilesAlertPanel();
          }
        }, 300);
      });
  }

  liabilityToolTip: string = this.translate.instant(
    "liabilityReport_requires_two_step"
  );

  auditTrailToolTip: string = this.translate.instant(
    "audittrail_requires_two_step"
  );

  advancesToolTip: string = this.translate.instant(
    "advances_requires_two_step"
  );

  getOrgPurchaseFunctionList() {
    this.OrgPurchaseFunctionRequest =
      new ApiRequest<SoloCheckFuncPurchaseListRequest>(
        "PurchaseFunctionList",
        0,
        environment.requestId
      );
    this.OrgPurchaseFunctionRequest.requestInfo =
      new SoloCheckFuncPurchaseListRequest(
        this.claims.OrgId,
        this.claims.UserId
      );
    this.orgSubscriptionFunctionService
      .getOrgSubscriptionPurchaseFunction(this.OrgPurchaseFunctionRequest)
      .pipe(first())
      .subscribe((data) => {
        if (data.statusCode == 0) {
          this.allPurchaseFunction =
            data.responseInfo?.orgPaidFunctionList[0]?.paidFunctions;
          if (this.allPurchaseFunction.length > 0) {
            this.claims.IsPackagePurchasedOrTrial = true;
            this.isMileagePurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.SetupAdvancedMileage
            );
            this.isTwoStepPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.TwoStepApproval
            );
            if (this.isTwoStepPurchased) {
              this.liabilityToolTip = "";
              this.auditTrailToolTip = "";
              this.advancesToolTip = "";
            } else if (!this.isTwoStepPurchased) {
              this.liabilityToolTip = this.translate.instant(
                "liabilityReport_requires_two_step"
              );
              this.auditTrailToolTip = this.translate.instant(
                "audittrail_requires_two_step"
              );
              this.advancesToolTip = this.translate.instant(
                "advances_requires_two_step"
              );
            }

            this.isLiabilityReportPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.LiabilityReport
            );
            this.isAuditTrailPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.AuditTrail
            );
            this.isAdvancesPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.Advances
            );
            this.isAPAutomationPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.APAutomation
            );
            this.isCarbonFootprintPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.CarbonFootprint
            );
            this.isLeaveManagementPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.LeaveManagement
            );
            this.isApproversDeputyPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.AssignDeputyToApprovers
            );
            if (this.isApproversDeputyPurchased) {
              this.checkForPendingDeputies();
            }
            this.isAssignMileageRateToGradePurchased =
              this.checkOrgFunctionPurchase(
                this.functionPackageEnum.SetupAdvancedMileage
              );
            this.isAssignCategoryToGradePurchased =
              this.checkOrgFunctionPurchase(
                this.functionPackageEnum.assigncategoriestograde
              );
            this.isDailyCapPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.ExpenseCappingDaily
            );
            this.isMonthlyCapPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.ExpenseCappingMonthly
            );
            this.isYearlyCapPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.ExpenseCappingYearly
            );
            this.isAutoApprovalPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.AutoApproval
            );
            this.isCounterApproverPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.CounterApprover
            );
            this.isPerPersonPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.PerPersonLimit
            );
            this.isPerNightPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.PerNightLimit
            );
            this.nrfcConfigModel.isNonReimbursableFuelPurchased =
              this.checkOrgFunctionPurchase(
                this.functionPackageEnum.NonReimbursableFuel
              );
            this.nrfcConfigModel.isFuelCardPurchased =
              this.checkOrgFunctionPurchase(this.functionPackageEnum.FuelCard);
            this.isDuplicateCheckerPurchased = this.checkOrgFunctionPurchase(
              this.functionPackageEnum.DuplicateChecker
            );
            this.claims = this.claimsService.SetFunctionPackageClaims(
              this.allPurchaseFunction,
              this.claims,
              Page.Home
            );

            this.claimsService.updateClaims(this.claims);
          }
        }
      });
  }

  checkOrgFunctionPurchase(funId: number) {
    let purchaseResult = this.allPurchaseFunction.find(
      (it) => it.functionId == funId
    );
    if (purchaseResult != undefined) {
      return true;
    }
    return false;
  }

  getCartDetails(): any {
    this.getCartDetailsRequest = new ApiRequest<GetCartDetailsRequest>(
      "SoloGetCartDetails",
      1,
      "111"
    );
    this.getCartDetailsRequest.requestInfo = new GetCartDetailsRequest(
      this.claims.UserId,
      this.OrganisationId
    );

    this.organisationSettingService
      .getCartDetailsList(this.getCartDetailsRequest)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.responseInfo != null) {
            this.claims.CartCount = data.responseInfo.listCartDetails.length;
            this.claimsService.updateClaims(this.claims);
            this.cartList = data.responseInfo.listCartDetails;
            this.disableCartMileage = this.isFunctionInCart(23);
            this.disableCartCc = this.isFunctionInCart(14);
            this.disableCartExpenseCategory = this.isFunctionInCart(17);
            this.disableTwoStepApp = this.isFunctionInCart(18);
            this.disableLiabilityReportApp = this.isFunctionInCart(
              this.functionPackageEnum.LiabilityReport
            );
            if (this.disableLiabilityReportApp) {
              this.liabilityToolTip = "";
            }

            this.disableAuditTrailApp = this.isFunctionInCart(
              this.functionPackageEnum.AuditTrail
            );
            if (this.disableAuditTrailApp) {
              this.auditTrailToolTip = "";
            }
          } else {
            this.toastr.error(data.errorInfo[0].message);
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }

  getCostCentresWithoutApprovers() {
    if (
      this.approvalRuleSetting != ApprovalRuleSetting.Manual &&
      this.isManageEmployeesAccess
    ) {
      if (this.claims.showCostCentresWithoutApproverCount) {
        this.costCentreService
          .GetCostCentreCountWithoutApprovers()
          .subscribe((result) => {
            this.costCentresWithoutApproverCount = 0;
            this.costCentresWithoutApproversMessage = "";
            if (
              result &&
              result.responseInfo &&
              result.responseInfo.length > 0
            ) {
              this.costCentresWithoutApproverCount = result.responseInfo.length;
              if (
                this.claims.ApprovalRuleSetting ==
                  ApprovalRuleSetting.AutomaticD ||
                this.claims.ApprovalRuleSetting ==
                  ApprovalRuleSetting.AutomaticE
              ) {
                this.costCentresWithoutApproversMessage = this.translate
                  .instant("note_cc_without_approvers")
                  .replace("{0}", this.costCentresWithoutApproverCount);
              } else {
                this.costCentresWithoutApproversMessage = this.translate
                  .instant("note_cc_without2_approvers")
                  .replace("{0}", this.costCentresWithoutApproverCount);
              }
            }

            this.claims.showCostCentresWithoutApproverCount = false;
            this.claimsService.updateClaims(this.claims);
          });
      }
    }
  }

  goToAssignCostCentreApprovers() {
    this.closeCostCentreAlertPanel();
    this.router.navigate(["/orgsetting/manageemployee"], {
      queryParams: { step: "5" },
    });
  }

  closeCostCentreAlertPanel() {
    this.costCentresWithoutApproverCount = 0;
  }

  isFunctionInCart(functionPackageId) {
    return this.cartList.some(
      (item) => item.functionPackageId == functionPackageId
    );
  }

  fnAddToCart() {
    if (this.dependentFunction.length > 0) {
      this.fnSaveCartDetails();
      this.saveDependentFunction();
    } else {
      this.fnSaveCartDetails();
    }
  }
  fnSaveCartDetails() {
    let request = new ApiRequest<ICartDetailsRequest>(
      "SaveCartDetails",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new CartDetailsRequest(
      parseInt(this.userId),
      this.OrganisationId,
      false,
      this.FunctionPackageId,
      this.functionPackageName
    );
    this.organisationSettingService
      .SaveCartDetails(request)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            this.claims.CartCount += 1;
            this.claimsService.updateClaims(this.claims);
            this.router.navigate(["/shoppingcartlist"]);
          } else {
            this.toastr.error(data.errorInfo[0].message);
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }

  routerNavigate() {
    let isFree = "false";
    let packagePath = this.packagePath.toString();
    let functionPackageName = this.functionPackageName.toString();
    let functionPackageId = this.FunctionPackageId.toString();
    let functionStatus = "0";
    this.router.navigate(["/addtocart"], {
      queryParams: {
        isFree: this.EncryptParameters(isFree),
        packagePath: this.EncryptParameters(packagePath),
        functionPackageName: this.EncryptParameters(functionPackageName),
        functionPackageId: this.EncryptParameters(functionPackageId),
        functionStatus: this.EncryptParameters(functionStatus),
      },
    });
  }
  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }

  getMySubscriptionSettings() {
    let functionDetails = [];
    this.organisationSettingService
      .GetOrganisationSettingList()
      .subscribe((data) => {
        if (data.statusCode == 0) {
          data.responseInfo.functionCategoryDetail.forEach((x) =>
            x.functionPackageDetail.forEach((y) => {
              functionDetails.push(y);
            })
          );
          this.getDependentFunction(this.FunctionPackageId, functionDetails);
        }
      });
  }

  getDependentFunction(FunctionPackageId: number, functionDetailss: any[]) {
    this.dependentFunction = [];
    this.getOrgDependentFunctionListRequest =
      new ApiRequest<GetOrgDependentFunctionListRequest>(
        "SoloGetOrgDependentFunctionList",
        1,
        "111"
      );

    this.getOrgDependentFunctionListRequest.requestInfo =
      new GetOrgDependentFunctionListRequest(
        FunctionPackageId.toString(),
        this.OrganisationId
      );
    this.organisationSettingService
      .getOrgDependentFunctionList(this.getOrgDependentFunctionListRequest)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.responseInfo != null) {
            data.responseInfo.orgDependentFunctionList.forEach((x) => {
              if (x.functionID == this.FunctionPackageId) {
                functionDetailss.forEach((items) => {
                  if (items.functionPackageId == x.dependentFunctionID) {
                    this.dependentFunction.push({
                      functionPackageId: items.functionPackageId,
                      functionPackageName: items.functionPackageName,
                      functionPackageDescripition:
                        items.functionPackageDescripition,
                      dependentFunctionAmount: "",
                    });
                  }
                });
              }
            });
          } else {
            console.log(data.errorInfo[0].message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private saveDependentFunction() {
    this.saveCartDetailsRequest = new ApiRequest<CartDetailsRequest>(
      "SaveCartDetails",
      1,
      "1111"
    );
    this.dependentFunction.forEach((depend) => {
      this.saveCartDetailsRequest.requestInfo = new CartDetailsRequest(
        this.claims.UserId,
        this.OrganisationId,
        false,
        depend.functionPackageId,
        depend.functionPackageName
      );
      this.organisationSettingService
        .SaveCartDetails(this.saveCartDetailsRequest)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.statusCode == 0) {
              this.claims.CartCount += 1;
              this.claimsService.updateClaims(this.claims);
            }
          },
          () => {}
        );
    });
  }

  purchaseMileageFunction() {
    this.router.navigate(["/orgsetting/mileagewizard"]);
  }

  showTwoStepPopup() {
    if (this.isTwoStepPurchased) {
      this.twoStepApprovalPopup = false;
    } else {
      this.twoStepApprovalPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.TwoStepApproval;
      this.functionPackageName = this.translate.instant(
        "label_two_step_approval"
      );
      this.packagePath = "twostepapproval";

      this.liabilityReportPopupModel = new MarketingPopup(
        this.translate.instant("two_Step_Approval"),
        this.translate.instant("two_step_header_message"),
        this.translate.instant("dashboard_message_4"),
        this.translate.instant("liabilityReport_two_step_approval"),
        "../../assets/svg-icons/two_step_workflow_icon.svg",
        "../../assets/images/pay_page.png",
        "",
        this.functionPackageEnum.TwoStepApproval,
        this.functionPackageName,
        this.packagePath,
        0,
        0
      );

      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  closeMarketingPopup() {
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
    this.twoStepApprovalPopup = false;
    this.liabilityReportPopup = false;
    this.auditTrailPopup = false;
    this.advancesPopup = false;
    this.apAutomationPopup = false;
    this.cfpPopup = false;
  }

  showLiabilityReport() {
    //this.router.navigate(["liabilityreport/summary"]);
    if (this.isLiabilityReportPurchased) {
      this.liabilityReportPopup = false;
      localStorage.setItem("isCameFromDashboardLiability", "1");
      this.router.navigate(["liabilityreport/summary"]);
    } else {
      //document.body.style.overflow = 'hidden';
      this.liabilityReportPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.LiabilityReport;
      this.functionPackageName = "Liability Report";
      this.packagePath = "liabilityreport/summary";
      this.liabilityReportPopupModel = new MarketingPopup(
        this.translate.instant("liabilityReport_header"),
        this.translate.instant("liabilityReport_this_report_shows_as_on_today"),
        this.translate.instant(
          "liabilityReport_this_function_can_be_purchased"
        ),
        this.translate.instant("liabilityReport_two_step_approval"),
        "../../assets/images/liability.png",
        "../../assets/images/liability-report-2.png",
        this.liabilityToolTip,
        this.functionPackageEnum.LiabilityReport,
        this.translate.instant("liabilityReport_header"),
        "liabilityreport/summary",
        this.functionPackageEnum.TwoStepApproval,
        this.functionPackageEnum.TwoStepApproval
      );
      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  showLeaveManagementDashboard() {
    if (this.isLeaveManagementPurchased) {
      this.leaveManagementPopup = false;
      this.router.navigate(["leavemanagement/dashboard"]);
    } else {
      //document.body.style.overflow = 'hidden';
      this.leaveManagementPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.LeaveManagement;
      this.functionPackageName = this.translate.instant("leave_management");
      this.packagePath = "leavemanagement/dashboard";
      this.liabilityReportPopupModel = new MarketingPopup(
        this.translate.instant("leave_management"),
        this.translate.instant("leave_management_header"),
        this.translate.instant("leave_management_marketing"),
        "",
        "../../assets/images/Leave-management.png",
        "../../assets/images/leave_management_marketing.png",
        "",
        this.functionPackageEnum.LeaveManagement,
        this.translate.instant("leave_management"),
        "leavemanagement/dashboard",
        0,
        0
      );
      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  showCarbonFootprint() {
    if (this.isCarbonFootprintPurchased) {
      this.cfpPopup = false;
      this.router.navigate(["dashboard/carbonFootprint"]);
    } else {
      this.cfpPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.CarbonFootprint;
      this.functionPackageName = this.translate.instant("carbon_footprint");
      this.packagePath = "carbonFPrint";

      this.cfpMarketingPopupModel = new MarketingPopup(
        this.translate.instant("carbon_footprint"),
        this.translate.instant("carbon_footprint_marketing_text"),
        "",
        "",
        "../../assets/svg-icons/Carbonfootprint.svg",
        "../../assets/images/CFP.png",
        "",
        this.functionPackageEnum.CarbonFootprint,
        this.translate.instant("carbon_footprint"),
        "carbonFPrint",
        0,
        0,
        this.translate.instant("carbon_footprint_function_header"),
        [
          this.translate.instant("carbon_footprint_function_description_part1"),
          this.translate.instant("carbon_footprint_function_description_part2"),
          this.translate.instant("carbon_footprint_function_description_part3"),
        ]
      );

      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  showAdvances() {
    if (this.isAdvancesPurchased) {
      this.advancesPopup = false;
      this.router.navigate(["dashboard/advances"]);
    } else {
      this.advancesPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.Advances;
      this.functionPackageName = "Advances";
      this.packagePath = "advances";

      this.advancesMarketingPopupModel = new MarketingPopup(
        this.translate.instant("label_Advance"),
        this.translate.instant("advance_marketing_text"),
        this.translate.instant(
          "liabilityReport_this_function_can_be_purchased"
        ),
        this.translate.instant("liabilityReport_two_step_approval"),
        "../../assets/svg-icons/Advance-Marketing.svg",
        "../../assets/images/advances.png",
        this.advancesToolTip,
        this.functionPackageEnum.Advances,
        this.translate.instant("audittrail_header"),
        "advances",
        this.functionPackageEnum.TwoStepApproval,
        this.functionPackageEnum.TwoStepApproval
      );

      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }
  marketingPopupPanel: MarketingPopup;
  showAPAutomation() {
    if (this.isAPAutomationPurchased) {
      this.apAutomationPopup = false;
      this.router.navigate(["/dashboard/vendors"]);
    } else {
      this.apAutomationPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.APAutomation;
      this.marketingPopupPanel = new MarketingPopup(
        this.translate.instant("label_ap_automation"),
        this.translate.instant("label_ap_automation_text"),
        this.translate.instant(
          "liabilityReport_this_function_can_be_purchased"
        ),
        this.translate.instant("liabilityReport_two_step_approval"),
        "../../assets/svg-icons/vendors_marketing.svg",
        "../../assets/images/vendor_example.png",
        this.translate.instant("ap_automation_dependency_msg"),
        FunctionPackageEnum.APAutomation,
        this.translate.instant("label_ap_automation"),
        "vendorSetup",
        FunctionPackageEnum.TwoStepApproval,
        FunctionPackageEnum.TwoStepApproval
      );

      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  showAuditTrail() {
    if (this.isAuditTrailPurchased) {
      this.auditTrailPopup = false;
      this.router.navigate(["audittrail"]);
    } else {
      this.auditTrailPopup = true;
      this.FunctionPackageId = this.functionPackageEnum.AuditTrail;
      this.functionPackageName = "Audit Trail";
      this.packagePath = "audittrail";

      this.liabilityReportPopupModel = new MarketingPopup(
        this.translate.instant("audittrail_header"),
        this.translate.instant("audittrail_query_and_expense"),
        this.translate.instant(
          "liabilityReport_this_function_can_be_purchased"
        ),
        this.translate.instant("liabilityReport_two_step_approval"),
        "../../assets/svg-icons/audit_trail.svg",
        "../../assets/images/audit-trail.png",
        this.auditTrailToolTip,
        this.functionPackageEnum.AuditTrail,
        this.translate.instant("audittrail_header"),
        "liabilityreport/summary",
        this.functionPackageEnum.TwoStepApproval,
        this.functionPackageEnum.TwoStepApproval
      );

      this.getMySubscriptionSettings();
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  closeLiabilityReport() {
    this.liabilityReportPopup = false;
    //document.body.style.overflow = 'auto';
  }
  closeAPAutomation() {
    this.apAutomationPopup = false;
    //document.body.style.overflow = 'auto';
  }
  closeAuditTrail() {
    this.auditTrailPopup = false;
  }

  closeLeaveManagement() {
    this.leaveManagementPopup = false;
  }

  changeUrl(path) {
    this.router.navigate(["" + path + ""]);
  }

  showOrphanspop(value) {
    this.openOrphansPopup = value;
    if (this.isDataLoadComplete) {
      this.showOrphanspopUp = value;
    }
  }

  closeOrphansPopup() {
    this.showOrphanspopUp = false;
    this.openOrphansPopup = false;
  }

  assignEmployee() {
    this.router.navigate(["/orgsetting/manageemployee"], {
      queryParams: { step: "4" },
    });
  }

  fnExportClick() {
    this.router.navigate(["/export/expenseexport"]);
  }

  showLiabilityReportPopup: boolean;
  showLiabilityReportPopupEvent() {
    this.showLiabilityReportPopup = true;
  }

  closeLiabilityReportPopupEvent() {
    this.showLiabilityReportPopup = false;
  }

  showAuditTrailPopupEvent(functionId) {
    this.showLargeImagePopup = true;
    switch (functionId) {
      case this.functionPackageEnum.TwoStepApproval:
        this.showLargImageForFunction =
          this.functionPackageEnum.TwoStepApproval;
        break;
      case this.functionPackageEnum.AuditTrail:
        this.showLargImageForFunction = this.functionPackageEnum.AuditTrail;
        break;
    }
  }

  closePopupEvent() {
    this.showLargeImagePopup = false;

    this.showLargImageForFunction = 0;
  }

  showPresetExportPanel: boolean = false;
  selectedPresetExportStatus: number;
  fnGetDashboardPresetData(currentStatus, count) {
    if (count == 0) {
      this.confirmDeactive();
    } else {
      this.selectedPresetExportStatus = currentStatus;
      this.showPresetExportPanel = true;
    }
  }

  closePresetExportPanel() {
    this.showPresetExportPanel = false;
  }
  milesOfOrg: any;
  displayAlertPanelModelForMiles: boolean;
  fnOpenFreeMilesAlertPanel() {
    let milesOfOrg = 0;
    if (Number(this.totalMilesOfOrg) >= 4000) {
      milesOfOrg = 4000;
    } else if (Number(this.totalMilesOfOrg) >= 3500) {
      milesOfOrg = 3500;
    } else if (Number(this.totalMilesOfOrg) >= 3000) {
      milesOfOrg = 3000;
    } else if (Number(this.totalMilesOfOrg) >= 2500) {
      milesOfOrg = 2500;
    } else if (Number(this.totalMilesOfOrg) >= 2000) {
      milesOfOrg = 2000;
    } else if (Number(this.totalMilesOfOrg) >= 1500) {
      milesOfOrg = 1500;
    } else if (Number(this.totalMilesOfOrg) >= 1000) {
      milesOfOrg = 1000;
    } else if (Number(this.totalMilesOfOrg) >= 500) {
      milesOfOrg = 500;
    }
    if (milesOfOrg && this.claims.RateType) {
      if (this.claims.displayAlertPanelModelForMiles == undefined) {
        this.claims.displayAlertPanelModelForMiles = true;
        this.claimsService.updateClaims(this.claims);
      }
      if (this.claims.displayAlertPanelModelForMiles) {
        this.displayAlertPanelModelForMiles = true;
        this.milesOfOrg = milesOfOrg;
      }
    } else if (!this.claims.RateType) {
      milesOfOrg = 4000;
      if (this.claims.displayAlertPanelModelForMiles == undefined) {
        this.claims.displayAlertPanelModelForMiles = true;
        this.claimsService.updateClaims(this.claims);
      }
      if (this.claims.displayAlertPanelModelForMiles) {
        this.displayAlertPanelModelForMiles = true;
        this.milesOfOrg = milesOfOrg;
      }
    }
  }

  alertPanelReceiver(event) {
    this.isAlertDisplay = false;
    this.alertPanelModel = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      this.translate.instant("please_review_or_confirm")
    );
  }

  alertPanelReceiverForMiles(returnValue) {
    this.claims.displayAlertPanelModelForMiles = false;
    this.claimsService.updateClaims(this.claims);
    this.displayAlertPanelModelForMiles = false;
    // this.alertPanelModel = new AlertPanelModel(
    //   false,
    //   "",
    //   [""],
    //   "",
    //   "",
    //   true,
    //   this.translate.instant("please_review_or_confirm")
    // );
    if (returnValue) {
      let url = "orgsetting/mileagewizard";
      this.router.navigate([url], {
        queryParams: {
          step: "1",
        },
      });
    }
  }

  confirmDeactive() {
    this.isAlertDisplay = true;
    this.alertPanelModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [this.translate.instant("no_data_to_view")],
      "",
      this.translate.instant("oK_text"),
      true,
      this.translate.instant("please_review_or_confirm")
    );
  }

  fnDisplayMarketingPopUp(FunctionId) {
    switch (FunctionId) {
      case this.functionPackageEnum.NonReimbursableFuel:
        if (!this.nrfcConfigModel.isNonReimbursableFuelPurchased) {
          this.nrfcConfigModel.secondaryFMPopupModel = new MarketingPopup(
            this.translate.instant("non_reimbursable_fuel"),
            this.translate.instant("markettingpopup_nr_text2"),
            this.translate.instant("markettingpopup_nr_text3"),
            null,
            "../../assets/svg-icons/NR_Fuel.svg",
            "../../assets/images/nr_fc_example.png",
            this.translate.instant("non_reimbursable_dependency_msg"),
            FunctionPackageEnum.NonReimbursableFuel,
            this.translate.instant("non_reimbursable_fuel"),
            "nrfc",
            FunctionPackageEnum.SetupAdvancedMileage,
            FunctionPackageEnum.SetupTaxRate
          );
          this.nrfcConfigModel.isMarketingPopUpDisplay = true;
        } else {
          this.router.navigate(["dashboard/nrDashboard"]);
        }
        break;
      case this.functionPackageEnum.FuelCard:
        if (!this.nrfcConfigModel.isFuelCardPurchased) {
          this.nrfcConfigModel.secondaryFMPopupModel = new MarketingPopup(
            this.translate.instant("fuel_card"),
            this.translate.instant("markettingpopup_fc_text2"),
            this.translate.instant("markettingpopup_fc_text3"),
            null,
            "../../assets/svg-icons/Fuel_card.svg",
            "../../assets/images/nr_fc_example.png",
            this.translate.instant("fuel_card_dependency_msg"),
            FunctionPackageEnum.FuelCard,
            this.translate.instant("fuel_card"),
            "nrfc",
            FunctionPackageEnum.SetupAdvancedMileage,
            0
          );
          this.nrfcConfigModel.isMarketingPopUpDisplay = true;
        } else {
          this.router.navigate(["orgsetting/fcdashboard"]);
        }
        break;
      default:
        break;
    }
  }

  fnpolicyDashboardNavigation() {
    this.router.navigate(["dashboard/policydashboarddetails"]);
  }
  closeSecondaryFMPopup() {
    this.nrfcConfigModel.isMarketingPopUpDisplay = false;
  }

  checkForPendingDeputies() {
    if (!this.isApproverDeputyAccess) return;

    this.dashboard.getPendingDeputiesCount().subscribe((res) => {
      if (res.responseInfo > 0) {
        if (this.claims.ShowPendingDeputyPanel == undefined) {
          this.claims.ShowPendingDeputyPanel = true;
          this.claimsService.updateClaims(this.claims);
        }
        if (this.claims.ShowPendingDeputyPanel) {
          this.noOfApproversDoNotHaveDeputies = res.responseInfo;
          this.showPendingDeputiesPopup = true;
        }
      } else {
        this.showPendingDeputiesPopup = false;
      }
    });
  }

  closePendingDeputiesPopup() {
    this.claims.ShowPendingDeputyPanel = false;
    this.claimsService.updateClaims(this.claims);
    this.showPendingDeputiesPopup = false;
  }

  navigateToDeputiesToApprover() {
    this.claims.ShowPendingDeputyPanel = false;
    this.claimsService.updateClaims(this.claims);
    this.router.navigate(["outofoffice/approversdeputy"]);
  }

  // checkForDOCStatus()
  // {
  //   if(this.claims.isDocRequired)
  //   {
  //     this.fnShowDutyofCareRequirementPopup(this.claims.docStatus);
  //   }
  // }

  checkForPendingEmployeeWithGrade() {
    this.dashboard.getPendingEmployeeWithGradeCount().subscribe((res) => {
      if (res.responseInfo > 0) {
        if (
          this.isDailyCapPurchased ||
          this.isMonthlyCapPurchased ||
          this.isYearlyCapPurchased ||
          this.isAutoApprovalPurchased ||
          this.isPerNightPurchased ||
          this.isPerPersonPurchased ||
          this.isAssignCategoryToGradePurchased
        ) {
          if (this.claims.ShowPendingEmployeeWithGradePanel == undefined) {
            this.claims.ShowPendingEmployeeWithGradePanel = true;
            this.claimsService.updateClaims(this.claims);
          }
          if (this.claims.ShowPendingEmployeeWithGradePanel) {
            this.noOfEmployeeDoNotHaveGrade = res.responseInfo;
            this.showPendingEmployeeWithGradePopup = true;
          }
        } else {
          this.showPendingEmployeeWithGradePopup = false;
        }
      } else {
        this.showPendingEmployeeWithGradePopup = false;
      }
    });
  }

  closePendingEmployeeWithGrade() {
    this.claims.ShowPendingEmployeeWithGradePanel = false;
    this.claimsService.updateClaims(this.claims);
    this.showPendingEmployeeWithGradePopup = false;
  }

  navigateToAssignGradesToClaimant() {
    this.claims.ShowPendingEmployeeWithGradePanel = false;
    this.claimsService.updateClaims(this.claims);
    this.router.navigate(["orgsetting/gradeassignemployee"]);
  }

  showCreditCardMarketingPopup() {
    if (this.claims.IsCreditCardPackageSubscribed) {
      this.creditCardPopup == false;
      this.router.navigate(["dashboard/carddashboard"]);
    } else {
      this.creditCardPopup = true;
      this.creditCardMarketingPopupModel = new MarketingPopup(
        this.translate.instant("dashboard_credit_card_setup"),
        this.translate.instant("credit_card_marketing_title"),
        this.translate.instant("credit_card_marketing_description"),
        null,
        "../../assets/svg-icons/CreditCardMarketing.svg",
        "../../assets/images/CreditCardMarketingZoomImage.png",
        "",
        FunctionPackageEnum.SetupCreditCard,
        this.translate.instant("dashboard_credit_card_setup"),
        "orgsetting/ccsetup",
        0,
        0
      );
    }
    this.categoryMarketingPopup = false;
    this.mileageMarketingPopup = false;
  }

  showManageCategoryMarketingPopup() {
    if (this.claims.IsExpenseCategoryPackagePurchasedOrTrial) {
      this.categoryMarketingPopup == false;
      this.router.navigate(["dashboard/topspendors"]);
    } else {
      this.categoryMarketingPopup = true;
      var descriptionList = this.translate
        .instant("expense_category_descriptionList")
        ?.split(",");
      this.manageCategoryMarketingPopupModel = new MarketingPopup(
        this.translate.instant("expense_Category_Management"),
        this.translate.instant("expense_category_popup_text"),
        "",
        null,
        "../../assets/images/ExpenseCategoryManagementMarketing.png",
        "../../assets/images/CategoriesMarketingZoomImage.png",
        "",
        FunctionPackageEnum.ExpenseCategoryManagement,
        this.translate.instant("expense_Category_Management"),
        "orgsetting/categorySetup",
        0,
        0,
        this.translate.instant("expense_category_descriptionListHeader"),
        descriptionList,
        this.translate.instant("expense_category_descriptionListFooter")
      );
    }
    this.mileageMarketingPopup = false;
    this.creditCardPopup = false;
  }

  showMileageMarketingPopup(value: number) {
    if (this.isMileagePurchased) {
      this.mileageMarketingPopup = false;
      if (value == 1) this.router.navigate(["dashboard/vehicleSummary"]);
      else if (value == 2) this.router.navigate(["dashboard/mileageanalysis"]);
    } else {
      this.mileageMarketingPopup = true;
      var zoomImage = "";
      if (value == 1)
        zoomImage = "../../assets/images/MileageMarketingZoomImage2.png";
      else zoomImage = "../../assets/images/MileageMarketingZoomImage1.png";
      this.mileageMarketingPopupModel = new MarketingPopup(
        this.translate.instant("setup_advanced_mileage"),
        this.translate.instant("mileage_marketing_title"),
        this.translate.instant("mileage_marketing_description"),
        null,
        "../../assets/svg-icons/MileageRatesMarketing.svg",
        zoomImage,
        "",
        FunctionPackageEnum.SetupAdvancedMileage,
        this.translate.instant("setup_advanced_mileage"),
        "orgsetting/mileagewizard",
        0,
        0
      );
    }

    this.creditCardPopup = false;
    this.categoryMarketingPopup = false;
  }

  fnGetCostCentresList() {
    let request = new ApiRequest<IGetAllEmployeesRequest>(
      "GetAllEmployees",
      environment.applicationVersion,
      environment.requestId
    );
    let requestInfo = new GetAllEmployeesRequest(this.claims.OrgId);
    request.requestInfo = requestInfo;

    this.manageEmpService
      .GetAllCostCentresAndEmployees(request, false)
      .subscribe((response: any) => {
        if (response && response.costCentreList && response.employeesList) {
          const costCentresList: any = response.costCentreList;

          const approversList = response.employeesList.filter(
            (e) =>
              (parseInt(e.employeeRole) == 2 ||
                parseInt(e.employeeRole) == 3) &&
              (e.status == EmployeeStatusEnum.NotInvited ||
                e.status == EmployeeStatusEnum.Pending)
          );

          this.fmAsApproverData = {
            costCentresList: costCentresList,
            approversList: approversList,
            approvalRuleSetting: this.approvalRuleSetting,
          };

          this.isDataLoadComplete = true;
          if (this.openOrphansPopup) {
            setTimeout(() => {
              this.showOrphanspopUp = true;
            }, 100);
          }
        }
      });
  }

  // Recommendation Section

  showRecommendationPanel: boolean = false;
  currentRecommModel: IRecommendation;
  SnoozedRecomm: IRecommendation[];

  getAllRecommendation() {
    if (this.claims.showRecommendationPopup == undefined) {
      this.claims.showRecommendationPopup = true;
      this.claimsService.updateClaims(this.claims);
    }
    if (this.claims.showRecommendationPopup) {
      this.recommService.getAllRecommendation().subscribe((res) => {
        this.allRecomm = res.responseInfo;

        if (!this.currentRecommModel) this.applicableRecommendation();
      });
    }
  }

  applicableRecommendation() {
    if (this.allRecomm) {
      this.allRecomm.forEach((x) => {
        this.currentRecommModel = x;
        this.getOrgRecommendation(x);
      });
    }
  }

  getOrgRecommendation(currentRecomm: IRecommendation) {
    this.recommService
      .getOrgRecommendation(this.claims.OrgId, currentRecomm.recommendationId)
      .subscribe((res) => {
        this.orgRecomm = res.responseInfo;
        if (this.orgRecomm.length > 0) {
          this.showRecommendationPanel = true;
        } else {
          this.claims.showRecommendationPopup = false;
          this.claimsService.updateClaims(this.claims);
          localStorage.setItem("showRecommendationPopup", "false");
        }
      });
  }

  fnCloseStripPaymentFailedPanel(isClose: boolean) {
    if (isClose) {
      this.showStripPaymentFailedPanel = false;
      this.claimsService.updateClaims(this.claims);
    }
  }
  goProducts() {
    this.router.navigate(["/orgsetting/mileagewizard"], {
      queryParams: { step: "4", assignVehicle: "1" },
    });
  }
  fnFuelCardComingSoonAlert() {
    this.alertPanelModel = new AlertPanelModel(
      true,
      this.translate.instant("alert_info"),
      [this.translate.instant("this_feature_is_coming_soon")],
      "",
      this.translate.instant("uk_mileage_btn_ok"),
      true,
      this.translate.instant("please_review_or_confirm")
    );
  }

  fnShowCardDashboard() {
    localStorage.setItem(
      "ShowPendingCCPanel",
      CCPendingTransactionsFilterEnum.Dismissed.toString()
    );
    this.router.navigate(["dashboard/carddashboard"]);
  }
  fnActionBackGroundClass() {
    if (
      (this.isDataLoadComplete &&
        this.fmAsApproverSummary &&
        this.fmAsApproverSummary.length > 0) ||
      (this.isFinanceApprovalAccess && this.financeApprovalPendingCount) ||
      (this.isPayAccess && this.financePayPendingCount) ||
      (this.isCardAccess && this.approvalSummary.dismissedExpensesCount > 0) ||
      (this.isAdvancedMileageAccess &&
        this.isMileagePurchased &&
        this.approvalSummary.vehicleApprovalCount > 0) ||
      (this.isDutyofCareAccess &&
        this.approvalSummary.pendingDutyofCareApprovalCount > 0) ||
      (this.isVendorAccess && this.approvalSummary.pendingKYCApprovalCount > 0)
    ) {
      return "items-for-approval";
    }
  }

  ngAfterViewInit(): void {}

  onCloseFoundersNote() {
    if (environment.AppUrl.includes("apps.expenseondemand.com")) {
      const gtmTag = {
        event: "OnboardingCompleted Event",
        data:
          "User " +
          this.claims.LoginId +
          " has completed the onboarding process.",
      };
      this.gtmService.pushTag(gtmTag);
    }

    this.claims.IsOnboardingCompleted = true;
    this.claims.IsGetStartedPopup = true;
    this.claimsService.updateClaims(this.claims);
    this.IsOnboardingCompleted = true;
    this.fnShowGetStartedPopup();
  }

  fnShowSetupSkipPayStepPanel() {
    this.setupSetupSkipPayStepPanelInputmodel =
      this.actionableRecordForDashboard;
    this.showSetupSkipPayStepPanel = true;
  }

  fnCloseSetupSkipPayStepPanel() {
    this.showSetupSkipPayStepPanel = false;
    this.setupSetupSkipPayStepPanelInputmodel = null;
  }

  fnSubmitSetupSkipPayStepPanel() {
    this.showSetupSkipPayStepPanel = false;
    this.setupSetupSkipPayStepPanelInputmodel = null;
    this.fnGetSoloActionableFMCountData();
  }

  basicFunctionTrialDays: number;
  fnGetOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService
      .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
      .subscribe((res: any) => {
        if (res.responseInfo != null) {
          this.basicFunctionTrialDays = res.responseInfo.basicFunctionTrialDays;
        }
      });
  }

  fnShowGetStartedPopup() {
    if (this.claims.IsGetStartedPopup) {
      const confirmationModel = Swal.mixin({
        customClass: {
          confirmButton: "btn eod-btn-success height-38px ",
          cancelButton: "btn eod-btn-outline-primary height-38px mr-2",
        },
        buttonsStyling: false,
      });

      let footerNote = `<h4 class="eod-text-danger mb-3 fw-700">
      ${this.translate.instant("start_trial_footer_note1")} 
      ${this.basicFunctionTrialDays}${this.translate.instant(
        "start_trial_footer_note2"
      )}</h4>`;

      confirmationModel
        .fire({
          //title: this.translate.instant("explore_app_title"),
          //imageUrl: 'assets/svg-icons/get-started-popup.svg',
          //imageWidth: '14em',
          html: `
        <div style="height:383px">
        <iframe  width="100%" height="88%" style="border-radius:5px 5px 0px 0px" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen="" src="https://www.youtube.com/embed/VOo0nkvYjn0?si=9feQifsGj96Gs-e2">
        </iframe>
        <h5 class="mt-2">Explore the app or set it up on your own.</h5>
        <div>`,
          width: 600,
          heightAuto: true,
          padding: "1em 0em",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translate.instant("get_started"),
          cancelButtonText: this.translate.instant("label_explore"),
          reverseButtons: true,
          footer: footerNote,
        })
        .then((result) => {
          if (result.value && this.claims.BandId > 0) {
            this.router.navigate(["/essential-pack"]);
          } else {
            localStorage.setItem("fromExploreAppEssentialPack", "1");
            this.fnShowBasicFunctionExploreAlertPanel();
          }
          this.claims.IsGetStartedPopup = false;
          this.claimsService.updateClaims(this.claims);
        });
    }
  }

  fnShowBasicFunctionExploreAlertPanel() {
    this.showBasicFunctionExploreAlertPanel = true;
  }

  fnCloseBasicFunctionExploreAlertPanel(isClose: boolean) {
    this.showBasicFunctionExploreAlertPanel = false;
    if (isClose) {
      this.router.navigate(["/settings"]);
    } else {
      this.router.navigate(["/essential-pack"]);
    }
  }

  onCloseFeedbackPanel() {
    this.isFeedbackPanelActive = false;
    this.feedbackPanelType = 0;

    if (!this.claims.IsOnboardingCompleted) {
      this.claims.IsOnboardingCompleted = true;
      this.claimsService.updateClaims(this.claims);
      document.getElementById("openModalButton").click();
    }
  }

  runImportCCAtSpecificTimeOfDay() {
    window.setInterval(function () {
      var date = new Date();
      if (date.getHours() === 5 && date.getMinutes() === 0) {
        this.importStatementNow();
      }
    }, 60000);
  }

  storageData: StorageData = new StorageData();
  reviewImportsResponse: any = {};

  importStatementNow() {
    this.accountingIntegrationService
      .GetXeroBankTransactionsToImportCompanyId(this.claims.OrgId)
      .subscribe((res: IApiresponse<any>) => {
        this.creditCardService
          .GetCardImportTemplateDetail(this.claims.OrgId, 90)
          .subscribe((response) => {
            if (response.statusCode == 0 && response.responseInfo) {
              let ccImportModel = new CcImportModel();

              let ccTemplate = response.responseInfo;

              //Handling for 1st time import
              if (ccTemplate.importTemplateType) {
                ccImportModel.isTemplateModified = false;
              } else {
                ccTemplate.importTemplateType =
                  CcImportTemplateTypeEnum.MultipleUser;
                ccImportModel.isTemplateModified = true;
              }

              ccTemplate.expenseDateCol = 1;
              ccTemplate.cardNumberCol = 2;
              ccTemplate.amountCol = 3;
              ccTemplate.currencyCol = 4;
              ccTemplate.exchangeRateCol = 5;
              ccTemplate.localCurrencyAmountCol = 6;
              ccTemplate.supplierCol = 7;
              ccTemplate.notes1Col = 9;
              ccTemplate.notes2Col = 10;
              ccTemplate.referenceNumberCol = 13;
              
              ccImportModel.templateDetails = ccTemplate;
              this.storageData.cardImportTemplateHeaderConfiguration = {
                ...ccImportModel,
              };
              this.storageData.cardImportTemplateHeaderConfiguration.statementDate =
                new Date();
              this.storageData.cardImportTemplateHeaderConfiguration.cardOrganisationId = 90;
              this.universalImporterService.fnSaveDataToLocalStorage(
                this.storageData
              );
              this.storageData.importTemplatFileName = "Card Transactions";
              this.storageData.importedTemplateData = res.responseInfo;
              this.storageData.templateData = res.responseInfo;
              this.storageData.gridData = res.responseInfo;
              this.storageData.gridData = this.storageData.gridData.map(
                (element, index) => {
                  element.uniqueRowId = index + 1;
                  return element;
                }
              );

              this.storageData.importTemplateExtension = "csv";
              this.storageData.importStep = 3;
              localStorage.setItem("isCreditCardImportFromXero", "1");
              localStorage.setItem(
                "isCameFromCreditCardImport" + this.claims.OrgId,
                "1"
              );
              this.storageData.importTemplate =
                UniversalImportTemplateEnum.ImportCreditCardStatement;
              this.universalImporterService.fnSaveDataToLocalStorage(
                this.storageData
              );

              var reviewUploadedCardStatementRequestData =
                new CardReviewUploadedTransactionsRequest(
                  this.claims.OrgId,
                  this.storageData.cardImportTemplateHeaderConfiguration?.templateDetails,
                  this.storageData.gridData
                );
              let reviewUploadedCardStatementRequest =
                new ApiRequest<ICardReviewUploadedTransactionsRequest>(
                  "ReviewUploadedCardStatement",
                  1.0,
                  "111"
                );

              reviewUploadedCardStatementRequest.requestInfo =
                reviewUploadedCardStatementRequestData;

              this.creditCardService
                .ReviewUploadedCardStatementNew(
                  reviewUploadedCardStatementRequest
                )
                .subscribe((response: IApiresponse<any>) => {
                  this.reviewImportsResponse = response.responseInfo;

                  for (
                    let index = 0;
                    index <
                    this.reviewImportsResponse.processedImportDataList.length;
                    index++
                  ) {
                    this.reviewImportsResponse.processedImportDataList[
                      index
                    ].amount =
                      this.reviewImportsResponse.processedImportDataList[
                        index
                      ].localCurrencyAmount;
                  }

                  this.reviewImportsResponse.statementDate = new Date(
                    UTCDateTime.getUTCDate(
                      new Date(
                        this.storageData?.cardImportTemplateHeaderConfiguration?.statementDate
                      )
                    )
                  ).toISOString();
                  this.reviewImportsResponse.organisationId = this.claims.OrgId;
                  this.reviewImportsResponse.importedBy = -1; // this.claims.UserId;
                  this.reviewImportsResponse.fmName =
                    this.claims.FinanceManager;
                  this.reviewImportsResponse.cardOrganisationId =
                    this.storageData.cardImportTemplateHeaderConfiguration.cardOrganisationId;
                  let saveReviewedImportsRequest = new ApiRequest<object>(
                    "SoloSaveReviewedImports",
                    1.0,
                    "111"
                  );

                  saveReviewedImportsRequest.requestInfo =
                    this.reviewImportsResponse;

                  this.creditCardService
                    .BulkCreditCardExpensesImport(saveReviewedImportsRequest)
                    .subscribe((jobId) => {
                      if (jobId) {
                        localStorage.removeItem("isCreditCardImportFromXero");
                      }
                    });
                });
            }
          });
      });
  }
}
