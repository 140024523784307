import { Directive, OnInit, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[required-field]",
})
export class MarkAsteriskDirective implements OnInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const parent = this.renderer.parentNode(this.el.nativeElement);

    if (parent.getElementsByClassName("ng-placeholder").length) {
      parent.getElementsByClassName("ng-placeholder")[0].innerHTML =
        this.el.nativeElement.getAttribute("placeholder") +
        '<span class="required-asterisk">*</span>';
    }
  }
}
