export interface IUserRequestBase {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: Userrequestdto;
}

export class UserRequestBase implements IUserRequestBase {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: Userrequestdto;
}

export interface ISocialMediaInfo {
  SocialMediaUserId: string;
  SocialMediaSiteId: string;
}

export class SocialMediaInfo implements ISocialMediaInfo {
  SocialMediaUserId: string;
  SocialMediaSiteId: string;
}

export interface IUserrequestdto {
  FirstName: string;
  LastName: string;
  LoginId: string;
  Password: string;
  ReferralCode: string;
  socialMediaInfo: SocialMediaInfo;
  IsfromOrgId:boolean;
}

export class Userrequestdto implements IUserrequestdto {
  FirstName: string;
  LastName: string;
  LoginId: string;
  Password: string;
  ReferralCode: string;
  socialMediaInfo: SocialMediaInfo;
  OTP: number;
  IsfromOrgId:boolean;
}

export interface IInviteInfo {
  email: string;
  firstName: string;
  lastName: number;
  roleId: Number;
  vehicleType: number;
}

export class InviteInfo implements IInviteInfo {
  email: string;
  firstName: string;
  lastName: number;
  roleId: Number;
  vehicleType: number;
}

export interface ICurrencyInfo {
  currencyCode: string;
  currencyId: number;
  currencyName: string;
}

export class CurrencyInfo implements ICurrencyInfo {
  countryId: number;
  countryName: string;
  currencyCode: string;
  currencyId: number;
  currencyName: string;
}

export interface IMileageInfo {
  fMVehicleType: number;
  mileageRateFor: string;
  mileageRate: number;
  mileageRateFor4Wheeler: number;
  mileageRateFor2Wheeler: number;
  mileageUOM: number;
}

export class MileageInfo implements IMileageInfo {
  fMVehicleType: number;
  mileageRateFor: string;
  mileageRate: number;
  mileageRateFor4Wheeler: number;
  mileageRateFor2Wheeler: number;
  mileageUOM: number;
}

export interface IProfileinfo {
  organisationName: string;
}

export class Profileinfo implements IProfileinfo {
  organisationName: string;
}

export interface ISetupInfo {
  inviteInfo: InviteInfo[];
  currencyInfo: CurrencyInfo;
  mileageInfo: MileageInfo;
  profileInfo: Profileinfo;
}

export class SetupInfo implements ISetupInfo {
  inviteInfo: InviteInfo[];
  currencyInfo: CurrencyInfo;
  mileageInfo: MileageInfo;
  profileInfo: Profileinfo;
}

export interface IOrganisationBasicSetupRequestInfo {
  userId: string;
  platform: number;
  loginId: string;
  languageCode: string;
  setUpInfo: SetupInfo;
}

export interface IOrganisationBasicSetupRequestInfo {
  userId: string;
  platform: number;
  loginId: string;
  languageCode: string;
  setUpInfo: SetupInfo;
}

export class OrganisationBasicSetupRequestInfo
  implements IOrganisationBasicSetupRequestInfo
{
  userId: string;
  platform: number;
  loginId: string;
  languageCode: string;
  setUpInfo: SetupInfo;
}

export interface IOrganisationBasicSetup {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: OrganisationBasicSetupRequestInfo;
}

export class OrganisationBasicSetup implements IOrganisationBasicSetup {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: OrganisationBasicSetupRequestInfo;
}

export interface IOrganisationLocationUpdate {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: OrganisationUpdateRequestInfo;
}

export class OrganisationLocationUpdate implements IOrganisationLocationUpdate {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: OrganisationUpdateRequestInfo;
}

export interface IOrganisationLocationUpdateRequestInfo {
  OrganisationLocationId: number;
  OrganisationId: number;
  Address: string;
  Address1: string;
  City: string;
  StateId: number;
  CountryId: number;
  PostalCode: string;
  IsActive: boolean;
}

export class OrganisationUpdateRequestInfo
  implements IOrganisationLocationUpdateRequestInfo
{
  OrganisationLocationId: number;
  OrganisationId: number;
  Address: string;
  Address1: string;
  City: string;
  StateId: number;
  CountryId: number;
  PostalCode: string;
  IsActive: boolean;
}

export interface IVerifyYourEmail {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: VerifyYourEmailRequestInfo;
}

export class VerifyYourEmail implements IVerifyYourEmail {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: VerifyYourEmailRequestInfo;
}

export interface IVerifyYourEmailRequestInfo {
  OldEmailId: string;
  NewEmailId: string;
  Password: string;
  VerificationKey: string;
  OrgId: number;
}

export class VerifyYourEmailRequestInfo implements IVerifyYourEmailRequestInfo {
  OldEmailId: string;
  NewEmailId: string;
  Password: string;
  VerificationKey: string;
  OrgId: number;
}

export class UserBrowserDetails {
  userBrowserDetailId: number;
  userId: number;
  browser: string;
  timezoneOffset: number;
  employeeRole: number;
  browserName: string;
}
