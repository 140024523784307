import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'app-pricing-text-renderer',
    templateUrl: './pricing-text-renderer.component.html',
})
export class PricingTextRendererComponent implements ICellRendererAngularComp {

    public functionPackageName: string = '';
    public functionPackageDescripition: string = '';

    constructor(private sanitizer: DomSanitizer) {
    }

    initilize(params: ICellRendererParams) {
        if (params.data) {
            this.functionPackageName = params.data.functionPackageName;
            this.functionPackageDescripition = params.data.functionPackageDescripition;
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    sanitizerHtml(element) {
        let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
        return safeText;
    }

}
