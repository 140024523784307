import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertPanelModel } from '../../../../_models/common/alertPanelModel';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'formly-img-upload',
    templateUrl: "./formly-img-upload.component.html"
})
export class FormlyImgUploadComponent extends FieldType implements OnInit, OnDestroy {
    param:string;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    label:string;
    alignMentClass:string = '';
    constructor(private dynamicFormService: DynamicFormService, public datepipe: DatePipe, private router: Router,
        private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        if (this.field && this.field.templateOptions) {
            this.label = this.field.templateOptions.label;     
            this.alignMentClass = this.field.templateOptions.alignMent == 2 ? 'text-right' : 
                         this.field.templateOptions.alignMent==3 ? 'text-center': '';       
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });        
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    fnOpenReceiptPanel(action: string) {
        this.to.click(this.field,action);
    }

}