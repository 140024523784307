import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-result-renderer',
  templateUrl: './result-renderer.component.html'
})
export class ResultRendererComponent implements ICellRendererAngularComp {
    param:string;
    public result: number;
    constructor() {
      //Empty constructor
    }

    initilize(params: ICellRendererParams) {
        if (params.data) {
            this.result = params.data.caResult
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}