
<p class="{{class}} padding-top-5" [hidden]="!text">{{text}}
    <span class="color-red font-weight-bold" [hidden]="status == 6 && aboutToExpirytext == ''">{{aboutToExpirytext}}</span>
    <span class="color-red font-weight-bold" [hidden]="status == 9 && suspensionText == ''">{{suspensionText}}</span>
    <span class="text-logo-blue font-weight-bold"  ngbPopover="popContent" triggers="mouseenter:mouseleave" container="body">{{secondDocumentPart3}}
    </span>
    <ng-template #popContent>
        <div class="popoverCustomWidth">
            <p class="text-charcoal-black font-weight-bold text-logo-blue" >({{secondDocumentPart1}}    
            </p>
            <p class="text-charcoal-black font-weight-bold padding-bottom-5" >{{ 'grade_status' | translate}}&nbsp;: 
                <span [style.color]="colorStatusBeforeExpiry" class="font-weight-bold">{{secondDocumentPart2}}</span>)
            </p>
        </div>
    </ng-template>    
</p>




