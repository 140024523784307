<div class="row">
    <div class="col-lg-12 no-padding-right-left">
        <div class="bg-white margin-5 padding-top-10 padding-bottom-10">

            <div *ngIf="!ifNoImage" class="receipt-container mx-auto width-auto" [ngClass]="{'disabled':isDisabled}">
                <div class="add-receipt align-center-middle text-center w-100pc ">
                    <img [src]="imgUrl" class="height-auto mx-auto" alt="logo" />
                </div>
            </div>
            <div *ngIf="ifNoImage" class="receipt-container mx-auto width-auto" [ngClass]="{'disabled':isDisabled}">
                <div class="add-receipt align-center-middle text-center w-100pc ">
                    <button type="button" (click)="fnShowAttachReceiptOptionsPanel()" class="align-center-middle">
                        <span class="d-block">
                            <i class="fas fa-plus-circle f-45 text-mustard" aria-hidden="true">
                            </i>
                        </span>
                        <span class="d-block f-17 margin-top-10 text-charcoal-black">
                            {{'text_add_receipt' | translate}}
                        </span>

                    </button>
                </div>
            </div>
            <div class="mx-auto w-100pc text-center margin-top-10 height-40px">
                <input id="fileSecond" #fileInputSecond type="file" [accept]="fileExtensions" class="fileUpload d-none"
                    (change)="onValueChange(fileInputSecond.files)" (click)="fileInputSecond.value = null" />
                <button [hidden]="ifNoImage" [ngClass]="{'disabled-state':isDisabled}" class="btn btn-primary"
                    (click)="fnShowAttachReceiptOptionsPanel()">
                    {{this.btnText }}
                </button>
            </div>
        </div>
    </div>
</div>

<app-alert-panel *ngIf="showAlertPanel" [data]="alertPanelModel" (callbackEvent)="alertPanelReceiver($event)">
</app-alert-panel>