import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { DateFormatPipe, EODDateFormatPipe, TimeFormatPipe, TimeFormatPipeNew } from "src/app/_helpers/pipe.module";

@Component({
  selector: 'formly-notes',
  templateUrl: "./formly-notes.component.html"
})
export class FormlyNotesComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;

  constructor(private dynamicFormService: DynamicFormService, public datepipe: EODDateFormatPipe, public dateFormatPipe: DateFormatPipe, public timeFormatPipeNew: TimeFormatPipeNew) {
    super();
  }

  ngOnInit() {
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onClick(item) {
    this.to.click(item, this.formControl.value);
  }

  fnActionString(item) {
    if (item) {
      if (this.field.templateOptions?.cTimeformat) {
        return this.datepipe.transform(item.createdOn)+ " " + item.actionString;
      }
      let date = this.dateFormatPipe.transform(item.createdOn, "dd-MMM-yyyy");
      let time = this.timeFormatPipeNew.transform(item.createdOn, "HH:mm");
      let actionString = date + " / " + time + " " + item.actionString
      return actionString;
    }
    return '';
  }

}
