export interface ISubscriptionCardDetailsRequest {
  CardBrand: string;
  CardType: string;
  CardExpirationMonthYear: string;
  CardLastFourDigits: string;
  LogoUrl: string;
  OrgId: number;
  UserId: number;
  PaymentGatewayId: string;
  PaymentCustomerId: string;
  PaymentIntentId: string;
}

export class SubscriptionCardDetailsRequest implements ISubscriptionCardDetailsRequest {
  CardBrand: string;
  CardType: string;
  CardExpirationMonthYear: string;
  CardLastFourDigits: string;
  LogoUrl: string;
  OrgId: number;
  UserId: number;
  PaymentGatewayId: string;
  PaymentCustomerId: string;
  PaymentIntentId: string;
  constructor(CardBrand: string,
    CardType: string,
    CardExpirationMonthYear: string,
    CardLastFourDigits: string,
    LogoUrl: string,
    OrgId: number,
    UserId: number,
    PaymentGatewayId: string,
    PaymentCustomerId: string,
    PaymentIntentId: string
  ) {
    this.CardBrand = CardBrand;
    this.CardType = CardType;
    this.CardExpirationMonthYear = CardExpirationMonthYear;
    this.CardLastFourDigits = CardLastFourDigits;
    this.LogoUrl = LogoUrl;
    this.OrgId = OrgId;
    this.UserId = UserId;
    this.PaymentGatewayId = PaymentGatewayId;
    this.PaymentCustomerId = PaymentCustomerId;
    this.PaymentIntentId = PaymentIntentId;
  }
}
