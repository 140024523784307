export interface IOrganisationGooglePackageSaveRequest {
  OrganisationGooglePackageId: number;
  OrganisationId: number;
  GooglePackageId: number;
  TotalCalls: number;
  UsedCalls: number;
  GooglePackageThresholdLimit: number;
  IsAutoPurchaseEnabled: boolean;
  AmountPaid: number;
  PurchaseDate: number;
  PaymentDate: number;
  TransactionId: string;
  GooglePackageCallCount: number;
  IsPurchaseSuccessful: boolean;
  UnitsPurchased: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CustomerId: string;
  PaymentMethodId: string;
  BillingStatus: number;
  VatPercent: number;
  VatAmount: number;
  BaseAmount: number;
  IsVatApplied: boolean;
  CurrencyCode: string;
}

export class OrganisationGooglePackageSaveRequest
  implements IOrganisationGooglePackageSaveRequest
{
  OrganisationGooglePackageId: number;
  OrganisationId: number;
  GooglePackageId: number;
  TotalCalls: number;
  UsedCalls: number;
  GooglePackageThresholdLimit: number;
  IsAutoPurchaseEnabled: boolean;
  AmountPaid: number;
  PurchaseDate: number;
  PaymentDate: number;
  TransactionId: string;
  GooglePackageCallCount: number;
  IsPurchaseSuccessful: boolean;
  UnitsPurchased: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CustomerId: string;
  PaymentMethodId: string;
  BillingStatus: number;
  VatPercent: number;
  VatAmount: number;
  BaseAmount: number;
  IsVatApplied: boolean;
  CurrencyCode: string;
}

export interface IOrganisationGooglePackageSaveResponse {
  savedRows: number;
}

export class OrganisationGooglePackageSaveResponse
  implements IOrganisationGooglePackageSaveResponse
{
  savedRows: number;
}

export interface IOrganisationGooglePackageUpdateCallsRequest {
  OrganisationId: number;
  CallsUsed: number;
  UpdatedBy: number;
}

export class OrganisationGooglePackageUpdateCallsRequest
  implements IOrganisationGooglePackageUpdateCallsRequest
{
  OrganisationId: number;
  CallsUsed: number;
  UpdatedBy: number;
}

export interface IOrganisationGooglePackageUpdateCallsResponse {
  savedRows: number;
}

export class OrganisationGooglePackageUpdateCallsResponse
  implements IOrganisationGooglePackageUpdateCallsResponse
{
  savedRows: number;
}

export interface IUnsubscribeGooglePackageCallsRequest {
  OrganisationId: number;
  UpdatedBy: number;
}

export class UnsubscribeGooglePackageCallsRequest
  implements IUnsubscribeGooglePackageCallsRequest
{
  OrganisationId: number;
  UpdatedBy: number;
  constructor(OrganisationId: number, UpdatedBy: number) {
    this.OrganisationId = OrganisationId;
    this.UpdatedBy = UpdatedBy;
  }
}

export interface IDismissGooglePackagePurchaseReminderRequest {
  OrganisationId: number;
  UpdatedBy: number;
}

export class DismissGooglePackagePurchaseReminderRequest
  implements IDismissGooglePackagePurchaseReminderRequest
{
  OrganisationId: number;
  UpdatedBy: number;
  constructor(OrganisationId: number, UpdatedBy: number) {
    this.OrganisationId = OrganisationId;
    this.UpdatedBy = UpdatedBy;
  }
}

export interface IUpdateOrganisationGooglePackagePaymentDetailStatusRequest {
  organisationGooglePackagePaymentDetailId: number;
  status: number;
  userId: number;
}

export class UpdateOrganisationGooglePackagePaymentDetailStatusRequest
  implements IUpdateOrganisationGooglePackagePaymentDetailStatusRequest
{
  organisationGooglePackagePaymentDetailId: number;
  status: number;
  userId: number;
}

export interface IOrganisationPlaidPackageSaveRequest {
  OrganisationPlaidPackageId: number;
  OrganisationId: number;
  PlaidPackageId: number;
  TotalTransactions: number;
  UsedTransactions: number;
  PackageThresholdLimit: number;
  IsAutoPurchaseEnabled: boolean;
  AmountPaid: number;
  PurchaseDate: number;
  PaymentDate: number;
  TransactionId: string;
  PlaidPackageTransactionCount: number;
  IsPurchaseSuccessful: boolean;
  UnitsPurchased: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CustomerId: string;
  PaymentMethodId: string;
  BillingStatus: number;
  VatPercent: number;
  VatAmount: number;
  BaseAmount: number;
  IsVatApplied: boolean;
  CurrencyCode: string;
}

export class OrganisationPlaidPackageSaveRequest
  implements IOrganisationPlaidPackageSaveRequest
{
  OrganisationPlaidPackageId: number;
  OrganisationId: number;
  PlaidPackageId: number;
  TotalTransactions: number;
  UsedTransactions: number;
  PackageThresholdLimit: number;
  IsAutoPurchaseEnabled: boolean;
  AmountPaid: number;
  PurchaseDate: number;
  PaymentDate: number;
  TransactionId: string;
  PlaidPackageTransactionCount: number;
  IsPurchaseSuccessful: boolean;
  UnitsPurchased: number;
  CreatedBy: number;
  CreatedOn: number;
  UpdatedBy: number;
  UpdatedOn: number;
  CustomerId: string;
  PaymentMethodId: string;
  BillingStatus: number;
  VatPercent: number;
  VatAmount: number;
  BaseAmount: number;
  IsVatApplied: boolean;
  CurrencyCode: string;
}

export interface IPlaidConnectionResponse {
  OrgId: number;
  PlaidConnection: string;
  AccessToken: string;
  ItemId: string;
  RequestId: string;
  Region: string;
}

export class PlaidConnectionResponse implements IPlaidConnectionResponse {
  OrgId: number;
  PlaidConnection: string;
  AccessToken: string;
  ItemId: string;
  RequestId: string;
  Region: string;
  constructor(
    OrganisationId: number,
    PlaidConnection: string,
    AccessToken: string,
    ItemId: string,
    RequestId: string,
    Region: string
  ) {
    this.OrgId = OrganisationId;
    this.PlaidConnection = PlaidConnection;
    this.AccessToken = AccessToken;
    this.ItemId = ItemId;
    this.RequestId = RequestId;
    this.Region = Region;
  }
}
