<div class="overLayDiv active z-99999" *ngIf="data.showPanel == true"></div>
<div id="rightSideBar">
  <div class="rightSidePanel d-flex flex-column z-99999" *ngIf="data.showPanel == true">
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
      <h2>{{data.title}}</h2>
    </header>
    <div class="clear"></div>

    <div [ngClass]="setClasses()">
      <h6 *ngIf="(data && data.message && data.message.length > 1)">{{ 'note' | translate}}</h6> 

      <div *ngIf="data.htmlMessage.length > 1">
        <h6 class="info-text" *ngFor="let item of data.htmlMessage">
          <i [ngClass]="item.iconClass" aria-hidden="true" *ngIf="item.iconClass"></i>
            <span [innerHTML]="item.textHtml"></span>
        </h6>
      </div>
      <div *ngIf="data.htmlMessage.length == 0 && data.message.length > 1">
        <div *ngIf="data.message.length > 1">
          <h6 class="info-text" *ngFor="let item of data.message">
              <i class="fas fa-exclamation-circle eod-text-danger"></i>
              <span [innerHTML]="item"></span>
          </h6>
        </div>
      </div>
      <div *ngIf="data.htmlMessage.length == 0 && data.message.length == 1">
        <h6 class="info-text" *ngFor="let item of data.message">
            <i class="fas fa-exclamation-circle eod-text-danger" *ngIf="!hideInfoIcon"></i>
            <span [ngClass]="singleMessageTextClass" [innerHTML]="item"></span>
        </h6>
      </div>      
      <div *ngIf="data.htmlMessage.length == 0 && data.message.length == 0">
        <h6 class="info-text mb-0 pb-0 border-0">
            <i class="fas fa-exclamation-circle eod-text-danger"></i>
            <span [innerHTML]="sanitizerHtml(data.showReviewConfirmContinuePanelText)"></span>
        </h6>
      </div>
    </div>

    <footer class="mt-auto py-1 d-flex justify-content-center align-items-center height-60px" *ngIf="this.data.btnArray.length == 0">
      <button type="button" class="btn eod-btn-outline-warning mr-2" *ngIf="this.data.btnCancelToolTipText!=''" (click)="dismiss()">
        {{this.data.btnCancelToolTipText}}
      </button>
      <button type="button" class="btn eod-btn-primary" *ngIf="this.data.btnOkToolTipText!=''" (click)="accept()">
        {{this.data.btnOkToolTipText}}
      </button>
    </footer>
    <footer class="mt-auto py-1 d-flex justify-content-center align-items-center height-60px" *ngIf="this.data.btnArray.length > 0">
      <button type="button" class="mr-2" [ngClass]="item.btnClass" *ngFor="let item of data.btnArray" (click)="fnSendAction(item.btnAction)">
        {{item.btnText}}
      </button>
    </footer>
  </div>
</div>
