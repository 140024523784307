<div class="overLayDiv" [ngClass]="{ active: (showReceipt || showReceiptsPanel || showMileageMap) && !showMapSectionOnly}"></div>

<div id="rightSideBar" *ngIf="showReceiptsPanel">
    <div class="rightSidePanel w-55pc active  {{zindexClass}}">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2 *ngIf="isImageOnly"><span *ngIf="claimType==1">{{ "invoice_receipt" | translate: param }}</span>
                                <span *ngIf="claimType == 2">{{ "label_invoice" | translate: param }}</span>
                            </h2>
                            <h2 *ngIf="!isImageOnly"><span *ngIf="claimType == 1">{{ "receipt_panel_title" | translate: param }}</span>
                                <span *ngIf="claimType == 2">{{ "invoice_receipt_panel_title" | translate: param }}</span>
                            </h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnCloseReceiptsPanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
            <div class="block cursor-pointer" *ngFor="let item of selectedReceiptInfo;index as receiptIndex;">
                <div class="row" (click)="fnShowSelectedReceipt(item, receiptIndex)">
                    <div class="col-lg-6 padding-left-0">
                        <p>
                            <span class="color-red position-absolute "
                                *ngIf="item.receiptFormatId == attachmentTypeEnum.Pdf">
                                <i class="fas fa-file-pdf f-20 align-middle"></i>
                            </span>
                            <span class="position-absolute " *ngIf="item.receiptFormatId != attachmentTypeEnum.Pdf">
                                <i class="fal fa-file-alt f-20 align-middle"></i>
                            </span>
                            <span
                                class="padding-left-10 w-90pc d-inline-block margin-left-20 lH-normal font-weight-bold text-truncate">
                                {{item.receiptName}}
                                <small class="d-block">
                                    <span *ngIf="item.receiptSize != 0">{{fnFormatBytes(item.receiptSize)}}</span>
                                </small>
                            </span>
                        </p>
                    </div>

                    <div class="col-lg-6 padding-right-0 text-right">
                        <p>
                            <span class="position-absolute w-90pc right-0 top--10px f-13">
                                {{this.eodDatePipe.transform(item.receiptDate)}}
                            </span>
                            <span class="d-block padding-top-10">
                                <i class="fas fa-chevron-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div id="rightSideBar" *ngIf="showReceipt"  [ngClass]="{ 'second-panel' : isFromDOCApproval }">
    <div class="rightSidePanel w-55pc active {{zindexClass}}">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2 *ngIf="!mapShow"><span *ngIf="claimType==1">{{ "invoice_receipt" | translate: param }}</span>
                                <span *ngIf="claimType == 2">{{ "label_invoice" | translate: param }}</span>
                            </h2>
                            <h2 *ngIf="mapShow">{{ "map" | translate: param }}</h2>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li *ngIf="showScanDetails && !hideUseReceipt" (click)="fnToggleReceipt()">
                                    <button type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                        {{ 'use_the_receipt' | translate: param }}
                                    </button>
                                </li>
                                <li *ngIf="showScanDetails && !hideUseReceipt" (click)="fnScanReceipt()">
                                    <button type="button"
                                        class="btn btn-primary color-white height-38px margin-right-15">
                                        {{ 'scan_receipt' | translate: param }}
                                    </button>
                                </li>                                
                                <li *ngIf="showDeleteButton && !mapShow" (click)="fnDeleteReceipt()">
                                    <button type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                        {{ 'label_delete' | translate: param }}
                                    </button>
                                </li>
                                <li (click)="downloadReceipt()">

                                    <button *ngIf="claimType==1" type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                        {{ 'download_receipt' | translate: param }}
                                    </button>
                                    <button *ngIf="claimType==2" type="button"
                                    class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                    {{ 'download_invoice' | translate: param }}
                                </button>
                                </li>
                                <li (click)="fnToggleReceipt()">
                                    <button type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'close' | translate: param }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div #receiptImageContainer class="col-md-12 margin-top-5 no-padding-right-left"
            style="background-color: white;">
            <div [hidden]="mapShow">
                <button class="arrow-left" (click)="prevImageClick()" *ngIf="imageReceiptInfo.length > 1">
                    <span>
                        <i class="fas fa-lg fa-chevron-double-left"></i>
                    </span>
                </button>
                <div [attr.style]="'height:' + imageHeight + ';overflow: scroll !important;'" class="text-center"
                    *ngFor="let item of imageReceiptInfo;index as receiptIndex;"
                    [hidden]="currentReceiptId != item.expenseReceiptId">
                    <img #receiptImage id="{{ item.expenseReceiptId }}" [src]="item.receiptUrl"
                        style="padding-top: 10px;" (load)="stopSpinner()" />
                </div>
                <button class="arrow-right" (click)="nextImageClick()" *ngIf="imageReceiptInfo.length > 1">
                    <span>
                        <i class="fas fa-lg fa-chevron-double-right"></i>
                    </span>
                </button>
                <div class="text-center margin-top-5" *ngIf="!hideUseReceipt">
                    <button type="button" class="zoom-button-left" (click)="zoomOut()">
                        <span>
                            <i class="fas fa-lg fa-search-minus"></i>
                        </span>
                    </button>
                    <button type="button" class="zoom-button-right" (click)="zoomIn()">
                        <span>
                            <i class="fas fa-lg fa-search-plus"></i>
                        </span>
                    </button>
                </div>
                <div class="text-center margin-top-5" *ngIf="imageReceiptInfo.length > 1">
                    <span style="color:black; font-weight: bold;">
                        {{(currentIndex + 1) + ' / ' + imageReceiptInfo.length }}
                    </span>
                </div>

            </div>
            <div [hidden]="!mapShow">
                <google-map height="600px" width="900px" [center]="center" [zoom]="zoom">
                    <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
                        [options]="markerOptions">
                    </map-marker>
                </google-map>
            </div>
            <div class="text-center margin-top-5" *ngIf="strExpenseIds!=''">
                <span>
                    {{ "linked_expense_id" | translate: param }}: <strong>{{strExpenseIds}}</strong>
                </span>
            </div>

        </div>
        <div #receiptImageFooter
            class="text-center d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15">
            <a class="d-block w-100pc color-blue" *ngIf="receiptLocation" (click)="mapShow = true">
                <div style="position: relative;">
                    <i class="fas fa-2x fa-map-marker-alt"
                        style="position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
                    </i>
                    <span style="margin-left: 23px;color: #007bff !important;" *ngIf="receiptLocation">
                        <a>{{ receiptLocation }}</a>
                    </span>
                    <br>
                    <span style="margin-left: 23px;color: #007bff !important;" *ngIf="receiptLocation">
                        <a>{{ this.eodDatePipe.transform(receiptDate) }}</a>
                    </span>
                </div>
            </a>
            <a class="d-block w-100pc color-blue cursor-default" *ngIf="!receiptLocation">
                <div style="position: relative;">
                    <i class="fas fa-2x fa-map-marker-alt"
                        style="position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
                    </i>
                    <span style="margin-left: 23px;color: red !important;" *ngIf="!receiptLocation">
                        {{ "receipt_no_location" | translate: param }}
                    </span>
                </div>
            </a>
        </div>
    </div>
</div>

<div id="rightSideBar" *ngIf="showCommonMileageMap && !showMapSectionOnly">
    <div class="rightSidePanel active  {{zindexClass}}">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8  padding-left-0">
                            <h2> {{ "map" | translate: param }}</h2>
                        </div>
                        <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="closeMileagePopup()">
                                    <button type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-lg-12 margin-top-10 no-padding-right-left">
            <div class="block bg-transparent padding-10 heightCalc-21"
                style="padding-top:5px !important;padding-bottom:5px !important">
                <google-map height="100%" width="{{mapWidth}}" [center]="center" [zoom]="zoom">
                    <map-polyline [path]="vertices" [strokeColor]="'red'"></map-polyline>
                    <map-marker *ngFor="let markerPosition of markers" [position]="markerPosition.position"
                        [options]="markerPosition.options" [label]="markerPosition.label">
                    </map-marker>
                </google-map>
            </div>

            <div class="block" *ngIf="showMileageMapBasedOnCategory">
                <div class="row">
                    <div class="col-lg-6 padding-left-0">
                        <ul>
                            <li *ngFor="let item of mapObjectArray" [ngClass]="item.class">
                                {{ item.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 padding-right-0">
                        <ul>
                            <li *ngFor="let item of mapObjectArray" [ngClass]="item.valueClass">
                                {{ item.value }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row mt-2 bg-mustard" *ngIf="showMileageMap"
                    [ngClass]="{'no-active':journeyType==3 && !isCompleteJourney}">
                    <div class="col-lg-12 no-padding-right-left ">
                        <p class="w-50pc text-left float-left padding-8 fw-700 f-18">
                            {{ amountDynamicText }}
                        </p>
                        <p class="w-50pc text-right float-right padding-8 fw-700 f-18">
                            {{ amount | number: ".2" | noComma }} {{ currency }}
                        </p>
                    </div>
                </div>
                <div class="row mt-2 bg-mustard" *ngIf="showMileageMapFromWeb">
                    <div class="col-lg-12 no-padding-right-left ">
                        <p class="w-50pc text-left float-left padding-8 fw-700 f-18">
                            {{ amountDynamicText }}
                        </p>
                        <p class="w-50pc text-right float-right padding-8 fw-700 f-18">
                            {{ amount | number: ".2" | noComma }} {{ currency }}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<app-pdf-viewer *ngIf="showPdfViewer" [showPdfViewerPanel]="showPdfViewer" [pdfUrl]='pdfSrc' [pdfName]='pdfName'
    [pdfExpenseReceiptId]='pdfExpenseReceiptId' [showDeleteButton]="showDeleteButton"
    (callbackEvent)="fnPdfViewerReceiver($event)" (deletePdfEvent)="fnDeletePdf($event)"
    [claimType]="claimType" [isClaimPage]="isClaimPage">
</app-pdf-viewer>

<app-alert-panel [data]="alertPanelModelForDeletePdf" (callbackEvent)="fnCloseDeletePdfAlert($event)">
</app-alert-panel>

<app-img-preview-panel *ngIf="showPreviewPanel"
  [scannedText]="scannedText"
  [previewImageUrl]="previewImageUrl"
  (closePanelEvent)="fnClosePreviewPanel($event)"
  (savePanelEvent)="fnSavePreviewPanel($event)"></app-img-preview-panel>

<app-alert-panel [data]="alertPanelModelForDeleteReceipt" (callbackEvent)="fnCloseDeleteReceiptAlert($event)">
</app-alert-panel>


<div *ngIf="showCommonMileageMap && showMapSectionOnly">
    <google-map [center]="center" height="300px" width="100%" [zoom]="zoom">
        <map-polyline [path]="vertices" [strokeColor]="'red'"></map-polyline>
        <map-marker *ngFor="let markerPosition of markers" [position]="markerPosition.position"
            [options]="markerPosition.options" [label]="markerPosition.label">
        </map-marker>
    </google-map>
</div>