import { Directive, Input, forwardRef, HostListener, ElementRef } from "@angular/core";
import {
  Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn
} from "@angular/forms";

@Directive({
  selector: "[min][formControlName],[min][formControl],[min][ngModel]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinDirective),
      multi: true
    }
  ]
})
export class MinDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set min(value: string) {
    this._validator = Validators.min(parseFloat(value));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }
}

@Directive({
  selector: "[max][formControlName],[max][formControl],[max][ngModel]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxDirective),
      multi: true
    }
  ]
})
export class MaxDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set max(value: string) {
    this._validator = Validators.max(parseFloat(value));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }
}

@Directive({
  selector: '[numeric]'
})

export class NumericDirective {

  @Input('decimals') decimals: number = 0;

  private check(value: string, decimals: number) {
    if (decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
      return String(value).match(new RegExp(regExpString));
    }
  }

  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Del'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || this.check(event.key, this.decimals)) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !this.check(next, this.decimals)) {
      event.preventDefault();
    }
  }
}
