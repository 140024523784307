import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'app-formly-toggle-rule',
  templateUrl: './formly-toggle-rule.component.html',
  styleUrls: ['./formly-toggle-rule.component.scss']
})
export class FormlyToggleRuleComponent extends FieldType implements OnInit, OnDestroy {

  param: any;
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;
  mainClass: string;
  constructor(private dynamicFormService: DynamicFormService) {
      super();
  }

  ngOnInit() {
      if (this.field.key) {
          this.fieldKey = this.field.key.toString();
      }
      this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
          this.isFormSubmitted = data;
      });
  }

  ngOnDestroy() {
      if (this.formSubmissionSubscription) {
          this.formSubmissionSubscription.unsubscribe();
      }
  }

  onValueChange() {
      this.to.change(this.field, this.formControl.value);
  }

}
