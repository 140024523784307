<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8  padding-left-0">
                            <h2>{{ "recent_journeys" | translate: param }}</h2>
                        </div>
                        <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnClosePanel()">
                                    <button type="button"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-lg-12 margin-top-10 no-padding-right-left">
            <div class="scrollbar-visible heightCalc-6 overflow-auto">

                <div class="block" *ngIf="(recentJourneysList).length === 0">
                    <p class="text-center noDataAvailable disabled opacity-60">
                        {{ "no_data_available" | translate: param }}</p>
                </div>

                <div class="block float-left w-100pc padding-top-10 padding-bottom-30 padding-left-10 cursor-pointer"
                    *ngFor="let item of recentJourneysList" (click)="fnSelectRecentJourneys(item)">
                    <div class="vertical-dotted-border-3 padding-left-15 margin-left-10 ">
                        <div class="position-relative">
                            <span class="position-absolute left--23px top-0px bg-white"><i
                                    class="far fa-dot-circle color-amber2"></i></span>
                            <p class="padding-top-0"><span
                                    class="fw-700 d-block lH-normal">{{item.fromLocation.location}} </span>
                            </p>
                        </div>
                        <div class="position-relative">
                            <span class="position-absolute left--21px bottom-0 bg-white"><i
                                    class="fas fa-map-marker-alt text-logo-blue"></i></span>
                            <p class="margin-top-30"><span class="fw-700 d-block lH-normal">
                                    {{item.toLocation.location}}
                                </span> </p>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    <p class="position-absolute bottom-10 right-10 fw-700 text-logo-blue">
                        {{item.totalDistance | number: "1.2-2" | noComma}}
                        <span *ngIf="item.mileageUOM == mileageUOMEnum.Kilometre">{{'text_km' | translate:param}}</span>
                        <span *ngIf="item.mileageUOM == mileageUOMEnum.Mile">{{'text_m' | translate:param}}</span>
                    </p>
                </div>
            </div>
            <!-- <div class="scrollbar-visible heightCalc-6 overflow-auto">

                <div class="block" *ngIf="(recentJourneysList).length === 0">
                    <p class="text-center noDataAvailable disabled opacity-60">
                        {{ "no_data_available" | translate: param }}</p>
                </div>

                <div class="block float-left w-100pc padding-top-10 padding-bottom-30 padding-left-10 cursor-pointer"
                    *ngFor="let item of recentJourneysList" (click)="fnSelectRecentJourneys(item)">
                    <div class="vertical-dotted-border-3 padding-left-15 margin-left-10">
                        <div class="position-relative">
                            <span class="position-absolute left--23px top-0px bg-white"><i
                                    class="far fa-dot-circle color-amber2"></i></span>
                            <p class="padding-top-5"><span
                                    class="fw-700 d-block lH-10">{{item.fromLocation.location}}</span>
                            </p>
                        </div>
                        <div class="position-relative">
                            <span class="position-absolute left--21px bottom-0 bg-white"><i
                                    class="fas fa-map-marker-alt text-logo-blue"></i></span>
                            <p class="margin-top-30"><span
                                    class="fw-700 d-block lH-10">{{item.toLocation.location}}</span>

                            </p>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    <p class="position-absolute bottom-10 right-10 fw-700 text-logo-blue">
                        {{item.totalDistance | number: "1.2-2" | noComma}}
                        <span *ngIf="item.mileageUOM == mileageUOMEnum.Kilometre">{{'text_km' | translate:param}}</span>
                        <span *ngIf="item.mileageUOM == mileageUOMEnum.Mile">{{'text_m' | translate:param}}</span>
                    </p>
                </div>
            </div> -->
        </div>
    </div>
</div>