<div *ngIf="!preApprovalId">
    <p>{{name}}</p>
</div>

<div *ngIf="preApprovalId > 0 && preApprovalTag">
    <p>{{name}} 
        <span class="px-2">|</span> 
        <span class="eod-text-danger f-13" *ngIf="preApprovalTag.remainingDays <= 0">
            {{ 'label_expired' | translate }}
        </span>
        <span class="eod-text-danger f-13" *ngIf="preApprovalTag.remainingDays > 0">
           {{preApprovalTag.remainingDays}} {{ 'day_left' | translate }}
        </span>
    </p>
    <div>
        <p class="eod-text-grey-01 lh-sm f-13">
            {{ 'approval_date' | translate }}:  {{ preApprovalTag.approvalDate | date: "dd-MMM-yyyy" }}<span class="px-2">|</span>
            <span class="eod-text-info lh-sm f-13">
                {{ 'balance' | translate }}: {{ preApprovalTag.balance | number: "1.2-2" | noComma }} {{preApprovalTag.currencyCode}}</span>
        </p>
        <p class="eod-text-danger lh-sm d-flex f-13" *ngIf="preApprovalTag.overDue > 0">
            <i class="fas fa-exclamation-circle d-inline-block lh-sm pr-2"></i>
            
            <span class="d-flex">{{ 'pre_approval_note1' | translate }} 
                {{preApprovalTag.overDue}} {{ 'pre_approval_note2' | translate }} 
            </span>
        </p>
    </div>
</div> 