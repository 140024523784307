<div class="input-group">
  <input id="calendarInput" type="text" class="form-control formly-calender-input"
    [ngClass]="{'has-value': formControl.value, 'margin-top-20': addTopMargin,
      'color-light-grey-disabledType pointer-events-none': to.disabled, 'text-logo-blue': !to.disabled }"
    #dateInput readonly [formControl]="formControl"
    [formlyAttributes]="field" (input)="onValueChange()">
  <label for="calendarInput" [hidden]="!isLabelVisible">
    {{calendarLabel}}<span *ngIf="field?.templateOptions?.required">*</span>
  </label>
  <app-calendar-icon></app-calendar-icon>
  <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
    <span *ngIf="!formControl.value || !formControl.value.trim()">
      <b>{{ "label_Required" | translate: param }}</b>
    </span>
  </div>
  <p class="text-left" *ngIf="field.templateOptions.note && field.templateOptions.note">
    <small>
        {{field.templateOptions.note}}
    </small>
</p>
</div>
