export interface IFunctionDetail {
  FunctionId: number;
  CurrencyId: number;
}

export class FunctionDetail implements IFunctionDetail {
  FunctionId: number;
  CurrencyId: number;
  constructor(FunctionId: number,
    CurrencyId: number,
  ) {
    this.FunctionId = FunctionId;
    this.CurrencyId = CurrencyId;
  }
}
