<!-- vertical layout -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

<!-- horizontal layout -->
<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>

<div *ngIf="showWebNotificationMessage"
     class="overLayDiv active"></div>
<div *ngIf="showWebNotificationMessage"
     id="rightSideBar"
     style="display: block"
     class="">
    <div class="rightSidePanel w-55pc"
         style="display: block">
        <header>
            <div class="row">
                <div class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12
                no-padding-right-left
              ">
                    <div class="row">
                        <div class="
                    col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8
                    padding-left-0
                  ">
                            <h2>{{ "information" | translate: param }}</h2>
                        </div>

                        <div class="
                    col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4
                    padding-right-0
                    header-icons
                    text-right
                  "></div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div>
            <div class="
            col-md-12 col-sm-12 col-lg-12 col-xl-12
            margin-top-10
            no-padding-right-left
          ">
                <div class="block">
                    <div class="row text-left font-weight-bold">
                        <p style="margin-top: 5px;padding-bottom: 5px;font-weight: 600;">
                            <i aria-hidden="true"
                               class="fas fa-exclamation-circle color-red"></i>
                            {{webNotificationMessage}} {{ 'policy_click_message_part2' | translate}}
                             <a class=" text-underline"
                               href="../../../assets/BrowserSettings.png"
                               target="_blank">{{ 'strip_note2_1' | translate}}</a> {{ 'for_settings' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="
            d-flex
            position-absolute
            bottom-10
            w-100pc
            justify-content-center
            bg-white
            padding-15
          ">
            <span class="margin-right-15"><button class="btn btn-outline-primary"
                        (click)="showWebNotificationMessage = false">
                    {{ "label_close" | translate: param }}
                </button></span>
        </div>
    </div>
</div>