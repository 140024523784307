import { Component, ViewEncapsulation } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'approver-cell',
    templateUrl: './approver-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ApproverRenderer implements ICellRendererAngularComp {
    text: string = '';
    class: string = '';
    popOverText: string = '';
    public isPopOverDisplay: boolean = false;
    constructor() {
    }

    initilize(params: ICellRendererParams) {
        this.text = params.getValue();
        let classProp = '';
        if (params.colDef && params.colDef.headerComponentParams) {
            this.isPopOverDisplay = params.colDef.headerComponentParams.isPopOverDisplay;
            classProp = params.colDef.headerComponentParams.classPropertyName
        }
        if (params.data) {
            switch (params.data.approverCount) {
                case 0:
                    this.class = 'cursor-pointer text-underline not-invited-state fw-700';
                    break;
                case 1:
                    this.class = 'cursor-pointer text-underline pending-state fw-700';
                    break;
                default:
                    this.class = 'cursor-pointer text-underline text-logo-blue fw-700';
                    break;
            }

            if (params.data['popOverText']) {
                this.popOverText = params.data['popOverText'];
            }
            if (classProp) {
                this.class = params.data[classProp];
            }
        }
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
}