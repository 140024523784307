import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'receipts-cell',
    templateUrl: './receipts-icon-renderer.component.html'
})
export class ReceiptsIconRendererComponent implements ICellRendererAngularComp {
    public hasReceipt: boolean = false;

    constructor() {

    }

    initilize(params: ICellRendererParams) {
        if (params.data) {
            this.hasReceipt = params.data.hasReceipt
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
