export const environment = {
  production: true,
  AppUrl: "#{AppUrl}#",
  GatewayUrl: "#{GatewayUrl}#",
  catalogApiUrl: "#{CatalogApimUrl}#",
  expenseApiUrl: "#{ExpenseApimUrl}#",
  exportApiUrl: "#{ExportApimUrl}#",
  billingApiUrl: "#{BillingApimUrl}#",
  docApiUrl: "#{DocApimUrl}#",
  notificationApiUrl: "#{NotificationApimUrl}#",
  functionsUrl: "#{AzFunctionsUrl}#",
  apimSubscriptionKey: "#{ApimSubscriptionKey}#",
  apimTraceEnabled: "#{ApimTraceEnabled}#",
  apimDevPortalUrl: "#{ApimDevPortalUrl}#",
  platform: "web",
  applicationVersion: 1.1,
  appId: "environment_appId",
  deviceId: "environment_deviceId",
  requestTimeStamp: 1569566525,
  requestId: "HJJHG2627KJH",
  StripePublicKey: "#{StripePublicKey}#",
  assistedSupportAmount_INR: 5000,
  assistedSupportAmount_GBP: 750,
  billingPlanRenewalDay: 180,
  pricingMinimumPrice_INR: 500,
  pricingMinimumPrice_GBP: 25,
  noOfRecords: 20,
  trialFeedbackDays: 15,
  instrumentationkey: "#{AppInsightsInstrumentationKey}#",
  googleApiKey: "AIzaSyAZ9a2KC5kcWBZyhivHTmI8Mj2wxT3vJTU",
  token_endpoint: "#{IdentityServerUrl}#/connect/token",
  authorization_endpoint: "#{IdentityServerUrl}#/connect/authorize",
  userinfo_endpoint: "#{IdentityServerUrl}#/connect/userinfo",
  end_session_endpoint: "#{IdentityServerUrl}#/connect/endsession",
  check_session_endpoint: "#{IdentityServerUrl}#/connect/checksession",
  revocation_endpoint: "#{IdentityServerUrl}#/connect/revocation",
  issuer: "#{IdentityServerUrl}#",
  openRoutes: [
    "/dashboard/overview",
    "/mysubscription",
    "/comingsoon",
    "/pricing",
    "/support/support",
    "/universalimport",
  ],
  restrictedRoutes: [
    "/secondaryfm/list",
    "/secondaryfm/privilege",
    "/support/unsubscribe",
    "/settings",
    "/shoppingcartlist",
    "/shoppingcartreview",
    "/shoppingcartConfirmed",
    "/stripeAgreement",
    "/orgsetting",
  ],
  fmNoPrivilegeRoutes: [
    "/approver/appclaimant",
    "/claimant/expenselist",
    "/changepassword",
    "/approver/appnrreceipts",
    "/approver/appfcreceipts",
    "/approver/papproval",
    "/approver/advanceclaimantlist",
    "/claimant/claimantdashboard",
    "/claimant/claimantnrlist",
    "/claimant/claimantfclist",
    "/claimant/mymileagerate",
    "/orgsetting/claimantfcdashboard",
  ],
  xeroClientId: "#{XeroConfigurationClientId}#",
  dateFormat: "dd-MMM-yyyy",
  defaultauth: "fakebackend",
  plaidClientId_UK: "#{plaidClientId_UK}#",
  plaidClientSecret_UK: "#{plaidClientSecret_UK}#",
  plaidClientId_US: "#{plaidClientId_US}#",
  plaidClientSecret_US: "#{plaidClientSecret_US}#",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};
