<div class="input-group">
    <p class="f-11 font-weight-600" [ngClass]="{'color-light-grey-disabledType': to.disabled}">
      {{field.templateOptions.label}}<span *ngIf="to.required" class="color-red">*</span>
    </p>
    <textarea id="{{ 'txtarea' + fieldKey }}" [readonly]="to.readonly" 
      [formControl]="formControl" [formlyAttributes]="field"
      class="form-control has-value"
      [ngClass]="{'has-value': formControl.value,'color-light-grey-disabledType': to.disabled }" (input)="onTextInput()"
      class="border w-100pc padding-5 margin-top-5 height-50px">
    </textarea>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>
    <p  *ngIf="field.templateOptions.note && field.templateOptions.note && field.templateOptions.maxLength && field.templateOptions.maxLengthCount">
      <small class="text-left" *ngIf="field.templateOptions.note && field.templateOptions.note">
          {{field.templateOptions.note}}
      </small>
      <small class="fa-pull-right" *ngIf="field.templateOptions.maxLength && field.templateOptions.maxLengthCount" >
          {{formControl.value ? formControl.value.length : 0}} / {{ field.templateOptions.maxLength}}
      </small>     
    </p>
</div>
