<div class="overLayDiv active"></div>
<div id="rightSideBar">
  <div class="rightSidePanel active" style="z-index: 99999;">
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
      <h2>{{ (isAdvancesDisplay ? 'select_advance' : 'select_category') | translate }}</h2>
      <div>
          <button type="button" (click)="fnClosePanel(true);"
              class="btn eod-btn-outline-warning height-38px">
              {{ 'label_close' | translate }}
          </button>
      </div>
    </header>
    
    <div class="clear"></div>
    <div class="row">
      <div class="col-lg-12 no-padding-right-left margin-top-15">
        <div class="block fw-700" *ngIf="isAdvancesDisplay">
          {{ "select_advance_text" | translate: param }}
        </div>
        <div class="block overflow-auto heightCalc-7 scrollbar-visible">
          <div class="row">
            <ul class="bulkList" *ngIf="!isAdvancesDisplay">
              <li class=" f-14 d-flex justify-content-between flex-row" style="display: flex !important;" *ngFor="let item of recentCategoryList"
                [ngClass]="{'active': item.orgExpenseCategoryId == selectedOrgExpenseCategoryId }"
                (click)="fnSelectExpenseCategory(item)">
                <span class="text-ellipsis">
                  <app-category-icons [expenseCategoryId]="item.expenseCategoryId"
                    className='svg-fill-theme-new padding-right-10'>
                  </app-category-icons>
                  {{item.expenseCategoryName}}                  
                </span>  
                 
                
                <div>
                  <span class="color-red mr-1" *ngIf="inputData?.nrfcStatus == 1 
                  && (item.baseCategoryId == 1 || item.baseCategoryId == 2 || item.baseCategoryId == 3)">
                  ({{ 'vehicle_mangt_msg10' | translate }})
                </span>      
                <i *ngIf="item.isRecentCategory" class="far fa-clock "></i>
                </div>
                
              </li>
            </ul>

            <ul class="bulkList" *ngIf="isAdvancesDisplay">
              <li class="border-bottom padding-bottom-12  f-14 " *ngFor="let item of advancesList"
                (click)="fnSelectAdvanceItem(item)" [ngClass]="{'active': item.advanceId == selectedAdvanceId }">
                <span class="fw-700">{{ 'cash_advance_on_credit_card_title' | translate }}</span>
                <span class="d-inline-block w-100p">{{item.amount}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert-panel *ngIf="showPopUpForNoCategorySetup" [data]="showPopUpForNoCategorySetupModel"
    (callbackEvent)="fnShowPopUpForNoCategorySetupReceiver($event)">
</app-alert-panel>