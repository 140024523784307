import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "../common/claims.service";
import { environment } from "src/environments/environment";
import {
  IApiRequest,
  IApiresponse,
  IRequestVehicleTypes,
  IOrgUKRateDefaultUIVehicle,
  IOrgUKRateSelectedVehiclesResponse,
  ApiRequest,
  RequestVehicleTypes,
  FunctionPackageEnum,
  AlertPanelTypeEnum,
} from "src/app/_models";
import { Observable, Subscription } from "rxjs";
import {
  IOrgOfficeCodesRequest,
  IOfficeDetails,
  ISaveOfficePostcodeResponse,
} from "src/app/_models/advance-mileage/org-office-codes";
import { IHomeWorkerEmployeesListRequest } from "src/app/_models/advance-mileage/home-worker-employees-list-request";
import {
  IEmployeeListByVehicleRequest, IOrgUKMileageVehicleTypesWithCounter,
  IUserByVehicleList, IEmployeeListByVehicleMapIdRequest, IVehicleReportRequest, IVehicleReportResponse
} from 'src/app/_models/advance-mileage/quick-vehicle-assignment';
import { IHomeWorkerEmployeeListResponse } from "src/app/_models/advance-mileage/home-worker-employee-list-response";
import { IEmployeeHomeWorkerCounterRequest } from "src/app/_models/advance-mileage/employee-home-worker-counter-request";
import { IEmployeeHomeWorkerCounterResponse } from "src/app/_models/advance-mileage/employee-home-worker-counter-response";
import { ISaveHomeWorkerListRequest } from "src/app/_models/advance-mileage/save-home-worker-list-request";
import { ISaveOrgMileageWizardResponse } from "src/app/_models/advance-mileage/save-org-mileage-wizard-response";
import { IOfficeWorkerEmployeeListResponse } from "src/app/_models/advance-mileage/office-worker-employee-list-response";
import { ISaveOfficeWorkerListRequest } from "src/app/_models/advance-mileage/save-office-worker-list-request";
import { IFetchMilesRequest } from "src/app/_models/advance-mileage/fetch-miles-request";
import { IFetchMilesResponse } from "src/app/_models/advance-mileage/fetch-miles-response";
import { ISubscriptionMileageRequest } from "src/app/_models/advance-mileage/subscription-mileage-request";
import { IEmployeeVehicleCounterRequest } from "src/app/_models/advance-mileage/employee-vehicle-counter-request";
import { IAdvVehicleTypeCounter } from "src/app/_models/advance-mileage/adv-vehicle-type-counter";
import { IOrgBasicMileageVehicleTypes } from "src/app/_models/advance-mileage/org-basic-mileage-vehicle-types";
import { IMileageAnalysisRequest, IMileageAnalysisResponse } from 'src/app/_models/advance-mileage/mileage-analysis';

import { IOrgUKRatesByOrgMapIdResponse } from "src/app/_models/advance-mileage/org-ukrates-by-org-map-id-response";
import { ITaxRateMasterResponse } from "src/app/_models/manage-category/tax-rate-master-response";
import { IVehicleTypeMasterInfo } from "src/app/_models/advance-mileage/vehicle-type-master-info";
import {
  IUkRateInfo,
  SaveOwnershiptypeRequest,
} from "src/app/_models/advance-mileage/uk-rate-info";
import { IValidateOwnershipDeactiveRequest } from "src/app/_models/advance-mileage/validate-ownership-deactive-request";
import { IValidateEffectiveDateRequest } from "src/app/_models/advance-mileage/validate-effective-date-request";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import {
  ICartDetailsRequest,
  ICartDetailsResponse,
  IPurchaseFunctionListRequest,
  PurchaseFunctionListRequest,
} from "src/app/_models/organisation-setting/organisation-setting";
import { ValidateOwnershipNameRequest } from "src/app/_models/advance-mileage/validate-ownership-name-request";
import {
  IAddUpdateUserVehicleRequest,
  IReqVehicleExpenseCounter,
  IRespVehicleExpenseCounter,
} from "../../_models";
import { OrganisationSettingService } from "../organisation-setting/organisation-setting.service";
import {
  KeyValueArray,
  Wizard,
  WizardDetailRequest,
  WizardResponse,
} from "src/app/_models/common/wizard";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { MarketingPopup } from "src/app/_models/common/marketing-popup";
import { OrgOwnershipTypeResponse } from "src/app/_models/advance-mileage/org-ukmileage-rate-info";
@Injectable({
  providedIn: "root",
})
export class UkMileageRateSettingService {
  //defaultParams: IDefaultParams;
  //deviceInfo: IDeviceInfo;
  //httpHeaders: HttpHeaders;
  orgId: number;
  userId: number;
  token: string;
  loginid: string;
  //organisationCurrency: string;
  claims: Claims;
  //httpHeaders: any = this.header.createAuthorizationHeader();
  claimsSubscription: Subscription;
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private claimsHeaderService: ClaimsHeaderService,
    private claimsService: ClaimsService,
    private organisationSettingService: OrganisationSettingService
  ) {
    this.claimsSubscription = this.claimsService.currentClaims.subscribe(
      (claims) => {
        this.claims = claims;
        this.orgId = this.claims.OrgId;
        this.userId = this.claims.UserId;
        this.token = this.claims.Token;
        this.loginid = this.claims.LoginId;
      }
    );
  }

  SoloGetOrgUKRateSelectedVehicles(
    requestinfo: IApiRequest<IRequestVehicleTypes>
  ): Observable<IApiresponse<IOrgUKRateSelectedVehiclesResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgUKRateSelectedVehiclesResponse>>(
      `${environment.billingApiUrl}GetOrgUKRateSelectedVehicles`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloGetOrgOfficeCodes(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetOrgOfficeCodes`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloSaveOfficePostcode(
    requestinfo: IApiRequest<IOfficeDetails>
  ): Observable<IApiresponse<ISaveOfficePostcodeResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOfficePostcodeResponse>>(
      `${environment.billingApiUrl}SaveOfficePostcode`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeeHomeWorkerList(): Observable<
    IApiresponse<IHomeWorkerEmployeeListResponse>
  > {
    return this.http.get<IApiresponse<IHomeWorkerEmployeeListResponse>>(
      `${environment.billingApiUrl}GetEmployeeHomeWorkerList`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeeOfficeWorkerList(userId = 0): Observable<
    IApiresponse<IOfficeWorkerEmployeeListResponse>
  > {
    if (userId) {
      return this.SoloGetEmployeeOfficeWorkerListWithUserId(userId)
    }
    else {
      return this.http.get<IApiresponse<IOfficeWorkerEmployeeListResponse>>(
        `${environment.billingApiUrl}GetEmployeeOfficeWorkerList`,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
    }
   
  }
  SoloGetEmployeeOfficeWorkerListWithUserId(
    userId
  ): Observable<IApiresponse<IOfficeWorkerEmployeeListResponse>> {
    let url = `${environment.billingApiUrl}GetEmployeeOfficeWorkerList/` + userId + ``;
    return this.http.get<IApiresponse<IOfficeWorkerEmployeeListResponse>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  SoloGetEmployeeHomeWorkerCounter(
    requestinfo: IApiRequest<IEmployeeHomeWorkerCounterRequest>
  ): Observable<IApiresponse<IEmployeeHomeWorkerCounterResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IEmployeeHomeWorkerCounterResponse>>(
      `${environment.billingApiUrl}GetEmployeeHomeWorkerCounter`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveEmployeeWorkFromHome(
    requestinfo: IApiRequest<ISaveHomeWorkerListRequest>
  ): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(
      `${environment.billingApiUrl}SaveEmployeeWorkFromHome`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveOfficeWorker(
    requestinfo: IApiRequest<ISaveOfficeWorkerListRequest>
  ): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(
      `${environment.billingApiUrl}SaveOfficeWorker`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateOfficeWorker(
    requestinfo: IApiRequest<ISaveOfficeWorkerListRequest>
  ): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(
      `${environment.billingApiUrl}UpdateEmployeeOfficeWorkerDistance`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloFetchDistance(
    requestinfo: IApiRequest<IFetchMilesRequest>
  ): Observable<IApiresponse<IFetchMilesResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IFetchMilesResponse>>(
      `${environment.billingApiUrl}FetchDistance`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeeAdvVehicleCounter(
    requestinfo: IApiRequest<IEmployeeVehicleCounterRequest>
  ): Observable<IApiresponse<IAdvVehicleTypeCounter>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IAdvVehicleTypeCounter>>(
      `${environment.billingApiUrl}GetAdvEmployeeVehicleCounter`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrgOwnershipType(
    requestinfo: IApiRequest<RequestVehicleTypes>
  ): Observable<IApiresponse<OrgOwnershipTypeResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<OrgOwnershipTypeResponse>>(
      `${environment.billingApiUrl}GetOrgOwnershipType`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAllTaxRates(): Observable<IApiresponse<ITaxRateMasterResponse[]>> {
    let url = `${environment.billingApiUrl}GetAllTaxRatesForExpenseCategory/`;
    return this.http.get<IApiresponse<ITaxRateMasterResponse[]>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  SoloGetMasterVehicles(
    rateType
  ): Observable<IApiresponse<IVehicleTypeMasterInfo[]>> {
    let url = `${environment.catalogApiUrl}GetMasterVehicles/` + rateType + ``;
    return this.http.get<IApiresponse<IVehicleTypeMasterInfo[]>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  SoloSaveUKMileageRate(
    requestinfo: IApiRequest<SaveOwnershiptypeRequest>
  ): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(
      `${environment.billingApiUrl}SaveUKMileageRate`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetOrgUKActiveRateByOrgMapId(
    requestinfo: IApiRequest<IRequestVehicleTypes>
  ): Observable<IApiresponse<IOrgUKRatesByOrgMapIdResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgUKRatesByOrgMapIdResponse>>(
      `${environment.billingApiUrl}GetOrgUKActiveRateByOrgMapId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloValidateEffectiveDate(
    requestinfo: IApiRequest<IValidateEffectiveDateRequest>
  ): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(
      `${environment.billingApiUrl}ValidateEffectiveDate`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloValidateOwnershipDeactive(
    requestinfo: IApiRequest<IValidateOwnershipDeactiveRequest>
  ): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(
      `${environment.billingApiUrl}ValidateOwnershipDeactive`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloDeleteMileageRateSlab(
    requestinfo: IApiRequest<IValidateOwnershipDeactiveRequest>
  ): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(
      `${environment.billingApiUrl}DeleteMileageRateSlab`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetOrganisationDetail() {
    let params = new HttpParams();
    params = params.append("LoginId", this.loginid);
    params = params.append("Userid", this.userId.toString());

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("loginToken"),
      'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
      'Ocp-Apim-Trace': environment.apimTraceEnabled
    });
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}OrganisationDetail`,
      { headers: httpHeaders, params: params }
    );
  }

  SoloGetOrgUKVehicleTypeWithCount(
    requestinfo: IApiRequest<IEmployeeListByVehicleRequest>
  ): Observable<IApiresponse<IOrgUKMileageVehicleTypesWithCounter>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgUKMileageVehicleTypesWithCounter>>(
      `${environment.billingApiUrl}GetOrgUKVehicleTypeWithCount`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetUserByVehicleMapIdList(
    requestinfo: IApiRequest<IEmployeeListByVehicleMapIdRequest>
  ): Observable<IApiresponse<IUserByVehicleList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IUserByVehicleList[]>>(
      `${environment.billingApiUrl}GetUserByVehicleMapIdList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetVehicleReport(
    requestinfo: IApiRequest<IVehicleReportRequest>
  ): Observable<IApiresponse<IVehicleReportResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IVehicleReportResponse>>(
      `${environment.billingApiUrl}GetVehicleReport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetMileageAnalysisReport(requestinfo: IApiRequest<IMileageAnalysisRequest>): Observable<IApiresponse<IMileageAnalysisResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IMileageAnalysisResponse>>(
      `${environment.expenseApiUrl}GetMileageAnalysisReport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckFunctionPurchased(
    functionId: string
  ): Observable<IApiresponse<boolean>> {
    let params = new HttpParams();
    params = params.append("functionId", functionId);
    let url = `${environment.billingApiUrl}CheckFunctionPurchased`;
    return this.http.get<IApiresponse<boolean>>(url, {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }
  SaveCartDetails(
    requestinfo: IApiRequest<ICartDetailsRequest>
  ): Observable<IApiresponse<ICartDetailsResponse>> {
    requestinfo.requestInfo.UserId = this.userId;
    requestinfo.requestInfo.OrganisationId = this.orgId;
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICartDetailsResponse>>(
      `${environment.catalogApiUrl}SaveCartDetails`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloValidateOwnerhipName(
    requestinfo: IApiRequest<ValidateOwnershipNameRequest>
  ): Observable<IApiresponse<any>> {
    requestinfo.requestInfo.userId = this.userId;
    requestinfo.requestInfo.orgId = this.orgId;
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}ValidateOwnerhipName`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloIsExpenseExistForVehicleType(
    requestinfo: IApiRequest<IValidateOwnershipDeactiveRequest>
  ): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(
      `${environment.billingApiUrl}IsExpenseExistForVehicleType`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetOrgAssignMileageRateToGrades(): Observable<IApiresponse<any>> {
    let url = `${environment.billingApiUrl}GetOrgAssignMileageRateToGrades`;
    return this.http.get<IApiresponse<any>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  SoloSaveAssignMileageRateToGrades(
    requestinfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveAssignMileageRateToGradesByGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAssignMileageRateToGradesByGrade(
    requestinfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetOrgAssignMileageRateToGradesByGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeedAssignedToGrade(
    requestinfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetAssignedEmployeeListToGrade`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloAddUpdateEmployeeVehicle(
    requestinfo: IApiRequest<IAddUpdateUserVehicleRequest>,
    claims: Claims
  ): Observable<IApiresponse<any>> {
    this.claims = claims;
    let functionIds = this.fnGetPurchaseFunctionList();
    requestinfo.requestInfo.purchaseFunctionList = functionIds;
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AddUpdateUserVehicle`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetMileageAllowanceList(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetMileageAllowanceList`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAllowanceWizardDetails(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetAllowanceWizardDetails`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  GetWizardDetails(
    requestinfo: IApiRequest<WizardDetailRequest>
  ): Observable<Wizard[]> {
    let body = JSON.stringify(requestinfo);
    return this.http
      .post<IApiresponse<WizardResponse>>(
        `${environment.billingApiUrl}GetWizardDetails`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      )
      .pipe(
        map((result) => {
          return this.mapwizard(result.responseInfo.wizardList);
        })
      );
  }
  GetWizardDetailWithOtherData(
    requestinfo: IApiRequest<WizardDetailRequest>
  ): Observable<WizardResponse> {
    let body = JSON.stringify(requestinfo);
    return this.http
      .post<IApiresponse<WizardResponse>>(
        `${environment.billingApiUrl}GetWizardDetails`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      )
      .pipe(map((result) => {
        return this.mapwizardWIthOtherDetail(result.responseInfo);
      })
      );
  }

  SoloGetVehicleAndExpenseCounter(
    requestinfo: IApiRequest<IReqVehicleExpenseCounter>
  ): Observable<IApiresponse<IRespVehicleExpenseCounter>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespVehicleExpenseCounter>>(
      `${environment.expenseApiUrl}GetVehicleExpenseCounter`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  fnGetPurchaseFunctionList() {
    let request = new ApiRequest<IPurchaseFunctionListRequest>(
      "SoloPurchaseFunctionList",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new PurchaseFunctionListRequest(
      this.claims.UserId,
      this.claims.OrgId
    );
    let functionIds = [];
    this.organisationSettingService
      .soloPurchaseFunctionList(request)
      .subscribe((data: IApiresponse<any>) => {
        if (data.responseInfo.orgPaidFunctionList.length > 0) {
          let purchaseFuncListDetails =
            data.responseInfo.orgPaidFunctionList[0].paidFunctions;
          purchaseFuncListDetails.forEach((element) => {
            functionIds.push(element.functionId);
          });
        }
      });
    return functionIds;
  }

  fnGetMileagerate(vehicleTypes, orgVehicleTypeMapId) {
    let mileageRate = "";
    if (orgVehicleTypeMapId && vehicleTypes) {
      let item = vehicleTypes.find(
        (i) => i.orgVehicleTypeMapId == orgVehicleTypeMapId
      );
      if (item) {
        let mileageArray = item.mileageRateInfo;
        if (mileageArray && mileageArray.length > 1) {
          for (let index = 0; index < mileageArray.length; index++) {
            const element = mileageArray[index];
            if (index == 0) {
              mileageRate = element.mileageRate;
            } else {
              mileageRate = mileageRate + " / " + element.mileageRate;
            }
          }
        }
        if (mileageArray && mileageArray.length == 1) {
          mileageRate = mileageArray[0].mileageRate;
        }
      }
    }
    return mileageRate;
  }
  fnKeyValueReplaceString(keyValueArray: KeyValueArray[], text: string) {
    if (keyValueArray) {
      for (let index = 0; index < keyValueArray.length; index++) {
        let replacer = "##" + index + "##";
        text = text.replace(
          replacer,
          this.translate.instant(keyValueArray[index].value)
        );
      }
      return text;
    }
  }
  mapwizardWIthOtherDetail(wizardData: WizardResponse): WizardResponse {
    const result: WizardResponse = new WizardResponse();
    result.cancelledFuncListDetails = wizardData.cancelledFuncListDetails;
    result.purchasedFunctionList = wizardData.purchasedFunctionList;
    result.mileageValidationDate = wizardData.mileageValidationDate;
    result.wizardList = this.mapwizard(wizardData.wizardList);
    return result;
  }
  mapwizard(wizardData): Wizard[] {
    const result: Wizard[] = [];
    wizardData.forEach((row) => {
      if (row) {
        result.push({
          iconClass: row.iconClass,
          iconPlacement: row.iconPlacement,
          iconTooltip: this.translate.instant(row.iconTooltip),
          isStepMandatory: row.isStepMandatory,
          progressbarMessage: this.fnKeyValueReplaceString(
            row.progressbarMessageArray,
            row.progressbarMessage
          ),
          progressbarMessageArray: row.progressbarMessageArray,
          progressbarType: row.progressbarType,
          progressbarValue: row.progressbarValue,
          stepClass: row.stepClass,
          stepDesc: this.translate.instant(row.stepDesc),
          stepDescMessage: this.fnKeyValueReplaceString(
            row.stepDescMessageArray,
            row.stepDescMessage
          ),
          stepDescMessageArray: row.stepDescMessageArray,
          stepExtraMessage: this.fnKeyValueReplaceString(
            row.stepExtraMessageArray,
            row.stepExtraMessage
          ),
          stepExtraMessageArray: row.stepExtraMessageArray,
          stepName: this.translate.instant(row.stepName),
          stepNum: row.stepNum,
          alertPanelType: row.alertPanelType,
          functionId: row.functionId,
          highlightStep: 0,
          isDisabled: row.isDisabled,
          isInfoIcon: row.isInfoIcon,
          infoIconMsg: row.infoIconMsg,
          stepUrl: row.stepurl,
          isProgressBar:row.isProgressBar,
          disabledClass:row.disabledClass,
          comingSoonDesc:row.comingSoonDesc
        });
      }
    });
    return result;
  }
  marketingPopupPanel: MarketingPopup;
  fnDisplayMarketingPopUp(FunctionId) {
    switch (FunctionId) {
      case FunctionPackageEnum.NonReimbursableFuel:
        this.marketingPopupPanel = new MarketingPopup(
          this.translate.instant("non_reimbursable_fuel"),
          this.translate.instant("markettingpopup_nr_text2"),
          this.translate.instant("markettingpopup_nr_text3"),
          null,
          "../../assets/svg-icons/NR_Fuel.svg",
          "../../assets/images/nr_fc_example.png",
          this.translate.instant("non_reimbursable_dependency_msg"),
          FunctionPackageEnum.NonReimbursableFuel,
          this.translate.instant("non_reimbursable_fuel"),
          "nrfc",
          FunctionPackageEnum.SetupAdvancedMileage,
          FunctionPackageEnum.SetupTaxRate
        );
        break;
      case FunctionPackageEnum.FuelCard:
        this.marketingPopupPanel = new MarketingPopup(
          this.translate.instant("fuel_card"),
          this.translate.instant("markettingpopup_fc_text2"),
          this.translate.instant("markettingpopup_fc_text3"),
          null,
          "../../assets/svg-icons/Fuel_card.svg",
          "../../assets/images/nr_fc_example.png",
          this.translate.instant("fuel_card_dependency_msg"),
          FunctionPackageEnum.FuelCard,
          this.translate.instant("fuel_card"),
          "nrfc",
          FunctionPackageEnum.SetupAdvancedMileage,
          0
        );
        break;
      case FunctionPackageEnum.SetupAdvancedMileage:
        this.marketingPopupPanel = new MarketingPopup(
          this.translate.instant("setup_advanced_mileage"),
          this.translate.instant("mileage_marketing_title"),
          this.translate.instant("mileage_marketing_description"),
          null,
          "../../assets/svg-icons/MileageRatesMarketing.svg",
          "../../assets/images/setupAdvancedMileage.png",
          "",
          FunctionPackageEnum.SetupAdvancedMileage,
          this.translate.instant("setup_advanced_mileage"),
          "orgsetting/mileagewizard",
          0,
          0
        );
        break;
      case FunctionPackageEnum.PassengerLoadMiles:
        this.marketingPopupPanel = new MarketingPopup(
          this.translate.instant("passenger_load_miles"),
          this.translate.instant("passenger_load_miles_marketing_msg1"),
          this.translate.instant("passenger_load_miles_marketing_msg2"),
          null,
          "../../assets/svg-icons/Passenger_Load.svg",
          "../../assets/images/Passenger_Load.png",
          "",
          FunctionPackageEnum.PassengerLoadMiles,
          this.translate.instant("passenger_load_miles"),
          "orgsetting/mileageAllowance",
          FunctionPackageEnum.SetupAdvancedMileage,
          0
        );
        break;
      case FunctionPackageEnum.MultipleVehicle:
        this.marketingPopupPanel = new MarketingPopup(
          this.translate.instant("multiple_vehicle"),
          this.translate.instant("multiple_vehicle_marketing_msg1"),
          this.translate.instant("multiple_vehicle_marketing_msg2"),
          null,
          "../../assets/svg-icons/MultipleVehicleMarketing.svg",
          "../../assets/images/MultipleVehicleMarketing.png",
          "",
          FunctionPackageEnum.MultipleVehicle,
          this.translate.instant("multiple_vehicle"),
          "orgsetting/mileageAllowance",
          FunctionPackageEnum.SetupAdvancedMileage,
          0
        );
        break;
    }
    return this.marketingPopupPanel;
  }
}
