import { Component, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicFormService } from './dynamic-form.service';

@Component({
  selector: "dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements AfterViewInit {

  @Input("model")
  model: any;
  @Input("fields")
  fields: FormlyFieldConfig[];

  options: FormlyFormOptions = {};

  @Output()
  onSubmitForm = new EventEmitter<any>();

  public form = new UntypedFormGroup({});

  initialValues: any;

  constructor(private dynamicFormService: DynamicFormService) {
  }

  ngAfterViewInit() {
    this.initialValues = this.model ? this.model : {};
  }

  onSubmit() {
    this.dynamicFormService.onFormSubmitted(true);
    this.onSubmitForm.emit(this.model);
  }
}
