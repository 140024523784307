import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportChecklistPanelComponent } from '../../../universal-import/import-checklist-panel/import-checklist-panel.component';
import { ImportEmployeeErrorPanelComponent } from '../../../universal-import/import-employees/import-employee-error-panel/import-employee-error-panel.component';
import { GridModule } from '../../../_modules/modules/grid.module';
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DynamicFormsModule } from "../../../_modules/modules/dynamic-forms.module";
import { NotificationPanelComponent } from "../../../shared/panels/notification-panel/notification-panel.component";
import { SharedModule } from 'src/app/shared.module';



@NgModule({
  declarations: [ ImportChecklistPanelComponent,
    ImportEmployeeErrorPanelComponent,
    NotificationPanelComponent],
  imports: [
    TranslateModule,
    FormsModule,
    DynamicFormsModule,
    CommonModule,
    GridModule,
    SharedModule
  ],
  exports:[ ImportChecklistPanelComponent,
    ImportEmployeeErrorPanelComponent,
    NotificationPanelComponent],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class ImportCommonModuleModule { }
