<span [hidden]="!displayAmount" [ngClass]="{ 'color-red': amount && amount < 0}"> {{ amount | number: "1.2-2" | noComma
    }}</span>

<em [ngClass]="buttonClass" [ngbPopover]="htmlContent" triggers="mouseenter:mouseleave" container="body"></em>

<ng-template #htmlContent>
    <div class="popoverCustomWidth">
        <p>
            {{ buttonTooltip | translate }}
        </p>
    </div>
</ng-template>