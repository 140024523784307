import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Claims } from 'src/app/_models/common/claims';
import { IDefaultParams, DefaultParams, ITimeZoneInfo, TimeZoneInfo } from 'src/app/_models/common/request-response/default-params';
import { IDeviceInfo, DeviceInfo } from 'src/app/_models/common/request-response/device-info';
import { environment } from 'src/environments/environment';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { DatePipe } from '@angular/common';
@Injectable({ providedIn: "root" })
export class ClaimsHeaderService {
  currentUser: any;
  logintoken: any;
  claims: Claims;
  token: any;
  orgId: number = 0;
  userId: number = 0;
  organisationCurrency: string;
  defaultParams: IDefaultParams;
  deviceInfo: IDeviceInfo;
  timeZoneInfo: ITimeZoneInfo;
  constructor(private claimsService: ClaimsService,public datepipe: DatePipe) {

  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';     
      case agent.indexOf('trident') > -1:
          return 'ie';
      case agent.indexOf('edg') > -1:
          return 'edge';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';   
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    let userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
  createLoginAuthorizationHeader(): HttpHeaders {    
    this.logintoken = localStorage.getItem("loginToken");
    this.claimsService.currentClaims.subscribe(a => this.claims = a);
    let appVersion = '' + this.claims?.appVersion;
    if (this.claims != undefined && this.claims != null) {
      this.token = this.claims.Token;
      this.orgId = this.claims.OrgId;
      this.userId = this.claims.UserId;
      this.organisationCurrency = this.claims.CurrentOrganisationCurrency;
      this.defaultParams = new DefaultParams(this.orgId, this.userId, this.claims.FinanceManager, Date.now());
      this.deviceInfo = new DeviceInfo(this.organisationCurrency, environment.platform,
        appVersion, environment.appId, environment.deviceId, this.detectBrowserName(), this.detectBrowserVersion());
      this.defaultParams.deviceInfo = this.deviceInfo;
      this.defaultParams.timeZoneInfo = this.fnGetTimeZoneInfo();
    }
    else {
      this.token = this.logintoken;
    }

    var headers: any = {
      "Content-Type": "application/json",
      "DefaultParams": JSON.stringify(this.defaultParams),
      "Authorization": `Bearer ${this.logintoken}`,
      'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
      'Ocp-Apim-Trace': environment.apimTraceEnabled,
    };

    return new HttpHeaders(headers);

  }

  createLoginAuthorizationHeaderWithoutContentTypeHeader(): HttpHeaders {
    this.logintoken = localStorage.getItem("loginToken");
    this.claimsService.currentClaims.subscribe(a => this.claims = a);
    if (this.claims != undefined && this.claims != null) {
      let appVersion = '' + this.claims?.appVersion;
      this.token = this.claims.Token;
      this.orgId = this.claims.OrgId;
      this.userId = this.claims.UserId;
      this.organisationCurrency = this.claims.CurrentOrganisationCurrency;
      this.defaultParams = new DefaultParams(this.orgId, this.userId, this.claims.FinanceManager, Date.now());
      this.deviceInfo = new DeviceInfo(this.organisationCurrency, environment.platform,
        appVersion, environment.appId, environment.deviceId, this.detectBrowserName(), this.detectBrowserVersion());
      this.defaultParams.deviceInfo = this.deviceInfo;
      this.defaultParams.timeZoneInfo = this.fnGetTimeZoneInfo();
    }
    else {
      this.token = this.logintoken;
    }

    var headers: any = {
      "DefaultParams": JSON.stringify(this.defaultParams),
      "Authorization": `Bearer ${this.logintoken}`,
      'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
      'Ocp-Apim-Trace': environment.apimTraceEnabled,
    };

    return new HttpHeaders(headers);

  }  
  fnGetTimeZoneInfo()
  {
    this.timeZoneInfo = new TimeZoneInfo();
    this.timeZoneInfo.localDateString = this.datepipe.transform(this.getUTCTimeStamp(new Date()), environment.dateFormat);
    this.timeZoneInfo.timeZoneOffset = this.fnClientTimeZoneOffset();
    return this.timeZoneInfo;
  }
  fnClientTimeZoneOffset() {
    const dt = new Date();
    return dt.getTimezoneOffset();
}
  getUTCTimeStamp(date:Date) {
    let now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );

    return now_utc;
}

}
