import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-disabled-input',
  templateUrl: "./formly-disabled-input.component.html"
})
export class FormlyDisabledInputComponent extends FieldType {

  fieldKey: string;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
  }
}