import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IApiresponse } from "src/app/_models";
import { Claims } from "src/app/_models/common/claims";
import { ICountryCurrencyMappingResponse } from "src/app/_models/country-currency-mapping/country-currency-mapping";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { CountryCurrencyMappingService } from "src/app/_services/country-currency-mapping/country-currency-mapping.service";

@Component({
  selector: "app-organisation-details",
  templateUrl: "./organisation-details.component.html",
  styleUrls: ["./organisation-details.component.scss"],
})
export class OrganisationDetailsComponent implements OnInit, AfterViewInit {
  @Output() gotoNextStepEvent = new EventEmitter<any>();

  claims: Claims;
  countryList: ICountryCurrencyMappingResponse[] = [];

  orgName = "";
  assignedCountry: any;

  selectedCountryId: any;
  submitted = false;

  constructor(
    private claimsService: ClaimsService,
    private countryCurrencyMappingService: CountryCurrencyMappingService
  ) {
    this.claimsService.currentClaims.subscribe(
      (claims) => (this.claims = claims)
    );
  }

  ngOnInit(): void {
    this.getIpCliente();
  }

  onCountryChange() {
    this.assignedCountry = this.countryList.filter(
      (x) => x.countryId == this.selectedCountryId
    )[0];
  }

  onSubmit() {
    this.submitted = true;

    if (
      this.orgName.trim() === "" ||
      !this.assignedCountry ||
      this.selectedCountryId == 0
    ) {
      return;
    }

    this.claims.SelectedCountry = this.assignedCountry.countryId;
    this.claims.SelectedCountryName = this.assignedCountry.countryName;
    this.claims.SelectedCurrency = this.assignedCountry.currencyId;
    this.claims.SelectedCurrencyCode = this.assignedCountry.currencyCode;
    this.claims.SelectedCurrencyName = this.assignedCountry.currencyName;
    this.claims.CurrentOrganisationCurrency = this.assignedCountry.currencyCode;
    var currencyCode = this.claims.CurrentOrganisationCurrency;
    this.claims.CurrentOrganisationCurrencyCode = currencyCode;
    this.claims.OrgName = this.orgName.trim();
    this.claims.CurrencyCode = this.assignedCountry.currencyCode;
    this.claims.CurrencyIcon = this.assignedCountry.currencyIcon;
    this.claims.UserHomeCurrencyId = this.assignedCountry.currencyId;
    this.claims.UserHomeCurrencyCode = this.assignedCountry.currencyCode;
    this.claims.UserHomeCurrencyName = this.assignedCountry.currencyName;
    this.gotoNextStepEvent.emit();
  }

  gotoNextStep() {
    this.gotoNextStepEvent.emit();
  }

  async getIpCliente() {
    try {
      const response = await fetch(
        "https://api.ipapi.is/?key=02107353329c92f8"
      );
      const jsonData = await response.json();
      localStorage.setItem("countryName", jsonData.location.country);
      this.fnGetCountryCurrencyMapping();
    } catch (error) {
      this.fnGetCountryCurrencyMapping();
    }

  }

  fnGetCountryCurrencyMapping() {
    this.countryCurrencyMappingService
      .GetCountryCurrencyMapping()
      .subscribe(
        (response: IApiresponse<ICountryCurrencyMappingResponse[]>) => {
          if (response.responseInfo != null) {
            this.countryList = response.responseInfo;

            if (localStorage.getItem("countryName")) {
              var countryName = localStorage.getItem("countryName");

              let countryDetails = this.countryList.find(
                (c) => c.countryName == countryName
              );
              if (countryDetails) {
                this.assignedCountry = countryDetails;
                this.selectedCountryId = this.assignedCountry.countryId;
              } else {
                let defaultCurrencyDetails = this.countryList.find(
                  (c) => c.countryName == "United Kingdom"
                );
                if (defaultCurrencyDetails) {
                  this.assignedCountry = defaultCurrencyDetails;
                  this.selectedCountryId = this.assignedCountry.countryId;
                }
              }
            } else {
              let defaultCurrencyDetails = this.countryList.find(
                (c) => c.countryName == "United Kingdom"
              );
              if (defaultCurrencyDetails) {
                this.assignedCountry = defaultCurrencyDetails;
                this.selectedCountryId = this.assignedCountry.countryId;
              }
            }
          }
        }
      );
  }



  @ViewChild("orgNameField") orgNameField: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    this.orgNameField.nativeElement.focus();
  }
}
