import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiRequest, IApiresponse } from '../../_models';
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { environment } from "../../../environments/environment";
import { IReqSaveCategoryCounterApproverSettings, IReqSaveCounterApproverDetails, IReqSaveCounterApproverRules, IRespApproverCADetails, IRespCategoryCounterApproverSettings, IRespCounterApproverAppDetails, IRespDeletedApproverCADetails, IResponseCounterApproverRules, IRespReplacedCounterApproverDetails } from '../../_models/counter-approver/counter-approver.model';
@Injectable({
  providedIn: 'root'
})
export class CounterApproverService {

  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService,) { }

  GetApproverCounterApproverDetails(): Observable<IApiresponse<IRespApproverCADetails>> {
    return this.http.get<IApiresponse<IRespApproverCADetails>>(
      `${environment.billingApiUrl}GetApproverCounterApproverDetails`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetDeletedCounterApproverDetails(): Observable<IApiresponse<IRespDeletedApproverCADetails>> {
    return this.http.get<IApiresponse<IRespDeletedApproverCADetails>>(
      `${environment.billingApiUrl}GetDeletedCounterApproverDetails`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetReplacedCounterApproverDetails(): Observable<IApiresponse<IRespReplacedCounterApproverDetails>> {
    return this.http.get<IApiresponse<IRespReplacedCounterApproverDetails>>(
      `${environment.billingApiUrl}GetReplacedCounterApproverDetails`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCounterApproverByApprover(): Observable<IApiresponse<IRespCounterApproverAppDetails>> {
    return this.http.get<IApiresponse<IRespCounterApproverAppDetails>>(
      `${environment.billingApiUrl}GetCounterApproverByApprover`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCounterApproverDetails(requestinfo: IApiRequest<IReqSaveCounterApproverDetails>): Observable<IApiresponse<boolean>>{
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}SaveCounterApproverDetails`,body, 
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCounterAppImportSampleData() {
    return this.http.get('/assets/sample-data/import/import-counter-approver.json');
  }

  GetCategoryCounterApproverSettings(): Observable<IApiresponse<IRespCategoryCounterApproverSettings>> {
    return this.http.get<IApiresponse<IRespCategoryCounterApproverSettings>>(
      `${environment.billingApiUrl}GetCategoryCounterApproverSettings`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCategoryCounterApproverSettings(requestinfo: IApiRequest<IReqSaveCategoryCounterApproverSettings>): Observable<IApiresponse<boolean>>{
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}SaveCategoryCounterApproverSettings`,body, 
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCounterApproverRules(): Observable<IApiresponse<IResponseCounterApproverRules>> {
    return this.http.get<IApiresponse<IResponseCounterApproverRules>>(
      `${environment.billingApiUrl}GetCounterApproverRules`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCounterApproverRules(requestinfo: IApiRequest<IReqSaveCounterApproverRules>): Observable<IApiresponse<boolean>>{
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}SaveCounterApproverRules`,body, 
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

}