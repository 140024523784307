<div class="input-group border-bottom padding-bottom-10"
      [ngClass]="{requiredAsterisk : field.templateOptions.required,'disabled':to.disabled}">
  <p class="f-11 fw-600" style="color:#686868;">{{field.templateOptions.label}}</p>
    <p class="w-100pc mt-2 d-flex justify-content-between" *ngFor="let option of to.options" [ngClass]="option.class">
      <span class="w-50pc d-flex justify-content-lg-start align-items-center "><input type="radio" [name]="to.name" [formControl]="formControl" [formlyAttributes]="field" [value]="option.id"
        class="margin-right-10 align-middle svg-18 cursor-pointer">
      <span *ngIf="option?.value" [innerHTML]="sanitizerHtml(option.value)">
      </span></span>      
  </p>
  <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
    <span> <strong>{{ "label_Required" | translate: param }}</strong></span>
  </div>
</div>