import { Injectable } from "@angular/core";
import { saveAs } from 'file-saver';
const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {
    constructor() { }

    public isValidCSVFile(file: any) {
        return file.name.endsWith(".csv");
    }

    public getHeaderArrayFromCSV(csvRecordsArr: any) {
        let headers = (<string>csvRecordsArr[0]).split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    public exportAsExcelFile(data: any[], excelFileName: string, header?: any[]): void {
        import("xlsx").then(XLSX => {
            const worksheet = XLSX.utils.json_to_sheet([]);
            if (header) {
                XLSX.utils.sheet_add_aoa(worksheet, header);

                //Starting in the second row to avoid overriding and skipping headers
                XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
            }
            else {
                XLSX.utils.sheet_add_json(worksheet, data);
            }
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"]
            };
            const excelBuffer: any = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array"
            });
            this.saveAsExcelFile(excelBuffer, excelFileName);
        });
    }
    
    private saveAsExcelFile(buffer: any, fileName: string): void {
        var FileSaver = require('file-saver');
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

    public exportToCsv(filename, rows) {
        const processRow = function (row) {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        const nav = (window.navigator as any);
        if (nav.msSaveBlob) { // IE 10+
            nav.msSaveBlob(blob, filename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    public convertStringToBase64TextCsv(str: string) {
      let base64 = btoa(str.replace(/[\u00A0-\u2666]/g, function(c) {
            return '&#' + c.charCodeAt(0) + ';';
        }));

      return "data:text/csv;base64,"  + base64;
    }

}
