import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'app-text-tooltip-renderer',
    templateUrl: './text-tooltip-renderer.component.html',
})
export class TextWithTooltipRendererComponent implements ICellRendererAngularComp {
    text: string;
    toolTipText: string;
    constructor(private sanitizer: DomSanitizer) {

    }

    initilize(params: ICellRendererParams) {
        let data = params.getValue();

        if (data) {
            this.text = data.text;
            this.toolTipText = data.tooltip;
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    sanitizerHtml(element) {
      return this.sanitizer.bypassSecurityTrustHtml(element);
    }

}
