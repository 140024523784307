import { IDeviceInfo, DeviceInfo } from "./device-info";

export interface IDefaultParams {
    orgId: number;
    userId: number;
    requestTimeStamp: number;
    deviceInfo: IDeviceInfo;
    timeZoneInfo: ITimeZoneInfo;

}

export class DefaultParams implements IDefaultParams {
    orgId: number;
    userId: number;
    userName: string;
    requestTimeStamp: number;
    deviceInfo: DeviceInfo;
    timeZoneInfo: TimeZoneInfo;

    constructor(orgId: number,
        userId: number,
        userName: string,
        requestTimeStamp: number) {
        this.orgId = orgId;
        this.userId = userId;
        this.userName = userName;
        this.requestTimeStamp = requestTimeStamp;
    }
}
export interface ITimeZoneInfo {
    timeZoneOffset: number;
    localDateString: string;
}
export class TimeZoneInfo implements ITimeZoneInfo {
    timeZoneOffset: number;
    localDateString: string;
}