<div class="input-group">
    <input id="{{ 'int' + fieldKey }}" threeDigitBeforeAndTwoDigitAfterDecimalNumber type="text" class="form-control"
        (paste)="$event.preventDefault()" (input)="onNumberInput()" [formControl]="formControl"
        [formlyAttributes]="field"
        [ngClass]="{'has-value': formControl.value || formControl.value==0, 'text-logo-blue': !to.disabled, 'color-light-grey-disabledType':to.disabled}">
    <label for="IntegerInput">{{field.templateOptions.label}}<span *ngIf="field.templateOptions.required">*</span>
    </label>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>

</div>
