import { Component, OnInit, Output, EventEmitter, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { DateFormatPipe } from "src/app/_helpers/pipe.module";
import { IApiRequest,ApiRequest, IApiresponse } from "src/app/_models";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { termsOfServiceURl } from "src/app/common/commonvariables";
import { StripeSessionRequest, IStripeSessionRequest } from "src/app/_models/stripe/stripe";
declare var Stripe: any;
import { parse } from "url";
import { StripeService } from "src/app/_services/stripe/stripe.service";
import { ISubscriptionCardDetailsRequest, SubscriptionCardDetailsRequest } from "src/app/_models/stripe/SubscriptionCardDetailsRequest";
import { SaveOrderCartDetailsService } from "src/app/_services/cart-order/SaveOrder-Cart-Details.service";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";

@Component({
  selector: 'stripe-agreement-panel',
  templateUrl: './stripe-agreement-panel.component.html'
})

export class StripeAgreementPanelComponent implements OnInit  {
  @Output() closePanelEvent = new EventEmitter<any>();

  claims: Claims;
  termsOfService = termsOfServiceURl;
  isDisabled: boolean = true;
  stripeSessionId: any;
  stripeSessionIdFromUrl: string;
  showRedirectText: boolean = false;
  basicFunctionTrialDays: number;

  constructor(
    private claimsService: ClaimsService,
    private location: Location,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DateFormatPipe,
    private translate: TranslateService,
    private elRef: ElementRef,
    private stripeService: StripeService,
    private saveOrderCartDetailsService: SaveOrderCartDetailsService,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService,
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {

   if (this.route.snapshot.queryParams["sessionId"]) {

        this.stripeSessionIdFromUrl = this.route.snapshot.queryParams["sessionId"];
        this.fnResetUrl();
        this.fnRegisterUserCardOnStripe();
    }
    else{
        this.fnLoadStripe();
        this.fnGetOrgBasicFunctionSubscriptionTrialDetails();
    }
  }

   fnClose(){
    this.closePanelEvent.emit(true);
   }  
   
   fnAgreeOnTerms(values: any) {
    this.isDisabled = values.currentTarget.checked == false ? true : false;
  }

  fnShowStripeCheckout() {
    this.fnLoadStripeSession();
  }

  fnLoadStripe() {
    let stripeImport = document.createElement("script");
    stripeImport.type = "text/javascript";
    stripeImport.src = "https://js.stripe.com/v3/";
    this.elRef.nativeElement.appendChild(stripeImport);
  }

  fnResetUrl() {
    this.showRedirectText = true;
    let myUrl = parse(window.location.href);

    if (!(!myUrl || null) && !(!myUrl.pathname || null)) {
      let urlPath = myUrl.pathname;
      this.location.go(urlPath);
    } else {
      this.location.go("/");
    }
  }

  fnLoadStripeSession() {
    let userEmail = this.claims.EmailId && this.claims.EmailId != "" ? this.claims.EmailId : this.claims.LoginId;
    let sessionRequestData = new StripeSessionRequest(window.location.href,userEmail,this.claims.CurrentOrganisationCurrencyCode);
    let sessionRequest = new ApiRequest<IStripeSessionRequest>("CreateStripeSession",environment.applicationVersion, environment.requestId);
    sessionRequest.requestInfo = sessionRequestData;

    this.stripeService.CreateStripeSession(sessionRequest)
      .subscribe(
        (sessionData) => {
          if (sessionData.statusCode == 0 && sessionData.responseInfo) {
            this.stripeSessionId = sessionData.responseInfo;
            this.fnShowStripeCheckoutWindow();
          }
        },
        (error) => {
          alert(error);
        }
      );
  }

  fnShowStripeCheckoutWindow() {
    let stripe = Stripe(environment.StripePublicKey);

    stripe.redirectToCheckout({ sessionId: this.stripeSessionId }).then(function (result) {
        this.toastr.error(result.error.message);
      });
  }

  fnRegisterUserCardOnStripe() {
    this.stripeService.RegisterUserCardOnStripe(this.stripeSessionIdFromUrl)
    .subscribe((registerCardData) => {

          if (registerCardData.statusCode == 0 && registerCardData.responseInfo) {

            this.fnSaveOrganisationCardInformation(
              registerCardData.responseInfo.customerId,
              registerCardData.responseInfo.paymentMethodId,
              registerCardData.responseInfo.cardBrand,
              registerCardData.responseInfo.cardType,
              registerCardData.responseInfo.cardExpirationMonth,
              registerCardData.responseInfo.cardExpirationYear,
              registerCardData.responseInfo.cardLastFourDigits
            );
            
          } else {
            this.toastr.error(this.translate.instant("failed"));
          }
        },
        (error) => {
          alert(error);
        }
      );
  }

  fnSaveOrganisationCardInformation(
     customerId: string,
     paymentMethodId: string, 
     cardBrand: string, 
     cardType: string, 
     cardExpirationMonth: string, 
     cardExpirationYear: string,
     cardLastFourDigits: string) {

    let cardMonth = "";
    if (cardExpirationMonth != null && cardExpirationMonth != "" && cardExpirationMonth != undefined) {
      cardMonth = parseInt(cardExpirationMonth).toLocaleString("en-US", {minimumIntegerDigits: 2, useGrouping: false});
    }
    let request = new ApiRequest<ISubscriptionCardDetailsRequest>("AddCardDetails", environment.applicationVersion, environment.requestId);
    let requestInfo = new SubscriptionCardDetailsRequest(
        cardBrand,
        cardType,
        cardMonth + "/" + cardExpirationYear,
        cardLastFourDigits,
        cardBrand != null ? "assets/svg-icons/" + cardBrand.toLowerCase() + ".svg" : "",
        this.claims.OrgId,
        this.claims.UserId,
        "1",
        customerId,
        paymentMethodId);

    request.requestInfo = requestInfo;

    this.saveOrderCartDetailsService.AddCardDetails(request)
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {

              if (localStorage.getItem("IsFromBasicFunctionSubscription")) {
                localStorage.removeItem("IsFromBasicFunctionSubscription");
                localStorage.setItem("IsFromBasicFunctionSubscriptionStrip",JSON.stringify(true));
                this.router.navigate(["/settings"]);
              } 
              else {
                this.router.navigate(["/shoppingcartConfirmed"]);
              }

          } else {
            this.toastr.error(data.errorInfo[0].message);
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }

  fnGetOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService.GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
      .subscribe((res: any) => {
        if (res.responseInfo != null) {
          this.basicFunctionTrialDays = res.responseInfo.basicFunctionTrialDays;
        }
      });
  }

}
