import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import {  Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { CountByStatus, GridConfig } from "src/app/_models/common/grid/grid-config";
import { ExcelExportService } from "src/app/_services/common/excel-export.service";
import { UserTypeEnum } from "src/app/_models/common/manage-emp-enum.enum";

@Component({
    selector: "import-employee-error-panel",
    templateUrl: "./import-employee-error-panel.component.html"
})

export class ImportEmployeeErrorPanelComponent implements OnInit {
    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

    @Input() employeeErrorList: any[] = [];

    @Output() closePanelEvent = new EventEmitter<boolean>();

    param: any;
    claims: Claims;

     //ag-grid definitions
     gridColumnDefs: ColDef[];
     gridConfigItems: GridConfig;
     gridData: any[] = [];

    constructor(
        private toastr: ToastrService,
        private claimsService: ClaimsService,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
        private excelExportService: ExcelExportService
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
    }

    ngOnInit() {
        this.fnInitialiseGrid();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

    fnInitialiseGrid() {
        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.initializeGrid();
        }, 100);
    }

    fnCreateColumnDefs() {
        this.gridColumnDefs = [
            {
                headerName: this.translate.instant("label_employee_code"),
                field: 'EmployeeCode',
                suppressMenu: true,
                minWidth: 50,
                width:50
            },
            {
                headerName: this.translate.instant("first_name"),
                field: 'FirstName',
                suppressMenu: true,
                minWidth: 80,
                width:80
            },
            {
                headerName: this.translate.instant("last_name"),
                field: 'LastName',
                suppressMenu: true,
                minWidth: 80,
                width:80
            },
            {
                headerName: this.translate.instant("approver_email"),
                field: 'ApproverEmail',
                suppressMenu: true,
                minWidth: 100,
                width:100
            },
            {
                headerName: this.translate.instant("label_errors"),
                field: 'FailedErrors',
                cellClass: 'color-amber',
                cellStyle: { 'font-weight': 'bold' },
                suppressMenu: true,
                minWidth: 300
            },
            {
                headerName: this.translate.instant("label_warnings"),
                field: 'FailedWarnings',
                cellClass: 'text-mustard',
                cellStyle: { 'font-weight': 'bold' },
                suppressMenu: true,
                minWidth: 300
            }
        ];
    
    }

    fnGetGridConfigItems() {
        let totalEmployeeCount = this.employeeErrorList.length;
        let countByStatusArray = [];

        countByStatusArray.push(new CountByStatus("d-block", this.translate.instant('label_employee_count'), totalEmployeeCount, false, 0));

        this.gridConfigItems = new GridConfig(
            false,
            false,
            "EmployeeId",
            "UniqueRowId",
            0,
            false,
            countByStatusArray,
            false,
            false,
            true,
            null,
            'asc',
            40,
            this.translate.instant('no_data_availabe'),
            null,
            null,
            false, 
            false,
            '',
            '',
            false
        );

        this.gridData = this.employeeErrorList;

        setTimeout(() => {
            this.gridComponent.refreshGridData();
        }, 100);
    }

    fnOnExportClick() {
        let exportData = [];

        let headerRow = [
            this.translate.instant('label_errors'),
            this.translate.instant('label_warnings'),
            this.translate.instant('employee_id1'),
            this.translate.instant('employee_id2'),
            this.translate.instant('employee_id3'),
            this.translate.instant('employee_id4'),
            this.translate.instant('label_first_name'),
            this.translate.instant('label_last_name'),
            this.translate.instant('label_email'),
            this.translate.instant('label_role'),
            this.translate.instant('approver_email')
        ];
        if (this.claims.IsCostCentrePackagePurchasedOrTrial) {
            headerRow.push( this.translate.instant('cost_centre_name'));
            headerRow.push( this.translate.instant('cost_centre_code'));
        }

        exportData.push(headerRow);

        if(this.employeeErrorList && this.employeeErrorList.length > 0){
            this.employeeErrorList.forEach(data => {
                let row = [];
                row.push(data.FailedErrors ? data.FailedErrors: "");
                row.push(data.FailedWarnings ? data.FailedWarnings: "");
                row.push(data.EmployeeCode ? data.EmployeeCode:"");
                row.push(data.EmployeeCode2 ? data.EmployeeCode2: "");
                row.push(data.EmployeeCode3 ? data.EmployeeCode3:"");
                row.push(data.EmployeeCode4 ? data.EmployeeCode4:"");
                row.push(data.FirstName ? data.FirstName:"");
                row.push(data.LastName ? data.LastName:"");
                row.push(data.Email? data.Email: "");
                if (Number(data.EmployeeRole) == UserTypeEnum.Approver || Number(data.EmployeeRole) == UserTypeEnum.FinanceManager){
                    row.push("A");
                }
                else {
                    row.push("");
                }
                row.push(data.ApproverEmail);
    
               if (this.claims.IsCostCentrePackagePurchasedOrTrial) {
                row.push(data.CostCentreName ? data.CostCentreName: "");
                row.push(data.CostCentreCode1 ? data.CostCentreCode1: "");
                }
                exportData.push(row); 
            
            });
        }
        this.excelExportService.exportToCsv("EmployeeErrorExport.csv", exportData);
    }

}
