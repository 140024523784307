import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { BuildDetailsService } from './build-details.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BuildDetails } from './build-details';

@Injectable({ providedIn: 'root' })
export class UpToDateBuildService {

    public buildNumberAtStartup: string;

    constructor(buildDetailsService: BuildDetailsService, private httpClient: HttpClient) {
        this.buildNumberAtStartup = buildDetailsService.buildDetails.buildNumber;
    }

    public pollForBuildNumber(): Observable<any>  {

        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache'
            })
        };
        return this.httpClient.get<BuildDetails>('build-details.json', httpOptions)
    }
}