export class GridConfig {
    constructor(
        public hideFilters: boolean,
        public isInsidePanel: boolean,
        public defaultSortColumn: string,
        public rowKey: string,
        public statusValue: number,
        public enableRowSelection: boolean,
        public countByStatus: CountByStatus[],
        public suppressCellSelection: boolean,
        public suppressRowClickSelection: boolean,
        public isCheckAllAllowed: boolean,
        public gridTitle: string,
        public defaultSortOrder: string,
        public rowHeight: number,
        public noDataMessage: string,
        public headerHeight: number = 0,
        public rowClassRules: any,
        public isHoverRowColumnHighlight: boolean,
        public suppressRowHoverHighlight: boolean,
        public searchBarClass: any,
        public gridWrapperClass: string,
        public columnHoverHighlight: boolean,
        public gridHeaderMessage:string = "",
        public dataFilterValue: number = 0,
        public dataFilterTitle: string = "",
    ) {
    }

    public gridTitleClass: string = '';
}

export class CountByStatus {
    constructor(
        public className: string,
        public label: string,
        public count: number,
        public isClickEvent: boolean,
        public value: number        
    ) {        
    }
}
