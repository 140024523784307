<div class="block">
    <div class="row">
        <p class="text-center w-100pc">
            <span class="color-red padding-left-15 padding-top-10" *ngIf="model?.isFreeCallsMsgRequired">
                {{"you_have_exhausted_your_free_500_call" | translate}}
            </span>
            <br />
            {{"the_google_mileage_and_gps_uses_a_chargeable_service" | translate}}
            <br />
            {{"please_click_on_purhcase_for_this_service" | translate}}
            <br />
        </p>
        <div class="w-100pc margin-top-30 text-center">
            <button (click)="fnGoToPurchaseGooglePackage()" class="btn btn-primary btn-rounded-4">
                {{"label_purchase" | translate}}
            </button>
        </div>
    </div>
</div>