  <div class="block overflow-auto heightCalc-5 scrollbar-visible ">
        <div class="row">
          <ul class="bulkList">
            <li class=" f-14 border-bottom padding-bottom-10" 
            [ngClass]="{ 'active': item ==  formControl.value}"
            (click)="fnSetDateFormat(item);"
                *ngFor="let item of dateFormats">
                <span class="d-inline-block">
                    {{item}}
                </span>
            </li>
          </ul>
        </div>
      </div>

     