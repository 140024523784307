<div class="col-lg-12 px-0 mt-3">
    <div class="scrollbar-visible heightCalc-17 overflow-auto">
        <div class="row" id="taxRateSetup-expiredSlab">
            <div class="col-md-12 col-sm-12 no-padding-right-left ">
                <div id="accordion">
                    <div *ngIf="expenseTimeLimitVisible" class="card margin-top-0">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'expenseTimeLimitVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseMoreThan75">{{ "expense_time_limit" | translate: param }}
                                {{ "violation" | translate: param }}<span><small class="color-red"></small></span>
                            </a> </div>
                        <div id="collapseMoreThan75" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'expenseTimeLimitVisible'}">
                            <div class="card-body padding-10">
                                <div class="col-md-12 no-padding-right-left margin-top-0">
                                    <div class="row ">
                                        <h6 class="d-block fw-600 w-100pc color-red"> {{ "more_than" | translate:
                                            param }}
                                            {{
                                            getPolicyResponse.policyViolation
                                            .expenseTimeLimit.expenseTimeLimit
                                            }}
                                            {{ "shopping_cart_label_days" | translate: param }}</h6>

                                        <div class="float-left mt-2 w-100pc">
                                            <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' | translate:
                                                param }}:</h6>
                                            <p class="f-13 lH-normal wordbreak">{{
                                                this.getPolicyResponse.policyViolation
                                                .expenseTimeLimit.notes}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="vatNoVatVisible" class="card margin-top-10">
                        <div class="card-header card-header-claimant-xx"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'vatNoVatVisible'}">
                            <a class="card-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="false">
                                {{ "novat_receipt_type" | translate: param }}
                            </a>
                        </div>
                        <div id="collapseOne" class="in collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'vatNoVatVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp ">
                                                        <li class="border-bottom-0">
                                                            {{ "novat_receipt_type" | translate: param }}
                                                            <span class="position-absolute right-10">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation
                                                                .vatNovatReceiptType
                                                                .vatNoVatReceiptTypeName
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="duplicateVisible">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'duplicateVisible'}">
                            <a class="card-link" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                {{'duplicate' | translate:param}} <span><small class="color-red">({{'other_items' |
                                        translate:param}})</small></span>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'duplicateVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li
                                                            *ngFor="let item of this.getPolicyResponse.policyViolation.duplicate">
                                                            {{ inputData.expenseCategoryName }} {{
                                                            item.expenseDate | date: "dd-MMM-yyyy" }}
                                                            <span class="position-absolute right-10">
                                                                {{ item.expenseAmount | number: ".2" | noComma
                                                                }}
                                                                {{getCurrencyName(item.currencyId)}}
                                                            </span>
                                                            <a *ngIf="(inputData.baseCategoryId == 4 ||
                                                                inputData.baseCategoryId == 5 ||
                                                                inputData.baseCategoryId == 6 ||
                                                                inputData.baseCategoryId == 8) &&
                                                                item.receiptInfo.length > 0"
                                                                (click)="fnShowReceiptsPanel(item.receiptInfo)"
                                                                class="d-block f-12 text-logo-blue margin-top-5">
                                                                <span>
                                                                    <i class="fas fa-file-alt text-logo-blue"
                                                                        aria-hidden="true"></i>
                                                                </span>
                                                                {{'label_view_receipt' | translate:param}} <span
                                                                    class="color-black"
                                                                    *ngIf="isReceiptNumberSettingsEnabled">| {{
                                                                    'receipt_number' | translate : param }} : {{
                                                                    item.receiptNumber }} </span>
                                                            </a>
                                                            <a *ngIf="inputData.baseCategoryId == 2 ||
                                                                inputData.baseCategoryId == 3" (click)="showMap()"
                                                                    class="d-block f-12 text-logo-blue margin-top-5">
                                                                    <span>
                                                                        <i class="fas fa-file-alt text-logo-blue"
                                                                            aria-hidden="true"></i>
                                                                    </span>
                                                                    View Map
                                                                </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="overallVisible" class="card">
                        <div class="card-header "
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'overallVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseThreeOverall">
                                {{
                                "overall_daily_limit_violation" | translate: param
                                }}
                                <span>
                                    <small class="color-red">
                                        ({{ "overspent_by" | translate: param }}
                                        {{
                                        getCurrencyName(getPolicyResponse.policyViolation.overallDailyLimit.currencyId)
                                        }}
                                        {{
                                        getPolicyResponse.policyViolation
                                        .overallDailyLimit.violation
                                        | number: ".2"
                                        | noComma
                                        }})
                                    </small>
                                </span>
                            </a>
                        </div>

                        <div id="collapseThreeOverall" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'overallVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span class="float-left w-40pc d-inline-block">
                                                                {{
                                                                "daily_limit" | translate: param
                                                                }}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse.policyViolation
                                                                .overallDailyLimit.limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.overallDailyLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="float-left w-40pc d-inline-block">
                                                                {{
                                                                "total_amount_spent_asondate"
                                                                | translate: param
                                                                }}{{ this.getPolicyResponse.policyViolation
                                                                .overallDailyLimit.violationDate | date :
                                                                'dd-MMM-yyyy'}}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse.policyViolation
                                                                .overallDailyLimit.spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.overallDailyLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                class="float-left w-40pc d-inline-block color-red">
                                                                {{
                                                                "violation_on"
                                                                | translate: param
                                                                }}{{ this.getPolicyResponse.policyViolation
                                                                .overallDailyLimit.violationDate | date :
                                                                'dd-MMM-yyyy'}}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse.policyViolation
                                                                .overallDailyLimit.violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.overallDailyLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .overallDailyLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="dailyCapVisible" class="card">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'dailyCapVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree">
                                {{'expense_capping_daily_limit_violation' | translate:param}} <span><small
                                        class="color-red">
                                        ({{ "overspent_by" | translate: param }}
                                        {{
                                        getCurrencyName(getPolicyResponse.policyViolation.dailyCappingLimit.currencyId)
                                        }}
                                        {{
                                        getPolicyResponse.policyViolation
                                        .dailyCappingLimit.violation
                                        | number: ".2"
                                        | noComma
                                        }})</small></span> </a>
                        </div>
                        <div id="collapseThree" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'dailyCapVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span class="float-left w-40pc d-inline-block">{{
                                                                "daily_capping_limit" | translate: param
                                                                }}</span> <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.dailyCappingLimit
                                                                .limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.dailyCappingLimit.currencyId)
                                                                }}</span></li>
                                                        <li><span
                                                                class="float-left w-40pc d-inline-block">{{'total_amount_spent_asondate'
                                                                | translate:param}} {{ this.getPolicyResponse
                                                                .policyViolation.dailyCappingLimit
                                                                .violationDate | date :'dd-MMM-yyyy' }}</span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.dailyCappingLimit
                                                                .spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.dailyCappingLimit.currencyId)
                                                                }}</span>
                                                        </li>
                                                        <li><span
                                                                class="float-left w-40pc d-inline-block color-red">{{
                                                                "total_violation_for" | translate: param
                                                                }} {{ this.getPolicyResponse
                                                                .policyViolation.dailyCappingLimit
                                                                .violationDate | date :'dd-MMM-yyyy' }}</span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.dailyCappingLimit
                                                                .violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.dailyCappingLimit.currencyId)
                                                                }}</span>
                                                        </li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .dailyCappingLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="monthlyCapVisible" class="card">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'monthlyCapVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                {{ "expense_monthly_limit_violation" | translate: param }}
                                <span>
                                    <small class="color-red">
                                        ({{ "overspent_by" | translate: param }}
                                        {{
                                        getCurrencyName(getPolicyResponse.policyViolation.monthlyCappingLimit.currencyId)
                                        }}
                                        {{
                                        this.getPolicyResponse.policyViolation
                                        .monthlyCappingLimit.violation
                                        | number: ".2"
                                        | noComma
                                        }})
                                    </small>
                                </span>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'monthlyCapVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span class="float-left w-40pc d-inline-block">
                                                                {{
                                                                "monthly_capping_limit" | translate: param
                                                                }}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.monthlyCappingLimit
                                                                .limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.monthlyCappingLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="float-left w-40pc d-inline-block">
                                                                {{
                                                                "total_violation_spent_during"
                                                                | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.monthlyCappingLimit
                                                                .violationDate | date : 'MMM-yyyy'}}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.monthlyCappingLimit
                                                                .spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.monthlyCappingLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                class="float-left w-40pc d-inline-block color-red">
                                                                {{
                                                                "total_violation_during" | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.monthlyCappingLimit
                                                                .violationDate | date : 'MMM-yyyy'}}
                                                            </span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.monthlyCappingLimit
                                                                .violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.monthlyCappingLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .monthlyCappingLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="yearlyCapVisible" class="card">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'yearlyCapVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                                {{ "expense_yearly_limit_violation" | translate: param }}
                                <span>
                                    <small class="color-red">
                                        ({{ "overspent_by" | translate: param }}
                                        {{
                                        getCurrencyName(getPolicyResponse.policyViolation.yearlyCappingLimit.currencyId)
                                        }}
                                        {{
                                        this.getPolicyResponse.policyViolation
                                        .yearlyCappingLimit.violation
                                        | number: ".2"
                                        | noComma
                                        }})
                                    </small></span> </a>
                        </div>
                        <div id="collapseFive" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'yearlyCapVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span class="float-left w-40pc d-inline-block">{{
                                                                "yearly_capping_limit" | translate: param
                                                                }}</span> <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.yearlyCappingLimit
                                                                .limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.yearlyCappingLimit.currencyId)
                                                                }}
                                                            </span></li>
                                                        <li><span class="float-left w-40pc d-inline-block">
                                                                {{
                                                                "total_violation_spent_during"
                                                                | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.yearlyCappingLimit
                                                                .violationDate | date : 'yyyy'}}
                                                            </span> <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.yearlyCappingLimit
                                                                .spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.yearlyCappingLimit.currencyId)
                                                                }}
                                                            </span></li>
                                                        <li><span
                                                                class="float-left w-40pc d-inline-block color-red">
                                                                {{
                                                                "total_violation_during" | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.yearlyCappingLimit
                                                                .violationDate | date : 'yyyy'}}
                                                            </span> <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.yearlyCappingLimit
                                                                .violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.yearlyCappingLimit.currencyId)
                                                                }}
                                                            </span></li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .yearlyCappingLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="perItemVisible" class="card">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'perItemVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseSix">{{'per_person_limit_entertainment' |
                                translate:param}}<span><small class="color-red"></small></span> </a>
                        </div>
                        <div id="collapseSix" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'perItemVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span
                                                                class="float-left w-50pc d-inline-block text-ellipsis">{{
                                                                "per_person_limit" | translate: param
                                                                }} ({{this.getPolicyResponse
                                                                .policyViolation.perPersonLimit.categoryName}})
                                                            </span> <span
                                                                class="float-right w-50pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perPersonLimit
                                                                .limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perPersonLimit.currencyId)
                                                                }}
                                                            </span></li>
                                                        <li><span class="float-left w-40pc d-inline-block">{{
                                                                "per_person_spent"
                                                                | translate: param
                                                                }}</span> <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perPersonLimit
                                                                .spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perPersonLimit.currencyId)
                                                                }} </span></li>
                                                        <li><span
                                                                class="float-left w-40pc d-inline-block color-red">
                                                                {{
                                                                "violation_on" | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.perPersonLimit
                                                                .violationDate | date : 'dd-MMM-yyyy'}}</span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perPersonLimit
                                                                .violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perPersonLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .perPersonLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="perNightVisible" class="card">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'perNightVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven">
                                {{'per_night_limit_hotels_and_lodgings' | translate:param}} <span><small
                                        class="color-red"></small></span> </a>
                        </div>
                        <div id="collapseSeven" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'perNightVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row ">
                                            <div class="w-100pc overflow-auto position-relative height-auto">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li><span
                                                                class="float-left w-50pc d-inline-block text-ellipsis">{{
                                                                "per_night_limit" | translate: param
                                                                }}
                                                                ({{this.getPolicyResponse
                                                                .policyViolation.perNightLimit.categoryName}})</span>
                                                            <span
                                                                class="float-right w-50pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perNightLimit
                                                                .limit
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perNightLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li><span class="float-left w-40pc d-inline-block">{{
                                                                "per_night_spent"
                                                                | translate: param
                                                                }}</span> <span
                                                                class="float-right w-60pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perNightLimit
                                                                .spentAmount
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perNightLimit.currencyId)
                                                                }}
                                                            </span></li>
                                                        <li><span
                                                                class="float-left w-40pc d-inline-block color-red">{{
                                                                "violation_on" | translate: param
                                                                }} {{this.getPolicyResponse
                                                                .policyViolation.perNightLimit
                                                                .violationDate | date: 'dd-MMM-yyyy'}}</span>
                                                            <span
                                                                class="float-right w-60pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.perNightLimit
                                                                .violation
                                                                | number: ".2"
                                                                | noComma
                                                                }}
                                                                {{
                                                                getCurrencyName(getPolicyResponse.policyViolation.perNightLimit.currencyId)
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div class="float-left mt-2 w-100pc">
                                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' |
                                                            translate: param }}:</h6>
                                                        <p class="f-13 lH-normal wordbreak">{{
                                                            this.getPolicyResponse.policyViolation
                                                            .perNightLimit.notes}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="operationalInformationVisible">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow':openSectionName == 'operationalInformationVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse"
                                data-bs-target="#collapseEleven">
                                {{ "operational_info_violation" | translate: param }}
                            </a>
                        </div>
                        <div id="collapseEleven" class="collapse"
                            [ngClass]="{'show':openSectionName == 'operationalInformationVisible'}"
                            data-bs-parent="#accordion">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row">
                                            <div class="w-100pc" style="
                                    overflow-x: auto;
                                    overflow-y: auto;
                                    position: relative;
                                    height: auto;
                                ">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li *ngFor="let item of operationalInformationViolation">
                                                            <span
                                                                class="float-left w-70pc d-inline-block">{{item.fieldTitle}}</span>
                                                            <span
                                                                class="float-right w-30pc d-inline-block text-right">{{item.fieldValueString}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="defaultVehicleTypeVisible">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'defaultVehicleTypeVisible'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse" data-bs-target="#collapseTen">
                                {{ "default_vehicle_changed" | translate: param }}
                            </a>
                        </div>
                        <div id="collapseTen" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'defaultVehicleTypeVisible'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row">
                                            <div class="w-100pc" style="
                                overflow-x: auto;
                                overflow-y: auto;
                                position: relative;
                                height: auto;
                                ">
                                                <div class="row margin-top-0">
                                                    <ul class="tempUL policyViolation-duplicateExp">
                                                        <li>
                                                            <span class="float-left w-30pc d-inline-block">{{
                                                                "default_vehicle" | translate: param
                                                                }}</span>
                                                            <span
                                                                class="float-right w-70pc d-inline-block text-right">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.defaultVehicleType
                                                                .defaultVehicle
                                                                }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                class="float-left w-70pc d-inline-block color-red">{{
                                                                "non_default_vehicle_selected" | translate:
                                                                param
                                                                }} </span>
                                                            <span
                                                                class="float-right w-30pc d-inline-block text-right color-red">
                                                                {{
                                                                this.getPolicyResponse
                                                                .policyViolation.defaultVehicleType
                                                                .selectedVehicle
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="showPotentialFraud">
                        <div class="card-header"
                            [ngClass]="{'card-header-claimant-downArrow': openSectionName == 'potentialFraud'}">
                            <a class="collapsed card-link" data-bs-toggle="collapse" data-bs-target="#collapse11">
                                {{expenseDetails.expenseCategory}} - {{"potential_fraud_alert" | translate}}
                            </a>
                        </div>
                        <div id="collapse11" class="collapse" data-bs-parent="#accordion"
                            [ngClass]="{'show': openSectionName == 'potentialFraud'}">
                            <div class="card-body padding-10">
                                <div class="row margin-top-0">
                                    <div class="col-md-12 no-padding-right-left margin-top-0">
                                        <div class="row">
                                            <div class="w-100pc" style="
                                overflow-x: auto;
                                overflow-y: auto;
                                position: relative;
                                height: auto;
                                ">
                                                <div class="row margin-top-0">
                                                    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-20">                                                                
                                                        <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems" 
                                                        (onCellClick)="fnOnGridCellClick($event)" >
                                                       </app-grid>                   
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </div>
        </div>
    </div>
    <div class="block padding-bottom-0 box-shadow margin-top-11" *ngIf="!this.expenseDetails?.potentialFraud">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left">
                <h6 class="border-bottom padding-bottom-10"><span class="padding-right-10 color-orange"><i
                            class="far fa-file-signature" aria-hidden="true"></i></span>{{'justification_notes'
                    | translate:param}}
                    <small class="color-blue"></small>
                </h6>
                <div class="input-group margin-top-40"
                    [ngClass]="{ 'disabled': inputData.disableJustificationNotes == true }">
                    <input type="text" class="form-control has-value rounded margin-top-5 padding-left-0"
                        (input)="onChangeJustificationNotes()" maxlength="200" required="" value=""
                        name="JustificationNotes"
                        [ngClass]="{ 'has-value': inputData.justificationNotes, 'color-light-grey-disabledType': inputData.disableJustificationNotes == true }"
                        [(ngModel)]="inputData.justificationNotes" 
                         (change)="onChangeJustificationNotes()" />
                    <label for="JustificationNotes">{{'enter_justification_notes' | translate:param}}<span
                            *ngIf="inputData && !inputData.disableJustificationNotes">*</span></label>

                    <div *ngIf="this.isNoteError" class="alert-danger">
                        <b>{{ "label_Required" | translate: param }}</b>
                    </div>

                </div>
                <p class="text-right">
                    <small><span
                            [ngClass]="{'color-red': inputData.justificationNotes && inputData.justificationNotes.length > 190 }">{{inputData.justificationNotes?inputData.justificationNotes.length:0}}/200</span></small>
                </p>
            </div>
        </div>
    </div>
</div>

<app-view-receipt *ngIf="getPolicyResponse && getPolicyResponse.policyViolation
 && getPolicyResponse.policyViolation.duplicate!=null && getPolicyResponse.policyViolation.duplicate.length > 0"
    #viewReceiptComponent [receiptInfo]="receiptInfo" [receiptCount]="receiptCount"
    [hasReturnJourney]="hasReturnJourney" [milesTravelled]="milesTravelled" [triangulationMiles]="triangulationMiles"
    [detourMiles]="detourMiles" [amount]="" [distance]="distance" [origin]="origin" [destination]="destination"
    [receiptLat]="receiptLat" [receiptLong]="receiptLong" [latLongArray]="latLongArray" [mileageRate]="mileageRate" [zindex_Class]="zindexClass">
</app-view-receipt>

<duplicate-receipt-panel *ngIf="showDuplicateReceiptPanel"
 [originalReceiptInfo]="originalReceiptInfo" [duplicateReceiptInfo]="duplicateReceiptInfo"
  [claimType] = "claimType"
 (closePanelEvent)="fnCloseDuplicateReceiptPanel()"></duplicate-receipt-panel>

