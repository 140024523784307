<div class="bg-danger-polVio-claimant rounded cursor-pointer p-3" (click)="fnOpenPolicyViolationPanel()">
  <h6 class="d-flex justify-content-between align-items-center mb-0">
    <div>
      <i class="fas fa-exclamation-triangle eod-text-danger"></i>
      <span class="pl-2 fw-700 fs-18">
        {{ 'click_to_view_policy_violation' | translate }}
      </span>
    </div>
    <i class="fas fa-chevron-circle-right"></i>
  </h6>
</div>

<policy-violation-panel *ngIf="showPolicyViolationPanel" [inputData]="policyViolationInputModel" [isReceiptNumberSettingsEnabled]="isReceiptNumberSettingsEnabled"
    (closePanelEvent)="fnClosePolicyViolationPanel($event)" [zindex_Class]="zindexClass"></policy-violation-panel>

   
