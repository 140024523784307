import { ErrorInfo } from "../common/error-info";
import { CcImportModel, ClaimantAssociatedExpenseListRequest } from "../credit-card/credit-card";
import { EmployeeStatusEnum, UserTypeEnum } from "src/app/_models/common/manage-emp-enum.enum";
import { VehicleTypeEnum, VehicleStatusEnum, MileageRateTypeEnum } from "src/app/_models";

export class HeaderTemplateStepsConfig {
    constructor(
        public stepNo: string,
        public title: string,
        public isCompleted: boolean,
        public isActive: boolean,
        public isFirst: boolean,
        public isLast: boolean
    ) {
    }
}

export class HeaderTemplateButtonConfig {
    constructor(
        public title: string,
        public isDisabled: boolean,
        public isVisible: boolean
    ) {
    }
}

export class ImportEmployeeMapping {
    public id: number;
    public columnName: string;
    public srNo: string;
    public header: string;
    public data: string;
    public mappedFieldId: number;
    public mappedFieldColumn: string;
}


export interface IUniversalImportFieldMasterResponse {
    universalImportFieldMasterId: number;
    templateType: number;
    columnName: string;
    displayName: string;
    functionId: number;
    isRequired: boolean;
    isActive: boolean;
    matchingFields: string;
    isNotMap: boolean;
    id: any;
    value: any;
    isDisabled: boolean;
    disabled: boolean;
}

export class UniversalImportFieldMasterResponse implements IUniversalImportFieldMasterResponse {
    universalImportFieldMasterId: number;
    templateType: number;
    columnName: string;
    displayName: string;
    functionId: number;
    isRequired: boolean;
    isActive: boolean;
    matchingFields: string;
    isNotMap: boolean;
    id: any;
    value: any;
    isDisabled: boolean;
    disabled: boolean;
}

export interface IEmployeeImportModel {
    uniqueRowId: number;
    //employee details
    employeeId: number;
    employeeCode: string;
    employeeCode2: string;
    employeeCode3: string;
    employeeCode4: string;
    firstName: string;
    lastName: string;
    email: string;
    status: number;
    employeeRole: number;
    approverId: number;
    approverName: string;
    approverEmail: string;

    //vehicle Details
    vehicleTypeId: number;
    vehicleStatus: number;
    vehicleSubTypeId: number;
    rateType: number;
    orgVehicleTypeMapId: number;
    derivedName: string;
    statusName: string;
    ownershipType: string;
    baseOwnershipType: number;

    //Privileges
    isFinanceApprover: string;
    isAdministrator: string;

    //grade
    gradeName: string;
    gradeDescription: string;

    //cost centre
    costCentreName: string;
    costCentreCode1: string;
    costCentreCode2: string;

    //credit Card
    cardNumber: string;

    //triangulation
    homePostCode: string;
    officeName: string;
    officePostCode: string;
    milesHomeToOffice: number;

    //advance Mileage
    fuelType: number;
    hpEngineSizeSmall: string;
    hpEngineSizeMedium: string;
    hpEngineSizeLarge: string;
    initialMileage: number;

    //doc
    vehicleRegNo: string;
    licence: number;
    registration: number;
    insurance: number;
    mot: number;
    carTax: number;
    isDocRequired: string;

    selected: boolean;
    disableEdit: boolean;
    hideEdit: boolean;
    disableDelete: boolean;
    hideDelete: boolean;
    errors: ErrorInfo[];
    errorsCount: number;
    updatedOn: any;
    isRowHighlighted: boolean;

}
export class EmployeeImportModel implements IEmployeeImportModel {
    uniqueRowId: number = 0;
    //employee details
    employeeId: number = 0;
    employeeCode: string = "";
    employeeCode2: string = "";
    employeeCode3: string = "";
    employeeCode4: string = "";
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    status: number = EmployeeStatusEnum.NotInvited;
    employeeRole: number = UserTypeEnum.Claimant;
    approverId: number = 0;
    approverName: string = "";
    approverEmail: string = "";

    //vehicle Details
    vehicleTypeId: number = VehicleTypeEnum.No_Vehicle;
    vehicleStatus: number = VehicleStatusEnum.NoVehicle;
    vehicleSubTypeId: number = 0;
    rateType: number = MileageRateTypeEnum.FreeForLife;
    orgVehicleTypeMapId: number = 0;
    derivedName: string = "No Vehicle";
    statusName: string = "";
    ownershipType: string = "No Vehicle"
    baseOwnershipType: number = Number(0);

    //Privileges
    isFinanceApprover: string = '';
    isAdministrator: string = '';
    //grade
    gradeName: string = "";
    gradeDescription: string = "";

    //cost centre
    costCentreName: string = "";
    costCentreCode1: string = "";
    costCentreCode2: string = "";

    //credit Card
    cardNumber: string = "";

    homePostCode: string = "";
    officeName: string = "";
    officePostCode: string = "";
    milesHomeToOffice: number = 0;

    //advance Mileage
    fuelType: number = 0;
    hpEngineSizeSmall: string = "";
    hpEngineSizeMedium: string = "";
    hpEngineSizeLarge: string = "";
    initialMileage: number = 0;

    //doc
    vehicleRegNo: string = "";
    licence: any;
    registration: any;
    insurance: any;
    mot: any;
    carTax: any;
    isDocRequired: string = "";

    errors: ErrorInfo[] = [];
    errorsCount: number = 0;

    updatedOn: any;
    selected: boolean;
    disableEdit: boolean;
    hideEdit: boolean;
    disableDelete: boolean;
    hideDelete: boolean;
    isRowHighlighted: boolean;
}

export class ImportEmployeesEditInputModel {
    public title: string;
    public uniqueRowId: number;
}

export interface IValidateUniversalImportEmployeesRequest {
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    employees: EmployeeImportModel[];
}

export class ValidateUniversalImportEmployeesRequest implements IValidateUniversalImportEmployeesRequest {
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    isImport: boolean;
    employees: EmployeeImportModel[];
    constructor(fmName: string, fmEmail: string, fmUserId: number,isImport: boolean, employees: EmployeeImportModel[]) {
        this.fmName = fmName;
        this.fmEmail = fmEmail;
        this.fmUserId = fmUserId;
        this.isImport = isImport;
        this.employees = employees;
    }
}

// Response
export interface IValidateUniversalImportEmployeesResponse {
    responseFailedInviteEmployees: IResponseInviteEmployee[];
    passedEmployeesUniqueIds: number[];
    responsePassedInviteEmployees: IUniversalPassedEmployeesResponse[];
}

export class ValidateUniversalImportEmployeesResponse implements IValidateUniversalImportEmployeesResponse {
    responseFailedInviteEmployees: IResponseInviteEmployee[];
    passedEmployeesUniqueIds: number[];
    responsePassedInviteEmployees: IUniversalPassedEmployeesResponse[];
}

export interface IResponseInviteEmployee {
    uniqueRowId: number;
    employeeEmail: string;
    employeeId: number;
    employeeRole: number;
    approverId: number;
    responseMessage: string;
    errors: ErrorInfo[]
    failedRecord: EmployeeFailedRecord;
}

export class ResponseInviteEmployee implements IResponseInviteEmployee {
    uniqueRowId: number;
    employeeEmail: string;
    employeeId: number;
    employeeRole: number;
    approverId: number;
    responseMessage: string;
    errors: ErrorInfo[]
    failedRecord: EmployeeFailedRecord;
}
export class EmployeeFailedRecord {
    uid: number;
    eid: string;
    eid2: string;
    eid3: string;
    eid4: string;
    fn: string;
    ln: string;
    e: number;
    r: number;
    ai: number;
    err: string;
}


//Bulk Save

export interface IBulkSaveUniversalImportEmployeesRequest {
    orgId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    employees: EmployeeImportModel[];
}

export class BulkSaveUniversalImportEmployeesRequest implements IBulkSaveUniversalImportEmployeesRequest {
    orgId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    employees: EmployeeImportModel[];
    constructor(orgId: number, createdBy: number, fmName: string, fmEmail: string, fmUserId: number, employees: EmployeeImportModel[]) {
        this.orgId = orgId;
        this.createdBy = createdBy;
        this.fmName = fmName;
        this.fmEmail = fmEmail;
        this.fmUserId = fmUserId;
        this.employees = employees;
    }
}

// Response
export interface IBulkSaveUniversalImportEmployeesResponse {
    NewGradeList: string[];
    NewCostCentreList: string[];
    SuccessRecordsCounts: number;
    ActivatedOwnershipTypeList: string[];
    NewOfficeSetupList: string[];
}

export class BulkSaveUniversalImportEmployeesResponse implements IBulkSaveUniversalImportEmployeesResponse {
    NewGradeList: string[];
    NewCostCentreList: string[];
    SuccessRecordsCounts: number;
    ActivatedOwnershipTypeList: string[];
    NewOfficeSetupList: string[];
}

export class UniversalGradeResponse {
    gradeId: number;
    gradeName: string;
    gradeDescription: string;
    createdDate: number;
    organisationId: number;
    gradeStatus: boolean;
}

export class UniversalCostCentreResponse {
    costCentreId: number;
    costCentreName: string;
    costCentreCode1: string;
    costCentreCode2: string;
    createdDate: number;
    organisationId: number;
    status: boolean;
}

export class CheckListPanelModel {
    public title: string;
    public isSmallPanel: boolean;
    public isGridPanel: boolean;
    public data: CheckListData[];
}

export class CheckListData {
    public icon: string;
    public id: number;
    public name: string;
    public buttonTitle: string;
    public isButtonVisible: boolean;
}
export class ImportCatMapping {
    id: number;
    srNo: string;
    eodCategoryName: string;
    eodAccountingCode: string;
    eodVatRate: string;
    smbCategoryName: string;
    smbAccountingCode: string;
    smbVatRate: string;
    guestAccountingCode: string;
    guestVatRate: string;
    tipsAccountingCode: string;
    tipsVatRate: string;
    buttonText: string;
    buttonClass: string;
    baseCategoryId: number;
    IsRowOnTop: boolean;
    IsRowHighlighted: boolean;
    IsmappingComplete: boolean;
    taxRateMasterId: number;
    guestTaxRateMasterId: number;
    tipsTaxRateMasterId: number;
    textBeforeBtn: string;
    expenseCategoryId: number;
}

export class StorageData {
    importTemplate: number;
    templateData: any;
    importTemplateExtension: any;
    importTemplatFileName: any;
    defineHeaderData: any;
    importedTemplateData: any;
    isTemplateContainsHeader: boolean;
    importStep: number;
    importedTemplateMappedData: any;
    gridData: any;
    cardImportTemplateHeaderConfiguration: CcImportModel;
}

export interface IUniversalPassedEmployeesResponse {
    uniqueRowId: number;
    employeeID: number;
    email: string;
    employeeRole: number;
    costCentreId: number;
    gradeId: number;
    status: number;
}

export class UniversalPassedEmployeesResponse implements IUniversalPassedEmployeesResponse {
    uniqueRowId: number;
    employeeID: number;
    email: string;
    employeeRole: number;
    costCentreId: number;
    gradeId: number;
    status: number;
}

export interface IAssignApproverToCostCentresImportModel {
    uniqueRowId: number;
    approverId: number;
    approverName: string;
    approverEmail: string;
    costCentreName: string;
    costCentreCode1: string;
    costCentreCode2: string;
    errors: any;
    errorsCount: number;
    disableDelete: boolean;
    hideDelete: boolean;
    selected: boolean;
    removeApprover: string;

}
export class AssignApproverToCostCentresImportModel implements IAssignApproverToCostCentresImportModel {
    uniqueRowId: number = 0;
    approverId: number = 0;
    approverName: string = "";
    approverEmail: string = "";
    costCentreName: string = "";
    costCentreCode1: string = "";
    costCentreCode2: string = "";
    errors: any;
    errorsCount: number;
    disableDelete: boolean;
    hideDelete: boolean;
    selected: boolean;
    removeApprover: string;
}

export class ImportAssignApproverToCostCentresMapping {
    public id: number;
    public columnName: string;
    public srNo: string;
    public header: string;
    public data: string;
    public mappedFieldId: number;
    public mappedFieldColumn: string;
}

export interface IBulkSaveUniversalCostCentreApproversRequest {
    orgId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    costCentreApprovers: IAssignApproverToCostCentresImportModel[];
}

export class BulkSaveUniversalCostCentreApproversRequest implements IBulkSaveUniversalCostCentreApproversRequest {
    orgId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    costCentreApprovers: IAssignApproverToCostCentresImportModel[];
    constructor(orgId: number, createdBy: number, fmName: string, fmEmail: string, fmUserId: number, costCentreApprovers: IAssignApproverToCostCentresImportModel[]) {
        this.orgId = orgId;
        this.createdBy = createdBy;
        this.fmName = fmName;
        this.fmEmail = fmEmail;
        this.fmUserId = fmUserId;
        this.costCentreApprovers = costCentreApprovers;
    }
}

// Response
export interface IBulkSaveUniversalCostCentreApproversResponse {
    NewCostCentreList: string[];
    SuccessRecordsCounts: number;
    TotalApproversCounts: number;
    TotalCostCentresCounts: number;
}

export class BulkSaveUniversalCostCentreApproversResponse implements IBulkSaveUniversalCostCentreApproversResponse {
    NewCostCentreList: string[];
    SuccessRecordsCounts: number;
    TotalApproversCounts: number;
    TotalCostCentresCounts: number;
}

export class ImportCustomerMapping {
    public id: number;
    public columnName: string;
    public srNo: string;
    public header: string;
    public data: string;
    public mappedFieldId: number;
    public mappedFieldColumn: string;
}

export class ImportCardTransactionsMapping {
    public id: number;
    public columnName: string;
    public srNo: string;
    public header: string;
    public data: string;
    public mappedFieldId: number;
    public mappedFieldColumn: string;
}

export class ICounterAppDetails {
    uniqueRowId: number = 0;
    approverEmail: string = "";
    approverLimit: string = "";
    cApp1: string = "";
    cAppCond1: string = "";
    cAppLimit1: string = "";
    cApp2: string = "";
    cAppCond2: string = "";
    cAppLimit2: string = "";
    cApp3: string = "";
    cAppCond3: string = "";
    cAppLimit3: string = "";
    cApp4: string = "";
    cAppCond4: string = "";
    cAppLimit4: string = "";
    cApp5: string = "";
    cAppCond5: string = "";
    cAppLimit5: string = "";
    cApp6: string = "";
    cAppCond6: string = "";
    cAppLimit6: string = "";
    errors: any;
    errorsCount: number;
    disableDelete: boolean;
    hideDelete: boolean;
    selected: boolean;    
}

export class CounterAppDetails implements ICounterAppDetails {
    uniqueRowId: number = 0;
    approverEmail: string = "";
    approverLimit: string = "";
    cApp1: string = "";
    cAppCond1: string = "";
    cAppLimit1: string = "";
    cApp2: string = "";
    cAppCond2: string = "";
    cAppLimit2: string = "";
    cApp3: string = "";
    cAppCond3: string = "";
    cAppLimit3: string = "";
    cApp4: string = "";
    cAppCond4: string = "";
    cAppLimit4: string = "";
    cApp5: string = "";
    cAppCond5: string = "";
    cAppLimit5: string = "";
    cApp6: string = "";
    cAppCond6: string = "";
    cAppLimit6: string = "";
    errors: any;
    errorsCount: number;
    disableDelete: boolean;
    hideDelete: boolean;
    selected: boolean;    
}