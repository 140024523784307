import { ActionButtonConfig} from "src/app/_models/common/form/form";
export interface IAlertPanelModel {
  title: string;
  message: string[];
  btnOkToolTipText: string;
  btnCancelToolTipText: string;
  showPanel: boolean;
  showReviewConfirmContinuePanel: boolean;
  showReviewConfirmContinuePanelText: string;
  bodyClass:string;
  hideInfoIcon:boolean;
  singleMessagetextClass:string;
  btnArray: any[];
}
export class AlertPanelModel implements IAlertPanelModel {
  title: string;
  message: string[];
  btnOkToolTipText: string;
  btnCancelToolTipText: string;
  showPanel: boolean;
  showReviewConfirmContinuePanel: boolean;
  showReviewConfirmContinuePanelText: string;
  bodyClass:string;
  hideInfoIcon:boolean;
  singleMessagetextClass:string;
  htmlMessage: any[] = [];
  btnArray: any[] = [];

  constructor(
    showPanel: boolean,
    title: string,
    message: string[],
    btnCancelToolTipText: string,
    btnOkToolTipText: string,
    showReviewConfirmContinuePanel: boolean,
    showReviewConfirmContinuePanelText: string

  ) {
    this.showPanel = showPanel;
    this.title = title;
    this.message = message;
    this.btnCancelToolTipText = btnCancelToolTipText;
    this.btnOkToolTipText = btnOkToolTipText;
    this.showReviewConfirmContinuePanel = showReviewConfirmContinuePanel;
    this.showReviewConfirmContinuePanelText = showReviewConfirmContinuePanelText;
  }
}



export class MultiButtonAlertPanelModel
{
  title: string;
  message: string[] = [];
  btnOkToolTipText: string;
  btnCancelToolTipText: string;
  showPanel: boolean;
  showReviewConfirmContinuePanel: boolean;
  showReviewConfirmContinuePanelText: string;
  bodyClass:string;
  hideInfoIcon:boolean;
  singleMessagetextClass:string;
  htmlMessage: any[] = [];
  buttonConfig: ActionButtonConfig[];
}
