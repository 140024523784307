import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pre-approval-link-renderer',
  templateUrl: './pre-approval-link-renderer.component.html'
})

export class PreApprovalLinkRendererComponent implements ICellRendererAngularComp {
  name: string = "";
  preApprovalId : number = 0;
  tagName: string = '';

  constructor() {
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  initilize(params: ICellRendererParams) { 
    this.name =  params.getValue();
    
    if (params.data && params.data.preApprovalId) {
      this.preApprovalId = params.data.preApprovalId;
    }

    if (params.colDef && params.colDef.headerComponentParams) {
      this.tagName = params.colDef.headerComponentParams.tagName;
    }

  }
}
