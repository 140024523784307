export class EmailModel {
  emailLogId: string;
  userId: number;
  templateName: string;
  subjct: string;
  to: string;
  replyTo: string;
  cC: string[];
  bCC: string[];
  isAttachmentsIncluded: boolean;
  attachments: string[];
  sentTime: number;
  deliveredTime: number;
  status: number;
  emailNotificationUniqueId: string;
  emailType: string;
  keyValuePairs: {[index:string]: string}={};
}
