import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'multiple-card-number-renderer',
    templateUrl: './multiple-card-number-renderer.component.html'
})
export class MultipleCardNumberRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams;
    isMultipleCard: boolean;
    public text: string;
    
    constructor(private translate: TranslateService){}

    initilize(params: ICellRendererParams) {
        if (params.data) {
            if(params.data.isMultipleCard)
            {
                this.isMultipleCard = true;
            }
            else{
                this.isMultipleCard = false;
            }
            this.text = params.getValue();
            this.params= params;
        }
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
    
    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }
}
