import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import {
  UserRequestBase,
  OrganisationBasicSetup,
  VerifyYourEmail,
  UserBrowserDetails,
} from "../../_models/user/userrequestdto";
import { Userresponsedto } from "../../_models/user/userresponsedto";
import { BehaviorSubject, Observable } from "rxjs";
import {
  OrganisationDTO,
  OrganisationLocationDTO,
} from "../../_models/Organisation/organisation-location-dto";
import { ForgotPasswordRequest } from "../../_models/reset-password/forgotpassword-request";

import { Router } from "@angular/router";
import { setHeader } from "../../_helpers/setRequestHeader";
import { ClaimsHeaderService } from "../../shared/claimsHeader/claims-header.service";
import { OAuthService } from "angular-oauth2-oidc";
import { MonitoringService } from "./logging/monitoring.service";
import { ClaimsService } from "./claims.service";
import { NotificationService } from "./notification.service";
import { ManageempService } from "../manage-emp/manage-emp.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<Userresponsedto>;
  public currentUser: Observable<Userresponsedto>;
  TokenHeader: HttpHeaders;
  loginTokenHeader: HttpHeaders;

  post_logout_redirect_uri: any;

  constructor(
    private header: setHeader,
    private router: Router,
    private http: HttpClient,
    private oauthService: OAuthService,
    private claimsHeaderService: ClaimsHeaderService,
    private claimsService: ClaimsService,
    private monitoringService: MonitoringService,
    private notificationService: NotificationService,
    private ManageempService: ManageempService
  ) {
    this.currentUserSubject = new BehaviorSubject<Userresponsedto>(
      JSON.parse(localStorage.getItem("currentUser"))
    );

    this.currentUser = this.currentUserSubject.asObservable();

    window.addEventListener("storage", (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== "access_token" && event.key !== null) {
        return;
      }

      console.warn(
        "Noticed changes to access_token (most likely from another tab), updating isAuthenticated"
      );
      //this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.logout();
      }
    });
  }

  hideAppLoader() {
    let loaderElement = document.getElementsByClassName('app-loading') as HTMLCollectionOf<HTMLElement>;

    if (loaderElement.length != 0) {
      loaderElement[0].style.display = "none";
    }
  }

  loadAccessToken() {
    localStorage.removeItem("loginToken");
    var settings = {
      url: environment.token_endpoint,
      method: "POST",
      timeout: 0,
      async: false,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic d2ViOnNlY3JldA==",
      },
      data: {
        grant_type: "client_credentials",
        scope: "Api",
      },
    };
    $.ajax(settings).done(function (response) {
      localStorage.setItem("loginToken", response.access_token);
    });
  }

  public get currentUserValue(): Userresponsedto {
    return this.currentUserSubject.value;
  }

  login(user_requestdto: UserRequestBase): Observable<any> {
    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(user_requestdto);
    return this.http
      .post<any>(`${environment.billingApiUrl}Login`, body, {
        headers: this.loginTokenHeader,
      })
      .pipe(
        map((user) => {
          //
          // login successful if there's a jwt token in the response
          if (user.responseInfo) {
            if (user.responseInfo.isOnboardingProcessComplete) {
              localStorage.setItem("currentUser", JSON.stringify(user));
              //this.currentUserSubject.next(user.responseInfo);
            } else {
              localStorage.setItem("currentUserTemp", JSON.stringify(user));
            }
          }

          return user;
        })
      );
  }

  socialmedialogin(user_requestdto: UserRequestBase): Observable<any> {
    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(user_requestdto);

    return this.http
      .post<any>(`${environment.billingApiUrl}SocialMediaLogin`, body, {
        headers: this.loginTokenHeader,
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user.responseInfo && user.responseInfo.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            //this.currentUserSubject.next(user.responseInfo);
            //this.oauthService
            //    .fetchTokenUsingPasswordFlowAndLoadUserProfile(
            //      user_requestdto.RequestInfo.LoginId,
            //      "9aYJu5ga9gy95n6a9ETHnp1dF+z/86FzNa3CZ2GvAbPwqTtapgdTUw=="
            //    )
            //    .then(() => {
            //      
            //      console.debug('successfully logged in');
            //      var tokenNew = this.oauthService.getAccessToken();
            //      //this.loginFailed = false;
            //    })
            //    .catch(err => {
            //      console.error('error logging in', err);
            //      //this.loginFailed = true;
            //    });
          }

          return user;
        })
      );
  }

  register(user_requestdto: UserRequestBase): Observable<any> {
    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(user_requestdto);
    localStorage.setItem("currentUserTemp", JSON.stringify(user_requestdto));
    return this.http.post<any>(
      `${environment.billingApiUrl}Register`,
      body,
      { headers: this.loginTokenHeader }
    );

    //this.currentUserSubject.next(user.responseInfo);
  }

  UpdateUserBrowserDetails(request: UserBrowserDetails): Observable<any> {
    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.billingApiUrl}UpdateUserBrowserDetail`,
      body,
      { headers: this.loginTokenHeader }
    );
  }

  registerwithSocial(user_requestdto: UserRequestBase): Observable<any> {
    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(user_requestdto);
    localStorage.setItem("currentUserTemp", JSON.stringify(user_requestdto));
    return this.http.post<any>(
      `${environment.billingApiUrl}SocialMediaRegister`,
      body,
      { headers: this.loginTokenHeader }
    );
  }

  organisationBasicSetup(
    organisationBasicSetup: OrganisationBasicSetup,
    token: string
  ): Observable<any> {
    this.TokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(organisationBasicSetup);
    return this.http.post<any>(
      `${environment.billingApiUrl}OrganisationBasicSetup`,
      body,
      { headers: this.TokenHeader }
    );
    //.pipe(map(user => {
    // login successful if there's a jwt token in the response
    //if (user) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    //localStorage.setItem('currentUser', JSON.stringify(user));
    //this.currentUserSubject.next(user);
    //}

    //return user;
    //}));
  }

  updateorganisation(
    organisationDto: OrganisationDTO,
    token: string
  ): Observable<any> {
    let body = JSON.stringify(organisationDto);
    return this.http.post<any>(
      `${environment.billingApiUrl}UpdateOrganisation`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  updateorganisationlocation(
    organisationLocationDto: OrganisationLocationDTO,
    token: string
  ): Observable<any> {
    let body = JSON.stringify(organisationLocationDto);
    return this.http.post<any>(
      `${environment.billingApiUrl}AddOrganisationLocation`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  organisationdetails(userid: string, loginid: string, token: string) {
    this.TokenHeader = this.header.authorizationHeader();
    let params = new HttpParams();
    params = params.append("LoginId", loginid);
    params = params.append("Userid", userid);

    return this.http.get<any>(
      `${environment.billingApiUrl}OrganisationDetail`,
      { params: params, headers: this.TokenHeader }
    );
  }

  organisationlocationdetails(orgid: string, token: string) {
    let params = new HttpParams();
    params = params.append("OrgId", orgid);

    return this.http.get<any>(
      `${environment.billingApiUrl}OrganisationLocationDetail`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  logout() {
    let claims = localStorage.getItem("claims");
    if (claims) {
      const userId = JSON.parse(claims).UserId;
      const orgId = JSON.parse(claims).OrgId;
      let params = new HttpParams();
      params = params.append("userId", userId);

      this.http.get<any>(`${environment.billingApiUrl}LogoutUser`, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        params: params,
      });

      //this.oauthService.logOut();

      localStorage.removeItem("orgId");
      localStorage.removeItem("selectedCurrencyCode");
      localStorage.removeItem("approverName");
      localStorage.removeItem("emailId");
      localStorage.removeItem("userId");
      localStorage.removeItem("employeesInvited");
      localStorage.removeItem("orgName");
      localStorage.removeItem("financeManager");
      localStorage.removeItem("selectedCurrency");
      localStorage.removeItem("currencyIcon");
      localStorage.removeItem("token");
      localStorage.removeItem("currentUserTemp");
      localStorage.removeItem("loginId");
      localStorage.removeItem("CurrentOrganisationCurrency");
      localStorage.removeItem("claims");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("loginToken");
      localStorage.removeItem("ImportStorageData");
      sessionStorage.removeItem("OrganisationFunctionSubscriptionCache");
      sessionStorage.removeItem("OrganisationSettingListData");
      sessionStorage.removeItem("SubscribedFunctionsForOrganisationData_" + orgId);
      sessionStorage.removeItem("FunctionPrivilegeMappingData");
      sessionStorage.removeItem("FunctionPrivilegeUserData_" + userId);
      sessionStorage.removeItem("OrgWizardDetailsCache_" + orgId);
      sessionStorage.removeItem("organisationFuncPurchaseCacheKey");
      sessionStorage.removeItem("ExpenseCurrencyListData");
      sessionStorage.removeItem("translatorKeys");
      sessionStorage.removeItem("ExpenseApprovalListFilters");
      localStorage.removeItem("policySubCategoryDetail");

      this.currentUserSubject.next(null);

      this.monitoringService.clearUserId();

      this.claimsService.updateClaims(null);

      this.notificationService.stopConnection();

      //var current = JSON.parse(localStorage.getItem('currentUser'));
      

      //var settings = {
      //  "url": 'http://localhost:5000/connect/endsession/' + current.responseInfo.token + '&post_logout_redirect_uri=http://localhost:59484/login',
      //  "method": "GET",
      //  "timeout": 0,
      //  //"headers": {
      //  //  "Content-Type": "application/x-www-form-urlencoded",
      //  //  "Authorization": "Basic V2ViOnNlY3JldA=="
      //  //},
      //  //"data": {
      //  //  "grant_type": "client_credentials",
      //  //  "scope": "Api"
      //  //}
      //};

      //$.ajax(settings).done(function (response) {
      //  

      //});
    }
    this.ManageempService.DropOrgAllEmployeesCache();
  }

  setregisternav() {
    let userResDto: any = localStorage.getItem("currentUserTemp");
    localStorage.setItem("currentUser", userResDto);
    this.currentUserSubject.next(userResDto);
  }

  setloginnav() {
    this.notificationService.openNotificationPanel = true;
    let userResDto: any = localStorage.getItem("currentUser");
    this.currentUserSubject.next(userResDto);
  }

  verifyYourEmail(
    verifyYourEmail: VerifyYourEmail,
    addCurrentUser: boolean
  ): Observable<any> {
    this.TokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(verifyYourEmail);
    let user: any = this.http.post<any>(
      `${environment.billingApiUrl}VerifyYourEmail`,
      body,
      { headers: this.TokenHeader }
    );
    if (addCurrentUser) {
      //localStorage.setItem("currentUser", JSON.stringify(user));
      this.currentUserSubject.next(null);
    } else {
      localStorage.setItem("currentUserTemp", JSON.stringify(user));
      this.currentUserSubject.next(null);
    }
    return user;
  }

  sendForgotpasswordEmail(
    forgotPasswordRequest: ForgotPasswordRequest
  ): Observable<any> {
    this.TokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(forgotPasswordRequest);
    return this.http
      .post<any>(`${environment.billingApiUrl}ForgotPassword`, body, {
        headers: this.TokenHeader,
      })
      .pipe(
        map((forgotPasswordResponse) => {
          return forgotPasswordResponse;
        })
      );
  }

  getLocationOnIP(orgData) {
    this.TokenHeader = this.header.authorizationHeader();
    return this.http.get<any>(
      `${environment.billingApiUrl}GetLocationDetailsWithIP?ip=` +
      orgData,
      { headers: this.TokenHeader }
    );
  }

  getIpCliente() {
    return this.http.get<any>(`https://api.ipify.org`, {
      responseType: "text" as "json",
    });
  }

  getIpClientLocation() {
    return this.http.get("https://ipapi.co/json/");
  }

  checkForUserExistance(emailId) {
    this.loginTokenHeader = this.header.authorizationHeader();
    
      let params = new HttpParams();
      params = params.append("emailId", emailId);

      return this.http.get<any>(
        `${environment.billingApiUrl}GetUserByEmail`,
        {
          headers: this.loginTokenHeader,
          params: params,
        }
      );
  }

  getXeroUserDetails(code) {
    this.loginTokenHeader = this.header.authorizationHeader();
    

      return this.http.get<any>(
        `${environment.exportApiUrl}GetXeroUserDetails/`+code,
        {
          headers: this.loginTokenHeader
        }
      );
  }

  checkDomainEnabledForSSO(domaiName) {
    this.loginTokenHeader = this.header.authorizationHeader();
    

      return this.http.get<any>(
        `${environment.billingApiUrl}CheckDomainEnabledForSSO?domainName=`+domaiName,
        {
          headers: this.loginTokenHeader
        }
      );
  }



  CheckDomainRegistered(domaiName) {
    this.loginTokenHeader = this.header.authorizationHeader();


      return this.http.get<any>(
        `${environment.billingApiUrl}CheckDomainRegistered?domainName=`+domaiName,
        {
          headers: this.loginTokenHeader
        }
      );
  }


  SendInviteeNotificationToFinanceManager(user_requestdto){

    this.loginTokenHeader = this.header.authorizationHeader();
    let body = JSON.stringify(user_requestdto);
    return this.http
      .post<any>(`${environment.billingApiUrl}SendInviteeNotificationToFinanceManager`, body, {
        headers: this.loginTokenHeader,
      })
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
}
