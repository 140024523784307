export class NotificationGroup {
    constructor(public header: string, public headerText: string,
        public notifications: Notification[], public unreadCount: number, public isUnread: boolean) {

    }
}

export class Notification {
    public id: string;
    public orgId: string;
    public jobStatus: number;
    public jobId: string;
    public functionId: number;
    public text: NotificationTextModel;
    public displayText: any;
    public iconUrl: string;
    public action: NotificationActionModel;
    public addOn: string;
    public addBy: number;
    public editOn: string;
    public editBy: number;
    public userId: number;
    public isNew: boolean;
    public isVisible: boolean;
    public isSnoozed: boolean;
    public snoozeStart: Date;
    public snoozeEnd: Date;
    public snoozeBtnVisible: boolean;
    public actionsBtnVisible: boolean;
    public failNotification: boolean;
}

export class NotificationTextModel {
    public header: string;
    public key: string;
    public params: string[];
}

export class NotificationActionModel {
    public type: number;
    public route: string;
    public panel: string;
    public userId: number;
}

export enum NotificationActionType {
    Redirect = 1,
    ShowPanel = 2,
    BackgroundJob = 3
}