import { Injectable } from '@angular/core';
import { Claims } from 'src/app/_models/common/claims';
import { HttpClient } from '@angular/common/http';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { ClaimsService } from './claims.service';
import { IApiRequest, IApiresponse, BillingMethod } from 'src/app/_models';
import { IWorkflowRequest } from 'src/app/_models/Workflow/workflow-request';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWorkflowResponse, WorkflowApprover, IWorkflowApprover } from '../../../app/_models/Workflow/workflow-response';
import { IPresetExport, PresetExport } from 'src/app/_models/Dashboard/preset-export';
import { EmployeeStatusEnum } from 'src/app/_models/common/manage-emp-enum.enum';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  claims: Claims;
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService
    , private claimsService: ClaimsService,  public datepipe: DatePipe)
    {
      this.claimsService.currentClaims.subscribe(claims => this.claims = claims);
    }

  SoloGetWorkflow(requestinfo: IApiRequest<IWorkflowRequest>): Observable<IApiresponse<IWorkflowResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IWorkflowResponse>>(`${environment.expenseApiUrl}GetWorkflow`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
  SoloGetDashboardPresetData(requestinfo: IApiRequest<IWorkflowRequest>): Observable<IApiresponse<IPresetExport[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IPresetExport[]>>(`${environment.expenseApiUrl}GetDashboardPresetData`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  mapWFApprover(data: any[]): WorkflowApprover[] {
    let result: IWorkflowApprover[] = [];
    if (data && data.length > 0) {
      data.forEach(row => {
        let emp: WorkflowApprover = {
          employeeID: row.eid,
          employeeCode: row.ec,
          employeeCode2: row.ec2,
          employeeCode3: row.ec3,
          employeeCode4: row.ec4,
          firstName: row.fn,
          lastName: row.ln,
          displayName: row.dn,
          email: row.e,
          status: row.s,
          employeeRole: row.er,
          vehicleTypeId: row.vti,
          derivedName: row.den,
          emailDisplayName: row.edn,
          vehicleStatus: row.vs,
          vehicleSubTypeId: row.vsti,
          rateType: row.rt,
          orgVehicleTypeMapId: row.ovtm,
          approverId: row.ai,
          updatedOn: row.uo,
          approverName: row.an,
          costCentreId: row.cci ? row.cci : 0,
          costCentreName: row.ccn ? row.ccn : '',
          disableDeactivate: false,
          disableOthers: false,
          isRowHighlighted: false,
          selected: false,
          hideDeactivate: false,
          hideResendInvite: row.s != EmployeeStatusEnum.Pending,
          hideSendInvite: row.s != EmployeeStatusEnum.NotInvited,
          hideActivate: row.s != EmployeeStatusEnum.Inactive,
          disableResendInvite: false,
          disableSendInvite: false,
          disableActivate: false,
          disableEdit: false,
          hideEdit: false,
          isCheckboxDisabled: false,
          createdOn: row.co,
          costCentreCode1: row.ccc1 ? row.ccc1 : '',
          gradeId: row.gi,
          gradeName: row.gn,
          linkedToXero: row.ltx,
          approvalStatus: row.approvalStatus,
          number: row.number,
          comment: row.comment,
          actionDate: row.actionDate,
          actionDateString: this.datepipe.transform(row.actionDate, "dd-MMM-yyyy HH:mm:ss"),
          sno: row.sno,
          perPersonBillingType: row.bt == BillingMethod.PerPersonBilling,
          hybridBillingType: row.bt == BillingMethod.HybridBilling,
          displaySN: row.sno == 0 ? 'Approver' : 'CA '+row.sno+'.',
          userType : row.userType,
          kycStatus:row.kycs,
          assignedExpCatIds:row.aeIds,
          assignedFormIds:row.afIds,
          companyName: row.comn,
          isConvertedToPowerUser : row.icpu,
          costCentreRuleId : row.crid,
          isDeleted: false,
          userUpdatedOn: row.uuo
        };

        result.push(emp);
      });
    }
    return result;
  }
}
