<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <form [formGroup]="createTagsForm" novalidate>
        <div class="rightSidePanel active" style="z-index: 99999;">
            <header>
                <div class="row">
                    <div class="col-lg-12 no-padding-right-left">
                        <div class="row">
                            <div class="col-lg-8 padding-left-0">
                                <h2>{{'enter_tag' | translate:param}}</h2>
                            </div>
                            <div
                                class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                                <ul class="d-flex justify-content-end w-100pc">
                                    <li class="padding-left-0">
                                        <Button class="btn btn-primary height-38px margin-right-15" 
                                        [ngClass]="{ 'disabled bg-secondary opacity-60': !this.createTagsForm.dirty}" (click)="fnSelectTag()">
                                             {{ 'label_save' | translate: param }}
                                        </Button>
                                    </li>
                                    <li class="padding-right-1" >
                                        <Button class="btn btn-outline-secondary border-color-warning text-mustard height-38px"
                                             (click)="fnClosePanel()">
                                            {{ 'label_close' | translate: param }}
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="clear"></div>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left margin-top-15">
                    <div class="block">
                        <div class="">
                            <span
                                class="align-middle d-inline-block position-relative margin-right-5 margin-bottom-5 border border-secondary rounded"
                                *ngFor="let tag of tagList; let i=index;let last = last">
                                <input type="text" placeholder="Enter Tag" [value]="tag"
                                    class="text-logo-blue  padding-5 align-middle disabled opacity-60 text-ellipsis w-90pc">
                                <span class="position-absolute right-10 top-5px cursor-pointer"
                                    (click)="fnRemoveTag(i)">
                                    <i class="far fa-times"></i>
                                </span>

                            </span>

                            <span class="align-middle d-inline-block position-relative margin-right-5 margin-bottom-5">
                                <input type="text" maxlength="40" placeholder="Enter Tag" formControlName="tagName"
                                    #tagName
                                    class="text-logo-blue border border-secondary rounded padding-5 align-middle"
                                    autofocus>

                            </span>

                            <span class="padding-left-15 align-middle d-inline-block cursor-pointer"
                                [ngClass]="{ 'disabled opacity-60': createTagsForm.invalid }" (click)="fnAddTag()">
                                <i class="fas fa-plus-circle text-logo-blue f-25 top-2px position-relative"> </i>
                            </span>
                        </div>
                    </div>
                    <div class="block padding-top-10" *ngIf="fnGetFilteredRecentTagList().length > 0">
                        <div class="row border-bottom">
                            <h6> <span class="align-middle"></span> {{'recent_tags' | translate:param}}</h6>
                        </div>
                        <div class="row margin-top-20">
                            <ul class="d-flex justify-content-start flex-row flex-wrap f-11">
                                <li class="position-relative w-24pc text-left margin-right-5 margin-top-10"
                                    (click)="fnAddRecentTag(item)" *ngFor="let item of fnGetFilteredRecentTagList()">
                                    <button type="button"
                                        class=" text-left text-charcoal-black bg-light-grey border border-secondary rounded padding-5 w-100pc align-middle">
                                        <span class="text-ellipsis w-90pc d-block">
                                            {{item}}
                                        </span>

                                    </button>
                                    <span class="position-absolute right-5 top-6px cursor-pointer">
                                        <i class="fas fa-plus-circle text-logo-blue f-14  position-relative"
                                            aria-hidden="true">
                                        </i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>