<span [hidden]="!preApprovalId">
    <span class="preapproval-badge">
        {{ "pre_approved" | translate }}
    </span>
    <span [hidden]="!preApprovalName">
        <span [class.red-text]="preApprovalBalance < 0">
            &nbsp; {{ "balance" | translate }}:&nbsp;
        </span>
        <span class="blue-text" [class.red-text]="preApprovalBalance < 0">
            {{ preApprovalBalance | number: "1.2-2" }}
        </span>
        <span>
            &nbsp;|&nbsp;{{ "tag" | translate }}:&nbsp;
        </span>
        <span class="blue-text">
            {{ preApprovalName }}
        </span>
    </span>
</span>