import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-vehicle-button',
    templateUrl: "./formly-vehicle-button.component.html"
})

export class FormlyVehicleButtonComponent extends FieldType implements OnInit, OnDestroy {
    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;

    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange(selectedValue) {
        this.formControl.setValue(selectedValue);
        this.to.change(this.field, this.formControl.value);
    }
    classupdateWhenNoAdvanceMileage(option) {
        let className = ''
        let options = this.field.templateOptions.options;
        let itemCount = 0;
        options.forEach(element => {
            itemCount = itemCount+1;
        });
        if (itemCount > 1) {
            if (this.formControl.value == option.id) {
                className = 'btn-vehicleType-active';
            }
            else {
                className = 'btn-vehicleType';
            }
        }
        else {
            className = 'btn-vehicleType-active disabled';
        }
        return className;
    }

}