import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-text-with-description-renderer',
  templateUrl: './text-with-description-renderer.component.html',
  styleUrls: ['./text-with-description-renderer.component.scss']
})
export class TextWithDescriptionRendererComponent implements ICellRendererAngularComp {
  text: string;
  description: string;
  constructor() {

  }

  initilize(params: ICellRendererParams) {
    let data = params.getValue();
    
    if (data) {
      this.text = data.text;
      this.description = data.description;
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
