import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Injectable } from "@angular/core";

import { IApiRequest, IApiresponse } from "src/app/_models";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import {
  CategorySummaryResponse,
  CloseProjectRequest,
  CostCentreCustomerList,
  GetActiveEmployeesForProjectBudgetRequest,
  GetActiveEmployeesForProjectBudgetResponse,
  GetCustomerCostCentreListRequest,
  GetProjectBudgetAllDetailsByProgBudIdRequest,
  GetProjectBudgetByEntityTypeRequest,
  GetProjectListForCloseResponse,
  GetProjectManagerCountRequest,
  IProjectBudgetListRequest,
  IProjectBudgetListResponse,
  ProjectBudgetForEntity,
  ProjectBudgetResponse,
  ProjectCategorySummaryRequest,
  ProjectManagerDetails,
  ProjectSummaryRequest,
  ProjectSummaryResponse,
  UpdateProjectManagerRequest,
  IGetCustomerCostCentreProjectsListRequest,
  ICostCentreCustomerProjectListResponse,
} from "src/app/_models/project-budget/project-budget";

@Injectable({
  providedIn: "root",
})
export class ProjectBudgetService {
  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) {}

  GetProjectBudgetList(
    requestInfo: IApiRequest<IProjectBudgetListRequest>
  ): Observable<IApiresponse<IProjectBudgetListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IProjectBudgetListResponse>>(
      `${environment.billingApiUrl}GetProjectBudgetList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectSummary(
    requestInfo: IApiRequest<ProjectSummaryRequest>
  ): Observable<IApiresponse<ProjectSummaryResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ProjectSummaryResponse>>(
      `${environment.billingApiUrl}GetProjectSummary`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomerCostCentreList(
    requestInfo: IApiRequest<GetCustomerCostCentreListRequest>
  ): Observable<IApiresponse<CostCentreCustomerList[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<CostCentreCustomerList[]>>(
      `${environment.billingApiUrl}GetCustomerCostCentreList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetActiveEmployeesForProjectBudget(
    requestInfo: IApiRequest<GetActiveEmployeesForProjectBudgetRequest>
  ): Observable<IApiresponse<GetActiveEmployeesForProjectBudgetResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<GetActiveEmployeesForProjectBudgetResponse[]>
    >(`${environment.billingApiUrl}GetActiveEmployeesForProjectBudget`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  SaveProjectBudget(
    requestInfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AddEditProjectBudget`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectBudgetAllDetailsByProjectBudgetId(
    requestInfo: IApiRequest<GetProjectBudgetAllDetailsByProgBudIdRequest>
  ): Observable<IApiresponse<ProjectBudgetResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ProjectBudgetResponse>>(
      `${environment.billingApiUrl}GetProjectBudgetAllDetailsByProjectBudgetId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectCategorySummaryResponse(
    requestInfo: IApiRequest<ProjectCategorySummaryRequest>
  ): Observable<IApiresponse<CategorySummaryResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<CategorySummaryResponse[]>>(
      `${environment.billingApiUrl}GetProjectCategorySummaryResponse`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectBudgetByEntityType(
    requestInfo: IApiRequest<GetProjectBudgetByEntityTypeRequest>
  ): Observable<IApiresponse<ProjectBudgetForEntity[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ProjectBudgetForEntity[]>>(
      `${environment.billingApiUrl}GetProjectBudgetByEntityType`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectManagerCount(
    requestInfo: IApiRequest<GetProjectManagerCountRequest>
  ): Observable<IApiresponse<ProjectManagerDetails[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ProjectManagerDetails[]>>(
      `${environment.billingApiUrl}GetProjectManagerCount`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateProjectManagerWhenApproverDeactivate(
    requestInfo: IApiRequest<UpdateProjectManagerRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UpdateProjectManagerWhenApproverDeactivate`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectListForClose(
    requestInfo: IApiRequest<IProjectBudgetListRequest>
  ): Observable<IApiresponse<GetProjectListForCloseResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<GetProjectListForCloseResponse>>(
      `${environment.billingApiUrl}GetProjectListForClose`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CloseProject(
    requestInfo: IApiRequest<CloseProjectRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}CloseProject`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomerCostCentreProjectList(
    requestInfo: IApiRequest<IGetCustomerCostCentreProjectsListRequest>
  ): Observable<IApiresponse<ICostCentreCustomerProjectListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICostCentreCustomerProjectListResponse>>(
      `${environment.billingApiUrl}GetCustomerCostCentreProjectList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
