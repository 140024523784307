import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiRequest, IApiresponse } from "src/app/_models";
import {
  IResponseCostCentre,
  IRequestCostCentre,
  IRequestCostCentreByOrg,
  IRequestGetCostCentre,
  IRequestGetCostCentreLog,
  IResponseCostCenterLog,
  IRequestGetCostCentreListForAssignEmployee,
  ICostCentreMappedEmployeeList,
  IRequestBulkDeactiveCostCentre,
  IRequestImportCostCentre,
  ICSVRecordExport,
  CSVRecordExport,
  IGetCostCentreResponse,
  CostCentreModel,
  CostCentreApprover,
  CostCentreApproversCount,
  SaveCostCentreApproverRequest,
  RequestSaveCCDisplaySetting,
} from "../../_models/costCentre/costCentre";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { ExcelExportService } from "../common/excel-export.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CostCentreService {
  costCentreUrl: any;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private excelExportService: ExcelExportService
  ) {}

  GetCostCentresWithApprovers(): Observable<
    IApiresponse<CostCentreApprover[]>
  > {
    return this.http.get<IApiresponse<CostCentreApprover[]>>(
      `${environment.billingApiUrl}GetCostCentresWithApprovers`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCostCentreCountWithoutApprovers(): Observable<
    IApiresponse<CostCentreApproversCount[]>
  > {
    return this.http.get<IApiresponse<CostCentreApproversCount[]>>(
      `${environment.billingApiUrl}GetCostCentresWithoutApprovers`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  saveCostCentreApproverAssignment(
    requestInfo: SaveCostCentreApproverRequest
  ): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<boolean>>(
      `${environment.billingApiUrl}SaveCostCentreApprovers`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCostCentres(): Observable<IApiresponse<CostCentreModel[]>> {
    return this.http
      .get<IApiresponse<CostCentreModel[]>>(
        `${environment.billingApiUrl}GetCostCentres`,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      )
      .pipe(
        map((result) => {
          if (result && result.responseInfo && result.responseInfo.length > 0)
            result.responseInfo = result.responseInfo.sort((a, b) =>
              a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1
            );
          return result;
        })
      );
  }

  GetCostCentresForOrg(
    requestInfo: IApiRequest<IRequestCostCentreByOrg>
  ): Observable<IApiresponse<IResponseCostCentre>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseCostCentre>>(
      `${environment.billingApiUrl}GetCostCentresByOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCostCentre(
    requestInfo: IApiRequest<IRequestGetCostCentre>
  ): Observable<IApiresponse<IResponseCostCentre>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseCostCentre>>(
      `${environment.billingApiUrl}GetCostCentreById`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCostCentreLogs(
    requestInfo: IApiRequest<IRequestGetCostCentreLog>
  ): Observable<IApiresponse<IResponseCostCenterLog>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseCostCenterLog>>(
      `${environment.billingApiUrl}GetCostCenterHistoryByCostCentreId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateCostCentre(
    requestInfo: IApiRequest<IRequestCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IRequestCostCentre>>(
      `${environment.billingApiUrl}UpdateCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  DeactiveCostCentre(
    requestInfo: IApiRequest<IRequestGetCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IRequestGetCostCentre>>(
      `${environment.billingApiUrl}DeactiveCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CreateCostCentre(
    requestInfo: IApiRequest<IRequestCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IRequestCostCentre>>(
      `${environment.billingApiUrl}CreateCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CreateCostCentreBulk(
    requestInfo: IApiRequest<IRequestCostCentre[]>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IRequestCostCentre[]>>(
      `${environment.billingApiUrl}CreateCostCentreBulk`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ReactiveCostCentre(
    requestInfo: IApiRequest<IRequestGetCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IRequestGetCostCentre>>(
      `${environment.billingApiUrl}ReactiveCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCostCentresForAssignEmployees(
    requestInfo: IApiRequest<IRequestGetCostCentreListForAssignEmployee>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetCostCenterListForAssignEmployee`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomerCCInfo(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetCostCentreCustomerSettings`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCCDisplaySetting(
    requestinfo: IApiRequest<RequestSaveCCDisplaySetting>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveCostCentreSettings`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignDeassignCostCentreToEmployee(
    requestInfo: IApiRequest<ICostCentreMappedEmployeeList>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AssignDeassignCostCentreToEmployee`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignDeassignCostCentreToEmployeeBackground(
    requestInfo: IApiRequest<ICostCentreMappedEmployeeList[]>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AssignDeassignCostCentreToEmployeeBackground`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  BulkDeactiveCostCentre(
    requestInfo: IApiRequest<IRequestBulkDeactiveCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkDeactiveCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllCostCentresForOrg(
    requestInfo: IApiRequest<IRequestCostCentreByOrg>
  ): Observable<IApiresponse<IResponseCostCentre>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseCostCentre>>(
      `${environment.billingApiUrl}GetAllCostCentresByOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ImportCostCentre(
    requestInfo: IApiRequest<IRequestImportCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}ImportCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  exportData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: ICSVRecordExport[] = [];
    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new CSVRecordExport();
      recordExport.CostCentreName = exportedData[i].costCentreName;
      recordExport.CostCentreCode1 = exportedData[i].costCentreCode1;
      recordExport.CostCentreCode2 = exportedData[i].costCentreCode2;
      if (addErrorColumn) {
        recordExport.Error = exportedData[i].error;
      }

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  BulkReactiveCostCentre(
    requestInfo: IApiRequest<IRequestBulkDeactiveCostCentre>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkReactiveCostCentre`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetActiveCostCentre(): Observable<IApiresponse<IGetCostCentreResponse>> {
    return this.http.get<IApiresponse<IGetCostCentreResponse>>(
      `${environment.billingApiUrl}GetActiveCostCentres`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
}
