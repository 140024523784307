import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'grid-toggle-renderer',
  templateUrl: './grid-toggle-renderer.component.html'
})
export class GridToggleRendererComponent  implements ICellRendererAngularComp {

  isEnabled: boolean;  
  params: any;
  showText: boolean;
  showSwitchText:string = "";
  sequence: number;

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      this.isEnabled = params.data.isActive;
      this.sequence = params.data.sequence;  
      this.showText =  params.data.showText;  
      this.showSwitchText = params.data.showSwitchText;  
    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    this.isEnabled = event.target.checked;
    this.params.setValue(this.isEnabled);
    this.params.onChange(this.params);
  }

}
