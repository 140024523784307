import {
  IFunctionCategoryDetail,
  FunctionCategoryDetail,
} from "./function-category-detail";

export interface IOrganisationSettingResponse {
  functionCategoryDetail: IFunctionCategoryDetail[];
}
export class OrganisationSettingResponse
  implements IOrganisationSettingResponse {
  functionCategoryDetail: FunctionCategoryDetail[];
}
export interface IListCartDetailsResponse {
  cartId: number;
  functionPackageId: number;
  functionPackageName: string;

  userId: number;
  organisationId: number;

  billingStatus: boolean;
}
export interface IListCartDetails {
  listCartDetails: IListCartDetailsResponse[];
}

export interface ICartDetailsResponse {
  CartId: number;
  FunctionPackageId: number;
  FunctionPackageName: string;

  UserId: number;
  OrganisationId: number;

  BillingStatus: boolean;
}
export class CartDetailsResponse implements ICartDetailsResponse {
  CartId: number;
  FunctionPackageId: number;
  FunctionPackageName: string;
  UserId: number;
  OrganisationId: number;
  BillingStatus: boolean;
}
export interface ICartDetailsRequest {
  FunctionPackageId: number;
  FunctionPackageName: string;

  UserId: number;
  OrganisationId: number;

  BillingStatus: boolean;
  SubscriptionType: number;
}
export class CartDetailsRequest implements ICartDetailsRequest {
  FunctionPackageId: number;
  FunctionPackageName: string;
  UserId: number;
  OrganisationId: number;
  BillingStatus: boolean;
  SubscriptionType: number;
  constructor(
    UserId: number,
    OrganisationId: number,
    BillingStatus: boolean,
    FunctionPackageId: number,
    FunctionPackageName: string
  ) {
    this.UserId = UserId;
    this.OrganisationId = OrganisationId;
    this.BillingStatus = Boolean(BillingStatus);
    this.FunctionPackageId = Number(FunctionPackageId);
    this.FunctionPackageName = FunctionPackageName;
  }
}
export interface IGetCartDetailsRequest {
  UserId: number;
  OrganisationId: number;
  FunctionPackageId: number;
  BillingStatus: boolean;
}
export class GetCartDetailsRequest implements IGetCartDetailsRequest {
  UserId: number;
  OrganisationId: number;
  BillingStatus: boolean;
  FunctionPackageId: number;
  constructor(UserId: number, OrganisationId: number) {
    this.UserId = UserId;
    this.OrganisationId = OrganisationId;
  }
}
export interface IFunctionDefaultCurrency {
  FunctionId: number;
  CurrencyId: number;
}
export class FunctionDefaultCurrency implements IFunctionDefaultCurrency {
  FunctionId: number;
  CurrencyId: number;

  constructor(FunctionId: number, CurrencyId: number) {
    this.FunctionId = FunctionId;
    this.CurrencyId = CurrencyId;
  }
}
export interface IRemoveFunction {
  FunctionId: number;
  UserId: number;
  OrgId: number;
}
export class RemoveFunction implements IRemoveFunction {
  FunctionId: number;
  UserId: number;
  OrgId: number;

  constructor(FunctionId: number, UserId: number, OrgId: number) {
    this.FunctionId = FunctionId;
    this.UserId = UserId;
    this.OrgId = OrgId;
  }
}
export interface IOrgDependentFunctionDetails {
  orgDependentFunctionList: IOrgDependentFunctionResponse[];
}
export interface IOrgDependentFunctionResponse {
  orgDependentFunctionID: number;
  functionID: number;
  dependentFunctionID: number;
  createdBy: number;
  isSubscribed: boolean;
}
export interface IGetOrgDependentFunctionListRequest {
  FunctionId: string;
  OrganisationId: number;
}
export class GetOrgDependentFunctionListRequest
  implements IGetOrgDependentFunctionListRequest {
  FunctionId: string;
  OrganisationId: number;
  constructor(functionId: string, OrganisationId: number) {
    this.FunctionId = functionId;
    this.OrganisationId = OrganisationId;
  }
}

export interface IPurchaseFunctionListDetails {
  orgPaidFunctionList: IPurchaseFunctionListResponse[];
}
export interface IPurchaseFunctionListResponse {
  paidFunctions: IPurchaseFunctioninnerList[];
}
export interface IPurchaseFunctioninnerList {
  paidFunctions: IPurchaseFunctioninnerList[];
}
export interface IPurchaseFunctioninnerList {
  functionId: number;
}
export interface IPurchaseFunctionListRequest {
  UserId: number;
  OrgId: number;
}
export class PurchaseFunctionListRequest
  implements IPurchaseFunctionListRequest {
  UserId: number;
  OrgId: number;
  constructor(UserId: number, OrgId: number) {
    this.UserId = UserId;
    this.OrgId = OrgId;
  }
}
export class DropdownItems {
  id: number;
  name: string;
}

export interface IFunctionPackageCurrencyMappingResponse {
  functionPackageCurrencyMappingId: number;
  countryId: number;
  currencyId: number;
  commonCurrencyId: number;
  displayCurrencyId: number;
  IsActive: boolean;
  createdBy: number;
  createdOn: Date;
  updatedBy: number;
  updatedOn: Date;
}

export class FunctionPackageCurrencyMappingResponse
  implements IFunctionPackageCurrencyMappingResponse {
  functionPackageCurrencyMappingId: number;
  countryId: number;
  currencyId: number;
  commonCurrencyId: number;
  displayCurrencyId: number;
  IsActive: boolean;
  createdBy: number;
  createdOn: Date;
  updatedBy: number;
  updatedOn: Date;
}
export class RequestGetPreferences {
  orgId: number;
}
