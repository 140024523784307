import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-toggle-renderer',
  templateUrl: './toggle-renderer.component.html'
})
export class ToggleRendererComponent  implements ICellRendererAngularComp {
  informationId:number;
  isEnabled: boolean;  
  params: ICellRendererParams;
  isHidden: boolean = false;
  showText: boolean=false;
  showSwitchText:string = "";
  toggleAlignClass:string = "toggle-renderer";
  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      if(params.data.alignClass)
      {
        this.toggleAlignClass = params.data.alignClass;
      }
      if(params.data.informationId)
      {
        this.isEnabled = params.data.isSwitchedOn;     
        this.informationId = params.data.informationId; 
        this.isHidden = params.data.isHidden;
        this.showText = params.data.showText;
        this.showSwitchText = params.data.showSwitchText;
      }
      else if(params.data.vehicleId)
      {
        this.isEnabled = params.data.isDefault;     
        this.informationId = params.data.vehicleId;
      }
      else if(params.data.userHomeOfficeSetupId)
      {
        this.isEnabled = params.data.isWorkFromHome;
        this.informationId = params.data.userHomeOfficeSetupId;
      }
      else if(params.data.orgExpenseCategoryId)
      {
        if(params.data.counterApproverRule)
            this.isEnabled = params.data.counterApproverRule;
        if(params.data.isCFPReporting)
            this.isEnabled = params.data.isCFPReporting;
        this.informationId = params.data.orgExpenseCategoryId;
      }
      else if(params.data.isSwitchedOn)
      {
        this.isEnabled = params.data.isSwitchedOn;  
        this.informationId = params.data.columnSequence; 
      }
      
    }
    else{
      this.isHidden = true;
    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    this.isEnabled = event.target.checked;
    this.params.setValue(this.isEnabled);
  }

}
