<div class="overLayDiv active"></div>
<div id="rightSideBar" *ngIf="inputData">
    <div class="rightSidePanel active" [ngClass]="{ 'w-40pc': inputData.isSmallPanel }">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2>{{inputData.title}}</h2>
                        </div>

                        <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="closePanelData()">
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ "close" | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
            <div *ngIf="inputData && inputData.isGridPanel">
                <div class="block heightCalc-16" >    
                    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-17 padding-top-10">                              
                           <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems"
                           (onCellClick)="fnOnGridCellClick($event)" >
                           </app-grid>                
                      </div>     
                                  
               </div>
                <div class="block mt-2">
                    <ul class="info-panel">
                        <li class="border-bottom">
                            <p>
                                <span>
                                    <i aria-hidden="true" class="fas fa-exclamation-circle text-mustard align-middle"></i>
                                </span>
                                <span class="pl-2 ">{{ "emp_imp_exp_msg11" | translate }}
                                    <span (click)="goToManEmp()"
                                     class="text-logo-blue text-underline cursor-pointer text-logo-blue">
                                     {{ "label_manageemp" | translate }}
                                    </span>  {{ "emp_imp_exp_msg12" | translate }}</span> 
                            </p>
                        </li>
                       
                    </ul>
                    </div>
            </div>
            
            <div class="block padding-bottom-30 padding-top-10" *ngIf="inputData && !inputData.isGridPanel">
                <div class="row">
                    <ul class="mt-0 w-100pc ml-0" *ngFor="let item of inputData.data">
                        <li class="w-100pc position-relative mt-3">
                            <span class="d-block">
                                <i [ngClass]="item.icon" *ngIf="item.icon"></i>
                                <span [innerHTML]="sanitizerHtml(item.name)"></span>
                            </span>
                            <span class="position-absolute right-0 top-0px" *ngIf="item.isButtonVisible">
                                <button class="btn btn-primary f-11"
                                    (click)="onSubmitPanel(item)">{{item.buttonTitle}}</button>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
