<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-60pc active" style="z-index:99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{'text_associate' | translate:param}}</h2>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnClosePanel()" class="padding-right-1">
                                    <Button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </Button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-lg-12  margin-top-10 no-padding-right-left scrollbar-visible overflow-auto heightCalc-11">

                <div class="block" *ngIf="(claimantAssociatedExpenseList).length === 0">
                    <p class="text-center noDataAvailable disabled opacity-60">
                        {{ "no_data_available" | translate: param }}</p>
                </div>

                <div class="block padding-top-30" *ngFor="let item of claimantAssociatedExpenseList">
                    <table class="table border-bottom">
                        <thead>
                            <tr>
                                <th width="25%">
                                    <span>
                                        <input type="radio" name="terms" class="svg-20 margin-right-10 align-middle"
                                            [(ngModel)]="selectedExpenseId" [value]="item.expenseId"
                                            (change)="fnOnExpenseChange(item.expenseId.expenseId);this.hasFormChanges= true;">
                                    </span>

                                    {{'text_category' | translate:param}}
                                </th>
                                <th width="12%">{{'ExpenseDate' | translate:param}}</th>
                                <th class="text-right padding-right-30" width="18%">{{'text_amount' | translate:param}}
                                    ({{ this.fnGetCurrencyCode(item.currencyId)}})</th>
                                <th width="25%">{{'text_supplier' | translate:param}}</th>
                                <th width="25%">{{'text_notes' | translate:param}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="25%" class="text-ellipsis">{{this.inputData.expenseCategoryName}}</td>
                                <td class="text-ellipsis"> {{ this.datePipe.transform(item.expenseDate) }}</td>
                                <td class="text-right padding-right-30 text-ellipsis">
                                    {{ item.amount | number: "1.2-2" | noComma }}
                                </td>
                                <td class="text-ellipsis">{{item.supplier}}</td>
                                <td class="text-left text-ellipsis">{{item.notes}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15"
                *ngIf="(claimantAssociatedExpenseList).length > 0">
                <span class="margin-right-0">
                    <button type="button" class="btn btn-primary"
                        [ngClass]="{ disabled: !selectedExpenseId || !this.hasFormChanges }"
                        (click)="fnSelectAssociateCreditCardExpense()">{{'text_associate' | translate:param}}</button>
                </span>
            </div>
        </div>
    </div>
</div>
