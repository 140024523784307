import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'status-data-cell',
  templateUrl: './status-data-renderer.component.html'
})
export class StatusDataRenderComponent implements ICellRendererAngularComp {
  public data: any;

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.data = params.data;
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }
}
