import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'card-number-cell',
    templateUrl: './card-number-renderer.component.html'
})
export class CardNumberRendererComponent implements ICellRendererAngularComp {
    public cardNumber: number;

    constructor() {
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    initilize(params: ICellRendererParams) {
        this.cardNumber = this.getCardNoForDisplay(params.getValue());
    }

    getCardNoForDisplay(cardNo) {
        if (cardNo != null && cardNo != "") {
            var cardWithX = "XXXXXXXXXXXXXXXX" + cardNo;
            cardNo = cardWithX.substring(cardWithX.length - 16, cardWithX.length);
        }
        return cardNo;
    }


}
