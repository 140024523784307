import {
  Component,
  OnInit,
  Inject,
  LOCALE_ID,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";

import { addDays } from "date-fns/addDays";
import { subHours } from "date-fns/subHours";
import {
  DAYS_IN_WEEK,
  SchedulerViewDay,
  SchedulerViewHour,
  SchedulerViewHourSegment,
  CalendarSchedulerEvent,
  CalendarSchedulerEventAction,
  startOfPeriod,
  endOfPeriod,
  addPeriod,
  subPeriod,
  SchedulerDateFormatter,
  SchedulerEventTimesChangedEvent,
} from "angular-calendar-scheduler";
import {
  CalendarView,
  CalendarDateFormatter,
  DateAdapter,
} from "angular-calendar";
import { AppService } from "../_services/common/app.service";
import { Subject } from "rxjs/internal/Subject";
import { HttpClient } from "@angular/common/http";
import { startOfHour } from "date-fns/startOfHour";
import { setHours } from "date-fns/setHours";
import { addHours } from "date-fns/addHours";

@Component({
  selector: "calander-scheduler",
  templateUrl: "./calander-scheduler.component.html",
  styleUrls: ["./calander-scheduler.component.scss"],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: SchedulerDateFormatter,
    },
  ],
})
export class CalanderSchedulerComponent implements OnInit {
  title = "Angular Calendar Scheduler Demo";

  CalendarView = CalendarView;

  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = 1;
  refresh: Subject<any> = new Subject();
  locale: string = "en";
  hourSegments: number = 2;
  weekStartsOn: number = 1;
  startsWithToday: boolean = true;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = []; // [0];
  dayStartHour: number = 6;
  dayEndHour: number = 22;

  minDate: Date = new Date('2024-01-01');
  maxDate: Date = new Date('2024-12-31');
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;

  actions: CalendarSchedulerEventAction[] = [
    {
      when: "enabled",
      label:
        '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: "Delete",
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log("Pressed action 'Delete' on event " + event.id);
      },
    },
    {
      when: "cancelled",
      label:
        '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
      title: "Restore",
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log("Pressed action 'Restore' on event " + event.id);
      },
    },
  ];

  events: CalendarSchedulerEvent[];

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustViewDays();
  }

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private appService: AppService,
    private dateAdapter: DateAdapter,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.locale = locale;

    this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
      segment.isDisabled = !this.isDateValid(segment.date);
    }).bind(this);

    this.eventModifier = ((event: CalendarSchedulerEvent): void => {
      event.isDisabled = !this.isDateValid(event.start);
    }).bind(this);

    this.adjustViewDays();
    this.dateOrViewChanged();
  }

  ngOnInit(): void {
    // this.appService
    //   .getEvents(this.actions)
    //   .then((events: CalendarSchedulerEvent[]) => {
    //     this.events = events;
    //   });
    // if (localStorage.getItem("calanderEvents")) {
    //   this.events = JSON.parse(localStorage.getItem("calanderEvents"));
    // } else {
    //   this.getCalendar();
    // }

    this.getCalendar();
  }

  getCalendar() {
    // set start and end dates to past 14 days in graph API calendar endpoint query.
    let startDate = new Date();

    // const startMonthNum = startDate.getMonth() + 1;

    // let startDateString =
    //   startDate.getFullYear().toString() +
    //   "-" +
    //   startMonthNum.toString().padStart(2, "0") +
    //   "-" +
    //   startDate.getDate().toString().padStart(2, "0");

    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    // const endMonthNum = endDate.getMonth() + 1;
    // let endDateString =
    //   endDate.getFullYear().toString() +
    //   "-" +
    //   endMonthNum.toString().padStart(2, "0") +
    //   "-" +
    //   endDate.getDate().toString().padStart(2, "0");

    const calendarEndPointQuery =
      "?startdatetime=2024-02-01&enddatetime=2024-03-31&%24$orderby=start/dateTime%20DESC&%24top=1000";

    this.http
      .get(
        "https://graph.microsoft.com/v1.0/me/calendarview" +
          calendarEndPointQuery
      )
      .toPromise()
      .then((calendar) => {

        localStorage.setItem('calendarRawData', JSON.stringify(calendar));
        localStorage.removeItem("calanderEvents");
        this.events = [];

        for (let index = 0; index < calendar["value"].length; index++) {
          this.events.push(<CalendarSchedulerEvent>{
            id: (index + 1).toString(),
            // start: startOfHour(
            //   setHours(
            //     new Date(calendar["value"][index].start.dateTime+'Z'),
            //     new Date(calendar["value"][index].start.dateTime+'Z').getHours()
            //   )
            // ),

            start: new Date(calendar["value"][index].start.dateTime+'Z'),
            end: new Date(calendar["value"][index].end.dateTime+'Z'),
            // end: addHours(
            //   startOfHour(
            //     setHours(
            //       new Date(calendar["value"][index].start.dateTime+'Z'),
            //       new Date(calendar["value"][index].end.dateTime+'Z').getHours()
            //     )
            //   ),
            //   Math.abs(
            //     new Date(calendar["value"][index].start.dateTime+'Z').getTime() -
            //       new Date(calendar["value"][index].end.dateTime+'Z').getTime()
            //   ) / 3600000
            // ),
            title: calendar["value"][index].subject,
            content: calendar["value"][index].subject,//calendar["value"][index].body.content,
            color: { primary: "#FFFFFF", secondary: "#29b7e9" },
            actions: this.actions,
            isClickable: true,
            isDisabled: false,
          });
        }

        localStorage.setItem("calanderEvents", JSON.stringify(this.events));
        console.log(calendar);
        this.refresh.next(0);
        this.changeDetectorRef.detectChanges();
      });
  }

  adjustViewDays(): void {
    const currentWidth: number = window.innerWidth;
    if (currentWidth <= 450) {
      this.viewDays = 1;
    } else if (currentWidth <= 768) {
      this.viewDays = 3;
    } else {
      this.viewDays = 5;
    }
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(
        subPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          this.viewDate,
          1
        )
      );
      this.nextBtnDisabled = !this.isDateValid(
        addPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          this.viewDate,
          1
        )
      );
    } else {
      this.prevBtnDisabled = !this.isDateValid(
        endOfPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          subPeriod(
            this.dateAdapter,
            CalendarView.Day /*this.view*/,
            this.viewDate,
            1
          )
        )
      );
      this.nextBtnDisabled = !this.isDateValid(
        startOfPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          addPeriod(
            this.dateAdapter,
            CalendarView.Day /*this.view*/,
            this.viewDate,
            1
          )
        )
      );
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  private isDateValid(date: Date): boolean {
    return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {}

  hourClicked(hour: SchedulerViewHour): void {}

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {}

  eventClicked(action: string, event: CalendarSchedulerEvent): void {}

  eventTimesChanged({
    event,
    newStart,
    newEnd,
    type,
  }: SchedulerEventTimesChangedEvent): void {
    const ev: CalendarSchedulerEvent = this.events.find(
      (e) => e.id === event.id
    );
    ev.start = newStart;
    ev.end = newEnd;
    this.refresh.next(0);
  }
}
