import { OnInit, Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit,ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Userresponsedto } from "src/app/_models/user/userresponsedto";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/_services";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { ApiRequest, IApiresponse, ExpenseStatusEnum } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { AssociateCreditCardExpenseInputModel, AssociateCreditCardExpenseOutputModel, ExpenseCurrency } from "src/app/_models/claimant-on-web/claimant-on-web";
import { environment } from "src/environments/environment";
import { CreditCardService } from "src/app/_services/credit-card/credit-card.service";
import {
    IClaimantAssociatedExpenseListResponse, IClaimantAssociatedExpenseListRequest, ClaimantAssociatedExpenseListRequest, IAssociatedExpenseDetail
} from "src/app/_models/credit-card/credit-card";

import { MonthFormatPipe, DateFormatPipe } from "src/app/_helpers/pipe.module";
import { CurrencyService } from "src/app/_services/claimant/currency.service";

@Component({
    selector: "associate-credit-card-expense-panel",
    templateUrl: "./associate-credit-card-expense-panel.component.html"
})

export class AssociateCreditCardExpensePanelComponent extends BaseComponent implements OnInit {

    @Input()
    inputData: AssociateCreditCardExpenseInputModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<AssociateCreditCardExpenseOutputModel>();

    currentUserSubscription: Subscription;
    userActionEventSubscription: Subscription;
    currentUser: Userresponsedto;
    claims: Claims;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    hasFormChanges: boolean = false;

    claimantAssociatedExpenseList = <IAssociatedExpenseDetail[]>[];
    selectedExpenseId: number = 0;
    currencytList = <ExpenseCurrency[]>[];

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private alertService: AlertService,
        public fb: FormBuilder,
        private creditCardService: CreditCardService,
        private datePipe: DateFormatPipe,
        private currencyService: CurrencyService,
        private cdr:ChangeDetectorRef
    ) {
        super();
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
            (user) => {
                this.currentUser = user;
            }
        );
        this.observeUnsavedChangesAlerts();
    }
    ngOnInit(): void {
        this.getCurrrencyList();
        this.fnGetClaimantAssociatedExpenseList();
        this.fnSeAssociateCreditCardExpenseInputValues();
    }

    canDeactivate(): boolean {
        return !this.hasFormChanges;
    }

    observeUnsavedChangesAlerts() {
        var currentContext = this;
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetClaimantAssociatedExpenseList() {
        let request = new ApiRequest<IClaimantAssociatedExpenseListRequest>("GetPreApprovalTagList", environment.applicationVersion, environment.requestId);
        let requestInfo = new ClaimantAssociatedExpenseListRequest(this.claims.OrgId, this.inputData.claimantId, this.inputData.orgExpenseCategoryId, this.inputData.supplier);
        request.requestInfo = requestInfo;

        this.creditCardService.GetClaimantAssociatedExpenseList(request)
            .pipe(first())
            .subscribe((response: IApiresponse<IClaimantAssociatedExpenseListResponse>) => {
                if (response.responseInfo != null) {
                    this.claimantAssociatedExpenseList = response.responseInfo.expenseList;
                    this.cdr.detectChanges();
                }
            });
    }

    fnOnExpenseChange(expenseId: number) {
        if (expenseId) {
            this.selectedExpenseId = expenseId;
        }
    }


    fnSelectAssociateCreditCardExpense() {
        if (this.selectedExpenseId && this.selectedExpenseId > 0) {
            let selectAssociateCreditCardExpenseOutputModel = new AssociateCreditCardExpenseOutputModel();
            let expenseDetails = this.claimantAssociatedExpenseList.find(item => item.expenseId == this.selectedExpenseId);
            if (expenseDetails) {
                selectAssociateCreditCardExpenseOutputModel.expenseId = expenseDetails.expenseId;
                selectAssociateCreditCardExpenseOutputModel.expenseDate = expenseDetails.expenseDate;
                selectAssociateCreditCardExpenseOutputModel.amount = expenseDetails.amount;
                selectAssociateCreditCardExpenseOutputModel.currencyId = expenseDetails.currencyId;
                selectAssociateCreditCardExpenseOutputModel.currencyCode = this.fnGetCurrencyCode(expenseDetails.currencyId);
                selectAssociateCreditCardExpenseOutputModel.supplier = expenseDetails.supplier;
            }
            this.selectItemEvent.emit(selectAssociateCreditCardExpenseOutputModel);
        }
    }

    fnSeAssociateCreditCardExpenseInputValues() {
        if (this.inputData) {
            this.selectedExpenseId = this.inputData.expenseId;
        }
    }
    getCurrrencyList() {
      this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.currencytList = data;
                }
            });
    }
    fnGetCurrencyCode(currencyId: number) {
        let currencyCode = this.claims.CurrentOrganisationCurrencyCode ? this.claims.CurrentOrganisationCurrencyCode : "";
        if (currencyId && this.currencytList.length > 0) {
            let currency = this.currencytList.find(item => item.currencyId == currencyId);
            if (currency) {
                currencyCode = currency.currencyCode;
            }
        }
        return currencyCode;
    }
}
