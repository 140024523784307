<div class="overLayDiv active" *ngIf="isOverlayRequired"></div>
<div id="rightSideBar">
    <div class="rightSidePanel d-flex flex-column w-55pc" style="z-index: 99999;">
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
    <h2> {{ "contaner_existing_reports" | translate }}</h2>
    <div>
        <button class="btn eod-btn-primary height-38px mr-2" (click)="fnShowAddContainerPanel(null,false)">
            {{'contaner_create_new_report' | translate}}
        </button>

        <button class="btn eod-btn-primary height-38px mr-2" 
        [ngClass]="{ disabled: !hasFormChanges}" (click)="fnOnSubmitContainer()">
            {{'label_save' | translate}}
        </button>

        <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
            {{ 'label_close' | translate }}
        </button>
    </div>
    </header>

        <div class="block mt-0">
            <h6 class="text-charcoal-black fw-700 f-14 mb-2">
                {{ "select_existing_report" | translate }}
            </h6>
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-8">
                <!----------------------Start Grid--------------------------->
                <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems" 
                 (onCellClick)="fnOnGridCellClick($event)"></app-grid>      
                <!----------------------End Grid--------------------------->
            </div>
        </div>
    </div>
</div>

<app-alert-panel *ngIf="showDeleteConfirmationAlert" [data]="deleteConfirmationAlertModel"
    (callbackEvent)="fnDeleteConfirmationAlertReceiver($event)">
</app-alert-panel>

<add-container-panel *ngIf="showAddContainerPanel"  [inputData]="addContainerInputModel"
(closePanelEvent)="fnCloseAddContainerPanel($event)" (submitPanelEvent)="fnSubmitAddContainerPanel($event)"></add-container-panel>