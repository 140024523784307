import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'field-value-cell',
    templateUrl: './field-value-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class FieldValueRendererComponent implements ICellRendererAngularComp {

    text: string = '';
    class: string = '';
    
    constructor() {
    }

    initilize(params: ICellRendererParams) {
        let value = params.getValue();
        if (value && params.colDef.headerComponentParams) {
            let item = params.colDef.headerComponentParams.find(item => item.value == value);
            if (item) {
                this.class = item.class;
                this.text = item.text;
            }
        }
        else{
            this.class = "";
            this.text = "";  
        }

        
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
}