import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { JourneyTypeEnum, MileageRateTypeEnum, MileageUOMEnum,BaseCategoryTypeEnum, ProjectBudgetEntityType } from 'src/app/_models';
import { Claims } from 'src/app/_models/common/claims';
import { AuthenticationService } from 'src/app/_services';
import { ClaimantOnWebService } from 'src/app/_services/claimant-on-web/claimant-on-web.service';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { ViewReceiptComponent } from '../view-receipt/view-receipt.component';
import { CurrencyService } from "src/app/_services/claimant/currency.service";
import { ExpenseCurrency } from '../../../_models/claimant-on-web/claimant-on-web';
@Component({
  selector: 'app-mileage-detail',
  templateUrl: './mileage-detail.component.html',
  //styleUrls: ['./mileage-detail.component.scss']
})
export class MileageDetailComponent implements OnInit, OnDestroy {
  @ViewChild('viewReceiptComponent') viewReceiptComponent: ViewReceiptComponent;
  isPanelDisplay: boolean = false;
  @Input() expenseDetailResponse: any;
  @Input() showMapSectionOnly: boolean = false;
  mileageRate: string;
  claims: Claims;
  detourMiles: any;
  triangulationMiles: any;
  milesTravelled: any;
  amount: any;
  distance: any;
  showViewTagPanel: boolean = false;
  tagList: any = [];
  currency: string;
  mileageLabel: string;
  isAdvancedMileageEnabled: boolean;
  resizeSubscription$: Subscription
  claimsSubscription: Subscription;
  constructor(private claimsService: ClaimsService, private translate: TranslateService,
    private authenticationService: AuthenticationService, private claimantOnWebService: ClaimantOnWebService,
    private currencyService: CurrencyService) {
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      this.currency = this.claims.CurrentOrganisationCurrencyCode;
    });

  }
  currencytList: ExpenseCurrency[];
  fromTextLength: number;
  toTextLength: number;
  origin: any;
  destination: any;
  receiptLat: number;
  receiptLong: number;
  latLongArray: any;
  isCFPReporting:boolean;
  isMileageCategory: boolean;
  carbonEmissionLabel: string;
  ngOnInit() {
    const resizeObservable$ = fromEvent(window, 'resize')
    this.getTextLength();
    this.resizeSubscription$ = resizeObservable$.subscribe(evt => {
      this.getTextLength();
    })
    this.getOrganisationData();
    this.getCurrrencyList();
  }
  getTextLength() {
    if (window.innerWidth >= 1535) {
      this.toTextLength = 22;
      this.fromTextLength = 52;
    }
    else {
      this.toTextLength = 18;
      this.fromTextLength = 45;
    }
  }
  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
    this.claimsSubscription.unsubscribe();
  }
  fnShowMileageDetailPanel(expenseDetailResponse) {
    this.expenseDetailResponse = expenseDetailResponse;
    this.isCFPReporting = this.expenseDetailResponse.isCFPReporting;
    this.isMileageCategory = this.expenseDetailResponse.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle;
    this.mileageLabel = (this.expenseDetailResponse.mileage.mileageUOM == MileageUOMEnum.Kilometre ? "km" : "m");
    this.carbonEmissionLabel = this.translate.instant('text_carbonemission');
    this.fnGetMileageRate();
    this.isPanelDisplay = true;
  }
  fnCloseMileageDetailPanel() {
    this.isPanelDisplay = false;
    //this.closePanelEvent.emit(true);
  }

  getCurrrencyList() {
    this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
        .subscribe((data: any) => {
            if (data && data.length > 0) {
                this.currencytList = data;
            }
        });
  }

 getCurrencyName(currencyId: number) {
    if (this.currencytList) {
        return this.currencytList.filter(a => a.currencyId == currencyId).map(function (b) { return b.currencyCode })[0];
    }
 }

  fnGetMileageRate() {
    let mileageLabel = (this.expenseDetailResponse.mileage.mileageUOM == MileageUOMEnum.Kilometre ? "km" : "m");
    this.mileageRate = this.expenseDetailResponse.mileage.mileageRate.toFixed(2)
      + " " + this.getCurrencyName(this.expenseDetailResponse.currencyId) + " / " + mileageLabel;

    if (this.expenseDetailResponse.mileage.mileageRateInfo != null) {
      let str = "";
      let length = 1;
      let arraylength = this.expenseDetailResponse.mileage.mileageRateInfo.length;
      this.expenseDetailResponse.mileage.mileageRateInfo.forEach(itemMileage => {
        if (length == arraylength) { str = str + itemMileage.mileageRate.toFixed(2) + " "; }
        else { str = str + itemMileage.mileageRate.toFixed(2) + " / " + " "; }
        ++length;
      });
      this.mileageRate = str + this.getCurrencyName(this.expenseDetailResponse.currencyId)  + " / " + mileageLabel;
    }
  }
  closeMultipleJourneyGroups(rowIndex) {
    const elements = document.querySelectorAll("[aria-expanded='true']");
    elements.forEach(element => {
      const multipleJourneyroupHeader = element.id;
      if ('multipleJourney' + rowIndex != multipleJourneyroupHeader) {
        if (element.id.includes('multipleJourney')) {
          (element as HTMLElement).click();
        }
      }
    });
  }
  fnMapData(isWholeJournew, sequence, expenseDetailResponse) {
    if (expenseDetailResponse != null) {
      this.expenseDetailResponse = expenseDetailResponse;
      this.isMileageCategory = this.expenseDetailResponse.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle;
    }
    if (this.expenseDetailResponse.mileage.journeyType == JourneyTypeEnum.Return) {
      this.fnMapForReturnJourney();
    }
    else {
      let mileageArray = [];
      this.fnGetMileageRate();
      let mileageLabel = (this.expenseDetailResponse.mileage.mileageUOM == MileageUOMEnum.Kilometre ? "km" : "m");
      if (isWholeJournew) {
        this.amount = this.expenseDetailResponse.spentAmount;
        this.detourMiles = this.expenseDetailResponse.mileage.detour.toFixed(2) + " " + mileageLabel;
        this.triangulationMiles = this.expenseDetailResponse.mileage.totalTriangulatedDistance.toFixed(2) + " " + mileageLabel;
        this.milesTravelled = this.expenseDetailResponse.mileage.totalMilesTraveled.toFixed(2) + " " + mileageLabel;
        this.distance = this.expenseDetailResponse.mileage.totalDistance.toFixed(2) + " " + mileageLabel;
        mileageArray = this.expenseDetailResponse.mileage.sourceDestinationInformation;
        this.viewReceiptComponent.fnshowMileagemap(mileageArray, this.expenseDetailResponse.mileage.journeyType, true,  this.getCurrencyName(this.expenseDetailResponse.currencyId) );
      }
      else {
        let sourceDestinatination = this.expenseDetailResponse.mileage.sourceDestinationInformation
          .find(a => a.sequence == sequence);
        this.amount = sourceDestinatination.amount;
        this.detourMiles = sourceDestinatination.detour.toFixed(2) + " " + mileageLabel;
        this.triangulationMiles = "0.00" + " " + mileageLabel;
        this.milesTravelled = sourceDestinatination.milesTraveled.toFixed(2) + " " + mileageLabel;
        this.distance = sourceDestinatination.distance.toFixed(2) + " " + mileageLabel;
        sourceDestinatination.mileageLabel = mileageLabel;
        mileageArray.push(sourceDestinatination);
        this.viewReceiptComponent.fnshowMileagemap(mileageArray, this.expenseDetailResponse.mileage.journeyType, false, this.getCurrencyName(this.expenseDetailResponse.currencyId) );
      }
    }


  }
  hasReturnJourney: boolean = false;
  mileageArray: any;
  fnMapForReturnJourney() {
    this.fnGetMileageRate();
    let mileageLabel = (this.expenseDetailResponse.mileage.mileageUOM == MileageUOMEnum.Kilometre ? "km" : "m");
    this.amount = this.expenseDetailResponse.spentAmount;
    this.hasReturnJourney = this.expenseDetailResponse.mileage.journeyType == JourneyTypeEnum.Return ? true : false;
    this.detourMiles = this.expenseDetailResponse.mileage.detour.toFixed(2) + " " + mileageLabel;
    this.triangulationMiles = this.expenseDetailResponse.mileage.totalTriangulatedDistance.toFixed(2) + " " + mileageLabel;
    this.milesTravelled = this.expenseDetailResponse.mileage.totalMilesTraveled.toFixed(2) + " " + mileageLabel;
    this.distance = this.expenseDetailResponse.mileage.totalDistance.toFixed(2) + " " + mileageLabel;
    this.origin = {
      lat: parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[0].latitude.toString()),
      lng: parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[0].longitude.toString())
    };
    this.destination = {
      lat: parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route.length - 1].latitude.toString()),
      lng: parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route.length - 1].longitude.toString())
    };
    this.receiptLat = parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[0].latitude.toString());
    this.receiptLong = parseFloat(this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route[0].longitude.toString());
    this.latLongArray = this.expenseDetailResponse.mileage.sourceDestinationInformation[0].route;
    this.mileageArray = [];
    this.mileageArray.push(this.expenseDetailResponse.mileage.sourceDestinationInformation[0])
    setTimeout(() => {
      this.viewReceiptComponent.fnshowMileage(this.getCurrencyName(this.expenseDetailResponse.currencyId));
    }, 100);
  }

  getOrganisationData() {
    this.authenticationService
      .organisationdetails(this.claims.UserId.toString(), this.claims.LoginId, this.claims.Token)
      .subscribe(orgData => {
        if (orgData.statusCode == 0) {
          this.isAdvancedMileageEnabled = orgData.responseInfo[0].rateType > MileageRateTypeEnum.FreeForLife ? true : false;
        }
      });
  }
  fnShowViewTagPanel(sequence) {
    this.tagList = [];
    let sourceDestinatination = this.expenseDetailResponse.mileage.sourceDestinationInformation
      .find(a => a.sequence == sequence);
    if (sourceDestinatination.tagList != null || sourceDestinatination.tagList.length > 0) {
      sourceDestinatination.tagList.forEach(item => {
        this.tagList.push(item);
      });
      this.showViewTagPanel = true;
    }

  }

  fnBindMileageSequence(item) {
    let seqObjectArray = [];
    let milesTraveled = item.milesTraveled.toFixed(2);
    seqObjectArray.push({
      name: this.translate.instant("miles_travelled"),
      value: milesTraveled.toString() + " " + this.mileageLabel,
      class: 'w-100pc d-block lH-25',
      valueClass: 'w-100pc d-block text-right lH-25'
    });

    if (this.isAdvancedMileageEnabled && this.isMileageCategory) {
      if (item.allowanceId) {
        seqObjectArray.push({
          name: 'B. ' + this.translate.instant("passenger_load"),
          value: this.translate.instant(this.fnSetAllowanceText(item.allowanceId)),
          class: 'w-100pc d-block lH-25',
          valueClass: 'w-100pc d-block text-right lH-25'
        });
        let detour = item.detour.toFixed(2);
        seqObjectArray.push({
          name: 'C. ' + this.translate.instant("detour_miles"),
          value: detour.toString() + " " + this.mileageLabel,
          class: 'w-100pc d-block lH-25 color-green',
          valueClass: 'w-100pc d-block text-right lH-25 color-green'
        });
        let totalMiles = Number(detour) + Number(milesTraveled);
        seqObjectArray.push({
          name: this.translate.instant("total_miles_claimed_a_c"),
          value: '<span class="border-double-single"><span class="text-logo-blue"> ' + totalMiles.toFixed(2).toString() + ' ' + this.mileageLabel + '</span></span>',
          class: 'w-100pc d-block lH-25 fw-700 text-logo-blue padding-TB-5',
          valueClass: 'w-100pc d-block text-right lH-25 padding-TB-5 fw-700'
        });
        if(this.isCFPReporting)
        seqObjectArray.push({
          name: 'D. ' + this.translate.instant("total_carbon_emission"),
          value: item.carbonFootprint + " " + this.carbonEmissionLabel,
          class: 'w-100pc d-block lH-25',
          valueClass: 'w-100pc d-block text-right lH-25'
        });
      }
      else {
        let detour = item.detour.toFixed(2);
        seqObjectArray.push({
          name: 'B. ' + this.translate.instant("detour_miles"),
          value: detour.toString() + " " + this.mileageLabel,
          class: 'w-100pc d-block lH-25 color-green',
          valueClass: 'w-100pc d-block text-right lH-25 color-green'
        });
        let totalMiles = Number(detour) + Number(milesTraveled);
        seqObjectArray.push({
          name: this.translate.instant("total_miles_claimed"),
          value: '<span class="border-double-single"><span class="text-logo-blue"> ' + totalMiles.toFixed(2).toString() + ' ' + this.mileageLabel + '</span></span>',
          class: 'w-100pc d-block lH-25 fw-700 text-logo-blue padding-TB-5',
          valueClass: 'w-100pc d-block text-right lH-25 padding-TB-5 fw-700'
        });

        if(this.isCFPReporting)
        seqObjectArray.push({
          name: 'C. ' + this.translate.instant("total_carbon_emission"),
          value: item.carbonFootprint + " " + this.carbonEmissionLabel,
          class: 'w-100pc d-block lH-25',
          valueClass: 'w-100pc d-block text-right lH-25'
        });
      }
    }

    if(!this.isMileageCategory && this.isCFPReporting)
    seqObjectArray.push({
      name: 'B. ' + this.translate.instant("total_carbon_emission"),
      value: item.carbonFootprint + " " + this.carbonEmissionLabel,
      class: 'w-100pc d-block lH-25',
      valueClass: 'w-100pc d-block text-right lH-25'
    });

    return seqObjectArray;

  }

  fnSetAllowanceText(allowanceId) {
    return this.claimantOnWebService.fnSetAllowanceText(true, allowanceId)
  }
}
