import { OnInit, Component, EventEmitter, Input, Output, AfterViewInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ViewTagsInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "view-tag-panel",
    templateUrl: "./view-tag-panel.component.html"
})
export class ViewTagPanelComponent implements OnInit, AfterViewInit {
    @Input()
    inputData: ViewTagsInputModel;
    @Output() closePanelEvent = new EventEmitter();
    param: any;

    constructor(
        private translate: TranslateService,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() { }

    fnCloseViewTagComponentPanel() {
        this.closePanelEvent.emit(null);
    }
}
