import { Component, OnInit, ViewChild, Output,Input, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/_services";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { AlertService } from "src/app/alert-panel/alert.service";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { DynamicFormComponent } from "src/app/shared/forms/dynamic-form/dynamic-form.component";
import { DynamicFormService } from "src/app/shared/forms/dynamic-form/dynamic-form.service";
import { IManualVatDetail, ManualVatDetail} from 'src/app/_models/twostepapproval/manual-vat-detail';
import { ReceiptViewerComponent } from "src/app/shared/panels/receipt-viewer/receipt-viewer.component";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { ClaimantOnWebService } from 'src/app/_services/claimant-on-web/claimant-on-web.service';
import { DateFormatPipe, EODDateFormatPipe, TimeFormatPipe, TimeFormatPipeNew } from "src/app/_helpers/pipe.module";
import { MileageDetailComponent } from 'src/app/shared/panels/mileage-detail/mileage-detail.component';
import { IReceiptInfo, IResponsePolicyViolation, RequestPolicyViolation } from 'src/app/_models/twostepapproval/claimant-expense-detail';

@Component({
    selector: "duplicate-receipt-panel",
    templateUrl: "./duplicate-receipt-panel.component.html"
})

export class DuplicateReceiptPanelComponent implements OnInit {
    @Input() originalReceiptInfo: IReceiptInfo[] = [];
    @Input() duplicateReceiptInfo: IReceiptInfo[] = [];
    @Input() claimType: any;
    @Output() closePanelEvent = new EventEmitter<boolean>();

    claims: Claims;

    constructor(
        private authenticationService: AuthenticationService,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private alertService: AlertService,
        private claimantOnWebService: ClaimantOnWebService,
        public datepipe: EODDateFormatPipe,
        public dateFormatPipe: DateFormatPipe,
        public timeFormatPipeNew: TimeFormatPipeNew
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
    }

    ngOnInit(): void {
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

}
