<div class="overLayDiv active"></div>

<div id="rightSideBar">
  <div class="rightSidePanel active">
    <header>
      <div class="row">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
          <div class="row">
            <div
              class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
              <h2 *ngIf="!showCardExpiryNotification">
                {{ "payment_failed" | translate }}
              </h2>
              <h2 *ngIf="showCardExpiryNotification">
                {{ "reminder_credit_card_abount_to_expire" | translate }}
              </h2>
            </div>

            <!-- <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">

                                <li (click)="fnClosePanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ "close" | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div> -->
          </div>
        </div>
      </div>
    </header>

    <div class="block mt-2" *ngIf="orgGoogleInvoiceFailedRecord">
      <div class="row">
        <div class="col-md-12 px-0 border-bottom pb-3">
          <ul class="">
            <li class="">
              <p class="text-charcoal-black lH-28">
                <span
                  ><i
                    class="fas f-16 fa-exclamation-circle color-red align-middle"
                    aria-hidden="true"></i
                ></span>
                <strong
                  ><span class="padding-left-5 f-16 font-weight-bold">{{
                    "payment_failed_google_mapping_service" | translate
                  }}</span></strong
                >

                <br />
                <span class="padding-left-20">{{
                  "please_click_on_payment_button"
                    | translate
                }}</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-12 pt-3 justify-content-center d-flex">
          <button
            class="btn eod-btn-primary"
            [class.disabled]="submitted"
            (click)="fnPayGooglePackage()">
            {{ "label_payment" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="block mt-2" *ngIf="orgTransactionDownloadInvoiceFailedRecord">
      <div class="row">
        <div class="col-md-12 px-0 border-bottom pb-3">
          <ul class="">
            <li class="">
              <p class="text-charcoal-black lH-28">
                <span
                  ><i
                    class="fas f-16 fa-exclamation-circle color-red align-middle"
                    aria-hidden="true"></i
                ></span>
                <strong
                  ><span class="padding-left-5 f-16 font-weight-bold">{{
                    "Automatic Transaction download has failed" | translate
                  }}</span></strong
                >

                <br />
                <span class="padding-left-20">{{
                  "please_click_on_payment_button"
                    | translate
                }}</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-12 pt-3 justify-content-center d-flex">
          <button
            class="btn eod-btn-primary"
            [class.disabled]="submitted"
            (click)="fnPayAutomaticTransactionDownload()">
            {{ "label_payment" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="block mt-2" *ngIf="orgSubscriptionInvoiceFailedRecord">
      <div class="row">
        <div class="col-md-12 px-0 border-bottom pb-3">
          <ul class="">
            <li class="">
              <p class="text-charcoal-black lH-28">
                <span
                  ><i
                    class="fas f-16 fa-exclamation-circle color-red align-middle"
                    aria-hidden="true"></i
                ></span>
                <strong
                  ><span class="padding-left-5 f-16 font-weight-bold">{{
                    "payment_failed_for_subscription" | translate
                  }}</span></strong
                >

                <br />
                <span class="padding-left-20">{{
                  "please_click_on_payment_button"
                    | translate
                }}</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-12 pt-3 justify-content-center d-flex">
          <button
            class="btn eod-btn-primary"
            [class.disabled]="submitted"
            (click)="fnPaySubscription()">
            {{ "label_payment" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="block mt-2" *ngIf="showCardExpiryNotification">
      <div class="row">
        <div class="col-md-12 px-0 border-bottom pb-3">
          <ul class="">
            <li class="">
              <p class="text-charcoal-black lH-28">
                <span
                  ><i
                    class="fas f-16 fa-exclamation-circle color-red align-middle"
                    aria-hidden="true"></i
                ></span>
                <strong
                  ><span class="padding-left-5 f-16 font-weight-bold"
                    >{{"cc_expire_note_1" |translate}}
                    <span class="color-red f-16 font-weight-bold"
                      >{{"cc_expire_note_2" |translate}} {{ cardExpiryDays }} {{"cc_expire_note_3" |translate}}</span
                    ></span
                  ></strong
                >

                <br />
                <span class="padding-left-20">{{
                  "please_click_on_update_card"
                    | translate
                }}</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-12 pt-3 justify-content-center d-flex">
          <button
            class="btn eod-btn-primary"
            [class.disabled]="submitted"
            (click)="goToCardUpdate()">
            {{ "update_card" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
