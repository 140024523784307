

export interface IRequestGetCustomer {
    customerId: number;
}

export class RequestGetCustomer implements IRequestGetCustomer {
    customerId: number;
    constructor(customerId: number) {
        this.customerId = customerId;
    }
}

export interface ICustomerResponse {
    customerId: number;
    userId: number;
    organisationId: number;
    name: string;
    code1: string;
    code2: string;
    status: number;
    accountingSystemParameter: string;
    createdBy: number;
    createdOn: Date;
    updatedBy: number;
    updatedOn: Date;
    isRowHighlighted: boolean;
    selected: boolean;
    isActiveProject: boolean;
    hideDeactivate: boolean;
    hideActivate: boolean;
}

export class CustomerResponse implements ICustomerResponse {
    customerId: number;
    userId: number;
    organisationId: number;
    name: string;
    code1: string;
    code2: string;
    status: number;
    accountingSystemParameter: string;
    createdBy: number;
    createdOn: Date;
    updatedBy: number;
    updatedOn: Date;
    isRowHighlighted: boolean;
    selected: boolean;
    isActiveProject: boolean;
    hideDeactivate: boolean;
    hideActivate: boolean;
}
//

export interface ICustomerListRequest {
    organisationId: number;
    status: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
}

export class CustomerListRequest implements ICustomerListRequest {
    organisationId: number;
    status: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
    constructor(organisationId: number, status: number, pageNo: number, sortOrder: number, sortColumn: string) {
        this.organisationId = organisationId;
        this.status = status;
        this.pageNo = pageNo;
        this.sortOrder = sortOrder;
        this.sortColumn = sortColumn;
    }
}

export interface IResponseCustomerList {
    customerList: ICustomerResponse[];
    totalCustomerCount: number;
    activeCustomerCount: number;
    inActiveCustomerCount: number;
}

//

export interface IRequestGetCustomersByCustomerIdsOrgId {
    customerIds: number[];
    organisationId: number;
    checkIsPurchase: boolean;
}

export class RequestGetCustomersByCustomerIdsOrgId implements IRequestGetCustomersByCustomerIdsOrgId {
    customerIds: number[];
    organisationId: number;
    checkIsPurchase: boolean;

    constructor(customerIds: number[], organisationId: number, checkIsPurchase: boolean) {
        this.customerIds = customerIds;
        this.organisationId = organisationId;
        this.checkIsPurchase = checkIsPurchase;
    }
}


//

export interface IRequestGetCustomersByOrgIdAndStatus {
    organisationId: number;
    status: number;
}

export class RequestGetCustomersByOrgIdAndStatus implements RequestGetCustomersByOrgIdAndStatus {
    organisationId: number;
    status: number;

    constructor(organisationId: number, status: number) {
        this.organisationId = organisationId;
        this.status = status;
    }
}

//

export interface ICustomerRequest {
    customerId: number;
    userId: number;
    organisationId: number;
    name: string;
    code1: string;
    code2: string;
    status: number;
    accountingSystemParameter: string;

    createdBy: number;
    updatedBy: number;
    //userName: string;
}

export class CustomerRequest implements ICustomerRequest {
    customerId: number;
    userId: number;
    organisationId: number;
    name: string;
    code1: string;
    code2: string;
    status: number;
    accountingSystemParameter: string;
    createdBy: number;
    updatedBy: number;
    userName: string;
    constructor(customerId: number,
        userId: number,
        organisationId: number,
        name: string,
        code1: string,
        code2: string,
        status: number,
        accountingSystemParameter: string,
        createdBy: number,
        updatedBy: number) {
        this.customerId = customerId;
        this.userId = userId;
        this.organisationId = organisationId;
        this.name = name;
        this.code1 = code1;
        this.code2 = code2;
        this.status = status;
        this.accountingSystemParameter = accountingSystemParameter;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
    }
}

//

export interface IBulkActionOnCustomerRequest {
    customerIds: number[];
    updatedBy: number;
    status: number;

}

export class BulkActionOnCustomerRequest implements IBulkActionOnCustomerRequest {
    customerIds: number[];
    updatedBy: number;
    status: number;
    constructor(customerIds: number[], updatedBy: number, status: number) {
        this.customerIds = customerIds;
        this.status = status;
        this.updatedBy = updatedBy;
    }
}

//


export interface IRequestGetEmployeesForCustomerRule {
    customerRuleStatus: number;
    organisationId: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
}

export class RequestGetEmployeesForCustomerRule
    implements IRequestGetEmployeesForCustomerRule {
    customerRuleStatus: number;
    organisationId: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
    constructor(
        customerRuleStatus: number,
        organisationId: number,
        pageNo: number,
        sortOrder: number,
        sortColumn: string
    ) {
        this.customerRuleStatus = customerRuleStatus;
        this.organisationId = organisationId;
        this.pageNo = pageNo;
        this.sortOrder = sortOrder;
        this.sortColumn = sortColumn;
    }
}

export interface IResponseGetEmployeesForCustomerRule {
    employeesListForCustomerRule: IEmployeesForCustomerRule[];
    totalClaimants: number;
    mandatory: number;
    nonMandatory: number;
    notRequired: number;
}

export interface IEmployeesForCustomerRule {
    userId: number;
    organisationId: number;
    userOrgMappingId: number;
    userName: string;
    customerRuleId: number;

    updatedBy: number;
    updatedOn: number;

    userMailId: string;
    customerRuleText: string;
    isRowHighlighted: boolean;
}


//

export interface IBulkActionsForCustomerRuleRequest {
    userOrgMappingId: number;
    updatedBy: number;
    customerRuleId: number;
}

export class BulkActionsForCustomerRuleRequest implements IBulkActionsForCustomerRuleRequest {
    userOrgMappingId: number;
    updatedBy: number;
    customerRuleId: number;

    constructor(userOrgMappingId: number,
        updatedBy: number,
        customerRuleId: number) {
        this.customerRuleId = customerRuleId;
        this.userOrgMappingId = userOrgMappingId;
        this.updatedBy = updatedBy;
    }
}

//

export class RequestGetCustomerHistory {
    customerId: number;

    constructor(customerId: number) {
        this.customerId = customerId;
    }
}

export class CustomerHistoryResponse {
    customerHistoryId: number;
    customerId: number;
    organisationId: number;
    notes: string;
    createdDateTime: string;
    username: string;
}

//

export interface ICustomerModelError {
    customernameValidationMessage: string;
    customercode1ValidationMessage: string;
}

export class CustomerModelError implements ICustomerModelError {
    customernameValidationMessage: string = "";
    customercode1ValidationMessage: string = "";
    constructor() { }
}

//

export interface ICustomerCsvRecord {
    customerName: string;
    customerCode1: string;
    customerCode2: string;
    error: string;
}
export class CustomerCsvRecord implements ICustomerCsvRecord {
    customerName: string;
    customerCode1: string;
    customerCode2: string;
    public error: string;
    constructor() { }
}

export interface IFailedCustomerImportInfo {
    customerName: string;
    customerCode1: string;
    customerCode2: string;
    error: string;
}
export class FailedCustomerImportInfo implements IFailedCustomerImportInfo {
    customerName: string;
    customerCode1: string;
    customerCode2: string;
    error: string;

    constructor(customerName: string, customerCode1: string, customerCode2: string, error: string) {
        this.customerName = customerName;
        this.customerCode1 = customerCode1;
        this.customerCode2 = customerCode2;
        this.error = error;
    }
}

export interface ICustomerCsvRecordExport {
    CustomerName: string;
    CustomerCode1: string;
    CustomerCode2: string;
    Error: string;
}

export class CustomerCsvRecordExport implements ICustomerCsvRecordExport {
    CustomerName: string;
    CustomerCode1: string;
    CustomerCode2: string;
    Error: string;
}


export interface IGetCustomerResponse {
    customerValues: ActiveCustomerResponse[];
}

export class GetCustomerResponse implements IGetCustomerResponse {
    customerValues: ActiveCustomerResponse[];
}

export class ActiveCustomerResponse {
    customerId: number;
    customerName: string;
    customerCode1: string;
    customerCode2: string;
}

export class GetEmployeeForCustomerCostCentreRuleAssignmentRequest {
    organisationId: number;
    numberOfRecords: number;
    skipRecords: number;
}

export class EmployeeForCustomerCostCentreRuleAssignmentResponse {
    userOrgMappingId: number;
    userId: number;
    employeeName: string;
    email: string;
    updatedOn: number;
    customerRuleId: number;
    costCentreRuleId: number;
    costCentreName: string;
	  costCentreCode: string;
    accessRuleText: string;

    isRowHighlighted: boolean;
    selected: boolean;

    isEditDisabled: boolean;
    isCheckboxDisabled: boolean;
}

export class EmployeeListForCustomerCostCentreRuleAssignmentResponse {
    employeeList: EmployeeForCustomerCostCentreRuleAssignmentResponse[];
    totalEmployessCount: number;
    totalEmployessCountWithNotRequiredForCustomer: number;
    totalEmployessCountWithNonMandatoryForCustomer: number;
    totalEmployessCountWithMandatoryForCustomer: number;

}

export class AssignCustomerCostCentreAccessRuleToEmployeesRequest {
    organisationId: number;
    accessRuleId: number;
    userOrgMappingIds: number[];
    updatedBy: number;
    updatedOn: number;
    userName: string;
}

export class AddUpdateCustomerModel {
    constructor(
        public title: string,
        public customerId: number,
        public isEditMode: boolean = false,
        public isActiveProject: boolean = false
    ) {

    }
}

export class CustomerFormModel {
    customer_id: number;
    customer_name: string;
    customer_code1: string;
    customer_code2: string;
    customer_status: number;
    customer_status_boolean: boolean;

}

export interface IBulkSaveCustomerRequest {
    organisationId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    customerList: ICustomerRequest[];
}

export class BulkSaveCustomerRequest implements IBulkSaveCustomerRequest {
    organisationId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    customerList: ICustomerRequest[];
    constructor(
        organisationId: number,
        createdBy: number,
        fmName: string,
        fmEmail: string,
        fmUserId: number,
        customerList: ICustomerRequest[]
    ) {
        this.organisationId = organisationId;
        this.createdBy = createdBy;
        this.fmName = fmName;
        this.fmEmail = fmEmail;
        this.fmUserId = fmUserId;
        this.customerList = customerList;
    }
}



export interface IBulkSaveCustomerResponse {
    OrganisationId: number;
    TotalRecords: number;
    FailedRecords: number;
    PassedRecords: number;
    CustomerList: ICustomerResponse[];
}

