<input type="text" *ngIf="!isMandatory && !isEnd" twoDigitDecimaNumber appBlockCopyPaste
   class="w-80pc date-right-panel border text-right  bg-white rounded padding-left-5 padding-bottom-10 text-renderer padding-right5"
   [ngClass]="{'border-danger red-placeholder': errReq == true,'border-secondary': errReq!=true}" value={{value}}
   placeholder="{{placeholdertext}}" [(ngModel)]="value" (change)="onChange($event)" autocomplete="off"
   maxlength="9" min="0.01" max="999999.99">

<i *ngIf="isMandatory"  class="fas fa-info-circle text-mustard f-18"
   data-toggle="tooltip"
   ngbTooltip="{{ 'mandatory_msg' | translate }}"
   tooltipClass="my-custom-class-additional-fields"
   placement="left"
   container="body">
</i>
<i *ngIf="isEnd" class="fas fa-info-circle text-mustard f-18"
   data-toggle="tooltip"
   ngbTooltip="{{ 'end_msg' | translate }}"
   tooltipClass="my-custom-class-additional-fields"
   placement="left"
   container="body">
</i>  