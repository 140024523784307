import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as $ from "jquery";
import { IUser } from "../../_models/common/user";
import { AuthenticationService } from "../../_services";
import { Router, NavigationEnd } from "@angular/router";
import { Claims } from "../../_models/common/claims";
import { ClaimsService } from "../../_services/common/claims.service";
import {
  IApiRequest,
  ApiRequest,
  FunctionPackageEnum,
  FMRoleType,
  FunctionPrivilege,
  OrgFeedbackTypes,
  UniversalImportTemplateEnum,
  IApiresponse,
  UserTypeEnum,
} from "../../_models";
import { OrgSubscription } from "../../_models/common/OrgSubscription";
import { Guid } from "guid-typescript";
import { first, filter } from "rxjs/operators";
import { OrgSubscriptionPurchaseFunctionList } from "../../_models/billing-pricing/IOrgSubscriptionPurchaseFunctionList";
import { OrgSubscriptionFunctionList } from "../../_models/billing-pricing/OrgSubscriptionFunctionList";
import { RSAHelper } from "../../_helpers/RSAHelper";
import { OrganisationSettingService } from "../../_services/organisation-setting/organisation-setting.service";
import {
  DropdownItems,
  IGetCartDetailsRequest,
  GetCartDetailsRequest,
  ICartDetailsRequest,
  CartDetailsRequest,
  IPurchaseFunctionListRequest,
  PurchaseFunctionListRequest,
} from "../../_models/organisation-setting/organisation-setting";
import { parse } from "url";
import { Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { FunctionPrivilegeService } from "../../_services/secondary-fm/function-privilege.service";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";
import { StorageData } from "src/app/_models/universal-importer/universal-importer";
//import { UniversalImporterService } from "src/app/_services/universal-importer/universal-importer.service";
import { AdvancesService } from "src/app/_services/advances/advance-service";
import { storeURLs } from "src/app/common/commonvariables";
import { DOCUMENT, DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { MarketingPopup } from "src/app/_models/common/marketing-popup";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  IsSocialMediaUser: boolean = false;
  testNav: boolean = false;
  claims: Claims;
  isExpanded = false;
  logoutMenuVisible = false;
  currentUser: IUser;
  userDetails: any;
  users: IUser[] = [];
  imageSource: string = "assets/images/logo.png";
  twoStepApproval: boolean;
  isFinanceManager: boolean;
  routerLink: string;
  routerLinkSettings: string;
  routerLinkExp: string;
  routerLinkPay: string;
  routerLinkFA: string;
  routerLinkCards: string;
  routerLinkDashboard: string;
  menuChange: boolean;
  isCardSetupComplete: boolean = false;
  pendingAllForPayment: number = 0;
  CartCount: number = 0;

  ExportPackageId: string;

  OrgPurchaseFunctionRequest: IApiRequest<OrgSubscription>;
  OrgTrialingFunctionRequest: IApiRequest<OrgSubscription>;
  public UniqueId: Guid;
  PurchaseFunctionResponse: OrgSubscriptionPurchaseFunctionList;
  trialingFunctionResponse = <OrgSubscriptionFunctionList[]>[];
  isAccountingIntegrationPackagePurchasedOrTrial: boolean = false;
  Initials: string;
  FirstName: string;
  LastName: string;
  Email: string;
  OrgName: string;
  searchValue: number;
  searchItems = [];
  showSettingsMenu: boolean = false;
  activateAllFunctionsMenu: boolean = false;
  activateMySubscriptionMenu: boolean = false;
  activateComingSoonMenu: boolean = false;
  activatePricingMenu: boolean = false;
  isThirdLevelMenuVisible: boolean = false;
  activateSecondaryFMPrivilegeScreen: boolean = false;
  _routerSub = Subscription.EMPTY;
  isCreditCardPackageSubscribed: boolean = false;
  isPackagePurchasedOrTrial: boolean = false;
  public param: string;
  showLoginMarquee: boolean = false;
  marqueeText: string = "";
  isExportReportPackagePurchasedOrTrial: boolean = false;
  activateCreateNewReportMenu: boolean = false;

  showExportReportMarketingPopup: boolean;
  isPrimaryFM: boolean;
  myInvoicesVisible: boolean = false;
  isAdvancesAccess: boolean = false;
  isCarbonFootprintAccess: boolean = false;
  isFinanceApprovalAccess: boolean = false;
  isPayAccess: boolean = false;
  isCardAccess: boolean = false;
  isBuildReportAccess: boolean = false;
  isPrivilegeCheckStarted: boolean = false;
  isNRFCAccess: boolean = false;
  isPreApprovalAccess: boolean = false;
  storeURL = storeURLs;
  showQRCodePanel: boolean = false;
  isNotificationPanelActive: boolean = false;
  notificationsCount: number;
  isClaimOnWebAllowed: boolean = false;
  IsLeaveManagementAllowed: boolean;
  isUniversalImportAllowed: boolean;
  IsAPAutomationAllow: boolean;
  storageData: StorageData = new StorageData();
  userType: number = 0;
  kycStatus: number = 0;
  manageCCOptions: number = 0;
  get universalImportTemplateEnum() {
    return UniversalImportTemplateEnum;
  }
  get userTypeEnum() {
    return UserTypeEnum;
  }
  activateImportStatementMenu: boolean = false;
  advanceBalanceOutstanding: boolean = false;
  isAdvanceBalanceChecked: boolean = false;
  isDelegator: boolean = false;
  isDelegate: boolean = false;
  isDelegateFunctionPurchasedOrTrial: boolean = false;
  isApAutomationAccess: boolean = false;
  isCarbonFootprintPurchasedOrTrial: boolean = false;
  @Output() toggleRightSidebarEvent = new EventEmitter<boolean>();

  @Output() mobileMenuButtonClicked = new EventEmitter<boolean>();

  isInFullscreenMode = false;
  isOrgBasicFunctionTrialEndsAlert: boolean = false;
  isPayStepEnabled: boolean = false;
  bandId: number = 0;
  isRecallExportedExpensesPurchased: boolean = false;
  isContainerAllowed: boolean;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authenticationService: AuthenticationService,
    private claimsService: ClaimsService,
    private router: Router,
    private organisationSettingService: OrganisationSettingService,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService,
    private sanitizer: DomSanitizer,
    private functionPrivilegeService: FunctionPrivilegeService,
    public datepipe: DatePipe,
    private advancesService: AdvancesService,
    private translate: TranslateService
  ) {
    //this.fullscreen();
    if (
      localStorage.getItem("claims") &&
      localStorage.getItem("claims") !== "undefined"
    ) {
      this.claimsService.updateClaims(
        JSON.parse(localStorage.getItem("claims"))
      );
    }

    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      this.userType = this.claims.userType ? this.claims.userType : 0;
      this.kycStatus = this.claims.kycStatus;
      this.IsLeaveManagementAllowed = claims.IsLeaveManagementAllowed;
      this.isUniversalImportAllowed = claims.isUniversalImportAllowed;
      this.IsAPAutomationAllow = claims.IsAPAutomationAllow;
      this.isDelegator = this.claims.IsDelegator;
      this.isDelegate = this.claims.IsDelegate;
      this.isDelegateFunctionPurchasedOrTrial =
        this.claims.IsDelegateFunctionPurchasedOrTrial;
      this.isOrgBasicFunctionTrialEndsAlert =
        this.claims.IsOrgBasicFunctionTrialEndsAlert;
      this.isPayStepEnabled = this.claims.IsPayStepEnabled;
      this.bandId = this.claims.BandId;
      this.manageCCOptions = this.claims.ManageCCOptions;
      this.isContainerAllowed = this.claims.IsContainerAllowed;
    });

    if (localStorage.getItem("currentUser")) {
      this.userDetails = JSON.parse(localStorage.getItem("currentUser"));
      if (this.userDetails.RequestInfo) {
        this.FirstName = this.userDetails.RequestInfo.FirstName;
        this.LastName = this.userDetails.RequestInfo.LastName;
      } else if (this.userDetails.responseInfo) {
        this.FirstName = this.userDetails.responseInfo.firstName;
        this.LastName = this.userDetails.responseInfo.lastName;
      }
    }
    else if(localStorage.getItem("registereduser")){
      this.userDetails = JSON.parse(localStorage.getItem("registereduser"));
      if (this.userDetails) {
        this.FirstName = this.userDetails.FirstName;
        this.LastName = this.userDetails.LastName;
      }
    }
    if (this.FirstName && this.FirstName != "") {
      this.Initials = this.FirstName.charAt(0);
    }
    if (this.LastName && this.LastName != "") {
      this.Initials += this.LastName.charAt(0);
    }

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.UniqueId = Guid.create();

    // this.universalImporterService.currentImportStorageData.subscribe((storageData) => {
    //     this.storageData = storageData;
    // });

    this.getOrgPurchaseFunctionList();
    this.fnGetOrgBasicFunctionSubscriptionTrialDetails();
  }


  GetOrganisationPreferences() {
    let request = new ApiRequest<any>(
      "GetOrganisationPreferences",
      environment.applicationVersion,
      environment.requestId
    );
    this.organisationSettingService
      .GetOrganisationPreferences(request)
      .subscribe((data: IApiresponse<any>) => {
        if (data.statusCode == 0) {
          this.claims.RateType = data.responseInfo.rateType;
          this.claimsService.updateClaims(this.claims);
          this.claims.ManageCCOptions = data.responseInfo.manageCCOption;
          this.manageCCOptions = data.responseInfo.manageCCOption;
          this.claimsService.updateClaims(this.claims);
        }
      });
  }

  onSettingClick(value: boolean) {
    this.testNav = value;
    //console.log('asdasd');
  }

  onNotificationsCountChange(event) {
    this.notificationsCount = event;
  }

  openNotificationPanel() {
    this.isNotificationPanelActive = true;
  }
  closeNotificationPanel() {
    this.isNotificationPanelActive = false;
  }
  basicFunctionRemainingTrialDays: number;
  getOrgBasicFunctionSubscriptionTrialDetails() {
    if (this.claims && this.claims.OrgId && this.isFinanceManager) {
      this.orgBasicFunctionSubscriptionService
        .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
        .subscribe((res: any) => {
          this.basicFunctionRemainingTrialDays =
            res.responseInfo.basicFunctionRemainingTrialDays;
        });
    }
  }
  linkClicked() {
    this.logoutMenuVisible = !this.logoutMenuVisible;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  MouseOverEvent(e) {
    $(".profileActionMenu").addClass("active");
    $(".notify-icons a#profileAction").css("color", "#096094");
  }

  routeIsActive(routePath: string) {
    let currentUrl = this.router.url;
    return currentUrl.startsWith(routePath);
  }

  ngOnInit() {
    this.detectUrlUpdates();
    this.isFinanceManager = false;
    this.GetOrganisationPreferences();
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      if (claims) {
        this.IsSocialMediaUser = this.claims.IsSocialMediaUser;
        this.Email = this.claims.LoginId;
        this.OrgName = this.claims.OrgName;
        //this.imageSource = this.claims.OrgLogo;
        this.twoStepApproval = this.claims.TwoStepApproval;
        this.isFinanceManager = this.claims.IsFinanceManager;
        this.menuChange = this.claims.MenuSettings;
        this.isCardSetupComplete = this.claims.IsCardSetupComplete;
        this.pendingAllForPayment = this.claims.pendingAllForPayment;
        this.CartCount = this.claims.CartCount;
        this.isAccountingIntegrationPackagePurchasedOrTrial =
          this.claims.IsAccountingIntegrationPackagePurchasedOrTrial;
        this.isExportReportPackagePurchasedOrTrial =
          this.claims.IsExportReportPackagePurchasedOrTrial;
        this.isRecallExportedExpensesPurchased =
          this.claims.IsRecallExportedExpensesPurchased;

        this.isPrimaryFM = this.claims.FMRoleType === FMRoleType.AllPrivilege;
        this.isClaimOnWebAllowed = this.claims.isClaimOnWebAllowed;

        if (this.isPrimaryFM) {
          this.myInvoicesVisible = true;
          this.isFinanceApprovalAccess = true;
          this.isPayAccess = true;
          this.isCardAccess = true;
          this.isBuildReportAccess = true;
          this.isNRFCAccess = true;
          this.isAdvancesAccess = true;
          this.isPreApprovalAccess = true;
          this.isApAutomationAccess = true;
          this.isCarbonFootprintAccess = true;
        } else if (this.claims.FMRoleType === FMRoleType.LimitedPrivilege) {
          if (!this.isPrivilegeCheckStarted) {
            this.isPrivilegeCheckStarted = true;
            var functionIds = [
              FunctionPrivilege.Invoices,
              FunctionPrivilege.FinanceApprover,
              FunctionPrivilege.PassforPayment,
              FunctionPrivilege.SetupCreditCard,
              FunctionPrivilege.TransferData,
              FunctionPrivilege.NonReimbursableFuel,
              FunctionPrivilege.Advances,
              FunctionPrivilege.PreApprovals,
              FunctionPrivilege.CarbonFootprint,
            ];
            this.functionPrivilegeService
              .checkPrivilegesAccess(functionIds, this.claims.UserId)
              .subscribe((access) => {
                this.myInvoicesVisible =
                  access.indexOf(FunctionPrivilege.Invoices) > -1;
                this.isFinanceApprovalAccess =
                  access.indexOf(FunctionPrivilege.FinanceApprover) > -1;
                this.isPayAccess =
                  access.indexOf(FunctionPrivilege.PassforPayment) > -1;
                this.isCardAccess =
                  access.indexOf(FunctionPrivilege.SetupCreditCard) > -1;
                this.isBuildReportAccess =
                  access.indexOf(FunctionPrivilege.TransferData) > -1;
                this.isNRFCAccess =
                  access.indexOf(FunctionPrivilege.SetupAdvancedMileage) > -1;
                this.isAdvancesAccess =
                  access.indexOf(FunctionPrivilege.Advances) > -1;
                this.isPreApprovalAccess =
                  access.indexOf(FunctionPrivilege.PreApprovals) > -1;
                this.isApAutomationAccess =
                  access.indexOf(FunctionPrivilege.ApAutomation) > -1;
                this.isCarbonFootprintAccess =
                  access.indexOf(FunctionPrivilege.CarbonFootprint) > -1;
              });
          }
        }

        if (this.Initials == undefined || this.Initials == "") {
          if (
            this.claims.FirstName != undefined &&
            this.claims.FirstName != ""
          ) {
            this.FirstName = this.claims.FirstName;
            this.Initials = this.claims.FirstName.charAt(0);
          }
          if (this.claims.LastName != undefined && this.claims.LastName != "") {
            this.LastName = this.claims.LastName;
            this.Initials += this.claims.LastName.charAt(0);
          }
        }
        this.isCreditCardPackageSubscribed =
          this.claims.IsCreditCardPackageSubscribed;

        // if (this.searchItems.length == 0) {
        //   this.getAvailableSearchFunctions();
        // }
        this.isPackagePurchasedOrTrial = this.claims.IsPackagePurchasedOrTrial;

        if (this.claims.IsMarqueeOn == true) {
          this.showLoginMarquee = this.claims.IsMarqueeOn;
          this.marqueeText = this.claims.MarqueeText;
        }

        if (this.showLoginMarquee == true) {
          setTimeout(() => {
            this.showLoginMarquee = false;
            this.claims.IsMarqueeOn = false;
            this.claims.MarqueeText = "";
            this.claimsService.updateClaims(this.claims);
          }, 300000);
        }
        this.getAdvanceBalanceOutstanding();
      }
    });

    $(document).ready(function () {
      $(".profileActionMenu").mouseleave(function () {
        $(".profileActionMenu").removeClass("active");
        $(".notify-icons a#profileAction").css("color", "#686868");
      });

      $("#resNavMenu > li").click(function () {
        $("#resNavMenu li").removeClass("highlight-div");
        $(this).addClass("highlight-div");
        $("#resNavMenu li ul").slideUp();
        $(this).find("ul").slideToggle(400);
      });
    });
  }

  getAdvanceBalanceOutstanding() {
    if (!this.isAdvanceBalanceChecked) {
      this.isAdvanceBalanceChecked = true;
      this.advancesService.isAdvanceBalanceOutstanding().subscribe((result) => {
        if (result && result.responseInfo) {
          this.advanceBalanceOutstanding = true;
        } else {
          this.advanceBalanceOutstanding = false;
        }
        this.claims.isAdvanceBalanceOutstanding =
          this.advanceBalanceOutstanding;
        this.claimsService.updateClaims(this.claims);
      });
    }
  }

  navSlider() {
    $(".overLayDiv").addClass("active");
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "0px";
  }

  closeNav() {
    $(".overLayDiv").removeClass("active");
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  logout() {
    // remove user from local storage to log user out
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  changePassword() {
    this.logoutMenuVisible = false;
    this.router.navigate(["changepassword"]);
  }

  billingPlan() {
    this.router.navigate(["billingplan"]);
  }

  fnNavigateToCart(
    is_Free,
    package_Path,
    function_PackageName,
    function_PackageId,
    function_Status
  ) {
    let isFree = is_Free.toString();
    let packagePath = package_Path.toString();
    let functionPackageName = function_PackageName.toString();
    let functionPackageId = function_PackageId.toString();
    let functionStatus = function_Status.toString();
    this.router.navigate(["/addtocart"], {
      queryParams: {
        isFree: this.EncryptParameters(isFree),
        packagePath: this.EncryptParameters(packagePath),
        functionPackageName: this.EncryptParameters(functionPackageName),
        functionPackageId: this.EncryptParameters(functionPackageId),
        functionStatus: this.EncryptParameters(functionStatus),
      },
    });
  }
  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }
  getAvailableSearchFunctions() {
    this.organisationSettingService
      .GetOrganisationSettingList()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            let result = data.responseInfo.functionCategoryDetail
              .filter((el) => {
                return el.functionPackageDetail.some((f) => {
                  return f.comingSoon == false;
                });
              })
              .map((a) => {
                a.functionPackageDetail = a.functionPackageDetail.filter(
                  (f) => f.comingSoon === false
                );
                return a;
              });
            let searchItemsList = [];
            result.forEach((a) => {
              a.functionPackageDetail.forEach((b) => {
                if (
                  b.functionPackageId == FunctionPackageEnum.LeaveManagement
                ) {
                  if (this.IsLeaveManagementAllowed) {
                    let searchItem = new DropdownItems();
                    searchItem.id = b.functionCategoryId;
                    searchItem.name = b.functionPackageName;
                    searchItemsList.push(searchItem);
                  }
                } else if (
                  b.functionPackageId == FunctionPackageEnum.UniversalImport
                ) {
                  if (this.isUniversalImportAllowed) {
                    let searchItem = new DropdownItems();
                    searchItem.id = b.functionCategoryId;
                    searchItem.name = b.functionPackageName;
                    searchItemsList.push(searchItem);
                  }
                } else if (
                  b.functionPackageId == FunctionPackageEnum.APAutomation
                ) {
                  if (this.IsAPAutomationAllow) {
                    let searchItem = new DropdownItems();
                    searchItem.id = b.functionCategoryId;
                    searchItem.name = b.functionPackageName;
                    searchItemsList.push(searchItem);
                  }
                }
                else if (
                  b.functionPackageId == FunctionPackageEnum.Container
                ) {
                  if (this.isContainerAllowed) {
                    let searchItem = new DropdownItems();
                    searchItem.id = b.functionCategoryId;
                    searchItem.name = b.functionPackageName;
                    searchItemsList.push(searchItem);
                  }
                } else {
                  let searchItem = new DropdownItems();
                  searchItem.id = b.functionCategoryId;
                  searchItem.name = b.functionPackageName;
                  searchItemsList.push(searchItem);
                }
              });
            });
            const uniq = new Set(searchItemsList.map((e) => JSON.stringify(e)));
            const res = Array.from(uniq).map((e) => JSON.parse(e));
            this.searchItems = res;
          }
        },
        (error) => {
          console.debug(error);
        }
      );
  }
  searchChanged(searchValue) {
    this.router.navigate(["settings"], { fragment: "place" + searchValue });
  }
  isSearchVisible(allFuncUrl) {
    let myUrl = parse(window.location.href);
    let currentUrl = myUrl.path;

    if (currentUrl == allFuncUrl) {
      return true;
    }
    this.searchValue = null;
    return false;
  }

  detectUrlUpdates() {
    this.setSettingsMenu("a");
    this._routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((value) => {
        this.setSettingsMenu(value);
      });
  }

  setSettingsMenu(urlDetails) {
    let myUrl = parse(window.location.href);
    let currentUrl = myUrl.path;
    let isRedirectFromMySubscriptionSettingPage = localStorage.getItem(
      "isRedirectFromMySubscriptionSettingPage"
    );

    if (
      currentUrl.startsWith("/dashboard") ||
      currentUrl.startsWith("/export") ||
      currentUrl.startsWith("/pay") ||
      currentUrl.startsWith("/financeapprover") ||
      currentUrl.startsWith("/card") ||
      currentUrl.startsWith("/claimant") ||
      currentUrl.startsWith("/ManageSubscription") ||
      currentUrl.startsWith("/shoppingcartlist") ||
      currentUrl.startsWith("/shoppingcartreview") ||
      currentUrl.startsWith("/stripeAgreement") ||
      currentUrl.startsWith("/shoppingcartConfirmed") ||
      currentUrl.startsWith("/referandearn") ||
      currentUrl.startsWith("/changepassword") ||
      currentUrl.startsWith("/support/support") ||
      currentUrl.startsWith("/support/unsubscribe") ||
      currentUrl.startsWith("/universalimport/ccuploadfile") ||
      currentUrl.startsWith("/universalimport/ccdefineheader") ||
      currentUrl.startsWith("/universalimport/ccimportheadermapping") ||
      currentUrl.startsWith("/universalimport/ccimportstmtreview") ||
      currentUrl.startsWith("/universalimport/ccimportsummary") ||
      currentUrl.startsWith("/approver")
    )
      this.showSettingsMenu = false;
    else this.showSettingsMenu = true;

    if (this.showSettingsMenu) {
      this.activateAllFunctionsMenu = currentUrl == "/settings";
      this.activateMySubscriptionMenu =
        currentUrl.startsWith("/mysubscription");
      this.activateComingSoonMenu = currentUrl.startsWith("/comingsoon");
      this.activatePricingMenu = currentUrl.startsWith("/pricing");
      this.activateSecondaryFMPrivilegeScreen = currentUrl.startsWith(
        "/secondaryfm/privilege"
      );
      this.isThirdLevelMenuVisible =
        this.activateAllFunctionsMenu ||
        this.activateMySubscriptionMenu ||
        this.activateComingSoonMenu ||
        this.activateSecondaryFMPrivilegeScreen;

      if (
        this.showSettingsMenu &&
        !(
          this.activateAllFunctionsMenu ||
          this.activateMySubscriptionMenu ||
          this.activateComingSoonMenu ||
          this.activatePricingMenu
        )
      ) {
        if (
          isRedirectFromMySubscriptionSettingPage == "0" ||
          isRedirectFromMySubscriptionSettingPage == "1"
        ) {
          this.activateAllFunctionsMenu =
            isRedirectFromMySubscriptionSettingPage == "0";
          this.activateMySubscriptionMenu =
            isRedirectFromMySubscriptionSettingPage == "1";
        } else {
          this.activateAllFunctionsMenu = true;
          this.activateMySubscriptionMenu = false;
        }
      }
    } else {
      this.activateAllFunctionsMenu = false;
      this.activateMySubscriptionMenu = false;
      this.activateComingSoonMenu = false;
      this.activatePricingMenu = false;
      this.isThirdLevelMenuVisible = false;
    }

    if (
      currentUrl.startsWith("/export/reportwizard") ||
      currentUrl.startsWith("/export/createnewreport")
    ) {
      this.activateCreateNewReportMenu = true;
    } else {
      this.activateCreateNewReportMenu = false;
    }
  }

  routeUser() {
    if (this.claims.IsFinanceManager == true)
      this.router.navigate(["dashboard/overview"]);
    else this.router.navigate(["claimant/expenselist"]);
  }

  supportPage() {
    this.logoutMenuVisible = false;
    this.router.navigate(["support/support"]);
  }
  fnMyMileageRate() {
    this.logoutMenuVisible = false;
    this.router.navigate(["claimant/mymileagerate"]);
  }

  unsubscribe() {
    this.logoutMenuVisible = false;
    this.router.navigate(["support/unsubscribe"]);
  }

  fnInvoicesClick() {
    this.logoutMenuVisible = false;
    this.claims.invoiceActiveTab = "nav-billingHistory";
    this.claimsService.updateClaims(this.claims);
    localStorage.setItem("BillingSettingsActiveTab", "nav-billingHistory");
    this.router.navigate(["ManageSubscription"]);
  }
  paymentMethodActiveTab() {
    this.logoutMenuVisible = false;
    this.claims.invoiceActiveTab = "nav-paymentMethod";
    this.claimsService.updateClaims(this.claims);
    localStorage.setItem("BillingSettingsActiveTab", "nav-paymentMethod");
    this.router.navigate(["ManageSubscription"]);
  }
  changeBillingPlan() {
    this.logoutMenuVisible = false;
    this.claims.invoiceActiveTab = "nav-discountContract";
    this.claimsService.updateClaims(this.claims);
    localStorage.setItem("BillingSettingsActiveTab", "nav-discountContract");
    this.router.navigate(["ManageSubscription"]);
  }
  assistedSupportActiveTab() {
    this.logoutMenuVisible = false;
    this.claims.invoiceActiveTab = "nav-implementationCharge";
    this.claimsService.updateClaims(this.claims);
    localStorage.setItem(
      "BillingSettingsActiveTab",
      "nav-implementationCharge"
    );
    this.router.navigate(["ManageSubscription"]);
  }
  fnManageSubscriptionClick() {
    this.logoutMenuVisible = false;
    this.claims.invoiceActiveTab = "nav-discountContract";
    this.claimsService.updateClaims(this.claims);
    localStorage.setItem("BillingSettingsActiveTab", "nav-discountContract");
    this.router.navigate(["ManageSubscription"]);
  }
  referAndEarnRoute() {
    this.logoutMenuVisible = false;
    this.router.navigate(["referandearn"]);
  }

  sanitizerHtml(element) {
    let svg = this.sanitizer.bypassSecurityTrustHtml(element);
    return svg;
  }

  fnCloseExportReportMarketingPopUp() {
    this.showExportReportMarketingPopup = false;
  }

  showCreateReportMarketingPopup() {
    this.getCartDetails();
    this.showExportReportMarketingPopup = true;
  }

  disableAddToCartForDataExport: boolean;
  getCartDetailsRequest: IApiRequest<IGetCartDetailsRequest>;
  disableAddToCart: boolean;
  cartList: any;
  getCartDetails(): any {
    this.getCartDetailsRequest = new ApiRequest<GetCartDetailsRequest>(
      "SoloGetCartDetails",
      1,
      "111"
    );
    this.getCartDetailsRequest.requestInfo = new GetCartDetailsRequest(
      this.claims.UserId,
      this.claims.OrgId
    );

    this.organisationSettingService
      .getCartDetailsList(this.getCartDetailsRequest)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.responseInfo != null) {
            this.claims.CartCount = data.responseInfo.listCartDetails.length;
            this.claimsService.updateClaims(this.claims);
            this.cartList = data.responseInfo.listCartDetails;
            this.disableAddToCart = this.isFunctionInCart(
              FunctionPackageEnum.AccountingIntegration
            );
            this.disableAddToCartForDataExport = this.isFunctionInCart(
              FunctionPackageEnum.ExportReport
            );
          } else {
          }
        },
        (error) => {}
      );
  }

  isFunctionInCart(functionPackageId) {
    return this.cartList.some(
      (item) => item.functionPackageId == functionPackageId
    );
  }

  fnSaveCartDetailsForExportReport() {
    let request = new ApiRequest<ICartDetailsRequest>(
      "SaveCartDetails",
      environment.applicationVersion,
      environment.requestId
    );

    request.requestInfo = new CartDetailsRequest(
      0,
      0,
      false,
      FunctionPackageEnum.ExportReport,
      this.translate.instant("data_to_other_system")
    );
    this.organisationSettingService
      .SaveCartDetails(request)
      .pipe(first())
      .subscribe((data) => {
        if (data.statusCode == 0) {
          this.showExportReportMarketingPopup = false;
          this.routerNavigateToExportData();
        }
      });
  }

  routerNavigateToExportData() {
    let isFree = "false";
    let packagePath = "export/reportwizard";
    let functionPackageName = this.translate.instant("data_to_other_system");
    let functionPackageId = FunctionPackageEnum.ExportReport.toString();
    let functionStatus = "0";
    this.router.navigate(["/addtocart"], {
      queryParams: {
        isFree: this.EncryptParameters(isFree),
        packagePath: this.EncryptParameters(packagePath),
        functionPackageName: this.EncryptParameters(functionPackageName),
        functionPackageId: this.EncryptParameters(functionPackageId),
        functionStatus: this.EncryptParameters(functionStatus),
      },
    });
  }

  showZoomedDataExportImage: boolean;
  zoomDataExportImage() {
    this.showZoomedDataExportImage = true;
  }

  closeDataExportImage() {
    this.showZoomedDataExportImage = false;
  }

  closeQRCodePanel() {
    this.showQRCodePanel = false;
  }
  openQRCodePanel() {
    this.showQRCodePanel = true;
  }
  navegateToGrid(page, url) {
    this.router.navigate([url], {
      queryParams: {
        page: this.EncryptParameters(page),
      },
    });
  }

  navigatToImportCardStatement() {
    localStorage.setItem("isCameFromCreditCardImport" + this.claims.OrgId, "1");
    this.storageData.importTemplate =
      UniversalImportTemplateEnum.ImportCreditCardStatement;
    //this.universalImporterService.fnSaveDataToLocalStorage(this.storageData);
    this.router.navigate(["/universalimport/ccuploadfile"]);
  }

  isCardMenuActive() {
    this.activateImportStatementMenu =
      this.routeIsActive("/universalimport/ccuploadfile") ||
      this.routeIsActive("/universalimport/ccdefineheader") ||
      this.routeIsActive("/universalimport/ccimportheadermapping") ||
      this.routeIsActive("/universalimport/ccimportstmtreview") ||
      this.routeIsActive("/universalimport/ccimportsummary");

    if (
      this.routeIsActive("/card") ||
      this.routeIsActive("/universalimport/ccuploadfile") ||
      this.routeIsActive("/universalimport/ccdefineheader") ||
      this.routeIsActive("/universalimport/ccimportheadermapping") ||
      this.routeIsActive("/universalimport/ccimportstmtreview") ||
      this.routeIsActive("/universalimport/ccimportsummary")
    ) {
      return true;
    } else {
      return false;
    }
  }

  getOrgPurchaseFunctionList() {
    //Use this function for any future validations ..

    let request = new ApiRequest<IPurchaseFunctionListRequest>(
      "SoloPurchaseFunctionList",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new PurchaseFunctionListRequest(
      this.claims.UserId,
      this.claims.OrgId
    );
    this.organisationSettingService
      .soloPurchaseFunctionList(request)
      .subscribe((data: IApiresponse<any>) => {
        if (data.statusCode == 0) {
          if (data.responseInfo.orgPaidFunctionList.length > 0) {
            let purchaseFuncListDetails =
              data.responseInfo.orgPaidFunctionList[0].paidFunctions;
            var preApprov = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.Pre_Approvals
            );
            if (preApprov) {
              this.claims.isPreApprovalPurchased = true;
            }
            var nrPurchased = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.NonReimbursableFuel
            );
            if (nrPurchased) {
              this.claims.isNRPackagePurchasedOrTrial = true;
            }
            var fcPurchased = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.FuelCard
            );
            if (fcPurchased) {
              this.claims.isFCPackagePurchasedOrTrial = true;
            }
            var advancesPurchased = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.Advances
            );
            if (advancesPurchased) {
              this.claims.isAdvancesFunctionPurchasedOrTrial = true;
            }
            var apAutomationPurchased = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.APAutomation
            );
            if (apAutomationPurchased) {
              this.claims.isAPAutomationFunctionPurchasedOrTrial = true;
            }

            var carbonFoorprintPurchased = purchaseFuncListDetails.find(
              (x) => x.functionId == FunctionPackageEnum.CarbonFootprint
            );

            if (carbonFoorprintPurchased) {
              this.isCarbonFootprintPurchasedOrTrial = true;
            }

            this.claimsService.updateClaims(this.claims);
          }
        }
      });
  }
  fnGetClass() {
    if (this.isFinanceManager) {
      return "col-md-8";
    } else {
      return "col-md-12";
    }
  }

  toggleRightSidebar() {
    this.toggleRightSidebarEvent.emit(true);
  }

  element: any;
  fullscreen() {
    this.element = document.documentElement;
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }

      this.isInFullscreenMode = true;
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }

      this.isInFullscreenMode = false;
    }
  }

  toggleMobileMenu(event) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  ngOnDestroy() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  isBasicFunctionPurchased: boolean = true;
  basicFunctionTrialDays: number;
  basicFuncEndDate: any;
  fnGetOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService
      .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
      .subscribe((res: any) => {
        if (res.responseInfo != null) {
          let todayDate = new Date();

          this.basicFunctionRemainingTrialDays =
            res.responseInfo.basicFunctionRemainingTrialDays;
          todayDate.setDate(
            todayDate.getDate() + this.basicFunctionRemainingTrialDays
          );
          let formatDate = this.datepipe.transform(
            todayDate,
            environment.dateFormat
          );
          this.basicFuncEndDate = formatDate;
          this.basicFunctionTrialDays = res.responseInfo.basicFunctionTrialDays;
          this.isBasicFunctionPurchased =
            res.responseInfo.isBasicFunctionPurchased;
          this.claims.isBasicFunctionPurchased =
            res.responseInfo.isBasicFunctionPurchased;
          this.claimsService.updateClaims(this.claims);
        }
      });
  }
  isDisplayEssDetail: boolean;
  fnRedirectToEssPack() {
    this.isDisplayEssDetail = false;
    localStorage.setItem("isEssentialPackSubscribeDisplay", "1");
    this.router.navigate(["/essential-pack"]);
  }
  openEssPackPanel() {
    this.isDisplayEssDetail = true;
  }
  dismissEssPack() {
    this.isDisplayEssDetail = false;
  }
  fnEssBtnClick(clickType: number) {
    this.isDisplayEssDetail = false;
    switch (clickType) {
      case 1:
        this.fnInvoicesClick();
        break;
      case 2:
      case 3:
        this.changeBillingPlan();
        break;
      default:
        break;
    }
  }

  recallExportedExpensesMarketingPopupModel: any;
  recallExportedExpensesMarketingPopup = false;

  showRecallExportedExpensesMarketingPopup() {
    if (this.claims.IsRecallExportedExpensesPurchased) {
      this.recallExportedExpensesMarketingPopup == false;
      this.router.navigate(["export/exportrollback"]);
    } else {
      this.recallExportedExpensesMarketingPopup = true;
      this.recallExportedExpensesMarketingPopupModel = new MarketingPopup(
        this.translate.instant("Roll Back Exported Expense"),
        this.translate.instant("Roll Back Any Expense You Want"),
        "",
        null,
        "../../assets/svg-icons/build-report-image.svg",
        "../../assets/images/create-report.png",
        "",
        FunctionPackageEnum.RecallExportedExpenses,
        this.translate.instant("Recall Exported Expenses"),
        "export/exportrollback",
        0,
        0,
        "",
        [
          this.translate.instant(
            "Humans are Prone to Errors, so are you. But the good thing about this function is that it lets you correct those."
          ),
        ],
        this.translate.instant(
          "Humans are Prone to Errors, so are you. But the good thing about this function is that it lets you correct those."
        )
      );
    }
  }

  closeMarketingPopup() {
    this.recallExportedExpensesMarketingPopup = false;
  }
}
