import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-info-step-renderer',
  templateUrl: './info-step-renderer.component.html',
  styleUrls: ['./info-step-renderer.component.scss']
})
export class InfoStepRendererComponent implements ICellRendererAngularComp {
  public infoStep: boolean = false;

  constructor() {

  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.infoStep = params.data.infoStep
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
