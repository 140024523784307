import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'grid-textbox-renderer',
    templateUrl: './grid-textbox-renderer.component.html'
})

export class GridTextBoxRendererComponent implements ICellRendererAngularComp {
    placeHolder = "";
    maxLength: number;
    value: any;
    params: any;
    isRequired: boolean = false;
    showValidator: boolean = false;
    isShowText: boolean = false;
    disabled: boolean = false;
    isShowValidationOnInit: boolean = false;


    initilize(params: ICellRendererParams) {

        if (params && params.data && params.data.isShowText) {
            this.isShowText = params.data.isShowText;
        }

        if (params && params.data && params.data.disabled) {
            this.disabled = params.data.disabled;
        }

        if (params && params.data && params.data.isRequired) {
            this.isRequired = params.data.isRequired;
        }

        if (params.colDef.headerComponentParams) {
            if (params.colDef.headerComponentParams.maxLength) {
                this.maxLength = params.colDef.headerComponentParams.maxLength;
            }

            if (params.colDef.headerComponentParams.placeHolder) {
                this.placeHolder = params.colDef.headerComponentParams.placeHolder;
            }

            if (params.colDef.headerComponentParams.isShowValidationOnInit) {
                this.isShowValidationOnInit = params.colDef.headerComponentParams.isShowValidationOnInit;
            }
        }
        
        this.value = params.getValue();

        if( this.isShowValidationOnInit && !this.value){
            this.showValidator = true;
        }
    }

    agInit(params: any): void {
        this.params = params;
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onInput($event) {
        if (this.params.onInput instanceof Function) {
            this.showValidator = false;

            this.params.setValue($event.target.value);

            let params = { event: $event, rowData: this.params.node.data }
            this.params.onInput(params);

            if (params.rowData.isRequired) {
               this.isRequired = params.rowData.isRequired;
            }
            
            if (!this.NoWhitespaceValidator($event.target.value) && this.isRequired) {
                this.showValidator = true;
                return;
            }
        }
    }


    NoWhitespaceValidator(value: string): boolean {
        if (value == null) {
            value = "";
        }

        const isWhitespace = (value || "").trim().length === 0;

        return !isWhitespace;
    }

}
