import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'grid-ng-select-or-text-renderer',
    templateUrl: './grid-ng-select-or-text-renderer.component.html',
    styleUrls: ["./grid-ng-select-or-text-renderer.component.scss"],
})

export class GridNgSelectOrTextRendererComponent implements ICellRendererAngularComp {

    showCheckIcon: boolean;
    placeholder = "";
    items = [];
    value: any;
    params: any;

    isShowText: boolean = false;


    initilize(params: ICellRendererParams) {

        if (params && params.data && params.data.isShowText) {
            this.isShowText = params.data.isShowText;
        }

        if (params.colDef.headerComponentParams) {
            this.items = params.colDef.headerComponentParams.dropdownItems;
            this.placeholder = params.colDef.headerComponentParams.placeholder;
        }

        this.value = params.getValue();
    }

    agInit(params: any): void {
        this.params = params;
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onChange($event) {
        if (this.params.onChange instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data
            }
            this.params.setValue(params.event.id);
            this.params.onChange(params);        
        }
    }

}
