export interface IRequestCostCentre {
  costCentreId: number;
  organisationId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  replacementDate: Date;
  newCostCentreName: string;
  newCostCentreCode1: string;
  newCostCentreCode2: string;
  status: number;
  createdBy: number;
  createdDate: Date;
  modifedBy: number;
  modifiedDate: Date;
  createdByName: string;
  updatedOn: Date;
}

export class RequestCostCentre implements IRequestCostCentre {
  costCentreId: number;
  organisationId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  replacementDate: Date;
  newCostCentreName: string;
  newCostCentreCode1: string;
  newCostCentreCode2: string;
  status: number;
  createdBy: number;
  createdDate: Date;
  modifedBy: number;
  modifiedDate: Date;
  createdByName: string;
  updatedOn: Date;
  constructor(
    costCentreId: number,
    organisationId: number,
    costCentreName: string,
    costCentreCode1: string,
    costCentreCode2: string,
    replacementDate: Date,
    newCostCentreName: string,
    newCostCentreCode1: string,
    newCostCentreCode2: string,
    status: number,
    createdBy: number,
    createdDate: Date,
    modifedBy: number,
    modifiedDate: Date,
    createdByName: string
  ) {
    this.costCentreId = costCentreId;
    this.organisationId = organisationId;
    this.costCentreName = costCentreName;
    this.costCentreCode1 = costCentreCode1;
    this.costCentreCode2 = costCentreCode2;
    this.replacementDate = replacementDate;
    this.newCostCentreName = newCostCentreName;
    this.newCostCentreCode1 = newCostCentreCode1;
    this.newCostCentreCode2 = newCostCentreCode2;
    this.status = status;
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.modifedBy = modifedBy;
    this.modifiedDate = modifiedDate;
    this.createdByName = createdByName;
  }
}

export class CostCentreModel {
  id: number;
  value: string;
  code: string;
  isActive: boolean;
}

export class CostCentreApprover {
  costCentreId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  status: number;
  approverIds: number[];
}

export class CostCentreApproversCount {
  costCentreId: number;
  approversCount: number;
}

export class SaveCostCentreApproverRequest {
  constructor(public userName: string, public costCentreApproverMap: AssignCostCentreApprover[]) {

  }
}

export class AssignCostCentreApprover {
  constructor(public costCentreId: number, public costCentreName: string,public costCentreCode1: string,
    public approverId: number, public approverName: string, public action: number) {

  }
}

export interface IResponseCostCentre {
  costCentreId: number;
  organisationId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  replacementDate: any;
  newCostCentreName: string;
  newCostCentreCode1: string;
  newCostCentreCode2: string;
  status: number;
  createdBy: number;
  createdDate: Date;
  modifedBy: number;
  modifiedDate: Date;
  isAnyEmployeeAssigned: boolean;
  selected: boolean;
  createdByName: string;
  updatedOn: Date;
  isRowHighlighted: boolean;
  isActiveProject: boolean;
  hideDeactivate:boolean;
  hideActivate:boolean;
}

export class ResponseCostCentre implements IResponseCostCentre {
  costCentreId: number;
  organisationId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  replacementDate: any;
  newCostCentreName: string;
  newCostCentreCode1: string;
  newCostCentreCode2: string;
  status: number;
  createdBy: number;
  createdDate: Date;
  modifedBy: number;
  modifiedDate: Date;
  isAnyEmployeeAssigned: boolean;
  selected: boolean = false;
  createdByName: string;
  updatedOn: Date;
  isRowHighlighted: boolean = false;
  isActiveProject: boolean;
  hideDeactivate:boolean;
  hideActivate:boolean;
  constructor(
    costCentreId: number,
    organisationId: number,
    costCentreName: string,
    costCentreCode1: string,
    costCentreCode2: string,
    replacementDate: any,
    newCostCentreName: string,
    newCostCentreCode1: string,
    newCostCentreCode2: string,
    status: number,
    createdBy: number,
    createdDate: Date,
    modifedBy: number,
    modifiedDate: Date,
    isAnyEmployeeAssigned: boolean,
    createdByName: string
  ) {
    this.costCentreId = costCentreId;
    this.organisationId = organisationId;
    this.costCentreName = costCentreName;
    this.costCentreCode1 = costCentreCode1;
    this.costCentreCode2 = costCentreCode2;
    this.replacementDate = replacementDate;
    this.newCostCentreName = newCostCentreName;
    this.newCostCentreCode1 = newCostCentreCode1;
    this.newCostCentreCode2 = newCostCentreCode2;
    this.status = status;
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.modifedBy = modifedBy;
    this.modifiedDate = modifiedDate;
    this.isAnyEmployeeAssigned = isAnyEmployeeAssigned;
    this.createdByName = createdByName;
  }
}

export interface IRequestCostCentreByOrg {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
}

export class RequestCostCentreByOrg implements IRequestCostCentreByOrg {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
  constructor(
    organisationId: number,
    status: number,
    pageNumber: number,
    sortOrder: number,
    sortColumn: string
  ) {
    this.organisationId = organisationId;
    this.status = status;
    this.pageNumber = pageNumber;
    this.sortOrder = sortOrder;
    this.sortColumn = sortColumn;
  }
}

export interface IRequestGetCostCentre {
  costCentreId: number;
}

export class RequestGetCostCentre implements IRequestGetCostCentre {
  costCentreId: number;
  constructor(costCentreId: number) {
    this.costCentreId = costCentreId;
  }
}

export interface IResponseCostCenterLog {
  costCentreHistoryId: number;
  costCentreId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  newCostCentreName: string;
  newCostCentreCode1: string;
  newCostCentreCode2: string;
  replacementDate: Date;
  actionPerformed: number;
  createdBy: number;
  createdDate: Date;
  createdByName: string;
  notes: string;
}

export interface IRequestGetCostCentreLog {
  costCentreId: number;
}

export class RequestGetCostCentreLog implements IRequestGetCostCentreLog {
  costCentreId: number;
  constructor(costCentreId: number) {
    this.costCentreId = costCentreId;
  }
}

export interface IModelError {
  costCentreNameError: boolean;
  costCentreCode1Error: boolean;
  costCentreCode2Error: boolean;
  newCostCentreNameError: boolean;
  newCostCentreCode1Error: boolean;
  newCostCentreCode2Error: boolean;
  costCentreNameExistsError: boolean;
  newCostCentreNameExistsError: boolean;
  costCentreCode1ExistsError: boolean;
  newCostCentreCode1ExistsError: boolean;
}

export class ModelError implements IModelError {
  costCentreNameError: boolean;
  costCentreCode1Error: boolean;
  costCentreCode2Error: boolean;
  newCostCentreNameError: boolean;
  newCostCentreCode1Error: boolean;
  newCostCentreCode2Error: boolean;
  costCentreNameExistsError: boolean;
  newCostCentreNameExistsError: boolean;
  costCentreCode1ExistsError: boolean;
  newCostCentreCode1ExistsError: boolean;
  constructor() { }
}

export interface IRequestDeactiveCostCentre {
  costCentreId: number;
}

export class RequestDeactiveCostCentre implements IRequestDeactiveCostCentre {
  costCentreId: number;
  modifedBy: number;
  createdByName: string;
  constructor(costCentreId: number, modifedBy: number, createdByName: string) {
    this.costCentreId = costCentreId;
    this.modifedBy = modifedBy;
    this.createdByName = createdByName;
  }
}

export interface IRequestGetCostCentreListForAssignEmployee {
  organisationId: number;
  status: number;
}

export class RequestSaveCCDisplaySetting{
  ccDisplaySetting:number;
}

export class RequestGetCostCentreListForAssignEmployee
  implements IRequestGetCostCentreListForAssignEmployee {
  organisationId: number;
  status: number;
  constructor(organisationId: number, status: number) {
    this.organisationId = organisationId;
    this.status = status;
  }
}

export interface ICostCentreMappedEmployeeList {
  costCentreId: number;

  organisationId: number;

  employeeIds: number[];
  costCentreName: string;
}

export class CostCentreMappedEmployeeList
  implements ICostCentreMappedEmployeeList {
  costCentreId: number;
  organisationId: number;
  employeeIds: number[];
  costCentreName: string;

  constructor(
    costCentreId: number,
    organisationId: number,
    employeeIds: number[],
    costCentreName: string
  ) {
    this.costCentreId = costCentreId;
    this.employeeIds = employeeIds;
    this.organisationId = organisationId;
    this.costCentreName = costCentreName;
  }
}

export interface IRequestBulkDeactiveCostCentre {
  costCentreIds: number[];

  modifiedBy: number;
  createdByName: string;
}

export class RequestBulkDeactiveCostCentre
  implements IRequestBulkDeactiveCostCentre {
  costCentreIds: number[];
  modifiedBy: number;
  createdByName: string;
  constructor(
    costCentreIds: number[],
    modifiedBy: number,
    createdByName: string
  ) {
    this.costCentreIds = costCentreIds;
    this.modifiedBy = modifiedBy;
    this.createdByName = createdByName;
  }
}

export interface ICSVRecord {
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  error: string;
}
export class CSVRecord implements ICSVRecord {
  public costCentreName: string;
  public costCentreCode1: string;
  public costCentreCode2: string;
  public error: string;
  constructor() { }
}

export interface IFailedCostCentreImportInfo {
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  error: string;
}
export class FailedCostCentreImportInfo implements IFailedCostCentreImportInfo {
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  error: string;

  constructor(
    costCentreName: string,
    costCentreCode1: string,
    costCentreCode2: string,
    error: string
  ) {
    this.costCentreName = costCentreName;
    this.costCentreCode1 = costCentreCode1;
    this.costCentreCode2 = costCentreCode2;
    this.error = error;
  }
}

export interface IRequestImportCostCentre {
  costCentreList: IRequestCostCentre[];
}

export class RequestImportCostCentre implements IRequestImportCostCentre {
  costCentreList: IRequestCostCentre[];

  constructor(costCentreList: IRequestCostCentre[]) {
    this.costCentreList = costCentreList;
  }
}

export interface ICSVRecordExport {
  CostCentreName: string;
  CostCentreCode1: string;
  CostCentreCode2: string;
  Error: string;
}

export class CSVRecordExport implements ICSVRecordExport {
  CostCentreName: string;
  CostCentreCode1: string;
  CostCentreCode2: string;
  Error: string;
}

export class ActiveCostCentreResponse {
  costCentreId: number;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  derivedName:string;
  isHomeCostCentre: boolean;
}

export interface IGetCostCentreResponse {
  costCentreValues: ActiveCostCentreResponse[];
}

export class GetCostCentreResponse implements IGetCostCentreResponse {
  costCentreValues: ActiveCostCentreResponse[];
}
