import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-advance-balance-input',
  templateUrl: "./formly-advance-balance-input.component.html"
})
export class FormlyAdvanceBalanceInputComponent extends FieldType {

  showAdvancePassbookPanel: boolean = false;
  currencyCode: string="";

  showAdvancePassbook() {
    this.showAdvancePassbookPanel = true;
    this.currencyCode = this.model.currencyCode;
  }

  closeAdvancePassbookPanel() {
    this.showAdvancePassbookPanel = false;
  }

}
