import { Injectable} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { IApiRequest, IApiresponse, GradePolicyFilterEnum} from 'src/app/_models';
import { IRequestOtherPolicyData, IResponseOtherPolicyData, IRequestSaveOtherPolicyData, IResponseSaveOtherPolicyData, IRequestDuplicatePolicyData, IResponseDuplicatePolicyData, IRequestSaveDuplicatePolicyData, IResponseSaveDuplicatePolicyData, IRequestEscalationSettings, IResponseEscalationSettings, IReqBusinessPurpose, IRespBusinessPurpose, IRespGetBusinessPurposeValues, IReqSaveBPValues, IResponseGetAdditionalFieldValues, ISaveAdditionalFieldsRequest, ISaveAdditionalFieldsResponse, IDeleteAdditionalFieldRequest, IDeleteFieldByIdRequest, IExpTimeLimitSaveRequest, IRequestPolicyConversion, IRequestDeleteOtherPoliciesForConversionToGradeOrClaimantWise, ClaimantWisePolicyExport, IClaimantWisePolicyExport, IRequestClaimantWisePolicyExport, IRequestClaimantWisePolicyHistoricalView, IResponseClaimantWisePolicyHistoricalView, PolicyDashboardData, PolicyDashboardFilter, PolicyDashBoardPanelModel, PolicyDashboardPanelData, IYearEndSaveRequest } from 'src/app/_models/policy/policy-data';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRequestGetPolicyDetail, IRequestPolicyViolation, IResponsePolicyDetail, IResponsePolicyViolation } from 'src/app/_models/twostepapproval/claimant-expense-detail';
import { setHeader } from 'src/app/_helpers/setRequestHeader';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { IReqUpdateDocRules, IRespUpdateRules } from '../../_models/DutyofCare/dutyofcare';
import { ExcelExportService } from '../common/excel-export.service';
import { IResponseFraudDetails } from '../../_models/twostepapproval/claimant-expense-detail';
import { ReqSaveCategoryAdditionalFieldDetails, RespCategoryAdditionalFieldSettings } from '../../_models/policy/policy-data';

@Injectable({
  providedIn: 'root'
})
export class PolicyDataService {

  constructor(private http: HttpClient, private header: setHeader,
    private claimsHeaderService: ClaimsHeaderService,
    private excelExportService: ExcelExportService
  ) { }

  //httpHeaders = this.header.createAuthorizationHeader();
  GetPolicyDetail(requestinfo: IApiRequest<IRequestGetPolicyDetail>): Observable<IApiresponse<IResponsePolicyDetail>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponsePolicyDetail>>(`${environment.billingApiUrl}GetPolicyDetail`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetPolicyData(requestinfo: IApiRequest<IRequestPolicyViolation>): Observable<IApiresponse<IResponsePolicyViolation>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponsePolicyViolation>>(`${environment.expenseApiUrl}GetPolicyViolationDetatils`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetPotentialFraudData(expenseId): Observable<IApiresponse<IResponseFraudDetails>> {
    let expenseDetailUrl = `${environment.expenseApiUrl}GetPotentialFraudDetatils/` + expenseId + ``;
    return this.http.get<IApiresponse<IResponseFraudDetails>>(expenseDetailUrl, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetOtherPolicyData(requestinfo: IApiRequest<IRequestOtherPolicyData>): Observable<IApiresponse<IResponseOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseOtherPolicyData>>(`${environment.billingApiUrl}GetOtherPolicesByGradeAndType`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetDuplicatePolicyData(requestinfo: IApiRequest<IRequestDuplicatePolicyData>): Observable<IApiresponse<IResponseDuplicatePolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseDuplicatePolicyData>>(`${environment.billingApiUrl}GetAllDuplicatePolicy`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveOtherPolicyData(requestinfo: IApiRequest<IRequestSaveOtherPolicyData>): Observable<IApiresponse<IResponseSaveOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseSaveOtherPolicyData>>(`${environment.billingApiUrl}SaveOtherPolicesByGradeAndType`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveDuplicatePolicyData(requestinfo: IApiRequest<IRequestSaveDuplicatePolicyData>): Observable<IApiresponse<IResponseSaveDuplicatePolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseSaveDuplicatePolicyData>>(`${environment.billingApiUrl}SaveAllDuplicatePolicy`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveEscalationSettings(requestinfo: IApiRequest<IRequestEscalationSettings>): Observable<IApiresponse<IResponseEscalationSettings>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseEscalationSettings>>(`${environment.billingApiUrl}SaveEscalationSettings`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveDocRules(requestinfo: IApiRequest<IReqUpdateDocRules>): Observable<IApiresponse<IRespUpdateRules>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespUpdateRules>>(`${environment.billingApiUrl}SaveDOCRules`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveBusinessPurpose(requestinfo: IApiRequest<IReqBusinessPurpose>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}SetBusinessPurposeForOrganisation`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetBusinessPurposeValues(): Observable<IApiresponse<IRespGetBusinessPurposeValues>> {
    let url = `${environment.billingApiUrl}GetBusinessPurposeValues`;
    return this.http.get<IApiresponse<IRespGetBusinessPurposeValues>>(url, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetAdditionalFieldValues(): Observable<IApiresponse<IResponseGetAdditionalFieldValues>> {
    let url = `${environment.billingApiUrl}GetAdditionalFieldValues`;
    return this.http.get<IApiresponse<IResponseGetAdditionalFieldValues>>(url, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveBusinessPurposeValues(requestinfo: IApiRequest<IReqSaveBPValues>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}SaveBusinessPurposeValues`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveAFValues(requestinfo: IApiRequest<ISaveAdditionalFieldsRequest>): Observable<IApiresponse<ISaveAdditionalFieldsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISaveAdditionalFieldsResponse>>(`${environment.billingApiUrl}SaveAdditionalFieldValues`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  DeleteAFValues(requestinfo: IApiRequest<IDeleteAdditionalFieldRequest>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}DeleteAdditionalFieldsData`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  DeleteAFDataValues(requestinfo: IApiRequest<IDeleteFieldByIdRequest>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}DeleteAdditionalFieldsDataById`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveExpenseTimeLimit(requestinfo: IApiRequest<IExpTimeLimitSaveRequest>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}SetupExpenseTimeLimit`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
  SaveYearEndValues(requestinfo: IApiRequest<IYearEndSaveRequest>): Observable<IApiresponse<IRespBusinessPurpose>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IRespBusinessPurpose>>(`${environment.billingApiUrl}SetupYearEndRule`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetClaimantWiseMonthlyAndYearlyCappingPolicy(requestinfo: IApiRequest<IRequestOtherPolicyData>): Observable<IApiresponse<IResponseOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseOtherPolicyData>>(`${environment.billingApiUrl}GetClaimantWiseMonthlyAndYearlyCappingPolicy`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveClaimantWiseMonthlyAndYearlyCappingPolicy(requestinfo: IApiRequest<IRequestSaveOtherPolicyData>): Observable<IApiresponse<IResponseSaveOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseSaveOtherPolicyData>>(`${environment.billingApiUrl}SaveClaimantWiseMonthlyAndYearlyCappingPolicy`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  ConvertPolicyFromGradeToClaimantWise(requestinfo: IApiRequest<IRequestPolicyConversion>): Observable<IApiresponse<IResponseSaveOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseSaveOtherPolicyData>>(`${environment.billingApiUrl}ConvertPolicyFromGradeToClaimantWise`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  DeleteOtherPoliciesForConversionToGradeOrClaimantWise(requestinfo: IApiRequest<IRequestDeleteOtherPoliciesForConversionToGradeOrClaimantWise>): Observable<IApiresponse<IResponseSaveOtherPolicyData>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseSaveOtherPolicyData>>(`${environment.billingApiUrl}DeleteOtherPoliciesForConversionToGradeOrClaimantWise`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  ExportClaimantWisePolicyExcel(
    exportedData: any[],
    excelFileName: string
  ) {
    let mappedData: IClaimantWisePolicyExport[] = [];

    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new ClaimantWisePolicyExport();

      recordExport.ClaimantName = exportedData[i].claimantName;
      recordExport.Category = exportedData[i].expenseCategoryName;
      recordExport.Limit = exportedData[i].limit;
      recordExport.EffectiveFrom = exportedData[i].effectiveFrom;
      recordExport.AlertPercentage = exportedData[i].alertPercentage;

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  GetClaimantWisePolicyHistoricalView(requestinfo: IApiRequest<IRequestClaimantWisePolicyHistoricalView>): Observable<IApiresponse<IResponseClaimantWisePolicyHistoricalView>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseClaimantWisePolicyHistoricalView>>(`${environment.billingApiUrl}GetClaimantWisePolicyHistoricalView`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  ExportClaimantWisePolicyData(requestinfo: IApiRequest<IRequestClaimantWisePolicyExport>) {
    let body = JSON.stringify(requestinfo);
    return this.http.post<any>(
      `${environment.billingApiUrl}ExportClaimantWisePolicyData`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        responseType: "text" as "json",
      }
    );
  }

  SetPolicyDefaultFilter(gradeId: number, gradeWithPolicy: number, policyCallback: any, defaultFilter: boolean = false)
  {
    if(defaultFilter && gradeWithPolicy <= 0)
    {
      var filterByValue = gradeWithPolicy > 0 ? GradePolicyFilterEnum.GradeWithPolicy
        : GradePolicyFilterEnum.GradeWithoutPolicy;     
      policyCallback(gradeId, filterByValue);        
    }
  }
 
  GetPolicyDashboardData(filterdata: IApiRequest<PolicyDashboardFilter>): Observable<IApiresponse<PolicyDashboardData>> {
    let body = JSON.stringify(filterdata);
    return this.http.post<IApiresponse<PolicyDashboardData>>(`${environment.expenseApiUrl}GetPolicyDashboardData`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
  GetPolicyPanelData(filterdata: IApiRequest<PolicyDashBoardPanelModel>): Observable<IApiresponse<PolicyDashboardPanelData[]>> {
    let body = JSON.stringify(filterdata);
    return this.http.post<IApiresponse<PolicyDashboardPanelData[]>>(`${environment.expenseApiUrl}GetPolicyPanelData`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetCategoryAdditionalFieldSettings(): Observable<IApiresponse<RespCategoryAdditionalFieldSettings>> {
    return this.http.get<IApiresponse<RespCategoryAdditionalFieldSettings>>(
      `${environment.billingApiUrl}GetCategoryAdditionalFieldSettings`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCategoryAdditionalFieldSettings(requestinfo: IApiRequest<ReqSaveCategoryAdditionalFieldDetails>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}SaveCategoryAdditionalFieldSettings`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }


}
