<div class="input-group">
    <input type="text" [formControl]="formControl" [formlyAttributes]="field" style="display: none;">
    <a class="create-expense-tag" (click)="showAdvancePassbook()">
        <span><i class="fas fa-wallet text-charcoal-black"></i></span>
        {{ 'advance_balance_title' | translate }}
    </a>
</div>

<advance-passbook-panel *ngIf="showAdvancePassbookPanel" [currencyCode]="currencyCode" (closePanelEvent)="closeAdvancePassbookPanel($event)">
</advance-passbook-panel>
