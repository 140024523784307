import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
//import { MarketingPopup } from "../../_models/common/marketing-popup";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { Observable } from "rxjs";
import { ClaimsHeaderService } from "../claimsHeader/claims-header.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { first } from "rxjs/operators";
import { LoggingPanelModel, LoggingPanelData } from "../../_models/common/LoggingPanelModel";
import { LoggingPanelService } from "src/app/_services/logging-panel/logging-panel.service";
import { LogObjects } from "../../_models/common/LogObjects";
//import { DatePipe } from "@angular/common";
import { LogObjectActionEnum } from 'src/app/_models';
import { EODDateFormatPipe } from "src/app/_helpers/pipe.module";

@Component({
  selector: "logging-panel",
  templateUrl: "./logging-panel.component.html",
  styleUrls: ["./logging-panel.component.scss"],
})

export class LoggingPanelComponent implements OnInit {

  loggingPanelModel: LoggingPanelModel;
  title: string;
  btnCancelToolTipText: string;

  dateText: string;
  ActionByText: string;
  LogText: string;

  logObjects: LogObjects[];
  messages: LoggingPanelData[] = [];
  logObjectActionEnum = LogObjectActionEnum;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private loggingPanelService: LoggingPanelService,
    //private datePipe: DatePipe,
    private eodDate: EODDateFormatPipe
  ) {
    this.loggingPanelService.getLogPanelEvent().subscribe((model) => {
        this.loggingPanelModel = model;
        if (this.loggingPanelModel) {
          this.title = this.translate.instant("label_log_history");
          this.btnCancelToolTipText = this.translate.instant("close");
          this.dateText = this.translate.instant("date_and_time");
          this.ActionByText = this.translate.instant("action_by");
          this.LogText = this.translate.instant("label_log");
          this.getFMActionLog(this.loggingPanelModel.orgId, this.loggingPanelModel.functionId, this.loggingPanelModel.FilterType, this.loggingPanelModel.MileageFilterType,this.loggingPanelModel.EntityFilterType);
        }
    });
  }

  ngOnInit() {

  }


  getFMActionLog(orgId: string, functionId: string, FilterType: string, MileageFilterType: string="0", EntityFilterType: string="0") {
    this.messages = [];
    this.loggingPanelService.getFMActionLog(orgId, functionId, FilterType, MileageFilterType, EntityFilterType)
      .subscribe((res: any) => {
        this.logObjects = res.responseInfo;
        this.processLogText(this.logObjects);
      });
    console.log(this.messages);
  }

  processLogText(logObjects: LogObjects[]) {
    logObjects.forEach(logs => {
      console.log('processLogText');
      console.log(logs);
      let LogDisplayText = this.getLogText(logs);
      if (LogDisplayText.length > 0)        
        this.messages.push({ Date: this.eodDate.transform(logs.addOn), ActionBy: logs.actionByUserName?.toString(), Log: LogDisplayText.trimEnd() });
      });
  }


  getLogText(logs: LogObjects): string {    
    let displayText = this.translate.instant(logs.text.key);
    for (let i = 0; i < logs.text.params.length; i++) {
      if(logs.text.params[i]){
        let parameter = this.translate.instant(logs.text.params[i]);
        displayText = displayText.replace(`{${i}}`, parameter);
      } 
    }
    return displayText;
  }

  dismiss() {
    this.loggingPanelModel = null;
  }

}
