import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { CostCentreService } from "src/app/_services";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { SelectCostCentrePopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ActiveCostCentreResponse, IGetCostCentreResponse } from "src/app/_models/costCentre/costCentre";
import { IApiresponse } from "src/app/_models";

@Component({
    selector: "select-cost-centre-panel",
    templateUrl: "./select-cost-centre-panel.component.html"
})
export class SelectCostCentrePanelComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectCostCentrePopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectCostCentrePopupModel>();

    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';
    hasFormChanges: boolean = false;

    userActionEventSubscription: Subscription;
    costCentreList = <ActiveCostCentreResponse[]>[];
    selectedCostCentreId: number = 0;
    isSelectionHide: boolean = false;
    disableSave: boolean= true;

    constructor(private alertService: AlertService,
        private costCentreService: CostCentreService, private cdr:ChangeDetectorRef) {
        super();
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }
    ngOnInit(): void {
        this.fnGetActiveCostCentreList();
        this.fnSetCostCentreInputValues();
    }

    canDeactivate(): boolean {
        return !this.hasFormChanges;
    }

    ngOnDestroy() {
        this.userActionEventSubscription.unsubscribe();
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetActiveCostCentreList() {
        this.costCentreService
            .GetActiveCostCentre()
            .subscribe((response: IApiresponse<IGetCostCentreResponse>) => {
                if (response.responseInfo != null) {
                    this.costCentreList = response.responseInfo.costCentreValues;
                    this.fnGetSelectedCostCentreOnTop();
                    this.cdr.detectChanges();
                }
            });
    }

    fnSelectCostCentre() {
        
        let selectCostCentreOutputModel = new SelectCostCentrePopupModel();
        if (this.selectedCostCentreId > 0) {
            let costCentreDetails = this.costCentreList.find(item => item.costCentreId == this.selectedCostCentreId);
            if (costCentreDetails) {
                selectCostCentreOutputModel.costCentreId = costCentreDetails.costCentreId;
                selectCostCentreOutputModel.costCentreName = costCentreDetails.derivedName;
            }
        } else {
            selectCostCentreOutputModel.costCentreId = 0;
            selectCostCentreOutputModel.costCentreName = '';
        }
        this.selectItemEvent.emit(selectCostCentreOutputModel);
    }

    fnOnChangeCostCentre(costCentreId: number) {
        if (costCentreId == this.selectedCostCentreId) {
            this.selectedCostCentreId = 0;
        } else {
            this.selectedCostCentreId = costCentreId;
        }

        //enabled / disabled save button
        if(this.isSelectionHide){
            if(!this.selectedCostCentreId){
                this.disableSave = true;
            }
            else{
                this.disableSave = false;
            }
        }       
        else{
            this.disableSave = false;
        }
    }

    fnSetCostCentreInputValues() {
        if (this.inputData) {
            this.selectedCostCentreId = this.inputData.costCentreId;
            this.isSelectionHide = this.inputData.isSelectionHide;
        }
    }
    fnGetSelectedCostCentreOnTop() {
        let costCentreId = this.inputData && this.inputData.costCentreId ? this.inputData.costCentreId : 0;

        if (costCentreId && this.costCentreList && this.costCentreList.length > 0) {
            const sortedArr = this.costCentreList.reduce((acc, element) => {
                if (element.costCentreId == costCentreId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.costCentreList = sortedArr;
        }
    }
}
