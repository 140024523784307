import { VehicleTypes, IVehicleTypes } from "../advance-mileage/vehicle-types";

export interface IOrgVehiclesNRFCResponse {
    orgID: number;
    mileageUOM: number;
    mileageUOMLabel: string;
    rateType: number;
    totalCount: number;
    nrTotalCount: number;
    fcTotalCount: number;
    noNRFCCount: number;
    vehicleTypes: IVehicleTypes[];
    removeOldReceipts: boolean;
}


export class OrgVehiclesNRFCResponse implements IOrgVehiclesNRFCResponse {
    orgID: number;
    mileageUOM: number;
    mileageUOMLabel: string;
    rateType: number;
    totalCount: number;
    nrTotalCount: number;
    fcTotalCount: number;
    noNRFCCount: number;
    vehicleTypes: VehicleTypes[];
    removeOldReceipts: boolean;

}
