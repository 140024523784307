<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <form [formGroup]="createDetourForm" novalidate>
        <div class="rightSidePanel z-9999 active">
            <header>
                <div class="row">
                    <div class="col-lg-12 no-padding-right-left">
                        <div class="row">
                            <div class="col-lg-8  padding-left-0">
                                <h2>{{'add_detour' | translate:param}}</h2>
                            </div>
                            <div class="col-lg-4 padding-right-0 header-icons text-right">
                                <ul class="text-logo-blue margin-top-5 d-flex justify-content-end " style="float: right !important;">
                                    <li class="padding-left-0">
                                        <button type="button" class="btn btn-primary height-38px margin-right-15"
                                         [ngClass]="{ 'disabled bg-secondary opacity-60': disableSubmitButton || !this.createDetourForm.dirty}" 
                                         (click)="fnOnSubmitDetour()">
                                            {{ 'label_save' | translate: param }}
                                        </button>
                                    </li>
                                    <li class="padding-right-1">
                                        <button type="button" class="btn btn-outline-secondary border-color-warning text-mustard height-38px"
                                         (click)="fnClosePanel()">
                                            {{ 'label_close' | translate: param }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="clear"></div>
            <div class="col-lg-12 margin-top-10 no-padding-right-left">
                <div class="block float-left w-100pc padding-top-25 ">
                    <div class="row">
                        <div class="input-group w-50pc">
                            <input type="text" class="form-control " formControlName="detour"
                            dynamicDigitBeforeAndDynamicDigitAfterDecimalNumber  [beforeDecimal]="6" [afterDecimal]="2"
                                [ngClass]="{'has-value':createDetourForm.controls.detour.value}">
                            <label for="FilterBy">{{'enter_detour' | translate:param}}<span>*</span></label>
                            <div *ngIf="createDetourForm.controls.detour.invalid && isDetourFormSubmitted"
                                class="alert-danger">
                                <span *ngIf="createDetourForm.controls.detour.errors.required">
                                    <b>{{ "label_Required" | translate: param }}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top-30">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="detourJustificationNotes"
                                maxlength="200"
                                [ngClass]="{'has-value':createDetourForm.controls.detourJustificationNotes.value}">
                            <label for="ExpenseCategory">{{'enter_justification_notes' | translate:param}}<span>*</span></label>
                            <div *ngIf="createDetourForm.controls.detourJustificationNotes.invalid && isDetourFormSubmitted"
                                class="alert-danger">
                                <span *ngIf="createDetourForm.controls.detourJustificationNotes.errors.required
                                     || createDetourForm.controls.detourJustificationNotes.value.trim()==''">
                                    <b>{{ "label_Required" | translate: param }}</b>
                                </span>
                            </div>
                            <p class="text-right">
                                <small><span
                                        [ngClass]="{'color-red': createDetourForm.controls.detourJustificationNotes.value &&
                                    createDetourForm.controls.detourJustificationNotes.value.length > 190 }">{{createDetourForm.controls.detourJustificationNotes.value
                                        ?
                                        createDetourForm.controls.detourJustificationNotes.value.length : 0}}</span>
                                    /
                                    200
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>