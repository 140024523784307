import { OnInit, Component, EventEmitter, Input, Output, ChangeDetectorRef } from "@angular/core";
import { IApiresponse } from "src/app/_models";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { EmailService } from "src/app/_services/common/email.service";
import { IVehicleInfo, IVehicleListResponse, SelectVehicleModel } from "../../../../_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "../../../../_services/claimant-on-web/claimant-on-web.service";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { TranslateService } from "@ngx-translate/core";
import { ApiRequest, EmailEnum, VehicleStatusEnum } from "../../../../_models";
import { environment } from "../../../../../environments/environment";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { EmailSendRequest, IEmailSendRequest, ISendReminderResponse } from "src/app/_models/common/email.model";
@Component({
  selector: 'app-select-vehicle-panel',
  templateUrl: './select-vehicle-panel.component.html',
  styleUrls: ['./select-vehicle-panel.component.scss']
})
export class SelectVehiclePanelComponent implements OnInit {

  @Input() inputData: SelectVehicleModel;

  @Output() closePanelEvent = new EventEmitter<boolean>();
  @Output() selectItemEvent = new EventEmitter<SelectVehicleModel>();

  vehicleTypeId: number;
  param: any;
  howDoIVideoLinks = HowDoIVideoLinks;
  searchText: string = '';
  showVehicleNotAppprovedPopuModel: AlertPanelModel;
  showVehicleNotAppprovedPopup: boolean;
  vehicleResponse: any;
  vehicleId: number = 0;
  claims: Claims;
  constructor(private claimantOnWebService: ClaimantOnWebService,
    private translate: TranslateService,
    private emailService: EmailService,
    private claimsService: ClaimsService,
    private cdfr:ChangeDetectorRef) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit(): void {
    this.vehicleResponse = [];
    this.fnGetVehicleDetails();

  }

  fnClosePanel() {
    this.closePanelEvent.emit(true);
  }
  fnGetVehicleDetails() {
    this.claimantOnWebService.GetUserVehicleInfo(this.inputData.claimantId)
      .subscribe((response: IApiresponse<IVehicleListResponse>) => {
        if (response.responseInfo != null) {
          this.vehicleResponse = response.responseInfo.userVehicleList.filter(a => a.vehicleStatus == VehicleStatusEnum.Approved);
          this.fnSetVehicleInputValues();
          this.cdfr.detectChanges();
        }
      });
  }

  fnSelectVehicleModel(item: IVehicleInfo) {
    if (item) {
      if (item.vehicleStatus != VehicleStatusEnum.Approved) {
        this.vehicleTypeId = item.vehicleTypeId;
        this.fnShowVehicleNotAppprovedPopup()
      }
      else {
        let selectVehicleModel = new SelectVehicleModel();
        selectVehicleModel.vehicleId = item.vehicleId;
        selectVehicleModel.derivedName = item.derivedName;
        selectVehicleModel.makeModel = item.makeModel;
        selectVehicleModel.registrationNumber = item.registrationNumber;
        selectVehicleModel.mileageAllowance = item.mileageAllowance;
        selectVehicleModel.docStatus = item.docStatus;
        this.selectItemEvent.emit(selectVehicleModel);
      }
    }
  }

  fnSetVehicleInputValues() {
    if (this.inputData) {
      this.vehicleId = this.inputData.vehicleId;
    }
  }

  fnShowVehicleNotAppprovedPopup() {
    this.showVehicleNotAppprovedPopuModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      this.translate.instant("text_cancel"),
      this.translate.instant("text_send_reminder"),
      true,
      this.translate.instant("vehicle_details_have_been_sent_to_the_finance_manager_alert_note")
    );
    this.showVehicleNotAppprovedPopup = true;
  }

  fnShowVehicleNotAppprovedPopupReceiver(returnValue) {
    if (returnValue) {

      let claimantName = this.inputData.claimantName;
      let keyValuePairs: { [key: string]: string; } = {};
      keyValuePairs["VehicleTypeId"] = this.vehicleTypeId.toString();
      keyValuePairs["ClaimantName"] = claimantName.trim();
      this.fnSendReminderEmail(EmailEnum.ClaimMilesAndVehicleNotApproved, keyValuePairs);
    }

    this.showVehicleNotAppprovedPopuModel = new AlertPanelModel(false, "", [""], "", "", true, "");
    this.showVehicleNotAppprovedPopup = false;
    this.fnClosePanel();
  }


  fnSendReminderEmail(templateName, keyValuePairs) {
    let request = new ApiRequest<IEmailSendRequest>("GetSetDetailForSendEmail", environment.applicationVersion, environment.requestId);
    request.requestInfo = new EmailSendRequest(templateName, this.inputData.claimantId, this.claims.OrgId, keyValuePairs);

    this.emailService.SendReminderEmail(request)
      .subscribe((response: IApiresponse<ISendReminderResponse>) => { });
  }

  fnGetSelectedVehicleOnTop() {
    let vehicleId = this.inputData && this.inputData.vehicleId ? this.inputData.vehicleId : 0;

    if (vehicleId && this.vehicleResponse && this.vehicleResponse.length > 0) {
      const sortedArr = this.vehicleResponse.reduce((acc, element) => {
        if (element.vehicleId == vehicleId) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      this.vehicleResponse = sortedArr;
    }


  }
  fnBindDefaultVehicle(isDefault) {
    if (this.claims.isPolicyOnVehicleChange) {
      if (isDefault) {
        return ' (' + this.translate.instant("default_vehicle") + ')';
      }
    }
    else {
      if (isDefault) {
        return ' (' + this.translate.instant("preferred_vehicle") + ')';
      }
    }
  }
}
