import { Injectable, OnInit, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  IBasicMileageRequestDto, IBasicMileageDto, IApiRequest, ISaveMileageRequestDto, IApiresponse, ISaveMileageResponseDto,
  IGetMileageHistoryResponseDto
} from '../../_models';
import { ClaimsHeaderService } from '../../shared/claimsHeader/claims-header.service';

@Injectable({
  providedIn: 'root'
})
export class BasicMileageService {
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) {
    //this.httpHeaders = this.header.createAuthorizationHeader();
  }
  SaveOrgBasicMileage(requestinfo: IApiRequest<ISaveMileageRequestDto>): Observable<IApiresponse<ISaveMileageResponseDto>> {
    let body = JSON.stringify(requestinfo);
    //let token = localStorage.getItem('token');
    //this.httpHeaders.append('Authorization', token);
    return this.http.post<IApiresponse<ISaveMileageResponseDto>>(`${environment.billingApiUrl}SaveOrgBasicMileage`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetBasicMileageHistory(request: IApiRequest<IBasicMileageRequestDto>): Observable<IApiresponse<IGetMileageHistoryResponseDto>> {
    let body = JSON.stringify(request);
    return this.http.post<IApiresponse<IGetMileageHistoryResponseDto>>(`${environment.billingApiUrl}GetBasicMileageHistory`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
}
