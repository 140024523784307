export interface IStripeSessionResponse {
  id: string;
}
export class StripeSessionResponse implements IStripeSessionResponse {
  id: string;
}

export interface IStripeSessionRequest {
  PageUrl: string;
  CustomerEmail: string;
  currencyCode: string;
}
export class StripeSessionRequest implements IStripeSessionRequest {
  PageUrl: string;
  CustomerEmail: string;
  currencyCode: string;
  constructor(PageUrl: string, CustomerEmail: string, currencyCode: string) {
    this.PageUrl = PageUrl;
    this.CustomerEmail = CustomerEmail;
    this.currencyCode = currencyCode;
  }
}

export interface IStripeGenerateInvoiceRequest {
  CustomerId: string;
  InvoiceItemList: StripeInvoiceItem[];
  BillingInvoiceFor: string;
}
export class StripeGenerateInvoiceRequest
  implements IStripeGenerateInvoiceRequest {
  CustomerId: string;
  InvoiceItemList: StripeInvoiceItem[];
  BillingInvoiceFor: string;
  constructor(
    CustomerId: string,
    InvoiceItemList: StripeInvoiceItem[],
    BillingInvoiceFor: string
  ) {
    this.CustomerId = CustomerId;
    this.InvoiceItemList = InvoiceItemList;
    this.BillingInvoiceFor = BillingInvoiceFor;
  }
}

export interface IStripeInvoiceItem {
  Customer: string;
  Amount: number;
  Currency: string;
  Description: string;
}
export class StripeInvoiceItem implements IStripeInvoiceItem {
  Customer: string;
  Amount: number;
  Currency: string;
  Description: string;
}

export interface IStripeRegisterUserResponse {
  customerId: string;
  paymentMethodId: string;
  cardBrand: string;
  cardType: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardLastFourDigits: string;
}
export class StripeRegisterUserResponse implements IStripeRegisterUserResponse {
  customerId: string;
  paymentMethodId: string;
  cardBrand: string;
  cardType: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardLastFourDigits: string;
}

export interface IStripeChangeUserCardRequest {
  SessionId: string;
  CustomerId: string;
  CurrencyCode: string;
}
export class StripeChangeUserCardRequest
  implements IStripeChangeUserCardRequest {
  SessionId: string;
  CustomerId: string;
  CurrencyCode: string;
  constructor(SessionId: string, CustomerId: string, CurrencyCode: string) {
    this.SessionId = SessionId;
    this.CustomerId = CustomerId;
    this.CurrencyCode = CurrencyCode;
  }
}

export interface IStripeInstantPaymentInvoiceItem {
  Name: string;
  Description: string;
  Amount: number;
  Currency: string;
  Quantity: number;
}
export class StripeInstantPaymentInvoiceItem
  implements IStripeInstantPaymentInvoiceItem {
  Name: string;
  Description: string;
  Amount: number;
  Currency: string;
  Quantity: number;
}

export interface IStripeInstantPaymentSessionRequest {
  PageUrl: string;
  CustomerId: string;
  CustomerEmail: string;
  currencyCode: string;
  InvoiceItemList: StripeInstantPaymentInvoiceItem[];
}
export class StripeInstantPaymentSessionRequest
  implements IStripeInstantPaymentSessionRequest {
  PageUrl: string;
  CustomerId: string;
  CustomerEmail: string;
  currencyCode: string;
  InvoiceItemList: StripeInstantPaymentInvoiceItem[];
  constructor(
    PageUrl: string,
    CustomerId: string,
    CustomerEmail: string,
    currencyCode: string,
    InvoiceItemList: StripeInstantPaymentInvoiceItem[]
  ) {
    this.PageUrl = PageUrl;
    this.CustomerId = CustomerId;
    this.CustomerEmail = CustomerEmail;
    this.currencyCode = currencyCode;
    this.InvoiceItemList = InvoiceItemList;
  }
}

export interface IStripeInstantPaymentRegisterUserResponse {
  customerId: string;
  paymentMethodId: string;
}
export class StripeInstantPaymentRegisterUserResponse
  implements IStripeInstantPaymentRegisterUserResponse {
  customerId: string;
  paymentMethodId: string;
}
