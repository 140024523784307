import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiresponse } from "src/app/_models/common/request-response/apiresponse";
import { IApiRequest } from "src/app/_models/common/request-response/api-request";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import {IGradesForDelegateSetupResponse,IUsersForActivateDelegatorDelegateResponse,
    IUsersForActivateDelegatorDelegateRequest,ISaveDelegatorDelegateByGradeRequest,
    ISaveDelegatorDelegateRequest,IDelegateListForAssignmentResponse,
    IAssignDelegateToDelegatorRequest,IDelegatorsDetailsRequest,
    IDelegatorsDetailsResponse,IDelegateListForAssignmentRequest,
    IClaimantDelegatorListRequest,IUserDelegatorDelegateStatusRequest,
    IUserDelegatorDelegateStatusResponse,IBulkAssignDelegateToDelegatorRequest
} from  "src/app/_models/delegate/delegate";
import { RSAHelper } from 'src/app/_helpers/RSAHelper';

@Injectable({
    providedIn: "root",
})
export class DelegateService {
    httpHeaders: HttpHeaders;
    constructor(
        private http: HttpClient,
        private claimsHeaderService: ClaimsHeaderService
    ) { }

    GetGradesForDelegateSetup(organisationId: number): Observable<IApiresponse<IGradesForDelegateSetupResponse[]>> {
        let params = new HttpParams();
        params = params.append("organisationId", organisationId.toString());
        return this.http.get<IApiresponse<IGradesForDelegateSetupResponse[]>>(
            `${environment.billingApiUrl}GetGradesForDelegateSetup`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    GetUsersForActivateDelegatorDelegate(requestInfo: IApiRequest<IUsersForActivateDelegatorDelegateRequest>): Observable<IApiresponse<IUsersForActivateDelegatorDelegateResponse[]>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IUsersForActivateDelegatorDelegateResponse[]>>(
            `${environment.billingApiUrl}GetUsersForActivateDelegatorDelegate`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    SaveDelegatorDelegateByGrade(requestInfo: IApiRequest<ISaveDelegatorDelegateByGradeRequest>): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}SaveDelegatorDelegateByGrade`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    
    SaveDelegatorDelegate(requestInfo: IApiRequest<ISaveDelegatorDelegateRequest>): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}SaveDelegatorDelegate`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    GetActiveDelegatorsDetails(organisationId: number): Observable<IApiresponse<IDelegatorsDetailsResponse[]>> {
        let params = new HttpParams();
        params = params.append("organisationId", organisationId.toString());
        return this.http.get<IApiresponse<IDelegatorsDetailsResponse[]>>(
            `${environment.billingApiUrl}GetActiveDelegatorsDetails`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    GetDelegateListForAssignment(requestInfo: IApiRequest<IDelegateListForAssignmentRequest>): Observable<IApiresponse<IDelegateListForAssignmentResponse[]>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IDelegateListForAssignmentResponse[]>>(
            `${environment.billingApiUrl}GetDelegateListForAssignment`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    AssignDelegateToDelegator(requestInfo: IApiRequest<IAssignDelegateToDelegatorRequest>): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}AssignDelegateToDelegator`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    GetDelegatorDetailsById(requestInfo: IApiRequest<IDelegatorsDetailsRequest>): Observable<IApiresponse<IDelegatorsDetailsResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IDelegatorsDetailsResponse>>(
            `${environment.billingApiUrl}GetDelegatorDetailsById`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }


    GetClaimantDelegatorList(requestInfo: IApiRequest<IClaimantDelegatorListRequest>): Observable<IApiresponse<IDelegatorsDetailsResponse[]>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IDelegatorsDetailsResponse[]>>(
            `${environment.billingApiUrl}GetClaimantDelegatorList`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    EncryptParameters(encryptValue) {
        let rsaHelper = new RSAHelper();
        let encrypt = rsaHelper.Encrypt(encryptValue);
        return encrypt;
      }
      DecryptParameters(encryptedParam): string {
        let rsaHelper = new RSAHelper();
        let decrypt = rsaHelper.Decrypt(encryptedParam);
        return decrypt;
      }

      GetUserDelegatorDelegateStatus(requestInfo: IApiRequest<IUserDelegatorDelegateStatusRequest>): Observable<IApiresponse<IUserDelegatorDelegateStatusResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IUserDelegatorDelegateStatusResponse>>(
            `${environment.billingApiUrl}GetUserDelegatorDelegateStatus`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

    BulkAssignDelegateToDelegator(requestInfo: IApiRequest<IBulkAssignDelegateToDelegatorRequest>): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}BulkAssignDelegateToDelegator`, body, 
            { 
                headers: this.claimsHeaderService.createLoginAuthorizationHeader() 
            });
    }

}


