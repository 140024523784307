import { NgModule } from "@angular/core";
import { NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InformationAlertPanelComponent } from "./information-alert-panel.component";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        NgbModule,
        NgbTooltipModule,
    ],
    declarations: [
        InformationAlertPanelComponent
    ],
    providers: [],
    exports: [
        NgbTooltipModule,
        InformationAlertPanelComponent
    ]
})
export class InformationAlertPanelModule {
    constructor() { }
}
