<span [hidden]="!toolTipText" placement="right" [ngbPopover]="popContent" container="body"
  triggers="mouseenter:mouseleave"> {{ text }}
  <i style="padding-left: 5px" class="fas fa-info-circle text-mustard cursor-pointer"></i>
</span>

<span [hidden]="toolTipText">
  {{ text }}
</span>

<ng-template #popContent>
  <div [innerHTML]="sanitizerHtml(toolTipText)"></div>
</ng-template>
