<div class="receipt-container mx-auto width-auto h-100" #receiptImageContainer>
  <div
    class="add-receipt align-center-middle text-center w-100pc"
    #receiptContainer
    *ngIf="totalReceipts?.length > 0"
    style="height: 100% !important">
    <img
      #receiptImage
      [hidden]="
        seletedItem?.receiptFormatId == this.attachmentTypeEnum.Pdf &&
        totalReceipts?.length > 0
      "
      id="{{ seletedItem.expenseReceiptId }}"
      [src]="seletedItem.receiptUrl"
      (load)="stopSpinner()"
      (dblclick)="showImgPopup()"
      data-bs-toggle="modal"
      data-bs-target="#receiptPopup" />

    <div
      class="d-flex h-100 align-items-center justify-content-center w-100pc"
      *ngIf="seletedItem.receiptFormatId == this.attachmentTypeEnum.Pdf">
      <div>
        <p class="border padding-10 text-center">
          {{ seletedItem.receiptName }}
        </p>
        <button
          class="btn bg-warning mt-2 border-0"
          (click)="fnShowSelectedReceipt(seletedItem, null)">
          {{ "view_pdf" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    class="add-receipt d-flex flex-column justify-content-center align-items-center text-center w-100pc"
    #receiptContainer
    *ngIf="totalReceipts?.length < 1 && !readOnlyMode"    
    style="height: 100% !important">
    <button
      *ngIf="totalReceipts?.length < 1 && !readOnlyMode && !isCreditCardExpense"
      class="align-center-middle"
      (click)="fnShowAttachReceiptOptionsPanel()">
      <span class="d-block"
        ><i class="fas fa-plus-circle f-45 text-mustard"></i
      ></span>
      <span
        *ngIf="this.claimType == 1"
        class="d-block f-17 margin-top-10 text-charcoal-black">
        {{ "text_add_receipt" | translate : param }}
      </span>
      <span
        *ngIf="this.claimType == 2"
        class="d-block f-17 margin-top-10 text-charcoal-black">
        {{ "text_add_invoice_receipt" | translate : param }}
      </span>
    </button>

    <button *ngIf="totalReceipts?.length < 1 && !readOnlyMode && isCreditCardExpense"
      class="btn btn-lg eod-btn-warning fw-600 d-flex align-items-center mb-4 w-53pc" (click)="matchReceipt()">
      <img class="mr-3" *ngIf="this.claimType == 1" src="../../../../assets/svg-icons/scan-receipt.svg" alt="" width="20px">
      <span *ngIf="this.claimType == 1" class="">
        {{ "match_receipt_automatically" | translate : param }}
      </span>
    </button>

    <button *ngIf="totalReceipts?.length < 1 && !readOnlyMode && isCreditCardExpense"
      class="btn btn-lg eod-btn-outline-primary fw-600 w-53pc text-left" (click)="fnShowAttachReceiptOptionsPanel()">
      <i *ngIf="this.claimType == 1" class="fas fa-plus-circle pr-2 text-start"></i>
      <span *ngIf="this.claimType == 1" class="">
        {{ "upload_receipt_manually" | translate : param }}
      </span>
    </button>
  </div>
  <!-- <div class="h-100 d-flex flex-column align-items-center justify-content-center">
        <img src="../../../../assets/svg-icons/no-receipt.svg" alt="" width="70px">
        <p class="f-18 mt-3">No Receipt Available</p>
    </div> -->
</div>
<div
  class="add-receipt-section d-flex align-items-center justify-content-between px-3"
  *ngIf="totalReceipts?.length > 0">
  <div class="d-flex">
    <div
      class="add-more-receipt position-relative cursor-pointer"
      [ngClass]="{
        active: seletedItem.expenseReceiptId == rec.expenseReceiptId
      }"
      *ngFor="let rec of totalReceipts; let i = index"
      (click)="fnShowSelectedReceipt(rec, i)">
      <img
        alt="receipt"
        *ngIf="rec.receiptFormatId == this.attachmentTypeEnum.Image"
        class="added-recipt-thumb"
        src="{{ rec.receiptUrl }}"
        alt="" />
      <i
        class="far fa-file-pdf trasform-center eod-text-danger"
        *ngIf="rec.receiptFormatId == this.attachmentTypeEnum.Pdf"></i>
    </div>
    <div
      class="add-more-receipt align-items-center justify-content-center cursor-pointer"
      (click)="fnShowAttachReceiptOptionsPanel()"
      *ngIf="totalReceipts?.length < 5 && !readOnlyMode">
      <i class="fas fa-plus-circle"></i>
    </div>
  </div>
  <div class="d-flex text-center">
    <div class="v-line height-30px"></div>
    <div class="d-flex" *ngIf="!readOnlyMode">
      <span class="left-rotate cursor-pointer" (click)="rotateImg(90)"
        ><i class="fas fa-undo"></i
      ></span>
      <span class="right-rotate cursor-pointer" (click)="rotateImg(-90)"
        ><i class="fas fa-redo"></i
      ></span>
    </div>
    <div class="mx-2 d-flex">
      <span class="zoom-in cursor-pointer" (click)="zoomIn()"
        ><i class="fas fa-search-plus"></i
      ></span>
      <span class="zoom-out cursor-pointer" (click)="zoomOut()"
        ><i class="fas fa-search-minus"></i
      ></span>
    </div>
    <div *ngIf="!readOnlyMode">
      <span
        class="trash eod-text-danger cursor-pointer"
        (click)="fnShowDeleteReceiptAlert()">
        <i
          tooltipclass="my-custom-class-recall"
          placement="left"
          container="body"
          class="fal fa-trash-alt eod-text-danger"></i>
      </span>
    </div>
    <div *ngIf="seletedItem?.receiptFormatId == this.attachmentTypeEnum.Image">
      <span (click)="downloadReceipt()" class="zoom-in cursor-pointer">
        <i class="fas fa-file-download"></i>
      </span>
    </div>
  </div>
</div>

<attach-receipt-options-panel
  *ngIf="showAttachReceiptOptionsPanel"
  (closePanelEvent)="showAttachReceiptOptionsPanel = false"
  (showFilePickerEvent)="fnShowFilePicker($event)"
  (showExpenseMergePanelEvent)="fnShowExpenseMergePanel($event)">
</attach-receipt-options-panel>

<input
  type="file"
  accept=".pdf,.jpg,.jpeg,.png"
  id="receiptUpload"
  name="receiptUpload"
  class="fileUpload d-none"
  (change)="readUploadedFile($event)"
  (click)="resetFileControl($event)"
  multiple />

<app-alert-panel
  *ngIf="showReceiptFormatAlertPanel"
  [data]="receiptFormatAlertPanelModel"
  (callbackEvent)="fnReceiptFormatAlertPanelReceiver($event)">
</app-alert-panel>

<app-alert-panel
  *ngIf="showReceiptLimitAlertPopup"
  [data]="receiptLimitAlertPopupModel"
  (callbackEvent)="fnReceiptLimitPopupReceiver($event)">
</app-alert-panel>

<app-alert-panel
  [data]="alertPanelModelForDeleteReceipt"
  (callbackEvent)="multiBtnAlertPanelReceiver($event)">
</app-alert-panel>

<app-pdf-viewer
  *ngIf="showPdfViewer"
  [showPdfViewerPanel]="showPdfViewer"
  [pdfUrl]="pdfSrc"
  [pdfName]="pdfName"
  [pdfExpenseReceiptId]="pdfExpenseReceiptId"
  [showDeleteButton]="showDeleteButton"
  (callbackEvent)="fnPdfViewerReceiver($event)"
  (deletePdfEvent)="fnDeletePdf($event)"
  [isOverLayHide]="true"
  [panelWidth]="pdfPanelWidth"
  [isClaimPage]="isClaimPage">
</app-pdf-viewer>

<app-alert-panel
  *ngIf="showReceiptSizeLimitPopup"
  [data]="receiptSizeLimitPopupModel"
  (callbackEvent)="fnReceiptSizeLimitPopupReceiver($event)">
</app-alert-panel>

<app-alert-panel
  *ngIf="showReceiptFormatAlertPanel"
  [data]="receiptFormatAlertPanelModel"
  (callbackEvent)="fnReceiptFormatAlertPanelReceiver($event)">
</app-alert-panel>

<!-- Modal -->
<div
  class="modal fade"
  *ngIf="seletedItem.receiptFormatId == this.attachmentTypeEnum.Image"
  id="receiptPopup"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="false">
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div
        class="modal-header eod-bg-white"
        style="border-bottom: none !important"
        id="header"
        style="cursor: move">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="{{ 'Close' | translate }}"
          (click)="imgPopup = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center pt-0">
        <img
          alt="receipt"
          id="{{ seletedItem.expenseReceiptId }}"
          [src]="seletedItem.receiptUrl" />
      </div>
    </div>
  </div>
</div>

<app-img-preview-panel
  *ngIf="showPreviewPanel"
  [scannedText]="scannedText"
  [previewImageUrl]="previewImageUrl"
  [selectedItem]="seletedItem"
  (closePanelEvent)="fnClosePreviewPanel($event)"
  (savePanelEvent)="fnSavePreviewPanel($event)"></app-img-preview-panel>

<div class="overLayDiv active" *ngIf="showReceiptScanInfoPanel"></div>
<div id="rightSideBar" [hidden]="!showReceiptScanInfoPanel">
  <div class="rightSidePanel active w-50pc" style="z-index: 99999">
    <header
      class="py-1 d-flex justify-content-between align-items-center height-60px">
      <h2>{{ "upload_receipt_pdf" | translate }}</h2>
      <div>
        <button
          (click)="closeScanReceiptPanel()"
          class="btn eod-btn-outline-warning height-38px mr-2">
          {{ "delete" | translate }}
        </button>

        <button
          *ngIf="showScanOption"
          (click)="SaveImageWithoutScan(true)"
          class="btn eod-btn-outline-warning height-38px mr-2">
          {{ "scan_receipt" | translate }}
        </button>

        <button
          (click)="SaveImageWithoutScan(false)"
          class="btn eod-btn-primary height-38px mr-2">
          {{ "save_receipt" | translate }}
        </button>
      </div>
    </header>
    <div class="block padding-top-10 heightCalc-10">
      <div class="row">
        <div
          class="col-lg-12 text-center no-padding-right-left heightCalc-11 overflow-auto scrollbar-visible">
          <img
            *ngIf="!isPdfReceipt"
            alt="receipt"
            id="{{ scanReceiptPDFUrl }}"
            [src]="scanReceiptPDFUrl" />

          <embed
            *ngIf="isPdfReceipt"
            [attr.src]="scanReceiptPDFUrl | safeUrl"
            type="application/pdf"
            width="100%"
            height="600px" />
        </div>
      </div>
    </div>
  </div>
</div>


<app-alert-panel
  *ngIf="showScanOverrideAlertPanel"
  [data]="receiptScanOverrideAlertPanelModel"
  (callbackEvent)="fnReceiptScanOverrideAlertPanelReceiver($event)">
</app-alert-panel>