<span [hidden]="!toolTipText" class="cursor-pointer" placement="left" [ngbPopover]="popContent" container="body"
  triggers="mouseenter:mouseleave"> {{ text }}
  <i style="padding-left: 5px" class="fas fa-info-circle text-mustard"></i>
</span>

<span [hidden]="toolTipText" class="cursor-pointer">
  {{ text }}
</span>

<ng-template #popContent>
  <div [innerHTML]="sanitizerHtml(toolTipText)"></div>
</ng-template>