<div class="overLayDiv active"></div>
<div id="rightSideBar">
  <form [formGroup]="foreignCurrencyForm" novalidate>
    <div class="rightSidePanel active" style="z-index: 99999;">
      <header>
        <div class="row">
          <div class="col-lg-12 no-padding-right-left">
            <div class="row">
              <div class="col-lg-8 padding-left-0">
                <h2>{{ "select_currency" | translate: param }}</h2>
              </div>

              <div class="col-lg-4 padding-right-0 header-icons text-right">
                <ul class="d-flex justify-content-end w-100pc">
                  <li>
                    <Button class="btn btn-primary height-38px margin-right-15" [ngClass]="{
                      'disabled bg-secondary opacity-60':
                        disableSubmitButton || !this.foreignCurrencyForm.dirty
                      }" (click)="fnSubmitForeignCurrency()"> 
                      {{ 'label_save' | translate: param }}
                    </Button>
                  </li>
                  <li (click)="fnClosePanel()">
                    <Button class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                      {{ 'label_close' | translate: param }}
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="clear"></div>
      <div class="col-lg-12 margin-top-0 no-padding-right-left">
        <div class="block">
          <div class="row margin-top-20">
            <div class="col-lg-6 padding-left-0">
              <div class="input-group disabled">
                <span class="position-absolute left-0px top-3px" *ngIf="inputData.baseCurrencyIcon">
                  <img src="{{baseCurrency.icon}}" alt="" class="position-relative w-30px height-30px z-9999">
                </span>
                <input type="text" class="form-control text-ellipsis-approver
                 has-value padding-left-35 color-light-grey-disabledType" formControlName="currencyCode" [ngClass]="{
                    'has-value': foreignCurrencyForm.controls.currencyCode.value
                  }" />

                <label for="ExpenseCategory">{{ "base_currency" | translate: param }}
                </label>

                <div *ngIf="
                    foreignCurrencyForm.controls.currencyCode.invalid &&
                    isForeignCurrencyFormSubmitted
                  " class="alert-danger">
                  <span *ngIf="
                      foreignCurrencyForm.controls.currencyCode.errors
                        .required ||
                      foreignCurrencyForm.controls.currencyCode.value.trim() ==
                        ''
                    ">
                    <b>{{ "label_Required" | translate: param }}</b>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 padding-right-0">
              <div class="input-group">
                <ng-select class="solo-dropdown filterDropdown" [items]="foreignCurrencyList" bindLabel="currencyDescription"
                  bindValue="currencyId" formControlName="spentCurrencyId" (change)="
                    fnGetSpentCurrencyCode(
                      foreignCurrencyForm.controls.spentCurrencyId.value
                    )
                  " placeholder="Spent Currency" [selectOnTab]="true"
                  notFoundText="{{ 'no_data_available' | translate: param }}" required-field [clearable]="false"
                  [clearOnBackspace]="false">
                  <ng-template ng-label-tmp let-item="item">
                    <img alt="icon" class="height-20px" [src]="item.icon" />
                    {{ item.currencyDescription }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      <img alt="icon" class="height-20px" src="{{ item.icon }}" />&nbsp;&nbsp;
                      {{ item.currencyDescription }}
                    </div>
                  </ng-template>
                </ng-select>
                <div *ngIf="
                    foreignCurrencyForm.controls.spentCurrencyId.invalid &&
                    isForeignCurrencyFormSubmitted
                  " class="alert-danger">
                  <div *ngIf="foreignCurrencyForm.controls.spentCurrencyId.invalid">
                    <b>{{ "label_Required" | translate: param }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row margin-top-30">
            <div class="col-lg-6 padding-left-0">
              <div class="input-group">
                <input type="text" class="form-control has-value searchField" [readonly]="fxRateRule == FxRateRule.NotEditable && foreignCurrencyForm.controls.conversionRate.value>1" sixDigitBeforeAndAfterDecimalNumber
                  formControlName="conversionRate" [ngClass]="{
                    'has-value':
                      foreignCurrencyForm.controls.conversionRate.value
                  }" >
                <label for="ExpenseCategory">{{ "rate" | translate: param }}<span>*</span>
                </label>

                <div *ngIf="
                    foreignCurrencyForm.controls.conversionRate.invalid &&
                    isForeignCurrencyFormSubmitted
                  " class="alert-danger">
                  <span *ngIf="
                      foreignCurrencyForm.controls.conversionRate.errors
                        .required ||
                      foreignCurrencyForm.controls.conversionRate.value.trim() ==
                        ''
                    ">
                    <b>{{ "label_Required" | translate: param }}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block padding-top-15" *ngIf="
            foreignCurrencyForm.controls.conversionRate.value > 0 &&
            foreignCurrencyForm.controls.spentCurrencyId.value > 0
          ">
          <h6 class="border-bottom padding-bottom-10">
            {{ "exchange_rates" | translate: param }}
          </h6>
          <div class="row">
            <div class="col-lg-12 no-padding-right-left">
              <span class="position-absolute left-0px top-3px"><i
                  class="fas fa-info-circle text-mustard f-16"></i></span>
              <div class="padding-left-30">
                <p class="w-100pc">
                  <span class="d-inline-block padding-right-18 f-14 w-35pc">
                    {{ this.devideValue }}
                    {{ foreignCurrencyForm.controls.currencyCode.value }}</span>=<span class="
                      d-inline-block
                      w-50pc
                      padding-left-30
                      fw-700
                      text-right
                    ">{{ this.fnCalculateConversionRates() }}
                    {{
                    foreignCurrencyForm.controls.spentCurrencyCode.value
                    }}</span>
                </p>
                <p class="w-100pc">
                  <span class="d-inline-block padding-right-15 f-14 w-35pc">{{ this.devideValue }}
                    {{
                    foreignCurrencyForm.controls.spentCurrencyCode.value
                    }} </span>=<span class="
                      d-inline-block
                      w-50pc
                      padding-left-30
                      fw-700
                      text-right 
                    ">{{ foreignCurrencyForm.controls.conversionRate.value }}
                    {{ foreignCurrencyForm.controls.currencyCode.value }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="block color-red" [hidden]="fxRateRule ==FxRateRule.Default">
          <span><strong>{{ "disclaimer" | translate: param }}:</strong></span>
           <ul class="info-panel">
              <li class="border-bottom  ">
                  <p class="">  <span><i class="fas fa-info-circle color-red f-12" aria-hidden="true"></i></span>  <span class="padding-left-5">{{ "disclaimer_msg" | translate: param }}</span>   </p>
              </li>          
          </ul>
        </div>
        <div class="clear">&nbsp;</div>
        <div class="mt-3">
          <div class="col-lg-8 mx-auto no-padding-right-left">
            <div class="bg-white border padding-15 border-primary box-shadow">
              <h6 class="fw-700">
                ** {{ "currency_reminder" | translate: param }} **
              </h6>
              <p class="mt-3 lH-20">
                {{ "currency_text1" | translate: param }}
              </p>
              <p class="mt-3 fw-700">{{ "thanks" | translate: param }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>