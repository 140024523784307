import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Apiresponse, IApiRequest, IApiresponse } from "src/app/_models";
import {
  AdvanceClaimantListResponse, AdvanceListResponse, OrganisationAdvancesSettings, AdvanceApproverActionRequest,
  AdvanceDetailResponse, IAdvanceRequest, AdvancePassbookData, AdvanceReturnSaveRequest, AdvancePassbookSummaryData,
  AdvancePassbookItem,
  SendAdvanceReturnRequest,
  AdvanceSaveRequest,
  AdvanceCappingPolicy,
  AdvanceClaimantCappingPolicy,
  AdvanceSubmitResponse,
  AdvancePolicyDashboardData,
  SaveAdvanceGradeCappingPolicy
} from "../../_models/advances/advances";
import { ApiRequest } from "src/app/_models";
import { RSAHelper } from "src/app/_helpers/RSAHelper";
import { map } from "rxjs/operators";
import { IReceiptInfo } from "src/app/_models/twostepapproval/claimant-expense-detail";

@Injectable({
  providedIn: "root",
})
export class AdvancesService {

  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) {

  }

  saveAdvanceSettings(request: ApiRequest<OrganisationAdvancesSettings>) {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.billingApiUrl}SaveAdvanceSettings`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getAdvanceSettings(): Observable<IApiresponse<OrganisationAdvancesSettings>> {
    return this.http.get<IApiresponse<OrganisationAdvancesSettings>>(
      `${environment.billingApiUrl}GetAdvanceSettings`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  switchAdvanceCappingPolicy(): Observable<Apiresponse<boolean>> {
    return this.http.post<Apiresponse<boolean>>(
      `${environment.billingApiUrl}SwitchAdvanceCappingPolicy`,
      null,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  saveClaimantCappingPolicy(request: ApiRequest<AdvanceClaimantCappingPolicy[]>): Observable<Apiresponse<boolean>> {
    let body = JSON.stringify(request);
    return this.http.post<Apiresponse<boolean>>(
      `${environment.billingApiUrl}SaveAdvanceClaimantCappingPolicy`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  saveGradeCappingPolicy(request: ApiRequest<SaveAdvanceGradeCappingPolicy>): Observable<Apiresponse<boolean>> {
    let body = JSON.stringify(request);
    return this.http.post<Apiresponse<boolean>>(
      `${environment.billingApiUrl}SaveAdvanceGradeCappingPolicy`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getAdvanceCappingPolicy(): Observable<IApiresponse<AdvanceCappingPolicy>> {
    return this.http.get<IApiresponse<AdvanceCappingPolicy>>(
      `${environment.billingApiUrl}GetAdvanceCappingPolicyForOrg`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvancesClaimantList(status): Observable<IApiresponse<AdvanceClaimantListResponse>> {
    return this.http.get<IApiresponse<AdvanceClaimantListResponse>>(
      `${environment.expenseApiUrl}GetAdvancesClaimantList?status=${status}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvancesListForClaimant(status, userId, userHomeCurrencyId): Observable<IApiresponse<AdvanceListResponse>> {
    return this.http.get<IApiresponse<AdvanceListResponse>>(
      `${environment.expenseApiUrl}GetAdvancesListForClaimant?status=${status}&userId=${userId}&uhcId=${userHomeCurrencyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  submitAdvanceApproverAction(request: IApiRequest<AdvanceApproverActionRequest>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.expenseApiUrl}SubmitAdvanceApproverAction`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getAdvanceDetails(advanceId): Observable<IApiresponse<AdvanceDetailResponse>> {
    return this.http.get<IApiresponse<AdvanceDetailResponse>>(
      `${environment.expenseApiUrl}GetAdvanceDetails?advanceId=${advanceId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvanceReceipts(advanceId): Observable<IApiresponse<IReceiptInfo[]>> {
    return this.http.get<IApiresponse<IReceiptInfo[]>>(
      `${environment.expenseApiUrl}GetAdvanceReceipts?advanceId=${advanceId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getExpenseReceipts(expenseId): Observable<IApiresponse<IReceiptInfo[]>> {
    return this.http.get<IApiresponse<IReceiptInfo[]>>(
      `${environment.expenseApiUrl}GetExpenseReceipts?expenseId=${expenseId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvancesPassbook(userId): Observable<AdvancePassbookItem[]> {
    return this.http.get<IApiresponse<AdvancePassbookData>>(
      `${environment.expenseApiUrl}GetAdvancesPassbook?userId=${userId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    ).pipe(map((result) => {
      return this.mapAdvance(result.responseInfo.advancePassbook);
    }));
  }

  getAdvancesPassbookNotLinked(userId): Observable<AdvancePassbookItem[]> {
    return this.http.get<IApiresponse<AdvancePassbookData>>(
      `${environment.expenseApiUrl}GetAdvancesPassbookNotLinked?userId=${userId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    ).pipe(map((result) => {
      return this.mapAdvance(result.responseInfo.advancePassbook);
    }));
  }

  mapAdvance(advanceData): AdvancePassbookItem[] {
    const result: AdvancePassbookItem[] = [];
    advanceData.forEach(row => {
      if (row && row.api) {
        result.push({
          advancePassbookId: row.api,
          advanceId: row.ai,
          advanceReturnId: row.ari,
          expenseId: row.ei,
          categoryId: row.ca,
          amount: row.a,
          currencyId: row.ci,
          createdDate: row.cd,
          advanceBalance: row.ab,
          spentAmount: row.sa,
          spentCurrencyId: row.sci,
          conversionRate: row.cr,
          hasReceipt: row.hr,
          isLinkedToCreditCardTransaction: row.iltcct,
          extraAmount: row.exa
        });
      }
    });
    return result;
  }

  getAdvancesPolicyDashboard(): Observable<IApiresponse<AdvancePolicyDashboardData>> {
    return this.http.get<IApiresponse<AdvancePolicyDashboardData>>(
      `${environment.expenseApiUrl}GetAdvancesPolicyDashboardData`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvancesPassbookSummary(): Observable<IApiresponse<AdvancePassbookSummaryData>> {
    return this.http.get<IApiresponse<AdvancePassbookSummaryData>>(
      `${environment.expenseApiUrl}GetAdvancesPassbookSummary`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvancePassbookBalance(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetAdvancesPassbookBalance`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  getAdvanceReturnRequests(userId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.expenseApiUrl}GetAdvanceReturnRequest?userId=${userId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  sendAdvanceReturnRequest(request: ApiRequest<SendAdvanceReturnRequest>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.expenseApiUrl}SendAdvanceReturnRequest`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  saveAdvanceReturn(request: IApiRequest<AdvanceReturnSaveRequest>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.expenseApiUrl}SaveAdvanceReturn`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SubmitAdvance(request: IApiRequest<AdvanceSaveRequest>): Observable<IApiresponse<AdvanceSubmitResponse>> {
    let body = JSON.stringify(request);
    return this.http.post<IApiresponse<AdvanceSubmitResponse>>(
      `${environment.expenseApiUrl}SubmitAdvance`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SaveAdvanceReceipts(requestInfo: IApiRequest<IAdvanceRequest>):
    Observable<IApiresponse<any>> {
    let formData = new FormData();
    for (let index = 0; index < requestInfo.requestInfo.receiptFiles.length; index++) {
      formData.append('receiptFiles', requestInfo.requestInfo.receiptFiles[index]);
    }
    formData.append('requestData', requestInfo.requestInfo.requestData);
    formData.append('content', requestInfo.requestInfo.content);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}SaveMediaForMultipleAdvances`,
      formData,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
    );
  }

  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }

  DecryptParameters(encryptedParam): string {
    let rsaHelper = new RSAHelper();
    let decrypt = rsaHelper.Decrypt(encryptedParam);
    return decrypt;
  }

  deleteAdvance(advanceId): Observable<any> {

    return this.http.get<any>(
      `${environment.expenseApiUrl}DeleteAdvance?advanceId=${advanceId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  isAdvanceBalanceOutstanding(): Observable<any> {
    return this.http.get<any>(
      `${environment.expenseApiUrl}IsAdvanceBalanceOutstanding`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

}
