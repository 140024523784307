import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'day-policy-renderer',
    templateUrl: './day-policy-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DayPolicyRenderer implements ICellRendererAngularComp {
    data: any;
    violationTooltip: string;

    constructor(private translate: TranslateService) {
    }

    initilize(params: ICellRendererParams) {
        this.data = params.getValue();
        this.violationTooltip = this.translate.instant('expense_policy_violation');
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
}