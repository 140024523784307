<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{'text_select' | translate:param}} {{this.businessPurposeCaption}}</h2>
            <div>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
                    {{ 'label_close' | translate: param }}
                </button>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block padding-top-0 ">
                    <div class="row margin-top-25">
                        <div class="col-lg-6 padding-left-0">
                            <div class="input-group">
                                <input type="text" class="form-control searchField" #searchField
                                    [ngClass]="{ 'has-value': searchField.value }" name="searchText"
                                    [(ngModel)]="searchText" autocomplete="off">
                                <label for="ExpenseCategory">{{ "label_search_criterion" | translate: param }}</label>
                                <span class="icons"><a>
                                        <i class="fas fa-search" aria-hidden="true"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block padding-top-10 heightCalc-12 overflow-auto scrollbar-visible">
                    <div class="row">
                        <div class="col-lg-12 no-padding-right-left">

                            <div class="text-center noDataAvailable disabled opacity-60"
                                *ngIf="(bpValuesList | grdFilter : {bpValue: searchText}).length === 0">
                                {{ "no_data_available" | translate: param }}
                            </div>

                            <ul class="bulkList">
                                <li class="border-bottom padding-bottom-12  f-14 "
                                    *ngFor="let item of bpValuesList | grdFilter: { bpValue: searchText}"
                                    (click)="fnSelectBusinessPurposeValues(item)"
                                    [ngClass]="{'active': item.bpId == selectedBpId }">
                                    <span class="d-inline-block w-100pc text-ellipsis">{{item.bpValue}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
