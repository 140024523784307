<div class="input-group margin-top-20">
    <input id="{{ 'int' + fieldKey }}" dynamicDigitBeforeAndDynamicDigitAfterDecimalNumber 
    [beforeDecimal]="beforeDecimal" [afterDecimal]="afterDecimal" appBlockCopyPaste type="text" class="form-control"
        (paste)="$event.preventDefault()" (input)="onNumberInput()" [formControl]="formControl"
        [formlyAttributes]="field" (keypress)="onKeyPress($event)" autocomplete="off"
        [ngClass]="{'has-value': formControl.value || formControl.value==0, 'text-logo-blue': !to.disabled, 'color-light-grey-disabledType':to.disabled}">
    <label for="IntegerInput">{{field.templateOptions.label}}<span *ngIf="field.templateOptions.required">*</span>
    </label>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>
    <span *ngIf="field.templateOptions.customErrors">
        <div class="alert-danger" *ngFor="let error of field.templateOptions.customErrors">
            <span><strong>{{error}}</strong></span>
        </div>
    </span>
    <span *ngIf="field.templateOptions.supportLabel">
        <div class="alert-danger">
            <span><strong>{{field.templateOptions.supportLabel}}</strong></span>
        </div>
    </span>

</div>