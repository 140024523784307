import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { OrganisationSubscriptionInvoiceResponse } from '../../_models/billing-pricing/OrganisationSubscriptionInvoice'
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IApiRequest, IApiresponse } from '../../_models';
import { OrgSubscription,UpdateOrganisationSubscriptionInvoiceStatusRequest } from '../../_models/common/OrgSubscription';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
//import { setHeader } from 'src/app/_helpers/setRequestHeader';



@Injectable({
  providedIn: 'root'
})
export class OrganisationSubscriptionInvoiceService {

  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }

  GetOrganisationSubscriptionInvoiceList(requestinfo: IApiRequest<OrgSubscription>): Observable<IApiresponse<OrganisationSubscriptionInvoiceResponse[]>> {
    const url = `${environment.billingApiUrl}OrganisationSubscriptionInvoice`;
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<OrganisationSubscriptionInvoiceResponse[]>>(url, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrganisationInvoiceFailedRecords(orgId: number): Observable<IApiresponse<any>> {
    let params = new HttpParams();
    params = params.append("orgId", orgId.toString());
    return this.http.get<IApiresponse<any>>(`${environment.billingApiUrl}GetOrganisationInvoiceFailedRecords`, { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
}

UpdateOrganisationSubscriptionInvoiceStatus(requestinfo: IApiRequest<UpdateOrganisationSubscriptionInvoiceStatusRequest>): Observable<IApiresponse<any[]>> {
  const url = `${environment.billingApiUrl}UpdateOrganisationSubscriptionInvoiceStatus`;
  let body = JSON.stringify(requestinfo);
  return this.http.post<IApiresponse<any[]>>(url, body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}



}
