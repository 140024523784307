import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable, Subject } from "rxjs";
import { ClaimsService } from "./claims.service";
import { SignalRService } from "./signalr/signalr.service";
import { Notification } from "../../_models/common/notification";
import {
  BackgroundTask,
  BackgroundTaskStatus,
} from "../../_models/common/background-task";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService extends SignalRService {
  notificationAction: Subject<Notification> = new Subject();
  notifications: Subject<Notification> = new Subject();
  notificationSessionStorageKey: string = "NotificationActionModel";

  openNotificationPanel: boolean = false;

  constructor(
    oauthService: OAuthService,
    claimsService: ClaimsService,
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) {
    super(oauthService, claimsService);
  }

  submitBackgroundJob(data: BackgroundTask): Observable<string> {
    return this.http.post<string>(
      `${environment.catalogApiUrl}SubmitJob`,
      data,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getBackgroundJobStatus(jobId: string): Observable<BackgroundTaskStatus> {
    return this.http.get<BackgroundTaskStatus>(
      `${environment.catalogApiUrl}GetJobStatus?id=${jobId}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetRunningJobStatusByKey(key: string): Observable<BackgroundTaskStatus> {
    return this.http.get<BackgroundTaskStatus>(
      `${environment.catalogApiUrl}GetRunningJobStatusByKey?key=${key}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  markJobRemoved(jobId: string): Observable<any> {
    return this.http.get(
      `${environment.catalogApiUrl}MarkJobRemoved?id=${jobId}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  intiateListener() {
    this.hubConnection.on("notificationraised", (data: any) => {
      if (data) {
        const notification = JSON.parse(data) as Notification;
        this.notifications.next(notification);
      }
    });
  }

  GetNotificationsForUser(): Observable<Notification[]> {
    return this.http.get<Notification[]>(
      `${environment.functionsUrl}GetNotifications`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  MarkNotificationRead(notifications: Notification[]): Observable<any> {
    const notificationIds = notifications.map((n) => n.id);
    return this.http.post(
      `${environment.functionsUrl}MarkNotificationRead`,
      notificationIds,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SnoozeNotification(notification: Notification): Observable<any> {
    return this.http.post(
      `${environment.functionsUrl}SnoozeNotification`,
      notification,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  RemoveNotifications(notificationId: string): Observable<any> {
    return this.http.post(
      `${environment.functionsUrl}RemoveNotification`,
      notificationId,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  RemoveAllUserNotification(userId: string): Observable<any> {
    return this.http.post(
      `${environment.functionsUrl}RemoveAllUserNotification`,
      userId,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  setNotificationActionModel(notification: Notification) {
    sessionStorage.setItem(
      this.notificationSessionStorageKey,
      JSON.stringify(notification)
    );
    this.notificationAction.next(notification);
  }

  getNotificationAction(): Notification {
    const notificationAction = sessionStorage.getItem(
      this.notificationSessionStorageKey
    );
    let notification: Notification = null;
    if (notificationAction) {
      notification = JSON.parse(notificationAction) as Notification;
    }
    return notification;
  }

  onNotificationRaised(): Observable<Notification> {
    return this.notifications.asObservable();
  }

  onNotificationAction(): Observable<Notification> {
    return this.notificationAction.asObservable();
  }

  notificationActionCompleted() {
    sessionStorage.removeItem(this.notificationSessionStorageKey);
  }

  GetScanDocumentWithURI(url): Observable<any> {
    return this.http.get<Notification[]>(
      `${environment.functionsUrl}ScanDocumentWithURI?`+url,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ScanDocumentWithBase64(body): Observable<any> {
    return this.http.post(
      `${environment.functionsUrl}ScanDocumentWithBase64`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
