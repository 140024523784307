<h6 *ngIf="field.templateOptions.options && field.templateOptions.options[0]
&& field.templateOptions.options[0].isHeadingRequired" [ngClass]="notesClass">
    <span class="padding-right-10 color-orange">
        <em class="far fa-file-signature" aria-hidden="true"></em>
    </span>{{ field.templateOptions.options[0].label }}
    <small class="color-blue" *ngIf="field.templateOptions.options[0].nameRequired">
        ({{field.templateOptions.options[0].name}})
    </small>
</h6>
<div [ngClass]="mainClass">
    <input trim = "blur" id="{{ 'txt' + fieldKey }}" type="text" class="form-control" [formControl]="formControl"  #textboxToShowTooltip
        [formlyAttributes]="field" (input)="onValueChange()"
        [ngClass]="{'has-value': formControl.value,'color-light-grey-disabledType pointer-events-none':to.disabled,'textTransform':field.templateOptions.turnToCaps ,
    'padding-right-20':field.templateOptions.showOverflowTooltip && showTooltip,'text-ellipsis':field.templateOptions.showOverflowTooltip && showTooltip}"
        autocomplete="off" (keypress)="omitSpecialChar($event);" (input)="checkOverflow()" />
    <label for="TextInput">{{field.templateOptions.label}}<span *ngIf="field.templateOptions.required">*</span>
    </label>
   
    <span *ngIf="to.attributes && to.attributes.instruction" class="cursor-pointer icon-position" placement="left"
        [ngbPopover]="popContent" triggers="mouseenter:mouseleave">
        <i class="far fa-info-circle text-info"> </i>
        <ng-template #popContent>
            <span>{{to.attributes.instruction}}</span>
        </ng-template>
    </span>

    <a *ngIf="field.templateOptions.showOverflowTooltip && showTooltip"
        style="position: absolute; right: 2px; top: 8px;z-index: 99;cursor: initial;" class="">
        <em class="fas fa-info-circle text-info text-mustard" tooltipClass="my-custom-class-additional-fields"
            ngbTooltip="{{ formControl?.value }}" container="body" placement="left"></em>
    </a>


    <a *ngIf="field.templateOptions.options && field.templateOptions.options[0] && field.templateOptions.options[0].isRightIcon"
        style="position: absolute; right: 2px; top: 10px;z-index: 99;cursor: initial;" class="text-mustard"
        [ngClass]="field.templateOptions.options[0].ancherClass">
        <em [ngClass]="field.templateOptions.options[0].rightIcon" tooltipClass="my-custom-class-additional-fields"
            ngbTooltip="{{ field.templateOptions.options[0].iconTooltip }}" placement="left"></em>
    </a>

    <div *ngIf="formControl.errors && (formControl.errors.required || formControl.errors.whitespace) && isFormSubmitted"
        class="alert-danger d-inline">
        <span *ngIf="!formControl.value || !formControl.value.trim() || formControl.value != '' ">
            <strong>{{ "label_Required" | translate }}</strong>
        </span>
    </div>

    <div *ngIf="formControl.errors && formControl.errors.invalidEmail && isFormSubmitted" class="alert-danger">
        <span> <strong>{{ "enter_valid_email" | translate }}</strong></span>
    </div>

  
        <div class="alert-danger" *ngIf="field.templateOptions.customErrors && isFormSubmitted">
            <span *ngFor="let error of field.templateOptions.customErrors">
                <strong>{{error}}</strong>
            </span>
        </div>
 


    <span *ngIf="field.templateOptions.customErrors && field.templateOptions.errorOnChange && !isFormSubmitted">
        <div class="alert-danger" *ngFor="let error of field.templateOptions.customErrors">
            <span><strong>{{error}}</strong></span>
        </div>
    </span>

    <p  *ngIf="field.templateOptions.note && field.templateOptions.note && field.templateOptions.maxLength && field.templateOptions.maxLengthCount">
        <small class="text-left" *ngIf="field.templateOptions.note && field.templateOptions.note">
            {{field.templateOptions.note}}
        </small>
        <small class="fa-pull-right" *ngIf="field.templateOptions.maxLength && field.templateOptions.maxLengthCount" >
            {{formControl.value ? formControl.value.length : 0}} / {{ field.templateOptions.maxLength}}
        </small>
       
    </p>

    <p class="text-right d-inline float-right" *ngIf="!field.templateOptions.note && !field.templateOptions.note && field.templateOptions.maxLength && field.templateOptions.maxLengthCount">
        <small>
            {{formControl.value ? formControl.value.length : 0}} / {{ field.templateOptions.maxLength}}
        </small>
    </p>
    <span class="padding-left-0 f-12 fw-700" *ngIf="field.templateOptions.htmlCustomMsg"
     [innerHTML]="sanitizerHtml(field.templateOptions.htmlCustomMsg)"></span>


     <p  class="text-left f-11 font-weight-bold" 
    *ngIf="field.templateOptions.supportingNote">
    <span class="" [innerHTML]="sanitizerHtml(field.templateOptions.supportingNote)" ></span>             
   </p>

</div>