import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
  Renderer2,
  Output,
  EventEmitter,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  AttachmentTypeEnum,
  DeleteExpenseEnum,
  ApiRequest,
  IApiresponse,
} from "src/app/_models";
import { Claims } from "src/app/_models/common/claims";
import { IReceiptInfo } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { EODDateFormatPipe } from "src/app/_helpers/pipe.module";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import {
  ReceiptsInfo,
  ReceiptLocationInfo,
  DeleteReceiptShortRequest,
} from "src/app/_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { environment } from "../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { Observable, Observer } from "rxjs";
import { KeyValue } from "../../../_models/common/key-value";
import { NgxImageCompressService } from "ngx-image-compress";
import {
  ExpenseRequest,
  IExpenseRequest,
  PreviewOutputModel,
  ReceiptSaveRequest,
} from "../../../_models/claimant-on-web/claimant-on-web";
import { ExpenseStatusEnum } from "../../../_models";
import { ClaimType } from "../../../_models/enum/solo-enum";
@Component({
  selector: "app-view-form-receipt",
  templateUrl: "./view-form-receipt.component.html",
})
export class ViewFormReceiptComponent {
  @Input() totalReceipts = <ReceiptsInfo[]>[];
  @Input() closePanel: boolean = false;
  @Input() readOnlyMode: boolean = false;
  @Input() showScanOption: boolean = false;
  @Input() isCreditCardExpense: boolean = false;
  @Input() claimType: number;
  @Input() createType: number = 1;
  @Input() isClaimPage: boolean = false;
  @Input() isHideDefaultPdfPanel: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Output() matchReceiptEvent = new EventEmitter<{
    addReceipt: ReceiptsInfo[];
    receiptFiles: any[];
    previewOutputModel: PreviewOutputModel;
    scanReceipt: boolean;
  }>();
  @Output() addReceiptEvent = new EventEmitter<{
    addReceipt: ReceiptsInfo[];
    receiptFiles: any[];
    previewOutputModel: PreviewOutputModel;
    scanReceipt: boolean;
  }>();
  @Output() showExpenseMergePanelEvent = new EventEmitter<any[]>();
  @Output() newExpenseDeleteReceiptEvent = new EventEmitter<boolean>();

  receiptInfo = <IReceiptInfo[]>[];
  showAttachReceiptOptionsPanel: boolean = false;
  receiptFormatAlertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    ""
  );
  showReceiptFormatAlertPanel: boolean = false;
  receiptSizeLimitPopupModel: AlertPanelModel;
  receiptTypeResp: any;
  showReceiptSizeLimitPopup: boolean = false;
  capturedReceipts = [];
  capturedLat: number;
  capturedLng: number;
  capturedReceiptsInfo = <any[]>[];
  editReceiptsInfo = <any[]>[];
  mergedReceiptsInfo = <any[]>[];
  seletedItem: ReceiptsInfo = new ReceiptsInfo();
  @ViewChildren("receiptImage") receiptImages: QueryList<ElementRef>;
  @ViewChildren("receiptContainer") receiptContainer: QueryList<ElementRef>;
  @ViewChild("receiptImageContainer", { read: ElementRef })
  receiptImageContainer: ElementRef<any>;
  alertPanelModelForDeleteReceipt = new AlertPanelModel(
    false,
    "",
    [],
    "",
    "",
    true,
    ""
  );
  pdfSrc: any;
  pdfName: string;
  pdfExpenseReceiptId: number = 0;
  showPdfViewer = false;
  attachmentTypeEnum = AttachmentTypeEnum;
  receiptCount: number = 1;
  imgPopup: boolean = false;
  claims: Claims;
  selectedAttachmentType: number;
  isPageLoad: boolean = true;

  get expenseStatusEnum() {
    return ExpenseStatusEnum;
  }

  showScanOverrideAlertPanel = false;
  receiptScanOverrideAlertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    ""
  );

  constructor(
    private claimsService: ClaimsService,
    private renderer: Renderer2,
    private ngxService: NgxUiLoaderService,
    private translate: TranslateService,
    public eodDatePipe: EODDateFormatPipe,
    private imageCompress: NgxImageCompressService,
    private claimantOnWebService: ClaimantOnWebService,
    private toastr: ToastrService
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.registerDragElement();
  }

  fnShowAttachReceiptOptionsPanel() {
    this.showAttachReceiptOptionsPanel = true;
  }

  fnShowFilePicker() {
    this.showAttachReceiptOptionsPanel = false;
    document.getElementById("receiptUpload").click();
    return false;
  }
  ngOnChanges() {
    if (this.closePanel) {
      this.fnResetReceiptPanel();
    }
    if (this.totalReceipts?.length > 0) {
      this.seletedItem = this.totalReceipts[0];
      this.fnShowSelectedReceipt(this.totalReceipts[0], 0);
    }
  }

  fnResetReceiptPanel() {
    this.capturedReceipts = [];
    this.seletedItem = <ReceiptsInfo>{};
    this.totalReceipts = [];
    this.capturedReceiptsInfo = [];
    this.showPdfViewer = false;
  }

  fnShowExpenseMergePanel() {
    this.showExpenseMergePanelEvent.emit(this.capturedReceiptsInfo);
    this.showAttachReceiptOptionsPanel = false;
  }

  isPdfReceipt = false;
  scanReceiptPDFUrl = "";
  showReceiptScanInfoPanel = false;
  fileSaveEvent: any[] = [];
  fileInputEvent: any;

  readUploadedFile(fileInput: Event) {
    this.fileInputEvent = fileInput;
    this.rotationDegree = 0;
    let attachmentCount = this.totalReceipts?.length ?? 0;
    let newUploadCount = 0;
    newUploadCount = (<HTMLInputElement>fileInput.target).files?.length;

    if (attachmentCount + newUploadCount > 5) {
      //Not allowed more than 5 for an item.
      this.fnShowReciptLimitAlert();
    } else {
      let filesToUpload = (<HTMLInputElement>fileInput.target).files;

      for (let i = 0; i < filesToUpload.length; i++) {
        let tempFile = filesToUpload[i];

        if (tempFile) {
          let fileExtension = tempFile.type.split("/")[1];
          if (
            !fileExtension ||
            (fileExtension.toLowerCase() != "png" &&
              fileExtension.toLowerCase() != "jpg" &&
              fileExtension.toLowerCase() != "jpeg" &&
              fileExtension.toLowerCase() != "pdf")
          ) {
            this.receiptFormatAlertPanelModel = new AlertPanelModel(
              true,
              this.translate.instant("label_alert"),
              [],
              "",
              this.translate.instant("text_ok"),
              true,
              this.translate.instant("upload_pdf_or_image_only")
            );
            this.showReceiptFormatAlertPanel = true;
            return;
          }

          let fileName =
            "receipt_" + new Date().valueOf() + "." + fileExtension;
          let file = new File([tempFile], fileName, { type: tempFile.type });

          let receiptUrl = "";
          var reader = new FileReader();
          this.fileSaveEvent = [];
          reader.onload = (event: any) => {
            this.isPdfReceipt = fileExtension == "pdf";
            this.scanReceiptPDFUrl = event.target?.result;
            this.showReceiptScanInfoPanel = true;
            this.fileSaveEvent.push(event);

            // if (fileExtension != "pdf") {
            //     let image = new Image();
            //     image.src = event.target?.result;

            //     this.getImageDimensions(image.src)
            //         .then(({ width, height }) => {

            //             const filesize = (file.size / 1024 / 1024).toFixed(2);
            //             if (Number(filesize) > 1) {
            //                 this.imageCompress.compressFile(event.target?.result, -1, 20, 90, 1280, 720).then(
            //                     result => {
            //                         const imageBlob = this.dataURItoBlob(result.split(',')[1]);
            //                         file = new File([imageBlob], fileName, { type: tempFile.type });
            //                         receiptUrl = result;
            //                         const uploadedFilesize = (file.size / 1024 / 1024).toFixed(2);

            //                         if (Number(uploadedFilesize) > 3) {
            //                             this.fnShowReceiptSizeLimitPopupReceiver();
            //                             return;
            //                         }

            //                         this.fnCreateReceiptArray(file, fileExtension, receiptUrl);
            //                     });
            //             }
            //             else
            //                 this.fnCreateReceiptArray(file, fileExtension, event.target?.result);
            //         });
            // }
            // else {
            //     this.fnCreateReceiptArray(file, fileExtension, event.target?.result);
            // }
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  SaveImageWithoutScan(scanReceipt) {
    if (
      this.totalReceipts.length >= 1 &&
      scanReceipt &&
      !this.showScanOverrideAlertPanel
    ) {
      this.receiptScanOverrideAlertPanelModel = new AlertPanelModel(
        true,
        this.translate.instant("label_alert"),
        [],
        "Do not Scan",
        this.translate.instant("Scan"),
        true,
        this.translate.instant(
          "Please note, this Scan will overwrite the previous scanned receipt."
        )
      );
      this.showScanOverrideAlertPanel = true;
      return;
    }

    this.showScanOverrideAlertPanel = false;
    this.showReceiptScanInfoPanel = false;

    let fileInput = this.fileInputEvent;
    let filesToUpload = (<HTMLInputElement>fileInput.target).files;

    for (let i = 0; i < filesToUpload.length; i++) {
      let tempFile = filesToUpload[i];

      let event = this.fileSaveEvent[i];
      if (tempFile) {
        let fileExtension = tempFile.type.split("/")[1];
        if (
          !fileExtension ||
          (fileExtension.toLowerCase() != "png" &&
            fileExtension.toLowerCase() != "jpg" &&
            fileExtension.toLowerCase() != "jpeg" &&
            fileExtension.toLowerCase() != "pdf")
        ) {
          this.receiptFormatAlertPanelModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            "",
            this.translate.instant("text_ok"),
            true,
            this.translate.instant("upload_pdf_or_image_only")
          );
          this.showReceiptFormatAlertPanel = true;
          return;
        }

        let fileName = "receipt_" + new Date().valueOf() + "." + fileExtension;
        let file = new File([tempFile], fileName, { type: tempFile.type });

        let receiptUrl = "";
        if (fileExtension != "pdf") {
          let image = new Image();
          image.src = event.target?.result;

          this.getImageDimensions(image.src).then(({ width, height }) => {
            const filesize = (file.size / 1024 / 1024).toFixed(2);
            if (Number(filesize) > 1) {
              this.imageCompress
                .compressFile(event.target?.result, -1, 20, 90, 1280, 720)
                .then((result) => {
                  const imageBlob = this.dataURItoBlob(result.split(",")[1]);
                  file = new File([imageBlob], fileName, {
                    type: tempFile.type,
                  });
                  receiptUrl = result;
                  const uploadedFilesize = (file.size / 1024 / 1024).toFixed(2);

                  if (Number(uploadedFilesize) > 3) {
                    this.fnShowReceiptSizeLimitPopupReceiver();
                    return;
                  }

                  this.fnCreateReceiptArray(
                    file,
                    fileExtension,
                    receiptUrl,
                    scanReceipt
                  );
                });
            } else
              this.fnCreateReceiptArray(
                file,
                fileExtension,
                event.target?.result,
                scanReceipt
              );
          });
        } else {
          this.fnCreateReceiptArray(
            file,
            fileExtension,
            event.target?.result,
            scanReceipt
          );
        }
      }
    }
  }

  showPreviewPanel: boolean = false;
  scannedText: KeyValue[];
  previewImageUrl: any;
  previewOutputModel: PreviewOutputModel;

  fnScanReceipt() {
    //this.ngxService.start();
    let receipt = this.totalReceipts.filter(
      (it) => it.expenseReceiptId == this.seletedItem.expenseReceiptId
    );
    this.previewImageUrl = receipt[0].receiptUrl;
    this.extractInvoiceReceipts(
      false,
      receipt,
      this.expenseStatusEnum.PendingForSubmission,
      []
    );
  }

  extractInvoiceReceipts(
    hasVoiceMemo,
    receiptsInfo,
    saveStatus,
    expenseIds: number[]
  ) {
    let recSaveReq = new ReceiptSaveRequest();
    recSaveReq.createdOn = new Date().valueOf();
    recSaveReq.expenseIds = expenseIds;
    recSaveReq.orgId = 1223;
    recSaveReq.userId = 24323;
    recSaveReq.platformID = 1;
    recSaveReq.saveStatus = saveStatus;
    recSaveReq.hasReceipt =
      receiptsInfo && receiptsInfo.length > 0 ? true : false;
    recSaveReq.hasVoiceMemo = hasVoiceMemo;
    recSaveReq.currencyId = 0;
    recSaveReq.receiptsInfo = receiptsInfo;

    let receiptSaveRequest = new ApiRequest<ReceiptSaveRequest>(
      "ExtractInvoiceReceipt",
      3.8,
      "1"
    );
    receiptSaveRequest.requestInfo = recSaveReq;
    let recSaveReqString = JSON.stringify(receiptSaveRequest);
    let expReq = new ExpenseRequest(
      recSaveReqString,
      this.capturedReceipts,
      null
    );
    let receiptRequest = new ApiRequest<IExpenseRequest>(
      "ScanReceiptData",
      environment.applicationVersion,
      environment.requestId
    );
    receiptRequest.requestInfo = expReq;
    this.addReceiptEvent.emit({
      addReceipt: this.totalReceipts,
      receiptFiles: this.capturedReceipts,
      previewOutputModel: this.previewOutputModel,
      scanReceipt: false,
    });
    // this.claimantOnWebService
    //   .ScanExpenseReceipts(receiptRequest)
    //   .subscribe((response: IApiresponse<KeyValue[]>) => {
    //     if (response.statusCode == 0) {
    //         this.ngxService.stop();
    //         // this.showPreviewPanel = true;
    //         // this.scannedText = response.responseInfo;
    //         this.addReceiptEvent.emit({ addReceipt: this.totalReceipts, receiptFiles: this.capturedReceipts, previewOutputModel : this.previewOutputModel });
    //     }
    //   });
  }

  fnClosePreviewPanel(_event) {
    this.showPreviewPanel = false;
    this.previewOutputModel = new PreviewOutputModel();
  }

  fnSavePreviewPanel(_event) {
    this.showPreviewPanel = false;
    this.previewOutputModel = _event;
    this.seletedItem = this.previewOutputModel.receipt;
    this.addReceiptEvent.emit({
      addReceipt: this.totalReceipts,
      receiptFiles: this.capturedReceipts,
      previewOutputModel: this.previewOutputModel,
      scanReceipt: false,
    });
  }

  resetFileControl(event) {
    event.target.value = "";
  }

  receiptLimitAlertPopupModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    ""
  );
  showReceiptLimitAlertPopup = false;
  fnShowReciptLimitAlert() {
    this.receiptLimitAlertPopupModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [this.translate.instant("receipt_limit_alert")],
      "",
      this.translate.instant("label_ok"),
      true,
      ""
    );
    this.showReceiptLimitAlertPopup = true;
  }

  fnReceiptLimitPopupReceiver() {
    this.showReceiptLimitAlertPopup = false;
  }

  fnShowReceiptSizeLimitPopupReceiver() {
    this.receiptSizeLimitPopupModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      "",
      this.translate.instant("label_ok"),
      true,
      this.translate.instant("receipt_upload_size_limit_alert")
    );
    this.showReceiptSizeLimitPopup = true;
  }

  fnReceiptSizeLimitPopupReceiver() {
    this.showReceiptSizeLimitPopup = false;
  }

  getImageDimensions(base64Image) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const { naturalHeight, naturalWidth } = img;
        resolve({ height: naturalHeight, width: naturalWidth });
      };
      img.onerror = function () {
        reject(new Error(this.translate.instant("image_load_fail")));
      };
      img.src = base64Image;
    });
  }

  fnCreateReceiptArray(file, fileExtension, receiptUrl, scanReceipt) {
    this.capturedReceipts.push(file);
    let receiptLocationInfo = new ReceiptLocationInfo();
    receiptLocationInfo.latitude = this.capturedLat;
    receiptLocationInfo.longitude = this.capturedLng;
    if (this.capturedLat && this.capturedLng)
      receiptLocationInfo.location = localStorage.getItem(
        "GoogleCurrentLocationName"
      );

    let receiptsInfo = new ReceiptsInfo();
    receiptsInfo.action = 1;
    receiptsInfo.isDeletable = true;
    receiptsInfo.isEmailedReceipt = false;
    receiptsInfo.receiptDate = new Date().valueOf();
    receiptsInfo.receiptLocationInfo = receiptLocationInfo;
    receiptsInfo.receiptFormatId = this.getReceiptFormatId(file.type);
    receiptsInfo.receiptSize = file.size;
    receiptsInfo.receiptName = file.name;
    receiptsInfo.expenseReceiptId = new Date().valueOf() % 100000;
    receiptsInfo.receiptUrl = receiptUrl;
    receiptsInfo.rotationDegree = 0;

    let newReceipt = receiptsInfo;

    this.capturedReceiptsInfo.push(newReceipt);
    this.totalReceipts.push(newReceipt);
    this.createType = this.createType ? this.createType : 1;
    if (this.createType == 1 || this.createType == 2)
      this.addReceiptEvent.emit({
        addReceipt: this.totalReceipts,
        receiptFiles: this.capturedReceipts,
        previewOutputModel: null,
        scanReceipt: scanReceipt,
      });
    this.seletedItem = receiptsInfo;
    // if (this.seletedItem.receiptFormatId == this.attachmentTypeEnum.Pdf) {
    //   this.fnShowSelectedReceipt(this.seletedItem, null);
    // }
    this.rotationDegree = 0;
    this.rotateImg(0, this.totalReceipts.length - 1);
    // if (this.createType == 2 && this.totalReceipts.length < 2)
    //   this.fnScanReceipt();
    this.removeZoom();
  }

  stopSpinner() {
    this.ngxService.stop();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  getReceiptFormatId(type: string) {
    let receiptType = 0;
    switch (type) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        receiptType = 1;
        break;
      case "application/pdf":
        receiptType = 2;
        break;
      default:
        break;
    }
    return receiptType;
  }

  fnReceiptFormatAlertPanelReceiver(returnValue) {
    this.showReceiptFormatAlertPanel = false;
  }

  rotationDegree = 0;
  rotateImg(degree: number, index: number) {
    this.rotationDegree += degree;
    if (this.receiptImages?.first) {
      this.receiptImages.forEach((div: ElementRef) => {
        this.renderer.setStyle(
          div.nativeElement,
          "transform",
          `rotate(${this.rotationDegree}deg)`
        );
      });
    }

    if (index) {
      let item = this.totalReceipts[index];
      item.rotationDegree = this.rotationDegree;
    } else if (this.totalReceipts.length > 0 && this.seletedItem) {
      let item = this.totalReceipts.filter(
        (x) => x.expenseReceiptId == this.seletedItem.expenseReceiptId
      )[0];
      item.rotationDegree = this.rotationDegree;
    }
  }

  zoomOut() {
    if (this.receiptImages?.first) {
      this.receiptImages.forEach((div: ElementRef) => {
        const rect = div.nativeElement.getBoundingClientRect();
        if (rect.height != 0 && rect.width != 0) {
          let newwidth = rect.width - 100;
          let newheight = (rect.height / rect.width) * newwidth;
          this.renderer.setStyle(div.nativeElement, "width", newwidth + "px");
          this.renderer.setStyle(div.nativeElement, "height", newheight + "px");
        }
      });
    }
  }

  zoomIn() {
    let containerRect =
      this.receiptImageContainer.nativeElement.getBoundingClientRect();
    if (this.receiptImages?.first) {
      this.receiptImages.forEach((div: ElementRef) => {
        const rect = div.nativeElement.getBoundingClientRect();
        if (rect.height != 0 && rect.width != 0) {
          let newwidth = rect.width + 100;
          let newheight = (rect.height / rect.width) * newwidth;
          if (containerRect.width > newwidth) {
            this.renderer.setStyle(div.nativeElement, "width", newwidth + "px");
            this.renderer.setStyle(
              div.nativeElement,
              "height",
              newheight + "px"
            );
          }
        }
      });
    }
  }
  fnShowSelectedReceipt(item: ReceiptsInfo, index: number) {
    this.seletedItem = item;
    this.removeZoom();
    if (item.receiptFormatId == this.attachmentTypeEnum.Pdf) {
      this.pdfSrc = item.receiptUrl;
      this.pdfName = item.receiptName;
      this.pdfExpenseReceiptId = item.expenseReceiptId;

      if (this.isPageLoad && this.isHideDefaultPdfPanel) {
        this.showPdfViewer = false;
        this.isPageLoad = false;
      } else {
        this.showPdfViewer = true;
        this.isPageLoad = false;
      }
    } else {
      this.rotationDegree = 0;
      this.rotateImg(item.rotationDegree ?? 0, index);
    }
  }

  removeZoom() {
    if (this.receiptImages?.first) {
      this.receiptImages.forEach((div: ElementRef) => {
        const rect = div.nativeElement.getBoundingClientRect();
        if (rect.height != 0 && rect.width != 0) {
          this.renderer.removeStyle(div.nativeElement, "width");
          this.renderer.removeStyle(div.nativeElement, "height");
        }
      });
    }
  }

  deleteReceipt() {
    this.totalReceipts = this.totalReceipts.filter(
      (x) => x.expenseReceiptId != this.seletedItem.expenseReceiptId
    );
    this.capturedReceiptsInfo = this.capturedReceiptsInfo.filter(
      (x) => x.expenseReceiptId != this.seletedItem.expenseReceiptId
    );
    this.addReceiptEvent.emit({
      addReceipt: this.totalReceipts,
      receiptFiles: this.capturedReceipts,
      previewOutputModel: this.previewOutputModel,
      scanReceipt: false,
    });
    if (this.totalReceipts.length > 0) {
      this.seletedItem = this.totalReceipts[0];
    } else {
      this.seletedItem = new ReceiptsInfo();
    }
  }

  fnShowDeleteReceiptAlert() {
    let action = this.seletedItem.action;
    let msg = this.translate.instant("do_you_want_to_delete_receipt");
    if (action == 1) {
      if (this.claimType == ClaimType.Invoice) {
        msg = this.translate.instant("do_you_want_to_delete_invoice");
      }
    } else {
      msg =
        this.claimType == ClaimType.Expense
          ? this.translate.instant("delete_incomplete_receipt_expense")
          : this.translate.instant("do_you_want_to_delete_invoice");
    }

    this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      this.translate.instant("label_cancel"),
      this.translate.instant("label_confirm"),
      true,
      msg
    );

    this.alertPanelModelForDeleteReceipt.btnArray = [
      {
        btnMarginClass: "margin-right-15",
        btnClass: "btn btn-outline-primary bg-white fw-600 height-38px",
        btnAction: DeleteExpenseEnum[DeleteExpenseEnum.Cancel],
        btnText: this.translate.instant("label_cancel"),
      },
      {
        btnMarginClass: "margin-right-15",
        btnClass: "btn eod-btn-danger",
        btnAction: DeleteExpenseEnum[DeleteExpenseEnum.Delete],
        btnText: this.translate.instant("label_delete"),
      },
    ];

    if (this.seletedItem.action == 0 && this.claimType == ClaimType.Expense) {
      this.alertPanelModelForDeleteReceipt.btnArray.push({
        btnMarginClass: "margin-right-0",
        btnClass: "btn eod-btn-primary",
        btnAction: DeleteExpenseEnum[DeleteExpenseEnum.New],
        btnText: this.translate.instant("create_incomplete_receipt_expense"),
      });
    }
  }

  fnCloseDeleteReceiptAlert(returnValue) {
    if (returnValue) this.deleteReceipt();
    this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
      false,
      "",
      [],
      "",
      "",
      true,
      ""
    );
  }

  fnPdfViewerReceiver() {
    this.showPdfViewer = false;
    this.pdfSrc = "";
    this.pdfName = "";
    this.pdfExpenseReceiptId = 0;
  }

  showImgPopup() {
    this.imgPopup = true;
  }

  multiBtnAlertPanelReceiver(returnValue) {
    switch (returnValue) {
      case DeleteExpenseEnum[DeleteExpenseEnum.Cancel]: {
        this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
          false,
          "",
          [],
          "",
          "",
          true,
          ""
        );
        break;
      }
      case DeleteExpenseEnum[DeleteExpenseEnum.Delete]: {
        this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
          false,
          "",
          [],
          "",
          "",
          true,
          ""
        );
        this.fnExpenseByDeleteReceipt(this.seletedItem.expenseReceiptId, false);
        break;
      }
      case DeleteExpenseEnum[DeleteExpenseEnum.New]: {
        this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
          false,
          "",
          [],
          "",
          "",
          true,
          ""
        );
        this.fnExpenseByDeleteReceipt(this.seletedItem.expenseReceiptId, true);
        this.newExpenseDeleteReceiptEvent.emit(true);
        break;
      }
    }
  }

  fnExpenseByDeleteReceipt(receiptId: any, isSoftDelete: boolean) {
    if (this.seletedItem?.action == 1) {
      this.deleteReceipt();
      return;
    }
    let request = new ApiRequest<DeleteReceiptShortRequest>(
      "DeleteReceipt",
      environment.applicationVersion,
      environment.requestId
    );

    request.requestInfo = new DeleteReceiptShortRequest();
    request.requestInfo.isSoftDelete = isSoftDelete;
    request.requestInfo.receiptIds = [receiptId];

    this.claimantOnWebService
      .DeleteReceipts(request)
      .subscribe((response: IApiresponse<any>) => {
        if (response.statusCode != 1) {
          this.toastr.success(this.translate.instant("success"));
          this.deleteReceipt();
        } else {
          this.toastr.error(this.translate.instant("create_expense_fail"));
        }
      });
  }

  downloadReceipt() {
    let item = this.totalReceipts.filter(
      (x) => x.expenseReceiptId == this.seletedItem.expenseReceiptId
    )[0];
    this.getBase64ImageFromURL(item?.receiptUrl?.toString()).subscribe(
      (base64data) => {
        let base64Image = "data:image/jpg;base64," + base64data;
        // save image to disk
        let link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);

        link.setAttribute("href", base64Image);
        link.setAttribute("download", item.receiptName);

        link.click();
        setTimeout(() => {
          link.remove();
        }, 100);
      }
    );
  }

  closeScanReceiptPanel() {
    this.showReceiptScanInfoPanel = false;
    this.addReceiptEvent.emit({
      addReceipt: this.totalReceipts,
      receiptFiles: this.capturedReceipts,
      previewOutputModel: this.previewOutputModel,
      scanReceipt: false,
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  registerDragElement() {
    const elmnt = document.getElementById("exampleModal");

    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const dragMouseDown = (e) => {
      e = e || window.event;
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e) => {
      e = e || window.event;
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    };

    const closeDragElement = () => {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    };

    if (elmnt) {
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }
    }
  }

  matchReceipt() {
    this.matchReceiptEvent.emit({
      addReceipt: this.totalReceipts,
      receiptFiles: this.capturedReceipts,
      previewOutputModel: this.previewOutputModel,
      scanReceipt: false,
    });
  }

  fnReceiptScanOverrideAlertPanelReceiver(returnValue) {
    if (returnValue) {
      this.SaveImageWithoutScan(true);
    } else {
      this.SaveImageWithoutScan(false);
    }
    this.showScanOverrideAlertPanel = false;
  }
}
