import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ng-select-description-renderer',
  templateUrl: './ng-select-description-rendrer.component.html',
  styleUrls: ['./ng-select-description-rendrer.component.scss']
})
export class NgSelectDescriptionRendrerComponent  implements ICellRendererAngularComp {

   showCheckIcon: boolean;
    placeholder = "";
    items = [];
    value: any;
    params: any;

    isShowText: boolean = false;
    isShowBold: boolean = false;

    initilize(params: ICellRendererParams) {

        if (params && params.data && params.data.isShowText) {
            this.isShowText = params.data.isShowText;
        }

        if (params && params.data && params.data.isShowBold) {
          this.isShowBold = params.data.isShowBold;
      }

        if (params.colDef.headerComponentParams) {
            this.items = params.colDef.headerComponentParams.dropdownItems;
            this.placeholder = params.colDef.headerComponentParams.placeholder;
        }

        this.value = params.getValue();

        this.value = (this.value == 0) ? null : this.value;
    }

    agInit(params: any): void {
        this.params = params;
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onChange($event) {
        if (this.params.onChange instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data
            }
            this.params.onChange(params);
        }
    }

}