import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'progress-bar-renderer',
    templateUrl: './progress-bar-renderer.component.html'
})
export class ProgressBarRendererComponent implements ICellRendererAngularComp {
    public total: number = 0;
    public value: number = 0;
    public isCompleted: boolean = false;

    initilize(params: ICellRendererParams) {
         let data = params.getValue();
         if(data){
            if(data.total){
                this.total = data.total;
            }
            if(data.value){
                this.value = data.value;
            }
            if(this.value ==  this.total){
                this.isCompleted = true;
            }
         }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}