import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiresponse, Apiresponse } from "src/app/_models/common/request-response/apiresponse";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { FunctionPrivilegeMapping } from "src/app/_models/secondary-fm/function-privilege";
import { UserPrivilegeModel } from "src/app/_models/secondary-fm/user-privilege";
import { FinanceManagerModel } from "src/app/_models/secondary-fm/finance-manager";
import { map, mergeMap } from "rxjs/operators";
import { ApiRequest } from "src/app/_models";
import { ISavePrivilegesRequest } from "src/app/_models/secondary-fm/save-privilege";

@Injectable({
    providedIn: "root",
})
export class FunctionPrivilegeService {

    constructor(
        private http: HttpClient,
        private claimsHeaderService: ClaimsHeaderService
    ) { }

    getFunctionPrivilegeMappings(): Observable<Apiresponse<FunctionPrivilegeMapping[]>> {

        const cacheKey = "FunctionPrivilegeMappingData";
        let cachedData = sessionStorage.getItem(cacheKey);
        if (cachedData) {
            return of(JSON.parse(cachedData));
        }
        else {
            return this.http.get<any>(
                `${environment.catalogApiUrl}GetFunctionPrivilegeMappings`,
                { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
            ).pipe(map(data => {
                sessionStorage.setItem(cacheKey, JSON.stringify(data));
                return data;
            }));
        }
    }

    checkRoutePrivilegeAccess(route: string, userId: number): Observable<boolean> {
        return this.getFunctionPrivilegeMappings().pipe(mergeMap((privileges) => {
            var routePrivilegeMap = privileges.responseInfo.filter(function (p) {
                return p.routes.filter(function (r) {
                    return route.includes(r.route);
                }).length > 0;
            });
            if (routePrivilegeMap && routePrivilegeMap.length > 0) {
                return this.checkPrivilegesAccess([routePrivilegeMap[0].privilegeID], userId).pipe(map(access => {
                    return access && access.length > 0;
                }));
            }
            else {
                return of(true);
            }
        }));
    }

    checkPrivilegesAccess(privilegeId: number[], userId: number): Observable<number[]> {
        return this.getFunctionPrivilegesForUser(userId).pipe(map(userPrivileges => {
            var userAllowed = userPrivileges.responseInfo.privileges.filter(function (p) {
                return privilegeId.indexOf(p) > -1;
            });
            return userAllowed;
        }));
    }

    getFinanceManagerPrivileges(): Observable<Apiresponse<FinanceManagerModel[]>> {
        return this.http.get<any>(
            `${environment.billingApiUrl}GetFinanceManagers`,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    getFunctionPrivilegesForUser(userId): Observable<Apiresponse<UserPrivilegeModel>> {
        const cacheKey = "FunctionPrivilegeUserData_" + userId;
        let cachedData = sessionStorage.getItem(cacheKey);
        if (cachedData) {
            return of(JSON.parse(cachedData));
        }
        else {
            return this.http.get<any>(
                `${environment.billingApiUrl}GetFunctionPrivilegesForUser?userId=${userId}`,
                { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
            ).pipe(map(data => {
                sessionStorage.setItem(cacheKey, JSON.stringify(data));
                return data;
            }));
        }
    }

    saveFunctionPrivilegesForUser(request: ApiRequest<ISavePrivilegesRequest>): Observable<Apiresponse<boolean>> {
        const cacheKey = "FunctionPrivilegeUserData_" + request.requestInfo.UserID;
        let body = JSON.stringify(request);
        return this.http.post<IApiresponse<boolean>>(
            `${environment.billingApiUrl}SaveFunctionPrivilegesForUser`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        ).pipe(map(result => {
            if (result.statusCode == 0 && result.responseInfo) {
                sessionStorage.removeItem(cacheKey);
            }
            return result;
        }));
    }

}
