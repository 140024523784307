import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Claims } from '../_models/common/claims';
import { IDefaultParams, DefaultParams } from '../_models/common/request-response/default-params';
import { IDeviceInfo, DeviceInfo } from '../_models/common/request-response/device-info';
import { environment } from '../../../src/environments/environment';
import { ClaimsService } from "src/app/_services/common/claims.service";

@Injectable()
export class setHeader {
  currentUser: any;
  logintoken: any;
  claims: Claims;
  token: any;
  orgId: number = 0;
  userId: number = 0;
  organisationCurrency: string;
  defaultParams: IDefaultParams;
  deviceInfo: IDeviceInfo;
  constructor(private claimsService: ClaimsService) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  authorizationHeader() {
    this.defaultParams = new DefaultParams(this.orgId, this.userId,
      this.claims ? this.claims.FinanceManager : '', environment.requestTimeStamp);
      let appVersion = '' + this.claims?.appVersion;
    this.deviceInfo = new DeviceInfo(this.organisationCurrency, environment.platform,
      appVersion, environment.appId, environment.deviceId,this.detectBrowserName(),this.detectBrowserVersion());
    this.defaultParams.deviceInfo = this.deviceInfo;
    this.logintoken = localStorage.getItem("loginToken");
    if (this.logintoken) {
      return new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + this.logintoken,
        "DefaultParams": JSON.stringify(this.defaultParams),
        'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
        'Ocp-Apim-Trace': environment.apimTraceEnabled,      });
    }

  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';     
      case agent.indexOf('trident') > -1:
          return 'ie';
      case agent.indexOf('edg') > -1:
          return 'edge';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';   
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   
  detectBrowserVersion(){
      let userAgent = navigator.userAgent, tem, 
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }
      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }

}
