<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-65pc d-flex flex-column">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{ "special_cat_wizard_text45" | translate }}</h2>
            <div>
                <button *ngIf="!isSaveHide" type="button" class="btn eod-btn-primary height-38px mr-2"
                 (click)="fnSaveJustification()"
                [ngClass]="{'disabled': isDisableSave }">
                    {{ "label_save" | translate }}
                </button>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>
        <div class="block mt-2 p-0">
            <div class="row">
                <div class="col-3 p-0 doc-sidebar" *ngIf="isSidePanel">
                    <div class="nav flex-column nav-pills" >
                        <button *ngIf="PolicyOnMainCategory || this.expenseModelInfo?.potentialFraud"
                            class="nav-link d-block position-relative f-16" 
                            [ngClass]="{ 'active' : isPolicyOnMainCategory }" 
                            (click)="fnPolicyOnMainCategory()" >
                            {{specialCategoryName}}
                        </button> 
                        <button *ngFor="let item of orgSubCategoryMappings" 
                            class="nav-link d-block position-relative f-16" 
                            [ngClass]="{ 'active' : item.expenseCategoryName == selectedSubCategoryItem?.expenseCategoryName }" 
                            (click)="fnUpdateSubcategoryPolicyViolation(item)" >
                            {{item.expenseCategoryName}}
                            <small class="d-block" 
                            [ngClass]="{ 'text-white' : item.expenseCategoryName == selectedSubCategoryItem?.expenseCategoryName,
                            'eod-text-danger' : item.expenseCategoryName != selectedSubCategoryItem?.expenseCategoryName,}">Sub Category</small>
                        </button>                            
                    </div>
                </div>
                <div class="col-9 heightCalc-5">
                    <app-generic-policy-violation-panel *ngIf="isShowPolicyViolationPanel" 
                    [(inputData)]="panelData" 
                    [selectedPolicySubCategoryItem]="selectedPolicySubCategoryItem"
                    [isReceiptNumberSettingsEnabled]="isReceiptNumberSettingsEnabled"
                    (ischangeJustifactionNotes)="ischangeJustifactionNotes($event)"
                    [expenseDetails]="expenseModelInfo"
                    ></app-generic-policy-violation-panel>
                </div>
            </div>
        </div>
    </div>
</div> 
