<p id="{{ 'receipt' + fieldKey }}" (click)="getReceipt(field.templateOptions.options[0].type)"
    [ngClass]="field.templateOptions.class ? field.templateOptions.class : 'text-center mx-auto w-100pc margin-top-20 position-absolute'">
    <a id="viewReceipt" [ngClass]="field.templateOptions.options[0].className">
        <span class="f-40 position-relative">
            <em class="fal" [ngClass]="field.templateOptions.options[0].iconClass"></em>
            <span class="badge-red2" *ngIf="field.templateOptions.options[0].receiptCount>0">
                {{field.templateOptions.options[0].receiptCount}}
            </span>
        </span>
        <span class="d-block">
            <strong>
                {{field.templateOptions.options[0].label}}
            </strong>
        </span>
    </a>
</p>