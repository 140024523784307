<div class="overLayDiv active"></div>

<div id="rightSideBar">
    <div style="width: 85% !important;" class="rightSidePanel active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2>
                                {{ "errors_warnings_employee_list" | translate }}
                            </h2>
                        </div>

                        <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnOnExportClick()" class="margin-right-15">
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ "label_export" | translate }}
                                    </button>
                                </li>

                                <li (click)="fnClosePanel()">
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ "close" | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>

        <div class="block heightCalc-6">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-12">
                <!----------------------Start Grid--------------------------->
                <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems"></app-grid>
                <!----------------------End Grid--------------------------->
            </div>
        </div>

    </div>
</div>