<div class="input-group margin-top-20"
    [ngClass]="{requiredAsterisk : field.templateOptions.required,'disabled':to.disabled}">

    <ng-select id="{{ 'select' + fieldKey }}" class="solo-dropdown filterDropdown auditTrailDropdown" [items]="to.options"
        bindLabel="value" bindValue="id" [clearable]="false" [placeholder]="field.templateOptions.placeholder"
        [formControl]="formControl" [selectOnTab]="true" notFoundText="{{ 'no_data_available' | translate: param }}"
        (change)="onValueChange()" (keypress)="omitSpecialChar($event)">

        <ng-template ng-option-tmp let-item="item" let-index="index">
            <span class="linkOption dropdownLink" *ngIf="item.isLink">
                <a class="float-left d-block linkOption dropdownLink">
                    {{ item.value }}
                </a>
            </span>
            <span *ngIf="!item.isLink">
                {{ item.value }}
            </span>
        </ng-template>

    </ng-select>

    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span> <strong>{{ "label_Required" | translate: param }}</strong></span>
    </div>

    <span *ngIf="field.templateOptions.customErrors && isFormSubmitted">
        <div class="alert-danger" *ngFor="let error of field.templateOptions.customErrors">
            <span><strong>{{error}}</strong></span>
        </div>
    </span>

    <div *ngIf="field.templateOptions.alertNote" class="alert-danger">
        {{ field.templateOptions.alertNote }}
    </div>

    <p class="text-left" *ngIf="field.templateOptions.note && field.templateOptions.note">
        <small><span [innerHTML]="sanitizerHtml(field.templateOptions.note)"></span>
            
        </small>
    </p>

   <p  [ngClass]="{ 'text-left f-11 font-weight-bold cursor-pointer text-underline text-logo-blue' : linkType, 
                            'text-left f-11 font-weight-bold cursor-pointer' : linkType == false }" 
    *ngIf="field.templateOptions.supportingNote" (click)="fnOpenPanel()">
    <span class="cursor-pointer" [innerHTML]="sanitizerHtml(field.templateOptions.supportingNote)" ></span>             
   </p>

</div>