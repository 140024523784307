import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'app-formly-link-component',
  templateUrl: './formly-link-component.component.html'
})
export class FormlyLinkComponentComponent extends FieldType implements OnInit, OnDestroy {
  fieldKey: string;
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  count:number=0;
  userType:number=0;
  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();           
    }

    if (this.to.options && this.to.options[0]) {
      this.count = this.to.options[0].count;    
      this.userType = this.to.options[0].userType; 
    }    
    
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onClick() {
    this.to.click(this.field);
  }

}
