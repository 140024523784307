

import { IFCGetReportList, FCGetReportList } from "./fcget-report-list";
import { IFCReceiptResponse } from "./fcreceipt-response";
export interface IFCGetReportResponse {
    totalCount: number;
    hasMoreRecords: number;
    fromDateFilter: number;
    toDateFilter: number;
    fcGetReportList: IFCGetReportList[];
    fcReceiptResponseList: IFCReceiptResponse[];
}
export class FCGetReportResponse implements IFCGetReportResponse {
    totalCount: number;
    hasMoreRecords: number;
    fromDateFilter: number;
    toDateFilter: number;
    fcGetReportList = <IFCGetReportList[]>[];
    fcReceiptResponseList = <IFCReceiptResponse[]>[];
}
