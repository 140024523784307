import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { ApiRequest, BaseCategoryTypeEnum, IApiresponse } from 'src/app/_models';
import { LocationCommonDetail, SelectRecentJourneyInputModel, SelectRecentJourneyOutputModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { IOrganisationGooglePackageUpdateCallsRequest, OrganisationGooglePackageUpdateCallsRequest } from 'src/app/_models/google-mapping/google-mapping';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { GoogleMappingService } from 'src/app/_services/google-mapping/google-mapping.service';
import { environment } from 'src/environments/environment';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'formly-location-input',
  templateUrl: "./formly-location-input.component.html"
})
export class FormlyLocationInputComponent extends FieldType implements OnInit, OnDestroy {

  claimsSubscription: Subscription;
  localUserId: number;
  localLoginId: string;
  localOrganisationId: number;

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  queryWait: boolean = false;
  googleDynamicAddressList: any;
  isGoogleDropdownVisible: boolean = false;
  isHomeOfcDropdownVisible: boolean = false;
  isGoogleAddressNotFound: boolean = false;
  countOfGoogleCalls: number = 0;

  showSelectRecentJourneyPanel: boolean = false;
  selectRecentJourneyInputModel: SelectRecentJourneyInputModel;
  hasReturnJourney: boolean = false;
  gm_lable: string;

  constructor(private dynamicFormService: DynamicFormService,
    private claimsService: ClaimsService,
    private googleMappingService: GoogleMappingService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) {
    super();
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.localUserId = claims.UserId;
      this.localLoginId = claims.LoginId;
      this.localOrganisationId = claims.OrgId;
    });
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    this.hasReturnJourney = this.model.mileage && this.model.mileage.hasReturnJourney;
    if (this.fnIsMileageGoogleExpense()) this.gm_lable = ' ' + this.translate.instant('gm_message');
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.claimsSubscription) {
      this.claimsSubscription.unsubscribe();
    }
  }

  onTextInput() {
    this.model.mileage[this.field.key.toString()].location = this.formControl.value;
    this.to.change(this.field, this.formControl.value);

  }

  onReturnJourneyInput() {
    this.model.mileage.hasReturnJourney = !this.hasReturnJourney;
    this.to.change(this.field, this.formControl.value);
  }

  fnIsMileageGoogleExpense() {
    return this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle;
  }
  fnIsManualMileageExpense() {
    return this.model.baseCategoryId == BaseCategoryTypeEnum.Mileage;
  }
  isCharLimit: boolean = false;
  isToDelIconDisplay: boolean = false;
  fnSearchAddress(value) {
    if (value.length == 0) {
      this.isToDelIconDisplay = false;
    }
    this.isCharLimit = false;
    if (this.model.mileage.googleCallsInfo
      && Number(this.model.mileage.googleCallsInfo.limitChar) > 0
      && value.length > Number(this.model.mileage.googleCallsInfo.limitChar)) {
      this.isCharLimit = true;
      return false;
    }

    this.isHomeOfcDropdownVisible = false;
    if (this.fnIsMileageGoogleExpense()) {
      this.model.mileage[this.field.key.toString()].placeId = null;
      this.model.mileage[this.field.key.toString()].location = this.formControl.value;

      if (!this.queryWait) {
        this.queryWait = true;
        setTimeout(() => {
          this.fnGetFromAutocompletePredictions();
          this.queryWait = false;
        }, 200);
      }
      this.cdr.detectChanges();
    }
  }

  private fnGetFromAutocompletePredictions() {

    if (this.model.mileage[this.field.key.toString()].location.length < 6) {
      this.googleDynamicAddressList = null;
      if (this.model.mileage[this.field.key.toString()].location.length > 0) {
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      }
      else {
        if (this.model.mileage.triangulationRule) {
          this.isHomeOfcDropdownVisible = true;
        }
        this.isGoogleAddressNotFound = false;
        this.isGoogleDropdownVisible = false;
      }
    }
    if (this.model.mileage[this.field.key.toString()].location.length != 6 && this.model.mileage[this.field.key.toString()].location.length < 10) {
      return;
    }
    this.fnGetPlacePredictions(this.model.mileage[this.field.key.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;
        this.isGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
        this.isGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
        this.isHomeOfcDropdownVisible = false;
        this.countOfGoogleCalls++;
      })
      .catch(err => {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      });
  }

  private fnGetPlacePredictions(query: string): Promise<any> {
    let autocompleteSrv = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteSrv.getPlacePredictions({
        types: ['geocode'],
        input: query
      }, function (predictions, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  }
  fnOnInputClick() {
    if (this.model.mileage.triangulationRule) {
      if ((!this.googleDynamicAddressList || this.googleDynamicAddressList == null
        || this.googleDynamicAddressList.length == 0) && (!this.formControl.value || this.formControl.value == '')) {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = false;
        this.isHomeOfcDropdownVisible = true;
      }
    }
  }
  fnCloseGoogleDropdown() {
    if (this.fnIsMileageGoogleExpense()) {
      setTimeout(() => {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = false;
        this.isHomeOfcDropdownVisible = false;
      }, 300);

      if (this.countOfGoogleCalls > 0) {
        this.fnUpdateOrganisationGooglePackageCalls();
      }
    }
  }

  fnUpdateOrganisationGooglePackageCalls() {
    let request = new ApiRequest<IOrganisationGooglePackageUpdateCallsRequest>("UpdateOrganisationGooglePackageCalls", environment.applicationVersion, environment.requestId);
    let requestInfo = new OrganisationGooglePackageUpdateCallsRequest();
    requestInfo.OrganisationId = this.localOrganisationId;
    requestInfo.CallsUsed = this.countOfGoogleCalls;
    requestInfo.UpdatedBy = this.localUserId;
    request.requestInfo = requestInfo;
    this.googleMappingService.UpdateOrganisationGooglePackageCalls(request).subscribe((res: IApiresponse<any>) => {
      this.countOfGoogleCalls = 0;
    });
  }

  fnSetAddress(place: object) {
    this.formControl.setValue(place['description']);
    this.model.mileage[this.field.key.toString()].location = place['description'];
    this.model.mileage[this.field.key.toString()].placeId = place['place_id'];
    this.isGoogleDropdownVisible = false;
    this.googleDynamicAddressList = null;
    this.isGoogleAddressNotFound = false;
    this.isHomeOfcDropdownVisible = false;
    this.fnIsDelIconDisplay();
    this.to.change(this.field, this.formControl.value);
    this.cdr.detectChanges();
  }
  fnSetAddressFromTriangulation(location: LocationCommonDetail) {
    if (location) {
      this.formControl.setValue(location.address);
      this.model.mileage[this.field.key.toString()].location = location.address;
      this.model.mileage[this.field.key.toString()].placeId = location.placeId;
      this.isGoogleDropdownVisible = false;
      this.googleDynamicAddressList = null;
      this.isGoogleAddressNotFound = false;
      this.isHomeOfcDropdownVisible = false;
      this.fnIsDelIconDisplay();
      this.to.change(this.field, this.formControl.value);
      this.cdr.detectChanges();
    }

  }
  fnDeleteText() {
    this.model.mileage[this.field.key.toString()].placeId = null;
    this.model[this.field.key.toString()] = null;
    this.model.mileage[this.field.key.toString()].location = null;
    this.model.mileage[this.field.key.toString()].placeId = null;
    this.to.change(this.field, this.formControl.value);
    this.isToDelIconDisplay = false;
  }
  fnIsDelIconDisplay() {
    this.isToDelIconDisplay = true;
  }

  fnOpenSelectRecentJourneyPanel() {
    this.selectRecentJourneyInputModel = new SelectRecentJourneyInputModel();
    this.selectRecentJourneyInputModel.baseCategoryId = this.model.baseCategoryId;
    this.selectRecentJourneyInputModel.claimantId = this.model.userId;
    this.selectRecentJourneyInputModel.claimantName = this.model.userName;

    this.showSelectRecentJourneyPanel = true;
  }

  fnCloseSelectRecentJourneyPanel() {
    this.showSelectRecentJourneyPanel = false;
  }

  fnSelectRecentJourney(data: SelectRecentJourneyOutputModel) {
    if (data) {
      this.to.click(this.field, data);
    }
    this.fnCloseSelectRecentJourneyPanel();
  }

  fnSearchPlacePrediction() {
    this.fnGetPlacePredictions(this.model.mileage[this.field.key.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;
        this.isGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
        this.isGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
        this.isHomeOfcDropdownVisible = false;
        this.countOfGoogleCalls++;
      })
      .catch(_err => {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      });
  }
}
