import { Component, OnInit, Inject, forwardRef } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
//import { ExpenseCategory } from '../_models';
import { map, debounceTime } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
  ExpenseCategoryRequest,
  ExpenseCategoryInExpenseRequest,
} from "src/app/_models/claimant/expense-category-request";
import { IApiRequest, IApiresponse } from "src/app/_models";
import { Observable } from "rxjs";
import {
  IExpenseCategoryRequest,
  IExpenseCategoryResponse,
} from "src/app/_models/common/expense-category";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";

@Injectable({
  providedIn: "root",
})
export class ExpenseCategoryService implements OnInit {
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) { }
  private masterExpenseCategoryUrl = `${environment.catalogApiUrl}GetFFLExpenseCategory`;
  private ExpenseCategoryUrl = `${environment.billingApiUrl}GetExpenseCategoryByID`;
  private ExpenseCategoryInExpenseUrl = `${environment.expenseApiUrl}GetExpenseCategoriesInExpenses`;

  ngOnInit() { }

  getMasterExpenseCategoryList(): Observable<any> {
    return this.http
      .get<any>(this.masterExpenseCategoryUrl, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      })
      .pipe(
        map((expenseCategory) => {
          return expenseCategory;
        })
      );
  }

  getOrgExpenseCategory(
    expenseCategoryRequest: ExpenseCategoryRequest
  ): Observable<any> {
    let body = JSON.stringify(expenseCategoryRequest);
    return this.http
      .post<any>(this.ExpenseCategoryUrl, body, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      })
      .pipe(
        map((expenseCategory) => {
          return expenseCategory;
        })
      );
  }

  getOrgExpenseCategoryInExpense(
    expenseCategoryRequest: ExpenseCategoryInExpenseRequest
  ): Observable<any> {
    let body = JSON.stringify(expenseCategoryRequest);
    return this.http
      .post<any>(this.ExpenseCategoryInExpenseUrl, body, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      })
      .pipe(
        map((expenseCategory) => {
          return expenseCategory;
        })
      );
  }

  GetExpenseCategories(
    requestinfo: ExpenseCategoryRequest
  ): Observable<IApiresponse<IExpenseCategoryResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IExpenseCategoryResponse>>(
      `${environment.billingApiUrl}GetExpenseCategories`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetVendorExpenseCategories(
    requestinfo: ExpenseCategoryRequest
  ): Observable<IApiresponse<IExpenseCategoryResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IExpenseCategoryResponse>>(
      `${environment.billingApiUrl}GetVendorExpenseCategories`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  
}
