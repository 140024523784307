<div [ngClass]="{'align-right margin-right-10': isRightAligned }">
    <div [hidden]="!params.isCheckbox || !params.isCheckAllAllowed" id="{{fieldKey}}">
        <input name="chkBxAll" [(ngModel)]="params.isAllChecked" (change)="onCheckboxChange($event)" type="checkbox"
            id="chkAll" placement="right" [ngbPopover]="popContent" container="body" triggers="mouseenter:mouseleave"
            [ngClass]="{'disabled opacity-60': isGridEmpty }">
    </div> 
    <div [hidden]="params.isCheckbox" class="" id="{{fieldKey}}"
        [ngClass]="{'text-logo-blue':ascSort == 'active' ||  descSort == 'active'}"
        [ngStyle]="{'color': noSort == 'active' ? 'black' : '#29b7e9'}">
        <div [hidden]="!params.enableMenu" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked($event)">
            <em class="fa fa-bars"></em>
        </div>
        <div class="customHeaderLabel margin-right-10" (click)="onSortRequested($event)" [innerHTML]="sanitizerHtml(params.displayName)"> </div>
        <div [hidden]="!params.enableSorting" (click)="onSortRequested($event)" [ngClass]="sortIconClass">
            <em [hidden]="ascSort != 'active'" class="fa fa-long-arrow-alt-up"></em>
            <em [hidden]="descSort != 'active'" class="fa fa-long-arrow-alt-down"></em>
        </div>

        <div [hidden]="!headerTooltipIcon" [placement]="headerTooltipPosition" [ngbPopover]="headerpopContent" container="body" triggers="mouseenter:mouseleave">
           <i [ngClass]="headerTooltipIcon"></i>
        </div>           
        <div [hidden]="!headerEditIcon">
            <i [ngClass]="headerEditIcon" (click)="handleHeaderClick($event)"></i>
        </div>    
    </div>
</div>

<ng-template #popContent>
    <div [innerHTML]="sanitizerHtml(tooltipText)"></div>
</ng-template>

<ng-template #headerpopContent>
    <div [innerHTML]="sanitizerHtml(headerTooltip)"></div>
</ng-template>
