import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { SelectReceiptTypeOutputModel, SelectReceiptTypePopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { first } from "rxjs/operators";
import { OrganisationSettingService } from "src/app/_services/organisation-setting/organisation-setting.service";
@Component({
    selector: "select-receipt-type-panel",
    templateUrl: "./select-receipt-type-panel.component.html"
})
export class SelectReceiptTypePanelComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectReceiptTypePopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectReceiptTypeOutputModel>();

    claimsSubscription: Subscription;
    claims: Claims;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';

    receiptTypesList: SelectReceiptTypeOutputModel[] = [];
    vatNoVatReceiptTypeId: number = 0;
    
    constructor(private claimsService: ClaimsService,
      private changeDetectorRef: ChangeDetectorRef,
      private organisationSettingService: OrganisationSettingService) {
      this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
          this.claims = claims;
      });
    }

    ngOnInit(): void {
      this.fnGetReceiptTypeSettings();
      this.vatNoVatReceiptTypeId = this.inputData.vatNoVatReceiptTypeId;
    }

    fnGetReceiptTypeSettings()
    {
      this.organisationSettingService
        .getVatNoVatReceiptType()
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.responseInfo != null) {
              this.receiptTypesList = data.responseInfo.receiptTypes.map(e => this.mapReceiptType(e)).filter(it=>it.isDisplayOn);
              this.changeDetectorRef.detectChanges();
            }
          }
        );
    }

    mapReceiptType(e): any {
      let receiptTypeDetail: any = e;
      receiptTypeDetail.vatNoVatReceiptType = e.receiptType;     
      return receiptTypeDetail;
    }

    ngOnDestroy(): void {
        this.claimsSubscription.unsubscribe();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

    fnSelectReceiptType(item: SelectReceiptTypeOutputModel) {
      if(item) {
        this.selectItemEvent.emit(item);
      }
    }

}
