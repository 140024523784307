import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'formly-integer-input',
  templateUrl: "./formly-integer-input.component.html"
})
export class FormlyIntegerInputComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  constructor(private dynamicFormService: DynamicFormService, private datePipe: DatePipe, private cdr:ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onNumberInput() {
    this.to.change(this.field, this.formControl.value);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (this.field?.templateOptions?.allowNotZero && charCode == 48 && 
            ((this.formControl.value != null && parseInt(this.formControl.value)==0) || this.formControl.value == null)) {
      return false;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}