import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExportToCSVService {
  constructor(private datepipe: DatePipe) {}
  downloadFile(data, headerText, headerProperties, filename = "data") {
    let csvData = this.ConvertToCSV(data, headerText, headerProperties);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadExcelFile(data, headerText, headerProperties, filename = "data") {
    let csvData = this.ConvertToCSV(data, headerText, headerProperties);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".xls");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerText, headerProperties) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    for (let index in headerText) {
      row += headerText[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in headerProperties) {
        let head = headerProperties[index];
        if (array[i][head] == "") {
          line += "" + ",";
        } else {
          if ((head.includes("Date") || head.includes("submittedOn")) && !head.includes("laterDateEntries")) {
            line +=
              (array[i][head] == null
                ? ""
                : this.datepipe.transform(array[i][head], "dd-MMM-yyyy")) + ",";
          } else {
            line +=
              (array[i][head] == null
                ? ""
                : array[i][head].toString().replace(/\,/g, "/")) + ",";
          }
        }
      }
      str += line + "\r\n";
    }
    return str;
  }
}
