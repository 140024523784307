<!-- <div class="col-md-6 padding-right-0"> -->
    <div class="block heightCalc-6_5 d-flex flex-column justify-content-start align-items-center">
        <h5 class="eod-text-info mb-3 fw-700">{{ baseCategoryNameModel }}</h5>
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.Entertainment" 
                src="/assets/images/entertainment.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.Generic" 
                src="/assets/images/generic_Category.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.NoReceiptCategory" 
                src="/assets/images/no_receipt_category.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.Hotel" 
                src="/assets/images/hotel_lodgings.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.Mileage" 
                src="/assets/images/manual_mileage.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGPS" 
                src="/assets/images/mileageGps.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGoogle" 
                src="/assets/images/mileage_google.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
        <img *ngIf="!baseCategoryId" 
                src="/assets/images/No-option-selected.png" alt="Mobile Image template"
                class="heightCalc-14" width="auto" />
                <div class="row manage-category--activeCategories" *ngIf="orgCategoryListByBaseCatId.length > 0">
                    <p class="pt-2" style="text-align: center; width: 100%;"
                        (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                        {{ "manageCat_templateUsing_msg1" | translate: param}}
                        <a class="color-blue-imp d-inline-block">{{ orgCategoryListByBaseCatId.length }}
                            {{"manageCat_templateUsing_msg2" | translate: param}}
                        </a>
                    </p>
                    <div class="manage-category--activeCategoriesList" *ngIf="isActiveCategoryForTemplate">
                        <ul>
                            <p (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                                <span>
                                    {{"manageCat_templateUsing_msg2" | translate: param}}
                                </span>
                                <span>
                                    <i class="fal fa-times-circle"></i>
                                </span>
                            </p>
    
                            <li *ngFor="let item of orgCategoryListByBaseCatId">
                                {{ item.expenseCategoryName }}
                            </li>
                        </ul>
                    </div>
                </div>
        
    </div>
<!-- <div class="row">
    <div class="col-md-12 no-padding-right-left">
        <div class="block mt-1 padding-bottom-25" style="min-height:calc(100vh - 8em)">
            <div class="row">
                <p class="f-20 w-100pc color-blue text-center padding-bottom-15">
                    {{ baseCategoryNameModel }}
                </p>
                <div class="imgContainer text-center mx-auto">
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Entertainment" class="d-block text-center">
                        <img src="/assets/images/entertainment.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Generic
                    || baseCategoryId == baseCategoryTypeEnum.NoReceiptCategory" class="d-block text-center">
                        <img src="/assets/images/generic_Category.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Hotel" class="d-block text-center">
                        <img src="/assets/images/hotel_lodgings.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Mileage" class="d-block text-center">
                        <img src="/assets/images/manual_mileage.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGPS" class="d-block text-center">
                        <img src="/assets/images/mileageGps.png" alt="Mobile Image template" class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGoogle" class="d-block text-center">
                        <img src="/assets/images/mileage_google.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="!baseCategoryId" class="d-block text-center">
                        <img src="/assets/images/No-option-selected.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                </div>
            </div>
            <div class="row manage-category--activeCategories" *ngIf="orgCategoryListByBaseCatId.length > 0">
                <p class="padding-top-15" style="text-align: center; width: 100%;"
                    (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                    {{ "manageCat_templateUsing_msg1" | translate: param}}
                    <a class="color-blue-imp">{{ orgCategoryListByBaseCatId.length }}
                        {{"manageCat_templateUsing_msg2" | translate: param}}
                    </a>
                </p>
                <div class="manage-category--activeCategoriesList" *ngIf="isActiveCategoryForTemplate">
                    <ul>
                        <p (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                            <span>
                                {{"manageCat_templateUsing_msg2" | translate: param}}
                            </span>
                            <span>
                                <i class="fal fa-times-circle"></i>
                            </span>
                        </p>

                        <li *ngFor="let item of orgCategoryListByBaseCatId">
                            {{ item.expenseCategoryName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- </div> -->