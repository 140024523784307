<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2>{{'view_tag' | translate:param}}</h2>
                        </div>

                        <div
                        class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                        <ul class="d-flex justify-content-end w-100pc">
                            <li class="padding-right-1" >
                                <Button class="btn btn-outline-secondary border-color-warning text-mustard height-38px"
                                (click)="fnCloseViewTagComponentPanel()">
                                    {{ 'label_close' | translate: param }}
                                </Button>
                            </li>
                        </ul>
                    </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-lg-12 no-padding-right-left margin-top-15">
                <div *ngIf="inputData" class="block padding-top-0">
                    <div class="row margin-top-20">
                        <span *ngFor="let tag of inputData"
                            class="align-middle d-inline-block position-relative margin-right-5 disabled margin-top-5">
                            <button type="button"
                                class="text-logo-blue bg-light-grey border border-secondary rounded padding-5 align-middle">
                                {{tag}}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>