import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
  selector: 'formly-textarea-input',
  templateUrl: "./formly-textarea-input.component.html"
})
export class FormlyTextareaInputComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
      if (this.isFormSubmitted) {
        if (!this.formControl.value || this.formControl.value.trim() == '') {
            this.formControl.setValue('');
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onTextInput() {
    this.to.change(this.field, this.formControl.value);
  }

}
