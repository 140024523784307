import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Claims } from "src/app/_models/common/claims";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";

@Component({
  selector: "app-app-download",
  templateUrl: "./app-download.component.html",
  styleUrls: ["./app-download.component.scss"],
})
export class AppDownloadComponent implements OnInit {

  claims: Claims;
  
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private claimsService: ClaimsService,
    private oauthService: OAuthService,
    private translate: TranslateService,
  ) {
    this.claimsService.currentClaims.subscribe(
      (claims) => (this.claims = claims)
    );
  }

  ngOnInit(): void {}

  goToDashboard() {
    var tokenNew = this.oauthService.getAccessToken();
    this.claims.Token = tokenNew;
    this.authenticationService.setregisternav();
    this.claims.IsFinanceManager = true;
    this.claims.IsOnboardingCompleted = false;
    this.claims.FMRoleType = 1;
    this.claims.ApprovalRuleSetting = 1;
    this.claimsService.updateClaims(this.claims);
    this.router.navigate([""]);
  }
}
