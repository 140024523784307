<div class="{{buttonAlignmentClass}} rowIcons" [hidden]="actionButtons.length == 0">
    <a style="padding: 0px 5px;" *ngFor="let item of actionButtons" [ngClass]="{'disabled': item.isDisabled }"
        [hidden]="item.isHidden" (click)="onUserAction(item.userAction);">

        <em [ngClass]="item.buttonClass" tooltipClass="my-custom-class-recall" ngbTooltip="{{ item.buttonTooltip | translate: param }}" placement="left"
            container="body" [hidden]="item.isLink"></em>

        <span [hidden]="!item.isLink" [ngClass]="item.buttonClass">
           {{item.buttonTooltip }}
        </span>
    </a>
</div>

