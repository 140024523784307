import { NgModule } from "@angular/core";
import { NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MarketingPopupComponent } from "./marketing-popup.component";
import { TranslateModule } from "@ngx-translate/core";
import { AlertModule } from "src/app/alert-panel/alert.module";


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        NgbModule,
        NgbTooltipModule,
        AlertModule
    ],
    declarations: [
        MarketingPopupComponent
    ],
    providers: [],
    exports: [
        NgbTooltipModule,
        MarketingPopupComponent
    ]
})
export class MarketingPopupModule {
    constructor() { }
}
