import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-suspension-message-renderer',
  templateUrl: './suspension-message-renderer.component.html'
})
export class SuspensionMessageRendererComponent implements ICellRendererAngularComp {
  informationId: number;
  isSuspended: boolean;
  suspensionMessage: string;
  params: ICellRendererParams;

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      
      this.params = params;
      this.isSuspended = params.data.isSuspended;
      this.informationId = params.data.informationId;
      this.suspensionMessage = params.data.supensionMessage;
    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    this.isSuspended = event.target.checked;
    this.params.setValue(this.isSuspended);
    this.params.eGridCell.click();
  }

}
