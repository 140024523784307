<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <form novalidate>
        <div class="rightSidePanel active w-50pc">
            <header>
                <div class="row">
                    <div class="col-lg-12 no-padding-right-left">
                        <div class="row">
                            <div class="col-lg-8 padding-left-0">
                                <h2>{{this.panelHeading}}
                                    <span *ngIf="this.journeyFormModel">
                                        ({{ 'text_leg' | translate }} - {{this.journeyFormModel && this.journeyFormModel.sequence}})
                                    </span>
                                </h2>
                            </div>
                            <div class="col-lg-4 padding-right-0 header-icons text-right">
                                <ul class="d-flex justify-content-end w-100pc">
                                    <li class="margin-right-10" *ngIf="this.inputModel && this.inputModel.isEditMode">
                                        <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px" 
                                        (click)="fnShowDeleteConfirmationAlert()">
                                        {{ 'label_delete' | translate }}
                                        </button>
                                    </li>

                                    <li class="margin-right-10">
                                        <button class="btn btn-primary height-38px"
                                            [ngClass]="{'disabled-state': disableSaveButton 
                                            || !(this.journeyForm && this.journeyForm.form && this.journeyForm.form.dirty)}"
                                             (click)="fnSaveJourneyDetails()">
                                            {{ "save" | translate }}
                                        </button>
                                    </li>

                                    <li class="margin-right-0">
                                        <button
                                            class="btn btn-outline-secondary border-color-warning text-mustard height-38px"
                                            (click)="fnClosePanel()">
                                            {{ "close" | translate }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="clear"></div>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">

                <dynamic-form [model]="journeyFormModel" [fields]="journeyFormFields"></dynamic-form>

            </div>
        </div>
    </form>
</div>


<app-alert-panel *ngIf="showWarningAlerts" [data]="showWarningAlertsModel"
    (callbackEvent)="fnShowWarningAlertsReceiver($event)">
</app-alert-panel>

<create-tag-panel *ngIf="showCreateMileageTagPanel" [inputData]="createMileageTagInputModel"
    (closePanelEvent)="fnCloseCreateMileageTagPanel($event)" (selectItemEvent)="fnSelectCreateMileageTag($event)">
</create-tag-panel>

<create-detour-panel *ngIf="showSelectDetourPanel" [inputData]="selectDetourInputModel"
    (closePanelEvent)="fnCloseSelectDetourPanel($event)" (selectItemEvent)="fnSelectDetourPanel($event)">
</create-detour-panel>

<app-alert-panel *ngIf="showDebtourNotActivatedAlertPanel" [data]="debtourNotActivatedAlertModel"
    (callbackEvent)="fnCloseDebtourNotActivatedAlertPanel($event)">
</app-alert-panel>

<add-passenger-load-panel *ngIf="showAddPassengerLoadPanel" [inputData]="passengerLoadInputModel"
    (closePanelEvent)="fnCloseAddPassengerLoadPanel($event)" (selectItemEvent)="fnSelectPassengerLoad($event)">
</add-passenger-load-panel>

<app-alert-panel *ngIf="showPassengerLoadNotActivatedAlertPanel" [data]="passengerLoadNotActivatedAlertModel"
    (callbackEvent)="fnClosePassengerLoadNotActivatedAlertPanel($event)">
</app-alert-panel>

<app-alert-panel *ngIf="showDeleteConfirmationAlert" [data]="deleteConfirmationAlertModel"
    (callbackEvent)="fnDeleteConfirmationAlertReceiver($event)">
</app-alert-panel>
<app-alert-panel *ngIf="showGoogleServiceErrorPopup" [data]="GoogleServiceErrorPopupModel"
(callbackEvent)="fnGoogleServiceErrorPopupReceiver($event)">
</app-alert-panel>