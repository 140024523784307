<div class="overLayDiv active"
     *ngIf="isOverlayRequired"></div>

<div id="rightSideBar">
  <div class="rightSidePanel w-50pc active z-99999">
    <header>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
              <h2>{{ 'office_address_select' | translate }}</h2>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
              <ul class="d-flex justify-content-end w-100pc">
                <li class="margin-left-15"><button (click)="onClosePanel()"
                          class="btn btn-outline-secondary border-color-warning text-mustard height-38px">{{ 'close' | translate }}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="clear"></div>
    <div 
         class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
      <div class="block padgridDatading-bottom-20 padding-top-0 padding-0">
        <div class="col-md-12 padding-right-left-20">
          <app-grid [columns]="gridColumnDefs"
                    [data]="gridData"
                    [gridConfigItems]="gridConfigItems"
                    (onCellClick)="fnOnGridCellClick($event)">
          </app-grid>
        </div>
      </div>
    </div>
    
  </div>
</div>