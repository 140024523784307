

<div class="card-button" [hidden]="actionButtons.length == 0" *ngFor="let item of actionButtons"
[hidden]="item.isHidden">
    {{ item.textBeforeBtn }}
    <button class="btn f-11 position-relative top--3px" [hidden]="!item.buttonText"
        (click)="onUserAction(item.userAction);" [ngClass]="item.buttonClass">
        {{ item.buttonText }}
    </button>
</div>
