import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'view-renderer',
  templateUrl: './view-renderer.component.html'
})
export class ViewRendererComponent implements ICellRendererAngularComp {
  hideLink: boolean = false;
  constructor() {
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }
  initilize(params: ICellRendererParams) {
    if (params.data && params.data.hideLink) {
      this.hideLink = true;
    }
  }
}
