import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { InfoModelPopup, InfoPanelOutput } from 'src/app/_models/common/info-model-popup';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";



@Component({
  selector: 'info-model-popup',
  templateUrl: './info-model-popup.component.html',
})
export class InfoModelPopupComponent implements OnInit {
  @Input() infoModel: InfoModelPopup;

  @Output() closePanelEvent = new EventEmitter<true>();

  @Output() btnClickEvent = new EventEmitter<any>();
  safeText: SafeHtml;
  videoURLs: any;



  constructor(private translate: TranslateService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

  }

  fnClosePanel() {
    this.closePanelEvent.emit(true);
  }

  fnBtnClick() {
    let returnOutput = new InfoPanelOutput(this.infoModel.panelKey, this.infoModel.btnLabel, {
      title: this.infoModel.title,
      subTitle: this.infoModel.subTitle, btnStatus: true
    }
    );
    this.btnClickEvent.emit(returnOutput);
  }

  sanitizerHtml(element) {
    this.safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return this.safeText;
  }

  sanitizeVideoUrl(element) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(element);
  }


  close() {
    let iframe = document.querySelector("iframe");

    if (iframe) {
      let iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
  }
}
