import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiRequest } from "src/app/_models";
import { EmailModel } from "src/app/_models/common/EmailModel";
import { Claims } from "src/app/_models/common/claims";
import {
  UserRequestBase,
  Userrequestdto,
} from "src/app/_models/user/userrequestdto";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { EmailService } from "src/app/_services/common/email.service";
import { ConvertNonAsciiToAscii } from "src/app/common/commonvariables";
import { EMAIL_REGEX } from "src/util/constants";

@Component({
  selector: "app-otp-verification",
  templateUrl: "./otp-verification.component.html",
  styleUrls: ["./otp-verification.component.scss"],
})
export class OtpVerificationComponent implements OnInit, OnDestroy {
  @Output() gotoNextStepEvent = new EventEmitter<any>();

  userrequest: UserRequestBase;
  userrequestdto: Userrequestdto;
  showResendEmail = false;
  isInvalidOTP = false;
  otp1: number;
  otp2: number;
  otp3: number;
  otp4: number;

  isValidOTPEntered = false;
  myColorVaraible: string = "black";
  mybgColorVaraible: string = "white";

  showPassword1 = false;
  showPassword2 = false;

  password1 = "";
  password2 = "";

  isPasswordf1Valid = false;
  passwordLengthMatch = false;
  passwordLowercaseMatch = false;
  passwordUppercaseMatch = false;
  passwordNumberSymbolMatch = false;

  isPasswordMatch = true;

  newEmail = "";

  claims: Claims;

  canRegisterDisabled = false;
  getCodeClass = "eod-text-primary underline";

  interval: any;

  showGetCodeInput = false;

  constructor(
    private emailService: EmailService,
    private authenticationService: AuthenticationService,
    private claimsService: ClaimsService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  digitValidate(element: any) {}

  ngOnInit(): void {
    this.userrequestdto = JSON.parse(localStorage.getItem("registereduser"));
    this.newEmail = this.userrequestdto.LoginId;

    this.interval = setInterval(() => {
      this.resendOTP();
    }, 600000);
  }

  validateOTP() {
    this.showResendEmail = false;
    if (
      this.otp1?.toString().toString().length > 0 &&
      this.otp2?.toString().toString().length > 0 &&
      this.otp3?.toString().toString().length > 0 &&
      this.otp4?.toString().length > 0
    ) {
      if (
        this.userrequestdto.OTP.toString() ==
        this.otp1.toString() +
          this.otp2.toString() +
          this.otp3.toString() +
          this.otp4.toString()
      ) {
        this.mybgColorVaraible = "green";
        this.myColorVaraible = "white";
        this.isValidOTPEntered = true;
        this.showGetCodeInput = false;
        this.isInvalidOTP = false;
        clearInterval(this.interval);
        setTimeout(() => {
          this.password1Box.nativeElement.focus();   
        }, 100);
       
      } else {
        this.mybgColorVaraible = "red";
        this.myColorVaraible = "white";
        this.isValidOTPEntered = false;
        this.isInvalidOTP = true;
      }
    } else {
      this.mybgColorVaraible = "white";
      this.myColorVaraible = "black";
      this.isValidOTPEntered = false;
      this.isInvalidOTP = false;
    }
  }

  checkPasswordValidity() {
    let value = this.password1;
    let isInvalid = true;

    const isValidLength = /^.{8,}$/;
    if (!isValidLength.test(value)) {
      //"Password must be att-least Characters Long.";
      this.passwordLengthMatch = false;
      this.isPasswordf1Valid = false;
      isInvalid = false;
    } else {
      this.passwordLengthMatch = true;
    }

    // const isNonWhiteSpace = /^\S*$/;
    // if (!isNonWhiteSpace.test(value)) {
    //   return "Password must not contain Whitespaces.";
    // }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      //"Password must have at least one Uppercase Character.";
      this.passwordUppercaseMatch = false;
      this.isPasswordf1Valid = false;
      isInvalid = false;
    } else {
      this.passwordUppercaseMatch = true;
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      //"Password must have at least one Lowercase Character.";
      this.passwordLowercaseMatch = false;
      this.isPasswordf1Valid = false;
      isInvalid = false;
    } else {
      this.passwordLowercaseMatch = true;
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;

    if (isContainsNumber.test(value) || isContainsSymbol.test(value)) {
      //"Password must contain at least one Digit." or "Password must contain at least one Special Symbol.";
      this.passwordNumberSymbolMatch = true;
    } else {
      this.passwordNumberSymbolMatch = false;
      this.isPasswordf1Valid = false;
      isInvalid = false;
    }

    this.isPasswordf1Valid = isInvalid;
  }

  checkPasswordMatch() {
    if (
      this.password1.length == this.password2.length ||
      this.password2?.length > 0
    ) {
      this.isPasswordMatch = this.password1 == this.password2;
    } else {
      this.isPasswordMatch = true;
    }
  }

  onGetCode() {
    this.showResendEmail = true;
    this.otp1 = this.otp2 = this.otp3 = this.otp4 = undefined;
    this.isInvalidOTP = false;
    this.myColorVaraible = "black";
    this.mybgColorVaraible = "white";
  }

  resendOTP() {
    let em = new EmailModel();
    em.to = this.newEmail;
    em.templateName = "OnboardingOTP";
    em.userId = 0;
    em.subjct = "OTP";
    em.sentTime = 0;
    em.deliveredTime = 0;
    em.status = 1;
    em.emailLogId = this.newGuid();
    var otp = Math.floor(1000 + Math.random() * 9000);
    em.keyValuePairs["OTP"] = otp.toString();

    let req = new ApiRequest<EmailModel>("", 1, "");
    req.requestInfo = em;
    this.emailService.SendEmail(req).subscribe(() => {
      this.userrequestdto.LoginId = this.newEmail;
      this.userrequestdto.OTP = otp;
      localStorage.setItem(
        "registereduser",
        JSON.stringify(this.userrequestdto)
      );
      this.showResendEmail = false;
      this.showGetCodeInput = false;
    });
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  RegisterUser() {
    this.canRegisterDisabled = true;
    this.claims = new Claims();
    this.userrequest = new UserRequestBase();

    this.userrequest.RequestInfo = new Userrequestdto();
    this.userrequest.WebApiName = "SoloRegister";
    this.userrequest.WebApiVersion = 1;
    this.userrequest.RequestId = "111";
    this.userrequestdto.Password = this.password1;
    this.userrequest.RequestInfo = this.userrequestdto;

    this.authenticationService.register(this.userrequest).subscribe(
      (data) => {
        if (data.statusCode == 0) {
          if (localStorage.getItem("currentUser")) {
            localStorage.removeItem("currentUser");
          }

          this.claims.Token = data.responseInfo.token;
          this.claims.EmailId = data.responseInfo.emailId;
          this.claims.UserId = data.responseInfo.userId;
          this.claims.FinanceManager =
          ConvertNonAsciiToAscii.removeAccents(data.responseInfo.firstName) + " " + ConvertNonAsciiToAscii.removeAccents(data.responseInfo.lastName);
          this.claims.LoginId = data.responseInfo.emailId;
          this.claims.FMRoleType = 1;
          this.claims.isClaimOnWebAllowed =
            data.responseInfo.isClaimOnWebAllowed;
          this.claims.orgRoleId = data.responseInfo.orgRoleId;

          this.claimsService.updateClaims(this.claims);

          this.gotoNextStepEvent.emit();
        } else {
          this.toastr.error(data.errorInfo[0].message);
        }
      },
      (error) => {}
    );
  }

  onDigitInput(event) {
    let element;
    if (event.code !== "Backspace")
      element = event.srcElement.nextElementSibling;

    if (event.code === "Backspace")
      element = event.srcElement.previousElementSibling;

    if (element == null) return;
    else element.focus();
  }

  isInvalidNewEmail = false;
  isInvalidEmail() {
    this.isInvalidNewEmail = !EMAIL_REGEX.test(this.newEmail);
  }

  @ViewChild("digitOne") digitOne: ElementRef<HTMLInputElement>;
  @ViewChild("password1Box") password1Box: ElementRef<HTMLInputElement>;
  

  ngAfterViewInit() {
    this.digitOne.nativeElement.focus();
    setTimeout(() => {
      this.getCodeClass = "underline color-red";
    }, 15000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
