import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, QueryList, ViewChildren, Renderer2, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AttachmentTypeEnum, JourneyTypeEnum, MileageRateTypeEnum, 
     ApiRequest, IApiresponse  } from "src/app/_models";
import { AlertPanelModel} from "src/app/_models/common/alertPanelModel";
import { Claims } from "src/app/_models/common/claims";
import { IReceiptInfo } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { fileSizeService } from 'src/app/_services/common/file-size.service';
import { EODDateFormatPipe } from "src/app/_helpers/pipe.module";
import { Observable, Observer } from "rxjs";
import { ExpenseRequest, IExpenseRequest, ReceiptSaveRequest } from "src/app/_models/claimant-on-web/claimant-on-web";
import { KeyValue } from "src/app/_models/common/key-value";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { environment } from "../../../../environments/environment";
import {
    ExpenseStatusEnum
  } from "../../../_models";
import { PreviewOutputModel, ReceiptOutputModel } from "../../../_models/claimant-on-web/claimant-on-web";

import { ToastrService } from "ngx-toastr";
@Component({
    selector: "app-view-receipt",
    templateUrl: "./view-receipt.component.html",
    styleUrls: ['./view-receipt.component.scss']
})

export class ViewReceiptComponent implements OnInit {
    @Input() orgRoleId: number;
    @Input() capturedReceipts = [];
    @Input() receiptInfo = <IReceiptInfo[]>[];
    @Input() receiptCount: number;
    @Input() hasReturnJourney: boolean = false;
    @Input() milesTravelled: any;
    @Input() triangulationMiles: string;
    @Input() detourMiles: any;
    @Input() amount: any;
    @Input() distance: string;
    @Input() origin: any;
    @Input() destination: any;
    @Input() receiptLat: number;
    @Input() receiptLong: number;
    @Input() latLongArray: any;
    @Input() mileageRate: string;
    @Input() strExpenseIds: string = '';
    @Input() isImageOnly: boolean = false;
    @Input() showDeleteButton: boolean = false;
    @Output() closePanelEvent = new EventEmitter<ReceiptOutputModel>();
    @Input() mileageArray: any;
    @Input() mileage: any;
    @Input() zindex_Class:string;
    @Input() isMileageCategory:boolean;
    @Input() claimType: any;
    @Input() isClaimPage: boolean = false;
    @Input() showMapSectionOnly: boolean = false;
    
    claims: Claims;
    currency: string;
    zindexClass: string;
    currentReceiptId: number = 0;
    currentIndex = 0;
    attachmentTypeEnum = AttachmentTypeEnum;
    showReceipt: boolean;
    selectedReceiptInfo = <IReceiptInfo[]>[];
    imageReceiptInfo = <IReceiptInfo[]>[];
    receiptLocation: string = "";
    showReceiptsPanel: boolean = false;
    mapObjectArray: any[] = [];
    receiptDate: any;
    showScanDetails:boolean;
    isAdvancedMileageEnabled: boolean;
    pdfSrc: any;
    pdfName: string;
    pdfExpenseReceiptId: number = 0;
    showPdfViewer = false;
    hideUseReceipt: boolean = false;
    mapShow: boolean = false;
    lat: any;
    long: any;
    zoom: number = 1500;
    param: string;
    showMileageMap: boolean;
    showMileageMapFromWeb: boolean;
    showCommonMileageMap: boolean;
    mileagemapHeight: string = '';
    mapHeight: string = '';
    imageHeight: string = '';
    mapWidth: string;
    amountDynamicText: string;
    @ViewChildren('imageContainer') imageContainers: QueryList<ElementRef>;
    @ViewChildren('receiptImage') receiptImages: QueryList<ElementRef>;
    @ViewChild('receiptImageContainer', { read: ElementRef }) receiptImageContainer: ElementRef<any>;
    @ViewChild('receiptImageFooter', { read: ElementRef }) receiptImageFooter: ElementRef<any>;
    alertPanelModelForDeleteReceipt = new AlertPanelModel(false, "", [], "", "", true, "");
    alertPanelModelForDeletePdf = new AlertPanelModel(false, "", [], "", "", true, "");
    journeyType: number;
    isCompleteJourney: boolean;
    showMileageMapBasedOnCategory: boolean;
    labelList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"]//Max 12 Leg allowed
    labelOptions = {
        color: '#eee',
        fontFamily: '',
        fontSize: '14px',
        fontWeight: 'bold',
        text: 'Some Text',
        backgroundColor: "#FFF"
    }
    insertedLabel = [];
    markers: any[] = [];
    selectedAttachmentType: number;
    //For styles on Map
    //https://mapstyle.withgoogle.com/
    styles: any[] = [
        {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ];
    constructor(private claimsService: ClaimsService,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService,
        private ngxService: NgxUiLoaderService,
        private translate: TranslateService,
        public eodDatePipe: EODDateFormatPipe,
        private claimantOnWebService: ClaimantOnWebService,
        private toastr: ToastrService
    ) {
        this.claimsService.currentClaims.subscribe(claims => {
            this.claims = claims;
            //this.currency = this.claims.CurrentOrganisationCurrencyCode;
        });
    }

    ngOnInit() {

        if(this.zindex_Class)
             this.zindexClass = this.zindex_Class;
        this.showScanDetails = this.orgRoleId == 1 ? true : false;
        this.getOrganisationData(); 
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        var currentContext = this;
        currentContext.adjustImageSize();
        currentContext.adjustMapSize();
    }

    adjustMapSize() {
        const docEl = document.documentElement;
        if (this.isAdvancedMileageEnabled) {
            this.mileagemapHeight = (docEl.clientHeight - 390) + "px !important";
        }
        else {
            this.mileagemapHeight = (docEl.clientHeight - 280) + "px !important";
        }
    }

    stopSpinner() {
        this.ngxService.stop();
    }

    adjustImageSize() {
        if (this.receiptImages && this.receiptImages.first) {
            let footerRect = this.receiptImageFooter.nativeElement.getBoundingClientRect();
            const containerHeight = (footerRect.top - 85);
            var dynamicHeight = 70;
            if (this.strExpenseIds != '') {
                dynamicHeight = this.receiptCount > 1 ? 90 : 70;
            }
            const imageHeight = (containerHeight - dynamicHeight);
            this.renderer.setStyle(this.receiptImageContainer.nativeElement, 'height', containerHeight + "px");
            this.receiptImages.forEach((div: ElementRef) => {
                const existingImageRect = div.nativeElement.getBoundingClientRect();
                if (existingImageRect.height > imageHeight) {
                    this.renderer.removeStyle(div.nativeElement, 'width');
                    this.renderer.setStyle(div.nativeElement, 'height', imageHeight + "px");
                }
            });
            this.imageHeight = imageHeight + 'px !important';
            this.mapHeight = (imageHeight + 80) + 'px !important';
        }
    }

    zoomOut() {
        if (this.receiptImages && this.receiptImages.first) {
            this.receiptImages.forEach((div: ElementRef) => {
                const rect = div.nativeElement.getBoundingClientRect();
                if (rect.height != 0 && rect.width != 0) {
                    var newwidth = rect.width - 100;
                    var newheight = rect.height / rect.width * newwidth;
                    this.renderer.setStyle(div.nativeElement, 'width', newwidth + "px");
                    this.renderer.setStyle(div.nativeElement, 'height', newheight + "px");
                }
            });
        }
    }

    zoomIn() {
        var containerRect = this.receiptImageContainer.nativeElement.getBoundingClientRect();
        if (this.receiptImages && this.receiptImages.first) {
            this.receiptImages.forEach((div: ElementRef) => {
                const rect = div.nativeElement.getBoundingClientRect();
                if (rect.height != 0 && rect.width != 0) {
                    var newwidth = rect.width + 100;
                    var newheight = rect.height / rect.width * newwidth;
                    if (containerRect.width > newwidth) {
                        this.renderer.setStyle(div.nativeElement, 'width', newwidth + "px");
                        this.renderer.setStyle(div.nativeElement, 'height', newheight + "px");
                    }
                }
            });
        }
    }

    nextImageClick() {
        this.currentIndex += 1;
        if (this.currentIndex >= this.imageReceiptInfo.length) {
            this.currentIndex = 0;
        }
        var item = this.imageReceiptInfo[this.currentIndex];
        this.currentReceiptId = item.expenseReceiptId;
        this.cycleItems();
    }

    prevImageClick() {
        this.currentIndex -= 1;
        if (this.currentIndex < 0) {
            this.currentIndex = this.imageReceiptInfo.length - 1;
        }
        var item = this.imageReceiptInfo[this.currentIndex];
        this.currentReceiptId = item.expenseReceiptId;
        this.cycleItems();
    }

    downloadReceipt() {
        var item = this.receiptInfo.filter(x => x.expenseReceiptId == this.currentReceiptId)[0]
        this.getBase64ImageFromURL(item?.receiptUrl).subscribe(base64data => {
            console.log(base64data);
            let base64Image = "data:image/jpg;base64," + base64data;
            // save image to disk
            var link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);

            link.setAttribute("href", base64Image);
            link.setAttribute("download", item.receiptName);

            link.click();
            setTimeout(() => {
                link.remove();
            }, 100);

        });
    }

    getBase64ImageFromURL(url: string) {
        return Observable.create((observer: Observer<string>) => {
            const img: HTMLImageElement = new Image();
            img.crossOrigin = "Anonymous";
            img.src = url;
            if (!img.complete) {
                img.onload = () => {
                    observer.next(this.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = err => {
                    observer.error(err);
                };
            } else {
                observer.next(this.getBase64Image(img));
                observer.complete();
            }
        });
    }

    getBase64Image(img: HTMLImageElement) {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL: string = canvas.toDataURL("image/png");

        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    getOrganisationData() {
        this.authenticationService
            .organisationdetails(this.claims.UserId.toString(), this.claims.LoginId, this.claims.Token)
            .subscribe(orgData => {
                if (orgData.statusCode == 0) {
                    this.isAdvancedMileageEnabled = orgData.responseInfo[0].rateType > MileageRateTypeEnum.FreeForLife ? true : false;
                }
            });
    }



    showGoogleMileageMap() {
        this.markers = [];
        this.markerPositions = [];
        this.vertices = [];
        let i = 0;

        this.markers.push({
            position: {
                lat: this.origin.lat,
                lng: this.origin.lng,
            },
            label: {
                color: '#096094',
                text: " ",
            },
            options: {
                animation: google.maps.Animation.DROP,
                icon: {
                    url: this.hasReturnJourney ? '../../assets/svg-icons/startandend.svg' : '../../assets/svg-icons/start.svg',
                    scaledSize: {
                        height: 50,
                        width: 50
                    }
                }
            },
        })

        this.latLongArray.forEach(routeElement => {
            this.vertices.push(
                {
                    lat: routeElement.latitude,
                    lng: routeElement.longitude,
                }
            );
        });

        this.markers.push({
            position: {
                lat: this.destination.lat,
                lng: this.destination.lng,
            },
            label: {
                color: '#096094',
                text: " ",
            },
            options: {
                animation: google.maps.Animation.DROP,
                icon: {
                    url: this.hasReturnJourney ? '../../assets/svg-icons/return.svg' : '../../assets/svg-icons/end.svg',
                    scaledSize: {
                        height: 50,
                        width: 50
                    }
                }
            },
        })


        this.center = { lat: this.origin.lat, lng: this.origin.lng };
        this.zoom = 10;


    }
    fnshowMileage(currency) {
        if(this.isMileageCategory == undefined)
         this.showMileageMapBasedOnCategory = true 
        else 
         this.showMileageMapBasedOnCategory = this.isMileageCategory;
        this.currency = currency;
        this.adjustMapSize();
        this.showGoogleMileageMap();
        this.showMileageMapFromWeb = true;
        setTimeout(() => {
            this.fnBindMileageSequence();
        }, 100);

        this.toggleChatbot(!this.showMileageMapFromWeb);
    }
    
    fnshowMileagemap(mileageArray, journeyType, isCompleteJourney, currency) {
        if(this.isMileageCategory == undefined)
         this.showMileageMapBasedOnCategory = true 
        else 
         this.showMileageMapBasedOnCategory = this.isMileageCategory;
         this.currency = currency;
        if (journeyType == 3 && isCompleteJourney) {
            this.isCompleteJourney = isCompleteJourney;
        }
        else { this.isCompleteJourney = false; }

        this.journeyType = journeyType;
        this.mileageArray = mileageArray;
        this.adjustMapSize();
        if (this.journeyType == JourneyTypeEnum.Multiple && this.isCompleteJourney) {
            this.fnBindMarkerMultipleLeg(this.mileageArray)
        }
        else {
            this.fnBindMarker(this.mileageArray);
        }

        this.showMileageMap = true;
        setTimeout(() => {
            this.fnBindMileageSequence();
        }, 100);
        this.toggleChatbot(!this.showMileageMap);
    }
    fnBindMileageSequence() {
        let seqObjectArray = [];
        seqObjectArray.push({
            name: this.translate.instant("miles_travelled"),
            value: this.milesTravelled,
            class: 'w-100pc d-block lH-25 color-green',
            valueClass: 'w-100pc d-block text-right lH-25 color-green fw-700'
        });
        if (this.showMileageMap) {
            this.mapWidth = '750px';
            if (this.journeyType == 3 && !this.isCompleteJourney) {
                if (this.isAdvancedMileageEnabled) {
                    seqObjectArray.push({
                        name: 'B. ' + this.translate.instant("detour_miles"),
                        value: this.detourMiles,
                        class: 'w-100pc d-block lH-25 color-green',
                        valueClass: 'w-100pc d-block text-right lH-25 color-green fw-700'
                    });
                    let miles = Number(this.mileageArray[0].detour.toFixed(2)) + Number(this.mileageArray[0].milesTraveled.toFixed(2));
                    seqObjectArray.push({
                        name: this.translate.instant("total_miles_claimed"),
                        value: miles.toFixed(2).toString() + " " + this.mileageArray[0].mileageLabel,
                        class: 'w-100pc d-block lH-25 text-logo-blue',
                        valueClass: 'w-100pc d-block text-right lH-25 text-logo-blue fw-700'
                    });
                }
                else {
                    seqObjectArray.push({
                        name: this.translate.instant("mileage_rates_per_leg_ffl"),
                        value: this.mileageRate,
                        class: 'w-100pc d-block lH-25',
                        valueClass: 'w-100pc d-block text-right lH-25 fw-700'
                    });
                }
            }
            else if (!(this.journeyType == 3 && !this.isCompleteJourney)) {
                seqObjectArray = this.fnBindConditionally(seqObjectArray);
            }
        }
        if (this.showMileageMapFromWeb) {
            this.mapWidth = 'auto';
            seqObjectArray = this.fnBindConditionally(seqObjectArray);
        }
        this.mapObjectArray = seqObjectArray;
        setTimeout(() => {
            this.showCommonMileageMap = true;
        }, 50);

    }

    fnBindConditionally(seqObjectArray) {
        if (this.isAdvancedMileageEnabled) {
            seqObjectArray.push({
                name: this.translate.instant("mileage_triangulation"),
                value: this.triangulationMiles,
                class: 'w-100pc d-block lH-25 color-red',
                valueClass: 'w-100pc d-block text-right lH-25 color-red fw-700'
            });
            seqObjectArray.push({
                name: this.translate.instant("mileage_detour_miles"),
                value: this.detourMiles,
                class: 'w-100pc d-block lH-25 color-green',
                valueClass: 'w-100pc d-block text-right lH-25 color-green fw-700'
            });
            seqObjectArray.push({
                name: this.translate.instant("miles_paid"),
                value: this.distance,
                class: 'w-100pc d-block lH-25 text-logo-blue',
                valueClass: 'w-100pc d-block text-right lH-25 text-logo-blue fw-700'
            });
            seqObjectArray.push({
                name: this.translate.instant("mileage_rates"),
                value: this.mileageRate,
                class: 'w-100pc d-block lH-25',
                valueClass: 'w-100pc d-block text-right lH-25 fw-700'
            });
            let amountWithPassenger = this.amount.toFixed(2);
            let passengerLoadAmount = this.mileage.passengerLoadAmount ? this.mileage.passengerLoadAmount.toFixed(2) : 0;
            let amountWithoutPassenger = Number(amountWithPassenger) - Number(passengerLoadAmount);
            seqObjectArray.push({
                name: this.translate.instant("map_amount"),
                value: amountWithoutPassenger.toFixed(2) + ' ' + this.currency,
                class: 'w-100pc d-block lH-25',
                valueClass: 'w-100pc d-block text-right lH-25 fw-700'
            });
            let PassengerLoadArray = this.mileageArray ? this.mileageArray.filter(a => a.allowanceRate && a.allowanceRate > 0) : false;
            if (PassengerLoadArray && PassengerLoadArray.length > 0) {
                let PassengerLoadRate = '';
                let countP = 0;
                let passengerRateInt = 0.0;
                PassengerLoadArray.forEach(item => {
                    passengerRateInt = Number(passengerRateInt) + Number(item.allowanceRate.toFixed(2))
                    PassengerLoadRate = PassengerLoadRate + (countP == 0 ? '' : ' / ') + item.allowanceRate.toFixed(2);
                    countP++;
                });

                seqObjectArray.push({
                    name: this.translate.instant("passenger_load_amount"),
                    value: Number(passengerLoadAmount).toFixed(2) + ' ' + this.currency,
                    class: 'w-100pc d-block lH-25',
                    valueClass: 'w-100pc d-block text-right lH-25 fw-700'
                });
                this.amountDynamicText = this.translate.instant("mileage_paid_with_passenger");
            }
            else {
                seqObjectArray.push({
                    name: this.translate.instant("passenger_load_amount"),
                    value: '0.00 ' + this.currency,
                    class: 'w-100pc d-block lH-25',
                    valueClass: 'w-100pc d-block text-right lH-25 fw-700'
                });
                this.amountDynamicText = this.translate.instant("mileage_paid_with_passenger");
            }


        }
        else {
            seqObjectArray.push({
                name: this.translate.instant("mileage_rates_ffl"),
                value: this.mileageRate,
                class: 'w-100pc d-block lH-25',
                valueClass: 'w-100pc d-block text-right lH-25 fw-700'
            });
            this.amountDynamicText = this.translate.instant("mileage_paid_ffl");
        }
        return seqObjectArray;
    }
    closeMileagePopup() {
        this.showMileageMap = false;
        this.showMileageMapFromWeb = false;
        this.showCommonMileageMap = false;
        this.toggleChatbot(!this.showMileageMap);
    }

    vertices: google.maps.LatLngLiteral[] = [];
    fnBindMarkerMultipleLeg(mileageArray) {
        this.markers = [];
        this.markerPositions = [];
        this.vertices = [];
        let i = 0;
        mileageArray.forEach(item => {

            item.route.forEach(routeElement => {
                this.vertices.push(
                    {
                        lat: routeElement.latitude,
                        lng: routeElement.longitude,
                    }
                );
            });

            this.markers.push({
                position: {
                    lat: item.route[0].latitude,
                    lng: item.route[0].longitude,
                },
                label: {
                    color: '#ffffff',
                    fontWeight: 'bold',
                    text: this.labelList[i],
                    fillColor: '#eee'
                },
                options: {
                    animation: google.maps.Animation.DROP,
                },
            })
            if (i == (mileageArray.length - 1)) {
                i++;


                this.vertices.push(
                    {
                        lat: item.route[item.route.length - 1].latitude,
                        lng: item.route[item.route.length - 1].longitude,
                    }
                );

                let StartLat = mileageArray[0].route[0].latitude;
                let EndLat = mileageArray[mileageArray.length - 1].route[mileageArray[mileageArray.length - 1].route.length - 1].latitude
                if (StartLat == EndLat) {
                    this.markers.splice(0, 1);
                }

                this.markers.push({
                    position: {
                        lat: item.route[item.route.length - 1].latitude,
                        lng: item.route[item.route.length - 1].longitude,
                    },
                    label: {
                        color: '#ffffff',
                        fontWeight: 'bold',
                        text: this.labelList[i],
                        fillColor: '#eee'
                    },
                    options: {
                        animation: google.maps.Animation.DROP,
                    },
                })

            }
            i++;


        });


        this.center = { lat: mileageArray[0].route[0].latitude, lng: mileageArray[0].route[0].longitude };
        this.zoom = 10;



    }
    fnBindMarker(mileageArray) {
        this.markers = [];
        this.markerPositions = [];
        this.vertices = [];
        let i = 0;
        mileageArray.forEach(item => {

            item.route.forEach(routeElement => {
                this.vertices.push(
                    {
                        lat: routeElement.latitude,
                        lng: routeElement.longitude,
                    }
                );
            });

            this.markers.push({
                position: {
                    lat: item.route[0].latitude,
                    lng: item.route[0].longitude,
                },
                label: {
                    color: '#096094',
                },
                options: {
                    animation: google.maps.Animation.DROP,
                    icon: {
                        url: '../../assets/svg-icons/start.svg',
                        scaledSize: {
                            height: 50,
                            width: 50
                        }
                    }
                },
            })
            if (i == (mileageArray.length - 1)) {
                i++;


                this.vertices.push(
                    {
                        lat: item.route[item.route.length - 1].latitude,
                        lng: item.route[item.route.length - 1].longitude,
                    }
                );

                let StartLat = mileageArray[0].route[0].latitude;
                let EndLat = mileageArray[mileageArray.length - 1].route[mileageArray[mileageArray.length - 1].route.length - 1].latitude
                if (StartLat == EndLat) {
                    this.markers.splice(0, 1);
                }

                this.markers.push({
                    position: {
                        lat: item.route[item.route.length - 1].latitude,
                        lng: item.route[item.route.length - 1].longitude,
                    },
                    label: {
                        color: '#096094',
                    },
                    options: {
                        animation: google.maps.Animation.DROP,
                        icon: {
                            url: StartLat == EndLat ? '../../assets/svg-icons/startandend.svg' : '../../assets/svg-icons/end.svg',
                            scaledSize: {
                                height: 50,
                                width: 50
                            }
                        }
                    },
                })

            }
            i++;


        });


        this.center = { lat: mileageArray[0].route[0].latitude, lng: mileageArray[0].route[0].longitude };
        this.zoom = 10;



    }

    fnReturnFloat(value) {
        return parseFloat(value);
    }
    fnGetJourneyIcon(isOrigin: boolean, rowIndex, mileage) {

        let url = '';
        if (this.journeyType == 2) {
            if (isOrigin && rowIndex == 0) {
                url = '../../assets/svg-icons/startandend.svg'
            }
            if (!isOrigin && rowIndex == 0) {
                url = '../../assets/svg-icons/return.svg'
            }
            if (isOrigin && rowIndex == 1) {
                url = '';
            }
        }
        else {
            if (isOrigin && rowIndex == 0) {
                url = '../../assets/svg-icons/start.svg'
            }

            if (!isOrigin && rowIndex == (this.mileageArray.length - 1)) {
                url = '../../assets/svg-icons/end.svg'
                if (this.journeyType == 3) {
                    let origen = this.mileageArray[0].route[0].latitude;
                    let currentLocation = mileage.route[mileage.route.length - 1].longitude;
                    if (origen == currentLocation) {
                        url = '../../assets/svg-icons/startandend.svg'
                    }
                }
            }
        }
        return url;
    }



    receiptsInfoDetails : IReceiptInfo[];
    fnShowReceiptsPanel(items: IReceiptInfo[] , fromgrid:boolean=false, createType : number = 1) {
        
        this.receiptsInfoDetails = items;       
        this.selectedReceiptInfo = items;
        this.imageReceiptInfo = this.selectedReceiptInfo.filter((r) => r.receiptFormatId != this.attachmentTypeEnum.Pdf);
        this.receiptCount = items.length;
        if(fromgrid ==false && createType == 1)
        {
            this.showReceiptsPanel = true; 
        }
        else{
            if(items.length >1 && createType == 1)
            {
                this.receiptInfo=items;
                this.showReceiptsPanel = true;  
            }
            else
            {
                this.receiptInfo=items;
                this.fnShowSelectedReceipt(items[0] , 0, createType);
            }       
        }

    }


    fnShowReceiptsPanelFromGrid(items: IReceiptInfo[]) {
        this.selectedReceiptInfo = items;
        this.imageReceiptInfo = this.selectedReceiptInfo.filter((r) => r.receiptFormatId != this.attachmentTypeEnum.Pdf);
        this.receiptCount = items.length;
        if (items.length > 1) {
            this.showReceiptsPanel = true;
        }
        else
        {
            this.fnShowSelectedReceipt(items[0] , 1, 1);
        }          
    }

    fnShowReceiptsPanelfromGrid(items: IReceiptInfo[]) {
        this.selectedReceiptInfo = items;
        this.imageReceiptInfo = this.selectedReceiptInfo.filter((r) => r.receiptFormatId != this.attachmentTypeEnum.Pdf);
        this.receiptCount = items.length;
        if (items.length > 1) {
            this.showReceiptsPanel = true;
        }
        else
        {
            this.fnShowSelectedReceipt(items[0] , 1, 1);
        }           
    }

    fnToggleReceipt() {
        if (this.mapShow) {
            this.mapShow = false;
        }
        else {
            this.showReceipt = false;
            this.toggleChatbot(true);
        }

        this.receiptOutputModel = new ReceiptOutputModel();
        this.receiptOutputModel.receiptModel = this.selectedReceiptInfo;
        this.receiptOutputModel.previewModel = this.previewOutputModel;
        this.closePanelEvent.emit(this.receiptOutputModel);
    }

    fnshowReceipt(item: IReceiptInfo[]) {

        this.currentIndex = 0;
        this.receiptInfo = item;
        this.receiptCount = item.length;
        if (this.receiptCount == 0) {
            this.showReceipt = false;
        }
        else {
            this.showReceipt = true;
        }
        this.toggleChatbot(!this.showReceipt);
        this.cycleItems();
    }

    showPreviewPanel:boolean = false;
    scannedText : KeyValue[];
    previewImageUrl : string;
    previewOutputModel : PreviewOutputModel;

    fnClosePreviewPanel(_event)
    {
        this.showPreviewPanel = false;
        this.showScanDetails = true;
        this.hideUseReceipt = false;
        this.previewOutputModel = new PreviewOutputModel();
    }

    fnSavePreviewPanel(_event)
    {
        this.showPreviewPanel = false;
        this.previewOutputModel = _event;
        this.showReceiptsPanel = false;
        this.fnToggleReceipt();
    }

    fnScanReceipt()
    {       
       this.ngxService.start();
       let receipt = this.receiptsInfoDetails.filter(it=>it.expenseReceiptId == this.currentReceiptId);
       this.previewImageUrl = receipt[0].receiptUrl;
       this.hideUseReceipt = true;       
       this.extractInvoiceReceipts(false,receipt,this.expenseStatusEnum.PendingForSubmission,[]);   
    }

    get expenseStatusEnum() {
        return ExpenseStatusEnum;
    }
    
    
    fnShowSelectedReceipt(item: IReceiptInfo, index: number, createType:number) {

        if (item.receiptFormatId == this.attachmentTypeEnum.Pdf) {
            this.selectedAttachmentType = this.attachmentTypeEnum.Pdf;
            this.pdfSrc = item.receiptUrl;
            this.pdfName = item.receiptName;
            this.pdfExpenseReceiptId = item.expenseReceiptId;
            this.showPdfViewer = true;
        } else {
            this.ngxService.start();
            this.selectedAttachmentType = this.attachmentTypeEnum.Image;
            if (this.receiptCount == 0) {
                this.showReceipt = false;
            }
            else {
                this.showScanDetails = this.orgRoleId == 1 ? true : false;
                this.hideUseReceipt=false;
                this.showReceipt = true;
                this.currentIndex = index;
                this.currentReceiptId = item.expenseReceiptId;
            }
            this.toggleChatbot(!this.showReceipt);
            this.cycleItems();
        }
    }
    options: google.maps.MapOptions;
    center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
    markerOptions: google.maps.MarkerOptions = { draggable: false };
    markerPositions: google.maps.LatLngLiteral[] = [];

    cycleItems() {
        this.mapShow = false;
        this.receiptLocation = null;
        this.receiptDate = null;

        setTimeout(() => {
            this.adjustImageSize();
        }, 100);

        if (this.showReceipt == true && this.receiptInfo[this.currentIndex].receiptLocationInfo.location != null) {
            var items = $('.imgContainer div');
            items.hide();
            $('.imgContainer div').eq(this.currentIndex).css('display', 'inline-block');
            this.receiptLocation = this.receiptInfo[this.currentIndex].receiptLocationInfo.location;
            this.lat = parseFloat(this.receiptInfo[this.currentIndex].receiptLocationInfo.latitude.toString());
            this.long = parseFloat(this.receiptInfo[this.currentIndex].receiptLocationInfo.longitude.toString());
            this.receiptDate = new Date(this.receiptInfo[this.currentIndex].receiptDate);
            this.zoom = 15;
            this.center = { lat: this.lat, lng: this.long };
            this.markerPositions.push(this.center);
        }
    }

    toggleChatbot(visible: boolean) {
        var el1 = document.getElementsByClassName('iticks-notif-msg') as HTMLCollectionOf<HTMLElement>;
        var el2 = document.getElementsByClassName('iticks-pop-button') as HTMLCollectionOf<HTMLElement>;

        var style = visible ? "block" : "none";
        if (el1 && el1.length > 0) {
            el1[0].style.display = style;
        }
        if (el2 && el2.length > 0) {
            el2[0].style.display = style;
        }
    }

    fnPdfViewerReceiver() {
        this.showPdfViewer = false;
        this.pdfSrc = "";
        this.pdfName = "";
        this.pdfExpenseReceiptId = 0;
    }

    fnDeletePdf(pdfExpenseReceiptId) {
        this.pdfExpenseReceiptId = pdfExpenseReceiptId;
    
        this.fnShowDeletePdfAlert();
    }

    fnDeleteReceipt() {
        this.fnShowDeleteReceiptAlert();
    }

    fnDeletePdfAction() {
        this.showPdfViewer = false;
        this.selectedReceiptInfo = this.selectedReceiptInfo.filter(a => a.expenseReceiptId != this.pdfExpenseReceiptId);
        this.receiptCount = this.selectedReceiptInfo.length;
        if (this.receiptCount > 0) {
            this.fnShowReceiptsPanel(this.selectedReceiptInfo);
        }
        else {
            this.fnCloseReceiptsPanel()
        }
    }

    fnDeleteReceiptAction() {
        this.selectedReceiptInfo = this.selectedReceiptInfo.filter(a => a.expenseReceiptId != this.currentReceiptId);
        this.showReceipt = false;
        this.receiptCount = this.selectedReceiptInfo.length;
        if (this.receiptCount > 0) {
            this.fnShowReceiptsPanel(this.selectedReceiptInfo);
        }
        else {
            this.fnCloseReceiptsPanel()
        }

    }

    fnFormatBytes(bytes: number) {
        return fileSizeService.GetFileSizeFromBytes(bytes);
    }
    receiptOutputModel : ReceiptOutputModel;
    fnCloseReceiptsPanel() {
        this.showReceiptsPanel = false;
        this.imageReceiptInfo = [];
        this.receiptOutputModel = new ReceiptOutputModel();
        this.receiptOutputModel.receiptModel = this.selectedReceiptInfo;
        this.receiptOutputModel.previewModel = this.previewOutputModel;
        this.closePanelEvent.emit(this.receiptOutputModel);
    }

    fnShowDeleteReceiptAlert() {
        this.alertPanelModelForDeleteReceipt = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            this.translate.instant("label_cancel"),
            this.translate.instant("label_confirm"),
            true,
            this.translate.instant("do_you_want_to_delete_receipt")
        );
    }

    fnCloseDeleteReceiptAlert(returnValue) {
        if (returnValue)
            this.fnDeleteReceiptAction();
        this.alertPanelModelForDeleteReceipt = new AlertPanelModel(false, "", [], "", "", true, "");
    }

    fnShowDeletePdfAlert() {
        this.alertPanelModelForDeletePdf = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            this.translate.instant("label_cancel"),
            this.translate.instant("label_confirm"),
            true,
            this.translate.instant("do_you_want_to_delete_receipt")
        );
    }

    fnCloseDeletePdfAlert(returnValue) {
        if (returnValue)
            this.fnDeletePdfAction();
        this.alertPanelModelForDeletePdf = new AlertPanelModel(false, "", [], "", "", true, "");
    }

    fnSetZoomOnMap() {
        let miles = Number(this.milesTravelled.split(' ')[0]);
        if (miles <= 30) {
            return this.fnFindJourneTypeAndSetZoom(30);
        }
        else if (miles > 30 && miles <= 50) {
            return this.fnFindJourneTypeAndSetZoom(50);
        }
        else if (miles > 50 && miles < 70) {
            return this.fnFindJourneTypeAndSetZoom(70);
        }
        else if (miles > 70 && miles < 100) {
            return this.fnFindJourneTypeAndSetZoom(100);
        }
        else if (miles >= 100) {
            return this.fnFindJourneTypeAndSetZoom(150);
        }
        else {
            return this.fnFindJourneTypeAndSetZoom(30);
        }


    }
    fnFindJourneTypeAndSetZoom(range) {
        let zoom = 8;
        switch (range) {
            case 30:
                zoom = (this.journeyType == 1 ? 10 : (this.journeyType == 2 ? 10 : (this.isCompleteJourney ? 10 : 10)))
                break;
            case 50:
                zoom = (this.journeyType == 1 ? 9 : (this.journeyType == 2 ? 10 : (this.isCompleteJourney ? 10 : 9)))
                break;
            case 70:
                zoom = (this.journeyType == 1 ? 8 : (this.journeyType == 2 ? 9 : (this.isCompleteJourney ? 10 : 8)))
                break;
            case 100:
                zoom = (this.journeyType == 1 ? 7 : (this.journeyType == 2 ? 9 : (this.isCompleteJourney ? 8 : 7)))
                break;
            case 150:
                zoom = (this.journeyType == 1 ? 7 : (this.journeyType == 2 ? 8 : (this.isCompleteJourney ? 8 : 7)))
                break;
        }
        return zoom;
    }
    fnUpdateReceiptPanelForDeleteButton(showDeleteButton: boolean) {
        this.showDeleteButton = showDeleteButton;
    }
    


    extractInvoiceReceipts(hasVoiceMemo, receiptsInfo, saveStatus, expenseIds: number[]) {
   
        let recSaveReq = new ReceiptSaveRequest();
        recSaveReq.createdOn = new Date().valueOf();
        recSaveReq.expenseIds = expenseIds;
        recSaveReq.orgId = 1223;
        recSaveReq.userId = 24323;
        recSaveReq.platformID = 1;
        recSaveReq.saveStatus = saveStatus;
        recSaveReq.hasReceipt = receiptsInfo && receiptsInfo.length > 0 ? true : false;
        recSaveReq.hasVoiceMemo = hasVoiceMemo;
        recSaveReq.currencyId = 0;
        recSaveReq.receiptsInfo = receiptsInfo;
    
        //Modeling for saving receipts
        let receiptSaveRequest = new ApiRequest<ReceiptSaveRequest>("ExtractInvoiceReceipt", 3.8, '1');
        receiptSaveRequest.requestInfo = recSaveReq;
        let recSaveReqString = JSON.stringify(receiptSaveRequest);
        let expReq = new ExpenseRequest(recSaveReqString, this.capturedReceipts, null);
        let receiptRequest = new ApiRequest<IExpenseRequest>("ScanReceiptData", environment.applicationVersion, environment.requestId);
        receiptRequest.requestInfo = expReq;
        this.ngxService.start();
        this.claimantOnWebService
          .ScanExpenseReceipts(receiptRequest)
          .subscribe((response: IApiresponse<KeyValue[]>) => {
            if (response.statusCode == 0) {
                this.ngxService.stop();
                this.showPreviewPanel = true;
                this.scannedText = response.responseInfo;   
            } 
          });
      }

      
}

