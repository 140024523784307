import { OnInit, Component, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { BaseComponent } from "src/app/shared/base/base-component";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "src/app/alert-panel/alert.service";
import { DelegateService } from "src/app/_services/delegate/delegate.service";
import { ApiRequest, IApiresponse, MileageUOMEnum, JourneyTypeEnum, ExpenseErrorWarningTypeEnum } from "src/app/_models";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DynamicFormComponent } from "src/app/shared/forms/dynamic-form/dynamic-form.component";
import { DynamicFormService } from "src/app/shared/forms/dynamic-form/dynamic-form.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
    MultipleLegsAddJourneyFormModel, CalculateMileageResponse, SourceDestinationResponse,
    LocationDetail, MultipleLegsPopupModel, SelectDetourInputModel, SelectDetourOutputModel, MileageAllowance,
    IResourceMileageDetailsResponse, MileageData, CalculateMileageRequest, SelectTagsPopupModel
} from "src/app/_models/claimant-on-web/claimant-on-web";
import { AlertPanelModel } from 'src/app/_models/common/alertPanelModel';
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { GetField } from "src/app/shared/forms/form-functions/form-functions";
import { BaseCategoryTypeEnum } from "../../../../_models";

@Component({
    selector: "add-journey-panel",
    templateUrl: "./add-journey-panel.component.html"
})
export class AddjourneyPanelComponent extends BaseComponent implements OnInit {
    @ViewChild(DynamicFormComponent) journeyForm: DynamicFormComponent;

    @Input() inputModel: MultipleLegsPopupModel;
    @Input() mileageAllowance: MileageAllowance[];
    @Input() resourceMileageDetails: IResourceMileageDetailsResponse;
    @Input() vehicleResponse: any;
    @Input() expenseModel: any;
    @Input() isCarbonFootprintReporting:boolean;
    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() submitEvent = new EventEmitter<any>();
    @Output() deleteEvent = new EventEmitter<any>();

    claimsSubscription: Subscription;
    userActionEventSubscription: Subscription;
    claims: Claims;
    panelHeading: string;

    disableSaveButton: boolean = false;

    journeyFormModel: MultipleLegsAddJourneyFormModel;
    journeyFormFields: FormlyFieldConfig[] = [];

    showWarningAlerts: boolean = false;
    showWarningAlertsModel: AlertPanelModel;

    showCreateMileageTagPanel: boolean = false;
    createMileageTagInputModel: SelectTagsPopupModel;

    showSelectDetourPanel: boolean = false;
    selectDetourInputModel: SelectDetourInputModel;

    showDebtourNotActivatedAlertPanel: boolean;
    debtourNotActivatedAlertModel: AlertPanelModel;

    showAddPassengerLoadPanel: boolean = false;
    passengerLoadInputModel: MileageAllowance[];

    showPassengerLoadNotActivatedAlertPanel: boolean;
    passengerLoadNotActivatedAlertModel: AlertPanelModel;
    mileageUOMText: string;
    carbonEmissionText:string;

    showDeleteConfirmationAlert: boolean = false;
    deleteConfirmationAlertModel: AlertPanelModel;

    constructor(
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private alertService: AlertService,
        private delegateService: DelegateService,
        private ngxService: NgxUiLoaderService,
        private dynamicFormService: DynamicFormService,
        private claimantOnWebService: ClaimantOnWebService,
    ) {
        super();
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });

        this.fnObserveUnsavedChangesAlerts();
    }

    ngOnInit(): void {
        this.fnSetPanelHeading();
        if (this.inputModel) {
            this.carbonEmissionText = this.translate.instant('text_carbonemission');
            this.mileageUOMText = this.inputModel.mileageUOM == MileageUOMEnum.Kilometre
                ? this.translate.instant("text_km")
                : this.translate.instant("text_m");

            if (!this.inputModel.isEditMode) {
                if (this.inputModel.isReturnJourney) {
                    this.fnOnReturnJourney(this.inputModel.selectedSequence);
                }
                else {
                    this.fnInitializeForm();
                }
            }
            else {
                if (this.inputModel.isEditMode) {
                    this.fnEditInputLeg(this.inputModel.selectedSequence);
                }
            }
        }
    }

    fnOnReturnJourney(selectedSequence) {

        if (this.inputModel.multipleLegList && this.inputModel.multipleLegList.length > 0) {
            let data = this.inputModel.multipleLegList.find(d => d.sequence == selectedSequence);

            if (data) {
                this.journeyFormModel = new MultipleLegsAddJourneyFormModel();
                this.journeyFormModel.fromLocation = data.toLocation;
                this.journeyFormModel.toLocation = data.fromLocation;
                this.journeyFormModel.sequence = data.sequence + 1;
                this.journeyFormModel.notes = data.notes;
                this.journeyFormModel.detour = data.detour ? data.detour : null;
                this.journeyFormModel.detourJustificationNotes = data.detourJustificationNotes;
                this.journeyFormModel.tagList = data.tagList;
                this.journeyFormModel.isEditMode = false;
                this.journeyFormModel.distance = 0.00;
                this.journeyFormModel.isFromLocationDisabled = true;
                this.journeyFormModel.mileageAllowance = data.mileageAllowance;
                this.journeyFormModel.allowanceDescription = data.mileageAllowance ? data.mileageAllowance.allowanceDescription : "";
                this.journeyFormModel.fromLocationDescription = this.journeyFormModel.fromLocation.location;
                this.journeyFormModel.toLocationDescription = this.journeyFormModel.toLocation.location;
                this.journeyFormModel.userHomeAddressInfo = this.resourceMileageDetails?.userHomeAddressInfo;
                this.journeyFormModel.userOfficeAddressInfo = this.resourceMileageDetails?.userOfficeAddressInfo;
                this.journeyFormModel.triangulationRule = this.resourceMileageDetails?.triangulationRule;
                this.journeyFormModel.googleCallsInfo = this.resourceMileageDetails?.googleCallsInfo;
                this.journeyFormModel.totalTriangulatedDistanceText = data.totalTriangulatedDistanceText;
                this.journeyFormModel.carbonFootprint = data.carbonFootprint;
                this.journeyFormModel.isOtherOfficeAvailable = this.resourceMileageDetails?.isOtherOfficeAvailable;
                this.journeyFormModel.currentLocation = this.expenseModel.mileage.currentLocation;
                setTimeout(() => {
                    this.fnInitializeForm();
                }, 80);
                
                this.fnUpdateTagDisplay(this.journeyFormModel.tagList);
            }
        }
    }

    fnEditInputLeg(sequence) {
        if (this.inputModel.multipleLegList && this.inputModel.multipleLegList.length > 0) {
            let editLegDetails = this.inputModel.multipleLegList.find(d => d.sequence == sequence);
            if (editLegDetails) {
                this.fnSelectLegDetailsForEdit(editLegDetails);
            }
        }
    }


    fnSelectLegDetailsForEdit(data) {
        this.journeyFormModel = new MultipleLegsAddJourneyFormModel();
        this.journeyFormModel.fromLocation = data.fromLocation;
        this.journeyFormModel.toLocation = data.toLocation;
        this.journeyFormModel.distance = data.distance;
        this.journeyFormModel.sequence = data.sequence;
        this.journeyFormModel.carbonFootprint = data.carbonFootprint;
        this.journeyFormModel.notes = data.notes;
        this.journeyFormModel.detour = data.detour ? data.detour : null;
        this.journeyFormModel.detourJustificationNotes = data.detourJustificationNotes;
        this.journeyFormModel.tagList = data.tagList;
        this.journeyFormModel.isEditMode = true;
        this.journeyFormModel.isFromLocationDisabled = data.sequence > 1 ? true : false;
        this.journeyFormModel.mileageAllowance = data.mileageAllowance;
        this.journeyFormModel.allowanceDescription = data.mileageAllowance ? data.mileageAllowance.allowanceDescription : "";
        this.journeyFormModel.fromLocationDescription = this.journeyFormModel.fromLocation.location;
        this.journeyFormModel.toLocationDescription = this.journeyFormModel.toLocation.location;
        this.journeyFormModel.userHomeAddressInfo = this.resourceMileageDetails?.userHomeAddressInfo;
        this.journeyFormModel.userOfficeAddressInfo = this.resourceMileageDetails?.userOfficeAddressInfo;
        this.journeyFormModel.triangulationRule = this.resourceMileageDetails?.triangulationRule;
        this.journeyFormModel.googleCallsInfo = this.resourceMileageDetails?.googleCallsInfo;
        this.journeyFormModel.totalTriangulatedDistanceText = data.totalTriangulatedDistanceText;
        this.journeyFormModel.isOtherOfficeAvailable = this.resourceMileageDetails?.isOtherOfficeAvailable;
        this.journeyFormModel.currentLocation = this.expenseModel.mileage.currentLocation;
        this.fnInitializeForm();
        this.fnUpdateTagDisplay(this.journeyFormModel.tagList);
    }


    fnSetPanelHeading() {
        if (this.inputModel) {
            this.panelHeading = this.inputModel.isEditMode
                ? this.translate.instant('edit_journey')
                : this.translate.instant('add_journey');
        }
    }

    ngOnDestroy() {
        this.claimsSubscription.unsubscribe();
        this.userActionEventSubscription.unsubscribe();
    }

    canDeactivate(): boolean {
        return !this.journeyForm.form.dirty;
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnObserveUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnInitializeForm() {

        if (!this.inputModel.isEditMode && !this.inputModel.isReturnJourney) {
            this.fnInitialiseFormModel();
        }

        let fields: FormlyFieldConfig[] = [];

        fields.push({
            fieldGroupClassName: 'block row',
            fieldGroup: [
                {
                    key: 'fromLocationDescription',
                    type: 'google-location-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    templateOptions: {
                        label: this.translate.instant('text_from'),
                        placeholder: '',
                        required: true,
                        modelKey: 'fromLocation',
                        change: (field, $event) => {
                            this.fnOnMileageAddressChange();
                        }
                    },
                    expressionProperties: { 'templateOptions.disabled': 'model.isFromLocationDisabled' }
                },
                {
                    key: 'toLocationDescription',
                    type: 'google-location-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    templateOptions: {
                        label: this.translate.instant('text_to'),
                        placeholder: '',
                        required: true,
                        modelKey: 'toLocation',
                        change: (field, $event) => {
                            this.fnOnMileageAddressChange();
                        }
                    }
                },
                {
                    key: 'distance',
                    type: 'text-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    templateOptions: {
                        label: `${this.translate.instant('text_distance')} (${this.mileageUOMText})`,
                        placeholder: '',
                        required: true,
                        disabled: true,
                        htmlCustomMsg: this.journeyFormModel.totalTriangulatedDistanceText

                    },
                },
                {
                    key: 'calculateMileage',
                    type: 'formly-button',
                    templateOptions: {
                        label: this.translate.instant('text_calculate'),
                        placeholder: '',
                        required: false,
                        buttonClass: 'btn btn-outline-primary bg-white margin-top-15',
                        iconClass: 'far fa-route',
                        click: (field, $event) => {
                            this.fnCalculateMileageMultipleLeg()
                        }
                    },
                    expressionProperties: {
                        'templateOptions.disabled': 'model.disabledCalculateBtn'
                    }
                },
                {
                    key: 'carbonFootprint',
                    type: 'text-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    hideExpression: !this.isCarbonFootprintReporting,
                    templateOptions: {
                        label: `${this.translate.instant('carbon_emission')} (${this.carbonEmissionText})`,
                        placeholder: '',
                        required: true,
                        disabled: true
                    }
                },
                {
                    className: this.isCarbonFootprintReporting ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-right-0' : '',
                },
                {
                    key: 'allowanceDescription',
                    type: 'text-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    hideExpression: !(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle),
                    templateOptions: {
                        label: this.translate.instant('text_passenger_load'),
                        placeholder: '',
                        required: false,
                        readonly: true,
                        click: (field, $event) => {
                            this.fnShowAddPassengerLoadPanel();
                        }
                    }
                },
                {
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-right-0',
                },
                {
                    key: 'detour',
                    type: 'text-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    hideExpression: !(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle),
                    templateOptions: {
                        label: `${this.translate.instant('enter_detour')} (${this.mileageUOMText})`,
                        placeholder: '',
                        required: false,
                        readonly: true,
                        click: (field, $event) => {
                            this.fnShowSelectDetourPanel();
                        }
                    }
                },
                {
                    key: 'detourJustificationNotes',
                    type: 'text-input',
                    className: 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-3',
                    hideExpression: !(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle),
                    templateOptions: {
                        label: this.translate.instant('enter_detour_justification_notes'),
                        placeholder: '',
                        required: false,
                        readonly: true,
                        click: (field, $event) => {
                            this.fnShowSelectDetourPanel();
                        }
                    }
                },
                {
                    key: 'notes',
                    type: 'text-input',
                    className: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-left-3',
                    hideExpression: !(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle),
                    templateOptions: {
                        label: this.translate.instant('label_notes'),
                        placeholder: '',
                        required: false,
                        maxLength: 200,
                        maxLengthCount: true,
                    }
                },
                {
                    key: 'tagList',
                    type: 'formly-button',
                    hideExpression: !(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle),
                    templateOptions: {
                        label: this.translate.instant('tag'),
                        placeholder: '',
                        required: false,
                        buttonClass: 'create-expense-tag margin-top-15',
                        iconClass: 'fas fa-tag text-charcoal-black',
                        click: (field, $event) => {
                            this.fnShowCreateMileageTagPanel();
                        }
                    }
                }] 
            });   

        this.journeyFormFields = fields;
    }

    fnInitialiseFormModel() {
        this.journeyFormModel = new MultipleLegsAddJourneyFormModel();

        let location: LocationDetail = { location: '', latitude: 0, longitude: 0, locationTrackTime: 0, placeId: 0 };
        this.journeyFormModel.fromLocation = location;
        this.journeyFormModel.toLocation = location;
        this.journeyFormModel.sequence = this.inputModel.multipleLegList.length > 0 ? this.inputModel.multipleLegList.length + 1 : 1;
        this.journeyFormModel.notes = "";
        this.journeyFormModel.detour = null;
        this.journeyFormModel.detourJustificationNotes = "";
        this.journeyFormModel.tagList = [];
        this.journeyFormModel.mileageAllowance = null;
        this.journeyFormModel.allowanceDescription = "";
        this.journeyFormModel.userHomeAddressInfo = this.resourceMileageDetails?.userHomeAddressInfo;
        this.journeyFormModel.userOfficeAddressInfo = this.resourceMileageDetails?.userOfficeAddressInfo;
        this.journeyFormModel.triangulationRule = this.resourceMileageDetails?.triangulationRule;
        this.journeyFormModel.googleCallsInfo = this.resourceMileageDetails?.googleCallsInfo;
        this.journeyFormModel.disabledCalculateBtn = true;
        this.journeyFormModel.carbonFootprint = null;
        //set from location
        this.journeyFormModel.fromLocation = this.fnGetFromLocation();
        this.journeyFormModel.fromLocationDescription = this.journeyFormModel.fromLocation.location;
        this.journeyFormModel.toLocationDescription = this.journeyFormModel.toLocation.location;
        this.journeyFormModel.isFromLocationDisabled = this.inputModel.multipleLegList.length > 0 ? true : false;
        this.journeyFormModel.isOtherOfficeAvailable = this.resourceMileageDetails?.isOtherOfficeAvailable;
        this.journeyFormModel.currentLocation = this.expenseModel.mileage.currentLocation;
    }

    fnGetFromLocation() {
        let location: LocationDetail = { location: '', latitude: 0, longitude: 0, locationTrackTime: 0, placeId: 0 };
        if (this.inputModel.multipleLegList && this.inputModel.multipleLegList.length > 0) {
            let lastLegDetails = this.inputModel.multipleLegList[this.inputModel.multipleLegList.length - 1];
            if (lastLegDetails) {
                location = lastLegDetails.toLocation;
            }
        }
        return location;
    }


fnIsBtnCalculateMileageDisbled() {
  let isDisabled = false;
  if (!this.journeyFormModel.fromLocation.location
    || !this.journeyFormModel.fromLocation.location.trim()
    || !this.journeyFormModel.toLocation.location
    || !this.journeyFormModel.toLocation.location.trim()
    || !this.journeyFormModel.fromLocation.placeId
    || !this.journeyFormModel.toLocation.placeId) {
    isDisabled = true;
  }
  this.journeyFormModel.disabledCalculateBtn = isDisabled;
}

    fnSaveJourneyDetails() {
        this.dynamicFormService.onFormSubmitted(true);
        this.disableSaveButton = true;

        if (this.journeyForm.form.invalid) {
            this.disableSaveButton = false;
            return;
        }
        // zero distance validation
        if (!Number(this.journeyFormModel.distance)
            && !(this.calculateMileageDetails && this.calculateMileageDetails.mileageDetailList
                && this.calculateMileageDetails.mileageDetailList.length > 0
                && this.calculateMileageDetails.mileageDetailList[0].amount)) {
            this.journeyFormModel.distance = null;
            this.disableSaveButton = false;
            this.fnShowWarningAlerts(ExpenseErrorWarningTypeEnum.ZeroDistance);
            return;
        }

        let legModel = new SourceDestinationResponse();
        let location: LocationDetail = { location: '', latitude: 0, longitude: 0, locationTrackTime: 0, placeId: 0 };
        legModel.fromLocation = location;
        legModel.toLocation = location;
        legModel.fromLocation = this.journeyFormModel.fromLocation;
        legModel.toLocation = this.journeyFormModel.toLocation;
        legModel.notes = this.journeyFormModel.notes;
        legModel.sequence = this.journeyFormModel.sequence;
        legModel.detour = this.journeyFormModel.detour ? Number(this.journeyFormModel.detour) : 0;
        legModel.detourJustificationNotes = this.journeyFormModel.detourJustificationNotes;
        legModel.mileageAllowance = this.journeyFormModel.mileageAllowance;
        legModel.distance = this.journeyFormModel.distance;
        legModel.tagList = this.journeyFormModel.tagList;
        legModel.carbonFootprint = this.journeyFormModel.carbonFootprint;
        this.submitEvent.emit(legModel);
    }

    fnOnMileageAddressChange() {
        this.journeyFormModel.distance = null;
        this.journeyForm.form.controls.distance.setValue(null);
        let htmlCustomMsg = GetField('distance', this.journeyFormFields);
        if (htmlCustomMsg) {
            htmlCustomMsg.templateOptions.htmlCustomMsg = '';
        }
        this.fnIsBtnCalculateMileageDisbled();
    }
    calculateMileageDetails: CalculateMileageResponse;
    fnCalculateMileageMultipleLeg() {

        this.fnMarkFormAsDirty();

        //check validation
        if (!this.journeyFormModel.fromLocation.location || !this.journeyFormModel.toLocation.location ||
            !this.journeyFormModel.fromLocation.placeId || !this.journeyFormModel.toLocation.placeId) {
            return;
        }

        // Start Loader

        this.ngxService.start();
        this.disableSaveButton = true;

        let vehicleId = 0;

        let defaultVehicle = this.vehicleResponse.find(it => it.isDefault);
        if (defaultVehicle) {
            vehicleId = defaultVehicle.vehicleId
        }

        let request = new ApiRequest<CalculateMileageRequest>("CalculateMileageMultipleLeg", environment.applicationVersion, environment.requestId);
        let requestInfo = new CalculateMileageRequest();
        requestInfo.baseCategoryId = this.expenseModel.baseCategoryId;
        requestInfo.orgExpenseCategoryId = this.expenseModel.orgExpenseCategoryId;
        requestInfo.journeyType = JourneyTypeEnum.Multiple;
        let vehicleDetail = this.vehicleResponse.find(it => it.vehicleId == this.expenseModel.mileage.vehicleId);
        requestInfo.orgVehicleTypeMapId = (this.inputModel.isMultipleVehiclePurchase
            ? (vehicleDetail ? vehicleDetail.orgVehicleTypeMapId : 0) : this.resourceMileageDetails ? this.resourceMileageDetails.vehicleDetailResponse.orgVehicleTypeMapId : 0);

        requestInfo.vehicleTypeId = (this.inputModel.isMultipleVehiclePurchase
            ? (vehicleDetail ? vehicleDetail.vehicleTypeId : 0) : this.resourceMileageDetails ?  this.resourceMileageDetails.vehicleDetailResponse.vehicleTypeId : 0);

        requestInfo.vehicleId = (this.inputModel.isMultipleVehiclePurchase ? this.expenseModel.mileage.vehicleId : vehicleId);
        requestInfo.mileageDateDetails = this.fnCalculateMileageMultipleLegRequest(null);
        requestInfo.userId = this.inputModel.claimantId;
        requestInfo.orgID = this.claims.OrgId;
        request.requestInfo = requestInfo;
        this.claimantOnWebService.CalculateMileageMultipleLeg(request).subscribe((response: IApiresponse<CalculateMileageResponse>) => {

            //Stop Loader
            this.ngxService.stop();
            this.disableSaveButton = false;

            if (response.responseInfo != null) {
                if (response.errorInfo && response.errorInfo.length > 0) {
                    this.expenseModel.disabledCalculateBtn = false;
                    this.ShowGoogleServiceErrorPanel();
                }
                else {
                    this.calculateMileageDetails = response.responseInfo;

                    let sourceDestinationInformation = this.calculateMileageDetails.mileageDetailList[0].sourceDestinationInformation;
                    if (sourceDestinationInformation && sourceDestinationInformation.length > 0) {
                        let calculatedDetails = sourceDestinationInformation[0];
                        if (calculatedDetails) {
                            if (this.journeyForm.form && this.journeyForm.form.controls && this.journeyForm.form.controls.distance) {
                                this.journeyForm.form.controls.distance.setValue(calculatedDetails.distance.toFixed(2));
                                if(this.isCarbonFootprintReporting)
                                   this.journeyForm.form.controls.carbonFootprint.setValue(calculatedDetails.carbonFootprint.toFixed(2));
                                if (this.calculateMileageDetails.mileageDetailList[0].totalTriangulatedDistance) {
                                    let totalTriangulatedDistanceText = this.translate.instant("passenger_load_step_10_msg")
                                        + '<span class="padding-left-0 f-12 color-red fw-700">' + this.translate.instant("passenger_load_step_11_msg")
                                        + calculatedDetails.triangulatedDistance.toFixed(2).toString()
                                        + ' ' + (this.expenseModel.mileage.mileageUOM == MileageUOMEnum.Kilometre ? 'km' : 'm')
                                        + '</span>'
                                        + this.translate.instant("passenger_load_step_12_msg");

                                    let htmlCustomMsg = GetField('distance', this.journeyFormFields);
                                    if (htmlCustomMsg) {
                                        htmlCustomMsg.props.htmlCustomMsg = totalTriangulatedDistanceText;
                                    }
                                }
                                else {
                                    let htmlCustomMsg = GetField('distance', this.journeyFormFields);
                                    if (htmlCustomMsg) {
                                        htmlCustomMsg.props.htmlCustomMsg = '';
                                    }
                                }
                            }
                        }
                    }
                }

            }

        });
    }

    fnCalculateMileageMultipleLegRequest(detourJustificationNotes) {
        let vehicleId = 0;

        let defaultVehicle = this.vehicleResponse.find(it => it.isDefault);
        if (defaultVehicle) {
            vehicleId = defaultVehicle.vehicleId
        }

        let mileage = new MileageData();
        mileage.journeyType = JourneyTypeEnum.Single;
        mileage.mileageDate = new Date(this.expenseModel.expenseDate).valueOf();
        mileage.operationalInformation = null;
        mileage.vehicleId = (this.inputModel.isMultipleVehiclePurchase ? this.expenseModel.mileage.vehicleId : vehicleId);
        mileage.customMileageRate = this.resourceMileageDetails ? this.resourceMileageDetails.customMileageRate : null;

        let sourceDestinationarray = [];
        let legModel = new SourceDestinationResponse();
        let location: LocationDetail = { location: '', latitude: 0, longitude: 0, locationTrackTime: 0, placeId: 0 };
        legModel.fromLocation = location;
        legModel.toLocation = location;
        legModel.fromLocation = this.journeyFormModel.fromLocation;
        legModel.toLocation = this.journeyFormModel.toLocation;
        legModel.notes = this.journeyFormModel.notes;
        legModel.sequence = this.journeyFormModel.sequence;
        legModel.detour = this.journeyFormModel.detour ? Number(this.journeyFormModel.detour) : 0;
        legModel.detourJustificationNotes = this.journeyFormModel.detourJustificationNotes;
        legModel.mileageAllowance = this.journeyFormModel.mileageAllowance;
        sourceDestinationarray.push(legModel)

        mileage.sourceDestinationInformation = sourceDestinationarray;
        let mileageData = [];
        mileageData.push(mileage);
        return mileageData;
    }


    fnShowWarningAlerts(expenseErrorWarningType: number) {

        switch (expenseErrorWarningType) {

            case ExpenseErrorWarningTypeEnum.ZeroDistance:
                this.showWarningAlertsModel = new AlertPanelModel(
                    true,
                    this.translate.instant("label_alert"),
                    [],
                    "",
                    this.translate.instant("text_ok"),
                    true,
                    this.translate.instant("journey_with_zero_amount_cannot_be_saved")
                );
                this.showWarningAlerts = true;
                break;

            default:
                break;
        }
    }

    fnShowWarningAlertsReceiver(returnValue) {
        this.showWarningAlertsModel = null;
        this.showWarningAlerts = false;
    }


    //Mileage Detour
    fnShowSelectDetourPanel() {

        if (this.inputModel.isAdvanceMileagePurchasedOrTrial && this.inputModel.isDetourEnabled) {
            this.selectDetourInputModel = new SelectDetourInputModel();
            this.selectDetourInputModel.detour = this.journeyFormModel.detour;
            this.selectDetourInputModel.detourJustificationNotes = this.journeyFormModel.detourJustificationNotes;

            this.showSelectDetourPanel = true;

        } else {
            this.fnShowDebtourNotActivatedAlertPanel();
        }
    }

    fnCloseSelectDetourPanel(isClose: boolean) {
        if (isClose) {
            this.showSelectDetourPanel = false;
        }
    }

    fnSelectDetourPanel(selectedData: SelectDetourOutputModel) {
        if (selectedData) {

            if (this.journeyForm && this.journeyForm.form) {
                this.journeyForm.form.controls.detour.setValue(Number(selectedData.detour).toFixed(2));
                this.journeyForm.form.controls.detourJustificationNotes.setValue(selectedData.detourJustificationNotes);
            }

            this.showSelectDetourPanel = false;
            this.fnCalculateMileageMultipleLeg();
        }
    }

    fnShowDebtourNotActivatedAlertPanel() {
        this.debtourNotActivatedAlertModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            "",
            this.translate.instant("text_ok"),
            true,
            this.translate.instant("create_expense_detour_not_subscribed_alert")
        );

        this.showDebtourNotActivatedAlertPanel = true;
    }

    fnCloseDebtourNotActivatedAlertPanel(event) {
        this.showDebtourNotActivatedAlertPanel = false;
    }

    //Passenger Load
    fnShowAddPassengerLoadPanel() {
        if (this.inputModel.isPassengerLoadPurchased && this.mileageAllowance && this.mileageAllowance.length > 0) {

            this.passengerLoadInputModel = this.mileageAllowance ? this.mileageAllowance : [];
            if (this.journeyFormModel && this.journeyFormModel.mileageAllowance) {
                this.passengerLoadInputModel.forEach(element => {
                    if (element.allowanceId == this.journeyFormModel.mileageAllowance.allowanceId) {
                        element.selected = true;
                    }
                    else {
                        element.selected = false;
                    }
                });
            }
            this.showAddPassengerLoadPanel = true;

        } else {
            this.fnShowPassengerLoadNotActivatedAlertPanel();
        }

    }

    fnMarkMileageAllowanceUnselect() {
        if (this.mileageAllowance) {
            this.mileageAllowance.forEach(element => {
                element.selected = false;
            });
        }
    }

    fnCloseAddPassengerLoadPanel(isClose: boolean) {
        if (isClose) {
            this.showAddPassengerLoadPanel = false;
            this.passengerLoadInputModel = null;
            this.fnMarkMileageAllowanceUnselect();
        }
    }

    fnSelectPassengerLoad(selectedData: MileageAllowance) {
        if (selectedData) {
            if (this.journeyForm && this.journeyForm.form) {
                this.journeyFormModel.mileageAllowance = selectedData;
                this.journeyForm.form.controls.allowanceDescription.setValue(selectedData.allowanceDescription);
            }
        }
        this.showAddPassengerLoadPanel = false;
        this.fnCalculateMileageMultipleLeg();
    }

    fnShowPassengerLoadNotActivatedAlertPanel() {
        this.passengerLoadNotActivatedAlertModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            "",
            this.translate.instant("text_ok"),
            true,
            this.translate.instant("create_expense_passenger_load_not_subscribed_alert")
        );

        this.showPassengerLoadNotActivatedAlertPanel = true;
    }

    fnClosePassengerLoadNotActivatedAlertPanel(event) {
        this.showPassengerLoadNotActivatedAlertPanel = false;
    }

    //Mileage Tag
    fnShowCreateMileageTagPanel() {
        this.createMileageTagInputModel = new SelectTagsPopupModel();
        this.createMileageTagInputModel.tagList = this.journeyFormModel.tagList;
        this.createMileageTagInputModel.claimantId = this.inputModel.claimantId;
        this.createMileageTagInputModel.claimantName = this.inputModel.claimantName;
        this.showCreateMileageTagPanel = true;
    }

    fnCloseCreateMileageTagPanel(isClose: boolean) {
        if (isClose) {
            this.showCreateMileageTagPanel = false;
        }
    }

    fnSelectCreateMileageTag(selectedData: SelectTagsPopupModel) {
        if (selectedData) {
            this.journeyFormModel.tagList = selectedData.tagList;
            this.fnUpdateTagDisplay(this.journeyFormModel.tagList);
        }
        this.showCreateMileageTagPanel = false;
        this.fnMarkFormAsDirty();
    }

    fnUpdateTagDisplay(tagList) {
        let displayTags = this.translate.instant("text_tag");
        if (tagList && tagList.length > 0) {
            displayTags = `${this.translate.instant("text_tag")} (${tagList ? tagList.length : 0})`;
        }
        let tagField = GetField('tagList', this.journeyFormFields);

        if (tagField) {
            tagField.templateOptions.label = displayTags;
        }
    }

    fnShowDeleteConfirmationAlert() {
        this.deleteConfirmationAlertModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [
                this.translate.instant("leg_delete_confirmation_note"),
            ],
            this.translate.instant("label_cancel"),
            this.translate.instant("label_confirm"),
            true,
            this.translate.instant("leg_delete_confirmation_note")
        );
        this.showDeleteConfirmationAlert = true;
    }

    fnDeleteConfirmationAlertReceiver(returnValue) {
        if (returnValue) {
            this.deleteEvent.emit(this.journeyFormModel.sequence);
        }
        this.deleteConfirmationAlertModel = null;
        this.showDeleteConfirmationAlert = false;
    }


    fnMarkFormAsPristine() {
        if (this.journeyForm && this.journeyForm.form) {
            this.journeyForm.form.markAsPristine();
        }
    }

    fnMarkFormAsDirty() {
        if (this.journeyForm && this.journeyForm.form) {
            this.journeyForm.form.markAsDirty();
        }
    }
    showGoogleServiceErrorPopup: boolean = false;
    GoogleServiceErrorPopupModel = new AlertPanelModel(false, "", [""], "", "", true, "");
    ShowGoogleServiceErrorPanel() {

        this.GoogleServiceErrorPopupModel = new AlertPanelModel(
            true,
            this.translate.instant("label_alert"),
            [],
            '',
            this.translate.instant("text_ok"),
            true
            , this.translate.instant("google_service_down_msg")
        );
        this.showGoogleServiceErrorPopup = true;
    }
    fnGoogleServiceErrorPopupReceiver() {
        this.showGoogleServiceErrorPopup = false;
        this.journeyFormModel.disabledCalculateBtn = false;
        this.GoogleServiceErrorPopupModel = new AlertPanelModel(false, "", [""], "", "", true, this.translate.instant("please_review_or_confirm"));
    }


}
