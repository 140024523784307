export class LoggingPanelModel {

  constructor(
    public orgId: string,
    public functionId: string,
    public FilterType: string,
    public MileageFilterType: string = "0",
    public EntityFilterType: string = "0"
  ) {
  }
}

export class LoggingPanelData {
  constructor(
    public Date: string,
    public ActionBy: string,    
    public Log: string) {

  }
}
