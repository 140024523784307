import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-ingrid-renderer',
  templateUrl: './checkbox-ingrid-renderer.component.html' 
})
export class CheckboxIngridRendererComponent implements ICellRendererAngularComp {
  isCheckboxDisabled:boolean = false
  accessToSettings: boolean;  
  params: ICellRendererParams;
  isEnabled: boolean;  
  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      this.accessToSettings = params.getValue();    
      this.isCheckboxDisabled = params.data.isCheckboxDisabled;  
    }
  }
 
  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    this.isEnabled = event.target.checked;
    this.params.setValue(this.isEnabled);
  }

}
