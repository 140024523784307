<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{ 'select_vehicle' | translate }}</h2>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                        <ul class="d-flex justify-content-end w-100pc">
                            <li (click)="fnClosePanel()" class="padding-right-1">
                                <Button
                                    class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                    {{ 'label_close' | translate: param }}
                                </Button>
                            </li>
                        </ul>
                    </div>
                    
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">

                <div class="block padding-top-0 ">
                    <div class="row margin-top-25">
                        <div class="col-lg-6 padding-left-0">
                            <div class="input-group">
                                <input type="text" class="form-control searchField" #searchField
                                    [ngClass]="{ 'has-value': searchField.value }" name="searchText"
                                    [(ngModel)]="searchText" autocomplete="off">
                                <label for="ExpenseCategory">{{ "label_search_criterion" | translate: param }}</label>
                                <span class="icons"><a>
                                        <i class="fas fa-search" aria-hidden="true"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block padding-top-10 heightCalc-12 overflow-auto scrollbar-visible">
                    <div class="row">
                        <div class="col-lg-12 no-padding-right-left">

                            <div class="text-center noDataAvailable disabled opacity-60"
                                *ngIf="(vehicleResponse | grdFilter : {derivedName: searchText}).length === 0">
                                {{ "no_data_available" | translate: param }}
                            </div>

                            <ul class="bulkList">
                                <li class="border-bottom padding-bottom-12  f-14 "
                                    *ngFor="let item of vehicleResponse | grdFilter: { derivedName: searchText}"
                                    (click)="fnSelectVehicleModel(item)"
                                    [ngClass]="{'active': item.vehicleId == vehicleId }">
                                    <span class="d-inline-block w-100pc text-ellipsis">{{item.derivedName}}
                                        ({{item.registrationNumber}})
                                        <span class="text-logo-blue">{{fnBindDefaultVehicle(item.isDefault)}}</span>
                                    </span>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-alert-panel *ngIf="showVehicleNotAppprovedPopup" [data]="showVehicleNotAppprovedPopuModel"
    (callbackEvent)="fnShowVehicleNotAppprovedPopupReceiver($event)">
</app-alert-panel>