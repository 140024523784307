<div class="custom-switch {{toggleAlignClass}}" [hidden]="isHidden">
    <input type="checkbox" class="custom-control-input" id="customSwitches{{informationId}}" 
    [(ngModel)]="isEnabled" [checked]="isEnabled" (change)="onChange($event)"/>  
    <label *ngIf="showText" class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5"
                    for="customSwitches{{informationId}}">
                    <p *ngIf="isEnabled">{{ showSwitchText }}</p>
    </label>
    <label *ngIf="!showText" class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5"
                    for="customSwitches{{informationId}}">
                   </label>
    
</div>
