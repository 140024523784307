import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pre-approval-tag-renderer',
  templateUrl: './pre-approval-tag-renderer.component.html'
})
export class PreApprovalTagRendererComponent implements ICellRendererAngularComp {
  name: string = "";
  preApprovalId: number = 0;
  preApprovalTag: any;

  constructor() {
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  initilize(params: ICellRendererParams) {
    
    this.name =  params.getValue();
    if (params.data && params.data.preApprovalId) {
      this.preApprovalId = params.data.preApprovalId;
    }

    if (params.data && params.data.preApprovalId) {
       this.preApprovalTag = params.data.preApprovalTag;
    }


  }
}
