import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { first, mergeMap, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FunctionPackageEnum, ApiRequest } from 'src/app/_models';
import { GetCartDetailsRequest, ICartDetailsRequest, CartDetailsRequest } from 'src/app/_models/organisation-setting/organisation-setting';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { OrganisationSettingService } from 'src/app/_services/organisation-setting/organisation-setting.service';
import { NRFCConfigModel } from 'src/app/_models/NrFc/nrfcconfig-model';
import { RSAHelper } from 'src/app/_helpers/RSAHelper';
import { Claims } from 'src/app/_models/common/claims';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NrFcLogicsService {
  nrfcConfigModel: NRFCConfigModel = new NRFCConfigModel();
  constructor(private router: Router, private organisationSettingService: OrganisationSettingService
    , private toastr: ToastrService, private translate: TranslateService, private claimsService: ClaimsService) {
    this.nrfcConfigModel.claims = new Claims();
    this.claimsService.currentClaims.subscribe((claims) => {
      this.nrfcConfigModel.claims = claims;
    });
  }

  checkForFunctionPurchased(): Observable<any> {
    return this.organisationSettingService
      .getSubscribedFunctionsForOrganisation(this.nrfcConfigModel.claims.OrgId)
      .pipe(map((subsData) => {
        var fuelCard = subsData.responseInfo.find(
          (x) => x.functionID == FunctionPackageEnum.FuelCard
        );
        var nonReimbursableFuel = subsData.responseInfo.find(
          (x) => x.functionID == FunctionPackageEnum.NonReimbursableFuel
        );
        if (fuelCard == undefined) { this.nrfcConfigModel.isFuelCardPurchased = false; } else { this.nrfcConfigModel.isFuelCardPurchased = true; }
        if (nonReimbursableFuel == undefined) { this.nrfcConfigModel.isNonReimbursableFuelPurchased = false; } else { this.nrfcConfigModel.isNonReimbursableFuelPurchased = true; }
        return this.nrfcConfigModel;
      }));
  }
  getCartDetails(): Observable<any> {
    this.nrfcConfigModel.getCartDetailsRequest = new ApiRequest<GetCartDetailsRequest>(
      "SoloGetCartDetails",
      1,
      "111"
    );
    this.nrfcConfigModel.getCartDetailsRequest.requestInfo = new GetCartDetailsRequest(
      this.nrfcConfigModel.claims.UserId,
      this.nrfcConfigModel.claims.OrgId
    );

    return this.organisationSettingService
      .getCartDetailsList(this.nrfcConfigModel.getCartDetailsRequest)
      .pipe(map((data) => {
        if (data.responseInfo != null) {
          this.nrfcConfigModel.claims.CartCount = data.responseInfo.listCartDetails.length;
          this.claimsService.updateClaims(this.nrfcConfigModel.claims);
          this.nrfcConfigModel.cartList = data.responseInfo.listCartDetails;
          this.nrfcConfigModel.isFuelCardInCart = this.nrfcConfigModel.cartList.some(
            (item) => item.functionPackageId == this.nrfcConfigModel.functionPackageEnum.FuelCard
          );
          this.nrfcConfigModel.isNonReimbursableFuelInCart = this.nrfcConfigModel.cartList.some(
            (item) => item.functionPackageId == this.nrfcConfigModel.functionPackageEnum.NonReimbursableFuel
          );
          return this.nrfcConfigModel;

        } else {
          this.toastr.error(data.errorInfo[0].message);
        }
      },
        (error) => {
          this.toastr.error(error);
        }
      ));
  }
  fnSaveCartDetails(nrfcConfigModel: NRFCConfigModel): void {
    let functionPackageName = '';
    let functionPackageId = 0;
    if (!nrfcConfigModel.isFuelCardPurchased) {
      functionPackageName = this.translate.instant("fuel_card");
      functionPackageId = nrfcConfigModel.functionPackageEnum.FuelCard;
    }
    else {
      functionPackageName = this.translate.instant("non_reimbursable_fuel")
      functionPackageId = nrfcConfigModel.functionPackageEnum.NonReimbursableFuel;
    }
    let request = new ApiRequest<ICartDetailsRequest>(
      "SaveCartDetails",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new CartDetailsRequest(
      nrfcConfigModel.claims.UserId,
      nrfcConfigModel.claims.OrgId,
      false,
      functionPackageId,
      functionPackageName
    );
    this.organisationSettingService
      .SaveCartDetails(request)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            nrfcConfigModel.claims.CartCount += 1;
            this.claimsService.updateClaims(nrfcConfigModel.claims);
            this.router.navigate(["/shoppingcartlist"]);
          } else {
            this.toastr.error(data.errorInfo[0].message);
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }
  fnPopUpSvgUrl(value, nrfcConfigModel: NRFCConfigModel) {
    let url = '';
    switch (value) {
      case 1:
        if (!nrfcConfigModel.isFuelCardPurchased) {
          url = '../../assets/svg-icons/Fuel_card.svg';
        }
        else { url = '../../assets/svg-icons/NR_Fuel.svg'; }
        break;
      case 2:
      case 3:
        if (!nrfcConfigModel.isFuelCardPurchased) {
          url = '../../assets/images/nr_fc_example.png';
        }
        else { url = '../../assets/images/nr_fc_example.png'; }
        break;
    }
    return url;
  }
  fnGetPopUpText(value, nrfcConfigModel: NRFCConfigModel) {
    let text = '';
    switch (value) {
      case 1:
        if (!nrfcConfigModel.isFuelCardPurchased) {
          text = this.translate.instant("fuel_card");
        }
        else { text = this.translate.instant("non_reimbursable_fuel") }
        break;
      case 2:
        if (!nrfcConfigModel.isFuelCardPurchased) {
          text = this.translate.instant("markettingpopup_fc_text2");
        }
        else { text = this.translate.instant("markettingpopup_nr_text2") }
        break;
      case 3:
        if (!nrfcConfigModel.isFuelCardPurchased) {
          text = this.translate.instant("markettingpopup_fc_text3");
        }
        else { text = this.translate.instant("markettingpopup_fc_text3"); }
        break;
    }
    return text;
  }
  routerNavigate(nrfcConfigModel: NRFCConfigModel): void {
    let isFree = "false";
    let packagePath = 'nrfc';
    let functionPackageName = '';
    let functionPackageId = '';
    if (!nrfcConfigModel.isFuelCardPurchased) {
      functionPackageName = this.translate.instant("fuel_card");
      functionPackageId = nrfcConfigModel.functionPackageEnum.FuelCard.toString();
    }
    else {
      functionPackageName = this.translate.instant("non_reimbursable_fuel")
      functionPackageId = nrfcConfigModel.functionPackageEnum.NonReimbursableFuel.toString();
    }

    let functionStatus = "0";
    this.router.navigate(["/addtocart"], {
      queryParams: {
        isFree: this.EncryptParameters(isFree),
        packagePath: this.EncryptParameters(packagePath),
        functionPackageName: this.EncryptParameters(functionPackageName),
        functionPackageId: this.EncryptParameters(functionPackageId),
        functionStatus: this.EncryptParameters(functionStatus),
      },
    });
  }
  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }
  DecryptParameters(encryptedParam): string {
    let rsaHelper = new RSAHelper();
    let decrypt = rsaHelper.Decrypt(encryptedParam);
    return decrypt;
  }
  fnCheckDisable(nrfcConfigModel: NRFCConfigModel) {
    if (!nrfcConfigModel.isFuelCardPurchased) {
      return nrfcConfigModel.isFuelCardInCart
    }
    else {
      return nrfcConfigModel.isNonReimbursableFuelInCart
    }
  }
  fnDisplayMarketingPopUp() {
    this.nrfcConfigModel.isMarketingPopUpDisplay = true;
    return this.nrfcConfigModel;
  }
  fnNavigateUrl(nrfcStatus) {
    if (nrfcStatus == this.nrfcConfigModel.nrFCStatusEnum.NR) {
      this.router.navigate(["nrfc/nrownershiplist"]);
    }
    else {
      this.router.navigate(["nrfc/fcownershiplist"]);
    }
  }

}
