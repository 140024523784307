import { OnInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { SelectAdditionalFieldDataPopupModel, IData } from 'src/app/_models/policy/policy-data';

@Component({
    selector: "select-additional-field-data-panel",
    templateUrl: "./select-additional-field-data-panel.component.html"
})
export class SelectAdditionalFieldDataPanelComponent implements OnInit {

    @Input()
    inputData: SelectAdditionalFieldDataPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectAdditionalFieldDataPopupModel>();

    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';

    additionalFieldDataList = <IData[]>[];
    selectedFieldValueId: number = 0;

    ngOnInit(): void {
        this.fnSetAdditionalFieldDataInputValues();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

    fnSelectBusinessPurposeValues(item: IData) {
        if (item) {
            let selectAdditionalFieldDataOutputModel = new SelectAdditionalFieldDataPopupModel();
            selectAdditionalFieldDataOutputModel.additionalFieldId = this.inputData.additionalFieldId;
            selectAdditionalFieldDataOutputModel.fieldValueId = item.fieldValueId;
            selectAdditionalFieldDataOutputModel.fieldValue = item.fieldValue;
            this.selectItemEvent.emit(selectAdditionalFieldDataOutputModel);
        }
    }

    fnSetAdditionalFieldDataInputValues() {
        if (this.inputData) {
            this.additionalFieldDataList = this.inputData.additionalFieldDataList;
            this.selectedFieldValueId = this.inputData.fieldValueId;
            this.fnGetSelectedAdditionalFieldOnTop();
        }
    }

    fnGetSelectedAdditionalFieldOnTop() {
        let fieldValueId = this.inputData && this.inputData.fieldValueId ? this.inputData.fieldValueId : 0;

        if (fieldValueId && this.additionalFieldDataList && this.additionalFieldDataList.length > 0) {
            const sortedArr = this.additionalFieldDataList.reduce((acc, element) => {
                if (element.fieldValueId == fieldValueId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.additionalFieldDataList = sortedArr;
        }
    }

}
