
<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-70pc active eod-bg-white">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{ "strip_title" | translate }}</h2>
            <div>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClose()"> 
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>
        <div *ngIf="!showRedirectText" class="px-3">
            <div class="block py-4 text-center">
                <h5 class="text-center my-5 font-weight-bold">
                    <span class="text-success font-weight-bold"> {{ "strip_heading1" | translate }} {{this.basicFunctionTrialDays}} {{ "strip_heading1_1" | translate }} </span>
                    <span class="text-danger font-weight-bold"> {{ "strip_heading2" | translate }}</span> {{ "strip_heading3" | translate }}
                </h5>
            </div>
            <div class="block mt-0 pt-0">
                <ul>
                    <li class="py-3 border-bottom border-top w-100pc d-flex align-items-center">
                        <i class="fas fa-circle f-8 pr-2 inline-block" aria-hidden="true"></i>
                        {{ "strip_note1" | translate }}  &nbsp;
                        <a href="https://www.stripe.com" target="_blank">
                            <u> {{ "strip_note1_1" | translate }} </u>
                        </a> &nbsp;  {{ "strip_note1_2" | translate }}
                    </li>
                    <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                        <i class="fas fa-circle f-8 pr-2 inline-block" aria-hidden="true"></i>
                        {{ "strip_note2" | translate }}
                        <a class="pl-1" href="{{ termsOfService }}" target="_blank">
                            <u>{{ "strip_note2_1" | translate }}</u>
                        </a>
                    </li>
                    <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                        <i class="fas fa-circle f-8 pr-2 inline-block" aria-hidden="true"></i>
                        {{ "strip_note3" | translate }}
                    </li>
                </ul>
            </div>
            <div class="block mt-0 text-center">
                <label for="employeeFirstName" class="f-16 position-relative d-flex align-items-center justify-content-center fw-600">
                    <input type="checkbox" class="align-middle margin-right-5 svg-20" required="" name="applyDOC" (change)="fnAgreeOnTerms($event)">
                    {{ "strip_terms_conditions" | translate }} 
                </label>                      
            </div>
            <div class="block mt-0 text-center">
                <button class="btn eod-btn-primary btn-lg" [ngClass]="{'disabled-state':isDisabled}" (click)="fnShowStripeCheckout()">
                    {{ "continue" | translate }}
                </button>                       
            </div>
            <div class="block mt-0 d-flex align-items-center">
                <i class="fas fa-lock fa-lg mr-3"></i>
                <p class="f-16">{{ "strip_footer_note" | translate }}</p>
            </div>
       </div>

    <!-- Stripe Redirect -->
     <div *ngIf="showRedirectText" class="container-fluid padding-LR-15">
            <section class="pageTitle-block margin-top-0">
            <div class="col-md-12 no-padding-right-left">
                <div class="pageTitle padding-bottom-10">
                <div class="viewDemo">
                    <h2 class="padding-bottom-5 text-center">
                    <em>{{ "stripe_redirect_message" | translate }}</em>
                    </h2>
                </div>
                </div>
            </div>
            </section>
        </div>
  
     <div class="clear"></div>

    </div>
</div>


