import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { environment } from "../../../../environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { ClaimsService } from "../claims.service";
import { Claims } from "src/app/_models/common/claims";
import { DefaultParams } from "src/app/_models/common/request-response/default-params";
import { DeviceInfo } from "src/app/_models/common/request-response/device-info";

export abstract class SignalRService {
    public hubConnection: HubConnection;
    public isConnectionStarted: boolean = false;
    public isConnectionSuccessful: boolean = false;

    private claims: Claims;

    constructor(private oauthService: OAuthService,
        private claimsService: ClaimsService) {

        this.claimsService.currentClaims.subscribe(a => {
            this.claims = a;
        });
    }

    abstract intiateListener(); 

    startConnection() {

        var isAuthenticated = this.oauthService.hasValidAccessToken();
        if (!this.isConnectionStarted && isAuthenticated) {
            this.isConnectionStarted = true;

            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(environment.functionsUrl, { httpClient: new SignalRHttpClient(this.claims) })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            this.hubConnection.start()
                .then(() => {
                    this.isConnectionSuccessful = true;
                    this.intiateListener();
                })
                .catch(err => {
                    this.isConnectionStarted = false;
                    console.error('Error while starting connection: ' + err.toString());
                });

            this.hubConnection.onclose(() => {
                this.isConnectionSuccessful = false;
                setTimeout(() => {
                    var isAuthenticated = this.oauthService.hasValidAccessToken();
                    if (isAuthenticated) {
                        this.hubConnection.start()
                            .then(() => {
                                this.isConnectionSuccessful = true;
                                this.intiateListener();
                            })
                            .catch(err => {
                                this.isConnectionStarted = false;
                                console.error('Error while starting connection: ' + err.toString());
                            });
                    }
                }, 3000);
            });
        }
    }

    stopConnection() {
        if (this.hubConnection && this.isConnectionStarted && this.isConnectionSuccessful) {
            this.hubConnection.stop();
        }
        this.isConnectionStarted = false;
        this.isConnectionSuccessful = false;
    }

}

class SignalRHttpClient extends signalR.DefaultHttpClient {

    constructor(private claims: Claims) {
        super(console);
    }

    public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {

        var authHeaders = this.getAuthHeaders();
        request.headers = { ...authHeaders, ...request.headers };

        return super.send(request);
    }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';     
      case agent.indexOf('trident') > -1:
          return 'ie';
      case agent.indexOf('edg') > -1:
          return 'edge';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';   
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    let userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
    getAuthHeaders() {
        let defaultParams = new DefaultParams(this.claims.OrgId, this.claims.UserId, this.claims.FinanceManager, environment.requestTimeStamp);
      let deviceInfo = new DeviceInfo(this.claims.CurrentOrganisationCurrency, environment.platform,
        environment.applicationVersion.toString(), environment.appId, environment.deviceId, this.detectBrowserName(), this.detectBrowserVersion());
        defaultParams.deviceInfo = deviceInfo;

        var headers: any = {
            "Content-Type": "application/json",
            "DefaultParams": JSON.stringify(defaultParams),
            "Authorization": `Bearer ${localStorage.getItem("loginToken")}`,
            "x-ms-signalr-userid": this.claims.UserId.toString()
        };

        return headers;
    }

}
