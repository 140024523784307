import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { OrgSubCategoryMapping } from 'src/app/_models/manage-category/manage-category-list-response';
import { PolicyViolationInputModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { PolicyViolation } from 'src/app/_models/twostepapproval/claimant-expense-detail';

@Component({
  selector: 'app-multiple-tab-policy-violation-screen',
  templateUrl: './multiple-tab-policy-violation-screen.component.html'
})
export class MultipleTabPolicyViolationScreenComponent implements OnInit{

  @Input() expenseModelInfo : any;
  @Output() closePanelEvent = new EventEmitter<any>();
  @Input() panelData:PolicyViolationInputModel;
  @Input() orgSubCategoryMappings:OrgSubCategoryMapping[];
  @Input() selectedSubCategoryItem :OrgSubCategoryMapping;
  @Input() PolicyOnMainCategory :PolicyViolation;
  @Input() isSidePanel:boolean;
  @Input() isReceiptNumberSettingsEnabled:boolean;
  justificationNotesOnMainCategory:string;
  selectedPolicySubCategoryItem: PolicyViolation;  //PolicyViolation
  isShowPolicyViolationPanel: boolean = false;
  isSaveHide : boolean = false;
  isDisableSave: boolean = true;
  specialCategoryName:string;
  isPolicyOnMainCategory:boolean;
  constructor(){
  }

  ngOnInit(){
    this.specialCategoryName = this.panelData.expenseCategoryName;
    for (let index = 0; index < this.orgSubCategoryMappings.length; index++) {
      this.orgSubCategoryMappings[index].sequence = index + 1;
    }
    if(this.panelData != undefined && this.panelData != null)
    {
      this.isSaveHide = this.panelData.disableJustificationNotes;
    }
    if(this.PolicyOnMainCategory)
      {
        this.isPolicyOnMainCategory = true;
        this.selectedPolicySubCategoryItem = this.PolicyOnMainCategory;
        this.panelData.justificationNotes = this.expenseModelInfo.justificationNotes;
      }
      else if(this.orgSubCategoryMappings && this.orgSubCategoryMappings.length > 0)
      {
        this.panelData.expenseCategoryName = this.orgSubCategoryMappings[0].expenseCategoryName;
        this.panelData.justificationNotes = this.orgSubCategoryMappings[0].justificationNotes;
        this.selectedSubCategoryItem = this.orgSubCategoryMappings[0];
        this.selectedPolicySubCategoryItem = this.orgSubCategoryMappings[0].policyViolation;
      }
      else if(this.expenseModelInfo.potentialFraud)
        {
          this.isPolicyOnMainCategory = true;
        this.selectedPolicySubCategoryItem = this.PolicyOnMainCategory;
        this.panelData.justificationNotes = this.expenseModelInfo.justificationNotes;
        }
    
    this.isShowPolicyViolationPanel = true;
  }

fnClosePanel()
{
  this.closePanelEvent.emit(false);
}
ischangeJustifactionNotes(event)
{
  this.isDisableSave = false;
  if(this.isPolicyOnMainCategory)
    {
      this.justificationNotesOnMainCategory = event;
    }
}
fnSaveJustification()
{
  if(this.isPolicyOnMainCategory)
    {
      this.expenseModelInfo.justificationNotes = this.justificationNotesOnMainCategory;
      if(this.orgSubCategoryMappings && this.orgSubCategoryMappings.length > 0)
        {
          this.isShowPolicyViolationPanel = false;
          this.isPolicyOnMainCategory = false;
          setTimeout(() => {
            this.panelData.expenseCategoryName = this.orgSubCategoryMappings[0].expenseCategoryName;
            this.panelData.justificationNotes = this.orgSubCategoryMappings[0].justificationNotes;
            this.selectedSubCategoryItem = this.orgSubCategoryMappings[0];
            this.selectedPolicySubCategoryItem = this.orgSubCategoryMappings[0].policyViolation;
            this.isShowPolicyViolationPanel = true;
          }, 100);
        }
        else
        {
          this.closePanelEvent.emit(this.expenseModelInfo);
        }
    }
    else
    {
      let selectedCategory = this.orgSubCategoryMappings
      .find(a=>a.orgExpenseCategoryId == this.selectedSubCategoryItem.orgExpenseCategoryId);
      if(selectedCategory)
        {
          selectedCategory.justificationNotes = this.panelData.justificationNotes;
        }
        if(this.orgSubCategoryMappings.length > 1 && selectedCategory.sequence < this.orgSubCategoryMappings.length)
          {
            this.selectedSubCategoryItem = this.orgSubCategoryMappings[selectedCategory.sequence];
            this.fnUpdateSubcategoryPolicyViolation(this.selectedSubCategoryItem)
          }
          else
          {
            if(this.justificationNotesOnMainCategory)
              {
                this.panelData.justificationNotes = this.justificationNotesOnMainCategory;
              }
            this.closePanelEvent.emit(this.expenseModelInfo);
          }
    }
  

  
}
fnPolicyOnMainCategory(){
  this.isPolicyOnMainCategory = true;
  this.isShowPolicyViolationPanel = false;
  setTimeout(() => {
    this.selectedPolicySubCategoryItem = this.PolicyOnMainCategory;
    if(this.panelData && this.panelData.disableJustificationNotes)
      {
        this.panelData.justificationNotes =  this.expenseModelInfo.justificationNotes;
      }
      else
      {
        if(this.justificationNotesOnMainCategory)
          {
            this.panelData.justificationNotes = this.justificationNotesOnMainCategory;
          }
          else
          {
            this.panelData.justificationNotes = this.expenseModelInfo.justificationNotes;
          }
        
      }
    
    this.isShowPolicyViolationPanel = true;
    this.selectedSubCategoryItem = null;
  }, 100);
  
}
fnUpdateSubcategoryPolicyViolation(selectedsubcategoryitem){
  this.isPolicyOnMainCategory = false;
  this.isShowPolicyViolationPanel = false;
  setTimeout(() => {
    this.selectedPolicySubCategoryItem = selectedsubcategoryitem.policyViolation;
    this.selectedSubCategoryItem = selectedsubcategoryitem;
    this.panelData.expenseCategoryName = selectedsubcategoryitem.expenseCategoryName;
    this.panelData.justificationNotes = selectedsubcategoryitem.justificationNotes;
    this.isShowPolicyViolationPanel = true;
  }, 100);
  
}
}
