export class ApprovalSummary {
    pendingExpensesWithFM: number = 0;
    pendingExpensesWithApprover: number = 0;
    pendingAdvancesWithApprover: number = 0;
    pendingCountExpensesWithFM: number = 0;
    pendingAmountExpensesWithFM: number = 0;
    pendingCountFinanceApproval: number = 0;
    pendingCountFinancePayment: number = 0;
    approverNotAssigned: number = 0;
    approverNotAssignedExpenseAmount: number = 0;
    vehicleApprovalCount: number = 0;
    approverNotInvited: number = 0;
    approverNotInvitedTotalExpenseAmount: number = 0;
    pendingPreApprovalsWithApprover: number = 0;
    dismissedExpensesCount: number = 0;
    totalCountOfItemForApproval: number = 0;
    pendingCountNRFinanceApproval: number = 0;
    pendingCountFCFinanceApproval: number = 0;
    pendingDutyofCareApprovalCount: number = 0;
    nrCountForFM: number = 0;
    fcCountForApprover: number = 0;
    pendingCountAdvanceApproval: number = 0;
    pendingCountAdvanceFinanceApproval: number = 0;
    pendingCountAdvancePay: number = 0;
    pendingCountInvoiceApproval: number = 0;
    pendingCountInvoiceFinanceApproval: number = 0;
    pendingCountInvoicePay: number = 0;
    pendingKYCApprovalCount:number=0;
}
