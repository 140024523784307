import { Component, OnInit, AfterViewInit } from "@angular/core";
import { EventService } from "../core/services/event.service";
import {
  LAYOUT_VERTICAL,
  LAYOUT_HORIZONTAL,
  LAYOUT_MODE,
  LAYOUT_WIDTH,
  LAYOUT_POSITION,
  SIDEBAR_SIZE,
  SIDEBAR_COLOR,
  TOPBAR,
} from "./layouts.model";
import { ClaimsService } from "../_services/common/claims.service";
import { Subscription, first } from "rxjs";
import { Claims } from "../_models/common/claims";
import { SwPush } from "@angular/service-worker";
import { UserBrowserDetails } from "../_models/user/userrequestdto";
import { AuthenticationService } from "../_services";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})

/**
 * Lauout Component
 */
export class LayoutComponent implements OnInit, AfterViewInit {
  // layout related config
  layoutType!: string;
  layoutMode!: string;
  layoutwidth!: string;
  layoutposition!: string;
  topbar!: string;
  sidebarcolor!: string;
  sidebarsize!: string;
  orgRoleId: number;
  claimsSubscription: Subscription;
  claims: Claims;

  constructor(
    private eventService: EventService,
    private claimsService: ClaimsService,
    private swPush: SwPush,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {
    this.claimsSubscription = this.claimsService.currentClaims.subscribe(
      (claims) => {
        this.claims = claims;
        if(this.claims)
        {
          this.orgRoleId = this.claims.orgRoleId;
        }
      }
    );
  }

  ngOnInit() {
    this.layoutMode = LAYOUT_MODE;
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.layoutposition = LAYOUT_POSITION;
    this.sidebarcolor = SIDEBAR_COLOR;
    this.sidebarsize = SIDEBAR_SIZE;
    this.topbar = TOPBAR;

    this.LayoutMode(this.layoutMode);
    this.LayoutWidth(this.layoutwidth);
    this.LayoutPosition(this.layoutposition);
    this.Topbar(this.topbar);
    this.SidebarColor(this.sidebarcolor);
    this.SidebarSize(this.sidebarsize);

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe("changeLayout", (layout) => {
      this.layoutType = layout;
    });

    this.eventService.subscribe("changeMode", (mode) => {
      this.layoutMode = mode;
      this.LayoutMode(this.layoutMode);
    });

    this.eventService.subscribe("changeWidth", (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    this.eventService.subscribe("changePosition", (position) => {
      this.layoutposition = position;
      this.LayoutPosition(this.layoutposition);
    });

    this.eventService.subscribe("changeTopbar", (topbar) => {
      this.topbar = topbar;
      this.Topbar(this.topbar);
    });

    this.eventService.subscribe("changeSidebarColor", (sidebarcolor) => {
      this.sidebarcolor = sidebarcolor;
      this.SidebarColor(this.sidebarcolor);
    });

    this.eventService.subscribe("changeSidebarSize", (sidebarsize) => {
      this.sidebarsize = sidebarsize;
      this.SidebarSize(this.sidebarsize);
    });
  }

  ngAfterViewInit() {
    this.pushSubscription();
  }

  readonly VAPID_PUBLIC_KEY =
    "BP8dsmafi6xfRWX3dZmpiMaXT0blFzlD7GOcqZV-ZJqeTbhlPEuI8Ge34DikR07BlJiFRJ59tVJWVkpyd8CbF1k";

  pushSubscription() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then((sub) => {
        let userBrowserDetails = new UserBrowserDetails();
        userBrowserDetails.userId = JSON.parse(
          localStorage.getItem("claims")
        ).UserId;
        userBrowserDetails.browser = JSON.stringify(sub);
        userBrowserDetails.timezoneOffset = new Date().getTimezoneOffset();
        userBrowserDetails.employeeRole = JSON.parse(
          localStorage.getItem("claims")
        ).orgRoleId;
        userBrowserDetails.browserName = this.getBrowserName();

        this.authenticationService
          .UpdateUserBrowserDetails(userBrowserDetails)
          .pipe(first())
          .subscribe((data) => {});
      })
      .catch((err) => {});
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edg/") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  LayoutMode(mode: string) {
    if (mode === "light") {
      document.body.setAttribute("data-bs-theme", "light");
      document.body.setAttribute("data-topbar", "light");
    } else if (mode === "dark") {
      document.body.setAttribute("data-sidebar", "dark");
      document.body.setAttribute("data-bs-theme", "dark");
      document.body.setAttribute("data-topbar", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
      document.body.setAttribute("data-topbar", "light");
    }
  }

  LayoutWidth(width: string) {
    if (width === "fluid") {
      document.body.setAttribute("data-layout-size", "fluid");
    } else {
      document.body.setAttribute("data-layout-size", "boxed");
    }
  }

  LayoutPosition(position: string) {
    if (position === "fixed") {
      document.body.setAttribute("data-layout-scrollable", "false");
    } else {
      document.body.setAttribute("data-layout-scrollable", "true");
    }
  }

  SidebarColor(color: string) {
    switch (color) {
      case "light":
        document.body.setAttribute("data-sidebar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        break;
      case "brand":
        document.body.setAttribute("data-sidebar", "brand");
        break;
      default:
        document.body.setAttribute("data-sidebar", "dark");
        break;
    }
  }

  Topbar(topbar: string) {
    if (topbar === "light") {
      document.body.setAttribute("data-topbar", "light");
    } else if (topbar === "dark") {
      document.body.setAttribute("data-topbar", "dark");
    } else {
      document.body.setAttribute("data-topbar", "dark");
    }
  }

  SidebarSize(size: string) {
    if (size === "default") {
      document.body.setAttribute("data-sidebar-size", "lg");
    } else if (size === "compact") {
      document.body.setAttribute("data-sidebar-size", "md");
    } else if (size === "small") {
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }
  
  webNotificationMessage = "";
  showWebNotificationMessage = false;
  handleWebNotifications() {    
    if (!("Notification" in window)) {
      this.webNotificationMessage =
      this.translate.instant('desktop_notifications_unsupported_msg');
      this.showWebNotificationMessage = true;
    } else if (Notification.permission === "denied") {
      this.webNotificationMessage =
      this.translate.instant('enable_notifications_msg');
      this.showWebNotificationMessage = true;
    }
  }
}
