import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-pre-approval-renderer',
  templateUrl: './pre-approval-renderer.component.html',
  styleUrls: ['./pre-approval-renderer.component.scss']
})
export class PreApprovalRendererComponent implements ICellRendererAngularComp {
  preApprovalId: number;
  preApprovalName: any;
  preApprovalBalance: any;

  constructor() {

  }

  initilize(params: ICellRendererParams) {
    if (params && params.data) {
      this.preApprovalId = params.data.preApprovalId;
      if (params.data.preApprovalTag) {
        this.preApprovalName = params.data.preApprovalTag.name;
        this.preApprovalBalance = params.data.preApprovalTag.balance;
      }
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
