<ng-select *ngIf="!isShowText" appendTo="body" class="grid-dropdown" [items]="items" bindLabel="value" bindValue="id"
    [clearable]="false" [selectOnTab]="true" [(ngModel)]="value" (change)="onChange($event)"
    [placeholder]="placeholder">

    <ng-template ng-option-tmp let-item="item" let-index="index" >
        <div [class.disabled]="item.isDisabled" class="custom-dropdown" style="padding: 10px 0px !important;"
         *ngIf="!item.isLink">
        <p class="font-weight-bold employee-role-option">{{ item.value }}</p>
        <p class="employee-role-option">{{ item.description }}</p>
    </div>
    <span class="linkOption dropdownLink" *ngIf="item.isLink">
        <a class="float-left d-block linkOption dropdownLink">
            {{ item.value }}
        </a>
    </span>

        <!-- <div [class.disabled]="item.isDisabled" class="custom-dropdown" style="padding: 10px 0px !important;" >
            <p class="font-weight-bold employee-role-option">{{ item.value }}</p>
            <p class="employee-role-option f-11description">{{ item.description }}</p>
        </div> -->
    </ng-template>
</ng-select>

<span *ngIf="isShowText"> {{ value }}</span>