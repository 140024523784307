<div class=" ">
  <div class="">
    <div class="col-lg-9 mx-auto">
      <div class="sliderContainer border">
        <div class="row">
          <div class="col-lg-12 no-padding-right-left">
            <div class="bg-white margin-5 padding-10">
              <div class="logo-container padding-15">
                <div class="logo-imgHolder text-center">
                  <img [src]="imgUrl" class="height-auto mx-auto w-30">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-top-10">
          <div class="col-lg-12 no-padding-right-left text-center mx-auto">
            <ul class="mx-auto w-90pc position-relative f-13">
              <li class="position-relative d-block text-right w-48pc">
                <span class="d-block">{{field.templateOptions.dimension}}</span>
                <span class="d-block">{{field.templateOptions.size}}</span>
                <span class="d-block">{{field.templateOptions.accepted_formats}}</span>
                <p class="position-absolute right-120px top-20px">
                  <span><i class="far fa-info-circle f-20 color-red" aria-hidden="true"></i></span>
                </p>
              </li>
              <li class="w-2pc">&nbsp;</li>
              <li class="d-block text-left w-48pc">
                <span class="d-block fw-700">{{field.templateOptions.h_30px}} | {{field.templateOptions.w_30px}}</span>
                <span class="d-block fw-700">{{field.templateOptions.kb_50}}</span>
                <span class="d-block fw-700" [innerHTML]="field.templateOptions.fileformats"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div class="row mt-3">

    <input id="{{ 'upl' + fieldKey }}" type="file" accept=".jpg,.jpeg,.png" (change)="onChange($event)" #file
      class="fileUpload d-none" [formControl]="formControl" [formlyAttributes]="field"
      [ngClass]="{'has-value': formControl.value }">
    <button class="btn btn-primary f-12 mx-auto" (click)="file.click()">{{"change_icon_label" | translate}}</button>
  </div>
</div>
<app-alert-panel *ngIf="ShowErrorPanel" [data]="alertPanelModel" (callbackEvent)="alertPanelReceiver($event)">
</app-alert-panel>