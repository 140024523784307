<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{"attach" | translate}}</h2>
            <div>
                <button type="button" (click)="fnCloseAttachReceiptOptionsPanel()" class="btn eod-btn-outline-warning height-38px">
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block padding-bottom-0">
                    <ul class="bulkList margin-bottom-0">
                        <li (click)="fnShowFilePicker()" class="f-14 padding-bottom-0">
                            <span class="d-inline-block" *ngIf="userType==0">
                                <i class="fas fa-image padding-right-10 w-30" aria-hidden="true">
                                </i>
                                {{ "upload_receipt_and_pdf" | translate:param }}
                            </span>
                            <span class="d-inline-block" *ngIf="userType==1">
                                <i class="fas fa-image padding-right-10 w-30" aria-hidden="true">
                                </i>
                                {{ "upload_document_and_pdf" | translate:param }}
                            </span>
                        </li>
                        <li *ngIf="userType==0 && !hideSavedEmailReciepts" (click)="fnShowExpenseMergePanel()" class="f-14 padding-bottom-5">
                            <span class="d-inline-block">
                                <i class="fas fa-file-alt padding-right-10 w-30" aria-hidden="true"></i>
                                {{ "saved_and_emailed_receipts" | translate:param }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>