<div class="overLayDiv active" *ngIf="loggingPanelModel"></div>
<div id="rightSideBar" *ngIf="loggingPanelModel">
  <div class="rightSidePanel w-70pc d-flex flex-column">
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
      <div class="viewDemo d-flex align-items-center">
        <h2>{{this.title}}</h2>
      </div>
      <div>
          <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="dismiss();">
            {{ this.btnCancelToolTipText ? this.btnCancelToolTipText : '' }}
          </button>
      </div>
    </header>
    <div class="block mt-2 heightCalc-5">
      <div class="table-responsive table-responsive-style">
        <table class="table table-striped my-0 tableBodyScroll">
          <thead>
            <tr>
              <th width="15%" class="border-right">{{this.dateText}}</th>
              <th width="15%" class="border-right">{{this.ActionByText}}</th>
              <th width="65%">{{this.LogText}}</th>
            </tr>
          </thead>
          <tbody id="expTableValues" class="scrollbar-width-thin heightCalc-9">
            <tr *ngFor="let item of messages">
              <td width="15%" class="border-right">{{item.Date}}</td>
              <td width="15%" class="text-left border-right text-truncate">{{item.ActionBy}}</td>
              <td width="65%" class="text-left" id="logMessage" [innerHTML]="item.Log"></td>
            </tr>

            <tr class="bg-transparent" *ngIf="messages == 0">
              <td colspan="3" class="text-center noHover">
                {{ "no_data_available" | translate: param }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<!-- <div class="overLayDiv active" *ngIf="loggingPanelModel"></div>
<div id="rightSideBar" *ngIf="loggingPanelModel">
  <div class="rightSidePanel z-99999 w-70pc active">
    <header>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
              <h2>{{this.title}}</h2>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
              <ul class="d-flex justify-content-end w-100pc">
                <li class="padding-right-0" (click)="dismiss();">
                  <button type="button" class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                    {{ this.btnCancelToolTipText ? this.btnCancelToolTipText : '' }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
      <div class="block heightCalc-6 ">
        <section class="tableContainer Li100 logTables  position-relative w-100pc">
          <div class="mainTable rowHeight18">
            <div class="table-responsive table-responsive-style">
              <table class="table table-striped margin-top-0 tableBodyScroll">
                <thead>
                  <tr>
                    <th style="width:15%" class="border-right">{{this.dateText}}</th>
                    <th style="width:15%" class="border-right">{{this.ActionByText}}</th>
                    <th style="width:65%">{{this.LogText}}</th>
                  </tr>
                </thead>
                <tbody id="expTableValues" class="scrollbar-width-thin heightCalc-12">
                  <tr *ngFor="let item of messages">
                    <td style="width:15%" class="border-right">{{item.Date}}</td>
                    <td class="text-left border-right text-truncate" style="width:15%">{{item.ActionBy}}</td>
                    <td class="text-left" id="logMessage" style="width:65%" [innerHTML]="item.Log"></td>
                  </tr>

                  <tr class="bg-transparent" *ngIf="messages == 0">
                    <td colspan="3" class="text-center noHover">
                      {{ "no_data_available" | translate: param }}
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div> -->