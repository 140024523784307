<div class="input-group margin-top-20">
    <input id="{{ 'int' + fieldKey }}" type="text" class="form-control text-logo-blue" (keypress)="numberOnly($event)"
        (paste)="$event.preventDefault()" (input)="onNumberInput()" [formControl]="formControl"
        [formlyAttributes]="field" [ngClass]="{'has-value': formControl.value || formControl.value == 0 }">
    <label for="IntegerInput">
        {{field.templateOptions.label}}<span *ngIf="to.required">*</span>
    </label>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>

</div>
